export function getSteps() {
  return [
    'Persoonlijke gegevens',
    'Acitiviteiten niveau',
    'Het persoonlijke doel',
    'Snelheid',
    'Macronutritients Range',
    'Nutritional composition',
  ]
}

export function getStepContent(step: number) {
  switch (step) {
    case 0:
      return {
        inputs: [
          {
            type: 'date',
            name: 'dateOfBirth',
            label: 'Geboortedatum:',
          },
          {
            type: 'number',
            name: 'height',
            label: 'Lengte, cm:',
          },
          {
            type: 'number',
            name: 'weight',
            label: 'Gewicht, kg:',
          },
        ],
        inputsTitle: 'Persoonlijke Gegevens Lid',
        title: 'Uitleg Persoonlijke Gegevens',
        text: 'Gezonde en de juiste voeding voor ieder lid is van groot belang voor een gezonde levensstijl om het gewenste resultaat te behalen en te behouden. Voor het berekenen van de dagelijkse calorie behoefte voor de lid zijn een aantal basis gegevens vereist van de lid. Vervolgens dien je het persoonlijke doel, activiteiten levels en de voedingswaarden in te vullen voor de lid. Hierdoor zal het systeem een Dagelijkse Kcal Behoefte berekenen.<br><br> Controleer de gegevens in de linkerkolom en pas deze waar nodig aan. Klik vervolgens op ‘Volgende’ om door te gaan.',
      }
    case 1:
      return {
        inputs: [
          {
            type: 'radio',
            name: 'activity_level',
            radio: [
              {
                value: 1.3,
                label: 'Niet Actief',
              },
              {
                value: 1.5,
                label: 'Licht Actief',
              },
              {
                value: 1.7,
                label: 'Actief',
              },
              {
                value: 2.0,
                label: 'Heel Erg Actief',
              },
            ],
          },
        ],
        inputsTitle: 'Acitiviteiten niveau opties:',
        title: 'Uitleg betreft persoonlijke gegevens',
        text: 'Om je dagelijkse caloriebehoefte te berekenen is meer informatie nodig dan alleen de levensstijl van een lid. Als de lid bijvoorbeeld een hele actieve baan heeft dan zal de lid meer kilocalorieën verbanden op een dag dan wanneer die geen actieve baan heeft. Geef links aan wat voor activiteiten level jouw lid heeft.<br><br> Let op: Als je aangeeft hoe actief iemand is in zijn of haar vrije tijd, dien je hierbij de sportactiviteiten van de lid buiten beschouwing te laten. Als de lid bijvoorbeeld drie keer per week sport maar de rest van zijn vrije tijd op de bank doorbrengt dan kies je voor niet actief. Sport de lid nooit maar heeft de lid wel een vrij drukke baan zoals bouwvakker, schoonmaker, kies dan voor ‘Heel erg actief',
      }
    case 2:
      return {
        inputs: [
          {
            type: 'radio',
            name: 'personal_goal',
            radio: [
              {
                value: 1,
                label: 'Afvallen',
              },
              {
                value: 2,
                label: 'Spiermassa aankomen',
              },
              {
                value: 3,
                label: 'Op gewicht blijven',
              },
            ],
          },
        ],
        inputsTitle: 'Wat is het persoonlijke doel van de lid',
        title: 'Uitleg betreft de persoonlijke doel van de lid',
        text: 'Geef in de linkerkolom aan wat het doel is van de lid. Wil de lid afvallen, aankomen of op gewicht blijven?<br><br> Om op een gezonde manier meer dan 10 kg af te vallen wordt het geadviseerd om 0,5 kg tot 1,0 kg per week af te vallen. Dit dient bereikt te worden door een gezonde kcal inname. Een te grote plotselinge daling in kcal kan de lid als onprettig ervaren. De lid zal dit ook minder lang kunnen volhouden. Te snel en te veel willen afvallen kan leiden tot het JoJo - Effect. Duurzame resultaten kosten tijd.',
      }
    case 3:
      return {
        inputs: [
          {
            type: 'radio',
            name: 'weight_rate',
            radio: [
              {
                value: 3000,
                label: '3000 kcal Week Te kort',
              },
              {
                value: 2500,
                label: '2500 kcal Week Te kort',
              },
              {
                value: 2000,
                label: '2000 kcal Week Te kort',
              },
            ],
          },
        ],
        title: 'Uitleg van afvallen',
        text: 'Als de lid een significante hoeveelheid aan gewicht wil verliezen (bijvoorbeeld 15+ kg) dan kan je kiezen voor een iets agressievere aanpak. Kies dan: 3000 kcal week te kort, hierdoor zal de lid in een groter negatief energiebalans zitten. Als een persoon langzaam gewicht wil verliezen dan kan je kiezen voor de 2500 of 2000 kcal week te kort. De week te kort geeft de snelheid van het gewicht verliezen aan en hoe diep de lid in een negatief energiebalans komt te zitten.',
      }
    case 4:
      return {
        inputs: [
          {
            type: 'slider',
            name: 'macronutrient_range',
            slider: [
              {
                element: 'Eiwitten',
                name: 'protein_per_kg',
                value: 1.8,
                min: 1.8,
                max: 2.8,
                step: 0.01,
                label: 'Kies een waarde tussen 1 gram en 2 gram per kilogram',
                valueType: ' gr',
              },
              {
                element: 'Vetten',
                name: 'fats_percentage',
                value: 15,
                min: 15,
                max: 40,
                step: 1,
                label: 'Kies een waarde voor de totale hoeveelheid van de Calorie inname',
                valueType: '%',
              },
            ],
          },
        ],
        title: 'Uitleg van de Macronutrienten Ranges',
        text: 'Als het persoonlijke doel aankomen in spiermassa is of het verliezen van lichaamsvet waarbij de lid zoveel mogelijk spiermassa wil behouden dan is de macronutrienten verdeling van cruciaal belang. Als de lid in een negatief energiebalans komt dan dient de lid ook een minimale hoeveelheid van eiwitten en vetten binnen te krijgen. De inname van koolhydraten is van minder belang en wordt opgevuld aan de hand van de eiwitten en vetten. Het is van groot belang dat de macronutrienten: eiwitten, vetten en koolhydraten een range hebben. Dit is belangrijk voor de flexibiliteit van het voedingsschema.',
      }
    default:
      return {
        inputs: [],
        text: 'Unknown step',
      }
  }
}
