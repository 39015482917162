import { useConfirmation } from 'context/ConfirmationServiceContext'
import { useSnackbar } from 'notistack'

import {
  GetCookbookCategoryByIdDocument,
  useDeleteCookbookRecipeByPkMutation,
  useInsertCookbookRecipeMutation,
  useUpdateCookbookRecipeByPkMutation,
} from '../../generated/graphql'

interface ICreate {
  title: string
  description: string
  photo_urls: string
  duration?: number | null
  kcal?: number | null
  cookbook_categories_id: number
}

interface IUpdate {
  title: string
  photo_urls: string
  duration?: number | null
  kcal?: number | null
  cookbook_categories_id: number
}

interface IHook {
  isLoading: boolean
  insert: (body: ICreate) => void
  updateById: (id: number, body: IUpdate) => void
  deleteById: (id: number, categoryId: number) => void
}

export default function useCookbookRecipes(callback?: () => void): IHook {
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirmation()

  const handleSuccess = () => {
    enqueueSnackbar('saved successfully', { variant: 'success' })
  }

  const handleError = (error: any) => {
    enqueueSnackbar('Something went wrong, please try again later...', {
      variant: 'error',
    })
    console.error(error)
  }

  const [insertRecipe, { loading: isInserting }] = useInsertCookbookRecipeMutation()
  const [updateRecipe, { loading: isUpdating }] = useUpdateCookbookRecipeByPkMutation()
  const [deleteCookbookRecipe, { loading: isDeleting }] = useDeleteCookbookRecipeByPkMutation()

  const deleteById = (id: number, categoryId: number): void => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'You are planning to delete',
      description: 'Are you sure you want to do this?',
    }).then(() => {
      deleteCookbookRecipe({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: GetCookbookCategoryByIdDocument,
            variables: { categoryId },
          },
        ],
      })
        .then(handleSuccess)
        .catch((error) => handleError(error))
        .finally(callback)
    })
  }

  const updateById = (id: number, body: IUpdate): void => {
    updateRecipe({
      variables: {
        id,
        changes: body,
      },
    })
      .then(handleSuccess)
      .catch((error) => handleError(error))
      .finally(callback)
  }

  const insert = (body: ICreate): void => {
    insertRecipe({
      variables: {
        objects: [body],
      },
      refetchQueries: [
        {
          query: GetCookbookCategoryByIdDocument,
          variables: {
            categoryId: body.cookbook_categories_id,
          },
        },
      ],
    })
      .then(handleSuccess)
      .catch((error) => handleError(error))
      .finally(callback)
  }

  const isLoading = isInserting || isUpdating || isDeleting

  return { deleteById, updateById, insert, isLoading }
}
