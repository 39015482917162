import { Box, Button, Grid, makeStyles } from '@material-ui/core'
import { Loading } from 'components/Loading'
import { SearchPanel } from 'components/SearchPanel'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import WithDrawer from 'wrappers'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { useGetBlogCategoryByPkQuery } from '../../generated/graphql'
import { NotCreated } from '../UserDetailedPage/components/NotCreated'
import ArticleCard from './components/ArticleCard'
import Modal from './components/Modal'
import { TBlogArticle } from './interfaces'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },

  containedBtn: {
    marginLeft: 'auto',
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    marginRight: 10,
  },
}))

const BlogArticlesPage = (): JSX.Element => {
  const classes = useStyles()
  const { categoryId }: any = useParams()

  const [searchString, setSearchString] = useState<string>('')
  const [isModalOpened, setModalOpened] = useState<boolean>(false)
  const [targetRecipe, setTargetRecipe] = useState<TBlogArticle | undefined>(undefined)

  const { data, loading } = useGetBlogCategoryByPkQuery({
    variables: {
      id: categoryId,
    },
  })

  const closeModal = (): void => {
    setModalOpened(false)
    setTargetRecipe(undefined)
  }

  const breadcrumbData: IBreadcrumb[] = [
    { isLink: true, name: 'BLOG', to: '/blog' },
    { isLink: false, name: data?.blog_categories_by_pk?.name ?? '-' },
  ]

  const blogArticles = data?.blog_categories_by_pk?.blog_articles || []
  const filtered = blogArticles.filter((item) => item.title.indexOf(searchString) !== -1) || []

  const showModal = (recipe: TBlogArticle): void => {
    setTargetRecipe(recipe)
    setModalOpened(true)
  }

  const render = (): JSX.Element | null => {
    if (searchString.trim().length === 0 && blogArticles.length === 0) {
      return (
        <NotCreated
          onPress={(): void => setModalOpened(true)}
          primary="Geen artikelen gevonden"
          secondary="Op deze pagina vind je een overzicht van alle blog artikelen."
          buttonText="Blog artikel aanmaken"
          image="../../images/create_nutritions.png"
        />
      )
    }
    return null
  }

  const listToRender = searchString.trim().length > 0 ? filtered : blogArticles

  return (
    <WithDrawer breadcrumbData={breadcrumbData}>
      {loading && <Loading />}

      <Box
        {...{
          component: 'div',
          className: classes.container,
        }}
      >
        <React.Fragment>
          <div style={{ display: 'flex', marginBottom: 20 }}>
            <SearchPanel placeholder="Zoek artikel op titel" onSubmit={(value) => setSearchString(value)} />

            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.containedBtn}
              onClick={(): void => setModalOpened(true)}
            >
              + Voeg artikel toe
            </Button>
          </div>
          <Grid container>
            {listToRender.map((item) => (
              <ArticleCard key={item.id} blogArticle={item} onClick={showModal} />
            ))}
          </Grid>
        </React.Fragment>
        {render()}
        <Grid container />
      </Box>

      {isModalOpened && <Modal item={targetRecipe} closeModal={closeModal} />}
    </WithDrawer>
  )
}

export default BlogArticlesPage
