import { format } from 'date-fns'
import { set } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import {
  GetUserByIdDocument,
  useUpdateUserByPkMutation,
  useUpdateUserProfileByPkMutation,
} from '../../../../../generated/graphql'
import { Buttons } from './AlgemeneGegevensTab/Buttons'
import { Form } from './AlgemeneGegevensTab/Form'
import { IProps, IUpdateProps } from './interfaces'

export const AlgemeneGegevensTab: React.FunctionComponent<IProps> = ({ userInfo, editMode, onClose }) => {
  const [fields, setFields] = useState<IUpdateProps>({
    user: {
      gender: 'M',
      first_name: '',
      last_name: '',
      email: '',
      user_profile: {
        date_of_birth: new Date(),
        initial_height: 1,
        initial_weight: 1,
      },
    },
  })

  const { enqueueSnackbar } = useSnackbar()
  const { userId } = useParams()

  const setInitialFields = useCallback(() => {
    if (userInfo) {
      setFields({
        user: {
          gender: userInfo.gender ?? 'M',
          first_name: userInfo.first_name ?? '',
          last_name: userInfo.last_name ?? '',
          email: userInfo.email,
          user_profile: {
            date_of_birth: userInfo.user_profile?.date_of_birth,
            initial_height: userInfo.user_profile?.initial_height ?? 1,
            initial_weight: userInfo.user_profile?.initial_weight ?? 1,
          },
        },
      })
    }
  }, [userInfo])

  const closeHandler = (): void => {
    setInitialFields()
    onClose()
  }

  useEffect(() => {
    setInitialFields()
  }, [setInitialFields])

  const onChange = (event: any, additionalKey?: any): void => {
    if (fields) {
      if (event instanceof Date) {
        setFields((prevState) => ({
          user: {
            ...prevState.user,
            user_profile: {
              ...prevState.user.user_profile,
              date_of_birth: event,
            },
          },
        }))
        return
      }

      let key = event?.target?.name
      let newValue = event?.target?.value
      if (additionalKey) {
        key = additionalKey
        newValue = event
      }
      const clonedObject = fields
      set(clonedObject, key, newValue)
      setFields((prevState) => ({
        ...prevState,
        ...clonedObject,
      }))
    }
  }

  const [updateUserByPk] = useUpdateUserByPkMutation()
  const [updateUserProfileByPk] = useUpdateUserProfileByPkMutation()

  const onSave = (): void => {
    if (fields) {
      const userData = fields.user
      const { gender, first_name, last_name, email } = userData
      updateUserByPk({
        variables: {
          id: userInfo.id,
          changes: {
            gender,
            first_name,
            last_name,
            email,
          },
        },
      })
        .then(() => {
          const { initial_height, initial_weight, date_of_birth } = fields.user.user_profile
          // eslint-disable-next-line promise/no-nesting
          updateUserProfileByPk({
            variables: {
              id: userInfo?.user_profile?.id,
              changes: {
                initial_height,
                initial_weight,
                date_of_birth: format(date_of_birth, 'yyyy-MM-dd'),
              },
            },
            refetchQueries: [{ query: GetUserByIdDocument, variables: { userId } }],
            awaitRefetchQueries: true,
          })
            .then(() => {
              enqueueSnackbar('Saved successfully', { variant: 'success' })
            })
            .catch(console.error)
        })
        .catch(console.error)
        .finally(() => {
          onClose()
        })
    }
  }

  return (
    <React.Fragment>
      <Form onChange={onChange} editMode={editMode} userInfo={userInfo} fields={fields} />
      {editMode && <Buttons onSave={onSave} onClose={closeHandler} />}
    </React.Fragment>
  )
}

export default AlgemeneGegevensTab
