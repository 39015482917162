import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core'
// MUI
import { makeStyles } from '@material-ui/core/styles'
import * as React from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 245,
    minWidth: 245,
    margin: 20,
  },
  cardMediaWrapper: {
    padding: `${theme.spacing(1)}px 0`,
    textAlign: 'center',
    border: '2px solid #fff',
    '&:hover': {
      border: '2px solid #3F51B5',
      backgroundColor: '#fff',
    },
  },
  cardMedia: {
    width: 'auto',
    margin: '0 auto 20px',
  },
  cardContent: {
    padding: 0,
  },
  programInfo: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    backgroundColor: '#3F51B5',
    color: '#fff',
  },
  small: {
    fontSize: 12,
  },
  bold: {
    fontWeight: 500,
  },
}))

export const ProgramCard: React.FunctionComponent = ({ data }: any) => {
  const classes = useStyles()
  const dateFormat = 'D MMM YYYY'

  return (
    <Link
      {...{
        to: `/program/${encodeURI(data.id)}`,
        style: { textDecoration: 'none' },
      }}
    >
      <Card {...{ className: classes.card }}>
        <CardActionArea>
          <Box
            {...{
              className: classes.cardMediaWrapper,
              component: 'div',
            }}
          >
            <CardMedia
              {...{
                className: classes.cardMedia,
                component: 'img',
                image: './images/program_image.png',
                width: 100,
                height: 90,
                title: data?.program?.name,
                alt: data?.program?.name,
              }}
            />

            <Typography
              gutterBottom
              {...{
                variant: 'body1',
                component: 'h2',
              }}
            >
              {data?.program?.name}
            </Typography>
          </Box>

          <CardContent
            {...{
              className: classes.cardContent,
            }}
          >
            <Box
              {...{
                component: 'div',
                className: classes.programInfo,
              }}
            >
              <Box
                {...{
                  component: 'div',
                }}
              >
                <Typography
                  className={classes.small}
                  {...{
                    variant: 'body2',
                    component: 'span',
                  }}
                >
                  Start:
                </Typography>
                <Typography
                  className={classes.bold}
                  {...{
                    variant: 'body1',
                    component: 'p',
                  }}
                >
                  <Moment
                    {...{
                      format: dateFormat,
                      date: data?.starts_at,
                    }}
                  />
                </Typography>
              </Box>

              <Box
                {...{
                  component: 'div',
                }}
              >
                <Typography
                  className={classes.small}
                  {...{
                    variant: 'body2',
                    component: 'span',
                  }}
                >
                  Duration:
                </Typography>
                <Typography
                  className={classes.bold}
                  {...{
                    variant: 'body1',
                    component: 'p',
                  }}
                >
                  {`${data?.program?.duration_amount} ${data?.program?.duration_unit}`}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  )
}
