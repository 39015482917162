import { Avatar, Box } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import clsx from 'clsx'
import { Loading } from 'components/Loading'
import { useGetUserByIdQuery } from 'generated/graphql'
import * as React from 'react'
import { useMemo } from 'react'
import { useAuth } from 'utils/useAuth'

import { MenuList } from '../MenuList'
import { IDrawerHeaderProps, IDrawerProps } from './interfaces'
import { useStyles } from './styles'

const DrawerHeader: React.FunctionComponent<IDrawerHeaderProps> = ({ isOpen }) => {
  const classes = useStyles()
  const { userId } = useAuth()

  const { data, loading } = useGetUserByIdQuery({
    variables: {
      userId,
    },
    skip: userId === null,
  })

  const user = data?.users_by_pk

  return useMemo(() => {
    if (loading) return <Loading />

    const getAvatar = (): string => {
      const avatar = user?.avatar_url
      if (avatar && avatar.length > 0) return avatar
      return './images/user_photo.png'
    }

    const fullName = (): string => {
      const names = [user?.first_name, user?.last_name].filter((name) => name !== undefined || name !== '')
      if (names.length) return names.join(' ')
      return 'Naam onbekend'
    }

    return (
      <Box
        {...{
          component: 'div',
          className: clsx(classes.coachInfo, !isOpen && classes.coachInfoClose),
        }}
      >
        <Avatar
          {...{
            className: classes.large,
            src: getAvatar(),
            alt: 'Coach Avatar',
          }}
        />

        <Typography
          {...{
            variant: 'h5',
            style: { margin: '25px 0 10px', fontWeight: 500, fontSize: 20 },
          }}
        >
          {fullName()}
        </Typography>

        <Typography
          {...{
            variant: 'body1',
            style: { color: '#AEAEAE' },
          }}
        >
          Coach
        </Typography>
      </Box>
    )
  }, [
    classes.coachInfo,
    classes.coachInfoClose,
    classes.large,
    isOpen,
    loading,
    user?.avatar_url,
    user?.first_name,
    user?.last_name,
  ])
}

export const DrawerComp: React.FunctionComponent<IDrawerProps> = ({ isOpen, drawerHandle }) => {
  const classes = useStyles()

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !isOpen && classes.drawerPaperClose),
      }}
      open={isOpen}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={drawerHandle}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <DrawerHeader {...{ isOpen }} />
      <Divider />
      <MenuList />
    </Drawer>
  )
}
