import CssBaseline from '@material-ui/core/CssBaseline'
import * as React from 'react'

import { AppBarComponent } from '../components/AppBar'
import { IBreadcrumb } from '../components/AppBar/interfaces'
import { DrawerComp } from '../components/Drawer'
import useWindowDimensions from '../utils/useWindowDimensions'
import { useStyles } from './styles'

interface IProps {
  breadcrumbData: IBreadcrumb[]
}

export const WithDrawer: React.FunctionComponent<IProps> = ({ breadcrumbData, children }) => {
  const classes = useStyles()
  const { width } = useWindowDimensions()
  const [isOpen, setOpen] = React.useState(width >= 680)

  const drawerHandle = (): void => setOpen(!isOpen)

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarComponent
        {...{
          isOpen,
          drawerHandle,
          breadcrumbData,
        }}
      />
      <DrawerComp
        {...{
          isOpen,
          drawerHandle,
        }}
      />
      <main className={classes.main}>
        <div className={classes.appBarSpacer} />
        {children}
      </main>
    </div>
  )
}
