/* eslint-disable */

import * as React from 'react'

import {
  Box,
  Grid,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core'
import { useLocation, useParams } from 'react-router-dom'

import { ReactSortable } from 'react-sortablejs'

import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import groupBy from 'lodash/groupBy'

import { useSnackbar } from 'notistack'
import { round } from 'lodash'

import WithDrawer from '../../wrappers'
import { StyledTableCell, StyledTableRow } from '../../components/Table'
import AddNewMealToBlock from './components/AddNewMealToBlock'
import {
  useGetMealPlanQuery,
  useGetNutritionPlanByPkQuery,
  useDeleteNutritionMealByPkMutation,
  useDeleteNutritionMealProductByPkMutation,
  useUpdateNutritionMealProductByPkMutation,
  useUpdateNutritionMealByPkMutation,
  useUpdateOrderForMealProductMutation,
  useInsertNewProductMutation,
  useInsertNewMealMutation,
  useGetUserByIdQuery,
  useGetNutritionPlanMealsViewQuery,
} from '../../generated/graphql'

import { useStyles } from './useStyles'
import { useNutrition } from 'utils/useNutrition'
import { useConfirmation } from 'context/ConfirmationServiceContext'
import { MealSelector } from './MealSelector'
import { useMealPlan } from './useMealPlan'
import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { FaseOverview } from './FaseOverview'
import { CurrentIndicators } from './CurrentIndicators'
import { AddNutritionMealBlock } from './NutritionMeal/AddNutritionMealBlock'
import { EditNutritionMealBlock } from './NutritionMeal/EditNutritionMealBlock'
import { TEditName } from './interfaces'
import { TProduct } from './MealSelector/interfaces'

const defaultTotalValues = {
  fat_sum: 0,
  calorie_sum: 0,
  protein_sum: 0,
  carbo_sum: 0,
  calories_maintenance_per_day: 0,
  calories_per_day: 0,
  calories_weekly_reduction: 0,
  max_carbo: 0,
  max_fat: 0,
  max_protein: 0,
}

export const MealPlanPage = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirmation()
  const [toEditMeal, setToEditMeal] = React.useState<any>(null)
  const [mealPlan, setMealPlan] = React.useState<any[]>([])
  const [total, setTotalMealDetails] = React.useState<any>(defaultTotalValues)
  const params: any = useLocation()
  const { nutritionPlanId } = useParams<{ nutritionPlanId: string }>()
  const [isMealBlockDisplayed, setMealBlockStatus] = React.useState<boolean>(false)

  // NEW HOOK TO REDUCE CODE IN THUS FILE
  const { nutritionPlanMealsId } = useMealPlan()

  const [selectedProduct, setSelectedProduct] = React.useState<TProduct>()
  const [editName, setEditName] = React.useState<TEditName>({ index: 0, active: false, value: '' })
  const selectProductHandler = (product: TProduct) => {
    setSelectedProduct(product)
  }

  const nutritionPlan = useGetNutritionPlanByPkQuery({
    variables: { planId: +nutritionPlanId },
  })

  const { data: plan } = nutritionPlan

  const { nutritions: foodComposition } = useNutrition(params?.state?.userId)

  React.useEffect(() => {
    if (selectedProduct) {
      const { calories, carbohydrates, proteins, fats, units } = selectedProduct
      const quantity = toEditMeal?.quantity
      const newValues = {
        calories: round(calories * quantity, 1),
        carbohydrates: round(carbohydrates * quantity, 1),
        fats: round(fats * quantity, 1),
        proteins: round(proteins * quantity, 1),
        units: selectedProduct.units,
      }

      setToEditMeal((prevState: any) => ({ ...prevState, ...newValues }))
    }
  }, [selectedProduct])

  const userQuery = useGetUserByIdQuery({
    variables: { userId: parseInt(params?.state?.userId) },
  })

  const { data: userQueryResult } = userQuery
  const name = `${userQueryResult?.users_by_pk?.first_name} ${userQueryResult?.users_by_pk?.last_name}`

  const mealsPlanQuery = useGetMealPlanQuery({
    variables: {
      userId: params?.state?.userId,
      startDate: params?.state?.startDate,
      endDate: params?.state?.endDate,
    },
  })

  const { data } = mealsPlanQuery

  const detailsQuery = useGetNutritionPlanMealsViewQuery({
    variables: {
      userId: params?.state?.userId,
      nutritionPlanId: params?.state?.planId,
    },
  })

  const details = detailsQuery.data

  const [deleteNutritionMeal] = useDeleteNutritionMealByPkMutation()
  const [updateNutritionMealProduct] = useUpdateNutritionMealProductByPkMutation()
  const [updateNutritionMeal] = useUpdateNutritionMealByPkMutation()
  const [deleteMeal] = useDeleteNutritionMealProductByPkMutation()
  const [updateMealOrder] = useUpdateOrderForMealProductMutation()
  const [insertNewProduct] = useInsertNewProductMutation()
  const [insertNewMeal] = useInsertNewMealMutation()

  const groupMeals = (meals: any) => groupBy(meals, 'nutrition_meal_order')

  React.useEffect(() => {
    if (data?.meals_view?.length && !toEditMeal) {
      // @ts-ignore  update structure
      const result = Object.entries(groupMeals(data.meals_view)).map((item) => {
        const meal = item[1][0]
        const sorted = item[1]
          .filter((a: any) => a.nutrition_meal_product_id !== null)
          .sort((a, b) => a.nutrition_meal_product_order - b.nutrition_meal_product_order)
        return {
          name: meal.meal_name,
          id: meal.nutrition_meal_id,
          order: meal.nutrition_meal_order,
          values: sorted,
        }
      })
      // @ts-ignore
      setMealPlan(result)
    }
  }, [data, toEditMeal])

  React.useEffect(() => {
    const npmv = details?.nutrition_plan_meals_view
    if (npmv && npmv.length > 0) {
      // @ts-ignore  update structure
      const result = npmv[0]
      // @ts-ignore
      setTotalMealDetails(result)
    }
  }, [details])

  const _onRemoveMeal = (meal: any) => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'You are planning to delete a single meal',
      description: 'Are you sure you want to do this?',
    }).then(() => {
      deleteMeal({
        variables: {
          id: meal.nutrition_meal_product_id,
        },
      })
        .then(() => {
          enqueueSnackbar('Deleted successfully', { variant: 'success' })
          detailsQuery.refetch()
          mealsPlanQuery.refetch()
        })
        .catch(console.log)
    })
  }

  const _onFinishAddNewMealBlock = (): void => {
    setMealBlockStatus(false)
  }

  const _onAddNewMealBlock = (newMealName: string): void => {
    if (!nutritionPlanMealsId)
      return alert(
        'nutritionPlanMealsId is undefined. This value is being set when creating a nutrition plan. Please make a new plan to solve this issue.'
      )

    if (newMealName.trim().length === 0) return window.alert('Maaltijd naam is verplicht')
    let lastOrderValue = mealPlan[mealPlan.length - 1]?.order + 1
    if (mealPlan.length === 0) lastOrderValue = 0
    if (lastOrderValue === undefined || isNaN(lastOrderValue)) return
    insertNewMeal({
      variables: {
        name: newMealName,
        order: lastOrderValue + 1,
        nutrition_plan_meals_id: nutritionPlanMealsId,
      },
    })
      .then(() => {
        detailsQuery.refetch()
        mealsPlanQuery.refetch()
        enqueueSnackbar('Saved successfully', { variant: 'success' })
        _onFinishAddNewMealBlock()
      })
      .catch(console.log)
      .finally(() => _onFinishAddNewMealBlock())
  }

  const _onSaveNewProduct = (product: any, item: any): void => {
    insertNewProduct({
      variables: {
        product_id: product.product_id,
        quantity: product.quantity,
        order: item.values.length,
        nutrition_meal_id: item.id,
      },
    })
      .then(() => {
        detailsQuery.refetch()
        mealsPlanQuery.refetch()
      })
      .catch(console.log)
  }

  const _onDragSortEnd = (index: number, array: any[]): void => {
    const _isOrderChanged = (array1: any[], array2: any[]): boolean => {
      return array1.some((item, index) => item.nutrition_meal_product_id !== array2[index].nutrition_meal_product_id)
    }

    if (_isOrderChanged(array, mealPlan[index].values)) {
      const promises = array.map(async (item, index) => {
        return await updateMealOrder({
          variables: {
            nutritionMealProductId: item.nutrition_meal_product_id,
            newOrder: index,
          },
        })
          .then(() => console.log('Order for', item, 'is changed'))
          .catch(console.log)
      })

      Promise.all(promises)
        .then(() => enqueueSnackbar('Order changed successfully', { variant: 'success' }))
        .catch(console.log)
        .finally(() => {
          detailsQuery.refetch()
          mealsPlanQuery.refetch()
        })
    }

    const _mealPlanCopy = [...mealPlan]
    _mealPlanCopy[index] = { ..._mealPlanCopy[index], values: array }
    setMealPlan(_mealPlanCopy)
  }

  const breadcrumbData: IBreadcrumb[] = [
    { isLink: true, to: '/clients', name: 'Members' },
    {
      isLink: true,
      to: `/user/${params?.state?.userId}`,
      name: name ?? 'Unknown',
    },
    { isLink: false, name: `Huidige fase: ${params?.state?.sequence}` },
  ]

  const indicatorsHandler = (value: any) => {
    const result = details?.nutrition_plan_meals_view
    if (result && result.length > 0) {
      setTotalMealDetails((prevState: any) => ({
        ...prevState,
        fat_sum: result[0].fat_sum + value.fats,
        calorie_sum: result[0].calorie_sum + value.calories,
        protein_sum: result[0].protein_sum + value.proteins,
        carbo_sum: result[0].carbo_sum + value.carbohydrates,
      }))
    }
  }

  const updateMealNameHandler = (meal: any) => {
    if (editName.value.trim().length) {
      updateNutritionMeal({
        variables: {
          id: meal.id,
          name: editName.value,
        },
      })
        .then(() => {
          enqueueSnackbar('Saved successfully', { variant: 'success' })
          mealsPlanQuery.refetch()
          setEditName({ index: 0, active: false, value: '' })
        })
        .catch(console.log)
    }
  }

  const deleteMealPlanHandler = (mealPlan: any) => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'You are planning to delete a meal',
      description: 'Are you sure you want to do this?',
    }).then(() => {
      deleteNutritionMeal({
        variables: {
          id: mealPlan.id,
        },
      })
        .then(() => {
          enqueueSnackbar('Deleted successfully', { variant: 'success' })
          detailsQuery.refetch()
          mealsPlanQuery.refetch()
        })
        .catch(console.log)
    })
  }

  // Edit Table Row Code

  const onQuantityChangeHandler = (e: any) => {
    const newQuantity = e.target.value
    if (newQuantity < 1) return
    const newValues = {
      calories: round((toEditMeal.calories / toEditMeal.quantity) * newQuantity, 1),
      carbohydrates: round((toEditMeal.carbohydrates / toEditMeal.quantity) * newQuantity, 1),
      fats: round((toEditMeal.fats / toEditMeal.quantity) * newQuantity, 1),
      proteins: round((toEditMeal.proteins / toEditMeal.quantity) * newQuantity, 1),
      quantity: newQuantity,
    }

    setToEditMeal((prevState: any) => ({ ...prevState, ...newValues }))
  }

  React.useEffect(() => {
    if (toEditMeal) {
      const groupedMeals = mealPlan.find((i) => i.id === toEditMeal.nutrition_meal_id)
      const meals = groupedMeals['values'].map((i: any) => {
        if (i.nutrition_meal_product_id === toEditMeal.nutrition_meal_product_id) {
          return toEditMeal
        }
        return i
      })
      const updatedMealPlan = mealPlan.map((i: any) => {
        const containsName = meals.some((other: any) => other.nutrition_meal_id === i.id)
        if (containsName) return { ...i, values: meals }
        return i
      })

      setMealPlan(updatedMealPlan)
    }
  }, [toEditMeal])

  const onSaveMealChangesHandler = () => {
    const { quantity, nutrition_meal_product_id } = toEditMeal!
    const productId = selectedProduct?.id || toEditMeal.product_id
    if (!productId) return alert('No product selected.')
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'You are planning to update a single meal',
      description: 'Are you sure you want to do this?',
    }).then(() => {
      updateNutritionMealProduct({
        variables: {
          id: nutrition_meal_product_id,
          productId,
          quantity,
        },
      })
        .then(() => {
          enqueueSnackbar('Updated successfully', { variant: 'success' })
          cancelEditMealRowHandler()
        })
        .catch(console.log)
    })
  }

  const cancelEditMealRowHandler = () => {
    setToEditMeal(null)
    mealsPlanQuery.refetch()
    detailsQuery.refetch()
  }

  React.useEffect(() => {
    const allMeals = mealPlan.map((item: any) => item.values).flat(1)
    if (allMeals?.length) {
      const totalCalories = allMeals.reduce((currentTotal: number, item: any) => {
        return item.calories + currentTotal
      }, 0)
      const totalCarbohydrates = allMeals.reduce((currentTotal: number, item: any) => {
        return item.carbohydrates + currentTotal
      }, 0)
      const totalFats = allMeals.reduce((currentTotal: number, item: any) => {
        return item.fats + currentTotal
      }, 0)
      const totalProteins = allMeals.reduce((currentTotal: number, item: any) => {
        return item.proteins + currentTotal
      }, 0)
      setTotalMealDetails((prevState: any) => ({
        ...prevState,
        carbo_sum: totalCarbohydrates,
        protein_sum: totalProteins,
        calorie_sum: totalCalories,
        fat_sum: totalFats,
      }))
    }
  }, [mealPlan])

  return (
    <React.Fragment>
      <WithDrawer breadcrumbData={breadcrumbData}>
        <Box
          {...{
            component: 'div',
            className: classes.container,
          }}
        >
          <Grid container spacing={3}>
            <Grid item sm={12} md={5}>
              <FaseOverview
                caloriesPerDay={plan?.nutrition_plans_by_pk?.calories_per_day ?? '-'}
                foodComposition={foodComposition}
                params={params}
              />
            </Grid>

            <Grid item sm={12} md={7}>
              <CurrentIndicators
                caloriesPerDay={plan?.nutrition_plans_by_pk?.calories_per_day ?? '-'}
                total={total}
                details={details}
              />
            </Grid>
          </Grid>

          <AddNutritionMealBlock
            _onAddNewMealBlock={_onAddNewMealBlock}
            _onFinishAddNewMealBlock={_onFinishAddNewMealBlock}
            isMealBlockDisplayed={isMealBlockDisplayed}
            setMealBlockStatus={() => setMealBlockStatus(true)}
          />

          {Boolean(mealPlan) &&
            mealPlan.length &&
            mealPlan.map((item, idx) => {
              return (
                <div key={idx}>
                  <EditNutritionMealBlock
                    onDelete={() => deleteMealPlanHandler(item)}
                    item={item}
                    editName={editName}
                    setEditName={setEditName}
                    idx={idx}
                    onSave={() => updateMealNameHandler(item)}
                  />
                  <Grid container className={classes.tableContainer}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Naam:</StyledTableCell>
                            <StyledTableCell>Hoeveelheid:</StyledTableCell>
                            <StyledTableCell>Eenheid:</StyledTableCell>
                            <StyledTableCell>Calories:</StyledTableCell>
                            <StyledTableCell>Carbs:</StyledTableCell>
                            <StyledTableCell>Fats:</StyledTableCell>
                            <StyledTableCell>Protein:</StyledTableCell>
                            <StyledTableCell />
                          </TableRow>
                        </TableHead>
                        <ReactSortable
                          tag={'tbody'}
                          handle={'.sort-icon'}
                          ghostClass={'.sortable-ghost'}
                          list={item.values}
                          setList={(array) => {
                            _onDragSortEnd(idx, array)
                          }}
                        >
                          {item.values.map((value: any, index: number) => {
                            if (value.nutrition_meal_product_id === toEditMeal?.nutrition_meal_product_id) {
                              return (
                                <StyledTableRow key={`edit-mealProduct-${index}`}>
                                  <StyledTableCell>
                                    <MealSelector productId={toEditMeal?.product_id} onChange={selectProductHandler} />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      style={{ maxWidth: 65 }}
                                      type="number"
                                      name="quantity"
                                      value={value.quantity}
                                      onChange={onQuantityChangeHandler}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>{value.units}</StyledTableCell>
                                  <StyledTableCell>{value.calories}</StyledTableCell>
                                  <StyledTableCell>{value.carbohydrates}</StyledTableCell>
                                  <StyledTableCell>{value.fats}</StyledTableCell>
                                  <StyledTableCell>{value.proteins}</StyledTableCell>
                                  <StyledTableCell className={classes.actionsCell} align="right">
                                    <div className={classes.tableIconWrapper}>
                                      <Button color="primary" onClick={onSaveMealChangesHandler}>
                                        Opslaan
                                      </Button>
                                      <Button onClick={cancelEditMealRowHandler}>Annuleren</Button>
                                    </div>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            } else {
                              return (
                                <StyledTableRow key={`mealProduct-${index}`}>
                                  <StyledTableCell>{value.product_name}</StyledTableCell>
                                  <StyledTableCell>{value.quantity}</StyledTableCell>
                                  <StyledTableCell>{value.units}</StyledTableCell>
                                  <StyledTableCell>{value.calories}</StyledTableCell>
                                  <StyledTableCell>{value.carbohydrates}</StyledTableCell>
                                  <StyledTableCell>{value.fats}</StyledTableCell>
                                  <StyledTableCell>{value.proteins}</StyledTableCell>
                                  <StyledTableCell className={classes.actionsCell} align="right">
                                    <div className={classes.tableIconWrapper}>
                                      <IconButton onClick={() => setToEditMeal(value)} aria-haspopup="true">
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton aria-haspopup="true" onClick={() => _onRemoveMeal(value)}>
                                        <DeleteIcon />
                                      </IconButton>

                                      <IconButton
                                        aria-label="more"
                                        aria-controls="action-menu"
                                        aria-haspopup="true"
                                        className={'sort-icon'}
                                      >
                                        <DragIndicatorIcon />
                                      </IconButton>
                                    </div>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            }
                          })}
                        </ReactSortable>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <div style={{}}>
                    <AddNewMealToBlock
                      updateIndicators={indicatorsHandler}
                      _onSaveNewProduct={(data) => _onSaveNewProduct(data, item)}
                    />
                  </div>
                </div>
              )
            })}
        </Box>
      </WithDrawer>
    </React.Fragment>
  )
}
