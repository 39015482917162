import { makeStyles } from '@material-ui/core/styles'

const justifyContent = 'space-between'
export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  card: {
    padding: 20,
    height: '100%',
  },
  cardHead: {
    textTransform: 'uppercase',
    fontSize: 18,
    marginBottom: 10,
  },
  cardLine: {
    display: 'flex',
    justifyContent,
    alignItems: 'baseline',
  },
  goalName: {
    fontSize: '12px',
    color: '#AEAEAE',
  },
  cardPropertyName: {
    color: '#AEAEAE',
  },
  progressBarContainer: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  indicatorName: {
    minWidth: 110,
  },
  indicatorValue: {
    minWidth: 90,
  },
  buttonsContainer: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  outlinedBtn: {
    backgroundColor: '#fff',
    color: '#3F51B5',
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    border: '1px solid #3F51B5',

    marginLeft: 10,
  },
  containedBtn: {
    backgroundColor: '#3F51B5',
    color: '#fff',
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    marginLeft: 10,
  },
  tableContainer: {
    marginTop: 30,
  },
  actionsCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tableIconWrapper: {
    marginLeft: 15,
  },
  tableName: {
    marginTop: 20,
  },
  mealBlockContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent,
    marginTop: 40,
  },
  mealBlockWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent,
  },
  mealBlockInput: {
    marginLeft: '20px',
    padding: '2px 14px',
    minWidth: '250px',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: '#EEEEF3',
    '& div::before': {
      border: 'none !important',
    },
    '& div::after': {
      border: 'none !important',
    },
    '& input': {
      fontSize: 12,
      padding: '10px 0',
    },
  },
}))
