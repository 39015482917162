import { useParams } from 'react-router'

import { useGetNutritionPlanNutritionMealQuery } from '../../generated/graphql'
import { IUseMealPlanHook } from './interfaces'

export const useMealPlan = (): IUseMealPlanHook => {
  const { nutritionPlanId } = useParams<{ nutritionPlanId: string }>()

  const { data, loading } = useGetNutritionPlanNutritionMealQuery({
    variables: {
      planId: +nutritionPlanId,
    },
    skip: nutritionPlanId === null,
  })

  const nutritionPlanMealsId = data?.nutrition_plan_meals[0]?.id

  return { nutritionPlanMealsId, loading }
}
