import { Avatar, Box, Container, debounce, Grid, Paper, Typography } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import { AvatarGroup } from '@material-ui/lab'
// MUI
import clsx from 'clsx'
import * as React from 'react'
import Skeleton from 'react-loading-skeleton'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { SearchPanel } from '../../components/SearchPanel'
import { useGetUsersLikeLazyQuery } from '../../generated/graphql'
import WithDrawer from '../../wrappers'
import { MemberCard } from './MemberCard'
import { useStyles } from './styles'

export const Dashboard: React.FunctionComponent = () => {
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper)
  const breadcrumbData: IBreadcrumb[] = [{ isLink: false, name: 'Dashboard' }]

  const [getUsersLike, { data, loading }] = useGetUsersLikeLazyQuery()

  const searchHandler = debounce((newValue: string): void => {
    if (!newValue.length) return
    getUsersLike({
      variables: {
        _like: `%${newValue}%`,
      },
    })
  }, 750)

  return (
    <WithDrawer breadcrumbData={breadcrumbData}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SearchPanel onSubmit={searchHandler} placeholder="Search client" />
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <Paper
              elevation={0}
              {...{
                className: fixedHeightPaper,
                style: {
                  backgroundColor: '#3F51B5',
                  display: 'flex',
                  position: 'relative',
                  overflow: 'unset',
                  minHeight: 180,
                },
              }}
            >
              <Box
                {...{
                  component: 'div',
                  className: classes.welcomeContentWrapper,
                }}
              >
                <Typography component="h1" variant="h3" style={{ marginBottom: 15, fontSize: 30, fontWeight: 700 }}>
                  Welcome back!
                </Typography>

                <Typography component="p" variant="body1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat.
                </Typography>
              </Box>

              <Box
                {...{
                  component: 'div',
                  style: {
                    position: 'absolute',
                    right: 0,
                    top: -20,
                  },
                }}
              >
                <img src="./images/dashboard_welcome.png" alt="" />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Paper elevation={0} className={fixedHeightPaper} style={{ minHeight: 180 }}>
              <Typography component="p" variant="body1" className={classes.activeMembersCount}>
                <GroupIcon />
                642
              </Typography>

              <Typography component="p" variant="body1">
                members, 168 members are active
              </Typography>

              <AvatarGroup max={4} className={classes.activeMembersPhotosWrapper}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
              </AvatarGroup>
            </Paper>
          </Grid>

          {loading && (
            <Grid container>
              <Skeleton style={{ marginRight: 10 }} width={100} height={100} count={3} />
            </Grid>
          )}

          {data && data.users.length > 0 && (
            <React.Fragment>
              <Typography component="h3" variant="h6">
                Gebruikers
              </Typography>

              <Grid container>
                {data.users.map((user, idx) => {
                  return (
                    <Grid item xs={6} md={3} lg={3} key={idx}>
                      <MemberCard user={user} />
                    </Grid>
                  )
                })}
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Container>
    </WithDrawer>
  )
}
