import * as React from 'react'

interface ITabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export const TabPanel: React.FunctionComponent<ITabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`scrollable-auto-tabpanel-${index}`}
    aria-labelledby={`scrollable-auto-tab-${index}`}
    {...other}
  >
    {value === index && <React.Fragment>{children}</React.Fragment>}
  </div>
)
