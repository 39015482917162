import { TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ContainerModal from '../../../components/ContainerModal'
import { TKnowledgeBaseVideos } from '../interfaces'
import { useStyles } from '../styles'
import { useKnowledgeBaseVideos } from '../useKnowledgeBaseVideos'

interface IProps {
  closeModal: () => void
  item?: TKnowledgeBaseVideos[0] | undefined
}

const Modal: React.FC<IProps> = ({ closeModal, item }) => {
  const classes = useStyles()
  const { insert, updateById, isLoading } = useKnowledgeBaseVideos(closeModal)
  const { categoryId } = useParams<{ categoryId: string }>()

  const [title, setTitle] = useState<string>('')
  const [videoUrl, setVideoUrl] = useState<string>('')
  const [duration, setDuration] = useState<number>(0)
  const [sequence, setSequence] = useState<number>(0)

  useEffect(() => {
    if (item) {
      setTitle(item.title)
      setVideoUrl(item.video_url)
      setDuration(item.duration_in_minutes)
      setSequence(item.sequence)
    }
  }, [item])

  const onSave = (): void => {
    if (item) updateById({ id: item.id, title, sequence, duration, videoUrl, knowledgeBaseId: item.knowledge_base_id })
    else insert({ title, sequence, duration, knowledgeBaseId: +categoryId, videoUrl })
  }

  const isEmpty = (value: string): boolean => {
    return value.trim().length === 0
  }

  const disableConfirmButton = isLoading || isEmpty(title) || isEmpty(videoUrl) || sequence === 0 || duration === 0

  return (
    <ContainerModal
      title={item ? 'Wijzigen' : 'Toevoegen'}
      isOpen
      handleClose={closeModal}
      handleSubmit={onSave}
      disabled={disableConfirmButton}
    >
      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Titel*: </Typography>
        <TextField
          multiline
          rows={3}
          variant="outlined"
          size="small"
          value={title}
          onChange={(event): void => {
            setTitle(event.target.value)
          }}
        />
      </div>

      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Vimdeo url*: </Typography>
        <TextField
          multiline
          rows={3}
          variant="outlined"
          size="small"
          value={videoUrl}
          onChange={(event): void => setVideoUrl(event.target.value)}
        />
      </div>

      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Week*: </Typography>
        <TextField
          multiline
          rows={1}
          type="number"
          variant="outlined"
          size="small"
          value={sequence}
          onChange={(event): void => setSequence(Number(event.target.value))}
        />
      </div>

      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Duur (min)*: </Typography>
        <TextField
          multiline
          rows={1}
          type="number"
          variant="outlined"
          size="small"
          value={duration}
          onChange={(event): void => setDuration(Number(event.target.value))}
        />
      </div>
    </ContainerModal>
  )
}

export default Modal
