import CookbookRecipesPage from 'pages/CookbookRecipesPage'
import { MealPlanClone } from 'pages/MealPlanClone'
import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import BlogArticlesPage from '../pages/BlogArticlesPage'
import BlogPage from '../pages/BlogPage'
import CookbookPage from '../pages/CookbookPage'
// PAGES
import Dashboard from '../pages/Dashboard'
import ExercisesProgress from '../pages/ExercisesProgress'
import InboxPage from '../pages/Inbox'
import KnowledgeBasePage from '../pages/KnowledgeBase'
import KnowledgeBaseVideosPage from '../pages/KnowledgeBaseVideos'
import MembersPage from '../pages/MembersPage'
import Products from '../pages/Products'
import ProfilePage from '../pages/ProfilePage'
import ProgramPage from '../pages/ProgramPage'
import TrainingSchemaItem from '../pages/TrainingSchemaItem'
import MealPlanPage from '../pages/UserDetailedMealPlanPage'
import UserDetailedPage from '../pages/UserDetailedPage'
import ROUTES from '../routes'
import PrivateRoute from './SecuredRoute'

export const useRoutes = (): JSX.Element => {
  return (
    <Switch>
      <Route {...{ path: ROUTES.DASHBOARD, exact: true }}>
        <Dashboard />
      </Route>
      <Route {...{ path: ROUTES.MEMBERS, exact: true }}>
        <MembersPage />
      </Route>
      <Route path={ROUTES.INBOX} exact>
        <InboxPage />
      </Route>
      <Route {...{ path: ROUTES.PROFILE, exact: true }}>
        <ProfilePage />
      </Route>
      <Route {...{ path: '/program/:programId' }}>
        <ProgramPage />
      </Route>
      <Route
        {...{
          path: '/user/:userId/meal-plan/clone/:nutritionPlanMealId',
          exact: true,
        }}
      >
        <MealPlanClone />
      </Route>
      <Route {...{ path: '/user/:userId/meal-plan/:nutritionPlanId' }}>
        <MealPlanPage />
      </Route>
      <Route path="/user/:userId/training-schema/:workoutPlanId/progress" exact>
        <ExercisesProgress />
      </Route>
      <Route {...{ path: '/user/:userId/training-schema/:workoutPlanId' }}>
        <TrainingSchemaItem />
      </Route>

      <Route {...{ path: '/user/:userId' }}>
        <UserDetailedPage />
      </Route>
      <PrivateRoute path="/cookbook" requiredRoles={['admin']} exact>
        <CookbookPage />
      </PrivateRoute>
      <PrivateRoute path="/blog" requiredRoles={['admin']} exact>
        <BlogPage />
      </PrivateRoute>
      <PrivateRoute path="/blog/:categoryId" requiredRoles={['admin']}>
        <BlogArticlesPage />
      </PrivateRoute>
      <PrivateRoute path="/cookbook/:categoryId" requiredRoles={['admin']}>
        <CookbookRecipesPage />
      </PrivateRoute>
      <PrivateRoute path="/products" requiredRoles={['admin']}>
        <Products />
      </PrivateRoute>
      <PrivateRoute path="/knowledge_base/:categoryId" requiredRoles={['admin']}>
        <KnowledgeBaseVideosPage />
      </PrivateRoute>
      <PrivateRoute path="/knowledge_base" requiredRoles={['admin']}>
        <KnowledgeBasePage />
      </PrivateRoute>
      <Redirect {...{ to: ROUTES.DASHBOARD }} />
    </Switch>
  )
}
