import { Paper, Select } from '@material-ui/core'
import clsx from 'clsx'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-simple-flex-grid'

import {
  IDiaryQuestionCategoriesEnum,
  IDiaryQuestionTypesEnum,
  useGetDiaryAnswersQuery,
} from '../../../../generated/graphql'
import { getHumanizedName } from './helpers'
import { IProps, TCategories, TDiaryAnswer } from './interfaces'
import { QuestionAnswerBlock } from './QuestionAnswerBlock'
import { QuestionAnswerMotivationBlock } from './QuestionAnswerMotivationBlock'
import { useStyles } from './useStyles'

// eslint-disable-next-line sonarjs/cognitive-complexity
export const DiaryTab: React.FC<IProps> = ({ userInfo }): JSX.Element => {
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)
  const [selectedDate, setSelectedDate] = useState<{ label: string; value: string }>()

  const { data } = useGetDiaryAnswersQuery({
    variables: {
      userId: userInfo.id,
    },
  })

  const answers = data?.diary_answers

  const answersMappedByDate =
    answers?.reduce<{ [date: string]: TCategories }>((acc, obj) => {
      const date = format(new Date(obj.created_at), 'yyyy-MM-dd')

      // Parent ex: ["2021-01-01"] : { ... }
      const parent = acc[date]

      // Child ex: [answer1, answer2] of a specific category
      const child = parent?.[obj.diary_question.category]

      // Asign new date with category and its answer
      if (!parent) return Object.assign(acc, { [date]: { [obj.diary_question.category]: [obj] } })

      // Assign answer to related category
      Object.assign(parent, { [obj.diary_question.category]: child ? [...child, obj] : [obj] })

      return acc
    }, {}) || {}

  const options = Object.keys(answersMappedByDate).map((item) => ({ label: item, value: item }))

  useEffect(() => {
    if (!selectedDate && options.length > 0) setSelectedDate(options[0])
  }, [options, selectedDate])

  const onChange = (item: any): void => {
    const { value } = item.target
    setSelectedDate({ label: value, value })
  }
  const categories = selectedDate ? answersMappedByDate[selectedDate.value] : {}

  const render = (): JSX.Element => {
    if (options.length === 0) return <p>Gebruiker heeft nog geen dagboek.</p>

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
          <p style={{ marginRight: 10 }}>Selecteer een datum</p>
          <Select
            name={name}
            margin="dense"
            native
            variant="outlined"
            defaultValue={selectedDate}
            // @ts-ignore
            onChange={onChange}
          >
            {options?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {selectedDate && categories && (
            <div>
              {Object.keys(categories).map((category) => {
                const answersOfCategory = categories[category]

                return (
                  <Row
                    key={category}
                    style={{
                      width: '100%',
                      border: '1px solid #e3e3e3',
                      padding: 10,
                      marginBottom: '1em',
                    }}
                  >
                    {answersOfCategory.map((answer: TDiaryAnswer, answerIndex) => {
                      return (
                        <React.Fragment key={`answer-${answerIndex}`}>
                          {answerIndex === 0 && (
                            <Col span={12}>
                              <p style={{ margin: 0, color: 'BLUE' }}>
                                {getHumanizedName(category as IDiaryQuestionCategoriesEnum)}
                              </p>
                            </Col>
                          )}
                          {answer.diary_question.type === IDiaryQuestionTypesEnum.SINGLE_CHOICE_WITH_MOTIVATION ? (
                            <QuestionAnswerMotivationBlock answer={answer} />
                          ) : (
                            <QuestionAnswerBlock answer={answer} />
                          )}
                        </React.Fragment>
                      )
                    })}
                  </Row>
                )
              })}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <Paper
      {...{
        elevation: 0,
        className: fixedHeightPaper,
      }}
    >
      {render()}
    </Paper>
  )
}
