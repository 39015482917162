import { Button, Paper } from '@material-ui/core'
import clsx from 'clsx'
import { InputSelectTabs } from 'components/InputSelectTabs'
import { useInsertUserProfilesMutation } from 'generated/graphql'
import { useSnackbar } from 'notistack'
import React from 'react'

import { TUser } from '../../interfaces'
import { AlgemeneGegevensTab } from './components/AlgemeneGegevensTab'
import IntakeTab from './components/IntakeTab'
import ProgrammaTab from './components/ProgrammaTab'
import VoedingsTab from './components/VoedingsTab'
import { useStyles } from './useStyles'

interface IVolledigProfielTabProps {
  userInfo: TUser
}

const filterOptions = [
  { label: 'Algemene gegevens', value: '0' },
  { label: 'Intake', value: '1' },
  { label: 'Voeding & Lifestyle', value: '2' },
  { label: 'Het programma', value: '3' },
]

const INITIAL_STATE = [
  { tab: '0', active: false },
  { tab: '1', active: false },
  { tab: '2', active: false },
  { tab: '3', active: false },
]

export const VolledigProfielTab: React.FunctionComponent<IVolledigProfielTabProps> = ({ userInfo }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)
  const [filter, setFilter] = React.useState(filterOptions[0].value)
  const [editMode, setEditMode] = React.useState<{ tab: string; active: boolean }[]>(INITIAL_STATE)

  const [insertUserProfile] = useInsertUserProfilesMutation()

  const onEditHandler = (): void => {
    const newList = editMode.map((object) => {
      if (object.tab === filter) return { ...object, active: !object.active }

      return object
    })
    setEditMode(newList)
  }

  const onCreateProfile = (): void => {
    if (!userInfo?.id) return window.alert('Unable to create user due to lack of user_id.')

    insertUserProfile({
      variables: {
        objects: [
          {
            user_id: userInfo.id,
          },
        ],
      },
    })
      .then(() => {
        enqueueSnackbar('Saved successfully', { variant: 'success' })
      })
      .catch(console.error)
  }

  const editModeValue = editMode.find((obj) => obj.tab === filter)?.active ?? INITIAL_STATE[0].active

  return (
    <Paper
      {...{
        elevation: 0,
        className: fixedHeightPaper,
        style: {
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
        },
      }}
    >
      {!userInfo?.user_profile ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <span style={{ marginBottom: 20 }}>Er is geen profiel gevonden voor deze gebruiker.</span>
          <Button variant="contained" color="primary" onClick={onCreateProfile} disableElevation>
            Maak profiel aan
          </Button>
        </div>
      ) : (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 50,
            }}
          >
            <InputSelectTabs
              value={filter}
              onChange={(newValue): void => setFilter(newValue)}
              options={filterOptions}
            />

            <Button onClick={onEditHandler} color="primary" size="small" variant="outlined">
              Bewerken
            </Button>
          </div>

          {filter === '0' && (
            <AlgemeneGegevensTab userInfo={userInfo} onClose={onEditHandler} editMode={editModeValue} />
          )}

          {filter === '1' && <IntakeTab userInfo={userInfo} onClose={onEditHandler} editMode={editModeValue} />}

          {filter === '2' && <VoedingsTab userInfo={userInfo} onClose={onEditHandler} editMode={editModeValue} />}

          {filter === '3' && <ProgrammaTab userInfo={userInfo} onClose={onEditHandler} editMode={editModeValue} />}
        </React.Fragment>
      )}
    </Paper>
  )
}

export default VolledigProfielTab
