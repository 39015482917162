import { Typography } from '@material-ui/core'
import React from 'react'
import { Col } from 'react-simple-flex-grid'

import { TDiaryAnswer } from '../interfaces'

interface IProps {
  answer: TDiaryAnswer
}

export const QuestionAnswerMotivationBlock: React.FC<IProps> = ({ answer }): JSX.Element => {
  return (
    <Col xs={12} sm={12} md={6}>
      <div style={{ marginBottom: '0.5em' }}>
        <Typography
          style={{
            fontWeight: 500,
          }}
        >
          {answer.diary_question.question}
        </Typography>
        <span>{`${answer.answer} ${answer.motivation ? `: ${answer.motivation}` : ''}`}</span>
      </div>
    </Col>
  )
}
