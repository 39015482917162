export const SORTABLE_COLUMNS = {
  start_date: {
    value: 'start_date',
    label: 'Start datum',
  },
  end_date: {
    value: 'end_date',
    label: 'Eind datum',
  },
  calories_per_day: {
    value: 'calories_per_day',
    label: 'Dagelijkse calor',
  },
  protein: {
    value: 'protein',
    label: 'Eiwitten',
  },
  fats: {
    value: 'fats',
    label: 'Vetten',
  },
}
