import { Typography } from '@material-ui/core'
import React from 'react'

import { useStyles } from '../../../useStyles'
import Switchable from '../../Switchable'
import { IProps } from './interfaces'

export const Form: React.FC<IProps> = ({ userInfo, editMode, onChange, fields }): JSX.Element => {
  const classes = useStyles()

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'wrap',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div className={classes.labelValueBox}>
          <Typography className={classes.labels}>Naam:</Typography>
          <Switchable
            type="text"
            defaultValue={userInfo.first_name}
            onChange={onChange}
            isEditable={editMode}
            name="user.first_name"
            disabled
          />
        </div>
        <div className={classes.labelValueBox}>
          <Typography className={classes.labels}>Achternaam:</Typography>
          <Switchable
            type="text"
            defaultValue={userInfo.last_name}
            onChange={onChange}
            isEditable={editMode}
            name="user.last_name"
            disabled
          />
        </div>
        <div className={classes.labelValueBox}>
          <Typography className={classes.labels}>E-mail:</Typography>
          <Switchable
            type="text"
            defaultValue={userInfo.email}
            onChange={onChange}
            isEditable={editMode}
            name="user.email"
            disabled
          />
        </div>
        <div className={classes.labelValueBox}>
          <Typography className={classes.labels}>Coach:</Typography>
          <Switchable
            type="select"
            defaultValue={userInfo.user_profile?.coach_id}
            onChange={(): void => {}}
            isEditable={editMode}
            name="user.user_profile.coach_id"
            disabled
            options={[
              {
                label: userInfo.user_profile?.current_coach?.first_name,
                value: userInfo.user_profile?.coach_id,
              },
            ]}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div className={classes.labelValueBox}>
          <Typography className={classes.labels}>Geslacht:</Typography>
          <Switchable
            type="select"
            defaultValue={fields.user.gender}
            onChange={onChange}
            isEditable={editMode}
            name="user.gender"
            options={[
              { label: 'Man', value: 'M' },
              { label: 'Vrouw', value: 'F' },
            ]}
          />
        </div>
        <div className={classes.labelValueBox}>
          <Typography className={classes.labels}>Leeftijd:</Typography>
          <Switchable
            type="date"
            defaultValue={fields.user.user_profile?.date_of_birth ?? new Date()}
            onChange={onChange}
            isEditable={editMode}
            name="user.user_profile.date_of_birth"
          />
        </div>
        <div className={classes.labelValueBox}>
          <Typography className={classes.labels}>Gewicht (kg):</Typography>
          <Switchable
            type="number"
            defaultValue={fields.user.user_profile?.initial_weight}
            onChange={onChange}
            isEditable={editMode}
            name="user.user_profile.initial_weight"
          />
        </div>
        <div className={classes.labelValueBox}>
          <Typography className={classes.labels}>Lengte (cm):</Typography>
          <Switchable
            type="number"
            defaultValue={fields.user.user_profile?.initial_height}
            onChange={onChange}
            isEditable={editMode}
            name="user.user_profile.initial_height"
          />
        </div>
      </div>
    </div>
  )
}
