import { makeStyles } from '@material-ui/core/styles'

const spaceBetween = 'space-between'
export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  paper: {
    // padding: theme.spacing(2),
    padding: '60px 0',
    width: '100%',
    overflow: 'auto',
  },
  fixedHeight: {
    minHeight: 240,
  },
  gridWrapper: {
    height: '100%',
  },
  profileDetails: {
    padding: '0 30px',
    borderRight: '1px solid #E4E4E4',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 40,
  },
  profilePhoto: {
    marginBottom: 30,
  },
  userPhoto: {
    width: 150,
    height: 150,
  },
  tableContainer: {
    boxShadow: 'none',
    marginTop: 30,
  },
  table: {
    backgroundColor: 'none',
    '& td, th': {
      border: 0,
    },
    '& th': {
      color: '#AEAEAE',
    },
  },
  outlinedBtn: {
    backgroundColor: '#fff',
    color: '#3F51B5',
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    border: '1px solid #3F51B5',

    marginRight: 10,
  },
  containedBtn: {
    marginTop: 20,
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    marginRight: 10,
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: '0.03em',
    marginTop: 40,
    marginBottom: 26,
    padding: '0 16px',
  },
  chart: {
    boxShadow: '2px 2px 21px rgba(0, 0, 0, 0.06)',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  weeklyUpdate: {
    maxWidth: 100,
    maxHeight: 100,
  },
  tileImage: {
    boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
  },
  tabButton: {
    marginRight: 10,
    backgroundColor: '#FAFAFF',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    boxShadow: '0 0 1px 0 rgba(0,0,0,0.2)',
    minHeight: 50,
    '&.Mui-selected': {
      backgroundColor: '#fff',
    },
    '& > .MuiTab-wrapper': {
      flexDirection: 'row',
      '& > svg': {
        marginBottom: '0 !important',
        marginRight: 15,
        fill: '#9DAEC0',
      },
    },
  },
  notCreatedWrapper: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 470,
    textAlign: 'center',
  },
  notCreatedTitle: {
    fontSize: 30,
    fontWeight: 'bold',
    color: '#3F51B5',
    margin: '45px 0 25px',
  },
  stepperWrapper: {
    padding: '0 100px',
    width: '100%',
  },
  stepWrapper: {
    display: 'flex',
    marginTop: 65,
  },
  stepLeft: {
    maxWidth: '30%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 40,
    borderRight: '1px solid #E4E4E4',
  },
  stepRight: {
    maxWidth: '70%',
    width: '100%',
    paddingLeft: '40px',
  },
  stepRightTitle: {
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0.03em',
    marginBottom: '20px',
    fontSize: 16,
    textTransform: 'uppercase',
  },
  stepRightText: {
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0em',
  },
  stepperTextField: {
    marginBottom: 15,
    borderRadius: 4,
    '& input': {
      backgroundColor: '#EEEEF3',
    },
    '& .MuiFilledInput-root::before': {
      display: 'none',
    },
  },
  slider: {
    background: '#FFFFFF',
    filter: 'drop-shadow(rgba(0, 0, 0, 0.06) 0px 4px 7px)',
    minHeight: '100%',
    padding: '0 25px',
    display: 'flex',
    justifyContent: spaceBetween,
    alignItems: 'center',
    color: '#3F51B5',
  },
  sliderArrow: {
    cursor: 'pointer',
  },
  notCreatedInfo: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center',
    maxWidth: 500,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  sliderHeader: {
    display: 'flex',
    justifyContent: spaceBetween,
    alignItems: 'center',
    margin: '10px 0',
    fontSize: '16px',
  },
  sliderHeaderText: {
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
  },
  sliderContent: {
    display: 'flex',
    justifyContent: spaceBetween,
    alignItems: 'center',
    margin: '10px 0',
  },
  sliderContentText: {
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.03em',
    width: 'max-content',
    whiteSpace: 'nowrap',
  },
  sliderLabel: {
    margin: '10px 0',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.03em',
    color: '#AEAEAE',
  },
}))
