import { useConfirmation } from 'context/ConfirmationServiceContext'
import { useSnackbar } from 'notistack'

import {
  GetBlogCategoryByPkDocument,
  useDeleteBlogArticleByPkMutation,
  useInsertBlogArticleMutation,
  useUpdateBlogArticleByPkMutation,
} from '../../generated/graphql'
import { ICreate, IHook, IUpdate } from './interfaces'

export default function useBlogArticles(callback?: () => void): IHook {
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirmation()

  const handleSuccess = () => {
    enqueueSnackbar('saved successfully', { variant: 'success' })
  }

  const handleError = (error: any) => {
    enqueueSnackbar('Something went wrong, please try again later...', {
      variant: 'error',
    })
    console.error(error)
  }

  const [insertArticle, { loading: isInserting }] = useInsertBlogArticleMutation()
  const [updateArticle, { loading: isUpdating }] = useUpdateBlogArticleByPkMutation()
  const [deleteArticle, { loading: isDeleting }] = useDeleteBlogArticleByPkMutation()

  const refetch = (categoryId: number) => {
    return {
      refetchQueries: [
        {
          query: GetBlogCategoryByPkDocument,
          variables: { id: categoryId },
        },
      ],
      awaitRefetchQueries: true,
    }
  }

  const deleteById = (id: number, categoryId: number): void => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'You are planning to delete',
      description: 'Are you sure you want to do this?',
    }).then(() => {
      deleteArticle({
        variables: {
          id,
        },
        ...refetch(categoryId),
      })
        .then(handleSuccess)
        .catch((error) => handleError(error))
        .finally(callback)
    })
  }

  const updateById = (id: number, body: IUpdate): void => {
    updateArticle({
      variables: {
        id,
        changes: body,
      },
      ...refetch(body.blog_category_id),
    })
      .then(handleSuccess)
      .catch((error) => handleError(error))
      .finally(callback)
  }

  const insert = (body: ICreate): void => {
    insertArticle({
      variables: {
        objects: [body],
      },
      ...refetch(body.blog_category_id),
    })
      .then(handleSuccess)
      .catch((error) => handleError(error))
      .finally(callback)
  }

  const isLoading = isInserting || isUpdating || isDeleting

  return { deleteById, updateById, insert, isLoading }
}
