import 'date-fns'

import DateFnsUtils from '@date-io/date-fns'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Slider from '@material-ui/core/Slider'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import TodayIcon from '@material-ui/icons/Today'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

import { ITableItem } from '../tabs/Trainingsschema/interfaces'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      color: '#3F51B5',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface IDialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: IDialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 450,
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const justifyContent = 'space-between'
export default function CustomizedDialogs({
  nextDate,
  item,
  handleClose = () => {},
  handleSubmit = (data: any) => {},
  open = false,
}: {
  nextDate: { startDate: Date | null; endDate: Date | null }
  item: ITableItem | null
  handleClose: any
  handleSubmit: (data: any) => void
  open: boolean
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [name, setName] = useState<string | null>(item ? item.name : '')

  const [startDate, setStartDate] = useState<Date | null>(item ? new Date(item.starts_on) : new Date())

  const [endDate, setEndDate] = useState<Date | null>(item ? new Date(item.ends_on) : new Date())

  const [daysCount, setDaysCount] = useState<number | number[]>(item ? item.day_count : 1)
  React.useEffect(() => {
    if (nextDate) {
      setStartDate(nextDate.startDate)
      setEndDate(nextDate.endDate)
    }
  }, [nextDate])

  React.useEffect(() => {
    if (item) {
      setName(item.name)
      setStartDate(new Date(item.starts_on))
      setEndDate(new Date(item.ends_on))
    }
  }, [item])

  const _onSubmit = () => {
    const totalDays = moment(endDate).diff(moment(startDate), 'days')
    if (daysCount > totalDays) {
      return enqueueSnackbar('De hoeveelheid dagen tussen de data is kleiner dan de hoeveelheid trainingsdagen', {
        variant: 'warning',
      })
    }
    const data: any = {
      ends_on: endDate,
      name,
      starts_on: startDate,
    }
    if (!item) data.day_count = daysCount

    handleSubmit(data)
    handleClose()
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Voeg Trainingsschema Toe
      </DialogTitle>

      <DialogContent dividers>
        <div
          style={{
            display: 'flex',
            justifyContent,
            alignItems: 'baseline',
          }}
        >
          <Typography>Naam: </Typography>
          <TextField variant="outlined" size="small" value={name} onChange={(event) => setName(event.target.value)} />
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div
            style={{
              display: 'flex',
              justifyContent,
              alignItems: 'baseline',
            }}
          >
            <Typography>Start datum:</Typography>
            <KeyboardDatePicker
              disableToolbar
              keyboardIcon={<TodayIcon color="primary" />}
              color="primary"
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              value={startDate}
              onChange={setStartDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent,
              alignItems: 'baseline',
            }}
          >
            <Typography>Eind datum:</Typography>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              keyboardIcon={<TodayIcon color="primary" />}
              color="primary"
              format="MM/dd/yyyy"
              margin="normal"
              value={endDate}
              onChange={setEndDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </div>
        </MuiPickersUtilsProvider>

        {!item && (
          <div
            style={{
              display: 'flex',
              justifyContent,
              alignItems: 'center',
              marginTop: 20,
            }}
          >
            <Typography>Trainingsdagen:</Typography>
            <Slider
              style={{ marginLeft: 25, marginRight: 25 }}
              defaultValue={1}
              value={daysCount}
              onChange={(__, value) => setDaysCount(value)}
              step={1}
              min={1}
              max={7}
            />
            <Typography>{daysCount}</Typography>
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button autoFocus variant="outlined" onClick={handleClose} color="primary">
          Annuleren
        </Button>

        <Button autoFocus variant="contained" onClick={_onSubmit} color="primary">
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  )
}
