import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import React from 'react'

interface IProps {
  isEditable: boolean
  label: string
  name: string
  defaultValue: any
  onChange: (event: any) => void
}

export const SwitchableRadio: React.FunctionComponent<IProps> = ({
  isEditable,
  label,
  name,
  defaultValue,
  onChange = () => {},
}) => {
  return (
    <FormControl component="fieldset">
      <Typography
        style={{
          fontWeight: 'bold',
        }}
      >
        {`${label}: `}
      </Typography>
      <RadioGroup
        style={{ display: 'flex', flexDirection: 'row' }}
        name={name}
        value={defaultValue}
        onChange={onChange}
      >
        <FormControlLabel value={Boolean(true)} control={<Radio />} disabled={!isEditable} label="Ja" />
        <FormControlLabel value={Boolean(false)} control={<Radio />} disabled={!isEditable} label="Nee" />
      </RadioGroup>
    </FormControl>
  )
}

export default SwitchableRadio
