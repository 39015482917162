import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  input: {
    padding: '0 14px',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: '#EEEEF3',
    '& div::before': {
      border: 'none !important',
    },
    '& div::after': {
      border: 'none !important',
    },
    '& input': {
      fontSize: 12,
      padding: '10px 0',
    },
  },
}))
