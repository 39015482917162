export const defaultTotalValues = {
  fat_sum: 0,
  calorie_sum: 0,
  protein_sum: 0,
  carbo_sum: 0,
  calories_maintenance_per_day: 0,
  calories_per_day: 0,
  calories_weekly_reduction: 0,
  max_carbo: 0,
  max_fat: 0,
  max_protein: 0,
}

export const getPercent = (current: number, max: number): number =>
  current > max ? 100 : Math.round((current * 100) / max)
