import { Button, IconButton, TextField, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'

import { TEditName } from '../../interfaces'
import { useStyles } from '../../useStyles'

interface IProps {
  editName: TEditName
  setEditName: React.Dispatch<React.SetStateAction<TEditName>>
  idx: number
  onSave: () => void
  onDelete: () => void
  item: any
}

export const EditNutritionMealBlock: React.FC<IProps> = ({
  onDelete,
  item,
  onSave,
  editName,
  setEditName,
  idx,
}): JSX.Element => {
  const classes = useStyles()

  return (
    <div style={{ display: 'flex', alignItems: 'baseline' }} className={classes.tableName}>
      {/* Conditionally */}
      {editName.active && editName.index === idx ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            value={editName.value}
            onChange={(event) => {
              event.persist()
              setEditName((prevState) => ({
                ...prevState,
                value: event.target.value,
              }))
            }}
          />
          <Button style={{ margin: '0 10px' }} color="primary" variant="contained" onClick={onSave}>
            Opslaan
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() =>
              setEditName({
                index: idx,
                active: false,
                value: '',
              })
            }
          >
            Annuleren
          </Button>
        </div>
      ) : (
        <Typography variant="h6" component="h6">
          {item.name}
        </Typography>
      )}
      <IconButton
        style={{ marginLeft: 'auto' }}
        onClick={() =>
          setEditName({
            index: idx,
            active: true,
            value: item.name,
          })
        }
      >
        <EditIcon />
      </IconButton>
      <IconButton onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </div>
  )
}
