// MUI
import { Button, Container, FormControl, Grid, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

// UTILS
import { useHttp } from '../../utils/useHTTP'
// MUI Icons
import { PageWrapper, useStyles } from './LoginPage.styled'

interface IResetPassword {
  token: string
  password: string
  passwordConfirmation: string
}

const INITIAL = {
  token: '',
  password: '',
  passwordConfirmation: '',
}

export const ResetPassword: React.FunctionComponent = () => {
  const { request, loading } = useHttp()
  const { search } = useLocation()

  const classes = useStyles()
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<string>()

  const [form, setForm] = React.useState<IResetPassword>(INITIAL)

  React.useEffect(() => {
    const query = new URLSearchParams(search)
    const token = query?.get('token')

    if (token) setForm((prevState) => ({ ...prevState, token }))
  }, [search])

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event?.target?.value?.trim()
    setForm({ ...form, [event.target.name]: trimmedValue })
  }

  const resetPasswordHandler = async (event: any) => {
    event.preventDefault()
    setError('')
    setSuccess('')
    const { token, password, passwordConfirmation } = form

    if (password !== passwordConfirmation) {
      setError('De opgegeven wachtwoorden komen niet overeen.')
      return
    }

    if (password.length < 8 || passwordConfirmation.length < 8) {
      setError('Wachtwoord moet uit minimaal 8 karakters bestaan.')
      return
    }

    if (token.trim().length === 0) {
      setError('Token is verplicht')
      return
    }

    try {
      const url = 'https://admin.rg.qraft.eu/users/update_password/'

      const send = {
        password,
        password_confirmation: passwordConfirmation,
      }

      const data = await request(url, 'PUT', send, {
        'Request-Password-Token': token,
      })

      setSuccess(data.message)
      setForm(INITIAL)
    } catch (_) {
      setError('Er ging wat mis! Probeer het opnieuw of neem contact met ons op.')
    }
  }

  return (
    <PageWrapper>
      <Container
        {...{
          maxWidth: 'md',
          className: classes.container,
        }}
      >
        <Grid
          {...{
            container: true,
            className: classes.gridWrapper,
          }}
        >
          <Grid
            {...{
              item: true,
              xs: 12,
              md: 7,
              className: classes.logoWrapper,
            }}
          >
            <img
              {...{
                src: './images/login_logo.png',
                alt: 'alt logo',
                className: classes.imageReset,
              }}
            />
          </Grid>

          <Grid
            {...{
              item: true,
              xs: 12,
              md: 5,
            }}
          >
            <Container
              {...{
                component: 'main',
                maxWidth: 'xs',
                className: classes.formWrapper,
              }}
            >
              <Typography
                {...{
                  component: 'h1',
                  variant: 'h5',
                  className: classes.pageTitle,
                }}
              >
                Wachtwoord resetten
              </Typography>

              <form {...{ className: classes.form }} onSubmit={resetPasswordHandler}>
                <FormControl className={classes.formControl}>
                  <TextField
                    {...{
                      id: 'token',
                      name: 'token',
                      label: 'Token',
                      margin: 'normal',
                      className: classes.textfield,
                      required: true,
                      fullWidth: true,
                      autoFocus: true,
                      onChange: changeHandler,
                      value: form.token,
                    }}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <TextField
                    {...{
                      id: 'password',
                      name: 'password',
                      label: 'Wachtwoord',
                      margin: 'normal',
                      className: classes.textfield,
                      required: true,
                      fullWidth: true,
                      autoFocus: true,
                      onChange: changeHandler,
                      value: form.password,
                    }}
                    inputProps={{ minLength: 8, maxLength: 16 }}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <TextField
                    {...{
                      id: 'passwordConfirmation',
                      name: 'passwordConfirmation',
                      label: 'Bevestig wachtwoord',
                      margin: 'normal',
                      className: classes.textfield,
                      required: true,
                      fullWidth: true,
                      autoFocus: true,
                      onChange: changeHandler,
                      value: form.passwordConfirmation,
                    }}
                    inputProps={{ minLength: 8, maxLength: 16 }}
                  />
                </FormControl>

                <Button
                  {...{
                    type: 'submit',
                    variant: 'contained',
                    color: 'primary',
                    className: classes.submit,
                    fullWidth: true,
                    disabled: loading,
                  }}
                >
                  Verstuur
                </Button>

                {error && <p className={classes.error}>{error}</p>}
                {success && <p className={classes.success}>{success}</p>}
              </form>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </PageWrapper>
  )
}

export default ResetPassword
