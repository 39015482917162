import React, { useCallback } from 'react'

interface IHook {
  moveFocusToInput: (event: React.KeyboardEvent<HTMLLIElement>) => void
  filterRef: React.RefObject<HTMLElement>
}

const useMealSelector = (): IHook => {
  //  useRef
  const filterRef = React.useRef<HTMLElement>(null)

  // useCallback
  const moveFocusToInput = useCallback((event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Tab' || event.key === 'ArrowRight') {
      event.stopPropagation()
      event.preventDefault()
      filterRef?.current?.focus()
    }
  }, [])

  return { moveFocusToInput, filterRef }
}

export default useMealSelector
