import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#AEAEAE',
    textTransform: 'uppercase',
    border: 'none',
  },
  root: {
    borderBottom: 'none',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,

    '&:nth-of-type(odd)': {
      backgroundColor: '#EEEEF3',
    },
  },
  head: {
    border: 'none',
  },
}))(TableRow)
