import { makeStyles } from '@material-ui/core/styles'

export const useScopedStyles = makeStyles(() => ({
  gridWrapper: {
    height: '100%',
    padding: '10px 63px',
  },
  tableContainer: {
    marginTop: 30,
  },
  tableButton: {
    backgroundColor: '#fff',
    color: '#3F51B5',
    textTransform: 'none',
    padding: '4px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    border: '1px solid #3F51B5',
  },
  actionsCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  moreIconWrapper: {
    marginLeft: 15,
  },
  iconHack: {
    position: 'relative',
    left: 15,
  },
  menuIcon: {
    marginRight: 10,
  },
}))
