import { ButtonGroup, debounce, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import { groupBy } from 'lodash'
import React, { useState } from 'react'

import { SearchPanel } from '../../../../components/SearchPanel'
import { useGetAllExerciseVariantsQuery, useGetExerciseVariantsLazyQuery } from '../../../../generated/graphql'
import { IProps } from './interface'

interface ICard {
  title: string
  onClick: () => void
  video?: string | undefined | null
}

const Card: React.FC<ICard> = ({ title, onClick, video }): JSX.Element => {
  return (
    <div
      style={{
        marginTop: 10,
        padding: 10,
        border: '1px solid #e3e3e3',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <h3 style={{ margin: 0 }}>{title}</h3>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!!video && (
          <PlayCircleFilledIcon
            color="secondary"
            style={{ marginRight: 15, cursor: 'pointer' }}
            onClick={(): void => {
              window.open(video, '_newtab')
            }}
          />
        )}
        <Button color="primary" variant="contained" onClick={onClick}>
          Selecteer
        </Button>
      </div>
    </div>
  )
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ExerciseSelectorModal: React.FC<IProps> = ({ onClose, onSelect, show }): JSX.Element => {
  // state
  const [mainCategory, setMainCategory] = useState<string>()
  const [subCategory, setSubCategory] = useState<string>()

  // graphql
  const [getExerciseVariants, { data, loading: loadingExerciseVariant }] = useGetExerciseVariantsLazyQuery()
  const { data: allExerciseVariants, loading: loadingExerciseVariants } = useGetAllExerciseVariantsQuery()

  const isLoading = loadingExerciseVariant || loadingExerciseVariants

  const exerciseVariants = allExerciseVariants?.exercise_variants
  const exerciseVariantTypes = groupBy(exerciseVariants, 'exercise_variant_category')

  const searchHandler = debounce((newValue: string): void => {
    if (!newValue.length) return
    getExerciseVariants({
      variables: {
        _like: `%${newValue}%`,
      },
    })
  }, 500)

  const renderCategories = (): JSX.Element | null => {
    if (!mainCategory && !subCategory) {
      return (
        <div style={{ zIndex: 1000, overflow: 'hidden' }}>
          {Object.keys(exerciseVariantTypes)?.map((type) => {
            return <Card key={type} title={type} onClick={(): void => setMainCategory(type)} />
          })}
        </div>
      )
    }

    if (mainCategory && !subCategory) {
      const muscleCategories = groupBy(
        exerciseVariantTypes[mainCategory].map((mCat) => mCat.exercise_muscle_category),
        'name'
      )

      return (
        <div>
          {Object.keys(muscleCategories).map((subCat, index) => {
            return <Card key={`${subCat}-${index}`} title={subCat} onClick={(): void => setSubCategory(subCat)} />
          })}
        </div>
      )
    }

    if (mainCategory && subCategory) {
      return (
        <div>
          {exerciseVariantTypes[mainCategory]
            .filter((ele) => ele?.exercise_muscle_category.name === subCategory)
            .map((variant, variantIndex) => {
              return (
                <Card
                  key={`variant-${variantIndex}`}
                  title={variant.name || 'Unknown'}
                  onClick={(): void => onSelect(variant)}
                  video={variant.media_content}
                />
              )
            })}
        </div>
      )
    }

    return null
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={show} onClose={onClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">Oefening zoeken</DialogTitle>
      <DialogContent>
        <SearchPanel onSubmit={searchHandler} placeholder="Zoek op naam" showButton={false} fullWidth />

        <div style={{ height: '300px', overflow: 'auto' }}>
          {isLoading && <div>Bezig met laden...</div>}
          {!isLoading && !!data?.exercise_variants.length
            ? data?.exercise_variants.map((variant) => (
                <Card
                  key={variant.id}
                  title={variant.name || 'Unknown'}
                  onClick={(): void => onSelect(variant)}
                  video={variant.media_content}
                />
              ))
            : renderCategories()}
        </div>
      </DialogContent>

      <DialogActions>
        <ButtonGroup style={{ marginRight: 'auto' }}>
          {mainCategory && (
            <Button
              color="primary"
              variant="outlined"
              onClick={(): void => {
                setMainCategory(undefined)
                setSubCategory(undefined)
              }}
            >
              {`${mainCategory}`}
            </Button>
          )}
          {subCategory && (
            <Button color="primary" variant="outlined" onClick={(): void => setSubCategory(undefined)}>
              {subCategory}
            </Button>
          )}
        </ButtonGroup>

        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
