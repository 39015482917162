import { Box } from '@material-ui/core'
import { format } from 'date-fns'
import { nl } from 'date-fns/locale'
import React from 'react'
import { useParams } from 'react-router'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { useGetExercisesProgressQuery } from '../../generated/graphql'
import WithDrawer from '../../wrappers'
import { useStyles } from '../TrainingSchemaItem/useStyles'
import { TExerciseProgress } from './interfaces'

const ExercisesProgress = (): JSX.Element => {
  const classes = useStyles()
  const { workoutPlanId } = useParams<{ workoutPlanId: string }>()

  const { data } = useGetExercisesProgressQuery({
    variables: { id: +workoutPlanId },
  })
  const exerciseProgressions = data?.exercise_progressions || []

  const grouped = exerciseProgressions.reduce<{ [key: string]: TExerciseProgress }>((acc, obj) => {
    const date = format(new Date(obj.created_at), 'yyyy-MM')
    const list = acc[date]
    return { ...acc, [date]: list ? [...list, obj] : [obj] }
  }, {})

  const fullName = `${data?.workout_plans_by_pk?.user.first_name} ${data?.workout_plans_by_pk?.user.last_name}`
  const breadcrumbData: IBreadcrumb[] = [
    { isLink: true, to: '/clients', name: 'Members' },
    {
      isLink: true,
      to: `/user/${data?.workout_plans_by_pk?.user.id}`,
      name: fullName,
    },
    { isLink: false, name: 'Training Schema Progressie' },
  ]

  return (
    <React.Fragment>
      <WithDrawer breadcrumbData={breadcrumbData}>
        <Box
          {...{
            component: 'div',
            className: classes.container,
          }}
        >
          <div>
            {Object.keys(grouped).map((item) => {
              const progresses = grouped[item]
              return (
                <div key={item}>
                  <p style={{ fontWeight: 'bold' }}>{format(new Date(item), 'MMMM yyyy', { locale: nl })}</p>
                  {progresses.map((progress) => (
                    <div
                      key={progress.id}
                      style={{
                        backgroundColor: '#fff',
                        border: '.5px solid gray',
                        marginBottom: 5,
                        padding: 10,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <p style={{ margin: 0, marginRight: 10 }}>
                          {format(new Date(progress.created_at), 'dd-MM-yyyy')}
                        </p>
                        <p style={{ margin: 0 }}>{progress.exercise.exercise_variant.name}</p>
                      </div>
                      {progress.sets.map((set, index) => (
                        <div key={index} style={{ display: 'flex' }}>
                          <p style={{ margin: 0, marginRight: 10 }}>{`Sets: ${set.set}`}</p>
                          <p style={{ margin: 0, marginRight: 10 }}>{`Gewicht: ${set.weight}`}</p>
                          <p style={{ margin: 0 }}>{`Herhalingen: ${set.repeats}`}</p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
        </Box>
      </WithDrawer>
    </React.Fragment>
  )
}

export default ExercisesProgress
