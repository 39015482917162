import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  gridWrapper: {
    height: '100%',
    padding: '10px 63px',
  },
  tableContainer: {
    marginTop: 30,
  },
  paper: {
    padding: '60px 0',
    width: '100%',
    overflow: 'auto',
  },
  fixedHeight: {
    minHeight: 240,
  },
  containedBtn: {
    marginLeft: 'auto',
    marginTop: 20,
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    marginRight: 10,
  },
  informationWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  spacingBottom: {
    marginBottom: 10,
  },
}))
