import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { IGetUserByIdQuery } from 'generated/graphql'
import React from 'react'
import { useCloudinary } from 'utils/useCloudinary'

import { useStyles } from './styles'

export interface IUser {
  id: string
  first_name: string
  last_name: string
  email: string
  biography: string
  avatar_url: string
  language: string
}

type TUser = NonNullable<IGetUserByIdQuery['users_by_pk']>

interface IEditProps {
  children: React.ReactElement
  user: TUser
  onPress: (values: IUser) => void
  avatar: string
}
interface IFromInput {
  label: string
  value: string
  onChange: (event: React.BaseSyntheticEvent) => void
  containerClass: string
}

const FormInput: React.FC<IFromInput> = ({ label, value, onChange, containerClass }): JSX.Element => (
  <div className={containerClass}>
    <TextField
      type="text"
      label={label}
      variant="outlined"
      fullWidth
      size="small"
      name="endingMonth"
      value={value || ''}
      onChange={onChange}
    />
  </div>
)

const EditProfileModalForm: React.FC<IEditProps> = ({ children, onPress, user, avatar }): JSX.Element => {
  const classes = useStyles()
  const [avatarChanged, setAvatarChanged] = React.useState<boolean>(false)

  const [values, setValues] = React.useState<any>({
    email: '',
    avatar_url: '',
    firstName: '',
    lastName: '',
    biography: '',
    language: '',
  })
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    // NOTE: add real user data
    setValues({
      email: user.email,
      avatar_url: user.avatar_url,
      firstName: user.first_name,
      lastName: user.last_name,
      biography: user.biography,
      language: user.language,
    })
  }, [open, user])

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
    // @ts-ignore

    setValues({
      email: '',
      avatar_url: '',
      firstName: '',
      lastName: '',
      biography: '',
      language: '',
    })
  }

  const { saveImage, deleteImagesByPublicIds } = useCloudinary()

  const updateImageAndGetPublicId = async () => {
    if (avatarChanged && values.avatar_url) {
      if (user.avatar_url) deleteImagesByPublicIds([user.avatar_url])
      return saveImage(values.avatar_url, 'ruggengraat/avatars')
        .then((resp) => resp.json())
        .then((img: any) => {
          return img.public_id
        })
        .catch((err) => {
          console.error(err)
          return user?.avatar_url
        })
    }
    return user?.avatar_url?.trim().length ? user?.avatar_url : null
  }

  const sendRequest = async () => {
    // NOTE: Add api call to update profile data
    const avatarId = await updateImageAndGetPublicId()

    // @ts-ignore
    onPress({
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      biography: values.biography,
      language: values.language,
      avatar_url: avatarId,
    })
    handleClose()
  }

  const toBase64 = async (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const handleImage = async (event: any) => {
    const file = await toBase64(event.target.files[0])
    setValues((prevState: any) => ({ ...prevState, avatar_url: file }))
    setAvatarChanged(true)
  }

  return (
    <div>
      {React.cloneElement(children, { onClick: handleClickOpen })}
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ minWidth: '450px' }}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Taal</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.language || ''}
                onChange={(event: React.BaseSyntheticEvent): void => {
                  const { target } = event
                  setValues({ ...values, language: target.value })
                }}
              >
                <MenuItem value="nl">Nederland</MenuItem>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="ru">Русский</MenuItem>
              </Select>
            </FormControl>
            <span>Wijzig profiel</span>
          </div>
        </DialogTitle>
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <Avatar
              alt="User Profile Image"
              src={avatarChanged ? values.avatar_url : avatar}
              style={{ width: 60, height: 60 }}
            />
            <Input
              inputProps={{
                accept: 'image/*',
              }}
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={handleImage}
            />
            <InputLabel htmlFor="raised-button-file">
              <Button component="span">Wijzig profielafbeelding</Button>
            </InputLabel>
          </div>
          <FormInput
            label="Voer email in"
            value={values.email}
            containerClass={classes.offsetTop}
            onChange={(event: React.BaseSyntheticEvent): void => {
              setValues({
                ...values,
                email: event.target.value,
              })
            }}
          />
          <FormInput
            label="Vul voornaam in"
            value={values.firstName}
            containerClass={classes.offsetTop}
            onChange={(event: React.BaseSyntheticEvent): void => {
              setValues({
                ...values,
                firstName: event.target.value,
              })
            }}
          />
          <FormInput
            label="Vul achternaam in"
            value={values.lastName}
            containerClass={classes.offsetTop}
            onChange={(event: React.BaseSyntheticEvent): void => {
              setValues({
                ...values,
                lastName: event.target.value,
              })
            }}
          />
          <div style={{ marginTop: 30, width: '100%' }}>
            <TextField
              id="outlined-multiline-static"
              label="Vul biografie in"
              multiline
              rows={5}
              style={{ width: '100%' }}
              value={values.biography || ''}
              onChange={(event: React.BaseSyntheticEvent): void => {
                setValues({
                  ...values,
                  biography: event.target.value,
                })
              }}
              variant="outlined"
            />
          </div>
          <div />
        </div>
        <DialogActions className={classes.offsetTop}>
          <Button onClick={handleClose} color="primary">
            Annuleren
          </Button>
          <Button onClick={sendRequest} color="primary">
            Opslaan
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditProfileModalForm
