import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      color: '#3F51B5',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export const useStyles = makeStyles(() => ({
  dialogContentItem: {
    // marginTop: 30,
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    justifyContent: 'space-between',
  },
  numberField: {
    margin: '10px 0',
  },
  dateField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
}))
