import { Button, Grid, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { GetUserByIdDocument, useUpdateUserProfileByPkMutation } from '../../../../../generated/graphql'
import { TUser } from '../../../interfaces'
import Switchable from './Switchable'

interface IProps {
  userInfo: TUser
  editMode: boolean
  onClose: () => void
}

interface IUpdateProps {
  program_info: string
  training_expectations: string
  result_after_1month: string
  result_after_3month: string
  result_after_6month: string
}

export const ProgrammaTab: React.FunctionComponent<IProps> = ({ userInfo, editMode, onClose }) => {
  const { userId } = useParams()
  const [fields, setFields] = useState<IUpdateProps>({
    program_info: '',
    training_expectations: '',
    result_after_1month: '',
    result_after_3month: '',
    result_after_6month: '',
  })
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setFields({
      program_info: userInfo.user_profile?.program_info ?? '',
      training_expectations: userInfo.user_profile?.training_expectations ?? '',
      result_after_1month: userInfo.user_profile?.result_after_1month ?? '',
      result_after_3month: userInfo.user_profile?.result_after_3month ?? '',
      result_after_6month: userInfo.user_profile?.result_after_6month ?? '',
    })
  }, [userInfo])

  const onChange = (event: any): void => {
    const key = event.target.name
    const newValue = event.target.value

    setFields((prevState) => ({ ...prevState, [key]: newValue }))
  }

  const [updateUserProfileByPk] = useUpdateUserProfileByPkMutation()

  const onSave = (): void => {
    updateUserProfileByPk({
      variables: {
        id: userInfo?.user_profile?.id,
        changes: {
          ...fields,
        },
      },
      refetchQueries: [{ query: GetUserByIdDocument, variables: { userId } }],
      awaitRefetchQueries: true,
    })
      .then(() => {
        enqueueSnackbar('Saved successfully', { variant: 'success' })
        onClose()
      })
      .catch(console.error)
  }

  const groupedTextAreas = [
    { name: 'program_info', label: 'Het programma:' },
    { name: 'training_expectations', label: 'Ik verwacht van jouw dat je:' },
    { name: 'result_after_1month', label: '1 maand' },
    { name: 'result_after_3month', label: '3 maanden' },
    { name: 'result_after_6month', label: '6 maanden' },
  ]

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        {groupedTextAreas.map((item, index) => (
          <Grid key={index} item xs={12} sm={6}>
            <Typography
              style={{
                fontWeight: 'bold',
              }}
            >
              {item.label}
            </Typography>
            <Switchable
              type="textarea"
              onChange={onChange}
              name={item.name}
              // @ts-ignore
              defaultValue={fields[item.name]}
              isEditable={editMode}
            />
          </Grid>
        ))}
      </Grid>

      {editMode && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Button variant="outlined" color="primary" style={{ marginRight: 10 }} onClick={onClose} fullWidth>
              Annuleren
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button variant="contained" disableElevation color="primary" onClick={onSave} fullWidth>
              Opslaan
            </Button>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default ProgrammaTab
