import { useGetUserByIdQuery } from 'generated/graphql'
import { useCallback, useEffect, useState } from 'react'

const some_coeff = 2.20462

interface INutrition {
  personalGoal: string
  carbonhydratesPercentage: number
  carbonhydratesInGrams: number
  proteinsPercentage: number
  proteinsInGrams: number
  fatRange: number
  fatsInGrams: number
  caloricIntakeForGoal: number
  caloricIntakeForMaintenance: number
  kcalDeficiency?: number | null
}

export const useNutrition = (userId?: string) => {
  const [nutritions, setNutritions] = useState<INutrition | undefined>(undefined)

  const { data: userData } = useGetUserByIdQuery({
    variables: {
      userId,
    },
    skip: userId === undefined || userId === null,
  })

  const getPersonalGoalById = (value: number): string => {
    if (value === 1) return 'Afvallen'
    if (value === 2) return 'Spiermassa aankomen'
    if (value === 3) return 'Op gewicht blijven'
    return 'Unknown'
  }

  const getCaloricIntakeForGoal = (
    caloricIntakeForMaintenance: number,
    personalGoal: number,
    kcalDeficiency?: number | null
  ): number => {
    switch (personalGoal) {
      case 1:
        return Math.round((caloricIntakeForMaintenance * 7 - kcalDeficiency!) / 7)
      case 2:
        return Math.round((caloricIntakeForMaintenance * 7 + kcalDeficiency!) / 7)
      default:
        return caloricIntakeForMaintenance
    }
  }

  const calculateFoodComposition = useCallback(
    (
      personalGoal: number,
      weight: number,
      activityLevel: number,
      proteinRange: number,
      fatRange: number,
      kcalDeficiency?: number | null
    ) => {
      // Calorie inname voor onderhoud
      const caloricIntakeForMaintenance = Math.round(weight * some_coeff * 10 * activityLevel)

      // Calorie inname voor het doel
      const caloricIntakeForGoal = getCaloricIntakeForGoal(caloricIntakeForMaintenance, personalGoal, kcalDeficiency)

      const proteinsInGrams = Math.round(weight * proteinRange)
      const proteinsCalories = proteinsInGrams * 4

      const fatsCalories = caloricIntakeForGoal * (fatRange / 100)
      const fatsInGrams = Math.round(fatsCalories / 9)

      const carbonhydratesCalories = caloricIntakeForGoal - caloricIntakeForGoal * (fatRange / 100) - proteinsCalories
      const carbonhydratesInGrams = Math.round(carbonhydratesCalories / 4)

      // Percentages

      const proteinsPercentage = Math.round((proteinsCalories / caloricIntakeForGoal) * 100)

      const fatsPercentage = Math.round(fatRange)

      const carbonhydratesPercentage = Math.round(100 - fatsPercentage - proteinsPercentage)

      const personalGoalAsString = getPersonalGoalById(personalGoal)
      setNutritions({
        personalGoal: personalGoalAsString,
        carbonhydratesPercentage,
        carbonhydratesInGrams,
        proteinsPercentage,
        proteinsInGrams,
        fatRange,
        fatsInGrams,
        caloricIntakeForGoal,
        caloricIntakeForMaintenance,
        kcalDeficiency,
      })
    },
    []
  )

  useEffect(() => {
    const userProfile = userData?.users_by_pk?.user_profile
    if (userProfile?.diet_summary) {
      const { initial_weight, diet_summary } = userProfile
      const { personal_goal, activity_level, protein_range, fat_range, kcal_deficiency } = diet_summary
      calculateFoodComposition(
        personal_goal ?? 1,
        initial_weight,
        activity_level,
        protein_range,
        fat_range,
        kcal_deficiency
      )
    }
  }, [calculateFoodComposition, userData?.users_by_pk?.user_profile])

  return { calculateFoodComposition, nutritions }
}
