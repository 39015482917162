import './App.css'

import { ConfirmationServiceProvider } from 'context/ConfirmationServiceContext'
import { SnackbarProvider } from 'notistack'
import ResetPassword from 'pages/ResetPassword'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import { ApolloClientProvider } from './components/ApolloClientProvider'
// AUTH
import { AuthContext } from './context/auth.context'
import Login from './pages/LoginPage'
import { useAuth } from './utils/useAuth'
import { useRoutes } from './utils/useRoutes'

const queryClient = new QueryClient()

function App(): JSX.Element {
  const { token, login, logout, userId, ready, role } = useAuth()
  const isAuthenticated = !!token
  const routes = useRoutes()

  if (!ready) return <div>Loading ...</div>

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider
        {...{
          value: {
            token,
            role,
            login,
            logout,
            userId,
            isAuthenticated,
          },
        }}
      >
        {isAuthenticated ? (
          <ApolloClientProvider>
            <SnackbarProvider maxSnack={3}>
              <ConfirmationServiceProvider>
                <Router>{routes}</Router>
              </ConfirmationServiceProvider>
            </SnackbarProvider>
          </ApolloClientProvider>
        ) : (
          <Router>
            <Switch>
              <Route {...{ path: '/', exact: true }}>
                <Login />
              </Route>
              <Route path="/reset_password">
                <ResetPassword />
              </Route>
              <Redirect {...{ to: '/' }} />
            </Switch>
          </Router>
        )}
      </AuthContext.Provider>
    </QueryClientProvider>
  )
}

export default App
