import { CardMedia, TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import ContainerModal from '../../../components/ContainerModal'
import { ICookbookCategories } from '../../../generated/graphql'
import { useStyles } from '../styles'
import useCookbookCategories from '../useCookbookCategories'

interface IProps {
  closeModal: () => void
  item?: ICookbookCategories | undefined
}

const Modal: React.FC<IProps> = ({ closeModal, item }) => {
  const classes = useStyles()
  const { insert, updateById, isLoading } = useCookbookCategories(closeModal)

  const [name, setName] = useState<string>('')
  const [imageUrl, setImageUrl] = useState<string>('')

  useEffect(() => {
    if (item) {
      setName(item.name)
      setImageUrl(item.photo_url)
    }
  }, [item])

  const onSave = () => {
    if (item) updateById({ id: item.id, name, photo_url: imageUrl })
    else insert({ name, photo_url: imageUrl })
  }

  const disableConfirmButton = isLoading || name.trim().length === 0 || imageUrl.trim().length === 0

  return (
    <ContainerModal
      title="Voeg categorie toe"
      isOpen
      handleClose={closeModal}
      handleSubmit={onSave}
      disabled={disableConfirmButton}
    >
      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Naam: </Typography>
        <TextField
          multiline
          rows={1}
          variant="outlined"
          size="small"
          value={name}
          onChange={(event) => {
            setName(event.target.value)
          }}
        />
      </div>

      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Foto url: </Typography>
        <TextField
          multiline
          rows={2}
          variant="outlined"
          size="small"
          value={imageUrl}
          onChange={(event) => {
            setImageUrl(event.target.value)
          }}
        />
      </div>

      <div className={classes.informationWrapper} style={{ alignItems: 'center' }}>
        <Typography>Voorbeeld: </Typography>
        <CardMedia
          {...{
            component: 'img',
            image: imageUrl,
            style: { width: 100, height: 100 },
          }}
        />
      </div>
    </ContainerModal>
  )
}

export default Modal
