// MUI
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  Typography,
} from '@material-ui/core'
// MUI Icons
import { Visibility, VisibilityOff } from '@material-ui/icons'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import jwt_decode from 'jwt-decode'
import * as React from 'react'
import { useHistory } from 'react-router'

import { AuthContext } from '../../context/auth.context'
// UTILS
import { useHttp } from '../../utils/useHTTP'
import { ImageWrapper, PageWrapper, useStyles } from './LoginPage.styled'

interface ILoginProps {
  username: string
  password: string
}

type TSession = {
  user_id: number
  name: string
  admin: boolean
  'https://hasura.io/jwt/claims': {
    'x-hasura-allowed-roles': string[]
    'x-hasura-default-role': string
    'x-hasura-user-id': string
    'x-hasura-custom': string
  }
}

export const LoginPage: React.FunctionComponent = () => {
  const auth = React.useContext(AuthContext)
  const { request } = useHttp()
  const [paswordVisible, setPaswordVisible] = React.useState(false)
  const classes = useStyles()
  const history = useHistory()

  const [form, setForm] = React.useState<ILoginProps>({
    username: '',
    password: '',
  })

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setPaswordVisible(!paswordVisible)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const loginHandler = async () => {
    try {
      // todo: move to .env or something similar
      const url = 'https://admin.rg.qraft.eu/sessions/'

      const send = {
        login: { ...form },
      }
      const data = await request(url, 'POST', send)
      const decoded: TSession = jwt_decode(data.token)
      const role = decoded['https://hasura.io/jwt/claims']['x-hasura-default-role']

      // @ts-ignore
      auth.login(data.token, decoded?.user_id, role)
      history.push('/')
    } catch (e) {
      console.error(e)
    }
  }

  const showPasswordButton = () => (
    <InputAdornment {...{ position: 'end' }}>
      <IconButton
        {...{
          'aria-label': 'toggle password visibility',
          onClick: handleClickShowPassword,
          onMouseDown: handleMouseDownPassword,
        }}
      >
        {paswordVisible ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  )

  const checkboxElement = () => (
    <Checkbox
      value="remember"
      color="default"
      icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 18 }} />}
      checkedIcon={<CheckBoxIcon style={{ fontSize: 18 }} />}
    />
  )

  return (
    <PageWrapper>
      <Container
        {...{
          maxWidth: 'md',
          className: classes.container,
        }}
      >
        <Grid
          {...{
            container: true,
            className: classes.gridWrapper,
          }}
        >
          <Grid
            {...{
              item: true,
              xs: 12,
              md: 7,
            }}
          >
            <img
              {...{
                src: './images/login_bg.jpg',
                alt: 'alt text',
                className: classes.imageReset,
              }}
            />
          </Grid>

          <Grid
            {...{
              item: true,
              xs: 12,
              md: 5,
            }}
          >
            <Container
              {...{
                component: 'main',
                maxWidth: 'xs',
              }}
            >
              <ImageWrapper>
                <img
                  {...{
                    src: './images/login_logo.png',
                    alt: 'Logo',
                  }}
                />
              </ImageWrapper>

              <Typography
                {...{
                  component: 'h1',
                  variant: 'h5',
                  className: classes.pageTitle,
                }}
              >
                Login
              </Typography>

              <div {...{ className: classes.form }}>
                <FormControl className={classes.formControl}>
                  <TextField
                    {...{
                      id: 'username',
                      name: 'username',
                      autoComplete: 'username',
                      label: 'Email Address',
                      margin: 'normal',
                      className: classes.textfield,
                      required: true,
                      fullWidth: true,
                      autoFocus: true,
                      onChange: changeHandler,
                    }}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <InputLabel {...{ htmlFor: 'standard-adornment-password' }}>Password</InputLabel>
                  <Input
                    {...{
                      id: 'standard-adornment-password',
                      name: 'password',
                      type: paswordVisible ? 'text' : 'password',
                      value: form.password,
                      onChange: changeHandler,
                      endAdornment: showPasswordButton(),
                    }}
                  />
                </FormControl>

                <Grid container className={classes.wrapper}>
                  <Grid item xs>
                    <FormControlLabel
                      {...{
                        className: `${classes.link} ${classes.wrapper}`,
                        control: checkboxElement(),
                        label: <Box {...{ component: 'span', fontSize: 12 }}>Remember me</Box>,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Link
                      {...{
                        href: '#',
                        variant: 'body2',
                        className: `${classes.link} ${classes.right}`,
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>

                <Button
                  {...{
                    type: 'submit',
                    variant: 'contained',
                    color: 'primary',
                    className: classes.submit,
                    onClick: loginHandler,
                    fullWidth: true,
                  }}
                >
                  Login
                </Button>

                <div className={classes.link}>
                  Don't have an account?
                  <Link
                    {...{
                      href: '#',
                      variant: 'body2',
                      style: {
                        marginLeft: 5,
                      },
                    }}
                  >
                    Sign Up
                  </Link>
                </div>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </PageWrapper>
  )
}

export default LoginPage
