import { Box, Button, InputAdornment, TextField } from '@material-ui/core'
// MUI
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import * as React from 'react'

const useStyles = makeStyles(() => ({
  searchPanel: {
    width: '100%',
    display: 'flex',
  },
  searchInput: {
    padding: '0 14px',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: '#EEEEF3',
    '& div::before': {
      border: 'none !important',
    },
    '& div::after': {
      border: 'none !important',
    },
    '& input': {
      fontSize: 12,
      padding: '10px 0',
    },
  },
  searchButton: {
    width: '30%',
    padding: '5px 14px',
    backgroundColor: '#3F51B5',
    color: '#fff',
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: 'none',
    transition: 'opacity .3s ease',
    '&:hover': {
      backgroundColor: '#3F51B5',
      opacity: 0.75,
      boxShadow: 'none',
    },
  },
}))

interface IProps {
  placeholder?: string
  onSubmit: (value: string) => void
  showButton?: boolean
  fullWidth?: boolean
}

export const SearchPanel: React.FunctionComponent<IProps> = ({
  placeholder = '',
  onSubmit,
  showButton = true,
  fullWidth = false,
}) => {
  const classes = useStyles()

  const [value, setValue] = React.useState('')

  const _onSubmit = () => {
    onSubmit && onSubmit(value)
  }

  React.useEffect(() => {
    _onSubmit()
  }, [value])

  return (
    <Box {...{ component: 'div', className: classes.searchPanel }} style={fullWidth ? {} : { maxWidth: 365 }}>
      <TextField
        className={classes.searchInput}
        style={{ width: showButton ? '70%' : '100%' }}
        placeholder={placeholder}
        value={value}
        onChange={(event): void => setValue(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: '#AEAEAE' }} />
            </InputAdornment>
          ),
        }}
      />
      {showButton && (
        <Button
          {...{
            variant: 'contained',
            className: classes.searchButton,
          }}
          onClick={_onSubmit}
        >
          Search
        </Button>
      )}
    </Box>
  )
}
