import { Box, Grid } from '@material-ui/core'
import { useGetUserByIdQuery } from 'generated/graphql'
import React from 'react'
import { useParams } from 'react-router'
import WithDrawer from 'wrappers'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { CurrentIndicators } from './CurrentIndicators'
import { Meals } from './Meals'
import { useStyles } from './useStyles'

export const MealPlanClone = (): JSX.Element => {
  const classes = useStyles()
  const { userId } = useParams<{ userId: string }>()

  const { data } = useGetUserByIdQuery({
    variables: { userId },
  })

  const name = `${data?.users_by_pk?.first_name} ${data?.users_by_pk?.last_name}`

  const breadcrumbData: IBreadcrumb[] = [
    { isLink: true, to: '/clients', name: 'Members' },
    { isLink: true, name: name ?? 'Onbekend', to: `/user/${userId}` },
    { isLink: false, name: `Gecloonde voedingsschema` },
  ]

  return (
    <WithDrawer breadcrumbData={breadcrumbData}>
      <Box
        {...{
          component: 'div',
          className: classes.container,
        }}
      >
        <Grid>
          <CurrentIndicators />
        </Grid>

        <Grid>
          <Meals />
        </Grid>
      </Box>
    </WithDrawer>
  )
}
