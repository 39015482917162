import { Box, Button, ButtonGroup } from '@material-ui/core'
import * as React from 'react'

interface IOption {
  value: string
  label: string
}

interface IInputSelectProps {
  value: string
  options: IOption[]
  onChange: (value: string) => void
  className?: any
}

export const InputSelectTabs: React.FunctionComponent<IInputSelectProps> = ({
  value = '',
  options,
  onChange = (): void => {},
  ...rest
}) => {
  return (
    <Box
      style={{
        filter: 'drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.06))',
        padding: 5,
        borderRadius: 4,
        backgroundColor: '#fff',
      }}
    >
      <ButtonGroup variant="outlined" color="primary" {...rest}>
        {options.map((item: IOption) => (
          <Button
            style={{
              border: 0,
              color: `${value === item.value ? '#fff' : '#AEAEAE'}`,
            }}
            disableElevation
            key={item.value}
            // variant={value === item.value ? 'contained' : null}
            {...{
              ...(value === item.value && { variant: 'contained' }),
            }}
            onClick={(): void => onChange(item.value)}
          >
            {item.label}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  )
}
