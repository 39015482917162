import { Grid, Paper, Table, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { StyledTableCell, StyledTableRow } from 'components/Table'
import { useGetMealPlanByNutritionMealPlanIdQuery } from 'generated/graphql'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router'

import { useStyles } from '../useStyles'

export const Meals = (): JSX.Element | null => {
  const { nutritionPlanMealId } = useParams<{ nutritionPlanMealId: string }>()
  const classes = useStyles()

  const { data, loading } = useGetMealPlanByNutritionMealPlanIdQuery({
    variables: {
      id: +nutritionPlanMealId,
    },
  })

  const meals = data?.nutrition_plan_meals_by_pk?.nutrition_meals

  if (loading) return <Skeleton className={classes.tableContainer} height={50} count={3} />

  return (
    <Grid container className={classes.tableContainer}>
      {meals?.map((value, index) => (
        <React.Fragment key={`main-${index}`}>
          <Typography variant="h6" component="h6">
            {value.name}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Naam:</StyledTableCell>
                  <StyledTableCell>Hoeveelheid:</StyledTableCell>
                  <StyledTableCell>Eenheid:</StyledTableCell>
                  <StyledTableCell>Calories:</StyledTableCell>
                  <StyledTableCell>Carbs:</StyledTableCell>
                  <StyledTableCell>Fats:</StyledTableCell>
                  <StyledTableCell>Protein:</StyledTableCell>
                </TableRow>
              </TableHead>

              {value.nutrition_meal_products_aggregate.nodes.map((node, idx) => {
                const quantity = node?.quantity ?? 0

                return (
                  <StyledTableRow key={`row-${idx}`}>
                    <StyledTableCell>{node.product?.name}</StyledTableCell>
                    <StyledTableCell>{node?.quantity}</StyledTableCell>
                    <StyledTableCell>{node?.product?.units}</StyledTableCell>
                    <StyledTableCell>{(node?.product?.calories * quantity).toFixed(1)}</StyledTableCell>
                    <StyledTableCell>{(node?.product?.carbohydrates ?? 0 * quantity).toFixed(1)}</StyledTableCell>
                    <StyledTableCell>{(node?.product?.fats * quantity).toFixed(1)}</StyledTableCell>
                    <StyledTableCell>{(node?.product?.proteins * quantity).toFixed(1)}</StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </Table>
          </TableContainer>
        </React.Fragment>
      ))}
    </Grid>
  )
}
