import { Box, Button, Snackbar, Step, StepLabel, Stepper } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import * as React from 'react'

import { IGetUserByIdQuery, useInsertOrUpdateDietSummaryMutation } from '../../../generated/graphql'
import { useStyles } from '../useStyles'
import { getStepContent, getSteps } from './helpers'
import { StepperWrapper } from './StepperWrapper'
import { VoedingssamenstellingView } from './VoedingssamenstellingView'

type TUser = NonNullable<IGetUserByIdQuery['users_by_pk']>

interface IStepperProps {
  onClose: () => void
  onSuccessHandler: () => void
  user: TUser
}

const StepButtons = (
  // @ts-ignore
  { activeStep, handleNext, handleSave, handleBack, steps }
) => {
  const classes = useStyles()
  return (
    <div className={classes.buttonsWrapper}>
      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
        variant="outlined"
        disableElevation
        color="primary"
        style={{ marginRight: 15, textTransform: 'none' }}
        startIcon={<ChevronLeftIcon />}
      >
        Vorige
      </Button>

      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={activeStep === steps.length - 1 ? handleSave : handleNext}
        endIcon={activeStep !== steps.length - 1 ? <ChevronRightIcon /> : null}
        style={{ textTransform: 'none' }}
      >
        {activeStep === steps.length - 1 ? 'Opslaan' : 'Volgende'}
      </Button>
    </div>
  )
}
export const HorizontalStepper: React.FunctionComponent<IStepperProps> = ({
  onClose = (): void => {},
  onSuccessHandler = (): void => {},
  user,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const [dateInputValue, setDateInputValue] = React.useState<any>()
  const [showSnackbar, setSnackbar] = React.useState<boolean>(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const [userData, setUserData] = React.useState({
    dateOfBirth: new Date(),
    weight: 1,
    height: 1,
    activity_level: 1.3,
    personal_goal: 1, // afvallen, spiermassa-aankomen, op-gewicht-blijven
    weight_rate: 3000, // 3000, 2500, 2000 (optional)
    protein_per_kg: 1.8, // slide 1.8 - 2.8 gr
    fats_percentage: 15, // slide 15% - 40%
  })
  const [steps, setSteps] = React.useState<string[]>(getSteps())

  React.useEffect(() => {
    const format = 'MM-DD-yyyy'
    let dateOfBirth = moment().utc().format(format)
    if (user.user_profile?.date_of_birth) dateOfBirth = moment(user.user_profile.date_of_birth).format(format)
    setDateInputValue(dateOfBirth)
    const existingData = {
      dateOfBirth,
      weight: user.user_profile?.initial_weight ?? 1,
      height: user.user_profile?.initial_height ?? 1,
      activity_level: user.user_profile?.diet_summary?.activity_level ?? 1.3,
      personal_goal: user.user_profile?.diet_summary?.personal_goal ?? 1,
      weight_rate: user.user_profile?.diet_summary?.kcal_deficiency ?? 3000,
      protein_per_kg: user.user_profile?.diet_summary?.protein_range ?? 1.8,
      fats_percentage: user.user_profile?.diet_summary?.fat_range ?? 15,
    }

    // @ts-ignore
    setUserData(existingData)
  }, [user])

  React.useEffect(() => {
    const WEIGHT_LOSE = 'Snelheid'
    if (userData?.personal_goal === 3) {
      setSteps((prevState) => {
        return prevState.filter((item) => item !== WEIGHT_LOSE)
      })
    } else if (userData?.personal_goal !== 3 && steps.some((step) => step !== WEIGHT_LOSE)) setSteps(getSteps())
  }, [userData.personal_goal])

  const handleNext = (): void => {
    if (activeStep === steps.length - 1) onClose()
    else setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = (): void => {
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleDate = (date: any, value: any, name: any): void => {
    setDateInputValue(value)
    setUserData((prevstate) => ({ ...prevstate, [name]: date }))
  }

  const handleChange = (event: any, newValue?: number, name?: any): void => {
    let key: string
    let val: string | number | undefined

    if (name === undefined && event?.target?.name === undefined) {
      key = event.target.getAttribute('aria-labelledby')
      val = newValue
    } else if (name !== undefined) {
      key = name
      val = newValue
    } else {
      key = event.target.name
      val = event.target.value
    }

    if (event.target.type === 'radio') val = +event.target.value

    setUserData((prevState) => ({
      ...prevState,
      [key]: val,
    }))
  }

  const getContentHandler = () => {
    if (userData?.personal_goal === 3 && activeStep === 3) return getStepContent(activeStep + 1)

    return getStepContent(activeStep)
  }

  const [persistDietSummary] = useInsertOrUpdateDietSummaryMutation()

  const handleSave = () => {
    const { dateOfBirth, weight, height, activity_level, personal_goal, weight_rate, protein_per_kg, fats_percentage } =
      userData
    const formattedDate = moment(dateOfBirth).format('MM-DD-yyyy')

    persistDietSummary({
      variables: {
        dateOfBirth: formattedDate,
        initialWeight: weight,
        initialHeight: height,
        activityLevel: activity_level,
        personalGoal: personal_goal,
        kcalDeficiency: personal_goal !== 3 ? weight_rate : null,
        proteinRange: protein_per_kg,
        fatRange: fats_percentage,
        userProfileId: user.user_profile?.id,
      },
    })
      .then(() => {
        onSuccessHandler()
        enqueueSnackbar('Saved successfully', { variant: 'success' })
      })
      .catch(console.log)
      .finally(() => onClose())
  }

  const handleCloseSnackbar = () => {
    setSnackbar(false)
  }

  return (
    <Box
      {...{
        component: 'div',
        className: classes.stepperWrapper,
      }}
    >
      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          Saved successfully!
        </Alert>
      </Snackbar>

      <Stepper {...{ activeStep, alternativeLabel: true }}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: { optional?: React.ReactNode } = {}

          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>

      <div>
        {activeStep === steps.length - 1 ? (
          <div style={{ marginTop: 30 }}>
            <VoedingssamenstellingView nutritionalComposition={userData} />

            <StepButtons {...{ activeStep, handleNext, handleSave, handleBack, steps }} />
          </div>
        ) : (
          <React.Fragment>
            <StepperWrapper
              {...{
                handleDate,
                handleChange,
                data: getContentHandler(),
                userData,
                dateInputValue,
              }}
            />
            <StepButtons {...{ activeStep, handleNext, handleSave, handleBack, steps }} />
          </React.Fragment>
        )}
      </div>
    </Box>
  )
}
