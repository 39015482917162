import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const drawerWidth = 299

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    barContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    breadcrumbs: {
      flexGrow: 1,
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#A2AEF4',
    },
    seperator: {
      marginTop: '-6px',
      fontSize: 'xx-large',
    },
    menuButtonHidden: {
      // display: 'none',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    logoutButton: {
      color: '#fff',
      fontSize: 14,
    },
  })
)
