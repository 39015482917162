import { makeStyles } from '@material-ui/core/styles'

const justifyContent = 'space-between'
export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  topSection: {
    marginTop: 40,
    display: 'flex',
    justifyContent,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent,
  },
  searchInput: {
    width: 240,
  },
  outlinedBtn: {
    backgroundColor: '#fff',
    color: '#3F51B5',
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    border: '1px solid #3F51B5',

    marginLeft: 10,
  },
  containedBtn: {
    backgroundColor: '#3F51B5',
    color: '#fff',
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',

    marginLeft: 10,
  },
  tableContainer: {
    marginTop: 30,
  },
  tableIconWrapper: {
    marginLeft: 15,
  },
  dayWrapper: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '7px',
    borderRadius: '15px 15px 0 0',
    marginTop: 40,
    display: 'flex',
    justifyContent,
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  exerciceTextField: {
    width: 70,
    backgroundColor: '#fff',
    height: 40,
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
    backgroundColor: '#fff',
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
}))
