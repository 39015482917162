import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  paper: {
    padding: '60px 30px',
    width: '100%',
    overflow: 'auto',
  },
  fixedHeight: {
    minHeight: 240,
  },
  gridWrapper: {
    height: '100%',
  },
  labels: {
    width: '15ch',
    fontWeight: 400,
    color: '#AEAEAE',
  },
  labelValueBox: {
    display: 'flex',
    marginBottom: 5,
    alignItems: 'center',
  },
}))
