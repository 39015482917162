// MUI
import { Button, Grid } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { format } from 'date-fns'
import { nl } from 'date-fns/locale'
import * as React from 'react'
import { useContext, useMemo, useState } from 'react'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { SearchPanel } from '../../components/SearchPanel'
import { AuthContext } from '../../context/auth.context'
import { useGetInboxSubscription } from '../../generated/graphql'
import WithDrawer from '../../wrappers'
import { ChatBox } from './ChatBox'
import { ClientCard } from './ClientCard'
import { TInbox } from './ClientCard/interfaces'
import { ClientsModal } from './ClientsModal'
import { useStyles } from './styles'

// eslint-disable-next-line sonarjs/cognitive-complexity
const InboxPage = (): JSX.Element => {
  const classes = useStyles()
  const breadcrumbData: IBreadcrumb[] = [{ isLink: false, name: 'Dashboard' }]
  const [searchValue, setSearchValue] = useState<string>('')
  const { data } = useGetInboxSubscription()
  const [collapsedKey, setCollapsedKey] = useState<string>()

  const filteredList = useMemo(
    () =>
      data?.inbox?.filter(
        (inbox) =>
          inbox.client.first_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          inbox.client.last_name?.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [data?.inbox, searchValue]
  )

  const { userId } = useContext(AuthContext)

  const groupedList =
    filteredList?.reduce<{ [key: string]: { inboxes: TInbox[]; unread: number } }>((parents, inbox) => {
      const programPlanParticipators = inbox.client.program_plan_participators
      const programPlan = programPlanParticipators[programPlanParticipators.length - 1].program_plan
      const programDuration = programPlan?.program?.duration_amount || ''
      const key = `${
        programPlan ? format(new Date(programPlan.starts_at), 'MMMM yyyy', { locale: nl }) : 'Onbekend programma'
      } - ${programDuration} maanden`

      const parent = parents[key]

      const count = inbox.inbox_messages.reduce((cnt, msg) => {
        if (!msg.read && msg.sender_id !== userId) return cnt + 1
        return cnt
      }, 0)

      Object.assign(parents, {
        [key]: parent
          ? { inboxes: [...parent.inboxes, inbox], unread: count + parent.unread }
          : { inboxes: [inbox], unread: count },
      })
      return parents
    }, {}) || {}

  const list = searchValue ? filteredList : data?.inbox

  const [selectedInbox, setSelectedInbox] = useState<TInbox>()
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <WithDrawer breadcrumbData={breadcrumbData}>
      <Grid container className={classes.header}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            endIcon={<Add />}
            onClick={(): void => setShowModal((prevState) => !prevState)}
          >
            Start nieuw chat met lid
          </Button>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item sm={12} md={4} lg={4} style={{ border: '1px solid #e3e3e3' }}>
          <SearchPanel
            onSubmit={setSearchValue}
            placeholder="Een bestaand gesprek zoeken"
            showButton={false}
            fullWidth
          />
          <div id="client-list" style={{ display: 'flex', flexDirection: 'column', overflow: 'auto', maxHeight: 500 }}>
            {!searchValue
              ? Object.keys(groupedList).map((key) => {
                  const value = groupedList[key]
                  const isHidden = collapsedKey !== key
                  return (
                    <div key={key}>
                      <div
                        style={{
                          backgroundColor: isHidden ? '#fff' : '#3F51B5',
                          padding: 10,
                          borderTop: '1px solid #f3f3f3',
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}
                        onClick={(): void => setCollapsedKey(key)}
                      >
                        <span
                          style={{
                            color: isHidden ? '#3F51B5' : '#fff',
                          }}
                        >
                          {key}
                        </span>
                        {value.unread > 0 && (
                          <p style={{ margin: 0, color: isHidden ? '#3F51B5' : '#fff', fontWeight: 500 }}>
                            {value.unread}
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          height: isHidden ? 0 : '100%',
                          display: isHidden ? 'none' : 'block',
                          transition: 'max-height 0.4s ease 0.1s',
                        }}
                      >
                        {value.inboxes.map((inbox) => {
                          return (
                            <div key={inbox.id} onClick={(): void => setSelectedInbox(inbox)}>
                              <ClientCard inbox={inbox} isActive={inbox.id === selectedInbox?.id} />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })
              : list?.map((inbox, idx) => (
                  <div key={idx} onClick={(): void => setSelectedInbox(inbox)}>
                    <ClientCard inbox={inbox} isActive={inbox.id === selectedInbox?.id} />
                  </div>
                ))}
          </div>
        </Grid>

        <Grid item sm={12} md={8} lg={8} style={{ border: '1px solid #e3e3e3', padding: 10 }}>
          <ChatBox inbox={selectedInbox} />
        </Grid>
      </Grid>

      {showModal && <ClientsModal onClose={(): void => setShowModal(false)} />}
    </WithDrawer>
  )
}

export default InboxPage
