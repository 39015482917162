import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  container: {
    flexDirection: 'column',
    paddingLeft: 25,
    paddingRight: 25,
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  offsetTop: {
    marginTop: 15,
  },
}))
