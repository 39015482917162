import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
    flexDirection: 'column',
    marginBottom: 26,
    boxShadow: '2px 2px 21px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
  },
  fixedHeight: {
    height: 240,
  },
  h3: {
    textTransform: 'uppercase',
    letterSpacing: '0.03em',
    marginBottom: 28,
    fontSize: 18,
    lineHeight: '21px',
    fontWeight: 500,
  },
  userPhoto: {
    width: 150,
    height: 'auto',
  },
  profileWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #E4E4E4',
  },
  profilePhoto: {},
  profileInfo: {
    marginLeft: 40,
  },
  userName: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#222',
    marginBottom: 12,
  },
  userPosition: {
    fontSize: 18,
    color: '#AEAEAE',
    marginBottom: 25,
  },
  buttonsWrapper: {
    display: 'flex',
  },
  containedBtn: {
    backgroundColor: '#3F51B5',
    color: '#fff',
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    marginRight: 15,
  },
  outlinedBtn: {
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    color: '#3F51B5',
    borderColor: '#3F51B5',
  },
  profileContacts: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  contactsWrapper: {
    width: '100%',
  },
  infoRow: {
    display: 'flex',
    fontSize: 16,
    lineHeight: '19px',
    minWidth: 80,
    marginBottom: 15,
  },
  infoRowTitle: {
    color: '#AEAEAE',
    minWidth: 80,
  },
}))
