import { Button, Grid } from '@material-ui/core'
import React from 'react'

import { IProps } from './interfaces'

export const Buttons: React.FC<IProps> = ({ onSave, onClose }): JSX.Element => {
  return (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      <Grid item xs={12} sm={12} md={6}>
        <Button variant="outlined" color="primary" style={{ marginRight: 10 }} onClick={onClose} fullWidth>
          Annuleren
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Button variant="contained" disableElevation color="primary" onClick={onSave} fullWidth>
          Opslaan
        </Button>
      </Grid>
    </Grid>
  )
}
