import {
  Button,
  IconButton,
  makeStyles,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import EditIcon from '@material-ui/icons/Edit'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import clsx from 'clsx'
import { IExerciseGroups, IExerciseVariants } from 'generated/graphql'
import React, { useEffect, useState } from 'react'

import { ExerciseSelectorModal } from './ExerciseSelectorModal'
import { useExercises } from './useExercises'

export const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.primary.light,
  },
  tableHeaderCell: {
    color: '#fff',
  },
  tableCell: {
    borderBottom: 'none',
  },
  textField: {
    backgroundColor: '#fff',
    width: '50%',
    borderRadius: '5px',
  },
  inputField: {
    padding: 8,
  },
  errorSign: {
    border: '1px solid red',
  },
}))

interface IProps {
  group: IExerciseGroups
  onDelete: (id: any[]) => void
  onSave: (body: any, callback: () => void) => void
  previewMode: boolean
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const OefeningTableRow: React.FunctionComponent<IProps> = ({ group, onDelete, onSave, previewMode }) => {
  const classes = useStyles()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)

  const { onDeleteParent } = useExercises()

  const [data, setData] = useState<
    | {
        id?: number
        exerciseVariant?: IExerciseVariants
        exerciseGroupId?: number
        sets: string | number
        repetition: string | number
        rest: number
        unit: string
      }
    | undefined
  >(undefined)

  const [errors, setErrors] = useState<any[]>([])

  useEffect(() => {
    const exercise = group.exercises[0]
    if (exercise) {
      setData({
        id: exercise.id,
        exerciseGroupId: exercise.exercise_group_id,
        exerciseVariant: exercise.exercise_variant,
        sets: exercise.sets ?? 0,
        repetition: exercise.repetition ?? 0,
        rest: exercise.rest ?? 0,
        unit: exercise.unit ?? 'Sec',
      })
    }
  }, [group])

  useEffect(() => {
    if (!editMode && !group.exercises[0] && data) setData(undefined)
  }, [editMode, group, data])

  const onChangeHandler = (event: any) => {
    if (data !== undefined) {
      const key = event.target.name
      const newValue = event.target.value

      if (newValue.trim().length !== 0) {
        const filteredList: any[] = [...errors].filter((err) => err.name !== key)
        setErrors(filteredList)
      } else {
        const newError = { name: key }
        setErrors((prevState) => [...prevState, newError])
        return
      }

      setData((prevState) => {
        if (prevState) return { ...prevState, [key]: newValue }
      })
    }
  }

  const onExerciseVariantChangeHandler = (exerciseVariant: any) => {
    setData((prevState) => {
      if (prevState !== undefined) return { ...prevState, exerciseVariant }
    })
    setShowModal(false)
  }

  const onSaveChangesHandler = () => {
    if (data) {
      if (data.exerciseVariant === undefined) return window.alert('Selecteer een exerise')
      onSave(
        [
          {
            id: data.id,
            exercise_group_id: group.id,
            exercise_variant_id: data.exerciseVariant?.id,
            repetition: String(data.repetition),
            rest: data.rest,
            sets: String(data.sets),
            unit: data.unit,
          },
        ],
        () => setEditMode(false)
      )
    }
  }

  const addExerciseHandler = () => {
    setData({
      id: undefined,
      exerciseGroupId: undefined,
      exerciseVariant: undefined,
      sets: 1,
      repetition: 1,
      rest: 1,
      unit: 'Sec',
    })
    setEditMode(true)
  }

  const exercise = group.exercises[0]

  return (
    <TableContainer>
      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell className={classes.tableHeaderCell}>Oefening</TableCell>
            <TableCell className={classes.tableHeaderCell}>Sets</TableCell>
            <TableCell className={classes.tableHeaderCell}>Herhaling</TableCell>
            <TableCell className={classes.tableHeaderCell}>Rust</TableCell>
            <TableCell style={{ float: 'right' }}>
              <IconButton style={{ padding: 5 }} onClick={(): void => onDeleteParent(group.id)}>
                <DeleteIcon style={{ color: 'red' }} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {editMode && data && !previewMode && (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Button onClick={(): void => setShowModal(true)} color="primary" variant="outlined">
                  {data?.exerciseVariant?.name || 'Search'}
                </Button>
                <ExerciseSelectorModal
                  show={showModal}
                  onSelect={onExerciseVariantChangeHandler}
                  onClose={(): void => setShowModal(false)}
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                <TextField
                  className={clsx(
                    classes.textField,
                    errors.some((err) => err.name === 'sets') ? classes.errorSign : null
                  )}
                  defaultValue={data.sets}
                  type="number"
                  name="sets"
                  InputProps={{
                    classes: { input: classes.inputField },
                    disableUnderline: true,
                    inputProps: {
                      min: 1,
                    },
                  }}
                  onChange={onChangeHandler}
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    className={clsx(
                      classes.textField,
                      errors.some((err) => err.name === 'repetition') ? classes.errorSign : null
                    )}
                    defaultValue={data.repetition}
                    InputProps={{
                      classes: { input: classes.inputField },
                      disableUnderline: true,
                      inputProps: {
                        min: 1,
                      },
                    }}
                    type="number"
                    name="repetition"
                    onChange={onChangeHandler}
                  />
                  <span style={{ marginLeft: 10 }}>X</span>
                </div>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <div style={{ display: 'flex' }}>
                  <TextField
                    className={clsx(
                      classes.textField,
                      errors.some((err) => err.name === 'rest') ? classes.errorSign : null
                    )}
                    style={{ flex: 1, marginRight: '10px' }}
                    defaultValue={data.rest ?? 1}
                    InputProps={{
                      classes: { input: classes.inputField },
                      disableUnderline: true,
                      inputProps: {
                        min: 1,
                      },
                    }}
                    type="number"
                    onChange={onChangeHandler}
                    name="rest"
                  />
                  <Select
                    disableUnderline
                    className={classes.textField}
                    style={{ flex: 1, paddingLeft: 8 }}
                    native
                    onChange={onChangeHandler}
                    name="unit"
                    defaultValue={data.unit ?? 'Sec'}
                  >
                    <option value="Sec">Sec</option>
                    <option value="Min">Min</option>
                  </Select>
                </div>
              </TableCell>
              <TableCell align="right" className={classes.tableCell}>
                <IconButton
                  onClick={() => {
                    setErrors([])
                    setEditMode(false)
                  }}
                >
                  <HighlightOffIcon color="secondary" />
                </IconButton>
                <IconButton disabled={errors.length !== 0} onClick={onSaveChangesHandler}>
                  <CheckCircleIcon style={{ color: 'green' }} />
                </IconButton>
              </TableCell>
            </TableRow>
          )}

          {!editMode && exercise && (
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography>{exercise.exercise_variant.name}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography>{exercise.sets}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography>{`${exercise.repetition} X`}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography>{`${exercise.rest} ${exercise.unit}`}</Typography>
              </TableCell>
              {!previewMode && (
                <React.Fragment>
                  <TableCell align="right" className={classes.tableCell}>
                    <IconButton onClick={() => setEditMode(true)}>
                      <EditIcon color="primary" />
                    </IconButton>
                    <IconButton onClick={() => onDelete([exercise.id])}>
                      <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <IconButton
                      aria-label="more"
                      aria-controls="action-menu"
                      aria-haspopup="true"
                      className="sort-icon"
                    >
                      <DragIndicatorIcon />
                    </IconButton>
                  </TableCell>
                </React.Fragment>
              )}
            </TableRow>
          )}
          {!data && !previewMode && (
            <TableRow>
              <TableCell className={classes.tableCell} colSpan={2}>
                <Button color="primary" onClick={addExerciseHandler}>
                  Voeg oefening toe
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default OefeningTableRow
