import { Box, Card, CardActionArea, CardContent, CardMedia, Link, Typography } from '@material-ui/core'
// MUI
import { makeStyles } from '@material-ui/core/styles'
import { ICookbookRecipes } from 'generated/graphql'
import * as React from 'react'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 245,
    minWidth: 245,
    margin: 20,
  },
  cardMediaWrapper: {
    textAlign: 'center',
    '&:hover': {
      border: '2px solid #3F51B5',
      backgroundColor: '#fff',
    },
  },
  cardContent: {
    padding: 0,
  },
  programInfo: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: '#3F51B5',
    color: '#fff',
  },
}))

interface IProps {
  recipe: ICookbookRecipes
  onClick: (recipe: ICookbookRecipes) => void
}

const RecipeCard: React.FC<IProps> = ({ recipe, onClick }) => {
  const classes = useStyles()

  return (
    <Link style={{ textDecoration: 'none' }} onClick={() => onClick(recipe)}>
      <Card {...{ className: classes.card }}>
        <CardActionArea>
          <Box
            {...{
              className: classes.cardMediaWrapper,
              component: 'div',
            }}
          >
            <CardMedia
              {...{
                component: 'img',
                image: recipe.photo_urls[0],
                width: 100,
                height: 100,
                title: recipe.title,
                alt: recipe.title,
              }}
            />
          </Box>

          <CardContent
            {...{
              className: classes.cardContent,
            }}
          >
            <Box
              {...{
                component: 'div',
                className: classes.programInfo,
              }}
            >
              <Typography
                gutterBottom
                {...{
                  variant: 'body1',
                  component: 'h2',
                }}
              >
                {recipe.title}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  )
}

export default RecipeCard
