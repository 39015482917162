import 'react-circular-progressbar/dist/styles.css'

// MUI
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as React from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { useParams } from 'react-router'
import { useNutrition } from 'utils/useNutrition'

const useStyles = makeStyles((theme) => ({
  container: {},
  statisticContainer: {
    height: 80,
    width: '100%',
    background: '#3F51B5',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  statisticItem: {
    flex: 1,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  statisticCircleBarWrapper: {
    width: 50,
    height: 50,
    marginRight: 24,
  },
  statisticTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  statisticTextHead: {
    fontWeight: 500,
    fontSize: 17,
    color: '#FFFFFF',
  },
  statisticText: {
    fontWeight: 500,
    fontSize: 12,
    color: '#FFFFFF',
  },
  statisticDivider: {
    height: 50,
    width: 1,
    opacity: 0.3,
    backgroundColor: '#E8E8E8',

    [theme.breakpoints.down('md')]: {
      height: 1,
      width: '80%',
    },
  },
  textsContainer: {
    marginTop: 30,
  },
  heading: {
    textTransform: 'uppercase',
  },
  leftContainer: {
    marginTop: 30,
    paddingRight: 40,
    borderRight: 'solid 1px #E4E4E4',

    [theme.breakpoints.down('sm')]: {
      border: 'none',
      paddingRight: 0,
    },
  },
  rightContainer: {
    marginTop: 30,
    paddingLeft: 40,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  tableContainer: {
    boxShadow: 'none',
    marginTop: 30,
  },
  table: {
    backgroundColor: 'none',
    '& td, th': {
      border: 0,
      padding: '0 0 10px 0',
    },
    '& th': {
      color: '#AEAEAE',
    },
  },
}))

interface INutritionalComposition {
  dateOfBirth: Date
  weight: number
  height: number
  activity_level: number
  personal_goal: number
  weight_rate?: number | null
  protein_per_kg: number
  fats_percentage: number
}

interface IVoedingssamenstellingViewProps {
  nutritionalComposition?: INutritionalComposition
}

export const VoedingssamenstellingView: React.FunctionComponent<IVoedingssamenstellingViewProps> = ({
  nutritionalComposition,
}) => {
  const { userId } = useParams<{ userId: string }>()
  const classes = useStyles()
  const progressBarStyles = buildStyles({
    textColor: '#FFFFFF',
    pathColor: '#FFFFFF',
    trailColor: 'rgba(0, 0, 0, 0.1)',
  })

  const { calculateFoodComposition, nutritions: foodComposition } = useNutrition(userId)

  React.useEffect(() => {
    if (nutritionalComposition) {
      const { weight_rate, personal_goal, weight, activity_level, protein_per_kg, fats_percentage } =
        nutritionalComposition
      calculateFoodComposition(personal_goal, weight, activity_level, protein_per_kg, fats_percentage, weight_rate)
    }
  }, [calculateFoodComposition, nutritionalComposition])

  if (!foodComposition) return <div>No Food composition found</div>

  const {
    personalGoal,
    carbonhydratesPercentage,
    carbonhydratesInGrams,
    proteinsPercentage,
    proteinsInGrams,
    fatRange,
    fatsInGrams,
    caloricIntakeForGoal,
    caloricIntakeForMaintenance,
    kcalDeficiency,
  } = foodComposition

  return (
    <Grid container className={classes.container}>
      <Grid container className={classes.statisticContainer}>
        <div className={classes.statisticItem}>
          <div className={classes.statisticCircleBarWrapper}>
            <CircularProgressbar
              value={carbonhydratesPercentage}
              text={`${carbonhydratesPercentage}%`}
              strokeWidth={8}
              styles={progressBarStyles}
            />
          </div>

          <div className={classes.statisticTextWrapper}>
            <Typography className={classes.statisticTextHead}>Koolhydraten</Typography>
            <Typography className={classes.statisticText}>
              {`${carbonhydratesInGrams - 5} - ${carbonhydratesInGrams + 5} Gram`}
            </Typography>
          </div>
        </div>

        <div className={classes.statisticDivider} />

        <div className={classes.statisticItem}>
          <div className={classes.statisticCircleBarWrapper}>
            <CircularProgressbar
              value={proteinsPercentage}
              text={`${proteinsPercentage}%`}
              strokeWidth={8}
              styles={progressBarStyles}
            />
          </div>

          <div className={classes.statisticTextWrapper}>
            <Typography className={classes.statisticTextHead}>Eiwitten</Typography>
            <Typography className={classes.statisticText}>
              {`${proteinsInGrams - 5} - ${proteinsInGrams + 5} Gram`}
            </Typography>
          </div>
        </div>

        <div className={classes.statisticDivider} />

        <div className={classes.statisticItem}>
          <div className={classes.statisticCircleBarWrapper}>
            <CircularProgressbar value={fatRange} text={`${fatRange}%`} strokeWidth={8} styles={progressBarStyles} />
          </div>

          <div className={classes.statisticTextWrapper}>
            <Typography className={classes.statisticTextHead}>Vetten</Typography>
            <Typography className={classes.statisticText}>{`${fatsInGrams - 5} - ${fatsInGrams + 5} Gram`}</Typography>
          </div>
        </div>
      </Grid>

      <Grid container className={classes.textsContainer}>
        <Grid item xs={12}>
          <Typography component="h6" variant="h6" className={classes.heading}>
            Voedingssamenstelling
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.leftContainer}>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {`Doel: `}
                  </TableCell>
                  <TableCell align="right">{personalGoal}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    {`Calorie inname voor onderhoud: `}
                  </TableCell>
                  <TableCell align="right">{`${caloricIntakeForMaintenance} KCAL`}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    {`Calorie inname voor het doel: `}
                  </TableCell>
                  <TableCell align="right">{`${caloricIntakeForGoal} KCAL`}</TableCell>
                </TableRow>

                {personalGoal !== 'Op gewicht blijven' && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Wekelijkse calorie te kort:
                    </TableCell>
                    <TableCell align="right">{`${kcalDeficiency} KCAL`}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.rightContainer}>
          <Typography>
            Op deze pagina vind je een kort overzicht van de benodigde calorie inname om op gewicht te blijven en de
            gewenste calorie inname voor het doel. Daarbij kan je de macronutrienten verhoudingen ook terug vinden in
            het overzicht. Dit is het beste advies voor de persoonlijke doel van de lid.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
