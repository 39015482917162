import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _varchar: any;
  bigint: any;
  date: any;
  float8: any;
  json: any;
  numeric: any;
  time: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface IBooleanComparisonExp {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface IIntComparisonExp {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface IStringComparisonExp {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
}


/** Boolean expression to compare columns of type "_varchar". All fields are combined with logical 'AND'. */
export interface IVarcharComparisonExp {
  _eq?: Maybe<Scalars['_varchar']>;
  _gt?: Maybe<Scalars['_varchar']>;
  _gte?: Maybe<Scalars['_varchar']>;
  _in?: Maybe<Array<Scalars['_varchar']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_varchar']>;
  _lte?: Maybe<Scalars['_varchar']>;
  _neq?: Maybe<Scalars['_varchar']>;
  _nin?: Maybe<Array<Scalars['_varchar']>>;
}


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface IBigintComparisonExp {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
}

/** columns and relationships of "blog_articles" */
export interface IBlogArticles {
   __typename?: 'blog_articles';
  blog_category_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  photo_url: Scalars['String'];
  tag: Scalars['String'];
  text: Scalars['String'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregated selection of "blog_articles" */
export interface IBlogArticlesAggregate {
   __typename?: 'blog_articles_aggregate';
  aggregate?: Maybe<IBlogArticlesAggregateFields>;
  nodes: Array<IBlogArticles>;
}

/** aggregate fields of "blog_articles" */
export interface IBlogArticlesAggregateFields {
   __typename?: 'blog_articles_aggregate_fields';
  avg?: Maybe<IBlogArticlesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IBlogArticlesMaxFields>;
  min?: Maybe<IBlogArticlesMinFields>;
  stddev?: Maybe<IBlogArticlesStddevFields>;
  stddev_pop?: Maybe<IBlogArticlesStddevPopFields>;
  stddev_samp?: Maybe<IBlogArticlesStddevSampFields>;
  sum?: Maybe<IBlogArticlesSumFields>;
  var_pop?: Maybe<IBlogArticlesVarPopFields>;
  var_samp?: Maybe<IBlogArticlesVarSampFields>;
  variance?: Maybe<IBlogArticlesVarianceFields>;
}


/** aggregate fields of "blog_articles" */
export interface IBlogArticlesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IBlogArticlesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "blog_articles" */
export interface IBlogArticlesAggregateOrderBy {
  avg?: Maybe<IBlogArticlesAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IBlogArticlesMaxOrderBy>;
  min?: Maybe<IBlogArticlesMinOrderBy>;
  stddev?: Maybe<IBlogArticlesStddevOrderBy>;
  stddev_pop?: Maybe<IBlogArticlesStddevPopOrderBy>;
  stddev_samp?: Maybe<IBlogArticlesStddevSampOrderBy>;
  sum?: Maybe<IBlogArticlesSumOrderBy>;
  var_pop?: Maybe<IBlogArticlesVarPopOrderBy>;
  var_samp?: Maybe<IBlogArticlesVarSampOrderBy>;
  variance?: Maybe<IBlogArticlesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "blog_articles" */
export interface IBlogArticlesArrRelInsertInput {
  data: Array<IBlogArticlesInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IBlogArticlesOnConflict>;
}

/** aggregate avg on columns */
export interface IBlogArticlesAvgFields {
   __typename?: 'blog_articles_avg_fields';
  blog_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "blog_articles" */
export interface IBlogArticlesAvgOrderBy {
  blog_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "blog_articles". All fields are combined with a logical 'AND'. */
export interface IBlogArticlesBoolExp {
  _and?: Maybe<Array<IBlogArticlesBoolExp>>;
  _not?: Maybe<IBlogArticlesBoolExp>;
  _or?: Maybe<Array<IBlogArticlesBoolExp>>;
  blog_category_id?: Maybe<IIntComparisonExp>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  photo_url?: Maybe<IStringComparisonExp>;
  tag?: Maybe<IStringComparisonExp>;
  text?: Maybe<IStringComparisonExp>;
  title?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "blog_articles" */
export enum IBlogArticlesConstraint {
  /** unique or primary key constraint */
  BLOG_ARTICLES_PKEY = 'blog_articles_pkey'
}

/** input type for incrementing numeric columns in table "blog_articles" */
export interface IBlogArticlesIncInput {
  blog_category_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "blog_articles" */
export interface IBlogArticlesInsertInput {
  blog_category_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  photo_url?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IBlogArticlesMaxFields {
   __typename?: 'blog_articles_max_fields';
  blog_category_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  photo_url?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "blog_articles" */
export interface IBlogArticlesMaxOrderBy {
  blog_category_id?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  photo_url?: Maybe<IOrderBy>;
  tag?: Maybe<IOrderBy>;
  text?: Maybe<IOrderBy>;
  title?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IBlogArticlesMinFields {
   __typename?: 'blog_articles_min_fields';
  blog_category_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  photo_url?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "blog_articles" */
export interface IBlogArticlesMinOrderBy {
  blog_category_id?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  photo_url?: Maybe<IOrderBy>;
  tag?: Maybe<IOrderBy>;
  text?: Maybe<IOrderBy>;
  title?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "blog_articles" */
export interface IBlogArticlesMutationResponse {
   __typename?: 'blog_articles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IBlogArticles>;
}

/** on conflict condition type for table "blog_articles" */
export interface IBlogArticlesOnConflict {
  constraint: IBlogArticlesConstraint;
  update_columns: Array<IBlogArticlesUpdateColumn>;
  where?: Maybe<IBlogArticlesBoolExp>;
}

/** Ordering options when selecting data from "blog_articles". */
export interface IBlogArticlesOrderBy {
  blog_category_id?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  photo_url?: Maybe<IOrderBy>;
  tag?: Maybe<IOrderBy>;
  text?: Maybe<IOrderBy>;
  title?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: blog_articles */
export interface IBlogArticlesPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "blog_articles" */
export enum IBlogArticlesSelectColumn {
  /** column name */
  BLOG_CATEGORY_ID = 'blog_category_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  PHOTO_URL = 'photo_url',
  /** column name */
  TAG = 'tag',
  /** column name */
  TEXT = 'text',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "blog_articles" */
export interface IBlogArticlesSetInput {
  blog_category_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  photo_url?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IBlogArticlesStddevFields {
   __typename?: 'blog_articles_stddev_fields';
  blog_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "blog_articles" */
export interface IBlogArticlesStddevOrderBy {
  blog_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IBlogArticlesStddevPopFields {
   __typename?: 'blog_articles_stddev_pop_fields';
  blog_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "blog_articles" */
export interface IBlogArticlesStddevPopOrderBy {
  blog_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IBlogArticlesStddevSampFields {
   __typename?: 'blog_articles_stddev_samp_fields';
  blog_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "blog_articles" */
export interface IBlogArticlesStddevSampOrderBy {
  blog_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IBlogArticlesSumFields {
   __typename?: 'blog_articles_sum_fields';
  blog_category_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "blog_articles" */
export interface IBlogArticlesSumOrderBy {
  blog_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** update columns of table "blog_articles" */
export enum IBlogArticlesUpdateColumn {
  /** column name */
  BLOG_CATEGORY_ID = 'blog_category_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  PHOTO_URL = 'photo_url',
  /** column name */
  TAG = 'tag',
  /** column name */
  TEXT = 'text',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IBlogArticlesVarPopFields {
   __typename?: 'blog_articles_var_pop_fields';
  blog_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "blog_articles" */
export interface IBlogArticlesVarPopOrderBy {
  blog_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IBlogArticlesVarSampFields {
   __typename?: 'blog_articles_var_samp_fields';
  blog_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "blog_articles" */
export interface IBlogArticlesVarSampOrderBy {
  blog_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IBlogArticlesVarianceFields {
   __typename?: 'blog_articles_variance_fields';
  blog_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "blog_articles" */
export interface IBlogArticlesVarianceOrderBy {
  blog_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** columns and relationships of "blog_categories" */
export interface IBlogCategories {
   __typename?: 'blog_categories';
  /** An array relationship */
  blog_articles: Array<IBlogArticles>;
  /** An aggregate relationship */
  blog_articles_aggregate: IBlogArticlesAggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
}


/** columns and relationships of "blog_categories" */
export interface IBlogCategoriesBlogArticlesArgs {
  distinct_on?: Maybe<Array<IBlogArticlesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IBlogArticlesOrderBy>>;
  where?: Maybe<IBlogArticlesBoolExp>;
}


/** columns and relationships of "blog_categories" */
export interface IBlogCategoriesBlogArticlesAggregateArgs {
  distinct_on?: Maybe<Array<IBlogArticlesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IBlogArticlesOrderBy>>;
  where?: Maybe<IBlogArticlesBoolExp>;
}

/** aggregated selection of "blog_categories" */
export interface IBlogCategoriesAggregate {
   __typename?: 'blog_categories_aggregate';
  aggregate?: Maybe<IBlogCategoriesAggregateFields>;
  nodes: Array<IBlogCategories>;
}

/** aggregate fields of "blog_categories" */
export interface IBlogCategoriesAggregateFields {
   __typename?: 'blog_categories_aggregate_fields';
  avg?: Maybe<IBlogCategoriesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IBlogCategoriesMaxFields>;
  min?: Maybe<IBlogCategoriesMinFields>;
  stddev?: Maybe<IBlogCategoriesStddevFields>;
  stddev_pop?: Maybe<IBlogCategoriesStddevPopFields>;
  stddev_samp?: Maybe<IBlogCategoriesStddevSampFields>;
  sum?: Maybe<IBlogCategoriesSumFields>;
  var_pop?: Maybe<IBlogCategoriesVarPopFields>;
  var_samp?: Maybe<IBlogCategoriesVarSampFields>;
  variance?: Maybe<IBlogCategoriesVarianceFields>;
}


/** aggregate fields of "blog_categories" */
export interface IBlogCategoriesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IBlogCategoriesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IBlogCategoriesAvgFields {
   __typename?: 'blog_categories_avg_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "blog_categories". All fields are combined with a logical 'AND'. */
export interface IBlogCategoriesBoolExp {
  _and?: Maybe<Array<IBlogCategoriesBoolExp>>;
  _not?: Maybe<IBlogCategoriesBoolExp>;
  _or?: Maybe<Array<IBlogCategoriesBoolExp>>;
  blog_articles?: Maybe<IBlogArticlesBoolExp>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "blog_categories" */
export enum IBlogCategoriesConstraint {
  /** unique or primary key constraint */
  BLOG_CATEGORIES_PKEY = 'blog_categories_pkey'
}

/** input type for incrementing numeric columns in table "blog_categories" */
export interface IBlogCategoriesIncInput {
  id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "blog_categories" */
export interface IBlogCategoriesInsertInput {
  blog_articles?: Maybe<IBlogArticlesArrRelInsertInput>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IBlogCategoriesMaxFields {
   __typename?: 'blog_categories_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IBlogCategoriesMinFields {
   __typename?: 'blog_categories_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "blog_categories" */
export interface IBlogCategoriesMutationResponse {
   __typename?: 'blog_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IBlogCategories>;
}

/** on conflict condition type for table "blog_categories" */
export interface IBlogCategoriesOnConflict {
  constraint: IBlogCategoriesConstraint;
  update_columns: Array<IBlogCategoriesUpdateColumn>;
  where?: Maybe<IBlogCategoriesBoolExp>;
}

/** Ordering options when selecting data from "blog_categories". */
export interface IBlogCategoriesOrderBy {
  blog_articles_aggregate?: Maybe<IBlogArticlesAggregateOrderBy>;
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
}

/** primary key columns input for table: blog_categories */
export interface IBlogCategoriesPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "blog_categories" */
export enum IBlogCategoriesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name'
}

/** input type for updating data in table "blog_categories" */
export interface IBlogCategoriesSetInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IBlogCategoriesStddevFields {
   __typename?: 'blog_categories_stddev_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IBlogCategoriesStddevPopFields {
   __typename?: 'blog_categories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IBlogCategoriesStddevSampFields {
   __typename?: 'blog_categories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IBlogCategoriesSumFields {
   __typename?: 'blog_categories_sum_fields';
  id?: Maybe<Scalars['Int']>;
}

/** update columns of table "blog_categories" */
export enum IBlogCategoriesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name'
}

/** aggregate var_pop on columns */
export interface IBlogCategoriesVarPopFields {
   __typename?: 'blog_categories_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IBlogCategoriesVarSampFields {
   __typename?: 'blog_categories_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IBlogCategoriesVarianceFields {
   __typename?: 'blog_categories_variance_fields';
  id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "cookbook_categories" */
export interface ICookbookCategories {
   __typename?: 'cookbook_categories';
  /** An array relationship */
  cookbook_recipes: Array<ICookbookRecipes>;
  /** An aggregate relationship */
  cookbook_recipes_aggregate: ICookbookRecipesAggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  photo_url: Scalars['String'];
}


/** columns and relationships of "cookbook_categories" */
export interface ICookbookCategoriesCookbookRecipesArgs {
  distinct_on?: Maybe<Array<ICookbookRecipesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICookbookRecipesOrderBy>>;
  where?: Maybe<ICookbookRecipesBoolExp>;
}


/** columns and relationships of "cookbook_categories" */
export interface ICookbookCategoriesCookbookRecipesAggregateArgs {
  distinct_on?: Maybe<Array<ICookbookRecipesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICookbookRecipesOrderBy>>;
  where?: Maybe<ICookbookRecipesBoolExp>;
}

/** aggregated selection of "cookbook_categories" */
export interface ICookbookCategoriesAggregate {
   __typename?: 'cookbook_categories_aggregate';
  aggregate?: Maybe<ICookbookCategoriesAggregateFields>;
  nodes: Array<ICookbookCategories>;
}

/** aggregate fields of "cookbook_categories" */
export interface ICookbookCategoriesAggregateFields {
   __typename?: 'cookbook_categories_aggregate_fields';
  avg?: Maybe<ICookbookCategoriesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ICookbookCategoriesMaxFields>;
  min?: Maybe<ICookbookCategoriesMinFields>;
  stddev?: Maybe<ICookbookCategoriesStddevFields>;
  stddev_pop?: Maybe<ICookbookCategoriesStddevPopFields>;
  stddev_samp?: Maybe<ICookbookCategoriesStddevSampFields>;
  sum?: Maybe<ICookbookCategoriesSumFields>;
  var_pop?: Maybe<ICookbookCategoriesVarPopFields>;
  var_samp?: Maybe<ICookbookCategoriesVarSampFields>;
  variance?: Maybe<ICookbookCategoriesVarianceFields>;
}


/** aggregate fields of "cookbook_categories" */
export interface ICookbookCategoriesAggregateFieldsCountArgs {
  columns?: Maybe<Array<ICookbookCategoriesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface ICookbookCategoriesAvgFields {
   __typename?: 'cookbook_categories_avg_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "cookbook_categories". All fields are combined with a logical 'AND'. */
export interface ICookbookCategoriesBoolExp {
  _and?: Maybe<Array<ICookbookCategoriesBoolExp>>;
  _not?: Maybe<ICookbookCategoriesBoolExp>;
  _or?: Maybe<Array<ICookbookCategoriesBoolExp>>;
  cookbook_recipes?: Maybe<ICookbookRecipesBoolExp>;
  id?: Maybe<IIntComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  photo_url?: Maybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "cookbook_categories" */
export enum ICookbookCategoriesConstraint {
  /** unique or primary key constraint */
  COOKBOOK_CATEGORIES_PKEY = 'cookbook_categories_pkey'
}

/** input type for incrementing numeric columns in table "cookbook_categories" */
export interface ICookbookCategoriesIncInput {
  id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "cookbook_categories" */
export interface ICookbookCategoriesInsertInput {
  cookbook_recipes?: Maybe<ICookbookRecipesArrRelInsertInput>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface ICookbookCategoriesMaxFields {
   __typename?: 'cookbook_categories_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface ICookbookCategoriesMinFields {
   __typename?: 'cookbook_categories_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "cookbook_categories" */
export interface ICookbookCategoriesMutationResponse {
   __typename?: 'cookbook_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ICookbookCategories>;
}

/** input type for inserting object relation for remote table "cookbook_categories" */
export interface ICookbookCategoriesObjRelInsertInput {
  data: ICookbookCategoriesInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<ICookbookCategoriesOnConflict>;
}

/** on conflict condition type for table "cookbook_categories" */
export interface ICookbookCategoriesOnConflict {
  constraint: ICookbookCategoriesConstraint;
  update_columns: Array<ICookbookCategoriesUpdateColumn>;
  where?: Maybe<ICookbookCategoriesBoolExp>;
}

/** Ordering options when selecting data from "cookbook_categories". */
export interface ICookbookCategoriesOrderBy {
  cookbook_recipes_aggregate?: Maybe<ICookbookRecipesAggregateOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  photo_url?: Maybe<IOrderBy>;
}

/** primary key columns input for table: cookbook_categories */
export interface ICookbookCategoriesPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "cookbook_categories" */
export enum ICookbookCategoriesSelectColumn {
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PHOTO_URL = 'photo_url'
}

/** input type for updating data in table "cookbook_categories" */
export interface ICookbookCategoriesSetInput {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface ICookbookCategoriesStddevFields {
   __typename?: 'cookbook_categories_stddev_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface ICookbookCategoriesStddevPopFields {
   __typename?: 'cookbook_categories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface ICookbookCategoriesStddevSampFields {
   __typename?: 'cookbook_categories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface ICookbookCategoriesSumFields {
   __typename?: 'cookbook_categories_sum_fields';
  id?: Maybe<Scalars['Int']>;
}

/** update columns of table "cookbook_categories" */
export enum ICookbookCategoriesUpdateColumn {
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PHOTO_URL = 'photo_url'
}

/** aggregate var_pop on columns */
export interface ICookbookCategoriesVarPopFields {
   __typename?: 'cookbook_categories_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface ICookbookCategoriesVarSampFields {
   __typename?: 'cookbook_categories_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface ICookbookCategoriesVarianceFields {
   __typename?: 'cookbook_categories_variance_fields';
  id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "cookbook_recipes" */
export interface ICookbookRecipes {
   __typename?: 'cookbook_recipes';
  cookbook_categories_id: Scalars['Int'];
  /** An object relationship */
  cookbook_category: ICookbookCategories;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  kcal?: Maybe<Scalars['Int']>;
  photo_urls: Scalars['_varchar'];
  title: Scalars['String'];
}

/** aggregated selection of "cookbook_recipes" */
export interface ICookbookRecipesAggregate {
   __typename?: 'cookbook_recipes_aggregate';
  aggregate?: Maybe<ICookbookRecipesAggregateFields>;
  nodes: Array<ICookbookRecipes>;
}

/** aggregate fields of "cookbook_recipes" */
export interface ICookbookRecipesAggregateFields {
   __typename?: 'cookbook_recipes_aggregate_fields';
  avg?: Maybe<ICookbookRecipesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ICookbookRecipesMaxFields>;
  min?: Maybe<ICookbookRecipesMinFields>;
  stddev?: Maybe<ICookbookRecipesStddevFields>;
  stddev_pop?: Maybe<ICookbookRecipesStddevPopFields>;
  stddev_samp?: Maybe<ICookbookRecipesStddevSampFields>;
  sum?: Maybe<ICookbookRecipesSumFields>;
  var_pop?: Maybe<ICookbookRecipesVarPopFields>;
  var_samp?: Maybe<ICookbookRecipesVarSampFields>;
  variance?: Maybe<ICookbookRecipesVarianceFields>;
}


/** aggregate fields of "cookbook_recipes" */
export interface ICookbookRecipesAggregateFieldsCountArgs {
  columns?: Maybe<Array<ICookbookRecipesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "cookbook_recipes" */
export interface ICookbookRecipesAggregateOrderBy {
  avg?: Maybe<ICookbookRecipesAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<ICookbookRecipesMaxOrderBy>;
  min?: Maybe<ICookbookRecipesMinOrderBy>;
  stddev?: Maybe<ICookbookRecipesStddevOrderBy>;
  stddev_pop?: Maybe<ICookbookRecipesStddevPopOrderBy>;
  stddev_samp?: Maybe<ICookbookRecipesStddevSampOrderBy>;
  sum?: Maybe<ICookbookRecipesSumOrderBy>;
  var_pop?: Maybe<ICookbookRecipesVarPopOrderBy>;
  var_samp?: Maybe<ICookbookRecipesVarSampOrderBy>;
  variance?: Maybe<ICookbookRecipesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "cookbook_recipes" */
export interface ICookbookRecipesArrRelInsertInput {
  data: Array<ICookbookRecipesInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<ICookbookRecipesOnConflict>;
}

/** aggregate avg on columns */
export interface ICookbookRecipesAvgFields {
   __typename?: 'cookbook_recipes_avg_fields';
  cookbook_categories_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kcal?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "cookbook_recipes" */
export interface ICookbookRecipesAvgOrderBy {
  cookbook_categories_id?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  kcal?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "cookbook_recipes". All fields are combined with a logical 'AND'. */
export interface ICookbookRecipesBoolExp {
  _and?: Maybe<Array<ICookbookRecipesBoolExp>>;
  _not?: Maybe<ICookbookRecipesBoolExp>;
  _or?: Maybe<Array<ICookbookRecipesBoolExp>>;
  cookbook_categories_id?: Maybe<IIntComparisonExp>;
  cookbook_category?: Maybe<ICookbookCategoriesBoolExp>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  description?: Maybe<IStringComparisonExp>;
  duration?: Maybe<IIntComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  kcal?: Maybe<IIntComparisonExp>;
  photo_urls?: Maybe<IVarcharComparisonExp>;
  title?: Maybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "cookbook_recipes" */
export enum ICookbookRecipesConstraint {
  /** unique or primary key constraint */
  COOKBOOK_RECIPES_PKEY = 'cookbook_recipes_pkey'
}

/** input type for incrementing numeric columns in table "cookbook_recipes" */
export interface ICookbookRecipesIncInput {
  cookbook_categories_id?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kcal?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "cookbook_recipes" */
export interface ICookbookRecipesInsertInput {
  cookbook_categories_id?: Maybe<Scalars['Int']>;
  cookbook_category?: Maybe<ICookbookCategoriesObjRelInsertInput>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kcal?: Maybe<Scalars['Int']>;
  photo_urls?: Maybe<Scalars['_varchar']>;
  title?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface ICookbookRecipesMaxFields {
   __typename?: 'cookbook_recipes_max_fields';
  cookbook_categories_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kcal?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "cookbook_recipes" */
export interface ICookbookRecipesMaxOrderBy {
  cookbook_categories_id?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  description?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  kcal?: Maybe<IOrderBy>;
  title?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface ICookbookRecipesMinFields {
   __typename?: 'cookbook_recipes_min_fields';
  cookbook_categories_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kcal?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "cookbook_recipes" */
export interface ICookbookRecipesMinOrderBy {
  cookbook_categories_id?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  description?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  kcal?: Maybe<IOrderBy>;
  title?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "cookbook_recipes" */
export interface ICookbookRecipesMutationResponse {
   __typename?: 'cookbook_recipes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ICookbookRecipes>;
}

/** on conflict condition type for table "cookbook_recipes" */
export interface ICookbookRecipesOnConflict {
  constraint: ICookbookRecipesConstraint;
  update_columns: Array<ICookbookRecipesUpdateColumn>;
  where?: Maybe<ICookbookRecipesBoolExp>;
}

/** Ordering options when selecting data from "cookbook_recipes". */
export interface ICookbookRecipesOrderBy {
  cookbook_categories_id?: Maybe<IOrderBy>;
  cookbook_category?: Maybe<ICookbookCategoriesOrderBy>;
  created_at?: Maybe<IOrderBy>;
  description?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  kcal?: Maybe<IOrderBy>;
  photo_urls?: Maybe<IOrderBy>;
  title?: Maybe<IOrderBy>;
}

/** primary key columns input for table: cookbook_recipes */
export interface ICookbookRecipesPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "cookbook_recipes" */
export enum ICookbookRecipesSelectColumn {
  /** column name */
  COOKBOOK_CATEGORIES_ID = 'cookbook_categories_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  ID = 'id',
  /** column name */
  KCAL = 'kcal',
  /** column name */
  PHOTO_URLS = 'photo_urls',
  /** column name */
  TITLE = 'title'
}

/** input type for updating data in table "cookbook_recipes" */
export interface ICookbookRecipesSetInput {
  cookbook_categories_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kcal?: Maybe<Scalars['Int']>;
  photo_urls?: Maybe<Scalars['_varchar']>;
  title?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface ICookbookRecipesStddevFields {
   __typename?: 'cookbook_recipes_stddev_fields';
  cookbook_categories_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kcal?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "cookbook_recipes" */
export interface ICookbookRecipesStddevOrderBy {
  cookbook_categories_id?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  kcal?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface ICookbookRecipesStddevPopFields {
   __typename?: 'cookbook_recipes_stddev_pop_fields';
  cookbook_categories_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kcal?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "cookbook_recipes" */
export interface ICookbookRecipesStddevPopOrderBy {
  cookbook_categories_id?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  kcal?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface ICookbookRecipesStddevSampFields {
   __typename?: 'cookbook_recipes_stddev_samp_fields';
  cookbook_categories_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kcal?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "cookbook_recipes" */
export interface ICookbookRecipesStddevSampOrderBy {
  cookbook_categories_id?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  kcal?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface ICookbookRecipesSumFields {
   __typename?: 'cookbook_recipes_sum_fields';
  cookbook_categories_id?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  kcal?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "cookbook_recipes" */
export interface ICookbookRecipesSumOrderBy {
  cookbook_categories_id?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  kcal?: Maybe<IOrderBy>;
}

/** update columns of table "cookbook_recipes" */
export enum ICookbookRecipesUpdateColumn {
  /** column name */
  COOKBOOK_CATEGORIES_ID = 'cookbook_categories_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  DURATION = 'duration',
  /** column name */
  ID = 'id',
  /** column name */
  KCAL = 'kcal',
  /** column name */
  PHOTO_URLS = 'photo_urls',
  /** column name */
  TITLE = 'title'
}

/** aggregate var_pop on columns */
export interface ICookbookRecipesVarPopFields {
   __typename?: 'cookbook_recipes_var_pop_fields';
  cookbook_categories_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kcal?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "cookbook_recipes" */
export interface ICookbookRecipesVarPopOrderBy {
  cookbook_categories_id?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  kcal?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface ICookbookRecipesVarSampFields {
   __typename?: 'cookbook_recipes_var_samp_fields';
  cookbook_categories_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kcal?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "cookbook_recipes" */
export interface ICookbookRecipesVarSampOrderBy {
  cookbook_categories_id?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  kcal?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface ICookbookRecipesVarianceFields {
   __typename?: 'cookbook_recipes_variance_fields';
  cookbook_categories_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kcal?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "cookbook_recipes" */
export interface ICookbookRecipesVarianceOrderBy {
  cookbook_categories_id?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  kcal?: Maybe<IOrderBy>;
}


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export interface IDateComparisonExp {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
}

/** columns and relationships of "diary_answers" */
export interface IDiaryAnswers {
   __typename?: 'diary_answers';
  answer: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  diary_question: IDiaryQuestions;
  id: Scalars['Int'];
  motivation?: Maybe<Scalars['String']>;
  question_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['bigint'];
}

/** aggregated selection of "diary_answers" */
export interface IDiaryAnswersAggregate {
   __typename?: 'diary_answers_aggregate';
  aggregate?: Maybe<IDiaryAnswersAggregateFields>;
  nodes: Array<IDiaryAnswers>;
}

/** aggregate fields of "diary_answers" */
export interface IDiaryAnswersAggregateFields {
   __typename?: 'diary_answers_aggregate_fields';
  avg?: Maybe<IDiaryAnswersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IDiaryAnswersMaxFields>;
  min?: Maybe<IDiaryAnswersMinFields>;
  stddev?: Maybe<IDiaryAnswersStddevFields>;
  stddev_pop?: Maybe<IDiaryAnswersStddevPopFields>;
  stddev_samp?: Maybe<IDiaryAnswersStddevSampFields>;
  sum?: Maybe<IDiaryAnswersSumFields>;
  var_pop?: Maybe<IDiaryAnswersVarPopFields>;
  var_samp?: Maybe<IDiaryAnswersVarSampFields>;
  variance?: Maybe<IDiaryAnswersVarianceFields>;
}


/** aggregate fields of "diary_answers" */
export interface IDiaryAnswersAggregateFieldsCountArgs {
  columns?: Maybe<Array<IDiaryAnswersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IDiaryAnswersAvgFields {
   __typename?: 'diary_answers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "diary_answers". All fields are combined with a logical 'AND'. */
export interface IDiaryAnswersBoolExp {
  _and?: Maybe<Array<IDiaryAnswersBoolExp>>;
  _not?: Maybe<IDiaryAnswersBoolExp>;
  _or?: Maybe<Array<IDiaryAnswersBoolExp>>;
  answer?: Maybe<IStringComparisonExp>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  diary_question?: Maybe<IDiaryQuestionsBoolExp>;
  id?: Maybe<IIntComparisonExp>;
  motivation?: Maybe<IStringComparisonExp>;
  question_id?: Maybe<IIntComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
  user?: Maybe<IUsersBoolExp>;
  user_id?: Maybe<IBigintComparisonExp>;
}

/** unique or primary key constraints on table "diary_answers" */
export enum IDiaryAnswersConstraint {
  /** unique or primary key constraint */
  DIARY_ANSWERS_PKEY = 'diary_answers_pkey'
}

/** input type for incrementing numeric columns in table "diary_answers" */
export interface IDiaryAnswersIncInput {
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "diary_answers" */
export interface IDiaryAnswersInsertInput {
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  diary_question?: Maybe<IDiaryQuestionsObjRelInsertInput>;
  id?: Maybe<Scalars['Int']>;
  motivation?: Maybe<Scalars['String']>;
  question_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<IUsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate max on columns */
export interface IDiaryAnswersMaxFields {
   __typename?: 'diary_answers_max_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  motivation?: Maybe<Scalars['String']>;
  question_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate min on columns */
export interface IDiaryAnswersMinFields {
   __typename?: 'diary_answers_min_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  motivation?: Maybe<Scalars['String']>;
  question_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** response of any mutation on the table "diary_answers" */
export interface IDiaryAnswersMutationResponse {
   __typename?: 'diary_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IDiaryAnswers>;
}

/** on conflict condition type for table "diary_answers" */
export interface IDiaryAnswersOnConflict {
  constraint: IDiaryAnswersConstraint;
  update_columns: Array<IDiaryAnswersUpdateColumn>;
  where?: Maybe<IDiaryAnswersBoolExp>;
}

/** Ordering options when selecting data from "diary_answers". */
export interface IDiaryAnswersOrderBy {
  answer?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  diary_question?: Maybe<IDiaryQuestionsOrderBy>;
  id?: Maybe<IOrderBy>;
  motivation?: Maybe<IOrderBy>;
  question_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user?: Maybe<IUsersOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** primary key columns input for table: diary_answers */
export interface IDiaryAnswersPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "diary_answers" */
export enum IDiaryAnswersSelectColumn {
  /** column name */
  ANSWER = 'answer',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  MOTIVATION = 'motivation',
  /** column name */
  QUESTION_ID = 'question_id',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "diary_answers" */
export interface IDiaryAnswersSetInput {
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  motivation?: Maybe<Scalars['String']>;
  question_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate stddev on columns */
export interface IDiaryAnswersStddevFields {
   __typename?: 'diary_answers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IDiaryAnswersStddevPopFields {
   __typename?: 'diary_answers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IDiaryAnswersStddevSampFields {
   __typename?: 'diary_answers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IDiaryAnswersSumFields {
   __typename?: 'diary_answers_sum_fields';
  id?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** update columns of table "diary_answers" */
export enum IDiaryAnswersUpdateColumn {
  /** column name */
  ANSWER = 'answer',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  MOTIVATION = 'motivation',
  /** column name */
  QUESTION_ID = 'question_id',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate var_pop on columns */
export interface IDiaryAnswersVarPopFields {
   __typename?: 'diary_answers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IDiaryAnswersVarSampFields {
   __typename?: 'diary_answers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IDiaryAnswersVarianceFields {
   __typename?: 'diary_answers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "diary_question_categories" */
export interface IDiaryQuestionCategories {
   __typename?: 'diary_question_categories';
  type: Scalars['String'];
}

/** aggregated selection of "diary_question_categories" */
export interface IDiaryQuestionCategoriesAggregate {
   __typename?: 'diary_question_categories_aggregate';
  aggregate?: Maybe<IDiaryQuestionCategoriesAggregateFields>;
  nodes: Array<IDiaryQuestionCategories>;
}

/** aggregate fields of "diary_question_categories" */
export interface IDiaryQuestionCategoriesAggregateFields {
   __typename?: 'diary_question_categories_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IDiaryQuestionCategoriesMaxFields>;
  min?: Maybe<IDiaryQuestionCategoriesMinFields>;
}


/** aggregate fields of "diary_question_categories" */
export interface IDiaryQuestionCategoriesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IDiaryQuestionCategoriesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "diary_question_categories". All fields are combined with a logical 'AND'. */
export interface IDiaryQuestionCategoriesBoolExp {
  _and?: Maybe<Array<IDiaryQuestionCategoriesBoolExp>>;
  _not?: Maybe<IDiaryQuestionCategoriesBoolExp>;
  _or?: Maybe<Array<IDiaryQuestionCategoriesBoolExp>>;
  type?: Maybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "diary_question_categories" */
export enum IDiaryQuestionCategoriesConstraint {
  /** unique or primary key constraint */
  DIARY_QUESTION_CATEGORIES_PKEY = 'diary_question_categories_pkey'
}

export enum IDiaryQuestionCategoriesEnum {
  GENERAL = 'GENERAL',
  MOTIVATION = 'MOTIVATION',
  MOVEMENT = 'MOVEMENT',
  NUTRITION = 'NUTRITION',
  RELAXATION_REST = 'RELAXATION_REST'
}

/** Boolean expression to compare columns of type "diary_question_categories_enum". All fields are combined with logical 'AND'. */
export interface IDiaryQuestionCategoriesEnumComparisonExp {
  _eq?: Maybe<IDiaryQuestionCategoriesEnum>;
  _in?: Maybe<Array<IDiaryQuestionCategoriesEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<IDiaryQuestionCategoriesEnum>;
  _nin?: Maybe<Array<IDiaryQuestionCategoriesEnum>>;
}

/** input type for inserting data into table "diary_question_categories" */
export interface IDiaryQuestionCategoriesInsertInput {
  type?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IDiaryQuestionCategoriesMaxFields {
   __typename?: 'diary_question_categories_max_fields';
  type?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IDiaryQuestionCategoriesMinFields {
   __typename?: 'diary_question_categories_min_fields';
  type?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "diary_question_categories" */
export interface IDiaryQuestionCategoriesMutationResponse {
   __typename?: 'diary_question_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IDiaryQuestionCategories>;
}

/** on conflict condition type for table "diary_question_categories" */
export interface IDiaryQuestionCategoriesOnConflict {
  constraint: IDiaryQuestionCategoriesConstraint;
  update_columns: Array<IDiaryQuestionCategoriesUpdateColumn>;
  where?: Maybe<IDiaryQuestionCategoriesBoolExp>;
}

/** Ordering options when selecting data from "diary_question_categories". */
export interface IDiaryQuestionCategoriesOrderBy {
  type?: Maybe<IOrderBy>;
}

/** primary key columns input for table: diary_question_categories */
export interface IDiaryQuestionCategoriesPkColumnsInput {
  type: Scalars['String'];
}

/** select columns of table "diary_question_categories" */
export enum IDiaryQuestionCategoriesSelectColumn {
  /** column name */
  TYPE = 'type'
}

/** input type for updating data in table "diary_question_categories" */
export interface IDiaryQuestionCategoriesSetInput {
  type?: Maybe<Scalars['String']>;
}

/** update columns of table "diary_question_categories" */
export enum IDiaryQuestionCategoriesUpdateColumn {
  /** column name */
  TYPE = 'type'
}

/** columns and relationships of "diary_question_types" */
export interface IDiaryQuestionTypes {
   __typename?: 'diary_question_types';
  type: Scalars['String'];
}

/** aggregated selection of "diary_question_types" */
export interface IDiaryQuestionTypesAggregate {
   __typename?: 'diary_question_types_aggregate';
  aggregate?: Maybe<IDiaryQuestionTypesAggregateFields>;
  nodes: Array<IDiaryQuestionTypes>;
}

/** aggregate fields of "diary_question_types" */
export interface IDiaryQuestionTypesAggregateFields {
   __typename?: 'diary_question_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IDiaryQuestionTypesMaxFields>;
  min?: Maybe<IDiaryQuestionTypesMinFields>;
}


/** aggregate fields of "diary_question_types" */
export interface IDiaryQuestionTypesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IDiaryQuestionTypesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "diary_question_types". All fields are combined with a logical 'AND'. */
export interface IDiaryQuestionTypesBoolExp {
  _and?: Maybe<Array<IDiaryQuestionTypesBoolExp>>;
  _not?: Maybe<IDiaryQuestionTypesBoolExp>;
  _or?: Maybe<Array<IDiaryQuestionTypesBoolExp>>;
  type?: Maybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "diary_question_types" */
export enum IDiaryQuestionTypesConstraint {
  /** unique or primary key constraint */
  DIARY_QUESTION_TYPES_PKEY = 'diary_question_types_pkey'
}

export enum IDiaryQuestionTypesEnum {
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  OPEN = 'OPEN',
  RANGE = 'RANGE',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  SINGLE_CHOICE_WITH_MOTIVATION = 'SINGLE_CHOICE_WITH_MOTIVATION'
}

/** Boolean expression to compare columns of type "diary_question_types_enum". All fields are combined with logical 'AND'. */
export interface IDiaryQuestionTypesEnumComparisonExp {
  _eq?: Maybe<IDiaryQuestionTypesEnum>;
  _in?: Maybe<Array<IDiaryQuestionTypesEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<IDiaryQuestionTypesEnum>;
  _nin?: Maybe<Array<IDiaryQuestionTypesEnum>>;
}

/** input type for inserting data into table "diary_question_types" */
export interface IDiaryQuestionTypesInsertInput {
  type?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IDiaryQuestionTypesMaxFields {
   __typename?: 'diary_question_types_max_fields';
  type?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IDiaryQuestionTypesMinFields {
   __typename?: 'diary_question_types_min_fields';
  type?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "diary_question_types" */
export interface IDiaryQuestionTypesMutationResponse {
   __typename?: 'diary_question_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IDiaryQuestionTypes>;
}

/** on conflict condition type for table "diary_question_types" */
export interface IDiaryQuestionTypesOnConflict {
  constraint: IDiaryQuestionTypesConstraint;
  update_columns: Array<IDiaryQuestionTypesUpdateColumn>;
  where?: Maybe<IDiaryQuestionTypesBoolExp>;
}

/** Ordering options when selecting data from "diary_question_types". */
export interface IDiaryQuestionTypesOrderBy {
  type?: Maybe<IOrderBy>;
}

/** primary key columns input for table: diary_question_types */
export interface IDiaryQuestionTypesPkColumnsInput {
  type: Scalars['String'];
}

/** select columns of table "diary_question_types" */
export enum IDiaryQuestionTypesSelectColumn {
  /** column name */
  TYPE = 'type'
}

/** input type for updating data in table "diary_question_types" */
export interface IDiaryQuestionTypesSetInput {
  type?: Maybe<Scalars['String']>;
}

/** update columns of table "diary_question_types" */
export enum IDiaryQuestionTypesUpdateColumn {
  /** column name */
  TYPE = 'type'
}

/**
 * Important note for column answers; When requires motivation we should pass an array with options as follow: ["Option A", "Question when A is selected", "Option B", "null"] Always pass a null or String next to each option. Do this only when type is equal to SINGLE_CHOICE_WITH_MOTIVATION and motivation required!
 * 
 * 
 * columns and relationships of "diary_questions"
 */
export interface IDiaryQuestions {
   __typename?: 'diary_questions';
  answers?: Maybe<Scalars['_varchar']>;
  category: IDiaryQuestionCategoriesEnum;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  question: Scalars['String'];
  range_from?: Maybe<Scalars['Int']>;
  range_to?: Maybe<Scalars['Int']>;
  requires_motivation: Scalars['Boolean'];
  type: IDiaryQuestionTypesEnum;
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "diary_questions" */
export interface IDiaryQuestionsAggregate {
   __typename?: 'diary_questions_aggregate';
  aggregate?: Maybe<IDiaryQuestionsAggregateFields>;
  nodes: Array<IDiaryQuestions>;
}

/** aggregate fields of "diary_questions" */
export interface IDiaryQuestionsAggregateFields {
   __typename?: 'diary_questions_aggregate_fields';
  avg?: Maybe<IDiaryQuestionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IDiaryQuestionsMaxFields>;
  min?: Maybe<IDiaryQuestionsMinFields>;
  stddev?: Maybe<IDiaryQuestionsStddevFields>;
  stddev_pop?: Maybe<IDiaryQuestionsStddevPopFields>;
  stddev_samp?: Maybe<IDiaryQuestionsStddevSampFields>;
  sum?: Maybe<IDiaryQuestionsSumFields>;
  var_pop?: Maybe<IDiaryQuestionsVarPopFields>;
  var_samp?: Maybe<IDiaryQuestionsVarSampFields>;
  variance?: Maybe<IDiaryQuestionsVarianceFields>;
}


/** aggregate fields of "diary_questions" */
export interface IDiaryQuestionsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IDiaryQuestionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IDiaryQuestionsAvgFields {
   __typename?: 'diary_questions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  range_from?: Maybe<Scalars['Float']>;
  range_to?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "diary_questions". All fields are combined with a logical 'AND'. */
export interface IDiaryQuestionsBoolExp {
  _and?: Maybe<Array<IDiaryQuestionsBoolExp>>;
  _not?: Maybe<IDiaryQuestionsBoolExp>;
  _or?: Maybe<Array<IDiaryQuestionsBoolExp>>;
  answers?: Maybe<IVarcharComparisonExp>;
  category?: Maybe<IDiaryQuestionCategoriesEnumComparisonExp>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  question?: Maybe<IStringComparisonExp>;
  range_from?: Maybe<IIntComparisonExp>;
  range_to?: Maybe<IIntComparisonExp>;
  requires_motivation?: Maybe<IBooleanComparisonExp>;
  type?: Maybe<IDiaryQuestionTypesEnumComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "diary_questions" */
export enum IDiaryQuestionsConstraint {
  /** unique or primary key constraint */
  DIARY_QUESTIONS_PKEY = 'diary_questions_pkey'
}

/** input type for incrementing numeric columns in table "diary_questions" */
export interface IDiaryQuestionsIncInput {
  id?: Maybe<Scalars['Int']>;
  range_from?: Maybe<Scalars['Int']>;
  range_to?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "diary_questions" */
export interface IDiaryQuestionsInsertInput {
  answers?: Maybe<Scalars['_varchar']>;
  category?: Maybe<IDiaryQuestionCategoriesEnum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  range_from?: Maybe<Scalars['Int']>;
  range_to?: Maybe<Scalars['Int']>;
  requires_motivation?: Maybe<Scalars['Boolean']>;
  type?: Maybe<IDiaryQuestionTypesEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IDiaryQuestionsMaxFields {
   __typename?: 'diary_questions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  range_from?: Maybe<Scalars['Int']>;
  range_to?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface IDiaryQuestionsMinFields {
   __typename?: 'diary_questions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  range_from?: Maybe<Scalars['Int']>;
  range_to?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "diary_questions" */
export interface IDiaryQuestionsMutationResponse {
   __typename?: 'diary_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IDiaryQuestions>;
}

/** input type for inserting object relation for remote table "diary_questions" */
export interface IDiaryQuestionsObjRelInsertInput {
  data: IDiaryQuestionsInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IDiaryQuestionsOnConflict>;
}

/** on conflict condition type for table "diary_questions" */
export interface IDiaryQuestionsOnConflict {
  constraint: IDiaryQuestionsConstraint;
  update_columns: Array<IDiaryQuestionsUpdateColumn>;
  where?: Maybe<IDiaryQuestionsBoolExp>;
}

/** Ordering options when selecting data from "diary_questions". */
export interface IDiaryQuestionsOrderBy {
  answers?: Maybe<IOrderBy>;
  category?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  question?: Maybe<IOrderBy>;
  range_from?: Maybe<IOrderBy>;
  range_to?: Maybe<IOrderBy>;
  requires_motivation?: Maybe<IOrderBy>;
  type?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: diary_questions */
export interface IDiaryQuestionsPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "diary_questions" */
export enum IDiaryQuestionsSelectColumn {
  /** column name */
  ANSWERS = 'answers',
  /** column name */
  CATEGORY = 'category',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  QUESTION = 'question',
  /** column name */
  RANGE_FROM = 'range_from',
  /** column name */
  RANGE_TO = 'range_to',
  /** column name */
  REQUIRES_MOTIVATION = 'requires_motivation',
  /** column name */
  TYPE = 'type',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "diary_questions" */
export interface IDiaryQuestionsSetInput {
  answers?: Maybe<Scalars['_varchar']>;
  category?: Maybe<IDiaryQuestionCategoriesEnum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  range_from?: Maybe<Scalars['Int']>;
  range_to?: Maybe<Scalars['Int']>;
  requires_motivation?: Maybe<Scalars['Boolean']>;
  type?: Maybe<IDiaryQuestionTypesEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IDiaryQuestionsStddevFields {
   __typename?: 'diary_questions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  range_from?: Maybe<Scalars['Float']>;
  range_to?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IDiaryQuestionsStddevPopFields {
   __typename?: 'diary_questions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  range_from?: Maybe<Scalars['Float']>;
  range_to?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IDiaryQuestionsStddevSampFields {
   __typename?: 'diary_questions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  range_from?: Maybe<Scalars['Float']>;
  range_to?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IDiaryQuestionsSumFields {
   __typename?: 'diary_questions_sum_fields';
  id?: Maybe<Scalars['Int']>;
  range_from?: Maybe<Scalars['Int']>;
  range_to?: Maybe<Scalars['Int']>;
}

/** update columns of table "diary_questions" */
export enum IDiaryQuestionsUpdateColumn {
  /** column name */
  ANSWERS = 'answers',
  /** column name */
  CATEGORY = 'category',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  QUESTION = 'question',
  /** column name */
  RANGE_FROM = 'range_from',
  /** column name */
  RANGE_TO = 'range_to',
  /** column name */
  REQUIRES_MOTIVATION = 'requires_motivation',
  /** column name */
  TYPE = 'type',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IDiaryQuestionsVarPopFields {
   __typename?: 'diary_questions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  range_from?: Maybe<Scalars['Float']>;
  range_to?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IDiaryQuestionsVarSampFields {
   __typename?: 'diary_questions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  range_from?: Maybe<Scalars['Float']>;
  range_to?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IDiaryQuestionsVarianceFields {
   __typename?: 'diary_questions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  range_from?: Maybe<Scalars['Float']>;
  range_to?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "diet_summaries" */
export interface IDietSummaries {
   __typename?: 'diet_summaries';
  activity_level?: Maybe<Scalars['numeric']>;
  fat_range?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  kcal_deficiency?: Maybe<Scalars['Int']>;
  personal_goal?: Maybe<Scalars['Int']>;
  protein_range?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  user_profile: IUserProfiles;
  user_profile_id: Scalars['bigint'];
}

/** aggregated selection of "diet_summaries" */
export interface IDietSummariesAggregate {
   __typename?: 'diet_summaries_aggregate';
  aggregate?: Maybe<IDietSummariesAggregateFields>;
  nodes: Array<IDietSummaries>;
}

/** aggregate fields of "diet_summaries" */
export interface IDietSummariesAggregateFields {
   __typename?: 'diet_summaries_aggregate_fields';
  avg?: Maybe<IDietSummariesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IDietSummariesMaxFields>;
  min?: Maybe<IDietSummariesMinFields>;
  stddev?: Maybe<IDietSummariesStddevFields>;
  stddev_pop?: Maybe<IDietSummariesStddevPopFields>;
  stddev_samp?: Maybe<IDietSummariesStddevSampFields>;
  sum?: Maybe<IDietSummariesSumFields>;
  var_pop?: Maybe<IDietSummariesVarPopFields>;
  var_samp?: Maybe<IDietSummariesVarSampFields>;
  variance?: Maybe<IDietSummariesVarianceFields>;
}


/** aggregate fields of "diet_summaries" */
export interface IDietSummariesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IDietSummariesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IDietSummariesAvgFields {
   __typename?: 'diet_summaries_avg_fields';
  activity_level?: Maybe<Scalars['Float']>;
  fat_range?: Maybe<Scalars['Float']>;
  kcal_deficiency?: Maybe<Scalars['Float']>;
  personal_goal?: Maybe<Scalars['Float']>;
  protein_range?: Maybe<Scalars['Float']>;
  user_profile_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "diet_summaries". All fields are combined with a logical 'AND'. */
export interface IDietSummariesBoolExp {
  _and?: Maybe<Array<IDietSummariesBoolExp>>;
  _not?: Maybe<IDietSummariesBoolExp>;
  _or?: Maybe<Array<IDietSummariesBoolExp>>;
  activity_level?: Maybe<INumericComparisonExp>;
  fat_range?: Maybe<INumericComparisonExp>;
  id?: Maybe<IUuidComparisonExp>;
  kcal_deficiency?: Maybe<IIntComparisonExp>;
  personal_goal?: Maybe<IIntComparisonExp>;
  protein_range?: Maybe<INumericComparisonExp>;
  user_profile?: Maybe<IUserProfilesBoolExp>;
  user_profile_id?: Maybe<IBigintComparisonExp>;
}

/** unique or primary key constraints on table "diet_summaries" */
export enum IDietSummariesConstraint {
  /** unique or primary key constraint */
  DIET_SUMMARY_PKEY = 'diet_summary_pkey',
  /** unique or primary key constraint */
  DIET_SUMMARY_USER_PROFILE_ID_KEY = 'diet_summary_user_profile_id_key'
}

/** input type for incrementing numeric columns in table "diet_summaries" */
export interface IDietSummariesIncInput {
  activity_level?: Maybe<Scalars['numeric']>;
  fat_range?: Maybe<Scalars['numeric']>;
  kcal_deficiency?: Maybe<Scalars['Int']>;
  personal_goal?: Maybe<Scalars['Int']>;
  protein_range?: Maybe<Scalars['numeric']>;
  user_profile_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "diet_summaries" */
export interface IDietSummariesInsertInput {
  activity_level?: Maybe<Scalars['numeric']>;
  fat_range?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  kcal_deficiency?: Maybe<Scalars['Int']>;
  personal_goal?: Maybe<Scalars['Int']>;
  protein_range?: Maybe<Scalars['numeric']>;
  user_profile?: Maybe<IUserProfilesObjRelInsertInput>;
  user_profile_id?: Maybe<Scalars['bigint']>;
}

/** aggregate max on columns */
export interface IDietSummariesMaxFields {
   __typename?: 'diet_summaries_max_fields';
  activity_level?: Maybe<Scalars['numeric']>;
  fat_range?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  kcal_deficiency?: Maybe<Scalars['Int']>;
  personal_goal?: Maybe<Scalars['Int']>;
  protein_range?: Maybe<Scalars['numeric']>;
  user_profile_id?: Maybe<Scalars['bigint']>;
}

/** aggregate min on columns */
export interface IDietSummariesMinFields {
   __typename?: 'diet_summaries_min_fields';
  activity_level?: Maybe<Scalars['numeric']>;
  fat_range?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  kcal_deficiency?: Maybe<Scalars['Int']>;
  personal_goal?: Maybe<Scalars['Int']>;
  protein_range?: Maybe<Scalars['numeric']>;
  user_profile_id?: Maybe<Scalars['bigint']>;
}

/** response of any mutation on the table "diet_summaries" */
export interface IDietSummariesMutationResponse {
   __typename?: 'diet_summaries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IDietSummaries>;
}

/** input type for inserting object relation for remote table "diet_summaries" */
export interface IDietSummariesObjRelInsertInput {
  data: IDietSummariesInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IDietSummariesOnConflict>;
}

/** on conflict condition type for table "diet_summaries" */
export interface IDietSummariesOnConflict {
  constraint: IDietSummariesConstraint;
  update_columns: Array<IDietSummariesUpdateColumn>;
  where?: Maybe<IDietSummariesBoolExp>;
}

/** Ordering options when selecting data from "diet_summaries". */
export interface IDietSummariesOrderBy {
  activity_level?: Maybe<IOrderBy>;
  fat_range?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  kcal_deficiency?: Maybe<IOrderBy>;
  personal_goal?: Maybe<IOrderBy>;
  protein_range?: Maybe<IOrderBy>;
  user_profile?: Maybe<IUserProfilesOrderBy>;
  user_profile_id?: Maybe<IOrderBy>;
}

/** primary key columns input for table: diet_summaries */
export interface IDietSummariesPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "diet_summaries" */
export enum IDietSummariesSelectColumn {
  /** column name */
  ACTIVITY_LEVEL = 'activity_level',
  /** column name */
  FAT_RANGE = 'fat_range',
  /** column name */
  ID = 'id',
  /** column name */
  KCAL_DEFICIENCY = 'kcal_deficiency',
  /** column name */
  PERSONAL_GOAL = 'personal_goal',
  /** column name */
  PROTEIN_RANGE = 'protein_range',
  /** column name */
  USER_PROFILE_ID = 'user_profile_id'
}

/** input type for updating data in table "diet_summaries" */
export interface IDietSummariesSetInput {
  activity_level?: Maybe<Scalars['numeric']>;
  fat_range?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  kcal_deficiency?: Maybe<Scalars['Int']>;
  personal_goal?: Maybe<Scalars['Int']>;
  protein_range?: Maybe<Scalars['numeric']>;
  user_profile_id?: Maybe<Scalars['bigint']>;
}

/** aggregate stddev on columns */
export interface IDietSummariesStddevFields {
   __typename?: 'diet_summaries_stddev_fields';
  activity_level?: Maybe<Scalars['Float']>;
  fat_range?: Maybe<Scalars['Float']>;
  kcal_deficiency?: Maybe<Scalars['Float']>;
  personal_goal?: Maybe<Scalars['Float']>;
  protein_range?: Maybe<Scalars['Float']>;
  user_profile_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IDietSummariesStddevPopFields {
   __typename?: 'diet_summaries_stddev_pop_fields';
  activity_level?: Maybe<Scalars['Float']>;
  fat_range?: Maybe<Scalars['Float']>;
  kcal_deficiency?: Maybe<Scalars['Float']>;
  personal_goal?: Maybe<Scalars['Float']>;
  protein_range?: Maybe<Scalars['Float']>;
  user_profile_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IDietSummariesStddevSampFields {
   __typename?: 'diet_summaries_stddev_samp_fields';
  activity_level?: Maybe<Scalars['Float']>;
  fat_range?: Maybe<Scalars['Float']>;
  kcal_deficiency?: Maybe<Scalars['Float']>;
  personal_goal?: Maybe<Scalars['Float']>;
  protein_range?: Maybe<Scalars['Float']>;
  user_profile_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IDietSummariesSumFields {
   __typename?: 'diet_summaries_sum_fields';
  activity_level?: Maybe<Scalars['numeric']>;
  fat_range?: Maybe<Scalars['numeric']>;
  kcal_deficiency?: Maybe<Scalars['Int']>;
  personal_goal?: Maybe<Scalars['Int']>;
  protein_range?: Maybe<Scalars['numeric']>;
  user_profile_id?: Maybe<Scalars['bigint']>;
}

/** update columns of table "diet_summaries" */
export enum IDietSummariesUpdateColumn {
  /** column name */
  ACTIVITY_LEVEL = 'activity_level',
  /** column name */
  FAT_RANGE = 'fat_range',
  /** column name */
  ID = 'id',
  /** column name */
  KCAL_DEFICIENCY = 'kcal_deficiency',
  /** column name */
  PERSONAL_GOAL = 'personal_goal',
  /** column name */
  PROTEIN_RANGE = 'protein_range',
  /** column name */
  USER_PROFILE_ID = 'user_profile_id'
}

/** aggregate var_pop on columns */
export interface IDietSummariesVarPopFields {
   __typename?: 'diet_summaries_var_pop_fields';
  activity_level?: Maybe<Scalars['Float']>;
  fat_range?: Maybe<Scalars['Float']>;
  kcal_deficiency?: Maybe<Scalars['Float']>;
  personal_goal?: Maybe<Scalars['Float']>;
  protein_range?: Maybe<Scalars['Float']>;
  user_profile_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IDietSummariesVarSampFields {
   __typename?: 'diet_summaries_var_samp_fields';
  activity_level?: Maybe<Scalars['Float']>;
  fat_range?: Maybe<Scalars['Float']>;
  kcal_deficiency?: Maybe<Scalars['Float']>;
  personal_goal?: Maybe<Scalars['Float']>;
  protein_range?: Maybe<Scalars['Float']>;
  user_profile_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IDietSummariesVarianceFields {
   __typename?: 'diet_summaries_variance_fields';
  activity_level?: Maybe<Scalars['Float']>;
  fat_range?: Maybe<Scalars['Float']>;
  kcal_deficiency?: Maybe<Scalars['Float']>;
  personal_goal?: Maybe<Scalars['Float']>;
  protein_range?: Maybe<Scalars['Float']>;
  user_profile_id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "errors" */
export interface IErrors {
   __typename?: 'errors';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  message: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['Int'];
}

/** aggregated selection of "errors" */
export interface IErrorsAggregate {
   __typename?: 'errors_aggregate';
  aggregate?: Maybe<IErrorsAggregateFields>;
  nodes: Array<IErrors>;
}

/** aggregate fields of "errors" */
export interface IErrorsAggregateFields {
   __typename?: 'errors_aggregate_fields';
  avg?: Maybe<IErrorsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IErrorsMaxFields>;
  min?: Maybe<IErrorsMinFields>;
  stddev?: Maybe<IErrorsStddevFields>;
  stddev_pop?: Maybe<IErrorsStddevPopFields>;
  stddev_samp?: Maybe<IErrorsStddevSampFields>;
  sum?: Maybe<IErrorsSumFields>;
  var_pop?: Maybe<IErrorsVarPopFields>;
  var_samp?: Maybe<IErrorsVarSampFields>;
  variance?: Maybe<IErrorsVarianceFields>;
}


/** aggregate fields of "errors" */
export interface IErrorsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IErrorsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IErrorsAvgFields {
   __typename?: 'errors_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "errors". All fields are combined with a logical 'AND'. */
export interface IErrorsBoolExp {
  _and?: Maybe<Array<IErrorsBoolExp>>;
  _not?: Maybe<IErrorsBoolExp>;
  _or?: Maybe<Array<IErrorsBoolExp>>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  message?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
  user_id?: Maybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "errors" */
export enum IErrorsConstraint {
  /** unique or primary key constraint */
  ERRORS_PKEY = 'errors_pkey'
}

/** input type for incrementing numeric columns in table "errors" */
export interface IErrorsIncInput {
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "errors" */
export interface IErrorsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface IErrorsMaxFields {
   __typename?: 'errors_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate min on columns */
export interface IErrorsMinFields {
   __typename?: 'errors_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** response of any mutation on the table "errors" */
export interface IErrorsMutationResponse {
   __typename?: 'errors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IErrors>;
}

/** on conflict condition type for table "errors" */
export interface IErrorsOnConflict {
  constraint: IErrorsConstraint;
  update_columns: Array<IErrorsUpdateColumn>;
  where?: Maybe<IErrorsBoolExp>;
}

/** Ordering options when selecting data from "errors". */
export interface IErrorsOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  message?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** primary key columns input for table: errors */
export interface IErrorsPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "errors" */
export enum IErrorsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "errors" */
export interface IErrorsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface IErrorsStddevFields {
   __typename?: 'errors_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IErrorsStddevPopFields {
   __typename?: 'errors_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IErrorsStddevSampFields {
   __typename?: 'errors_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IErrorsSumFields {
   __typename?: 'errors_sum_fields';
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** update columns of table "errors" */
export enum IErrorsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate var_pop on columns */
export interface IErrorsVarPopFields {
   __typename?: 'errors_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IErrorsVarSampFields {
   __typename?: 'errors_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IErrorsVarianceFields {
   __typename?: 'errors_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "events" */
export interface IEvents {
   __typename?: 'events';
  created_at: Scalars['timestamp'];
  ends_at?: Maybe<Scalars['timestamp']>;
  event_source_id?: Maybe<Scalars['Int']>;
  event_source_type?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  starts_at?: Maybe<Scalars['timestamp']>;
  state?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
}

/** aggregated selection of "events" */
export interface IEventsAggregate {
   __typename?: 'events_aggregate';
  aggregate?: Maybe<IEventsAggregateFields>;
  nodes: Array<IEvents>;
}

/** aggregate fields of "events" */
export interface IEventsAggregateFields {
   __typename?: 'events_aggregate_fields';
  avg?: Maybe<IEventsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IEventsMaxFields>;
  min?: Maybe<IEventsMinFields>;
  stddev?: Maybe<IEventsStddevFields>;
  stddev_pop?: Maybe<IEventsStddevPopFields>;
  stddev_samp?: Maybe<IEventsStddevSampFields>;
  sum?: Maybe<IEventsSumFields>;
  var_pop?: Maybe<IEventsVarPopFields>;
  var_samp?: Maybe<IEventsVarSampFields>;
  variance?: Maybe<IEventsVarianceFields>;
}


/** aggregate fields of "events" */
export interface IEventsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IEventsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IEventsAvgFields {
   __typename?: 'events_avg_fields';
  event_source_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export interface IEventsBoolExp {
  _and?: Maybe<Array<IEventsBoolExp>>;
  _not?: Maybe<IEventsBoolExp>;
  _or?: Maybe<Array<IEventsBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  ends_at?: Maybe<ITimestampComparisonExp>;
  event_source_id?: Maybe<IIntComparisonExp>;
  event_source_type?: Maybe<IStringComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  starts_at?: Maybe<ITimestampComparisonExp>;
  state?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "events" */
export enum IEventsConstraint {
  /** unique or primary key constraint */
  EVENTS_PKEY = 'events_pkey'
}

/** input type for incrementing numeric columns in table "events" */
export interface IEventsIncInput {
  event_source_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "events" */
export interface IEventsInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  ends_at?: Maybe<Scalars['timestamp']>;
  event_source_id?: Maybe<Scalars['Int']>;
  event_source_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  starts_at?: Maybe<Scalars['timestamp']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IEventsMaxFields {
   __typename?: 'events_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  ends_at?: Maybe<Scalars['timestamp']>;
  event_source_id?: Maybe<Scalars['Int']>;
  event_source_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  starts_at?: Maybe<Scalars['timestamp']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate min on columns */
export interface IEventsMinFields {
   __typename?: 'events_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  ends_at?: Maybe<Scalars['timestamp']>;
  event_source_id?: Maybe<Scalars['Int']>;
  event_source_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  starts_at?: Maybe<Scalars['timestamp']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** response of any mutation on the table "events" */
export interface IEventsMutationResponse {
   __typename?: 'events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IEvents>;
}

/** on conflict condition type for table "events" */
export interface IEventsOnConflict {
  constraint: IEventsConstraint;
  update_columns: Array<IEventsUpdateColumn>;
  where?: Maybe<IEventsBoolExp>;
}

/** Ordering options when selecting data from "events". */
export interface IEventsOrderBy {
  created_at?: Maybe<IOrderBy>;
  ends_at?: Maybe<IOrderBy>;
  event_source_id?: Maybe<IOrderBy>;
  event_source_type?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  starts_at?: Maybe<IOrderBy>;
  state?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: events */
export interface IEventsPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "events" */
export enum IEventsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ENDS_AT = 'ends_at',
  /** column name */
  EVENT_SOURCE_ID = 'event_source_id',
  /** column name */
  EVENT_SOURCE_TYPE = 'event_source_type',
  /** column name */
  ID = 'id',
  /** column name */
  STARTS_AT = 'starts_at',
  /** column name */
  STATE = 'state',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "events" */
export interface IEventsSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  ends_at?: Maybe<Scalars['timestamp']>;
  event_source_id?: Maybe<Scalars['Int']>;
  event_source_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  starts_at?: Maybe<Scalars['timestamp']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IEventsStddevFields {
   __typename?: 'events_stddev_fields';
  event_source_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IEventsStddevPopFields {
   __typename?: 'events_stddev_pop_fields';
  event_source_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IEventsStddevSampFields {
   __typename?: 'events_stddev_samp_fields';
  event_source_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IEventsSumFields {
   __typename?: 'events_sum_fields';
  event_source_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
}

/** update columns of table "events" */
export enum IEventsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ENDS_AT = 'ends_at',
  /** column name */
  EVENT_SOURCE_ID = 'event_source_id',
  /** column name */
  EVENT_SOURCE_TYPE = 'event_source_type',
  /** column name */
  ID = 'id',
  /** column name */
  STARTS_AT = 'starts_at',
  /** column name */
  STATE = 'state',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IEventsVarPopFields {
   __typename?: 'events_var_pop_fields';
  event_source_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IEventsVarSampFields {
   __typename?: 'events_var_samp_fields';
  event_source_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IEventsVarianceFields {
   __typename?: 'events_variance_fields';
  event_source_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "exercise_day_progressions" */
export interface IExerciseDayProgressions {
   __typename?: 'exercise_day_progressions';
  created_at: Scalars['timestamptz'];
  exercise_day_id: Scalars['bigint'];
  id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['bigint'];
}

/** aggregated selection of "exercise_day_progressions" */
export interface IExerciseDayProgressionsAggregate {
   __typename?: 'exercise_day_progressions_aggregate';
  aggregate?: Maybe<IExerciseDayProgressionsAggregateFields>;
  nodes: Array<IExerciseDayProgressions>;
}

/** aggregate fields of "exercise_day_progressions" */
export interface IExerciseDayProgressionsAggregateFields {
   __typename?: 'exercise_day_progressions_aggregate_fields';
  avg?: Maybe<IExerciseDayProgressionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IExerciseDayProgressionsMaxFields>;
  min?: Maybe<IExerciseDayProgressionsMinFields>;
  stddev?: Maybe<IExerciseDayProgressionsStddevFields>;
  stddev_pop?: Maybe<IExerciseDayProgressionsStddevPopFields>;
  stddev_samp?: Maybe<IExerciseDayProgressionsStddevSampFields>;
  sum?: Maybe<IExerciseDayProgressionsSumFields>;
  var_pop?: Maybe<IExerciseDayProgressionsVarPopFields>;
  var_samp?: Maybe<IExerciseDayProgressionsVarSampFields>;
  variance?: Maybe<IExerciseDayProgressionsVarianceFields>;
}


/** aggregate fields of "exercise_day_progressions" */
export interface IExerciseDayProgressionsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IExerciseDayProgressionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "exercise_day_progressions" */
export interface IExerciseDayProgressionsAggregateOrderBy {
  avg?: Maybe<IExerciseDayProgressionsAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IExerciseDayProgressionsMaxOrderBy>;
  min?: Maybe<IExerciseDayProgressionsMinOrderBy>;
  stddev?: Maybe<IExerciseDayProgressionsStddevOrderBy>;
  stddev_pop?: Maybe<IExerciseDayProgressionsStddevPopOrderBy>;
  stddev_samp?: Maybe<IExerciseDayProgressionsStddevSampOrderBy>;
  sum?: Maybe<IExerciseDayProgressionsSumOrderBy>;
  var_pop?: Maybe<IExerciseDayProgressionsVarPopOrderBy>;
  var_samp?: Maybe<IExerciseDayProgressionsVarSampOrderBy>;
  variance?: Maybe<IExerciseDayProgressionsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "exercise_day_progressions" */
export interface IExerciseDayProgressionsArrRelInsertInput {
  data: Array<IExerciseDayProgressionsInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IExerciseDayProgressionsOnConflict>;
}

/** aggregate avg on columns */
export interface IExerciseDayProgressionsAvgFields {
   __typename?: 'exercise_day_progressions_avg_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "exercise_day_progressions" */
export interface IExerciseDayProgressionsAvgOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "exercise_day_progressions". All fields are combined with a logical 'AND'. */
export interface IExerciseDayProgressionsBoolExp {
  _and?: Maybe<Array<IExerciseDayProgressionsBoolExp>>;
  _not?: Maybe<IExerciseDayProgressionsBoolExp>;
  _or?: Maybe<Array<IExerciseDayProgressionsBoolExp>>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  exercise_day_id?: Maybe<IBigintComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
  user_id?: Maybe<IBigintComparisonExp>;
}

/** unique or primary key constraints on table "exercise_day_progressions" */
export enum IExerciseDayProgressionsConstraint {
  /** unique or primary key constraint */
  EXERCISE_PROGRESSIONS_PKEY = 'exercise_progressions_pkey'
}

/** input type for incrementing numeric columns in table "exercise_day_progressions" */
export interface IExerciseDayProgressionsIncInput {
  exercise_day_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "exercise_day_progressions" */
export interface IExerciseDayProgressionsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  exercise_day_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate max on columns */
export interface IExerciseDayProgressionsMaxFields {
   __typename?: 'exercise_day_progressions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  exercise_day_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** order by max() on columns of table "exercise_day_progressions" */
export interface IExerciseDayProgressionsMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IExerciseDayProgressionsMinFields {
   __typename?: 'exercise_day_progressions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  exercise_day_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** order by min() on columns of table "exercise_day_progressions" */
export interface IExerciseDayProgressionsMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "exercise_day_progressions" */
export interface IExerciseDayProgressionsMutationResponse {
   __typename?: 'exercise_day_progressions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IExerciseDayProgressions>;
}

/** on conflict condition type for table "exercise_day_progressions" */
export interface IExerciseDayProgressionsOnConflict {
  constraint: IExerciseDayProgressionsConstraint;
  update_columns: Array<IExerciseDayProgressionsUpdateColumn>;
  where?: Maybe<IExerciseDayProgressionsBoolExp>;
}

/** Ordering options when selecting data from "exercise_day_progressions". */
export interface IExerciseDayProgressionsOrderBy {
  created_at?: Maybe<IOrderBy>;
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** primary key columns input for table: exercise_day_progressions */
export interface IExerciseDayProgressionsPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "exercise_day_progressions" */
export enum IExerciseDayProgressionsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EXERCISE_DAY_ID = 'exercise_day_id',
  /** column name */
  ID = 'id',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "exercise_day_progressions" */
export interface IExerciseDayProgressionsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  exercise_day_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate stddev on columns */
export interface IExerciseDayProgressionsStddevFields {
   __typename?: 'exercise_day_progressions_stddev_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "exercise_day_progressions" */
export interface IExerciseDayProgressionsStddevOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IExerciseDayProgressionsStddevPopFields {
   __typename?: 'exercise_day_progressions_stddev_pop_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "exercise_day_progressions" */
export interface IExerciseDayProgressionsStddevPopOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IExerciseDayProgressionsStddevSampFields {
   __typename?: 'exercise_day_progressions_stddev_samp_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "exercise_day_progressions" */
export interface IExerciseDayProgressionsStddevSampOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IExerciseDayProgressionsSumFields {
   __typename?: 'exercise_day_progressions_sum_fields';
  exercise_day_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "exercise_day_progressions" */
export interface IExerciseDayProgressionsSumOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** update columns of table "exercise_day_progressions" */
export enum IExerciseDayProgressionsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EXERCISE_DAY_ID = 'exercise_day_id',
  /** column name */
  ID = 'id',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate var_pop on columns */
export interface IExerciseDayProgressionsVarPopFields {
   __typename?: 'exercise_day_progressions_var_pop_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "exercise_day_progressions" */
export interface IExerciseDayProgressionsVarPopOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IExerciseDayProgressionsVarSampFields {
   __typename?: 'exercise_day_progressions_var_samp_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "exercise_day_progressions" */
export interface IExerciseDayProgressionsVarSampOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IExerciseDayProgressionsVarianceFields {
   __typename?: 'exercise_day_progressions_variance_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "exercise_day_progressions" */
export interface IExerciseDayProgressionsVarianceOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "exercise_days" */
export interface IExerciseDays {
   __typename?: 'exercise_days';
  created_at: Scalars['timestamp'];
  /** An array relationship */
  exercise_day_progressions: Array<IExerciseDayProgressions>;
  /** An aggregate relationship */
  exercise_day_progressions_aggregate: IExerciseDayProgressionsAggregate;
  /** An array relationship */
  exercise_groups: Array<IExerciseGroups>;
  /** An aggregate relationship */
  exercise_groups_aggregate: IExerciseGroupsAggregate;
  id: Scalars['bigint'];
  sequence?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  workout_plan?: Maybe<IWorkoutPlans>;
  workout_plan_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  workout_template?: Maybe<IWorkoutTemplates>;
  workout_template_id?: Maybe<Scalars['bigint']>;
}


/** columns and relationships of "exercise_days" */
export interface IExerciseDaysExerciseDayProgressionsArgs {
  distinct_on?: Maybe<Array<IExerciseDayProgressionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDayProgressionsOrderBy>>;
  where?: Maybe<IExerciseDayProgressionsBoolExp>;
}


/** columns and relationships of "exercise_days" */
export interface IExerciseDaysExerciseDayProgressionsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseDayProgressionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDayProgressionsOrderBy>>;
  where?: Maybe<IExerciseDayProgressionsBoolExp>;
}


/** columns and relationships of "exercise_days" */
export interface IExerciseDaysExerciseGroupsArgs {
  distinct_on?: Maybe<Array<IExerciseGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseGroupsOrderBy>>;
  where?: Maybe<IExerciseGroupsBoolExp>;
}


/** columns and relationships of "exercise_days" */
export interface IExerciseDaysExerciseGroupsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseGroupsOrderBy>>;
  where?: Maybe<IExerciseGroupsBoolExp>;
}

/** aggregated selection of "exercise_days" */
export interface IExerciseDaysAggregate {
   __typename?: 'exercise_days_aggregate';
  aggregate?: Maybe<IExerciseDaysAggregateFields>;
  nodes: Array<IExerciseDays>;
}

/** aggregate fields of "exercise_days" */
export interface IExerciseDaysAggregateFields {
   __typename?: 'exercise_days_aggregate_fields';
  avg?: Maybe<IExerciseDaysAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IExerciseDaysMaxFields>;
  min?: Maybe<IExerciseDaysMinFields>;
  stddev?: Maybe<IExerciseDaysStddevFields>;
  stddev_pop?: Maybe<IExerciseDaysStddevPopFields>;
  stddev_samp?: Maybe<IExerciseDaysStddevSampFields>;
  sum?: Maybe<IExerciseDaysSumFields>;
  var_pop?: Maybe<IExerciseDaysVarPopFields>;
  var_samp?: Maybe<IExerciseDaysVarSampFields>;
  variance?: Maybe<IExerciseDaysVarianceFields>;
}


/** aggregate fields of "exercise_days" */
export interface IExerciseDaysAggregateFieldsCountArgs {
  columns?: Maybe<Array<IExerciseDaysSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "exercise_days" */
export interface IExerciseDaysAggregateOrderBy {
  avg?: Maybe<IExerciseDaysAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IExerciseDaysMaxOrderBy>;
  min?: Maybe<IExerciseDaysMinOrderBy>;
  stddev?: Maybe<IExerciseDaysStddevOrderBy>;
  stddev_pop?: Maybe<IExerciseDaysStddevPopOrderBy>;
  stddev_samp?: Maybe<IExerciseDaysStddevSampOrderBy>;
  sum?: Maybe<IExerciseDaysSumOrderBy>;
  var_pop?: Maybe<IExerciseDaysVarPopOrderBy>;
  var_samp?: Maybe<IExerciseDaysVarSampOrderBy>;
  variance?: Maybe<IExerciseDaysVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "exercise_days" */
export interface IExerciseDaysArrRelInsertInput {
  data: Array<IExerciseDaysInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IExerciseDaysOnConflict>;
}

/** aggregate avg on columns */
export interface IExerciseDaysAvgFields {
   __typename?: 'exercise_days_avg_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  workout_plan_id?: Maybe<Scalars['Float']>;
  workout_template_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "exercise_days" */
export interface IExerciseDaysAvgOrderBy {
  id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  workout_plan_id?: Maybe<IOrderBy>;
  workout_template_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "exercise_days". All fields are combined with a logical 'AND'. */
export interface IExerciseDaysBoolExp {
  _and?: Maybe<Array<IExerciseDaysBoolExp>>;
  _not?: Maybe<IExerciseDaysBoolExp>;
  _or?: Maybe<Array<IExerciseDaysBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  exercise_day_progressions?: Maybe<IExerciseDayProgressionsBoolExp>;
  exercise_groups?: Maybe<IExerciseGroupsBoolExp>;
  id?: Maybe<IBigintComparisonExp>;
  sequence?: Maybe<IIntComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
  workout_plan?: Maybe<IWorkoutPlansBoolExp>;
  workout_plan_id?: Maybe<IBigintComparisonExp>;
  workout_template?: Maybe<IWorkoutTemplatesBoolExp>;
  workout_template_id?: Maybe<IBigintComparisonExp>;
}

/** unique or primary key constraints on table "exercise_days" */
export enum IExerciseDaysConstraint {
  /** unique or primary key constraint */
  EXERCISE_DAYS_PKEY = 'exercise_days_pkey'
}

/** input type for incrementing numeric columns in table "exercise_days" */
export interface IExerciseDaysIncInput {
  id?: Maybe<Scalars['bigint']>;
  sequence?: Maybe<Scalars['Int']>;
  workout_plan_id?: Maybe<Scalars['bigint']>;
  workout_template_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "exercise_days" */
export interface IExerciseDaysInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  exercise_day_progressions?: Maybe<IExerciseDayProgressionsArrRelInsertInput>;
  exercise_groups?: Maybe<IExerciseGroupsArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  workout_plan?: Maybe<IWorkoutPlansObjRelInsertInput>;
  workout_plan_id?: Maybe<Scalars['bigint']>;
  workout_template?: Maybe<IWorkoutTemplatesObjRelInsertInput>;
  workout_template_id?: Maybe<Scalars['bigint']>;
}

/** aggregate max on columns */
export interface IExerciseDaysMaxFields {
   __typename?: 'exercise_days_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  workout_plan_id?: Maybe<Scalars['bigint']>;
  workout_template_id?: Maybe<Scalars['bigint']>;
}

/** order by max() on columns of table "exercise_days" */
export interface IExerciseDaysMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  workout_plan_id?: Maybe<IOrderBy>;
  workout_template_id?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IExerciseDaysMinFields {
   __typename?: 'exercise_days_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  workout_plan_id?: Maybe<Scalars['bigint']>;
  workout_template_id?: Maybe<Scalars['bigint']>;
}

/** order by min() on columns of table "exercise_days" */
export interface IExerciseDaysMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  workout_plan_id?: Maybe<IOrderBy>;
  workout_template_id?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "exercise_days" */
export interface IExerciseDaysMutationResponse {
   __typename?: 'exercise_days_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IExerciseDays>;
}

/** input type for inserting object relation for remote table "exercise_days" */
export interface IExerciseDaysObjRelInsertInput {
  data: IExerciseDaysInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IExerciseDaysOnConflict>;
}

/** on conflict condition type for table "exercise_days" */
export interface IExerciseDaysOnConflict {
  constraint: IExerciseDaysConstraint;
  update_columns: Array<IExerciseDaysUpdateColumn>;
  where?: Maybe<IExerciseDaysBoolExp>;
}

/** Ordering options when selecting data from "exercise_days". */
export interface IExerciseDaysOrderBy {
  created_at?: Maybe<IOrderBy>;
  exercise_day_progressions_aggregate?: Maybe<IExerciseDayProgressionsAggregateOrderBy>;
  exercise_groups_aggregate?: Maybe<IExerciseGroupsAggregateOrderBy>;
  id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  workout_plan?: Maybe<IWorkoutPlansOrderBy>;
  workout_plan_id?: Maybe<IOrderBy>;
  workout_template?: Maybe<IWorkoutTemplatesOrderBy>;
  workout_template_id?: Maybe<IOrderBy>;
}

/** primary key columns input for table: exercise_days */
export interface IExerciseDaysPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "exercise_days" */
export enum IExerciseDaysSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WORKOUT_PLAN_ID = 'workout_plan_id',
  /** column name */
  WORKOUT_TEMPLATE_ID = 'workout_template_id'
}

/** input type for updating data in table "exercise_days" */
export interface IExerciseDaysSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  workout_plan_id?: Maybe<Scalars['bigint']>;
  workout_template_id?: Maybe<Scalars['bigint']>;
}

/** aggregate stddev on columns */
export interface IExerciseDaysStddevFields {
   __typename?: 'exercise_days_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  workout_plan_id?: Maybe<Scalars['Float']>;
  workout_template_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "exercise_days" */
export interface IExerciseDaysStddevOrderBy {
  id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  workout_plan_id?: Maybe<IOrderBy>;
  workout_template_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IExerciseDaysStddevPopFields {
   __typename?: 'exercise_days_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  workout_plan_id?: Maybe<Scalars['Float']>;
  workout_template_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "exercise_days" */
export interface IExerciseDaysStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  workout_plan_id?: Maybe<IOrderBy>;
  workout_template_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IExerciseDaysStddevSampFields {
   __typename?: 'exercise_days_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  workout_plan_id?: Maybe<Scalars['Float']>;
  workout_template_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "exercise_days" */
export interface IExerciseDaysStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  workout_plan_id?: Maybe<IOrderBy>;
  workout_template_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IExerciseDaysSumFields {
   __typename?: 'exercise_days_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  sequence?: Maybe<Scalars['Int']>;
  workout_plan_id?: Maybe<Scalars['bigint']>;
  workout_template_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "exercise_days" */
export interface IExerciseDaysSumOrderBy {
  id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  workout_plan_id?: Maybe<IOrderBy>;
  workout_template_id?: Maybe<IOrderBy>;
}

/** update columns of table "exercise_days" */
export enum IExerciseDaysUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WORKOUT_PLAN_ID = 'workout_plan_id',
  /** column name */
  WORKOUT_TEMPLATE_ID = 'workout_template_id'
}

/** aggregate var_pop on columns */
export interface IExerciseDaysVarPopFields {
   __typename?: 'exercise_days_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  workout_plan_id?: Maybe<Scalars['Float']>;
  workout_template_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "exercise_days" */
export interface IExerciseDaysVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  workout_plan_id?: Maybe<IOrderBy>;
  workout_template_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IExerciseDaysVarSampFields {
   __typename?: 'exercise_days_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  workout_plan_id?: Maybe<Scalars['Float']>;
  workout_template_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "exercise_days" */
export interface IExerciseDaysVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  workout_plan_id?: Maybe<IOrderBy>;
  workout_template_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IExerciseDaysVarianceFields {
   __typename?: 'exercise_days_variance_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  workout_plan_id?: Maybe<Scalars['Float']>;
  workout_template_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "exercise_days" */
export interface IExerciseDaysVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  workout_plan_id?: Maybe<IOrderBy>;
  workout_template_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "exercise_device_categories" */
export interface IExerciseDeviceCategories {
   __typename?: 'exercise_device_categories';
  created_at: Scalars['timestamp'];
  /** An array relationship */
  exercise_variants: Array<IExerciseVariants>;
  /** An aggregate relationship */
  exercise_variants_aggregate: IExerciseVariantsAggregate;
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
}


/** columns and relationships of "exercise_device_categories" */
export interface IExerciseDeviceCategoriesExerciseVariantsArgs {
  distinct_on?: Maybe<Array<IExerciseVariantsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseVariantsOrderBy>>;
  where?: Maybe<IExerciseVariantsBoolExp>;
}


/** columns and relationships of "exercise_device_categories" */
export interface IExerciseDeviceCategoriesExerciseVariantsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseVariantsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseVariantsOrderBy>>;
  where?: Maybe<IExerciseVariantsBoolExp>;
}

/** aggregated selection of "exercise_device_categories" */
export interface IExerciseDeviceCategoriesAggregate {
   __typename?: 'exercise_device_categories_aggregate';
  aggregate?: Maybe<IExerciseDeviceCategoriesAggregateFields>;
  nodes: Array<IExerciseDeviceCategories>;
}

/** aggregate fields of "exercise_device_categories" */
export interface IExerciseDeviceCategoriesAggregateFields {
   __typename?: 'exercise_device_categories_aggregate_fields';
  avg?: Maybe<IExerciseDeviceCategoriesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IExerciseDeviceCategoriesMaxFields>;
  min?: Maybe<IExerciseDeviceCategoriesMinFields>;
  stddev?: Maybe<IExerciseDeviceCategoriesStddevFields>;
  stddev_pop?: Maybe<IExerciseDeviceCategoriesStddevPopFields>;
  stddev_samp?: Maybe<IExerciseDeviceCategoriesStddevSampFields>;
  sum?: Maybe<IExerciseDeviceCategoriesSumFields>;
  var_pop?: Maybe<IExerciseDeviceCategoriesVarPopFields>;
  var_samp?: Maybe<IExerciseDeviceCategoriesVarSampFields>;
  variance?: Maybe<IExerciseDeviceCategoriesVarianceFields>;
}


/** aggregate fields of "exercise_device_categories" */
export interface IExerciseDeviceCategoriesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IExerciseDeviceCategoriesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IExerciseDeviceCategoriesAvgFields {
   __typename?: 'exercise_device_categories_avg_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "exercise_device_categories". All fields are combined with a logical 'AND'. */
export interface IExerciseDeviceCategoriesBoolExp {
  _and?: Maybe<Array<IExerciseDeviceCategoriesBoolExp>>;
  _not?: Maybe<IExerciseDeviceCategoriesBoolExp>;
  _or?: Maybe<Array<IExerciseDeviceCategoriesBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  exercise_variants?: Maybe<IExerciseVariantsBoolExp>;
  id?: Maybe<IBigintComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "exercise_device_categories" */
export enum IExerciseDeviceCategoriesConstraint {
  /** unique or primary key constraint */
  EXERCISE_DEVICE_CATEGORIES_PKEY = 'exercise_device_categories_pkey'
}

/** input type for incrementing numeric columns in table "exercise_device_categories" */
export interface IExerciseDeviceCategoriesIncInput {
  id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "exercise_device_categories" */
export interface IExerciseDeviceCategoriesInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  exercise_variants?: Maybe<IExerciseVariantsArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IExerciseDeviceCategoriesMaxFields {
   __typename?: 'exercise_device_categories_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate min on columns */
export interface IExerciseDeviceCategoriesMinFields {
   __typename?: 'exercise_device_categories_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** response of any mutation on the table "exercise_device_categories" */
export interface IExerciseDeviceCategoriesMutationResponse {
   __typename?: 'exercise_device_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IExerciseDeviceCategories>;
}

/** input type for inserting object relation for remote table "exercise_device_categories" */
export interface IExerciseDeviceCategoriesObjRelInsertInput {
  data: IExerciseDeviceCategoriesInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IExerciseDeviceCategoriesOnConflict>;
}

/** on conflict condition type for table "exercise_device_categories" */
export interface IExerciseDeviceCategoriesOnConflict {
  constraint: IExerciseDeviceCategoriesConstraint;
  update_columns: Array<IExerciseDeviceCategoriesUpdateColumn>;
  where?: Maybe<IExerciseDeviceCategoriesBoolExp>;
}

/** Ordering options when selecting data from "exercise_device_categories". */
export interface IExerciseDeviceCategoriesOrderBy {
  created_at?: Maybe<IOrderBy>;
  exercise_variants_aggregate?: Maybe<IExerciseVariantsAggregateOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: exercise_device_categories */
export interface IExerciseDeviceCategoriesPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "exercise_device_categories" */
export enum IExerciseDeviceCategoriesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "exercise_device_categories" */
export interface IExerciseDeviceCategoriesSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IExerciseDeviceCategoriesStddevFields {
   __typename?: 'exercise_device_categories_stddev_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IExerciseDeviceCategoriesStddevPopFields {
   __typename?: 'exercise_device_categories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IExerciseDeviceCategoriesStddevSampFields {
   __typename?: 'exercise_device_categories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IExerciseDeviceCategoriesSumFields {
   __typename?: 'exercise_device_categories_sum_fields';
  id?: Maybe<Scalars['bigint']>;
}

/** update columns of table "exercise_device_categories" */
export enum IExerciseDeviceCategoriesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IExerciseDeviceCategoriesVarPopFields {
   __typename?: 'exercise_device_categories_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IExerciseDeviceCategoriesVarSampFields {
   __typename?: 'exercise_device_categories_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IExerciseDeviceCategoriesVarianceFields {
   __typename?: 'exercise_device_categories_variance_fields';
  id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "exercise_groups" */
export interface IExerciseGroups {
   __typename?: 'exercise_groups';
  created_at: Scalars['timestamp'];
  /** An object relationship */
  exercise_day: IExerciseDays;
  exercise_day_id: Scalars['bigint'];
  /** An array relationship */
  exercises: Array<IExercises>;
  /** An aggregate relationship */
  exercises_aggregate: IExercisesAggregate;
  group_type?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  order: Scalars['Int'];
  updated_at: Scalars['timestamp'];
}


/** columns and relationships of "exercise_groups" */
export interface IExerciseGroupsExercisesArgs {
  distinct_on?: Maybe<Array<IExercisesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExercisesOrderBy>>;
  where?: Maybe<IExercisesBoolExp>;
}


/** columns and relationships of "exercise_groups" */
export interface IExerciseGroupsExercisesAggregateArgs {
  distinct_on?: Maybe<Array<IExercisesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExercisesOrderBy>>;
  where?: Maybe<IExercisesBoolExp>;
}

/** aggregated selection of "exercise_groups" */
export interface IExerciseGroupsAggregate {
   __typename?: 'exercise_groups_aggregate';
  aggregate?: Maybe<IExerciseGroupsAggregateFields>;
  nodes: Array<IExerciseGroups>;
}

/** aggregate fields of "exercise_groups" */
export interface IExerciseGroupsAggregateFields {
   __typename?: 'exercise_groups_aggregate_fields';
  avg?: Maybe<IExerciseGroupsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IExerciseGroupsMaxFields>;
  min?: Maybe<IExerciseGroupsMinFields>;
  stddev?: Maybe<IExerciseGroupsStddevFields>;
  stddev_pop?: Maybe<IExerciseGroupsStddevPopFields>;
  stddev_samp?: Maybe<IExerciseGroupsStddevSampFields>;
  sum?: Maybe<IExerciseGroupsSumFields>;
  var_pop?: Maybe<IExerciseGroupsVarPopFields>;
  var_samp?: Maybe<IExerciseGroupsVarSampFields>;
  variance?: Maybe<IExerciseGroupsVarianceFields>;
}


/** aggregate fields of "exercise_groups" */
export interface IExerciseGroupsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IExerciseGroupsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "exercise_groups" */
export interface IExerciseGroupsAggregateOrderBy {
  avg?: Maybe<IExerciseGroupsAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IExerciseGroupsMaxOrderBy>;
  min?: Maybe<IExerciseGroupsMinOrderBy>;
  stddev?: Maybe<IExerciseGroupsStddevOrderBy>;
  stddev_pop?: Maybe<IExerciseGroupsStddevPopOrderBy>;
  stddev_samp?: Maybe<IExerciseGroupsStddevSampOrderBy>;
  sum?: Maybe<IExerciseGroupsSumOrderBy>;
  var_pop?: Maybe<IExerciseGroupsVarPopOrderBy>;
  var_samp?: Maybe<IExerciseGroupsVarSampOrderBy>;
  variance?: Maybe<IExerciseGroupsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "exercise_groups" */
export interface IExerciseGroupsArrRelInsertInput {
  data: Array<IExerciseGroupsInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IExerciseGroupsOnConflict>;
}

/** aggregate avg on columns */
export interface IExerciseGroupsAvgFields {
   __typename?: 'exercise_groups_avg_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "exercise_groups" */
export interface IExerciseGroupsAvgOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "exercise_groups". All fields are combined with a logical 'AND'. */
export interface IExerciseGroupsBoolExp {
  _and?: Maybe<Array<IExerciseGroupsBoolExp>>;
  _not?: Maybe<IExerciseGroupsBoolExp>;
  _or?: Maybe<Array<IExerciseGroupsBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  exercise_day?: Maybe<IExerciseDaysBoolExp>;
  exercise_day_id?: Maybe<IBigintComparisonExp>;
  exercises?: Maybe<IExercisesBoolExp>;
  group_type?: Maybe<IStringComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  order?: Maybe<IIntComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "exercise_groups" */
export enum IExerciseGroupsConstraint {
  /** unique or primary key constraint */
  EXERCISE_GROUPS_PKEY = 'exercise_groups_pkey'
}

/** input type for incrementing numeric columns in table "exercise_groups" */
export interface IExerciseGroupsIncInput {
  exercise_day_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "exercise_groups" */
export interface IExerciseGroupsInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  exercise_day?: Maybe<IExerciseDaysObjRelInsertInput>;
  exercise_day_id?: Maybe<Scalars['bigint']>;
  exercises?: Maybe<IExercisesArrRelInsertInput>;
  group_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IExerciseGroupsMaxFields {
   __typename?: 'exercise_groups_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  exercise_day_id?: Maybe<Scalars['bigint']>;
  group_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by max() on columns of table "exercise_groups" */
export interface IExerciseGroupsMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  exercise_day_id?: Maybe<IOrderBy>;
  group_type?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IExerciseGroupsMinFields {
   __typename?: 'exercise_groups_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  exercise_day_id?: Maybe<Scalars['bigint']>;
  group_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by min() on columns of table "exercise_groups" */
export interface IExerciseGroupsMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  exercise_day_id?: Maybe<IOrderBy>;
  group_type?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "exercise_groups" */
export interface IExerciseGroupsMutationResponse {
   __typename?: 'exercise_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IExerciseGroups>;
}

/** input type for inserting object relation for remote table "exercise_groups" */
export interface IExerciseGroupsObjRelInsertInput {
  data: IExerciseGroupsInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IExerciseGroupsOnConflict>;
}

/** on conflict condition type for table "exercise_groups" */
export interface IExerciseGroupsOnConflict {
  constraint: IExerciseGroupsConstraint;
  update_columns: Array<IExerciseGroupsUpdateColumn>;
  where?: Maybe<IExerciseGroupsBoolExp>;
}

/** Ordering options when selecting data from "exercise_groups". */
export interface IExerciseGroupsOrderBy {
  created_at?: Maybe<IOrderBy>;
  exercise_day?: Maybe<IExerciseDaysOrderBy>;
  exercise_day_id?: Maybe<IOrderBy>;
  exercises_aggregate?: Maybe<IExercisesAggregateOrderBy>;
  group_type?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: exercise_groups */
export interface IExerciseGroupsPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "exercise_groups" */
export enum IExerciseGroupsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EXERCISE_DAY_ID = 'exercise_day_id',
  /** column name */
  GROUP_TYPE = 'group_type',
  /** column name */
  ID = 'id',
  /** column name */
  ORDER = 'order',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "exercise_groups" */
export interface IExerciseGroupsSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  exercise_day_id?: Maybe<Scalars['bigint']>;
  group_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IExerciseGroupsStddevFields {
   __typename?: 'exercise_groups_stddev_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "exercise_groups" */
export interface IExerciseGroupsStddevOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IExerciseGroupsStddevPopFields {
   __typename?: 'exercise_groups_stddev_pop_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "exercise_groups" */
export interface IExerciseGroupsStddevPopOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IExerciseGroupsStddevSampFields {
   __typename?: 'exercise_groups_stddev_samp_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "exercise_groups" */
export interface IExerciseGroupsStddevSampOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IExerciseGroupsSumFields {
   __typename?: 'exercise_groups_sum_fields';
  exercise_day_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "exercise_groups" */
export interface IExerciseGroupsSumOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** update columns of table "exercise_groups" */
export enum IExerciseGroupsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EXERCISE_DAY_ID = 'exercise_day_id',
  /** column name */
  GROUP_TYPE = 'group_type',
  /** column name */
  ID = 'id',
  /** column name */
  ORDER = 'order',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IExerciseGroupsVarPopFields {
   __typename?: 'exercise_groups_var_pop_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "exercise_groups" */
export interface IExerciseGroupsVarPopOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IExerciseGroupsVarSampFields {
   __typename?: 'exercise_groups_var_samp_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "exercise_groups" */
export interface IExerciseGroupsVarSampOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IExerciseGroupsVarianceFields {
   __typename?: 'exercise_groups_variance_fields';
  exercise_day_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "exercise_groups" */
export interface IExerciseGroupsVarianceOrderBy {
  exercise_day_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** columns and relationships of "exercise_muscle_categories" */
export interface IExerciseMuscleCategories {
   __typename?: 'exercise_muscle_categories';
  body_range?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  /** An array relationship */
  exercise_variants: Array<IExerciseVariants>;
  /** An aggregate relationship */
  exercise_variants_aggregate: IExerciseVariantsAggregate;
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  type: IExerciseMuscleCategoriesEnumEnum;
  updated_at: Scalars['timestamp'];
}


/** columns and relationships of "exercise_muscle_categories" */
export interface IExerciseMuscleCategoriesExerciseVariantsArgs {
  distinct_on?: Maybe<Array<IExerciseVariantsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseVariantsOrderBy>>;
  where?: Maybe<IExerciseVariantsBoolExp>;
}


/** columns and relationships of "exercise_muscle_categories" */
export interface IExerciseMuscleCategoriesExerciseVariantsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseVariantsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseVariantsOrderBy>>;
  where?: Maybe<IExerciseVariantsBoolExp>;
}

/** aggregated selection of "exercise_muscle_categories" */
export interface IExerciseMuscleCategoriesAggregate {
   __typename?: 'exercise_muscle_categories_aggregate';
  aggregate?: Maybe<IExerciseMuscleCategoriesAggregateFields>;
  nodes: Array<IExerciseMuscleCategories>;
}

/** aggregate fields of "exercise_muscle_categories" */
export interface IExerciseMuscleCategoriesAggregateFields {
   __typename?: 'exercise_muscle_categories_aggregate_fields';
  avg?: Maybe<IExerciseMuscleCategoriesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IExerciseMuscleCategoriesMaxFields>;
  min?: Maybe<IExerciseMuscleCategoriesMinFields>;
  stddev?: Maybe<IExerciseMuscleCategoriesStddevFields>;
  stddev_pop?: Maybe<IExerciseMuscleCategoriesStddevPopFields>;
  stddev_samp?: Maybe<IExerciseMuscleCategoriesStddevSampFields>;
  sum?: Maybe<IExerciseMuscleCategoriesSumFields>;
  var_pop?: Maybe<IExerciseMuscleCategoriesVarPopFields>;
  var_samp?: Maybe<IExerciseMuscleCategoriesVarSampFields>;
  variance?: Maybe<IExerciseMuscleCategoriesVarianceFields>;
}


/** aggregate fields of "exercise_muscle_categories" */
export interface IExerciseMuscleCategoriesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IExerciseMuscleCategoriesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IExerciseMuscleCategoriesAvgFields {
   __typename?: 'exercise_muscle_categories_avg_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "exercise_muscle_categories". All fields are combined with a logical 'AND'. */
export interface IExerciseMuscleCategoriesBoolExp {
  _and?: Maybe<Array<IExerciseMuscleCategoriesBoolExp>>;
  _not?: Maybe<IExerciseMuscleCategoriesBoolExp>;
  _or?: Maybe<Array<IExerciseMuscleCategoriesBoolExp>>;
  body_range?: Maybe<IStringComparisonExp>;
  created_at?: Maybe<ITimestampComparisonExp>;
  exercise_variants?: Maybe<IExerciseVariantsBoolExp>;
  id?: Maybe<IBigintComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  type?: Maybe<IExerciseMuscleCategoriesEnumEnumComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "exercise_muscle_categories" */
export enum IExerciseMuscleCategoriesConstraint {
  /** unique or primary key constraint */
  EXERCISE_MUSCLE_CATEGORIES_PKEY = 'exercise_muscle_categories_pkey'
}

/** columns and relationships of "exercise_muscle_categories_enum" */
export interface IExerciseMuscleCategoriesEnum {
   __typename?: 'exercise_muscle_categories_enum';
  name: Scalars['String'];
}

/** aggregated selection of "exercise_muscle_categories_enum" */
export interface IExerciseMuscleCategoriesEnumAggregate {
   __typename?: 'exercise_muscle_categories_enum_aggregate';
  aggregate?: Maybe<IExerciseMuscleCategoriesEnumAggregateFields>;
  nodes: Array<IExerciseMuscleCategoriesEnum>;
}

/** aggregate fields of "exercise_muscle_categories_enum" */
export interface IExerciseMuscleCategoriesEnumAggregateFields {
   __typename?: 'exercise_muscle_categories_enum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IExerciseMuscleCategoriesEnumMaxFields>;
  min?: Maybe<IExerciseMuscleCategoriesEnumMinFields>;
}


/** aggregate fields of "exercise_muscle_categories_enum" */
export interface IExerciseMuscleCategoriesEnumAggregateFieldsCountArgs {
  columns?: Maybe<Array<IExerciseMuscleCategoriesEnumSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to filter rows from the table "exercise_muscle_categories_enum". All fields are combined with a logical 'AND'. */
export interface IExerciseMuscleCategoriesEnumBoolExp {
  _and?: Maybe<Array<IExerciseMuscleCategoriesEnumBoolExp>>;
  _not?: Maybe<IExerciseMuscleCategoriesEnumBoolExp>;
  _or?: Maybe<Array<IExerciseMuscleCategoriesEnumBoolExp>>;
  name?: Maybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "exercise_muscle_categories_enum" */
export enum IExerciseMuscleCategoriesEnumConstraint {
  /** unique or primary key constraint */
  EXERCISE_GROUP_ENUM_PKEY = 'exercise_group_enum_pkey'
}

export enum IExerciseMuscleCategoriesEnumEnum {
  NEW = 'NEW',
  OLD = 'OLD'
}

/** Boolean expression to compare columns of type "exercise_muscle_categories_enum_enum". All fields are combined with logical 'AND'. */
export interface IExerciseMuscleCategoriesEnumEnumComparisonExp {
  _eq?: Maybe<IExerciseMuscleCategoriesEnumEnum>;
  _in?: Maybe<Array<IExerciseMuscleCategoriesEnumEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<IExerciseMuscleCategoriesEnumEnum>;
  _nin?: Maybe<Array<IExerciseMuscleCategoriesEnumEnum>>;
}

/** input type for inserting data into table "exercise_muscle_categories_enum" */
export interface IExerciseMuscleCategoriesEnumInsertInput {
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IExerciseMuscleCategoriesEnumMaxFields {
   __typename?: 'exercise_muscle_categories_enum_max_fields';
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IExerciseMuscleCategoriesEnumMinFields {
   __typename?: 'exercise_muscle_categories_enum_min_fields';
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "exercise_muscle_categories_enum" */
export interface IExerciseMuscleCategoriesEnumMutationResponse {
   __typename?: 'exercise_muscle_categories_enum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IExerciseMuscleCategoriesEnum>;
}

/** on conflict condition type for table "exercise_muscle_categories_enum" */
export interface IExerciseMuscleCategoriesEnumOnConflict {
  constraint: IExerciseMuscleCategoriesEnumConstraint;
  update_columns: Array<IExerciseMuscleCategoriesEnumUpdateColumn>;
  where?: Maybe<IExerciseMuscleCategoriesEnumBoolExp>;
}

/** Ordering options when selecting data from "exercise_muscle_categories_enum". */
export interface IExerciseMuscleCategoriesEnumOrderBy {
  name?: Maybe<IOrderBy>;
}

/** primary key columns input for table: exercise_muscle_categories_enum */
export interface IExerciseMuscleCategoriesEnumPkColumnsInput {
  name: Scalars['String'];
}

/** select columns of table "exercise_muscle_categories_enum" */
export enum IExerciseMuscleCategoriesEnumSelectColumn {
  /** column name */
  NAME = 'name'
}

/** input type for updating data in table "exercise_muscle_categories_enum" */
export interface IExerciseMuscleCategoriesEnumSetInput {
  name?: Maybe<Scalars['String']>;
}

/** update columns of table "exercise_muscle_categories_enum" */
export enum IExerciseMuscleCategoriesEnumUpdateColumn {
  /** column name */
  NAME = 'name'
}

/** input type for incrementing numeric columns in table "exercise_muscle_categories" */
export interface IExerciseMuscleCategoriesIncInput {
  id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "exercise_muscle_categories" */
export interface IExerciseMuscleCategoriesInsertInput {
  body_range?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  exercise_variants?: Maybe<IExerciseVariantsArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<IExerciseMuscleCategoriesEnumEnum>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IExerciseMuscleCategoriesMaxFields {
   __typename?: 'exercise_muscle_categories_max_fields';
  body_range?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate min on columns */
export interface IExerciseMuscleCategoriesMinFields {
   __typename?: 'exercise_muscle_categories_min_fields';
  body_range?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** response of any mutation on the table "exercise_muscle_categories" */
export interface IExerciseMuscleCategoriesMutationResponse {
   __typename?: 'exercise_muscle_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IExerciseMuscleCategories>;
}

/** input type for inserting object relation for remote table "exercise_muscle_categories" */
export interface IExerciseMuscleCategoriesObjRelInsertInput {
  data: IExerciseMuscleCategoriesInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IExerciseMuscleCategoriesOnConflict>;
}

/** on conflict condition type for table "exercise_muscle_categories" */
export interface IExerciseMuscleCategoriesOnConflict {
  constraint: IExerciseMuscleCategoriesConstraint;
  update_columns: Array<IExerciseMuscleCategoriesUpdateColumn>;
  where?: Maybe<IExerciseMuscleCategoriesBoolExp>;
}

/** Ordering options when selecting data from "exercise_muscle_categories". */
export interface IExerciseMuscleCategoriesOrderBy {
  body_range?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  exercise_variants_aggregate?: Maybe<IExerciseVariantsAggregateOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  type?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: exercise_muscle_categories */
export interface IExerciseMuscleCategoriesPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "exercise_muscle_categories" */
export enum IExerciseMuscleCategoriesSelectColumn {
  /** column name */
  BODY_RANGE = 'body_range',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  TYPE = 'type',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "exercise_muscle_categories" */
export interface IExerciseMuscleCategoriesSetInput {
  body_range?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<IExerciseMuscleCategoriesEnumEnum>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IExerciseMuscleCategoriesStddevFields {
   __typename?: 'exercise_muscle_categories_stddev_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IExerciseMuscleCategoriesStddevPopFields {
   __typename?: 'exercise_muscle_categories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IExerciseMuscleCategoriesStddevSampFields {
   __typename?: 'exercise_muscle_categories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IExerciseMuscleCategoriesSumFields {
   __typename?: 'exercise_muscle_categories_sum_fields';
  id?: Maybe<Scalars['bigint']>;
}

/** update columns of table "exercise_muscle_categories" */
export enum IExerciseMuscleCategoriesUpdateColumn {
  /** column name */
  BODY_RANGE = 'body_range',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  TYPE = 'type',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IExerciseMuscleCategoriesVarPopFields {
   __typename?: 'exercise_muscle_categories_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IExerciseMuscleCategoriesVarSampFields {
   __typename?: 'exercise_muscle_categories_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IExerciseMuscleCategoriesVarianceFields {
   __typename?: 'exercise_muscle_categories_variance_fields';
  id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "exercise_progressions" */
export interface IExerciseProgressions {
   __typename?: 'exercise_progressions';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  exercise: IExercises;
  exercise_id: Scalars['bigint'];
  id: Scalars['Int'];
  sets: Scalars['json'];
  updated_at: Scalars['timestamptz'];
}


/** columns and relationships of "exercise_progressions" */
export interface IExerciseProgressionsSetsArgs {
  path?: Maybe<Scalars['String']>;
}

/** aggregated selection of "exercise_progressions" */
export interface IExerciseProgressionsAggregate {
   __typename?: 'exercise_progressions_aggregate';
  aggregate?: Maybe<IExerciseProgressionsAggregateFields>;
  nodes: Array<IExerciseProgressions>;
}

/** aggregate fields of "exercise_progressions" */
export interface IExerciseProgressionsAggregateFields {
   __typename?: 'exercise_progressions_aggregate_fields';
  avg?: Maybe<IExerciseProgressionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IExerciseProgressionsMaxFields>;
  min?: Maybe<IExerciseProgressionsMinFields>;
  stddev?: Maybe<IExerciseProgressionsStddevFields>;
  stddev_pop?: Maybe<IExerciseProgressionsStddevPopFields>;
  stddev_samp?: Maybe<IExerciseProgressionsStddevSampFields>;
  sum?: Maybe<IExerciseProgressionsSumFields>;
  var_pop?: Maybe<IExerciseProgressionsVarPopFields>;
  var_samp?: Maybe<IExerciseProgressionsVarSampFields>;
  variance?: Maybe<IExerciseProgressionsVarianceFields>;
}


/** aggregate fields of "exercise_progressions" */
export interface IExerciseProgressionsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IExerciseProgressionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "exercise_progressions" */
export interface IExerciseProgressionsAggregateOrderBy {
  avg?: Maybe<IExerciseProgressionsAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IExerciseProgressionsMaxOrderBy>;
  min?: Maybe<IExerciseProgressionsMinOrderBy>;
  stddev?: Maybe<IExerciseProgressionsStddevOrderBy>;
  stddev_pop?: Maybe<IExerciseProgressionsStddevPopOrderBy>;
  stddev_samp?: Maybe<IExerciseProgressionsStddevSampOrderBy>;
  sum?: Maybe<IExerciseProgressionsSumOrderBy>;
  var_pop?: Maybe<IExerciseProgressionsVarPopOrderBy>;
  var_samp?: Maybe<IExerciseProgressionsVarSampOrderBy>;
  variance?: Maybe<IExerciseProgressionsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "exercise_progressions" */
export interface IExerciseProgressionsArrRelInsertInput {
  data: Array<IExerciseProgressionsInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IExerciseProgressionsOnConflict>;
}

/** aggregate avg on columns */
export interface IExerciseProgressionsAvgFields {
   __typename?: 'exercise_progressions_avg_fields';
  exercise_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "exercise_progressions" */
export interface IExerciseProgressionsAvgOrderBy {
  exercise_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "exercise_progressions". All fields are combined with a logical 'AND'. */
export interface IExerciseProgressionsBoolExp {
  _and?: Maybe<Array<IExerciseProgressionsBoolExp>>;
  _not?: Maybe<IExerciseProgressionsBoolExp>;
  _or?: Maybe<Array<IExerciseProgressionsBoolExp>>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  exercise?: Maybe<IExercisesBoolExp>;
  exercise_id?: Maybe<IBigintComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  sets?: Maybe<IJsonComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "exercise_progressions" */
export enum IExerciseProgressionsConstraint {
  /** unique or primary key constraint */
  EXERCISE_PROGRESSIONS_PKEY1 = 'exercise_progressions_pkey1'
}

/** input type for incrementing numeric columns in table "exercise_progressions" */
export interface IExerciseProgressionsIncInput {
  exercise_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "exercise_progressions" */
export interface IExerciseProgressionsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  exercise?: Maybe<IExercisesObjRelInsertInput>;
  exercise_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  sets?: Maybe<Scalars['json']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IExerciseProgressionsMaxFields {
   __typename?: 'exercise_progressions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  exercise_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "exercise_progressions" */
export interface IExerciseProgressionsMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  exercise_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IExerciseProgressionsMinFields {
   __typename?: 'exercise_progressions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  exercise_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "exercise_progressions" */
export interface IExerciseProgressionsMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  exercise_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "exercise_progressions" */
export interface IExerciseProgressionsMutationResponse {
   __typename?: 'exercise_progressions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IExerciseProgressions>;
}

/** on conflict condition type for table "exercise_progressions" */
export interface IExerciseProgressionsOnConflict {
  constraint: IExerciseProgressionsConstraint;
  update_columns: Array<IExerciseProgressionsUpdateColumn>;
  where?: Maybe<IExerciseProgressionsBoolExp>;
}

/** Ordering options when selecting data from "exercise_progressions". */
export interface IExerciseProgressionsOrderBy {
  created_at?: Maybe<IOrderBy>;
  exercise?: Maybe<IExercisesOrderBy>;
  exercise_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  sets?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: exercise_progressions */
export interface IExerciseProgressionsPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "exercise_progressions" */
export enum IExerciseProgressionsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EXERCISE_ID = 'exercise_id',
  /** column name */
  ID = 'id',
  /** column name */
  SETS = 'sets',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "exercise_progressions" */
export interface IExerciseProgressionsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  exercise_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  sets?: Maybe<Scalars['json']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IExerciseProgressionsStddevFields {
   __typename?: 'exercise_progressions_stddev_fields';
  exercise_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "exercise_progressions" */
export interface IExerciseProgressionsStddevOrderBy {
  exercise_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IExerciseProgressionsStddevPopFields {
   __typename?: 'exercise_progressions_stddev_pop_fields';
  exercise_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "exercise_progressions" */
export interface IExerciseProgressionsStddevPopOrderBy {
  exercise_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IExerciseProgressionsStddevSampFields {
   __typename?: 'exercise_progressions_stddev_samp_fields';
  exercise_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "exercise_progressions" */
export interface IExerciseProgressionsStddevSampOrderBy {
  exercise_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IExerciseProgressionsSumFields {
   __typename?: 'exercise_progressions_sum_fields';
  exercise_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "exercise_progressions" */
export interface IExerciseProgressionsSumOrderBy {
  exercise_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** update columns of table "exercise_progressions" */
export enum IExerciseProgressionsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EXERCISE_ID = 'exercise_id',
  /** column name */
  ID = 'id',
  /** column name */
  SETS = 'sets',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IExerciseProgressionsVarPopFields {
   __typename?: 'exercise_progressions_var_pop_fields';
  exercise_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "exercise_progressions" */
export interface IExerciseProgressionsVarPopOrderBy {
  exercise_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IExerciseProgressionsVarSampFields {
   __typename?: 'exercise_progressions_var_samp_fields';
  exercise_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "exercise_progressions" */
export interface IExerciseProgressionsVarSampOrderBy {
  exercise_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IExerciseProgressionsVarianceFields {
   __typename?: 'exercise_progressions_variance_fields';
  exercise_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "exercise_progressions" */
export interface IExerciseProgressionsVarianceOrderBy {
  exercise_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** columns and relationships of "exercise_variants" */
export interface IExerciseVariants {
   __typename?: 'exercise_variants';
  content_type?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  exercise_device_category: IExerciseDeviceCategories;
  exercise_device_category_id: Scalars['bigint'];
  /** An object relationship */
  exercise_muscle_category: IExerciseMuscleCategories;
  exercise_muscle_category_id: Scalars['bigint'];
  exercise_variant_category?: Maybe<Scalars['String']>;
  /** An array relationship */
  exercises: Array<IExercises>;
  /** An aggregate relationship */
  exercises_aggregate: IExercisesAggregate;
  id: Scalars['bigint'];
  media_content?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
}


/** columns and relationships of "exercise_variants" */
export interface IExerciseVariantsExercisesArgs {
  distinct_on?: Maybe<Array<IExercisesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExercisesOrderBy>>;
  where?: Maybe<IExercisesBoolExp>;
}


/** columns and relationships of "exercise_variants" */
export interface IExerciseVariantsExercisesAggregateArgs {
  distinct_on?: Maybe<Array<IExercisesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExercisesOrderBy>>;
  where?: Maybe<IExercisesBoolExp>;
}

/** aggregated selection of "exercise_variants" */
export interface IExerciseVariantsAggregate {
   __typename?: 'exercise_variants_aggregate';
  aggregate?: Maybe<IExerciseVariantsAggregateFields>;
  nodes: Array<IExerciseVariants>;
}

/** aggregate fields of "exercise_variants" */
export interface IExerciseVariantsAggregateFields {
   __typename?: 'exercise_variants_aggregate_fields';
  avg?: Maybe<IExerciseVariantsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IExerciseVariantsMaxFields>;
  min?: Maybe<IExerciseVariantsMinFields>;
  stddev?: Maybe<IExerciseVariantsStddevFields>;
  stddev_pop?: Maybe<IExerciseVariantsStddevPopFields>;
  stddev_samp?: Maybe<IExerciseVariantsStddevSampFields>;
  sum?: Maybe<IExerciseVariantsSumFields>;
  var_pop?: Maybe<IExerciseVariantsVarPopFields>;
  var_samp?: Maybe<IExerciseVariantsVarSampFields>;
  variance?: Maybe<IExerciseVariantsVarianceFields>;
}


/** aggregate fields of "exercise_variants" */
export interface IExerciseVariantsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IExerciseVariantsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "exercise_variants" */
export interface IExerciseVariantsAggregateOrderBy {
  avg?: Maybe<IExerciseVariantsAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IExerciseVariantsMaxOrderBy>;
  min?: Maybe<IExerciseVariantsMinOrderBy>;
  stddev?: Maybe<IExerciseVariantsStddevOrderBy>;
  stddev_pop?: Maybe<IExerciseVariantsStddevPopOrderBy>;
  stddev_samp?: Maybe<IExerciseVariantsStddevSampOrderBy>;
  sum?: Maybe<IExerciseVariantsSumOrderBy>;
  var_pop?: Maybe<IExerciseVariantsVarPopOrderBy>;
  var_samp?: Maybe<IExerciseVariantsVarSampOrderBy>;
  variance?: Maybe<IExerciseVariantsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "exercise_variants" */
export interface IExerciseVariantsArrRelInsertInput {
  data: Array<IExerciseVariantsInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IExerciseVariantsOnConflict>;
}

/** aggregate avg on columns */
export interface IExerciseVariantsAvgFields {
   __typename?: 'exercise_variants_avg_fields';
  exercise_device_category_id?: Maybe<Scalars['Float']>;
  exercise_muscle_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "exercise_variants" */
export interface IExerciseVariantsAvgOrderBy {
  exercise_device_category_id?: Maybe<IOrderBy>;
  exercise_muscle_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "exercise_variants". All fields are combined with a logical 'AND'. */
export interface IExerciseVariantsBoolExp {
  _and?: Maybe<Array<IExerciseVariantsBoolExp>>;
  _not?: Maybe<IExerciseVariantsBoolExp>;
  _or?: Maybe<Array<IExerciseVariantsBoolExp>>;
  content_type?: Maybe<IStringComparisonExp>;
  created_at?: Maybe<ITimestampComparisonExp>;
  description?: Maybe<IStringComparisonExp>;
  exercise_device_category?: Maybe<IExerciseDeviceCategoriesBoolExp>;
  exercise_device_category_id?: Maybe<IBigintComparisonExp>;
  exercise_muscle_category?: Maybe<IExerciseMuscleCategoriesBoolExp>;
  exercise_muscle_category_id?: Maybe<IBigintComparisonExp>;
  exercise_variant_category?: Maybe<IStringComparisonExp>;
  exercises?: Maybe<IExercisesBoolExp>;
  id?: Maybe<IBigintComparisonExp>;
  media_content?: Maybe<IStringComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "exercise_variants" */
export enum IExerciseVariantsConstraint {
  /** unique or primary key constraint */
  EXERCISE_VARIANTS_PKEY = 'exercise_variants_pkey'
}

/** input type for incrementing numeric columns in table "exercise_variants" */
export interface IExerciseVariantsIncInput {
  exercise_device_category_id?: Maybe<Scalars['bigint']>;
  exercise_muscle_category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "exercise_variants" */
export interface IExerciseVariantsInsertInput {
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  exercise_device_category?: Maybe<IExerciseDeviceCategoriesObjRelInsertInput>;
  exercise_device_category_id?: Maybe<Scalars['bigint']>;
  exercise_muscle_category?: Maybe<IExerciseMuscleCategoriesObjRelInsertInput>;
  exercise_muscle_category_id?: Maybe<Scalars['bigint']>;
  exercise_variant_category?: Maybe<Scalars['String']>;
  exercises?: Maybe<IExercisesArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  media_content?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IExerciseVariantsMaxFields {
   __typename?: 'exercise_variants_max_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  exercise_device_category_id?: Maybe<Scalars['bigint']>;
  exercise_muscle_category_id?: Maybe<Scalars['bigint']>;
  exercise_variant_category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  media_content?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by max() on columns of table "exercise_variants" */
export interface IExerciseVariantsMaxOrderBy {
  content_type?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  description?: Maybe<IOrderBy>;
  exercise_device_category_id?: Maybe<IOrderBy>;
  exercise_muscle_category_id?: Maybe<IOrderBy>;
  exercise_variant_category?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  media_content?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IExerciseVariantsMinFields {
   __typename?: 'exercise_variants_min_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  exercise_device_category_id?: Maybe<Scalars['bigint']>;
  exercise_muscle_category_id?: Maybe<Scalars['bigint']>;
  exercise_variant_category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  media_content?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by min() on columns of table "exercise_variants" */
export interface IExerciseVariantsMinOrderBy {
  content_type?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  description?: Maybe<IOrderBy>;
  exercise_device_category_id?: Maybe<IOrderBy>;
  exercise_muscle_category_id?: Maybe<IOrderBy>;
  exercise_variant_category?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  media_content?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "exercise_variants" */
export interface IExerciseVariantsMutationResponse {
   __typename?: 'exercise_variants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IExerciseVariants>;
}

/** input type for inserting object relation for remote table "exercise_variants" */
export interface IExerciseVariantsObjRelInsertInput {
  data: IExerciseVariantsInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IExerciseVariantsOnConflict>;
}

/** on conflict condition type for table "exercise_variants" */
export interface IExerciseVariantsOnConflict {
  constraint: IExerciseVariantsConstraint;
  update_columns: Array<IExerciseVariantsUpdateColumn>;
  where?: Maybe<IExerciseVariantsBoolExp>;
}

/** Ordering options when selecting data from "exercise_variants". */
export interface IExerciseVariantsOrderBy {
  content_type?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  description?: Maybe<IOrderBy>;
  exercise_device_category?: Maybe<IExerciseDeviceCategoriesOrderBy>;
  exercise_device_category_id?: Maybe<IOrderBy>;
  exercise_muscle_category?: Maybe<IExerciseMuscleCategoriesOrderBy>;
  exercise_muscle_category_id?: Maybe<IOrderBy>;
  exercise_variant_category?: Maybe<IOrderBy>;
  exercises_aggregate?: Maybe<IExercisesAggregateOrderBy>;
  id?: Maybe<IOrderBy>;
  media_content?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: exercise_variants */
export interface IExerciseVariantsPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "exercise_variants" */
export enum IExerciseVariantsSelectColumn {
  /** column name */
  CONTENT_TYPE = 'content_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  EXERCISE_DEVICE_CATEGORY_ID = 'exercise_device_category_id',
  /** column name */
  EXERCISE_MUSCLE_CATEGORY_ID = 'exercise_muscle_category_id',
  /** column name */
  EXERCISE_VARIANT_CATEGORY = 'exercise_variant_category',
  /** column name */
  ID = 'id',
  /** column name */
  MEDIA_CONTENT = 'media_content',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "exercise_variants" */
export interface IExerciseVariantsSetInput {
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  exercise_device_category_id?: Maybe<Scalars['bigint']>;
  exercise_muscle_category_id?: Maybe<Scalars['bigint']>;
  exercise_variant_category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  media_content?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IExerciseVariantsStddevFields {
   __typename?: 'exercise_variants_stddev_fields';
  exercise_device_category_id?: Maybe<Scalars['Float']>;
  exercise_muscle_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "exercise_variants" */
export interface IExerciseVariantsStddevOrderBy {
  exercise_device_category_id?: Maybe<IOrderBy>;
  exercise_muscle_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IExerciseVariantsStddevPopFields {
   __typename?: 'exercise_variants_stddev_pop_fields';
  exercise_device_category_id?: Maybe<Scalars['Float']>;
  exercise_muscle_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "exercise_variants" */
export interface IExerciseVariantsStddevPopOrderBy {
  exercise_device_category_id?: Maybe<IOrderBy>;
  exercise_muscle_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IExerciseVariantsStddevSampFields {
   __typename?: 'exercise_variants_stddev_samp_fields';
  exercise_device_category_id?: Maybe<Scalars['Float']>;
  exercise_muscle_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "exercise_variants" */
export interface IExerciseVariantsStddevSampOrderBy {
  exercise_device_category_id?: Maybe<IOrderBy>;
  exercise_muscle_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IExerciseVariantsSumFields {
   __typename?: 'exercise_variants_sum_fields';
  exercise_device_category_id?: Maybe<Scalars['bigint']>;
  exercise_muscle_category_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "exercise_variants" */
export interface IExerciseVariantsSumOrderBy {
  exercise_device_category_id?: Maybe<IOrderBy>;
  exercise_muscle_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** update columns of table "exercise_variants" */
export enum IExerciseVariantsUpdateColumn {
  /** column name */
  CONTENT_TYPE = 'content_type',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  EXERCISE_DEVICE_CATEGORY_ID = 'exercise_device_category_id',
  /** column name */
  EXERCISE_MUSCLE_CATEGORY_ID = 'exercise_muscle_category_id',
  /** column name */
  EXERCISE_VARIANT_CATEGORY = 'exercise_variant_category',
  /** column name */
  ID = 'id',
  /** column name */
  MEDIA_CONTENT = 'media_content',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IExerciseVariantsVarPopFields {
   __typename?: 'exercise_variants_var_pop_fields';
  exercise_device_category_id?: Maybe<Scalars['Float']>;
  exercise_muscle_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "exercise_variants" */
export interface IExerciseVariantsVarPopOrderBy {
  exercise_device_category_id?: Maybe<IOrderBy>;
  exercise_muscle_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IExerciseVariantsVarSampFields {
   __typename?: 'exercise_variants_var_samp_fields';
  exercise_device_category_id?: Maybe<Scalars['Float']>;
  exercise_muscle_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "exercise_variants" */
export interface IExerciseVariantsVarSampOrderBy {
  exercise_device_category_id?: Maybe<IOrderBy>;
  exercise_muscle_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IExerciseVariantsVarianceFields {
   __typename?: 'exercise_variants_variance_fields';
  exercise_device_category_id?: Maybe<Scalars['Float']>;
  exercise_muscle_category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "exercise_variants" */
export interface IExerciseVariantsVarianceOrderBy {
  exercise_device_category_id?: Maybe<IOrderBy>;
  exercise_muscle_category_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
}

/** columns and relationships of "exercises" */
export interface IExercises {
   __typename?: 'exercises';
  created_at: Scalars['timestamp'];
  duration?: Maybe<Scalars['Int']>;
  /** An object relationship */
  exercise_group: IExerciseGroups;
  exercise_group_id: Scalars['bigint'];
  /** An array relationship */
  exercise_progressions: Array<IExerciseProgressions>;
  /** An aggregate relationship */
  exercise_progressions_aggregate: IExerciseProgressionsAggregate;
  /** An object relationship */
  exercise_variant: IExerciseVariants;
  exercise_variant_id: Scalars['bigint'];
  id: Scalars['bigint'];
  repetition?: Maybe<Scalars['String']>;
  rest?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  sets?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  weight?: Maybe<Scalars['numeric']>;
}


/** columns and relationships of "exercises" */
export interface IExercisesExerciseProgressionsArgs {
  distinct_on?: Maybe<Array<IExerciseProgressionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseProgressionsOrderBy>>;
  where?: Maybe<IExerciseProgressionsBoolExp>;
}


/** columns and relationships of "exercises" */
export interface IExercisesExerciseProgressionsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseProgressionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseProgressionsOrderBy>>;
  where?: Maybe<IExerciseProgressionsBoolExp>;
}

/** aggregated selection of "exercises" */
export interface IExercisesAggregate {
   __typename?: 'exercises_aggregate';
  aggregate?: Maybe<IExercisesAggregateFields>;
  nodes: Array<IExercises>;
}

/** aggregate fields of "exercises" */
export interface IExercisesAggregateFields {
   __typename?: 'exercises_aggregate_fields';
  avg?: Maybe<IExercisesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IExercisesMaxFields>;
  min?: Maybe<IExercisesMinFields>;
  stddev?: Maybe<IExercisesStddevFields>;
  stddev_pop?: Maybe<IExercisesStddevPopFields>;
  stddev_samp?: Maybe<IExercisesStddevSampFields>;
  sum?: Maybe<IExercisesSumFields>;
  var_pop?: Maybe<IExercisesVarPopFields>;
  var_samp?: Maybe<IExercisesVarSampFields>;
  variance?: Maybe<IExercisesVarianceFields>;
}


/** aggregate fields of "exercises" */
export interface IExercisesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IExercisesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "exercises" */
export interface IExercisesAggregateOrderBy {
  avg?: Maybe<IExercisesAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IExercisesMaxOrderBy>;
  min?: Maybe<IExercisesMinOrderBy>;
  stddev?: Maybe<IExercisesStddevOrderBy>;
  stddev_pop?: Maybe<IExercisesStddevPopOrderBy>;
  stddev_samp?: Maybe<IExercisesStddevSampOrderBy>;
  sum?: Maybe<IExercisesSumOrderBy>;
  var_pop?: Maybe<IExercisesVarPopOrderBy>;
  var_samp?: Maybe<IExercisesVarSampOrderBy>;
  variance?: Maybe<IExercisesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "exercises" */
export interface IExercisesArrRelInsertInput {
  data: Array<IExercisesInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IExercisesOnConflict>;
}

/** aggregate avg on columns */
export interface IExercisesAvgFields {
   __typename?: 'exercises_avg_fields';
  duration?: Maybe<Scalars['Float']>;
  exercise_group_id?: Maybe<Scalars['Float']>;
  exercise_variant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rest?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "exercises" */
export interface IExercisesAvgOrderBy {
  duration?: Maybe<IOrderBy>;
  exercise_group_id?: Maybe<IOrderBy>;
  exercise_variant_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  rest?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  weight?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "exercises". All fields are combined with a logical 'AND'. */
export interface IExercisesBoolExp {
  _and?: Maybe<Array<IExercisesBoolExp>>;
  _not?: Maybe<IExercisesBoolExp>;
  _or?: Maybe<Array<IExercisesBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  duration?: Maybe<IIntComparisonExp>;
  exercise_group?: Maybe<IExerciseGroupsBoolExp>;
  exercise_group_id?: Maybe<IBigintComparisonExp>;
  exercise_progressions?: Maybe<IExerciseProgressionsBoolExp>;
  exercise_variant?: Maybe<IExerciseVariantsBoolExp>;
  exercise_variant_id?: Maybe<IBigintComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  repetition?: Maybe<IStringComparisonExp>;
  rest?: Maybe<IIntComparisonExp>;
  sequence?: Maybe<IIntComparisonExp>;
  sets?: Maybe<IStringComparisonExp>;
  unit?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
  weight?: Maybe<INumericComparisonExp>;
}

/** unique or primary key constraints on table "exercises" */
export enum IExercisesConstraint {
  /** unique or primary key constraint */
  EXERCISES_ID_KEY = 'exercises_id_key',
  /** unique or primary key constraint */
  EXERCISES_PKEY = 'exercises_pkey'
}

/** input type for incrementing numeric columns in table "exercises" */
export interface IExercisesIncInput {
  duration?: Maybe<Scalars['Int']>;
  exercise_group_id?: Maybe<Scalars['bigint']>;
  exercise_variant_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  rest?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['numeric']>;
}

/** input type for inserting data into table "exercises" */
export interface IExercisesInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  duration?: Maybe<Scalars['Int']>;
  exercise_group?: Maybe<IExerciseGroupsObjRelInsertInput>;
  exercise_group_id?: Maybe<Scalars['bigint']>;
  exercise_progressions?: Maybe<IExerciseProgressionsArrRelInsertInput>;
  exercise_variant?: Maybe<IExerciseVariantsObjRelInsertInput>;
  exercise_variant_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  repetition?: Maybe<Scalars['String']>;
  rest?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  sets?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  weight?: Maybe<Scalars['numeric']>;
}

/** aggregate max on columns */
export interface IExercisesMaxFields {
   __typename?: 'exercises_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  duration?: Maybe<Scalars['Int']>;
  exercise_group_id?: Maybe<Scalars['bigint']>;
  exercise_variant_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  repetition?: Maybe<Scalars['String']>;
  rest?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  sets?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  weight?: Maybe<Scalars['numeric']>;
}

/** order by max() on columns of table "exercises" */
export interface IExercisesMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  exercise_group_id?: Maybe<IOrderBy>;
  exercise_variant_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  repetition?: Maybe<IOrderBy>;
  rest?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  sets?: Maybe<IOrderBy>;
  unit?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  weight?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IExercisesMinFields {
   __typename?: 'exercises_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  duration?: Maybe<Scalars['Int']>;
  exercise_group_id?: Maybe<Scalars['bigint']>;
  exercise_variant_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  repetition?: Maybe<Scalars['String']>;
  rest?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  sets?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  weight?: Maybe<Scalars['numeric']>;
}

/** order by min() on columns of table "exercises" */
export interface IExercisesMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  exercise_group_id?: Maybe<IOrderBy>;
  exercise_variant_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  repetition?: Maybe<IOrderBy>;
  rest?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  sets?: Maybe<IOrderBy>;
  unit?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  weight?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "exercises" */
export interface IExercisesMutationResponse {
   __typename?: 'exercises_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IExercises>;
}

/** input type for inserting object relation for remote table "exercises" */
export interface IExercisesObjRelInsertInput {
  data: IExercisesInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IExercisesOnConflict>;
}

/** on conflict condition type for table "exercises" */
export interface IExercisesOnConflict {
  constraint: IExercisesConstraint;
  update_columns: Array<IExercisesUpdateColumn>;
  where?: Maybe<IExercisesBoolExp>;
}

/** Ordering options when selecting data from "exercises". */
export interface IExercisesOrderBy {
  created_at?: Maybe<IOrderBy>;
  duration?: Maybe<IOrderBy>;
  exercise_group?: Maybe<IExerciseGroupsOrderBy>;
  exercise_group_id?: Maybe<IOrderBy>;
  exercise_progressions_aggregate?: Maybe<IExerciseProgressionsAggregateOrderBy>;
  exercise_variant?: Maybe<IExerciseVariantsOrderBy>;
  exercise_variant_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  repetition?: Maybe<IOrderBy>;
  rest?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  sets?: Maybe<IOrderBy>;
  unit?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  weight?: Maybe<IOrderBy>;
}

/** primary key columns input for table: exercises */
export interface IExercisesPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "exercises" */
export enum IExercisesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DURATION = 'duration',
  /** column name */
  EXERCISE_GROUP_ID = 'exercise_group_id',
  /** column name */
  EXERCISE_VARIANT_ID = 'exercise_variant_id',
  /** column name */
  ID = 'id',
  /** column name */
  REPETITION = 'repetition',
  /** column name */
  REST = 'rest',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SETS = 'sets',
  /** column name */
  UNIT = 'unit',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WEIGHT = 'weight'
}

/** input type for updating data in table "exercises" */
export interface IExercisesSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  duration?: Maybe<Scalars['Int']>;
  exercise_group_id?: Maybe<Scalars['bigint']>;
  exercise_variant_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  repetition?: Maybe<Scalars['String']>;
  rest?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  sets?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  weight?: Maybe<Scalars['numeric']>;
}

/** aggregate stddev on columns */
export interface IExercisesStddevFields {
   __typename?: 'exercises_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
  exercise_group_id?: Maybe<Scalars['Float']>;
  exercise_variant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rest?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "exercises" */
export interface IExercisesStddevOrderBy {
  duration?: Maybe<IOrderBy>;
  exercise_group_id?: Maybe<IOrderBy>;
  exercise_variant_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  rest?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  weight?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IExercisesStddevPopFields {
   __typename?: 'exercises_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  exercise_group_id?: Maybe<Scalars['Float']>;
  exercise_variant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rest?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "exercises" */
export interface IExercisesStddevPopOrderBy {
  duration?: Maybe<IOrderBy>;
  exercise_group_id?: Maybe<IOrderBy>;
  exercise_variant_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  rest?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  weight?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IExercisesStddevSampFields {
   __typename?: 'exercises_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  exercise_group_id?: Maybe<Scalars['Float']>;
  exercise_variant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rest?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "exercises" */
export interface IExercisesStddevSampOrderBy {
  duration?: Maybe<IOrderBy>;
  exercise_group_id?: Maybe<IOrderBy>;
  exercise_variant_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  rest?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  weight?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IExercisesSumFields {
   __typename?: 'exercises_sum_fields';
  duration?: Maybe<Scalars['Int']>;
  exercise_group_id?: Maybe<Scalars['bigint']>;
  exercise_variant_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  rest?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['numeric']>;
}

/** order by sum() on columns of table "exercises" */
export interface IExercisesSumOrderBy {
  duration?: Maybe<IOrderBy>;
  exercise_group_id?: Maybe<IOrderBy>;
  exercise_variant_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  rest?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  weight?: Maybe<IOrderBy>;
}

/** update columns of table "exercises" */
export enum IExercisesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DURATION = 'duration',
  /** column name */
  EXERCISE_GROUP_ID = 'exercise_group_id',
  /** column name */
  EXERCISE_VARIANT_ID = 'exercise_variant_id',
  /** column name */
  ID = 'id',
  /** column name */
  REPETITION = 'repetition',
  /** column name */
  REST = 'rest',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  SETS = 'sets',
  /** column name */
  UNIT = 'unit',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  WEIGHT = 'weight'
}

/** aggregate var_pop on columns */
export interface IExercisesVarPopFields {
   __typename?: 'exercises_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
  exercise_group_id?: Maybe<Scalars['Float']>;
  exercise_variant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rest?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "exercises" */
export interface IExercisesVarPopOrderBy {
  duration?: Maybe<IOrderBy>;
  exercise_group_id?: Maybe<IOrderBy>;
  exercise_variant_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  rest?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  weight?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IExercisesVarSampFields {
   __typename?: 'exercises_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
  exercise_group_id?: Maybe<Scalars['Float']>;
  exercise_variant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rest?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "exercises" */
export interface IExercisesVarSampOrderBy {
  duration?: Maybe<IOrderBy>;
  exercise_group_id?: Maybe<IOrderBy>;
  exercise_variant_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  rest?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  weight?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IExercisesVarianceFields {
   __typename?: 'exercises_variance_fields';
  duration?: Maybe<Scalars['Float']>;
  exercise_group_id?: Maybe<Scalars['Float']>;
  exercise_variant_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rest?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "exercises" */
export interface IExercisesVarianceOrderBy {
  duration?: Maybe<IOrderBy>;
  exercise_group_id?: Maybe<IOrderBy>;
  exercise_variant_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  rest?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  weight?: Maybe<IOrderBy>;
}

/** columns and relationships of "feature_flags" */
export interface IFeatureFlags {
   __typename?: 'feature_flags';
  active: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
}

/** aggregated selection of "feature_flags" */
export interface IFeatureFlagsAggregate {
   __typename?: 'feature_flags_aggregate';
  aggregate?: Maybe<IFeatureFlagsAggregateFields>;
  nodes: Array<IFeatureFlags>;
}

/** aggregate fields of "feature_flags" */
export interface IFeatureFlagsAggregateFields {
   __typename?: 'feature_flags_aggregate_fields';
  avg?: Maybe<IFeatureFlagsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IFeatureFlagsMaxFields>;
  min?: Maybe<IFeatureFlagsMinFields>;
  stddev?: Maybe<IFeatureFlagsStddevFields>;
  stddev_pop?: Maybe<IFeatureFlagsStddevPopFields>;
  stddev_samp?: Maybe<IFeatureFlagsStddevSampFields>;
  sum?: Maybe<IFeatureFlagsSumFields>;
  var_pop?: Maybe<IFeatureFlagsVarPopFields>;
  var_samp?: Maybe<IFeatureFlagsVarSampFields>;
  variance?: Maybe<IFeatureFlagsVarianceFields>;
}


/** aggregate fields of "feature_flags" */
export interface IFeatureFlagsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IFeatureFlagsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IFeatureFlagsAvgFields {
   __typename?: 'feature_flags_avg_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "feature_flags". All fields are combined with a logical 'AND'. */
export interface IFeatureFlagsBoolExp {
  _and?: Maybe<Array<IFeatureFlagsBoolExp>>;
  _not?: Maybe<IFeatureFlagsBoolExp>;
  _or?: Maybe<Array<IFeatureFlagsBoolExp>>;
  active?: Maybe<IBooleanComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "feature_flags" */
export enum IFeatureFlagsConstraint {
  /** unique or primary key constraint */
  FEATURE_FLAGS_NAME_KEY = 'feature_flags_name_key',
  /** unique or primary key constraint */
  FEATURE_FLAGS_PKEY = 'feature_flags_pkey'
}

/** input type for incrementing numeric columns in table "feature_flags" */
export interface IFeatureFlagsIncInput {
  id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "feature_flags" */
export interface IFeatureFlagsInsertInput {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IFeatureFlagsMaxFields {
   __typename?: 'feature_flags_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IFeatureFlagsMinFields {
   __typename?: 'feature_flags_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "feature_flags" */
export interface IFeatureFlagsMutationResponse {
   __typename?: 'feature_flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IFeatureFlags>;
}

/** on conflict condition type for table "feature_flags" */
export interface IFeatureFlagsOnConflict {
  constraint: IFeatureFlagsConstraint;
  update_columns: Array<IFeatureFlagsUpdateColumn>;
  where?: Maybe<IFeatureFlagsBoolExp>;
}

/** Ordering options when selecting data from "feature_flags". */
export interface IFeatureFlagsOrderBy {
  active?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
}

/** primary key columns input for table: feature_flags */
export interface IFeatureFlagsPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "feature_flags" */
export enum IFeatureFlagsSelectColumn {
  /** column name */
  ACTIVE = 'active',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name'
}

/** input type for updating data in table "feature_flags" */
export interface IFeatureFlagsSetInput {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IFeatureFlagsStddevFields {
   __typename?: 'feature_flags_stddev_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IFeatureFlagsStddevPopFields {
   __typename?: 'feature_flags_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IFeatureFlagsStddevSampFields {
   __typename?: 'feature_flags_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IFeatureFlagsSumFields {
   __typename?: 'feature_flags_sum_fields';
  id?: Maybe<Scalars['Int']>;
}

/** update columns of table "feature_flags" */
export enum IFeatureFlagsUpdateColumn {
  /** column name */
  ACTIVE = 'active',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name'
}

/** aggregate var_pop on columns */
export interface IFeatureFlagsVarPopFields {
   __typename?: 'feature_flags_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IFeatureFlagsVarSampFields {
   __typename?: 'feature_flags_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IFeatureFlagsVarianceFields {
   __typename?: 'feature_flags_variance_fields';
  id?: Maybe<Scalars['Float']>;
}


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export interface IFloat8ComparisonExp {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
}

/** columns and relationships of "inbox" */
export interface IInbox {
   __typename?: 'inbox';
  /** An object relationship */
  client: IUsers;
  client_id: Scalars['Int'];
  /** An object relationship */
  coach: IUsers;
  coach_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  inbox_messages: Array<IInboxMessages>;
  /** An aggregate relationship */
  inbox_messages_aggregate: IInboxMessagesAggregate;
  updated_at: Scalars['timestamptz'];
}


/** columns and relationships of "inbox" */
export interface IInboxInboxMessagesArgs {
  distinct_on?: Maybe<Array<IInboxMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxMessagesOrderBy>>;
  where?: Maybe<IInboxMessagesBoolExp>;
}


/** columns and relationships of "inbox" */
export interface IInboxInboxMessagesAggregateArgs {
  distinct_on?: Maybe<Array<IInboxMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxMessagesOrderBy>>;
  where?: Maybe<IInboxMessagesBoolExp>;
}

/** aggregated selection of "inbox" */
export interface IInboxAggregate {
   __typename?: 'inbox_aggregate';
  aggregate?: Maybe<IInboxAggregateFields>;
  nodes: Array<IInbox>;
}

/** aggregate fields of "inbox" */
export interface IInboxAggregateFields {
   __typename?: 'inbox_aggregate_fields';
  avg?: Maybe<IInboxAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IInboxMaxFields>;
  min?: Maybe<IInboxMinFields>;
  stddev?: Maybe<IInboxStddevFields>;
  stddev_pop?: Maybe<IInboxStddevPopFields>;
  stddev_samp?: Maybe<IInboxStddevSampFields>;
  sum?: Maybe<IInboxSumFields>;
  var_pop?: Maybe<IInboxVarPopFields>;
  var_samp?: Maybe<IInboxVarSampFields>;
  variance?: Maybe<IInboxVarianceFields>;
}


/** aggregate fields of "inbox" */
export interface IInboxAggregateFieldsCountArgs {
  columns?: Maybe<Array<IInboxSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IInboxAvgFields {
   __typename?: 'inbox_avg_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "inbox". All fields are combined with a logical 'AND'. */
export interface IInboxBoolExp {
  _and?: Maybe<Array<IInboxBoolExp>>;
  _not?: Maybe<IInboxBoolExp>;
  _or?: Maybe<Array<IInboxBoolExp>>;
  client?: Maybe<IUsersBoolExp>;
  client_id?: Maybe<IIntComparisonExp>;
  coach?: Maybe<IUsersBoolExp>;
  coach_id?: Maybe<IIntComparisonExp>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  inbox_messages?: Maybe<IInboxMessagesBoolExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "inbox" */
export enum IInboxConstraint {
  /** unique or primary key constraint */
  INBOX_COACH_ID_CLIENT_ID_KEY = 'inbox_coach_id_client_id_key',
  /** unique or primary key constraint */
  INBOX_PKEY = 'inbox_pkey'
}

/** input type for incrementing numeric columns in table "inbox" */
export interface IInboxIncInput {
  client_id?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "inbox" */
export interface IInboxInsertInput {
  client?: Maybe<IUsersObjRelInsertInput>;
  client_id?: Maybe<Scalars['Int']>;
  coach?: Maybe<IUsersObjRelInsertInput>;
  coach_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbox_messages?: Maybe<IInboxMessagesArrRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IInboxMaxFields {
   __typename?: 'inbox_max_fields';
  client_id?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** columns and relationships of "inbox_messages" */
export interface IInboxMessages {
   __typename?: 'inbox_messages';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  inbox: IInbox;
  inbox_id: Scalars['Int'];
  message: Scalars['String'];
  read: Scalars['Boolean'];
  /** An object relationship */
  sender?: Maybe<IUsers>;
  sender_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "inbox_messages" */
export interface IInboxMessagesAggregate {
   __typename?: 'inbox_messages_aggregate';
  aggregate?: Maybe<IInboxMessagesAggregateFields>;
  nodes: Array<IInboxMessages>;
}

/** aggregate fields of "inbox_messages" */
export interface IInboxMessagesAggregateFields {
   __typename?: 'inbox_messages_aggregate_fields';
  avg?: Maybe<IInboxMessagesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IInboxMessagesMaxFields>;
  min?: Maybe<IInboxMessagesMinFields>;
  stddev?: Maybe<IInboxMessagesStddevFields>;
  stddev_pop?: Maybe<IInboxMessagesStddevPopFields>;
  stddev_samp?: Maybe<IInboxMessagesStddevSampFields>;
  sum?: Maybe<IInboxMessagesSumFields>;
  var_pop?: Maybe<IInboxMessagesVarPopFields>;
  var_samp?: Maybe<IInboxMessagesVarSampFields>;
  variance?: Maybe<IInboxMessagesVarianceFields>;
}


/** aggregate fields of "inbox_messages" */
export interface IInboxMessagesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IInboxMessagesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "inbox_messages" */
export interface IInboxMessagesAggregateOrderBy {
  avg?: Maybe<IInboxMessagesAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IInboxMessagesMaxOrderBy>;
  min?: Maybe<IInboxMessagesMinOrderBy>;
  stddev?: Maybe<IInboxMessagesStddevOrderBy>;
  stddev_pop?: Maybe<IInboxMessagesStddevPopOrderBy>;
  stddev_samp?: Maybe<IInboxMessagesStddevSampOrderBy>;
  sum?: Maybe<IInboxMessagesSumOrderBy>;
  var_pop?: Maybe<IInboxMessagesVarPopOrderBy>;
  var_samp?: Maybe<IInboxMessagesVarSampOrderBy>;
  variance?: Maybe<IInboxMessagesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "inbox_messages" */
export interface IInboxMessagesArrRelInsertInput {
  data: Array<IInboxMessagesInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IInboxMessagesOnConflict>;
}

/** aggregate avg on columns */
export interface IInboxMessagesAvgFields {
   __typename?: 'inbox_messages_avg_fields';
  id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "inbox_messages" */
export interface IInboxMessagesAvgOrderBy {
  id?: Maybe<IOrderBy>;
  inbox_id?: Maybe<IOrderBy>;
  sender_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "inbox_messages". All fields are combined with a logical 'AND'. */
export interface IInboxMessagesBoolExp {
  _and?: Maybe<Array<IInboxMessagesBoolExp>>;
  _not?: Maybe<IInboxMessagesBoolExp>;
  _or?: Maybe<Array<IInboxMessagesBoolExp>>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  inbox?: Maybe<IInboxBoolExp>;
  inbox_id?: Maybe<IIntComparisonExp>;
  message?: Maybe<IStringComparisonExp>;
  read?: Maybe<IBooleanComparisonExp>;
  sender?: Maybe<IUsersBoolExp>;
  sender_id?: Maybe<IIntComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "inbox_messages" */
export enum IInboxMessagesConstraint {
  /** unique or primary key constraint */
  INBOX_MESSAGES_PKEY = 'inbox_messages_pkey'
}

/** input type for incrementing numeric columns in table "inbox_messages" */
export interface IInboxMessagesIncInput {
  id?: Maybe<Scalars['Int']>;
  inbox_id?: Maybe<Scalars['Int']>;
  sender_id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "inbox_messages" */
export interface IInboxMessagesInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbox?: Maybe<IInboxObjRelInsertInput>;
  inbox_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  sender?: Maybe<IUsersObjRelInsertInput>;
  sender_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IInboxMessagesMaxFields {
   __typename?: 'inbox_messages_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbox_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "inbox_messages" */
export interface IInboxMessagesMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  inbox_id?: Maybe<IOrderBy>;
  message?: Maybe<IOrderBy>;
  sender_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IInboxMessagesMinFields {
   __typename?: 'inbox_messages_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbox_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  sender_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "inbox_messages" */
export interface IInboxMessagesMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  inbox_id?: Maybe<IOrderBy>;
  message?: Maybe<IOrderBy>;
  sender_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "inbox_messages" */
export interface IInboxMessagesMutationResponse {
   __typename?: 'inbox_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IInboxMessages>;
}

/** on conflict condition type for table "inbox_messages" */
export interface IInboxMessagesOnConflict {
  constraint: IInboxMessagesConstraint;
  update_columns: Array<IInboxMessagesUpdateColumn>;
  where?: Maybe<IInboxMessagesBoolExp>;
}

/** Ordering options when selecting data from "inbox_messages". */
export interface IInboxMessagesOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  inbox?: Maybe<IInboxOrderBy>;
  inbox_id?: Maybe<IOrderBy>;
  message?: Maybe<IOrderBy>;
  read?: Maybe<IOrderBy>;
  sender?: Maybe<IUsersOrderBy>;
  sender_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: inbox_messages */
export interface IInboxMessagesPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "inbox_messages" */
export enum IInboxMessagesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  INBOX_ID = 'inbox_id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  READ = 'read',
  /** column name */
  SENDER_ID = 'sender_id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "inbox_messages" */
export interface IInboxMessagesSetInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbox_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  sender_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IInboxMessagesStddevFields {
   __typename?: 'inbox_messages_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "inbox_messages" */
export interface IInboxMessagesStddevOrderBy {
  id?: Maybe<IOrderBy>;
  inbox_id?: Maybe<IOrderBy>;
  sender_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IInboxMessagesStddevPopFields {
   __typename?: 'inbox_messages_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "inbox_messages" */
export interface IInboxMessagesStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  inbox_id?: Maybe<IOrderBy>;
  sender_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IInboxMessagesStddevSampFields {
   __typename?: 'inbox_messages_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "inbox_messages" */
export interface IInboxMessagesStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  inbox_id?: Maybe<IOrderBy>;
  sender_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IInboxMessagesSumFields {
   __typename?: 'inbox_messages_sum_fields';
  id?: Maybe<Scalars['Int']>;
  inbox_id?: Maybe<Scalars['Int']>;
  sender_id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "inbox_messages" */
export interface IInboxMessagesSumOrderBy {
  id?: Maybe<IOrderBy>;
  inbox_id?: Maybe<IOrderBy>;
  sender_id?: Maybe<IOrderBy>;
}

/** update columns of table "inbox_messages" */
export enum IInboxMessagesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  INBOX_ID = 'inbox_id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  READ = 'read',
  /** column name */
  SENDER_ID = 'sender_id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IInboxMessagesVarPopFields {
   __typename?: 'inbox_messages_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "inbox_messages" */
export interface IInboxMessagesVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  inbox_id?: Maybe<IOrderBy>;
  sender_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IInboxMessagesVarSampFields {
   __typename?: 'inbox_messages_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "inbox_messages" */
export interface IInboxMessagesVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  inbox_id?: Maybe<IOrderBy>;
  sender_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IInboxMessagesVarianceFields {
   __typename?: 'inbox_messages_variance_fields';
  id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
  sender_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "inbox_messages" */
export interface IInboxMessagesVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  inbox_id?: Maybe<IOrderBy>;
  sender_id?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IInboxMinFields {
   __typename?: 'inbox_min_fields';
  client_id?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "inbox" */
export interface IInboxMutationResponse {
   __typename?: 'inbox_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IInbox>;
}

/** input type for inserting object relation for remote table "inbox" */
export interface IInboxObjRelInsertInput {
  data: IInboxInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IInboxOnConflict>;
}

/** on conflict condition type for table "inbox" */
export interface IInboxOnConflict {
  constraint: IInboxConstraint;
  update_columns: Array<IInboxUpdateColumn>;
  where?: Maybe<IInboxBoolExp>;
}

/** Ordering options when selecting data from "inbox". */
export interface IInboxOrderBy {
  client?: Maybe<IUsersOrderBy>;
  client_id?: Maybe<IOrderBy>;
  coach?: Maybe<IUsersOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  inbox_messages_aggregate?: Maybe<IInboxMessagesAggregateOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: inbox */
export interface IInboxPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "inbox" */
export enum IInboxSelectColumn {
  /** column name */
  CLIENT_ID = 'client_id',
  /** column name */
  COACH_ID = 'coach_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "inbox" */
export interface IInboxSetInput {
  client_id?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IInboxStddevFields {
   __typename?: 'inbox_stddev_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IInboxStddevPopFields {
   __typename?: 'inbox_stddev_pop_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IInboxStddevSampFields {
   __typename?: 'inbox_stddev_samp_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IInboxSumFields {
   __typename?: 'inbox_sum_fields';
  client_id?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
}

/** update columns of table "inbox" */
export enum IInboxUpdateColumn {
  /** column name */
  CLIENT_ID = 'client_id',
  /** column name */
  COACH_ID = 'coach_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/**
 * This view is required to access the data (in this case only the name and the avatar) of the coach. The query is only used for members - they can only retrieve their own data because of the permissions. This is used to load the user inboxes (chats that have been created)
 * 
 * 
 * columns and relationships of "inbox_user_view"
 */
export interface IInboxUserView {
   __typename?: 'inbox_user_view';
  client_id?: Maybe<Scalars['Int']>;
  coach_avatar?: Maybe<Scalars['String']>;
  coach_id?: Maybe<Scalars['bigint']>;
  coach_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  inbox?: Maybe<IInbox>;
  inbox_id?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "inbox_user_view" */
export interface IInboxUserViewAggregate {
   __typename?: 'inbox_user_view_aggregate';
  aggregate?: Maybe<IInboxUserViewAggregateFields>;
  nodes: Array<IInboxUserView>;
}

/** aggregate fields of "inbox_user_view" */
export interface IInboxUserViewAggregateFields {
   __typename?: 'inbox_user_view_aggregate_fields';
  avg?: Maybe<IInboxUserViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IInboxUserViewMaxFields>;
  min?: Maybe<IInboxUserViewMinFields>;
  stddev?: Maybe<IInboxUserViewStddevFields>;
  stddev_pop?: Maybe<IInboxUserViewStddevPopFields>;
  stddev_samp?: Maybe<IInboxUserViewStddevSampFields>;
  sum?: Maybe<IInboxUserViewSumFields>;
  var_pop?: Maybe<IInboxUserViewVarPopFields>;
  var_samp?: Maybe<IInboxUserViewVarSampFields>;
  variance?: Maybe<IInboxUserViewVarianceFields>;
}


/** aggregate fields of "inbox_user_view" */
export interface IInboxUserViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<IInboxUserViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IInboxUserViewAvgFields {
   __typename?: 'inbox_user_view_avg_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "inbox_user_view". All fields are combined with a logical 'AND'. */
export interface IInboxUserViewBoolExp {
  _and?: Maybe<Array<IInboxUserViewBoolExp>>;
  _not?: Maybe<IInboxUserViewBoolExp>;
  _or?: Maybe<Array<IInboxUserViewBoolExp>>;
  client_id?: Maybe<IIntComparisonExp>;
  coach_avatar?: Maybe<IStringComparisonExp>;
  coach_id?: Maybe<IBigintComparisonExp>;
  coach_name?: Maybe<IStringComparisonExp>;
  inbox?: Maybe<IInboxBoolExp>;
  inbox_id?: Maybe<IIntComparisonExp>;
}

/** aggregate max on columns */
export interface IInboxUserViewMaxFields {
   __typename?: 'inbox_user_view_max_fields';
  client_id?: Maybe<Scalars['Int']>;
  coach_avatar?: Maybe<Scalars['String']>;
  coach_id?: Maybe<Scalars['bigint']>;
  coach_name?: Maybe<Scalars['String']>;
  inbox_id?: Maybe<Scalars['Int']>;
}

/** aggregate min on columns */
export interface IInboxUserViewMinFields {
   __typename?: 'inbox_user_view_min_fields';
  client_id?: Maybe<Scalars['Int']>;
  coach_avatar?: Maybe<Scalars['String']>;
  coach_id?: Maybe<Scalars['bigint']>;
  coach_name?: Maybe<Scalars['String']>;
  inbox_id?: Maybe<Scalars['Int']>;
}

/** Ordering options when selecting data from "inbox_user_view". */
export interface IInboxUserViewOrderBy {
  client_id?: Maybe<IOrderBy>;
  coach_avatar?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  coach_name?: Maybe<IOrderBy>;
  inbox?: Maybe<IInboxOrderBy>;
  inbox_id?: Maybe<IOrderBy>;
}

/** select columns of table "inbox_user_view" */
export enum IInboxUserViewSelectColumn {
  /** column name */
  CLIENT_ID = 'client_id',
  /** column name */
  COACH_AVATAR = 'coach_avatar',
  /** column name */
  COACH_ID = 'coach_id',
  /** column name */
  COACH_NAME = 'coach_name',
  /** column name */
  INBOX_ID = 'inbox_id'
}

/** aggregate stddev on columns */
export interface IInboxUserViewStddevFields {
   __typename?: 'inbox_user_view_stddev_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IInboxUserViewStddevPopFields {
   __typename?: 'inbox_user_view_stddev_pop_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IInboxUserViewStddevSampFields {
   __typename?: 'inbox_user_view_stddev_samp_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IInboxUserViewSumFields {
   __typename?: 'inbox_user_view_sum_fields';
  client_id?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['bigint']>;
  inbox_id?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface IInboxUserViewVarPopFields {
   __typename?: 'inbox_user_view_var_pop_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IInboxUserViewVarSampFields {
   __typename?: 'inbox_user_view_var_samp_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IInboxUserViewVarianceFields {
   __typename?: 'inbox_user_view_variance_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  inbox_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_pop on columns */
export interface IInboxVarPopFields {
   __typename?: 'inbox_var_pop_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IInboxVarSampFields {
   __typename?: 'inbox_var_samp_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IInboxVarianceFields {
   __typename?: 'inbox_variance_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}


/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export interface IJsonComparisonExp {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
}

/** columns and relationships of "knowledge_base" */
export interface IKnowledgeBase {
   __typename?: 'knowledge_base';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['Int'];
  /** fetch data from the table: "knowledge_base_videos" */
  knowledge_base_videos: Array<IKnowledgeBaseVideos>;
  /** fetch aggregated fields from the table: "knowledge_base_videos" */
  knowledge_base_videos_aggregate: IKnowledgeBaseVideosAggregate;
  sequence: Scalars['Int'];
}


/** columns and relationships of "knowledge_base" */
export interface IKnowledgeBaseKnowledgeBaseVideosArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseVideosSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseVideosOrderBy>>;
  where?: Maybe<IKnowledgeBaseVideosBoolExp>;
}


/** columns and relationships of "knowledge_base" */
export interface IKnowledgeBaseKnowledgeBaseVideosAggregateArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseVideosSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseVideosOrderBy>>;
  where?: Maybe<IKnowledgeBaseVideosBoolExp>;
}

/** aggregated selection of "knowledge_base" */
export interface IKnowledgeBaseAggregate {
   __typename?: 'knowledge_base_aggregate';
  aggregate?: Maybe<IKnowledgeBaseAggregateFields>;
  nodes: Array<IKnowledgeBase>;
}

/** aggregate fields of "knowledge_base" */
export interface IKnowledgeBaseAggregateFields {
   __typename?: 'knowledge_base_aggregate_fields';
  avg?: Maybe<IKnowledgeBaseAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IKnowledgeBaseMaxFields>;
  min?: Maybe<IKnowledgeBaseMinFields>;
  stddev?: Maybe<IKnowledgeBaseStddevFields>;
  stddev_pop?: Maybe<IKnowledgeBaseStddevPopFields>;
  stddev_samp?: Maybe<IKnowledgeBaseStddevSampFields>;
  sum?: Maybe<IKnowledgeBaseSumFields>;
  var_pop?: Maybe<IKnowledgeBaseVarPopFields>;
  var_samp?: Maybe<IKnowledgeBaseVarSampFields>;
  variance?: Maybe<IKnowledgeBaseVarianceFields>;
}


/** aggregate fields of "knowledge_base" */
export interface IKnowledgeBaseAggregateFieldsCountArgs {
  columns?: Maybe<Array<IKnowledgeBaseSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IKnowledgeBaseAvgFields {
   __typename?: 'knowledge_base_avg_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "knowledge_base". All fields are combined with a logical 'AND'. */
export interface IKnowledgeBaseBoolExp {
  _and?: Maybe<Array<IKnowledgeBaseBoolExp>>;
  _not?: Maybe<IKnowledgeBaseBoolExp>;
  _or?: Maybe<Array<IKnowledgeBaseBoolExp>>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  description?: Maybe<IStringComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  knowledge_base_videos?: Maybe<IKnowledgeBaseVideosBoolExp>;
  sequence?: Maybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "knowledge_base" */
export enum IKnowledgeBaseConstraint {
  /** unique or primary key constraint */
  KNOWLEDGE_BASE_PKEY = 'knowledge_base_pkey',
  /** unique or primary key constraint */
  KNOWLEDGE_BASE_SEQUENCE_KEY = 'knowledge_base_sequence_key'
}

/** input type for incrementing numeric columns in table "knowledge_base" */
export interface IKnowledgeBaseIncInput {
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "knowledge_base" */
export interface IKnowledgeBaseInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  knowledge_base_videos?: Maybe<IKnowledgeBaseVideosArrRelInsertInput>;
  sequence?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface IKnowledgeBaseMaxFields {
   __typename?: 'knowledge_base_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
}

/** aggregate min on columns */
export interface IKnowledgeBaseMinFields {
   __typename?: 'knowledge_base_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
}

/** response of any mutation on the table "knowledge_base" */
export interface IKnowledgeBaseMutationResponse {
   __typename?: 'knowledge_base_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IKnowledgeBase>;
}

/** on conflict condition type for table "knowledge_base" */
export interface IKnowledgeBaseOnConflict {
  constraint: IKnowledgeBaseConstraint;
  update_columns: Array<IKnowledgeBaseUpdateColumn>;
  where?: Maybe<IKnowledgeBaseBoolExp>;
}

/** Ordering options when selecting data from "knowledge_base". */
export interface IKnowledgeBaseOrderBy {
  created_at?: Maybe<IOrderBy>;
  description?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_videos_aggregate?: Maybe<IKnowledgeBaseVideosAggregateOrderBy>;
  sequence?: Maybe<IOrderBy>;
}

/** primary key columns input for table: knowledge_base */
export interface IKnowledgeBasePkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "knowledge_base" */
export enum IKnowledgeBaseSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ID = 'id',
  /** column name */
  SEQUENCE = 'sequence'
}

/** input type for updating data in table "knowledge_base" */
export interface IKnowledgeBaseSetInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface IKnowledgeBaseStddevFields {
   __typename?: 'knowledge_base_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IKnowledgeBaseStddevPopFields {
   __typename?: 'knowledge_base_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IKnowledgeBaseStddevSampFields {
   __typename?: 'knowledge_base_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IKnowledgeBaseSumFields {
   __typename?: 'knowledge_base_sum_fields';
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
}

/** update columns of table "knowledge_base" */
export enum IKnowledgeBaseUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  ID = 'id',
  /** column name */
  SEQUENCE = 'sequence'
}

/** aggregate var_pop on columns */
export interface IKnowledgeBaseVarPopFields {
   __typename?: 'knowledge_base_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IKnowledgeBaseVarSampFields {
   __typename?: 'knowledge_base_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IKnowledgeBaseVarianceFields {
   __typename?: 'knowledge_base_variance_fields';
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgresses {
   __typename?: 'knowledge_base_video_progresses';
  completed: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  knowledge_base_video_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['Int'];
}

/** aggregated selection of "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesAggregate {
   __typename?: 'knowledge_base_video_progresses_aggregate';
  aggregate?: Maybe<IKnowledgeBaseVideoProgressesAggregateFields>;
  nodes: Array<IKnowledgeBaseVideoProgresses>;
}

/** aggregate fields of "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesAggregateFields {
   __typename?: 'knowledge_base_video_progresses_aggregate_fields';
  avg?: Maybe<IKnowledgeBaseVideoProgressesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IKnowledgeBaseVideoProgressesMaxFields>;
  min?: Maybe<IKnowledgeBaseVideoProgressesMinFields>;
  stddev?: Maybe<IKnowledgeBaseVideoProgressesStddevFields>;
  stddev_pop?: Maybe<IKnowledgeBaseVideoProgressesStddevPopFields>;
  stddev_samp?: Maybe<IKnowledgeBaseVideoProgressesStddevSampFields>;
  sum?: Maybe<IKnowledgeBaseVideoProgressesSumFields>;
  var_pop?: Maybe<IKnowledgeBaseVideoProgressesVarPopFields>;
  var_samp?: Maybe<IKnowledgeBaseVideoProgressesVarSampFields>;
  variance?: Maybe<IKnowledgeBaseVideoProgressesVarianceFields>;
}


/** aggregate fields of "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IKnowledgeBaseVideoProgressesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesAggregateOrderBy {
  avg?: Maybe<IKnowledgeBaseVideoProgressesAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IKnowledgeBaseVideoProgressesMaxOrderBy>;
  min?: Maybe<IKnowledgeBaseVideoProgressesMinOrderBy>;
  stddev?: Maybe<IKnowledgeBaseVideoProgressesStddevOrderBy>;
  stddev_pop?: Maybe<IKnowledgeBaseVideoProgressesStddevPopOrderBy>;
  stddev_samp?: Maybe<IKnowledgeBaseVideoProgressesStddevSampOrderBy>;
  sum?: Maybe<IKnowledgeBaseVideoProgressesSumOrderBy>;
  var_pop?: Maybe<IKnowledgeBaseVideoProgressesVarPopOrderBy>;
  var_samp?: Maybe<IKnowledgeBaseVideoProgressesVarSampOrderBy>;
  variance?: Maybe<IKnowledgeBaseVideoProgressesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesArrRelInsertInput {
  data: Array<IKnowledgeBaseVideoProgressesInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IKnowledgeBaseVideoProgressesOnConflict>;
}

/** aggregate avg on columns */
export interface IKnowledgeBaseVideoProgressesAvgFields {
   __typename?: 'knowledge_base_video_progresses_avg_fields';
  id?: Maybe<Scalars['Float']>;
  knowledge_base_video_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesAvgOrderBy {
  id?: Maybe<IOrderBy>;
  knowledge_base_video_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "knowledge_base_video_progresses". All fields are combined with a logical 'AND'. */
export interface IKnowledgeBaseVideoProgressesBoolExp {
  _and?: Maybe<Array<IKnowledgeBaseVideoProgressesBoolExp>>;
  _not?: Maybe<IKnowledgeBaseVideoProgressesBoolExp>;
  _or?: Maybe<Array<IKnowledgeBaseVideoProgressesBoolExp>>;
  completed?: Maybe<IBooleanComparisonExp>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  knowledge_base_video_id?: Maybe<IIntComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
  user_id?: Maybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "knowledge_base_video_progresses" */
export enum IKnowledgeBaseVideoProgressesConstraint {
  /** unique or primary key constraint */
  KNOWLEDGE_BASE_VIDEO_PROGRESSES_KNOWLEDGE_BASE_VIDEO_ID_USER_ID = 'knowledge_base_video_progresses_knowledge_base_video_id_user_id',
  /** unique or primary key constraint */
  KNOWLEDGE_BASE_VIDEO_PROGRESSES_PKEY = 'knowledge_base_video_progresses_pkey'
}

/** input type for incrementing numeric columns in table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesIncInput {
  id?: Maybe<Scalars['Int']>;
  knowledge_base_video_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesInsertInput {
  completed?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  knowledge_base_video_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface IKnowledgeBaseVideoProgressesMaxFields {
   __typename?: 'knowledge_base_video_progresses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  knowledge_base_video_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_video_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IKnowledgeBaseVideoProgressesMinFields {
   __typename?: 'knowledge_base_video_progresses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  knowledge_base_video_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_video_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesMutationResponse {
   __typename?: 'knowledge_base_video_progresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IKnowledgeBaseVideoProgresses>;
}

/** on conflict condition type for table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesOnConflict {
  constraint: IKnowledgeBaseVideoProgressesConstraint;
  update_columns: Array<IKnowledgeBaseVideoProgressesUpdateColumn>;
  where?: Maybe<IKnowledgeBaseVideoProgressesBoolExp>;
}

/** Ordering options when selecting data from "knowledge_base_video_progresses". */
export interface IKnowledgeBaseVideoProgressesOrderBy {
  completed?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_video_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** primary key columns input for table: knowledge_base_video_progresses */
export interface IKnowledgeBaseVideoProgressesPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "knowledge_base_video_progresses" */
export enum IKnowledgeBaseVideoProgressesSelectColumn {
  /** column name */
  COMPLETED = 'completed',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  KNOWLEDGE_BASE_VIDEO_ID = 'knowledge_base_video_id',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesSetInput {
  completed?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  knowledge_base_video_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface IKnowledgeBaseVideoProgressesStddevFields {
   __typename?: 'knowledge_base_video_progresses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  knowledge_base_video_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesStddevOrderBy {
  id?: Maybe<IOrderBy>;
  knowledge_base_video_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IKnowledgeBaseVideoProgressesStddevPopFields {
   __typename?: 'knowledge_base_video_progresses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  knowledge_base_video_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  knowledge_base_video_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IKnowledgeBaseVideoProgressesStddevSampFields {
   __typename?: 'knowledge_base_video_progresses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  knowledge_base_video_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  knowledge_base_video_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IKnowledgeBaseVideoProgressesSumFields {
   __typename?: 'knowledge_base_video_progresses_sum_fields';
  id?: Maybe<Scalars['Int']>;
  knowledge_base_video_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesSumOrderBy {
  id?: Maybe<IOrderBy>;
  knowledge_base_video_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** update columns of table "knowledge_base_video_progresses" */
export enum IKnowledgeBaseVideoProgressesUpdateColumn {
  /** column name */
  COMPLETED = 'completed',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  KNOWLEDGE_BASE_VIDEO_ID = 'knowledge_base_video_id',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate var_pop on columns */
export interface IKnowledgeBaseVideoProgressesVarPopFields {
   __typename?: 'knowledge_base_video_progresses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  knowledge_base_video_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  knowledge_base_video_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IKnowledgeBaseVideoProgressesVarSampFields {
   __typename?: 'knowledge_base_video_progresses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  knowledge_base_video_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  knowledge_base_video_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IKnowledgeBaseVideoProgressesVarianceFields {
   __typename?: 'knowledge_base_video_progresses_variance_fields';
  id?: Maybe<Scalars['Float']>;
  knowledge_base_video_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "knowledge_base_video_progresses" */
export interface IKnowledgeBaseVideoProgressesVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  knowledge_base_video_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "knowledge_base_videos" */
export interface IKnowledgeBaseVideos {
   __typename?: 'knowledge_base_videos';
  created_at: Scalars['timestamptz'];
  duration_in_minutes: Scalars['Int'];
  id: Scalars['Int'];
  knowledge_base_id: Scalars['Int'];
  /** An array relationship */
  knowledge_base_video_progresses: Array<IKnowledgeBaseVideoProgresses>;
  /** An aggregate relationship */
  knowledge_base_video_progresses_aggregate: IKnowledgeBaseVideoProgressesAggregate;
  sequence: Scalars['Int'];
  title: Scalars['String'];
  video_url: Scalars['String'];
}


/** columns and relationships of "knowledge_base_videos" */
export interface IKnowledgeBaseVideosKnowledgeBaseVideoProgressesArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseVideoProgressesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseVideoProgressesOrderBy>>;
  where?: Maybe<IKnowledgeBaseVideoProgressesBoolExp>;
}


/** columns and relationships of "knowledge_base_videos" */
export interface IKnowledgeBaseVideosKnowledgeBaseVideoProgressesAggregateArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseVideoProgressesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseVideoProgressesOrderBy>>;
  where?: Maybe<IKnowledgeBaseVideoProgressesBoolExp>;
}

/** aggregated selection of "knowledge_base_videos" */
export interface IKnowledgeBaseVideosAggregate {
   __typename?: 'knowledge_base_videos_aggregate';
  aggregate?: Maybe<IKnowledgeBaseVideosAggregateFields>;
  nodes: Array<IKnowledgeBaseVideos>;
}

/** aggregate fields of "knowledge_base_videos" */
export interface IKnowledgeBaseVideosAggregateFields {
   __typename?: 'knowledge_base_videos_aggregate_fields';
  avg?: Maybe<IKnowledgeBaseVideosAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IKnowledgeBaseVideosMaxFields>;
  min?: Maybe<IKnowledgeBaseVideosMinFields>;
  stddev?: Maybe<IKnowledgeBaseVideosStddevFields>;
  stddev_pop?: Maybe<IKnowledgeBaseVideosStddevPopFields>;
  stddev_samp?: Maybe<IKnowledgeBaseVideosStddevSampFields>;
  sum?: Maybe<IKnowledgeBaseVideosSumFields>;
  var_pop?: Maybe<IKnowledgeBaseVideosVarPopFields>;
  var_samp?: Maybe<IKnowledgeBaseVideosVarSampFields>;
  variance?: Maybe<IKnowledgeBaseVideosVarianceFields>;
}


/** aggregate fields of "knowledge_base_videos" */
export interface IKnowledgeBaseVideosAggregateFieldsCountArgs {
  columns?: Maybe<Array<IKnowledgeBaseVideosSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosAggregateOrderBy {
  avg?: Maybe<IKnowledgeBaseVideosAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IKnowledgeBaseVideosMaxOrderBy>;
  min?: Maybe<IKnowledgeBaseVideosMinOrderBy>;
  stddev?: Maybe<IKnowledgeBaseVideosStddevOrderBy>;
  stddev_pop?: Maybe<IKnowledgeBaseVideosStddevPopOrderBy>;
  stddev_samp?: Maybe<IKnowledgeBaseVideosStddevSampOrderBy>;
  sum?: Maybe<IKnowledgeBaseVideosSumOrderBy>;
  var_pop?: Maybe<IKnowledgeBaseVideosVarPopOrderBy>;
  var_samp?: Maybe<IKnowledgeBaseVideosVarSampOrderBy>;
  variance?: Maybe<IKnowledgeBaseVideosVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosArrRelInsertInput {
  data: Array<IKnowledgeBaseVideosInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IKnowledgeBaseVideosOnConflict>;
}

/** aggregate avg on columns */
export interface IKnowledgeBaseVideosAvgFields {
   __typename?: 'knowledge_base_videos_avg_fields';
  duration_in_minutes?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  knowledge_base_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosAvgOrderBy {
  duration_in_minutes?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "knowledge_base_videos". All fields are combined with a logical 'AND'. */
export interface IKnowledgeBaseVideosBoolExp {
  _and?: Maybe<Array<IKnowledgeBaseVideosBoolExp>>;
  _not?: Maybe<IKnowledgeBaseVideosBoolExp>;
  _or?: Maybe<Array<IKnowledgeBaseVideosBoolExp>>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  duration_in_minutes?: Maybe<IIntComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  knowledge_base_id?: Maybe<IIntComparisonExp>;
  knowledge_base_video_progresses?: Maybe<IKnowledgeBaseVideoProgressesBoolExp>;
  sequence?: Maybe<IIntComparisonExp>;
  title?: Maybe<IStringComparisonExp>;
  video_url?: Maybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "knowledge_base_videos" */
export enum IKnowledgeBaseVideosConstraint {
  /** unique or primary key constraint */
  KNOWLEDGE_BASE_VIDEO_PKEY = 'knowledge_base_video_pkey'
}

/** input type for incrementing numeric columns in table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosIncInput {
  duration_in_minutes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  knowledge_base_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  duration_in_minutes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  knowledge_base_id?: Maybe<Scalars['Int']>;
  knowledge_base_video_progresses?: Maybe<IKnowledgeBaseVideoProgressesArrRelInsertInput>;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IKnowledgeBaseVideosMaxFields {
   __typename?: 'knowledge_base_videos_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  duration_in_minutes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  knowledge_base_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  duration_in_minutes?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  title?: Maybe<IOrderBy>;
  video_url?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IKnowledgeBaseVideosMinFields {
   __typename?: 'knowledge_base_videos_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  duration_in_minutes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  knowledge_base_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  duration_in_minutes?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
  title?: Maybe<IOrderBy>;
  video_url?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosMutationResponse {
   __typename?: 'knowledge_base_videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IKnowledgeBaseVideos>;
}

/** on conflict condition type for table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosOnConflict {
  constraint: IKnowledgeBaseVideosConstraint;
  update_columns: Array<IKnowledgeBaseVideosUpdateColumn>;
  where?: Maybe<IKnowledgeBaseVideosBoolExp>;
}

/** Ordering options when selecting data from "knowledge_base_videos". */
export interface IKnowledgeBaseVideosOrderBy {
  created_at?: Maybe<IOrderBy>;
  duration_in_minutes?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_id?: Maybe<IOrderBy>;
  knowledge_base_video_progresses_aggregate?: Maybe<IKnowledgeBaseVideoProgressesAggregateOrderBy>;
  sequence?: Maybe<IOrderBy>;
  title?: Maybe<IOrderBy>;
  video_url?: Maybe<IOrderBy>;
}

/** primary key columns input for table: knowledge_base_videos */
export interface IKnowledgeBaseVideosPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "knowledge_base_videos" */
export enum IKnowledgeBaseVideosSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DURATION_IN_MINUTES = 'duration_in_minutes',
  /** column name */
  ID = 'id',
  /** column name */
  KNOWLEDGE_BASE_ID = 'knowledge_base_id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  TITLE = 'title',
  /** column name */
  VIDEO_URL = 'video_url'
}

/** input type for updating data in table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosSetInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  duration_in_minutes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  knowledge_base_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IKnowledgeBaseVideosStddevFields {
   __typename?: 'knowledge_base_videos_stddev_fields';
  duration_in_minutes?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  knowledge_base_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosStddevOrderBy {
  duration_in_minutes?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IKnowledgeBaseVideosStddevPopFields {
   __typename?: 'knowledge_base_videos_stddev_pop_fields';
  duration_in_minutes?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  knowledge_base_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosStddevPopOrderBy {
  duration_in_minutes?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IKnowledgeBaseVideosStddevSampFields {
   __typename?: 'knowledge_base_videos_stddev_samp_fields';
  duration_in_minutes?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  knowledge_base_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosStddevSampOrderBy {
  duration_in_minutes?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IKnowledgeBaseVideosSumFields {
   __typename?: 'knowledge_base_videos_sum_fields';
  duration_in_minutes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  knowledge_base_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosSumOrderBy {
  duration_in_minutes?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
}

/** update columns of table "knowledge_base_videos" */
export enum IKnowledgeBaseVideosUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DURATION_IN_MINUTES = 'duration_in_minutes',
  /** column name */
  ID = 'id',
  /** column name */
  KNOWLEDGE_BASE_ID = 'knowledge_base_id',
  /** column name */
  SEQUENCE = 'sequence',
  /** column name */
  TITLE = 'title',
  /** column name */
  VIDEO_URL = 'video_url'
}

/** aggregate var_pop on columns */
export interface IKnowledgeBaseVideosVarPopFields {
   __typename?: 'knowledge_base_videos_var_pop_fields';
  duration_in_minutes?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  knowledge_base_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosVarPopOrderBy {
  duration_in_minutes?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IKnowledgeBaseVideosVarSampFields {
   __typename?: 'knowledge_base_videos_var_samp_fields';
  duration_in_minutes?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  knowledge_base_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosVarSampOrderBy {
  duration_in_minutes?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IKnowledgeBaseVideosVarianceFields {
   __typename?: 'knowledge_base_videos_variance_fields';
  duration_in_minutes?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  knowledge_base_id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "knowledge_base_videos" */
export interface IKnowledgeBaseVideosVarianceOrderBy {
  duration_in_minutes?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  knowledge_base_id?: Maybe<IOrderBy>;
  sequence?: Maybe<IOrderBy>;
}

/** columns and relationships of "meal_plans_view" */
export interface IMealPlansView {
   __typename?: 'meal_plans_view';
  calorie_sum?: Maybe<Scalars['float8']>;
  calories_maintenance_per_day?: Maybe<Scalars['Int']>;
  calories_per_day?: Maybe<Scalars['Int']>;
  calories_weekly_reduction?: Maybe<Scalars['Int']>;
  carbo?: Maybe<Scalars['float8']>;
  carbo_sum?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  fat?: Maybe<Scalars['float8']>;
  fat_sum?: Maybe<Scalars['float8']>;
  max_carbo?: Maybe<Scalars['float8']>;
  max_fat?: Maybe<Scalars['float8']>;
  max_protein?: Maybe<Scalars['float8']>;
  min_carbo?: Maybe<Scalars['float8']>;
  min_fat?: Maybe<Scalars['float8']>;
  min_protein?: Maybe<Scalars['float8']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  protein_sum?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  total_fats?: Maybe<Scalars['float8']>;
  total_protein?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "meal_plans_view" */
export interface IMealPlansViewAggregate {
   __typename?: 'meal_plans_view_aggregate';
  aggregate?: Maybe<IMealPlansViewAggregateFields>;
  nodes: Array<IMealPlansView>;
}

/** aggregate fields of "meal_plans_view" */
export interface IMealPlansViewAggregateFields {
   __typename?: 'meal_plans_view_aggregate_fields';
  avg?: Maybe<IMealPlansViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IMealPlansViewMaxFields>;
  min?: Maybe<IMealPlansViewMinFields>;
  stddev?: Maybe<IMealPlansViewStddevFields>;
  stddev_pop?: Maybe<IMealPlansViewStddevPopFields>;
  stddev_samp?: Maybe<IMealPlansViewStddevSampFields>;
  sum?: Maybe<IMealPlansViewSumFields>;
  var_pop?: Maybe<IMealPlansViewVarPopFields>;
  var_samp?: Maybe<IMealPlansViewVarSampFields>;
  variance?: Maybe<IMealPlansViewVarianceFields>;
}


/** aggregate fields of "meal_plans_view" */
export interface IMealPlansViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<IMealPlansViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IMealPlansViewAvgFields {
   __typename?: 'meal_plans_view_avg_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "meal_plans_view". All fields are combined with a logical 'AND'. */
export interface IMealPlansViewBoolExp {
  _and?: Maybe<Array<IMealPlansViewBoolExp>>;
  _not?: Maybe<IMealPlansViewBoolExp>;
  _or?: Maybe<Array<IMealPlansViewBoolExp>>;
  calorie_sum?: Maybe<IFloat8ComparisonExp>;
  calories_maintenance_per_day?: Maybe<IIntComparisonExp>;
  calories_per_day?: Maybe<IIntComparisonExp>;
  calories_weekly_reduction?: Maybe<IIntComparisonExp>;
  carbo?: Maybe<IFloat8ComparisonExp>;
  carbo_sum?: Maybe<IFloat8ComparisonExp>;
  end_date?: Maybe<IDateComparisonExp>;
  fat?: Maybe<IFloat8ComparisonExp>;
  fat_sum?: Maybe<IFloat8ComparisonExp>;
  max_carbo?: Maybe<IFloat8ComparisonExp>;
  max_fat?: Maybe<IFloat8ComparisonExp>;
  max_protein?: Maybe<IFloat8ComparisonExp>;
  min_carbo?: Maybe<IFloat8ComparisonExp>;
  min_fat?: Maybe<IFloat8ComparisonExp>;
  min_protein?: Maybe<IFloat8ComparisonExp>;
  nutrition_plan_id?: Maybe<IIntComparisonExp>;
  protein?: Maybe<IFloat8ComparisonExp>;
  protein_sum?: Maybe<IFloat8ComparisonExp>;
  start_date?: Maybe<IDateComparisonExp>;
  total_fats?: Maybe<IFloat8ComparisonExp>;
  total_protein?: Maybe<IFloat8ComparisonExp>;
  user_id?: Maybe<IIntComparisonExp>;
}

/** aggregate max on columns */
export interface IMealPlansViewMaxFields {
   __typename?: 'meal_plans_view_max_fields';
  calorie_sum?: Maybe<Scalars['float8']>;
  calories_maintenance_per_day?: Maybe<Scalars['Int']>;
  calories_per_day?: Maybe<Scalars['Int']>;
  calories_weekly_reduction?: Maybe<Scalars['Int']>;
  carbo?: Maybe<Scalars['float8']>;
  carbo_sum?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  fat?: Maybe<Scalars['float8']>;
  fat_sum?: Maybe<Scalars['float8']>;
  max_carbo?: Maybe<Scalars['float8']>;
  max_fat?: Maybe<Scalars['float8']>;
  max_protein?: Maybe<Scalars['float8']>;
  min_carbo?: Maybe<Scalars['float8']>;
  min_fat?: Maybe<Scalars['float8']>;
  min_protein?: Maybe<Scalars['float8']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  protein_sum?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  total_fats?: Maybe<Scalars['float8']>;
  total_protein?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate min on columns */
export interface IMealPlansViewMinFields {
   __typename?: 'meal_plans_view_min_fields';
  calorie_sum?: Maybe<Scalars['float8']>;
  calories_maintenance_per_day?: Maybe<Scalars['Int']>;
  calories_per_day?: Maybe<Scalars['Int']>;
  calories_weekly_reduction?: Maybe<Scalars['Int']>;
  carbo?: Maybe<Scalars['float8']>;
  carbo_sum?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  fat?: Maybe<Scalars['float8']>;
  fat_sum?: Maybe<Scalars['float8']>;
  max_carbo?: Maybe<Scalars['float8']>;
  max_fat?: Maybe<Scalars['float8']>;
  max_protein?: Maybe<Scalars['float8']>;
  min_carbo?: Maybe<Scalars['float8']>;
  min_fat?: Maybe<Scalars['float8']>;
  min_protein?: Maybe<Scalars['float8']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  protein_sum?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  total_fats?: Maybe<Scalars['float8']>;
  total_protein?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** Ordering options when selecting data from "meal_plans_view". */
export interface IMealPlansViewOrderBy {
  calorie_sum?: Maybe<IOrderBy>;
  calories_maintenance_per_day?: Maybe<IOrderBy>;
  calories_per_day?: Maybe<IOrderBy>;
  calories_weekly_reduction?: Maybe<IOrderBy>;
  carbo?: Maybe<IOrderBy>;
  carbo_sum?: Maybe<IOrderBy>;
  end_date?: Maybe<IOrderBy>;
  fat?: Maybe<IOrderBy>;
  fat_sum?: Maybe<IOrderBy>;
  max_carbo?: Maybe<IOrderBy>;
  max_fat?: Maybe<IOrderBy>;
  max_protein?: Maybe<IOrderBy>;
  min_carbo?: Maybe<IOrderBy>;
  min_fat?: Maybe<IOrderBy>;
  min_protein?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  protein_sum?: Maybe<IOrderBy>;
  start_date?: Maybe<IOrderBy>;
  total_fats?: Maybe<IOrderBy>;
  total_protein?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** select columns of table "meal_plans_view" */
export enum IMealPlansViewSelectColumn {
  /** column name */
  CALORIE_SUM = 'calorie_sum',
  /** column name */
  CALORIES_MAINTENANCE_PER_DAY = 'calories_maintenance_per_day',
  /** column name */
  CALORIES_PER_DAY = 'calories_per_day',
  /** column name */
  CALORIES_WEEKLY_REDUCTION = 'calories_weekly_reduction',
  /** column name */
  CARBO = 'carbo',
  /** column name */
  CARBO_SUM = 'carbo_sum',
  /** column name */
  END_DATE = 'end_date',
  /** column name */
  FAT = 'fat',
  /** column name */
  FAT_SUM = 'fat_sum',
  /** column name */
  MAX_CARBO = 'max_carbo',
  /** column name */
  MAX_FAT = 'max_fat',
  /** column name */
  MAX_PROTEIN = 'max_protein',
  /** column name */
  MIN_CARBO = 'min_carbo',
  /** column name */
  MIN_FAT = 'min_fat',
  /** column name */
  MIN_PROTEIN = 'min_protein',
  /** column name */
  NUTRITION_PLAN_ID = 'nutrition_plan_id',
  /** column name */
  PROTEIN = 'protein',
  /** column name */
  PROTEIN_SUM = 'protein_sum',
  /** column name */
  START_DATE = 'start_date',
  /** column name */
  TOTAL_FATS = 'total_fats',
  /** column name */
  TOTAL_PROTEIN = 'total_protein',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IMealPlansViewStddevFields {
   __typename?: 'meal_plans_view_stddev_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IMealPlansViewStddevPopFields {
   __typename?: 'meal_plans_view_stddev_pop_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IMealPlansViewStddevSampFields {
   __typename?: 'meal_plans_view_stddev_samp_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IMealPlansViewSumFields {
   __typename?: 'meal_plans_view_sum_fields';
  calorie_sum?: Maybe<Scalars['float8']>;
  calories_maintenance_per_day?: Maybe<Scalars['Int']>;
  calories_per_day?: Maybe<Scalars['Int']>;
  calories_weekly_reduction?: Maybe<Scalars['Int']>;
  carbo?: Maybe<Scalars['float8']>;
  carbo_sum?: Maybe<Scalars['float8']>;
  fat?: Maybe<Scalars['float8']>;
  fat_sum?: Maybe<Scalars['float8']>;
  max_carbo?: Maybe<Scalars['float8']>;
  max_fat?: Maybe<Scalars['float8']>;
  max_protein?: Maybe<Scalars['float8']>;
  min_carbo?: Maybe<Scalars['float8']>;
  min_fat?: Maybe<Scalars['float8']>;
  min_protein?: Maybe<Scalars['float8']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  protein_sum?: Maybe<Scalars['float8']>;
  total_fats?: Maybe<Scalars['float8']>;
  total_protein?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface IMealPlansViewVarPopFields {
   __typename?: 'meal_plans_view_var_pop_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IMealPlansViewVarSampFields {
   __typename?: 'meal_plans_view_var_samp_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IMealPlansViewVarianceFields {
   __typename?: 'meal_plans_view_variance_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "meals_view" */
export interface IMealsView {
   __typename?: 'meals_view';
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  fats?: Maybe<Scalars['float8']>;
  meal_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  nutrition_meal?: Maybe<INutritionMeals>;
  nutrition_meal_id?: Maybe<Scalars['Int']>;
  nutrition_meal_order?: Maybe<Scalars['Int']>;
  nutrition_meal_product_id?: Maybe<Scalars['Int']>;
  nutrition_meal_product_order?: Maybe<Scalars['Int']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meals_created_at?: Maybe<Scalars['date']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meals_is_original?: Maybe<Scalars['Boolean']>;
  nutrition_plan_order?: Maybe<Scalars['Int']>;
  /** An object relationship */
  product?: Maybe<IProductsRg>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  proteins?: Maybe<Scalars['float8']>;
  quantity?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  units?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregated selection of "meals_view" */
export interface IMealsViewAggregate {
   __typename?: 'meals_view_aggregate';
  aggregate?: Maybe<IMealsViewAggregateFields>;
  nodes: Array<IMealsView>;
}

/** aggregate fields of "meals_view" */
export interface IMealsViewAggregateFields {
   __typename?: 'meals_view_aggregate_fields';
  avg?: Maybe<IMealsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IMealsViewMaxFields>;
  min?: Maybe<IMealsViewMinFields>;
  stddev?: Maybe<IMealsViewStddevFields>;
  stddev_pop?: Maybe<IMealsViewStddevPopFields>;
  stddev_samp?: Maybe<IMealsViewStddevSampFields>;
  sum?: Maybe<IMealsViewSumFields>;
  var_pop?: Maybe<IMealsViewVarPopFields>;
  var_samp?: Maybe<IMealsViewVarSampFields>;
  variance?: Maybe<IMealsViewVarianceFields>;
}


/** aggregate fields of "meals_view" */
export interface IMealsViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<IMealsViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "meals_view" */
export interface IMealsViewAggregateOrderBy {
  avg?: Maybe<IMealsViewAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IMealsViewMaxOrderBy>;
  min?: Maybe<IMealsViewMinOrderBy>;
  stddev?: Maybe<IMealsViewStddevOrderBy>;
  stddev_pop?: Maybe<IMealsViewStddevPopOrderBy>;
  stddev_samp?: Maybe<IMealsViewStddevSampOrderBy>;
  sum?: Maybe<IMealsViewSumOrderBy>;
  var_pop?: Maybe<IMealsViewVarPopOrderBy>;
  var_samp?: Maybe<IMealsViewVarSampOrderBy>;
  variance?: Maybe<IMealsViewVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "meals_view" */
export interface IMealsViewArrRelInsertInput {
  data: Array<IMealsViewInsertInput>;
}

/** aggregate avg on columns */
export interface IMealsViewAvgFields {
   __typename?: 'meals_view_avg_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  nutrition_meal_order?: Maybe<Scalars['Float']>;
  nutrition_meal_product_id?: Maybe<Scalars['Float']>;
  nutrition_meal_product_order?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  nutrition_plan_order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "meals_view" */
export interface IMealsViewAvgOrderBy {
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  nutrition_meal_order?: Maybe<IOrderBy>;
  nutrition_meal_product_id?: Maybe<IOrderBy>;
  nutrition_meal_product_order?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  nutrition_plan_order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  proteins?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "meals_view". All fields are combined with a logical 'AND'. */
export interface IMealsViewBoolExp {
  _and?: Maybe<Array<IMealsViewBoolExp>>;
  _not?: Maybe<IMealsViewBoolExp>;
  _or?: Maybe<Array<IMealsViewBoolExp>>;
  calories?: Maybe<IFloat8ComparisonExp>;
  carbohydrates?: Maybe<IFloat8ComparisonExp>;
  end_date?: Maybe<IDateComparisonExp>;
  fats?: Maybe<IFloat8ComparisonExp>;
  meal_name?: Maybe<IStringComparisonExp>;
  nutrition_meal?: Maybe<INutritionMealsBoolExp>;
  nutrition_meal_id?: Maybe<IIntComparisonExp>;
  nutrition_meal_order?: Maybe<IIntComparisonExp>;
  nutrition_meal_product_id?: Maybe<IIntComparisonExp>;
  nutrition_meal_product_order?: Maybe<IIntComparisonExp>;
  nutrition_plan_id?: Maybe<IIntComparisonExp>;
  nutrition_plan_meals_created_at?: Maybe<IDateComparisonExp>;
  nutrition_plan_meals_id?: Maybe<IIntComparisonExp>;
  nutrition_plan_meals_is_original?: Maybe<IBooleanComparisonExp>;
  nutrition_plan_order?: Maybe<IIntComparisonExp>;
  product?: Maybe<IProductsRgBoolExp>;
  product_id?: Maybe<IIntComparisonExp>;
  product_name?: Maybe<IStringComparisonExp>;
  proteins?: Maybe<IFloat8ComparisonExp>;
  quantity?: Maybe<IIntComparisonExp>;
  slug?: Maybe<IStringComparisonExp>;
  start_date?: Maybe<IDateComparisonExp>;
  units?: Maybe<IStringComparisonExp>;
  user_id?: Maybe<IIntComparisonExp>;
}

/** input type for inserting data into table "meals_view" */
export interface IMealsViewInsertInput {
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  fats?: Maybe<Scalars['float8']>;
  meal_name?: Maybe<Scalars['String']>;
  nutrition_meal?: Maybe<INutritionMealsObjRelInsertInput>;
  nutrition_meal_id?: Maybe<Scalars['Int']>;
  nutrition_meal_order?: Maybe<Scalars['Int']>;
  nutrition_meal_product_id?: Maybe<Scalars['Int']>;
  nutrition_meal_product_order?: Maybe<Scalars['Int']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meals_created_at?: Maybe<Scalars['date']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meals_is_original?: Maybe<Scalars['Boolean']>;
  nutrition_plan_order?: Maybe<Scalars['Int']>;
  product?: Maybe<IProductsRgObjRelInsertInput>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  proteins?: Maybe<Scalars['float8']>;
  quantity?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  units?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface IMealsViewMaxFields {
   __typename?: 'meals_view_max_fields';
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  fats?: Maybe<Scalars['float8']>;
  meal_name?: Maybe<Scalars['String']>;
  nutrition_meal_id?: Maybe<Scalars['Int']>;
  nutrition_meal_order?: Maybe<Scalars['Int']>;
  nutrition_meal_product_id?: Maybe<Scalars['Int']>;
  nutrition_meal_product_order?: Maybe<Scalars['Int']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meals_created_at?: Maybe<Scalars['date']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Int']>;
  nutrition_plan_order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  proteins?: Maybe<Scalars['float8']>;
  quantity?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  units?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "meals_view" */
export interface IMealsViewMaxOrderBy {
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  end_date?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  meal_name?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  nutrition_meal_order?: Maybe<IOrderBy>;
  nutrition_meal_product_id?: Maybe<IOrderBy>;
  nutrition_meal_product_order?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_created_at?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  nutrition_plan_order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  product_name?: Maybe<IOrderBy>;
  proteins?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  slug?: Maybe<IOrderBy>;
  start_date?: Maybe<IOrderBy>;
  units?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IMealsViewMinFields {
   __typename?: 'meals_view_min_fields';
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  fats?: Maybe<Scalars['float8']>;
  meal_name?: Maybe<Scalars['String']>;
  nutrition_meal_id?: Maybe<Scalars['Int']>;
  nutrition_meal_order?: Maybe<Scalars['Int']>;
  nutrition_meal_product_id?: Maybe<Scalars['Int']>;
  nutrition_meal_product_order?: Maybe<Scalars['Int']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meals_created_at?: Maybe<Scalars['date']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Int']>;
  nutrition_plan_order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  proteins?: Maybe<Scalars['float8']>;
  quantity?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  units?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "meals_view" */
export interface IMealsViewMinOrderBy {
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  end_date?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  meal_name?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  nutrition_meal_order?: Maybe<IOrderBy>;
  nutrition_meal_product_id?: Maybe<IOrderBy>;
  nutrition_meal_product_order?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_created_at?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  nutrition_plan_order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  product_name?: Maybe<IOrderBy>;
  proteins?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  slug?: Maybe<IOrderBy>;
  start_date?: Maybe<IOrderBy>;
  units?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** Ordering options when selecting data from "meals_view". */
export interface IMealsViewOrderBy {
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  end_date?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  meal_name?: Maybe<IOrderBy>;
  nutrition_meal?: Maybe<INutritionMealsOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  nutrition_meal_order?: Maybe<IOrderBy>;
  nutrition_meal_product_id?: Maybe<IOrderBy>;
  nutrition_meal_product_order?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_created_at?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_is_original?: Maybe<IOrderBy>;
  nutrition_plan_order?: Maybe<IOrderBy>;
  product?: Maybe<IProductsRgOrderBy>;
  product_id?: Maybe<IOrderBy>;
  product_name?: Maybe<IOrderBy>;
  proteins?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  slug?: Maybe<IOrderBy>;
  start_date?: Maybe<IOrderBy>;
  units?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** select columns of table "meals_view" */
export enum IMealsViewSelectColumn {
  /** column name */
  CALORIES = 'calories',
  /** column name */
  CARBOHYDRATES = 'carbohydrates',
  /** column name */
  END_DATE = 'end_date',
  /** column name */
  FATS = 'fats',
  /** column name */
  MEAL_NAME = 'meal_name',
  /** column name */
  NUTRITION_MEAL_ID = 'nutrition_meal_id',
  /** column name */
  NUTRITION_MEAL_ORDER = 'nutrition_meal_order',
  /** column name */
  NUTRITION_MEAL_PRODUCT_ID = 'nutrition_meal_product_id',
  /** column name */
  NUTRITION_MEAL_PRODUCT_ORDER = 'nutrition_meal_product_order',
  /** column name */
  NUTRITION_PLAN_ID = 'nutrition_plan_id',
  /** column name */
  NUTRITION_PLAN_MEALS_CREATED_AT = 'nutrition_plan_meals_created_at',
  /** column name */
  NUTRITION_PLAN_MEALS_ID = 'nutrition_plan_meals_id',
  /** column name */
  NUTRITION_PLAN_MEALS_IS_ORIGINAL = 'nutrition_plan_meals_is_original',
  /** column name */
  NUTRITION_PLAN_ORDER = 'nutrition_plan_order',
  /** column name */
  PRODUCT_ID = 'product_id',
  /** column name */
  PRODUCT_NAME = 'product_name',
  /** column name */
  PROTEINS = 'proteins',
  /** column name */
  QUANTITY = 'quantity',
  /** column name */
  SLUG = 'slug',
  /** column name */
  START_DATE = 'start_date',
  /** column name */
  UNITS = 'units',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IMealsViewStddevFields {
   __typename?: 'meals_view_stddev_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  nutrition_meal_order?: Maybe<Scalars['Float']>;
  nutrition_meal_product_id?: Maybe<Scalars['Float']>;
  nutrition_meal_product_order?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  nutrition_plan_order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "meals_view" */
export interface IMealsViewStddevOrderBy {
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  nutrition_meal_order?: Maybe<IOrderBy>;
  nutrition_meal_product_id?: Maybe<IOrderBy>;
  nutrition_meal_product_order?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  nutrition_plan_order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  proteins?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IMealsViewStddevPopFields {
   __typename?: 'meals_view_stddev_pop_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  nutrition_meal_order?: Maybe<Scalars['Float']>;
  nutrition_meal_product_id?: Maybe<Scalars['Float']>;
  nutrition_meal_product_order?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  nutrition_plan_order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "meals_view" */
export interface IMealsViewStddevPopOrderBy {
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  nutrition_meal_order?: Maybe<IOrderBy>;
  nutrition_meal_product_id?: Maybe<IOrderBy>;
  nutrition_meal_product_order?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  nutrition_plan_order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  proteins?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IMealsViewStddevSampFields {
   __typename?: 'meals_view_stddev_samp_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  nutrition_meal_order?: Maybe<Scalars['Float']>;
  nutrition_meal_product_id?: Maybe<Scalars['Float']>;
  nutrition_meal_product_order?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  nutrition_plan_order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "meals_view" */
export interface IMealsViewStddevSampOrderBy {
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  nutrition_meal_order?: Maybe<IOrderBy>;
  nutrition_meal_product_id?: Maybe<IOrderBy>;
  nutrition_meal_product_order?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  nutrition_plan_order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  proteins?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IMealsViewSumFields {
   __typename?: 'meals_view_sum_fields';
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  fats?: Maybe<Scalars['float8']>;
  nutrition_meal_id?: Maybe<Scalars['Int']>;
  nutrition_meal_order?: Maybe<Scalars['Int']>;
  nutrition_meal_product_id?: Maybe<Scalars['Int']>;
  nutrition_meal_product_order?: Maybe<Scalars['Int']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Int']>;
  nutrition_plan_order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  proteins?: Maybe<Scalars['float8']>;
  quantity?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "meals_view" */
export interface IMealsViewSumOrderBy {
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  nutrition_meal_order?: Maybe<IOrderBy>;
  nutrition_meal_product_id?: Maybe<IOrderBy>;
  nutrition_meal_product_order?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  nutrition_plan_order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  proteins?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate var_pop on columns */
export interface IMealsViewVarPopFields {
   __typename?: 'meals_view_var_pop_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  nutrition_meal_order?: Maybe<Scalars['Float']>;
  nutrition_meal_product_id?: Maybe<Scalars['Float']>;
  nutrition_meal_product_order?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  nutrition_plan_order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "meals_view" */
export interface IMealsViewVarPopOrderBy {
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  nutrition_meal_order?: Maybe<IOrderBy>;
  nutrition_meal_product_id?: Maybe<IOrderBy>;
  nutrition_meal_product_order?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  nutrition_plan_order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  proteins?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IMealsViewVarSampFields {
   __typename?: 'meals_view_var_samp_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  nutrition_meal_order?: Maybe<Scalars['Float']>;
  nutrition_meal_product_id?: Maybe<Scalars['Float']>;
  nutrition_meal_product_order?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  nutrition_plan_order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "meals_view" */
export interface IMealsViewVarSampOrderBy {
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  nutrition_meal_order?: Maybe<IOrderBy>;
  nutrition_meal_product_id?: Maybe<IOrderBy>;
  nutrition_meal_product_order?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  nutrition_plan_order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  proteins?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IMealsViewVarianceFields {
   __typename?: 'meals_view_variance_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  nutrition_meal_order?: Maybe<Scalars['Float']>;
  nutrition_meal_product_id?: Maybe<Scalars['Float']>;
  nutrition_meal_product_order?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  nutrition_plan_order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "meals_view" */
export interface IMealsViewVarianceOrderBy {
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  nutrition_meal_order?: Maybe<IOrderBy>;
  nutrition_meal_product_id?: Maybe<IOrderBy>;
  nutrition_meal_product_order?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  nutrition_plan_order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  proteins?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "messages" */
export interface IMessages {
   __typename?: 'messages';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  /** An object relationship */
  program_message: IProgramMessages;
  program_message_id: Scalars['bigint'];
  /** An object relationship */
  program_plan: IProgramPlans;
  program_plan_id: Scalars['bigint'];
  scheduled_at?: Maybe<Scalars['timestamp']>;
  updated_at: Scalars['timestamp'];
}

/** aggregated selection of "messages" */
export interface IMessagesAggregate {
   __typename?: 'messages_aggregate';
  aggregate?: Maybe<IMessagesAggregateFields>;
  nodes: Array<IMessages>;
}

/** aggregate fields of "messages" */
export interface IMessagesAggregateFields {
   __typename?: 'messages_aggregate_fields';
  avg?: Maybe<IMessagesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IMessagesMaxFields>;
  min?: Maybe<IMessagesMinFields>;
  stddev?: Maybe<IMessagesStddevFields>;
  stddev_pop?: Maybe<IMessagesStddevPopFields>;
  stddev_samp?: Maybe<IMessagesStddevSampFields>;
  sum?: Maybe<IMessagesSumFields>;
  var_pop?: Maybe<IMessagesVarPopFields>;
  var_samp?: Maybe<IMessagesVarSampFields>;
  variance?: Maybe<IMessagesVarianceFields>;
}


/** aggregate fields of "messages" */
export interface IMessagesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IMessagesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "messages" */
export interface IMessagesAggregateOrderBy {
  avg?: Maybe<IMessagesAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IMessagesMaxOrderBy>;
  min?: Maybe<IMessagesMinOrderBy>;
  stddev?: Maybe<IMessagesStddevOrderBy>;
  stddev_pop?: Maybe<IMessagesStddevPopOrderBy>;
  stddev_samp?: Maybe<IMessagesStddevSampOrderBy>;
  sum?: Maybe<IMessagesSumOrderBy>;
  var_pop?: Maybe<IMessagesVarPopOrderBy>;
  var_samp?: Maybe<IMessagesVarSampOrderBy>;
  variance?: Maybe<IMessagesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "messages" */
export interface IMessagesArrRelInsertInput {
  data: Array<IMessagesInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IMessagesOnConflict>;
}

/** aggregate avg on columns */
export interface IMessagesAvgFields {
   __typename?: 'messages_avg_fields';
  id?: Maybe<Scalars['Float']>;
  program_message_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "messages" */
export interface IMessagesAvgOrderBy {
  id?: Maybe<IOrderBy>;
  program_message_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "messages". All fields are combined with a logical 'AND'. */
export interface IMessagesBoolExp {
  _and?: Maybe<Array<IMessagesBoolExp>>;
  _not?: Maybe<IMessagesBoolExp>;
  _or?: Maybe<Array<IMessagesBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  program_message?: Maybe<IProgramMessagesBoolExp>;
  program_message_id?: Maybe<IBigintComparisonExp>;
  program_plan?: Maybe<IProgramPlansBoolExp>;
  program_plan_id?: Maybe<IBigintComparisonExp>;
  scheduled_at?: Maybe<ITimestampComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "messages" */
export enum IMessagesConstraint {
  /** unique or primary key constraint */
  MESSAGES_PKEY = 'messages_pkey'
}

/** input type for incrementing numeric columns in table "messages" */
export interface IMessagesIncInput {
  id?: Maybe<Scalars['bigint']>;
  program_message_id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "messages" */
export interface IMessagesInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  program_message?: Maybe<IProgramMessagesObjRelInsertInput>;
  program_message_id?: Maybe<Scalars['bigint']>;
  program_plan?: Maybe<IProgramPlansObjRelInsertInput>;
  program_plan_id?: Maybe<Scalars['bigint']>;
  scheduled_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IMessagesMaxFields {
   __typename?: 'messages_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  program_message_id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
  scheduled_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by max() on columns of table "messages" */
export interface IMessagesMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_message_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
  scheduled_at?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IMessagesMinFields {
   __typename?: 'messages_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  program_message_id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
  scheduled_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by min() on columns of table "messages" */
export interface IMessagesMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_message_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
  scheduled_at?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "messages" */
export interface IMessagesMutationResponse {
   __typename?: 'messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IMessages>;
}

/** on conflict condition type for table "messages" */
export interface IMessagesOnConflict {
  constraint: IMessagesConstraint;
  update_columns: Array<IMessagesUpdateColumn>;
  where?: Maybe<IMessagesBoolExp>;
}

/** Ordering options when selecting data from "messages". */
export interface IMessagesOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_message?: Maybe<IProgramMessagesOrderBy>;
  program_message_id?: Maybe<IOrderBy>;
  program_plan?: Maybe<IProgramPlansOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
  scheduled_at?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: messages */
export interface IMessagesPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "messages" */
export enum IMessagesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  PROGRAM_MESSAGE_ID = 'program_message_id',
  /** column name */
  PROGRAM_PLAN_ID = 'program_plan_id',
  /** column name */
  SCHEDULED_AT = 'scheduled_at',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "messages" */
export interface IMessagesSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  program_message_id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
  scheduled_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IMessagesStddevFields {
   __typename?: 'messages_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  program_message_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "messages" */
export interface IMessagesStddevOrderBy {
  id?: Maybe<IOrderBy>;
  program_message_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IMessagesStddevPopFields {
   __typename?: 'messages_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  program_message_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "messages" */
export interface IMessagesStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  program_message_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IMessagesStddevSampFields {
   __typename?: 'messages_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  program_message_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "messages" */
export interface IMessagesStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  program_message_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IMessagesSumFields {
   __typename?: 'messages_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  program_message_id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "messages" */
export interface IMessagesSumOrderBy {
  id?: Maybe<IOrderBy>;
  program_message_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** update columns of table "messages" */
export enum IMessagesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  PROGRAM_MESSAGE_ID = 'program_message_id',
  /** column name */
  PROGRAM_PLAN_ID = 'program_plan_id',
  /** column name */
  SCHEDULED_AT = 'scheduled_at',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IMessagesVarPopFields {
   __typename?: 'messages_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  program_message_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "messages" */
export interface IMessagesVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  program_message_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IMessagesVarSampFields {
   __typename?: 'messages_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  program_message_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "messages" */
export interface IMessagesVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  program_message_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IMessagesVarianceFields {
   __typename?: 'messages_variance_fields';
  id?: Maybe<Scalars['Float']>;
  program_message_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "messages" */
export interface IMessagesVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  program_message_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** mutation root */
export interface IMutationRoot {
   __typename?: 'mutation_root';
  /** delete data from the table: "blog_articles" */
  delete_blog_articles?: Maybe<IBlogArticlesMutationResponse>;
  /** delete single row from the table: "blog_articles" */
  delete_blog_articles_by_pk?: Maybe<IBlogArticles>;
  /** delete data from the table: "blog_categories" */
  delete_blog_categories?: Maybe<IBlogCategoriesMutationResponse>;
  /** delete single row from the table: "blog_categories" */
  delete_blog_categories_by_pk?: Maybe<IBlogCategories>;
  /** delete data from the table: "cookbook_categories" */
  delete_cookbook_categories?: Maybe<ICookbookCategoriesMutationResponse>;
  /** delete single row from the table: "cookbook_categories" */
  delete_cookbook_categories_by_pk?: Maybe<ICookbookCategories>;
  /** delete data from the table: "cookbook_recipes" */
  delete_cookbook_recipes?: Maybe<ICookbookRecipesMutationResponse>;
  /** delete single row from the table: "cookbook_recipes" */
  delete_cookbook_recipes_by_pk?: Maybe<ICookbookRecipes>;
  /** delete data from the table: "diary_answers" */
  delete_diary_answers?: Maybe<IDiaryAnswersMutationResponse>;
  /** delete single row from the table: "diary_answers" */
  delete_diary_answers_by_pk?: Maybe<IDiaryAnswers>;
  /** delete data from the table: "diary_question_categories" */
  delete_diary_question_categories?: Maybe<IDiaryQuestionCategoriesMutationResponse>;
  /** delete single row from the table: "diary_question_categories" */
  delete_diary_question_categories_by_pk?: Maybe<IDiaryQuestionCategories>;
  /** delete data from the table: "diary_question_types" */
  delete_diary_question_types?: Maybe<IDiaryQuestionTypesMutationResponse>;
  /** delete single row from the table: "diary_question_types" */
  delete_diary_question_types_by_pk?: Maybe<IDiaryQuestionTypes>;
  /** delete data from the table: "diary_questions" */
  delete_diary_questions?: Maybe<IDiaryQuestionsMutationResponse>;
  /** delete single row from the table: "diary_questions" */
  delete_diary_questions_by_pk?: Maybe<IDiaryQuestions>;
  /** delete data from the table: "diet_summaries" */
  delete_diet_summaries?: Maybe<IDietSummariesMutationResponse>;
  /** delete single row from the table: "diet_summaries" */
  delete_diet_summaries_by_pk?: Maybe<IDietSummaries>;
  /** delete data from the table: "errors" */
  delete_errors?: Maybe<IErrorsMutationResponse>;
  /** delete single row from the table: "errors" */
  delete_errors_by_pk?: Maybe<IErrors>;
  /** delete data from the table: "events" */
  delete_events?: Maybe<IEventsMutationResponse>;
  /** delete single row from the table: "events" */
  delete_events_by_pk?: Maybe<IEvents>;
  /** delete data from the table: "exercise_day_progressions" */
  delete_exercise_day_progressions?: Maybe<IExerciseDayProgressionsMutationResponse>;
  /** delete single row from the table: "exercise_day_progressions" */
  delete_exercise_day_progressions_by_pk?: Maybe<IExerciseDayProgressions>;
  /** delete data from the table: "exercise_days" */
  delete_exercise_days?: Maybe<IExerciseDaysMutationResponse>;
  /** delete single row from the table: "exercise_days" */
  delete_exercise_days_by_pk?: Maybe<IExerciseDays>;
  /** delete data from the table: "exercise_device_categories" */
  delete_exercise_device_categories?: Maybe<IExerciseDeviceCategoriesMutationResponse>;
  /** delete single row from the table: "exercise_device_categories" */
  delete_exercise_device_categories_by_pk?: Maybe<IExerciseDeviceCategories>;
  /** delete data from the table: "exercise_groups" */
  delete_exercise_groups?: Maybe<IExerciseGroupsMutationResponse>;
  /** delete single row from the table: "exercise_groups" */
  delete_exercise_groups_by_pk?: Maybe<IExerciseGroups>;
  /** delete data from the table: "exercise_muscle_categories" */
  delete_exercise_muscle_categories?: Maybe<IExerciseMuscleCategoriesMutationResponse>;
  /** delete single row from the table: "exercise_muscle_categories" */
  delete_exercise_muscle_categories_by_pk?: Maybe<IExerciseMuscleCategories>;
  /** delete data from the table: "exercise_muscle_categories_enum" */
  delete_exercise_muscle_categories_enum?: Maybe<IExerciseMuscleCategoriesEnumMutationResponse>;
  /** delete single row from the table: "exercise_muscle_categories_enum" */
  delete_exercise_muscle_categories_enum_by_pk?: Maybe<IExerciseMuscleCategoriesEnum>;
  /** delete data from the table: "exercise_progressions" */
  delete_exercise_progressions?: Maybe<IExerciseProgressionsMutationResponse>;
  /** delete single row from the table: "exercise_progressions" */
  delete_exercise_progressions_by_pk?: Maybe<IExerciseProgressions>;
  /** delete data from the table: "exercise_variants" */
  delete_exercise_variants?: Maybe<IExerciseVariantsMutationResponse>;
  /** delete single row from the table: "exercise_variants" */
  delete_exercise_variants_by_pk?: Maybe<IExerciseVariants>;
  /** delete data from the table: "exercises" */
  delete_exercises?: Maybe<IExercisesMutationResponse>;
  /** delete single row from the table: "exercises" */
  delete_exercises_by_pk?: Maybe<IExercises>;
  /** delete data from the table: "feature_flags" */
  delete_feature_flags?: Maybe<IFeatureFlagsMutationResponse>;
  /** delete single row from the table: "feature_flags" */
  delete_feature_flags_by_pk?: Maybe<IFeatureFlags>;
  /** delete data from the table: "inbox" */
  delete_inbox?: Maybe<IInboxMutationResponse>;
  /** delete single row from the table: "inbox" */
  delete_inbox_by_pk?: Maybe<IInbox>;
  /** delete data from the table: "inbox_messages" */
  delete_inbox_messages?: Maybe<IInboxMessagesMutationResponse>;
  /** delete single row from the table: "inbox_messages" */
  delete_inbox_messages_by_pk?: Maybe<IInboxMessages>;
  /** delete data from the table: "knowledge_base" */
  delete_knowledge_base?: Maybe<IKnowledgeBaseMutationResponse>;
  /** delete single row from the table: "knowledge_base" */
  delete_knowledge_base_by_pk?: Maybe<IKnowledgeBase>;
  /** delete data from the table: "knowledge_base_video_progresses" */
  delete_knowledge_base_video_progresses?: Maybe<IKnowledgeBaseVideoProgressesMutationResponse>;
  /** delete single row from the table: "knowledge_base_video_progresses" */
  delete_knowledge_base_video_progresses_by_pk?: Maybe<IKnowledgeBaseVideoProgresses>;
  /** delete data from the table: "knowledge_base_videos" */
  delete_knowledge_base_videos?: Maybe<IKnowledgeBaseVideosMutationResponse>;
  /** delete single row from the table: "knowledge_base_videos" */
  delete_knowledge_base_videos_by_pk?: Maybe<IKnowledgeBaseVideos>;
  /** delete data from the table: "messages" */
  delete_messages?: Maybe<IMessagesMutationResponse>;
  /** delete single row from the table: "messages" */
  delete_messages_by_pk?: Maybe<IMessages>;
  /** delete data from the table: "nutrition_meal_products" */
  delete_nutrition_meal_products?: Maybe<INutritionMealProductsMutationResponse>;
  /** delete single row from the table: "nutrition_meal_products" */
  delete_nutrition_meal_products_by_pk?: Maybe<INutritionMealProducts>;
  /** delete data from the table: "nutrition_meals" */
  delete_nutrition_meals?: Maybe<INutritionMealsMutationResponse>;
  /** delete single row from the table: "nutrition_meals" */
  delete_nutrition_meals_by_pk?: Maybe<INutritionMeals>;
  /** delete data from the table: "nutrition_plan_meals" */
  delete_nutrition_plan_meals?: Maybe<INutritionPlanMealsMutationResponse>;
  /** delete single row from the table: "nutrition_plan_meals" */
  delete_nutrition_plan_meals_by_pk?: Maybe<INutritionPlanMeals>;
  /** delete data from the table: "nutrition_plans" */
  delete_nutrition_plans?: Maybe<INutritionPlansMutationResponse>;
  /** delete single row from the table: "nutrition_plans" */
  delete_nutrition_plans_by_pk?: Maybe<INutritionPlans>;
  /** delete data from the table: "product_categories" */
  delete_product_categories?: Maybe<IProductCategoriesMutationResponse>;
  /** delete single row from the table: "product_categories" */
  delete_product_categories_by_pk?: Maybe<IProductCategories>;
  /** delete data from the table: "products_rg" */
  delete_products_rg?: Maybe<IProductsRgMutationResponse>;
  /** delete single row from the table: "products_rg" */
  delete_products_rg_by_pk?: Maybe<IProductsRg>;
  /** delete data from the table: "products_rg_review" */
  delete_products_rg_review?: Maybe<IProductsRgReviewMutationResponse>;
  /** delete single row from the table: "products_rg_review" */
  delete_products_rg_review_by_pk?: Maybe<IProductsRgReview>;
  /** delete data from the table: "program_event_roles" */
  delete_program_event_roles?: Maybe<IProgramEventRolesMutationResponse>;
  /** delete single row from the table: "program_event_roles" */
  delete_program_event_roles_by_pk?: Maybe<IProgramEventRoles>;
  /** delete data from the table: "program_events" */
  delete_program_events?: Maybe<IProgramEventsMutationResponse>;
  /** delete single row from the table: "program_events" */
  delete_program_events_by_pk?: Maybe<IProgramEvents>;
  /** delete data from the table: "program_goals" */
  delete_program_goals?: Maybe<IProgramGoalsMutationResponse>;
  /** delete single row from the table: "program_goals" */
  delete_program_goals_by_pk?: Maybe<IProgramGoals>;
  /** delete data from the table: "program_messages" */
  delete_program_messages?: Maybe<IProgramMessagesMutationResponse>;
  /** delete single row from the table: "program_messages" */
  delete_program_messages_by_pk?: Maybe<IProgramMessages>;
  /** delete data from the table: "program_plan_employees" */
  delete_program_plan_employees?: Maybe<IProgramPlanEmployeesMutationResponse>;
  /** delete single row from the table: "program_plan_employees" */
  delete_program_plan_employees_by_pk?: Maybe<IProgramPlanEmployees>;
  /** delete data from the table: "program_plan_participators" */
  delete_program_plan_participators?: Maybe<IProgramPlanParticipatorsMutationResponse>;
  /** delete single row from the table: "program_plan_participators" */
  delete_program_plan_participators_by_pk?: Maybe<IProgramPlanParticipators>;
  /** delete data from the table: "program_plans" */
  delete_program_plans?: Maybe<IProgramPlansMutationResponse>;
  /** delete single row from the table: "program_plans" */
  delete_program_plans_by_pk?: Maybe<IProgramPlans>;
  /** delete data from the table: "programs" */
  delete_programs?: Maybe<IProgramsMutationResponse>;
  /** delete single row from the table: "programs" */
  delete_programs_by_pk?: Maybe<IPrograms>;
  /** delete data from the table: "progress_days" */
  delete_progress_days?: Maybe<IProgressDaysMutationResponse>;
  /** delete single row from the table: "progress_days" */
  delete_progress_days_by_pk?: Maybe<IProgressDays>;
  /** delete data from the table: "progress_weeks" */
  delete_progress_weeks?: Maybe<IProgressWeeksMutationResponse>;
  /** delete single row from the table: "progress_weeks" */
  delete_progress_weeks_by_pk?: Maybe<IProgressWeeks>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<IRolesMutationResponse>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<IRoles>;
  /** delete data from the table: "roles_users" */
  delete_roles_users?: Maybe<IRolesUsersMutationResponse>;
  /** delete single row from the table: "roles_users" */
  delete_roles_users_by_pk?: Maybe<IRolesUsers>;
  /** delete data from the table: "user_profiles" */
  delete_user_profiles?: Maybe<IUserProfilesMutationResponse>;
  /** delete single row from the table: "user_profiles" */
  delete_user_profiles_by_pk?: Maybe<IUserProfiles>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<IUsersMutationResponse>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<IUsers>;
  /** delete data from the table: "water_progress_days" */
  delete_water_progress_days?: Maybe<IWaterProgressDaysMutationResponse>;
  /** delete single row from the table: "water_progress_days" */
  delete_water_progress_days_by_pk?: Maybe<IWaterProgressDays>;
  /** delete data from the table: "workout_plans" */
  delete_workout_plans?: Maybe<IWorkoutPlansMutationResponse>;
  /** delete single row from the table: "workout_plans" */
  delete_workout_plans_by_pk?: Maybe<IWorkoutPlans>;
  /** delete data from the table: "workout_templates" */
  delete_workout_templates?: Maybe<IWorkoutTemplatesMutationResponse>;
  /** delete single row from the table: "workout_templates" */
  delete_workout_templates_by_pk?: Maybe<IWorkoutTemplates>;
  /** insert data into the table: "blog_articles" */
  insert_blog_articles?: Maybe<IBlogArticlesMutationResponse>;
  /** insert a single row into the table: "blog_articles" */
  insert_blog_articles_one?: Maybe<IBlogArticles>;
  /** insert data into the table: "blog_categories" */
  insert_blog_categories?: Maybe<IBlogCategoriesMutationResponse>;
  /** insert a single row into the table: "blog_categories" */
  insert_blog_categories_one?: Maybe<IBlogCategories>;
  /** insert data into the table: "cookbook_categories" */
  insert_cookbook_categories?: Maybe<ICookbookCategoriesMutationResponse>;
  /** insert a single row into the table: "cookbook_categories" */
  insert_cookbook_categories_one?: Maybe<ICookbookCategories>;
  /** insert data into the table: "cookbook_recipes" */
  insert_cookbook_recipes?: Maybe<ICookbookRecipesMutationResponse>;
  /** insert a single row into the table: "cookbook_recipes" */
  insert_cookbook_recipes_one?: Maybe<ICookbookRecipes>;
  /** insert data into the table: "diary_answers" */
  insert_diary_answers?: Maybe<IDiaryAnswersMutationResponse>;
  /** insert a single row into the table: "diary_answers" */
  insert_diary_answers_one?: Maybe<IDiaryAnswers>;
  /** insert data into the table: "diary_question_categories" */
  insert_diary_question_categories?: Maybe<IDiaryQuestionCategoriesMutationResponse>;
  /** insert a single row into the table: "diary_question_categories" */
  insert_diary_question_categories_one?: Maybe<IDiaryQuestionCategories>;
  /** insert data into the table: "diary_question_types" */
  insert_diary_question_types?: Maybe<IDiaryQuestionTypesMutationResponse>;
  /** insert a single row into the table: "diary_question_types" */
  insert_diary_question_types_one?: Maybe<IDiaryQuestionTypes>;
  /** insert data into the table: "diary_questions" */
  insert_diary_questions?: Maybe<IDiaryQuestionsMutationResponse>;
  /** insert a single row into the table: "diary_questions" */
  insert_diary_questions_one?: Maybe<IDiaryQuestions>;
  /** insert data into the table: "diet_summaries" */
  insert_diet_summaries?: Maybe<IDietSummariesMutationResponse>;
  /** insert a single row into the table: "diet_summaries" */
  insert_diet_summaries_one?: Maybe<IDietSummaries>;
  /** insert data into the table: "errors" */
  insert_errors?: Maybe<IErrorsMutationResponse>;
  /** insert a single row into the table: "errors" */
  insert_errors_one?: Maybe<IErrors>;
  /** insert data into the table: "events" */
  insert_events?: Maybe<IEventsMutationResponse>;
  /** insert a single row into the table: "events" */
  insert_events_one?: Maybe<IEvents>;
  /** insert data into the table: "exercise_day_progressions" */
  insert_exercise_day_progressions?: Maybe<IExerciseDayProgressionsMutationResponse>;
  /** insert a single row into the table: "exercise_day_progressions" */
  insert_exercise_day_progressions_one?: Maybe<IExerciseDayProgressions>;
  /** insert data into the table: "exercise_days" */
  insert_exercise_days?: Maybe<IExerciseDaysMutationResponse>;
  /** insert a single row into the table: "exercise_days" */
  insert_exercise_days_one?: Maybe<IExerciseDays>;
  /** insert data into the table: "exercise_device_categories" */
  insert_exercise_device_categories?: Maybe<IExerciseDeviceCategoriesMutationResponse>;
  /** insert a single row into the table: "exercise_device_categories" */
  insert_exercise_device_categories_one?: Maybe<IExerciseDeviceCategories>;
  /** insert data into the table: "exercise_groups" */
  insert_exercise_groups?: Maybe<IExerciseGroupsMutationResponse>;
  /** insert a single row into the table: "exercise_groups" */
  insert_exercise_groups_one?: Maybe<IExerciseGroups>;
  /** insert data into the table: "exercise_muscle_categories" */
  insert_exercise_muscle_categories?: Maybe<IExerciseMuscleCategoriesMutationResponse>;
  /** insert data into the table: "exercise_muscle_categories_enum" */
  insert_exercise_muscle_categories_enum?: Maybe<IExerciseMuscleCategoriesEnumMutationResponse>;
  /** insert a single row into the table: "exercise_muscle_categories_enum" */
  insert_exercise_muscle_categories_enum_one?: Maybe<IExerciseMuscleCategoriesEnum>;
  /** insert a single row into the table: "exercise_muscle_categories" */
  insert_exercise_muscle_categories_one?: Maybe<IExerciseMuscleCategories>;
  /** insert data into the table: "exercise_progressions" */
  insert_exercise_progressions?: Maybe<IExerciseProgressionsMutationResponse>;
  /** insert a single row into the table: "exercise_progressions" */
  insert_exercise_progressions_one?: Maybe<IExerciseProgressions>;
  /** insert data into the table: "exercise_variants" */
  insert_exercise_variants?: Maybe<IExerciseVariantsMutationResponse>;
  /** insert a single row into the table: "exercise_variants" */
  insert_exercise_variants_one?: Maybe<IExerciseVariants>;
  /** insert data into the table: "exercises" */
  insert_exercises?: Maybe<IExercisesMutationResponse>;
  /** insert a single row into the table: "exercises" */
  insert_exercises_one?: Maybe<IExercises>;
  /** insert data into the table: "feature_flags" */
  insert_feature_flags?: Maybe<IFeatureFlagsMutationResponse>;
  /** insert a single row into the table: "feature_flags" */
  insert_feature_flags_one?: Maybe<IFeatureFlags>;
  /** insert data into the table: "inbox" */
  insert_inbox?: Maybe<IInboxMutationResponse>;
  /** insert data into the table: "inbox_messages" */
  insert_inbox_messages?: Maybe<IInboxMessagesMutationResponse>;
  /** insert a single row into the table: "inbox_messages" */
  insert_inbox_messages_one?: Maybe<IInboxMessages>;
  /** insert a single row into the table: "inbox" */
  insert_inbox_one?: Maybe<IInbox>;
  /** insert data into the table: "knowledge_base" */
  insert_knowledge_base?: Maybe<IKnowledgeBaseMutationResponse>;
  /** insert a single row into the table: "knowledge_base" */
  insert_knowledge_base_one?: Maybe<IKnowledgeBase>;
  /** insert data into the table: "knowledge_base_video_progresses" */
  insert_knowledge_base_video_progresses?: Maybe<IKnowledgeBaseVideoProgressesMutationResponse>;
  /** insert a single row into the table: "knowledge_base_video_progresses" */
  insert_knowledge_base_video_progresses_one?: Maybe<IKnowledgeBaseVideoProgresses>;
  /** insert data into the table: "knowledge_base_videos" */
  insert_knowledge_base_videos?: Maybe<IKnowledgeBaseVideosMutationResponse>;
  /** insert a single row into the table: "knowledge_base_videos" */
  insert_knowledge_base_videos_one?: Maybe<IKnowledgeBaseVideos>;
  /** insert data into the table: "messages" */
  insert_messages?: Maybe<IMessagesMutationResponse>;
  /** insert a single row into the table: "messages" */
  insert_messages_one?: Maybe<IMessages>;
  /** insert data into the table: "nutrition_meal_products" */
  insert_nutrition_meal_products?: Maybe<INutritionMealProductsMutationResponse>;
  /** insert a single row into the table: "nutrition_meal_products" */
  insert_nutrition_meal_products_one?: Maybe<INutritionMealProducts>;
  /** insert data into the table: "nutrition_meals" */
  insert_nutrition_meals?: Maybe<INutritionMealsMutationResponse>;
  /** insert a single row into the table: "nutrition_meals" */
  insert_nutrition_meals_one?: Maybe<INutritionMeals>;
  /** insert data into the table: "nutrition_plan_meals" */
  insert_nutrition_plan_meals?: Maybe<INutritionPlanMealsMutationResponse>;
  /** insert a single row into the table: "nutrition_plan_meals" */
  insert_nutrition_plan_meals_one?: Maybe<INutritionPlanMeals>;
  /** insert data into the table: "nutrition_plans" */
  insert_nutrition_plans?: Maybe<INutritionPlansMutationResponse>;
  /** insert a single row into the table: "nutrition_plans" */
  insert_nutrition_plans_one?: Maybe<INutritionPlans>;
  /** insert data into the table: "product_categories" */
  insert_product_categories?: Maybe<IProductCategoriesMutationResponse>;
  /** insert a single row into the table: "product_categories" */
  insert_product_categories_one?: Maybe<IProductCategories>;
  /** insert data into the table: "products_rg" */
  insert_products_rg?: Maybe<IProductsRgMutationResponse>;
  /** insert a single row into the table: "products_rg" */
  insert_products_rg_one?: Maybe<IProductsRg>;
  /** insert data into the table: "products_rg_review" */
  insert_products_rg_review?: Maybe<IProductsRgReviewMutationResponse>;
  /** insert a single row into the table: "products_rg_review" */
  insert_products_rg_review_one?: Maybe<IProductsRgReview>;
  /** insert data into the table: "program_event_roles" */
  insert_program_event_roles?: Maybe<IProgramEventRolesMutationResponse>;
  /** insert a single row into the table: "program_event_roles" */
  insert_program_event_roles_one?: Maybe<IProgramEventRoles>;
  /** insert data into the table: "program_events" */
  insert_program_events?: Maybe<IProgramEventsMutationResponse>;
  /** insert a single row into the table: "program_events" */
  insert_program_events_one?: Maybe<IProgramEvents>;
  /** insert data into the table: "program_goals" */
  insert_program_goals?: Maybe<IProgramGoalsMutationResponse>;
  /** insert a single row into the table: "program_goals" */
  insert_program_goals_one?: Maybe<IProgramGoals>;
  /** insert data into the table: "program_messages" */
  insert_program_messages?: Maybe<IProgramMessagesMutationResponse>;
  /** insert a single row into the table: "program_messages" */
  insert_program_messages_one?: Maybe<IProgramMessages>;
  /** insert data into the table: "program_plan_employees" */
  insert_program_plan_employees?: Maybe<IProgramPlanEmployeesMutationResponse>;
  /** insert a single row into the table: "program_plan_employees" */
  insert_program_plan_employees_one?: Maybe<IProgramPlanEmployees>;
  /** insert data into the table: "program_plan_participators" */
  insert_program_plan_participators?: Maybe<IProgramPlanParticipatorsMutationResponse>;
  /** insert a single row into the table: "program_plan_participators" */
  insert_program_plan_participators_one?: Maybe<IProgramPlanParticipators>;
  /** insert data into the table: "program_plans" */
  insert_program_plans?: Maybe<IProgramPlansMutationResponse>;
  /** insert a single row into the table: "program_plans" */
  insert_program_plans_one?: Maybe<IProgramPlans>;
  /** insert data into the table: "programs" */
  insert_programs?: Maybe<IProgramsMutationResponse>;
  /** insert a single row into the table: "programs" */
  insert_programs_one?: Maybe<IPrograms>;
  /** insert data into the table: "progress_days" */
  insert_progress_days?: Maybe<IProgressDaysMutationResponse>;
  /** insert a single row into the table: "progress_days" */
  insert_progress_days_one?: Maybe<IProgressDays>;
  /** insert data into the table: "progress_weeks" */
  insert_progress_weeks?: Maybe<IProgressWeeksMutationResponse>;
  /** insert a single row into the table: "progress_weeks" */
  insert_progress_weeks_one?: Maybe<IProgressWeeks>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<IRolesMutationResponse>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<IRoles>;
  /** insert data into the table: "roles_users" */
  insert_roles_users?: Maybe<IRolesUsersMutationResponse>;
  /** insert a single row into the table: "roles_users" */
  insert_roles_users_one?: Maybe<IRolesUsers>;
  /** insert data into the table: "user_profiles" */
  insert_user_profiles?: Maybe<IUserProfilesMutationResponse>;
  /** insert a single row into the table: "user_profiles" */
  insert_user_profiles_one?: Maybe<IUserProfiles>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<IUsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<IUsers>;
  /** insert data into the table: "water_progress_days" */
  insert_water_progress_days?: Maybe<IWaterProgressDaysMutationResponse>;
  /** insert a single row into the table: "water_progress_days" */
  insert_water_progress_days_one?: Maybe<IWaterProgressDays>;
  /** insert data into the table: "workout_plans" */
  insert_workout_plans?: Maybe<IWorkoutPlansMutationResponse>;
  /** insert a single row into the table: "workout_plans" */
  insert_workout_plans_one?: Maybe<IWorkoutPlans>;
  /** insert data into the table: "workout_templates" */
  insert_workout_templates?: Maybe<IWorkoutTemplatesMutationResponse>;
  /** insert a single row into the table: "workout_templates" */
  insert_workout_templates_one?: Maybe<IWorkoutTemplates>;
  /** update data of the table: "blog_articles" */
  update_blog_articles?: Maybe<IBlogArticlesMutationResponse>;
  /** update single row of the table: "blog_articles" */
  update_blog_articles_by_pk?: Maybe<IBlogArticles>;
  /** update data of the table: "blog_categories" */
  update_blog_categories?: Maybe<IBlogCategoriesMutationResponse>;
  /** update single row of the table: "blog_categories" */
  update_blog_categories_by_pk?: Maybe<IBlogCategories>;
  /** update data of the table: "cookbook_categories" */
  update_cookbook_categories?: Maybe<ICookbookCategoriesMutationResponse>;
  /** update single row of the table: "cookbook_categories" */
  update_cookbook_categories_by_pk?: Maybe<ICookbookCategories>;
  /** update data of the table: "cookbook_recipes" */
  update_cookbook_recipes?: Maybe<ICookbookRecipesMutationResponse>;
  /** update single row of the table: "cookbook_recipes" */
  update_cookbook_recipes_by_pk?: Maybe<ICookbookRecipes>;
  /** update data of the table: "diary_answers" */
  update_diary_answers?: Maybe<IDiaryAnswersMutationResponse>;
  /** update single row of the table: "diary_answers" */
  update_diary_answers_by_pk?: Maybe<IDiaryAnswers>;
  /** update data of the table: "diary_question_categories" */
  update_diary_question_categories?: Maybe<IDiaryQuestionCategoriesMutationResponse>;
  /** update single row of the table: "diary_question_categories" */
  update_diary_question_categories_by_pk?: Maybe<IDiaryQuestionCategories>;
  /** update data of the table: "diary_question_types" */
  update_diary_question_types?: Maybe<IDiaryQuestionTypesMutationResponse>;
  /** update single row of the table: "diary_question_types" */
  update_diary_question_types_by_pk?: Maybe<IDiaryQuestionTypes>;
  /** update data of the table: "diary_questions" */
  update_diary_questions?: Maybe<IDiaryQuestionsMutationResponse>;
  /** update single row of the table: "diary_questions" */
  update_diary_questions_by_pk?: Maybe<IDiaryQuestions>;
  /** update data of the table: "diet_summaries" */
  update_diet_summaries?: Maybe<IDietSummariesMutationResponse>;
  /** update single row of the table: "diet_summaries" */
  update_diet_summaries_by_pk?: Maybe<IDietSummaries>;
  /** update data of the table: "errors" */
  update_errors?: Maybe<IErrorsMutationResponse>;
  /** update single row of the table: "errors" */
  update_errors_by_pk?: Maybe<IErrors>;
  /** update data of the table: "events" */
  update_events?: Maybe<IEventsMutationResponse>;
  /** update single row of the table: "events" */
  update_events_by_pk?: Maybe<IEvents>;
  /** update data of the table: "exercise_day_progressions" */
  update_exercise_day_progressions?: Maybe<IExerciseDayProgressionsMutationResponse>;
  /** update single row of the table: "exercise_day_progressions" */
  update_exercise_day_progressions_by_pk?: Maybe<IExerciseDayProgressions>;
  /** update data of the table: "exercise_days" */
  update_exercise_days?: Maybe<IExerciseDaysMutationResponse>;
  /** update single row of the table: "exercise_days" */
  update_exercise_days_by_pk?: Maybe<IExerciseDays>;
  /** update data of the table: "exercise_device_categories" */
  update_exercise_device_categories?: Maybe<IExerciseDeviceCategoriesMutationResponse>;
  /** update single row of the table: "exercise_device_categories" */
  update_exercise_device_categories_by_pk?: Maybe<IExerciseDeviceCategories>;
  /** update data of the table: "exercise_groups" */
  update_exercise_groups?: Maybe<IExerciseGroupsMutationResponse>;
  /** update single row of the table: "exercise_groups" */
  update_exercise_groups_by_pk?: Maybe<IExerciseGroups>;
  /** update data of the table: "exercise_muscle_categories" */
  update_exercise_muscle_categories?: Maybe<IExerciseMuscleCategoriesMutationResponse>;
  /** update single row of the table: "exercise_muscle_categories" */
  update_exercise_muscle_categories_by_pk?: Maybe<IExerciseMuscleCategories>;
  /** update data of the table: "exercise_muscle_categories_enum" */
  update_exercise_muscle_categories_enum?: Maybe<IExerciseMuscleCategoriesEnumMutationResponse>;
  /** update single row of the table: "exercise_muscle_categories_enum" */
  update_exercise_muscle_categories_enum_by_pk?: Maybe<IExerciseMuscleCategoriesEnum>;
  /** update data of the table: "exercise_progressions" */
  update_exercise_progressions?: Maybe<IExerciseProgressionsMutationResponse>;
  /** update single row of the table: "exercise_progressions" */
  update_exercise_progressions_by_pk?: Maybe<IExerciseProgressions>;
  /** update data of the table: "exercise_variants" */
  update_exercise_variants?: Maybe<IExerciseVariantsMutationResponse>;
  /** update single row of the table: "exercise_variants" */
  update_exercise_variants_by_pk?: Maybe<IExerciseVariants>;
  /** update data of the table: "exercises" */
  update_exercises?: Maybe<IExercisesMutationResponse>;
  /** update single row of the table: "exercises" */
  update_exercises_by_pk?: Maybe<IExercises>;
  /** update data of the table: "feature_flags" */
  update_feature_flags?: Maybe<IFeatureFlagsMutationResponse>;
  /** update single row of the table: "feature_flags" */
  update_feature_flags_by_pk?: Maybe<IFeatureFlags>;
  /** update data of the table: "inbox" */
  update_inbox?: Maybe<IInboxMutationResponse>;
  /** update single row of the table: "inbox" */
  update_inbox_by_pk?: Maybe<IInbox>;
  /** update data of the table: "inbox_messages" */
  update_inbox_messages?: Maybe<IInboxMessagesMutationResponse>;
  /** update single row of the table: "inbox_messages" */
  update_inbox_messages_by_pk?: Maybe<IInboxMessages>;
  /** update data of the table: "knowledge_base" */
  update_knowledge_base?: Maybe<IKnowledgeBaseMutationResponse>;
  /** update single row of the table: "knowledge_base" */
  update_knowledge_base_by_pk?: Maybe<IKnowledgeBase>;
  /** update data of the table: "knowledge_base_video_progresses" */
  update_knowledge_base_video_progresses?: Maybe<IKnowledgeBaseVideoProgressesMutationResponse>;
  /** update single row of the table: "knowledge_base_video_progresses" */
  update_knowledge_base_video_progresses_by_pk?: Maybe<IKnowledgeBaseVideoProgresses>;
  /** update data of the table: "knowledge_base_videos" */
  update_knowledge_base_videos?: Maybe<IKnowledgeBaseVideosMutationResponse>;
  /** update single row of the table: "knowledge_base_videos" */
  update_knowledge_base_videos_by_pk?: Maybe<IKnowledgeBaseVideos>;
  /** update data of the table: "messages" */
  update_messages?: Maybe<IMessagesMutationResponse>;
  /** update single row of the table: "messages" */
  update_messages_by_pk?: Maybe<IMessages>;
  /** update data of the table: "nutrition_meal_products" */
  update_nutrition_meal_products?: Maybe<INutritionMealProductsMutationResponse>;
  /** update single row of the table: "nutrition_meal_products" */
  update_nutrition_meal_products_by_pk?: Maybe<INutritionMealProducts>;
  /** update data of the table: "nutrition_meals" */
  update_nutrition_meals?: Maybe<INutritionMealsMutationResponse>;
  /** update single row of the table: "nutrition_meals" */
  update_nutrition_meals_by_pk?: Maybe<INutritionMeals>;
  /** update data of the table: "nutrition_plan_meals" */
  update_nutrition_plan_meals?: Maybe<INutritionPlanMealsMutationResponse>;
  /** update single row of the table: "nutrition_plan_meals" */
  update_nutrition_plan_meals_by_pk?: Maybe<INutritionPlanMeals>;
  /** update data of the table: "nutrition_plans" */
  update_nutrition_plans?: Maybe<INutritionPlansMutationResponse>;
  /** update single row of the table: "nutrition_plans" */
  update_nutrition_plans_by_pk?: Maybe<INutritionPlans>;
  /** update data of the table: "product_categories" */
  update_product_categories?: Maybe<IProductCategoriesMutationResponse>;
  /** update single row of the table: "product_categories" */
  update_product_categories_by_pk?: Maybe<IProductCategories>;
  /** update data of the table: "products_rg" */
  update_products_rg?: Maybe<IProductsRgMutationResponse>;
  /** update single row of the table: "products_rg" */
  update_products_rg_by_pk?: Maybe<IProductsRg>;
  /** update data of the table: "products_rg_review" */
  update_products_rg_review?: Maybe<IProductsRgReviewMutationResponse>;
  /** update single row of the table: "products_rg_review" */
  update_products_rg_review_by_pk?: Maybe<IProductsRgReview>;
  /** update data of the table: "program_event_roles" */
  update_program_event_roles?: Maybe<IProgramEventRolesMutationResponse>;
  /** update single row of the table: "program_event_roles" */
  update_program_event_roles_by_pk?: Maybe<IProgramEventRoles>;
  /** update data of the table: "program_events" */
  update_program_events?: Maybe<IProgramEventsMutationResponse>;
  /** update single row of the table: "program_events" */
  update_program_events_by_pk?: Maybe<IProgramEvents>;
  /** update data of the table: "program_goals" */
  update_program_goals?: Maybe<IProgramGoalsMutationResponse>;
  /** update single row of the table: "program_goals" */
  update_program_goals_by_pk?: Maybe<IProgramGoals>;
  /** update data of the table: "program_messages" */
  update_program_messages?: Maybe<IProgramMessagesMutationResponse>;
  /** update single row of the table: "program_messages" */
  update_program_messages_by_pk?: Maybe<IProgramMessages>;
  /** update data of the table: "program_plan_employees" */
  update_program_plan_employees?: Maybe<IProgramPlanEmployeesMutationResponse>;
  /** update single row of the table: "program_plan_employees" */
  update_program_plan_employees_by_pk?: Maybe<IProgramPlanEmployees>;
  /** update data of the table: "program_plan_participators" */
  update_program_plan_participators?: Maybe<IProgramPlanParticipatorsMutationResponse>;
  /** update single row of the table: "program_plan_participators" */
  update_program_plan_participators_by_pk?: Maybe<IProgramPlanParticipators>;
  /** update data of the table: "program_plans" */
  update_program_plans?: Maybe<IProgramPlansMutationResponse>;
  /** update single row of the table: "program_plans" */
  update_program_plans_by_pk?: Maybe<IProgramPlans>;
  /** update data of the table: "programs" */
  update_programs?: Maybe<IProgramsMutationResponse>;
  /** update single row of the table: "programs" */
  update_programs_by_pk?: Maybe<IPrograms>;
  /** update data of the table: "progress_days" */
  update_progress_days?: Maybe<IProgressDaysMutationResponse>;
  /** update single row of the table: "progress_days" */
  update_progress_days_by_pk?: Maybe<IProgressDays>;
  /** update data of the table: "progress_weeks" */
  update_progress_weeks?: Maybe<IProgressWeeksMutationResponse>;
  /** update single row of the table: "progress_weeks" */
  update_progress_weeks_by_pk?: Maybe<IProgressWeeks>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<IRolesMutationResponse>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<IRoles>;
  /** update data of the table: "roles_users" */
  update_roles_users?: Maybe<IRolesUsersMutationResponse>;
  /** update single row of the table: "roles_users" */
  update_roles_users_by_pk?: Maybe<IRolesUsers>;
  /** update data of the table: "user_profiles" */
  update_user_profiles?: Maybe<IUserProfilesMutationResponse>;
  /** update single row of the table: "user_profiles" */
  update_user_profiles_by_pk?: Maybe<IUserProfiles>;
  /** update data of the table: "users" */
  update_users?: Maybe<IUsersMutationResponse>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<IUsers>;
  /** update data of the table: "water_progress_days" */
  update_water_progress_days?: Maybe<IWaterProgressDaysMutationResponse>;
  /** update single row of the table: "water_progress_days" */
  update_water_progress_days_by_pk?: Maybe<IWaterProgressDays>;
  /** update data of the table: "workout_plans" */
  update_workout_plans?: Maybe<IWorkoutPlansMutationResponse>;
  /** update single row of the table: "workout_plans" */
  update_workout_plans_by_pk?: Maybe<IWorkoutPlans>;
  /** update data of the table: "workout_templates" */
  update_workout_templates?: Maybe<IWorkoutTemplatesMutationResponse>;
  /** update single row of the table: "workout_templates" */
  update_workout_templates_by_pk?: Maybe<IWorkoutTemplates>;
}


/** mutation root */
export interface IMutationRootDeleteBlogArticlesArgs {
  where: IBlogArticlesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteBlogArticlesByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteBlogCategoriesArgs {
  where: IBlogCategoriesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteBlogCategoriesByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteCookbookCategoriesArgs {
  where: ICookbookCategoriesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteCookbookCategoriesByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteCookbookRecipesArgs {
  where: ICookbookRecipesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteCookbookRecipesByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteDiaryAnswersArgs {
  where: IDiaryAnswersBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteDiaryAnswersByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteDiaryQuestionCategoriesArgs {
  where: IDiaryQuestionCategoriesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteDiaryQuestionCategoriesByPkArgs {
  type: Scalars['String'];
}


/** mutation root */
export interface IMutationRootDeleteDiaryQuestionTypesArgs {
  where: IDiaryQuestionTypesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteDiaryQuestionTypesByPkArgs {
  type: Scalars['String'];
}


/** mutation root */
export interface IMutationRootDeleteDiaryQuestionsArgs {
  where: IDiaryQuestionsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteDiaryQuestionsByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteDietSummariesArgs {
  where: IDietSummariesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteDietSummariesByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteErrorsArgs {
  where: IErrorsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteErrorsByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteEventsArgs {
  where: IEventsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteEventsByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteExerciseDayProgressionsArgs {
  where: IExerciseDayProgressionsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteExerciseDayProgressionsByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteExerciseDaysArgs {
  where: IExerciseDaysBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteExerciseDaysByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteExerciseDeviceCategoriesArgs {
  where: IExerciseDeviceCategoriesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteExerciseDeviceCategoriesByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteExerciseGroupsArgs {
  where: IExerciseGroupsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteExerciseGroupsByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteExerciseMuscleCategoriesArgs {
  where: IExerciseMuscleCategoriesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteExerciseMuscleCategoriesByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteExerciseMuscleCategoriesEnumArgs {
  where: IExerciseMuscleCategoriesEnumBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteExerciseMuscleCategoriesEnumByPkArgs {
  name: Scalars['String'];
}


/** mutation root */
export interface IMutationRootDeleteExerciseProgressionsArgs {
  where: IExerciseProgressionsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteExerciseProgressionsByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteExerciseVariantsArgs {
  where: IExerciseVariantsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteExerciseVariantsByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteExercisesArgs {
  where: IExercisesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteExercisesByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteFeatureFlagsArgs {
  where: IFeatureFlagsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteFeatureFlagsByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteInboxArgs {
  where: IInboxBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteInboxByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteInboxMessagesArgs {
  where: IInboxMessagesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteInboxMessagesByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteKnowledgeBaseArgs {
  where: IKnowledgeBaseBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteKnowledgeBaseByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteKnowledgeBaseVideoProgressesArgs {
  where: IKnowledgeBaseVideoProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteKnowledgeBaseVideoProgressesByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteKnowledgeBaseVideosArgs {
  where: IKnowledgeBaseVideosBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteKnowledgeBaseVideosByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteMessagesArgs {
  where: IMessagesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteMessagesByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteNutritionMealProductsArgs {
  where: INutritionMealProductsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteNutritionMealProductsByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteNutritionMealsArgs {
  where: INutritionMealsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteNutritionMealsByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteNutritionPlanMealsArgs {
  where: INutritionPlanMealsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteNutritionPlanMealsByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteNutritionPlansArgs {
  where: INutritionPlansBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteNutritionPlansByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteProductCategoriesArgs {
  where: IProductCategoriesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProductCategoriesByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteProductsRgArgs {
  where: IProductsRgBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProductsRgByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteProductsRgReviewArgs {
  where: IProductsRgReviewBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProductsRgReviewByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteProgramEventRolesArgs {
  where: IProgramEventRolesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProgramEventRolesByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteProgramEventsArgs {
  where: IProgramEventsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProgramEventsByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteProgramGoalsArgs {
  where: IProgramGoalsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProgramGoalsByPkArgs {
  id: Scalars['uuid'];
}


/** mutation root */
export interface IMutationRootDeleteProgramMessagesArgs {
  where: IProgramMessagesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProgramMessagesByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteProgramPlanEmployeesArgs {
  where: IProgramPlanEmployeesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProgramPlanEmployeesByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteProgramPlanParticipatorsArgs {
  where: IProgramPlanParticipatorsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProgramPlanParticipatorsByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteProgramPlansArgs {
  where: IProgramPlansBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProgramPlansByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteProgramsArgs {
  where: IProgramsBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProgramsByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteProgressDaysArgs {
  where: IProgressDaysBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProgressDaysByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteProgressWeeksArgs {
  where: IProgressWeeksBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteProgressWeeksByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteRolesArgs {
  where: IRolesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteRolesByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteRolesUsersArgs {
  where: IRolesUsersBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteRolesUsersByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteUserProfilesArgs {
  where: IUserProfilesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteUserProfilesByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteUsersArgs {
  where: IUsersBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteUsersByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteWaterProgressDaysArgs {
  where: IWaterProgressDaysBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteWaterProgressDaysByPkArgs {
  id: Scalars['Int'];
}


/** mutation root */
export interface IMutationRootDeleteWorkoutPlansArgs {
  where: IWorkoutPlansBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteWorkoutPlansByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootDeleteWorkoutTemplatesArgs {
  where: IWorkoutTemplatesBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteWorkoutTemplatesByPkArgs {
  id: Scalars['bigint'];
}


/** mutation root */
export interface IMutationRootInsertBlogArticlesArgs {
  objects: Array<IBlogArticlesInsertInput>;
  on_conflict?: Maybe<IBlogArticlesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertBlogArticlesOneArgs {
  object: IBlogArticlesInsertInput;
  on_conflict?: Maybe<IBlogArticlesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertBlogCategoriesArgs {
  objects: Array<IBlogCategoriesInsertInput>;
  on_conflict?: Maybe<IBlogCategoriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertBlogCategoriesOneArgs {
  object: IBlogCategoriesInsertInput;
  on_conflict?: Maybe<IBlogCategoriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertCookbookCategoriesArgs {
  objects: Array<ICookbookCategoriesInsertInput>;
  on_conflict?: Maybe<ICookbookCategoriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertCookbookCategoriesOneArgs {
  object: ICookbookCategoriesInsertInput;
  on_conflict?: Maybe<ICookbookCategoriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertCookbookRecipesArgs {
  objects: Array<ICookbookRecipesInsertInput>;
  on_conflict?: Maybe<ICookbookRecipesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertCookbookRecipesOneArgs {
  object: ICookbookRecipesInsertInput;
  on_conflict?: Maybe<ICookbookRecipesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertDiaryAnswersArgs {
  objects: Array<IDiaryAnswersInsertInput>;
  on_conflict?: Maybe<IDiaryAnswersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertDiaryAnswersOneArgs {
  object: IDiaryAnswersInsertInput;
  on_conflict?: Maybe<IDiaryAnswersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertDiaryQuestionCategoriesArgs {
  objects: Array<IDiaryQuestionCategoriesInsertInput>;
  on_conflict?: Maybe<IDiaryQuestionCategoriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertDiaryQuestionCategoriesOneArgs {
  object: IDiaryQuestionCategoriesInsertInput;
  on_conflict?: Maybe<IDiaryQuestionCategoriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertDiaryQuestionTypesArgs {
  objects: Array<IDiaryQuestionTypesInsertInput>;
  on_conflict?: Maybe<IDiaryQuestionTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertDiaryQuestionTypesOneArgs {
  object: IDiaryQuestionTypesInsertInput;
  on_conflict?: Maybe<IDiaryQuestionTypesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertDiaryQuestionsArgs {
  objects: Array<IDiaryQuestionsInsertInput>;
  on_conflict?: Maybe<IDiaryQuestionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertDiaryQuestionsOneArgs {
  object: IDiaryQuestionsInsertInput;
  on_conflict?: Maybe<IDiaryQuestionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertDietSummariesArgs {
  objects: Array<IDietSummariesInsertInput>;
  on_conflict?: Maybe<IDietSummariesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertDietSummariesOneArgs {
  object: IDietSummariesInsertInput;
  on_conflict?: Maybe<IDietSummariesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertErrorsArgs {
  objects: Array<IErrorsInsertInput>;
  on_conflict?: Maybe<IErrorsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertErrorsOneArgs {
  object: IErrorsInsertInput;
  on_conflict?: Maybe<IErrorsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertEventsArgs {
  objects: Array<IEventsInsertInput>;
  on_conflict?: Maybe<IEventsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertEventsOneArgs {
  object: IEventsInsertInput;
  on_conflict?: Maybe<IEventsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseDayProgressionsArgs {
  objects: Array<IExerciseDayProgressionsInsertInput>;
  on_conflict?: Maybe<IExerciseDayProgressionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseDayProgressionsOneArgs {
  object: IExerciseDayProgressionsInsertInput;
  on_conflict?: Maybe<IExerciseDayProgressionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseDaysArgs {
  objects: Array<IExerciseDaysInsertInput>;
  on_conflict?: Maybe<IExerciseDaysOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseDaysOneArgs {
  object: IExerciseDaysInsertInput;
  on_conflict?: Maybe<IExerciseDaysOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseDeviceCategoriesArgs {
  objects: Array<IExerciseDeviceCategoriesInsertInput>;
  on_conflict?: Maybe<IExerciseDeviceCategoriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseDeviceCategoriesOneArgs {
  object: IExerciseDeviceCategoriesInsertInput;
  on_conflict?: Maybe<IExerciseDeviceCategoriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseGroupsArgs {
  objects: Array<IExerciseGroupsInsertInput>;
  on_conflict?: Maybe<IExerciseGroupsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseGroupsOneArgs {
  object: IExerciseGroupsInsertInput;
  on_conflict?: Maybe<IExerciseGroupsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseMuscleCategoriesArgs {
  objects: Array<IExerciseMuscleCategoriesInsertInput>;
  on_conflict?: Maybe<IExerciseMuscleCategoriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseMuscleCategoriesEnumArgs {
  objects: Array<IExerciseMuscleCategoriesEnumInsertInput>;
  on_conflict?: Maybe<IExerciseMuscleCategoriesEnumOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseMuscleCategoriesEnumOneArgs {
  object: IExerciseMuscleCategoriesEnumInsertInput;
  on_conflict?: Maybe<IExerciseMuscleCategoriesEnumOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseMuscleCategoriesOneArgs {
  object: IExerciseMuscleCategoriesInsertInput;
  on_conflict?: Maybe<IExerciseMuscleCategoriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseProgressionsArgs {
  objects: Array<IExerciseProgressionsInsertInput>;
  on_conflict?: Maybe<IExerciseProgressionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseProgressionsOneArgs {
  object: IExerciseProgressionsInsertInput;
  on_conflict?: Maybe<IExerciseProgressionsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseVariantsArgs {
  objects: Array<IExerciseVariantsInsertInput>;
  on_conflict?: Maybe<IExerciseVariantsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExerciseVariantsOneArgs {
  object: IExerciseVariantsInsertInput;
  on_conflict?: Maybe<IExerciseVariantsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExercisesArgs {
  objects: Array<IExercisesInsertInput>;
  on_conflict?: Maybe<IExercisesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertExercisesOneArgs {
  object: IExercisesInsertInput;
  on_conflict?: Maybe<IExercisesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertFeatureFlagsArgs {
  objects: Array<IFeatureFlagsInsertInput>;
  on_conflict?: Maybe<IFeatureFlagsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertFeatureFlagsOneArgs {
  object: IFeatureFlagsInsertInput;
  on_conflict?: Maybe<IFeatureFlagsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertInboxArgs {
  objects: Array<IInboxInsertInput>;
  on_conflict?: Maybe<IInboxOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertInboxMessagesArgs {
  objects: Array<IInboxMessagesInsertInput>;
  on_conflict?: Maybe<IInboxMessagesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertInboxMessagesOneArgs {
  object: IInboxMessagesInsertInput;
  on_conflict?: Maybe<IInboxMessagesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertInboxOneArgs {
  object: IInboxInsertInput;
  on_conflict?: Maybe<IInboxOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertKnowledgeBaseArgs {
  objects: Array<IKnowledgeBaseInsertInput>;
  on_conflict?: Maybe<IKnowledgeBaseOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertKnowledgeBaseOneArgs {
  object: IKnowledgeBaseInsertInput;
  on_conflict?: Maybe<IKnowledgeBaseOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertKnowledgeBaseVideoProgressesArgs {
  objects: Array<IKnowledgeBaseVideoProgressesInsertInput>;
  on_conflict?: Maybe<IKnowledgeBaseVideoProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertKnowledgeBaseVideoProgressesOneArgs {
  object: IKnowledgeBaseVideoProgressesInsertInput;
  on_conflict?: Maybe<IKnowledgeBaseVideoProgressesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertKnowledgeBaseVideosArgs {
  objects: Array<IKnowledgeBaseVideosInsertInput>;
  on_conflict?: Maybe<IKnowledgeBaseVideosOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertKnowledgeBaseVideosOneArgs {
  object: IKnowledgeBaseVideosInsertInput;
  on_conflict?: Maybe<IKnowledgeBaseVideosOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertMessagesArgs {
  objects: Array<IMessagesInsertInput>;
  on_conflict?: Maybe<IMessagesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertMessagesOneArgs {
  object: IMessagesInsertInput;
  on_conflict?: Maybe<IMessagesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNutritionMealProductsArgs {
  objects: Array<INutritionMealProductsInsertInput>;
  on_conflict?: Maybe<INutritionMealProductsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNutritionMealProductsOneArgs {
  object: INutritionMealProductsInsertInput;
  on_conflict?: Maybe<INutritionMealProductsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNutritionMealsArgs {
  objects: Array<INutritionMealsInsertInput>;
  on_conflict?: Maybe<INutritionMealsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNutritionMealsOneArgs {
  object: INutritionMealsInsertInput;
  on_conflict?: Maybe<INutritionMealsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNutritionPlanMealsArgs {
  objects: Array<INutritionPlanMealsInsertInput>;
  on_conflict?: Maybe<INutritionPlanMealsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNutritionPlanMealsOneArgs {
  object: INutritionPlanMealsInsertInput;
  on_conflict?: Maybe<INutritionPlanMealsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNutritionPlansArgs {
  objects: Array<INutritionPlansInsertInput>;
  on_conflict?: Maybe<INutritionPlansOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertNutritionPlansOneArgs {
  object: INutritionPlansInsertInput;
  on_conflict?: Maybe<INutritionPlansOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProductCategoriesArgs {
  objects: Array<IProductCategoriesInsertInput>;
  on_conflict?: Maybe<IProductCategoriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProductCategoriesOneArgs {
  object: IProductCategoriesInsertInput;
  on_conflict?: Maybe<IProductCategoriesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProductsRgArgs {
  objects: Array<IProductsRgInsertInput>;
  on_conflict?: Maybe<IProductsRgOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProductsRgOneArgs {
  object: IProductsRgInsertInput;
  on_conflict?: Maybe<IProductsRgOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProductsRgReviewArgs {
  objects: Array<IProductsRgReviewInsertInput>;
  on_conflict?: Maybe<IProductsRgReviewOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProductsRgReviewOneArgs {
  object: IProductsRgReviewInsertInput;
  on_conflict?: Maybe<IProductsRgReviewOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramEventRolesArgs {
  objects: Array<IProgramEventRolesInsertInput>;
  on_conflict?: Maybe<IProgramEventRolesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramEventRolesOneArgs {
  object: IProgramEventRolesInsertInput;
  on_conflict?: Maybe<IProgramEventRolesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramEventsArgs {
  objects: Array<IProgramEventsInsertInput>;
  on_conflict?: Maybe<IProgramEventsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramEventsOneArgs {
  object: IProgramEventsInsertInput;
  on_conflict?: Maybe<IProgramEventsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramGoalsArgs {
  objects: Array<IProgramGoalsInsertInput>;
  on_conflict?: Maybe<IProgramGoalsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramGoalsOneArgs {
  object: IProgramGoalsInsertInput;
  on_conflict?: Maybe<IProgramGoalsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramMessagesArgs {
  objects: Array<IProgramMessagesInsertInput>;
  on_conflict?: Maybe<IProgramMessagesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramMessagesOneArgs {
  object: IProgramMessagesInsertInput;
  on_conflict?: Maybe<IProgramMessagesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramPlanEmployeesArgs {
  objects: Array<IProgramPlanEmployeesInsertInput>;
  on_conflict?: Maybe<IProgramPlanEmployeesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramPlanEmployeesOneArgs {
  object: IProgramPlanEmployeesInsertInput;
  on_conflict?: Maybe<IProgramPlanEmployeesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramPlanParticipatorsArgs {
  objects: Array<IProgramPlanParticipatorsInsertInput>;
  on_conflict?: Maybe<IProgramPlanParticipatorsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramPlanParticipatorsOneArgs {
  object: IProgramPlanParticipatorsInsertInput;
  on_conflict?: Maybe<IProgramPlanParticipatorsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramPlansArgs {
  objects: Array<IProgramPlansInsertInput>;
  on_conflict?: Maybe<IProgramPlansOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramPlansOneArgs {
  object: IProgramPlansInsertInput;
  on_conflict?: Maybe<IProgramPlansOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramsArgs {
  objects: Array<IProgramsInsertInput>;
  on_conflict?: Maybe<IProgramsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgramsOneArgs {
  object: IProgramsInsertInput;
  on_conflict?: Maybe<IProgramsOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgressDaysArgs {
  objects: Array<IProgressDaysInsertInput>;
  on_conflict?: Maybe<IProgressDaysOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgressDaysOneArgs {
  object: IProgressDaysInsertInput;
  on_conflict?: Maybe<IProgressDaysOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgressWeeksArgs {
  objects: Array<IProgressWeeksInsertInput>;
  on_conflict?: Maybe<IProgressWeeksOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertProgressWeeksOneArgs {
  object: IProgressWeeksInsertInput;
  on_conflict?: Maybe<IProgressWeeksOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertRolesArgs {
  objects: Array<IRolesInsertInput>;
  on_conflict?: Maybe<IRolesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertRolesOneArgs {
  object: IRolesInsertInput;
  on_conflict?: Maybe<IRolesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertRolesUsersArgs {
  objects: Array<IRolesUsersInsertInput>;
  on_conflict?: Maybe<IRolesUsersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertRolesUsersOneArgs {
  object: IRolesUsersInsertInput;
  on_conflict?: Maybe<IRolesUsersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUserProfilesArgs {
  objects: Array<IUserProfilesInsertInput>;
  on_conflict?: Maybe<IUserProfilesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUserProfilesOneArgs {
  object: IUserProfilesInsertInput;
  on_conflict?: Maybe<IUserProfilesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUsersArgs {
  objects: Array<IUsersInsertInput>;
  on_conflict?: Maybe<IUsersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertUsersOneArgs {
  object: IUsersInsertInput;
  on_conflict?: Maybe<IUsersOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWaterProgressDaysArgs {
  objects: Array<IWaterProgressDaysInsertInput>;
  on_conflict?: Maybe<IWaterProgressDaysOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWaterProgressDaysOneArgs {
  object: IWaterProgressDaysInsertInput;
  on_conflict?: Maybe<IWaterProgressDaysOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWorkoutPlansArgs {
  objects: Array<IWorkoutPlansInsertInput>;
  on_conflict?: Maybe<IWorkoutPlansOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWorkoutPlansOneArgs {
  object: IWorkoutPlansInsertInput;
  on_conflict?: Maybe<IWorkoutPlansOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWorkoutTemplatesArgs {
  objects: Array<IWorkoutTemplatesInsertInput>;
  on_conflict?: Maybe<IWorkoutTemplatesOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertWorkoutTemplatesOneArgs {
  object: IWorkoutTemplatesInsertInput;
  on_conflict?: Maybe<IWorkoutTemplatesOnConflict>;
}


/** mutation root */
export interface IMutationRootUpdateBlogArticlesArgs {
  _inc?: Maybe<IBlogArticlesIncInput>;
  _set?: Maybe<IBlogArticlesSetInput>;
  where: IBlogArticlesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateBlogArticlesByPkArgs {
  _inc?: Maybe<IBlogArticlesIncInput>;
  _set?: Maybe<IBlogArticlesSetInput>;
  pk_columns: IBlogArticlesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateBlogCategoriesArgs {
  _inc?: Maybe<IBlogCategoriesIncInput>;
  _set?: Maybe<IBlogCategoriesSetInput>;
  where: IBlogCategoriesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateBlogCategoriesByPkArgs {
  _inc?: Maybe<IBlogCategoriesIncInput>;
  _set?: Maybe<IBlogCategoriesSetInput>;
  pk_columns: IBlogCategoriesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateCookbookCategoriesArgs {
  _inc?: Maybe<ICookbookCategoriesIncInput>;
  _set?: Maybe<ICookbookCategoriesSetInput>;
  where: ICookbookCategoriesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateCookbookCategoriesByPkArgs {
  _inc?: Maybe<ICookbookCategoriesIncInput>;
  _set?: Maybe<ICookbookCategoriesSetInput>;
  pk_columns: ICookbookCategoriesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateCookbookRecipesArgs {
  _inc?: Maybe<ICookbookRecipesIncInput>;
  _set?: Maybe<ICookbookRecipesSetInput>;
  where: ICookbookRecipesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateCookbookRecipesByPkArgs {
  _inc?: Maybe<ICookbookRecipesIncInput>;
  _set?: Maybe<ICookbookRecipesSetInput>;
  pk_columns: ICookbookRecipesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateDiaryAnswersArgs {
  _inc?: Maybe<IDiaryAnswersIncInput>;
  _set?: Maybe<IDiaryAnswersSetInput>;
  where: IDiaryAnswersBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateDiaryAnswersByPkArgs {
  _inc?: Maybe<IDiaryAnswersIncInput>;
  _set?: Maybe<IDiaryAnswersSetInput>;
  pk_columns: IDiaryAnswersPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateDiaryQuestionCategoriesArgs {
  _set?: Maybe<IDiaryQuestionCategoriesSetInput>;
  where: IDiaryQuestionCategoriesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateDiaryQuestionCategoriesByPkArgs {
  _set?: Maybe<IDiaryQuestionCategoriesSetInput>;
  pk_columns: IDiaryQuestionCategoriesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateDiaryQuestionTypesArgs {
  _set?: Maybe<IDiaryQuestionTypesSetInput>;
  where: IDiaryQuestionTypesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateDiaryQuestionTypesByPkArgs {
  _set?: Maybe<IDiaryQuestionTypesSetInput>;
  pk_columns: IDiaryQuestionTypesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateDiaryQuestionsArgs {
  _inc?: Maybe<IDiaryQuestionsIncInput>;
  _set?: Maybe<IDiaryQuestionsSetInput>;
  where: IDiaryQuestionsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateDiaryQuestionsByPkArgs {
  _inc?: Maybe<IDiaryQuestionsIncInput>;
  _set?: Maybe<IDiaryQuestionsSetInput>;
  pk_columns: IDiaryQuestionsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateDietSummariesArgs {
  _inc?: Maybe<IDietSummariesIncInput>;
  _set?: Maybe<IDietSummariesSetInput>;
  where: IDietSummariesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateDietSummariesByPkArgs {
  _inc?: Maybe<IDietSummariesIncInput>;
  _set?: Maybe<IDietSummariesSetInput>;
  pk_columns: IDietSummariesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateErrorsArgs {
  _inc?: Maybe<IErrorsIncInput>;
  _set?: Maybe<IErrorsSetInput>;
  where: IErrorsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateErrorsByPkArgs {
  _inc?: Maybe<IErrorsIncInput>;
  _set?: Maybe<IErrorsSetInput>;
  pk_columns: IErrorsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateEventsArgs {
  _inc?: Maybe<IEventsIncInput>;
  _set?: Maybe<IEventsSetInput>;
  where: IEventsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateEventsByPkArgs {
  _inc?: Maybe<IEventsIncInput>;
  _set?: Maybe<IEventsSetInput>;
  pk_columns: IEventsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateExerciseDayProgressionsArgs {
  _inc?: Maybe<IExerciseDayProgressionsIncInput>;
  _set?: Maybe<IExerciseDayProgressionsSetInput>;
  where: IExerciseDayProgressionsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateExerciseDayProgressionsByPkArgs {
  _inc?: Maybe<IExerciseDayProgressionsIncInput>;
  _set?: Maybe<IExerciseDayProgressionsSetInput>;
  pk_columns: IExerciseDayProgressionsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateExerciseDaysArgs {
  _inc?: Maybe<IExerciseDaysIncInput>;
  _set?: Maybe<IExerciseDaysSetInput>;
  where: IExerciseDaysBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateExerciseDaysByPkArgs {
  _inc?: Maybe<IExerciseDaysIncInput>;
  _set?: Maybe<IExerciseDaysSetInput>;
  pk_columns: IExerciseDaysPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateExerciseDeviceCategoriesArgs {
  _inc?: Maybe<IExerciseDeviceCategoriesIncInput>;
  _set?: Maybe<IExerciseDeviceCategoriesSetInput>;
  where: IExerciseDeviceCategoriesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateExerciseDeviceCategoriesByPkArgs {
  _inc?: Maybe<IExerciseDeviceCategoriesIncInput>;
  _set?: Maybe<IExerciseDeviceCategoriesSetInput>;
  pk_columns: IExerciseDeviceCategoriesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateExerciseGroupsArgs {
  _inc?: Maybe<IExerciseGroupsIncInput>;
  _set?: Maybe<IExerciseGroupsSetInput>;
  where: IExerciseGroupsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateExerciseGroupsByPkArgs {
  _inc?: Maybe<IExerciseGroupsIncInput>;
  _set?: Maybe<IExerciseGroupsSetInput>;
  pk_columns: IExerciseGroupsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateExerciseMuscleCategoriesArgs {
  _inc?: Maybe<IExerciseMuscleCategoriesIncInput>;
  _set?: Maybe<IExerciseMuscleCategoriesSetInput>;
  where: IExerciseMuscleCategoriesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateExerciseMuscleCategoriesByPkArgs {
  _inc?: Maybe<IExerciseMuscleCategoriesIncInput>;
  _set?: Maybe<IExerciseMuscleCategoriesSetInput>;
  pk_columns: IExerciseMuscleCategoriesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateExerciseMuscleCategoriesEnumArgs {
  _set?: Maybe<IExerciseMuscleCategoriesEnumSetInput>;
  where: IExerciseMuscleCategoriesEnumBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateExerciseMuscleCategoriesEnumByPkArgs {
  _set?: Maybe<IExerciseMuscleCategoriesEnumSetInput>;
  pk_columns: IExerciseMuscleCategoriesEnumPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateExerciseProgressionsArgs {
  _inc?: Maybe<IExerciseProgressionsIncInput>;
  _set?: Maybe<IExerciseProgressionsSetInput>;
  where: IExerciseProgressionsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateExerciseProgressionsByPkArgs {
  _inc?: Maybe<IExerciseProgressionsIncInput>;
  _set?: Maybe<IExerciseProgressionsSetInput>;
  pk_columns: IExerciseProgressionsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateExerciseVariantsArgs {
  _inc?: Maybe<IExerciseVariantsIncInput>;
  _set?: Maybe<IExerciseVariantsSetInput>;
  where: IExerciseVariantsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateExerciseVariantsByPkArgs {
  _inc?: Maybe<IExerciseVariantsIncInput>;
  _set?: Maybe<IExerciseVariantsSetInput>;
  pk_columns: IExerciseVariantsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateExercisesArgs {
  _inc?: Maybe<IExercisesIncInput>;
  _set?: Maybe<IExercisesSetInput>;
  where: IExercisesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateExercisesByPkArgs {
  _inc?: Maybe<IExercisesIncInput>;
  _set?: Maybe<IExercisesSetInput>;
  pk_columns: IExercisesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateFeatureFlagsArgs {
  _inc?: Maybe<IFeatureFlagsIncInput>;
  _set?: Maybe<IFeatureFlagsSetInput>;
  where: IFeatureFlagsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateFeatureFlagsByPkArgs {
  _inc?: Maybe<IFeatureFlagsIncInput>;
  _set?: Maybe<IFeatureFlagsSetInput>;
  pk_columns: IFeatureFlagsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateInboxArgs {
  _inc?: Maybe<IInboxIncInput>;
  _set?: Maybe<IInboxSetInput>;
  where: IInboxBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateInboxByPkArgs {
  _inc?: Maybe<IInboxIncInput>;
  _set?: Maybe<IInboxSetInput>;
  pk_columns: IInboxPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateInboxMessagesArgs {
  _inc?: Maybe<IInboxMessagesIncInput>;
  _set?: Maybe<IInboxMessagesSetInput>;
  where: IInboxMessagesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateInboxMessagesByPkArgs {
  _inc?: Maybe<IInboxMessagesIncInput>;
  _set?: Maybe<IInboxMessagesSetInput>;
  pk_columns: IInboxMessagesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateKnowledgeBaseArgs {
  _inc?: Maybe<IKnowledgeBaseIncInput>;
  _set?: Maybe<IKnowledgeBaseSetInput>;
  where: IKnowledgeBaseBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateKnowledgeBaseByPkArgs {
  _inc?: Maybe<IKnowledgeBaseIncInput>;
  _set?: Maybe<IKnowledgeBaseSetInput>;
  pk_columns: IKnowledgeBasePkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateKnowledgeBaseVideoProgressesArgs {
  _inc?: Maybe<IKnowledgeBaseVideoProgressesIncInput>;
  _set?: Maybe<IKnowledgeBaseVideoProgressesSetInput>;
  where: IKnowledgeBaseVideoProgressesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateKnowledgeBaseVideoProgressesByPkArgs {
  _inc?: Maybe<IKnowledgeBaseVideoProgressesIncInput>;
  _set?: Maybe<IKnowledgeBaseVideoProgressesSetInput>;
  pk_columns: IKnowledgeBaseVideoProgressesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateKnowledgeBaseVideosArgs {
  _inc?: Maybe<IKnowledgeBaseVideosIncInput>;
  _set?: Maybe<IKnowledgeBaseVideosSetInput>;
  where: IKnowledgeBaseVideosBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateKnowledgeBaseVideosByPkArgs {
  _inc?: Maybe<IKnowledgeBaseVideosIncInput>;
  _set?: Maybe<IKnowledgeBaseVideosSetInput>;
  pk_columns: IKnowledgeBaseVideosPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateMessagesArgs {
  _inc?: Maybe<IMessagesIncInput>;
  _set?: Maybe<IMessagesSetInput>;
  where: IMessagesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateMessagesByPkArgs {
  _inc?: Maybe<IMessagesIncInput>;
  _set?: Maybe<IMessagesSetInput>;
  pk_columns: IMessagesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateNutritionMealProductsArgs {
  _inc?: Maybe<INutritionMealProductsIncInput>;
  _set?: Maybe<INutritionMealProductsSetInput>;
  where: INutritionMealProductsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateNutritionMealProductsByPkArgs {
  _inc?: Maybe<INutritionMealProductsIncInput>;
  _set?: Maybe<INutritionMealProductsSetInput>;
  pk_columns: INutritionMealProductsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateNutritionMealsArgs {
  _inc?: Maybe<INutritionMealsIncInput>;
  _set?: Maybe<INutritionMealsSetInput>;
  where: INutritionMealsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateNutritionMealsByPkArgs {
  _inc?: Maybe<INutritionMealsIncInput>;
  _set?: Maybe<INutritionMealsSetInput>;
  pk_columns: INutritionMealsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateNutritionPlanMealsArgs {
  _inc?: Maybe<INutritionPlanMealsIncInput>;
  _set?: Maybe<INutritionPlanMealsSetInput>;
  where: INutritionPlanMealsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateNutritionPlanMealsByPkArgs {
  _inc?: Maybe<INutritionPlanMealsIncInput>;
  _set?: Maybe<INutritionPlanMealsSetInput>;
  pk_columns: INutritionPlanMealsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateNutritionPlansArgs {
  _inc?: Maybe<INutritionPlansIncInput>;
  _set?: Maybe<INutritionPlansSetInput>;
  where: INutritionPlansBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateNutritionPlansByPkArgs {
  _inc?: Maybe<INutritionPlansIncInput>;
  _set?: Maybe<INutritionPlansSetInput>;
  pk_columns: INutritionPlansPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProductCategoriesArgs {
  _inc?: Maybe<IProductCategoriesIncInput>;
  _set?: Maybe<IProductCategoriesSetInput>;
  where: IProductCategoriesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProductCategoriesByPkArgs {
  _inc?: Maybe<IProductCategoriesIncInput>;
  _set?: Maybe<IProductCategoriesSetInput>;
  pk_columns: IProductCategoriesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProductsRgArgs {
  _inc?: Maybe<IProductsRgIncInput>;
  _set?: Maybe<IProductsRgSetInput>;
  where: IProductsRgBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProductsRgByPkArgs {
  _inc?: Maybe<IProductsRgIncInput>;
  _set?: Maybe<IProductsRgSetInput>;
  pk_columns: IProductsRgPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProductsRgReviewArgs {
  _inc?: Maybe<IProductsRgReviewIncInput>;
  _set?: Maybe<IProductsRgReviewSetInput>;
  where: IProductsRgReviewBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProductsRgReviewByPkArgs {
  _inc?: Maybe<IProductsRgReviewIncInput>;
  _set?: Maybe<IProductsRgReviewSetInput>;
  pk_columns: IProductsRgReviewPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProgramEventRolesArgs {
  _inc?: Maybe<IProgramEventRolesIncInput>;
  _set?: Maybe<IProgramEventRolesSetInput>;
  where: IProgramEventRolesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProgramEventRolesByPkArgs {
  _inc?: Maybe<IProgramEventRolesIncInput>;
  _set?: Maybe<IProgramEventRolesSetInput>;
  pk_columns: IProgramEventRolesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProgramEventsArgs {
  _inc?: Maybe<IProgramEventsIncInput>;
  _set?: Maybe<IProgramEventsSetInput>;
  where: IProgramEventsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProgramEventsByPkArgs {
  _inc?: Maybe<IProgramEventsIncInput>;
  _set?: Maybe<IProgramEventsSetInput>;
  pk_columns: IProgramEventsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProgramGoalsArgs {
  _inc?: Maybe<IProgramGoalsIncInput>;
  _set?: Maybe<IProgramGoalsSetInput>;
  where: IProgramGoalsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProgramGoalsByPkArgs {
  _inc?: Maybe<IProgramGoalsIncInput>;
  _set?: Maybe<IProgramGoalsSetInput>;
  pk_columns: IProgramGoalsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProgramMessagesArgs {
  _inc?: Maybe<IProgramMessagesIncInput>;
  _set?: Maybe<IProgramMessagesSetInput>;
  where: IProgramMessagesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProgramMessagesByPkArgs {
  _inc?: Maybe<IProgramMessagesIncInput>;
  _set?: Maybe<IProgramMessagesSetInput>;
  pk_columns: IProgramMessagesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProgramPlanEmployeesArgs {
  _inc?: Maybe<IProgramPlanEmployeesIncInput>;
  _set?: Maybe<IProgramPlanEmployeesSetInput>;
  where: IProgramPlanEmployeesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProgramPlanEmployeesByPkArgs {
  _inc?: Maybe<IProgramPlanEmployeesIncInput>;
  _set?: Maybe<IProgramPlanEmployeesSetInput>;
  pk_columns: IProgramPlanEmployeesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProgramPlanParticipatorsArgs {
  _inc?: Maybe<IProgramPlanParticipatorsIncInput>;
  _set?: Maybe<IProgramPlanParticipatorsSetInput>;
  where: IProgramPlanParticipatorsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProgramPlanParticipatorsByPkArgs {
  _inc?: Maybe<IProgramPlanParticipatorsIncInput>;
  _set?: Maybe<IProgramPlanParticipatorsSetInput>;
  pk_columns: IProgramPlanParticipatorsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProgramPlansArgs {
  _inc?: Maybe<IProgramPlansIncInput>;
  _set?: Maybe<IProgramPlansSetInput>;
  where: IProgramPlansBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProgramPlansByPkArgs {
  _inc?: Maybe<IProgramPlansIncInput>;
  _set?: Maybe<IProgramPlansSetInput>;
  pk_columns: IProgramPlansPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProgramsArgs {
  _inc?: Maybe<IProgramsIncInput>;
  _set?: Maybe<IProgramsSetInput>;
  where: IProgramsBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProgramsByPkArgs {
  _inc?: Maybe<IProgramsIncInput>;
  _set?: Maybe<IProgramsSetInput>;
  pk_columns: IProgramsPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProgressDaysArgs {
  _inc?: Maybe<IProgressDaysIncInput>;
  _set?: Maybe<IProgressDaysSetInput>;
  where: IProgressDaysBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProgressDaysByPkArgs {
  _inc?: Maybe<IProgressDaysIncInput>;
  _set?: Maybe<IProgressDaysSetInput>;
  pk_columns: IProgressDaysPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateProgressWeeksArgs {
  _inc?: Maybe<IProgressWeeksIncInput>;
  _set?: Maybe<IProgressWeeksSetInput>;
  where: IProgressWeeksBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateProgressWeeksByPkArgs {
  _inc?: Maybe<IProgressWeeksIncInput>;
  _set?: Maybe<IProgressWeeksSetInput>;
  pk_columns: IProgressWeeksPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateRolesArgs {
  _inc?: Maybe<IRolesIncInput>;
  _set?: Maybe<IRolesSetInput>;
  where: IRolesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateRolesByPkArgs {
  _inc?: Maybe<IRolesIncInput>;
  _set?: Maybe<IRolesSetInput>;
  pk_columns: IRolesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateRolesUsersArgs {
  _inc?: Maybe<IRolesUsersIncInput>;
  _set?: Maybe<IRolesUsersSetInput>;
  where: IRolesUsersBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateRolesUsersByPkArgs {
  _inc?: Maybe<IRolesUsersIncInput>;
  _set?: Maybe<IRolesUsersSetInput>;
  pk_columns: IRolesUsersPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateUserProfilesArgs {
  _inc?: Maybe<IUserProfilesIncInput>;
  _set?: Maybe<IUserProfilesSetInput>;
  where: IUserProfilesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateUserProfilesByPkArgs {
  _inc?: Maybe<IUserProfilesIncInput>;
  _set?: Maybe<IUserProfilesSetInput>;
  pk_columns: IUserProfilesPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateUsersArgs {
  _inc?: Maybe<IUsersIncInput>;
  _set?: Maybe<IUsersSetInput>;
  where: IUsersBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateUsersByPkArgs {
  _inc?: Maybe<IUsersIncInput>;
  _set?: Maybe<IUsersSetInput>;
  pk_columns: IUsersPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateWaterProgressDaysArgs {
  _inc?: Maybe<IWaterProgressDaysIncInput>;
  _set?: Maybe<IWaterProgressDaysSetInput>;
  where: IWaterProgressDaysBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateWaterProgressDaysByPkArgs {
  _inc?: Maybe<IWaterProgressDaysIncInput>;
  _set?: Maybe<IWaterProgressDaysSetInput>;
  pk_columns: IWaterProgressDaysPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateWorkoutPlansArgs {
  _inc?: Maybe<IWorkoutPlansIncInput>;
  _set?: Maybe<IWorkoutPlansSetInput>;
  where: IWorkoutPlansBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateWorkoutPlansByPkArgs {
  _inc?: Maybe<IWorkoutPlansIncInput>;
  _set?: Maybe<IWorkoutPlansSetInput>;
  pk_columns: IWorkoutPlansPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateWorkoutTemplatesArgs {
  _inc?: Maybe<IWorkoutTemplatesIncInput>;
  _set?: Maybe<IWorkoutTemplatesSetInput>;
  where: IWorkoutTemplatesBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateWorkoutTemplatesByPkArgs {
  _inc?: Maybe<IWorkoutTemplatesIncInput>;
  _set?: Maybe<IWorkoutTemplatesSetInput>;
  pk_columns: IWorkoutTemplatesPkColumnsInput;
}


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export interface INumericComparisonExp {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
}

/** columns and relationships of "nutrition_meal_products" */
export interface INutritionMealProducts {
   __typename?: 'nutrition_meal_products';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  nutrition_meal?: Maybe<INutritionMeals>;
  nutrition_meal_id: Scalars['Int'];
  order: Scalars['Int'];
  /** An object relationship */
  product?: Maybe<IProductsRg>;
  product_id: Scalars['Int'];
  quantity: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "nutrition_meal_products" */
export interface INutritionMealProductsAggregate {
   __typename?: 'nutrition_meal_products_aggregate';
  aggregate?: Maybe<INutritionMealProductsAggregateFields>;
  nodes: Array<INutritionMealProducts>;
}

/** aggregate fields of "nutrition_meal_products" */
export interface INutritionMealProductsAggregateFields {
   __typename?: 'nutrition_meal_products_aggregate_fields';
  avg?: Maybe<INutritionMealProductsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<INutritionMealProductsMaxFields>;
  min?: Maybe<INutritionMealProductsMinFields>;
  stddev?: Maybe<INutritionMealProductsStddevFields>;
  stddev_pop?: Maybe<INutritionMealProductsStddevPopFields>;
  stddev_samp?: Maybe<INutritionMealProductsStddevSampFields>;
  sum?: Maybe<INutritionMealProductsSumFields>;
  var_pop?: Maybe<INutritionMealProductsVarPopFields>;
  var_samp?: Maybe<INutritionMealProductsVarSampFields>;
  variance?: Maybe<INutritionMealProductsVarianceFields>;
}


/** aggregate fields of "nutrition_meal_products" */
export interface INutritionMealProductsAggregateFieldsCountArgs {
  columns?: Maybe<Array<INutritionMealProductsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "nutrition_meal_products" */
export interface INutritionMealProductsAggregateOrderBy {
  avg?: Maybe<INutritionMealProductsAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<INutritionMealProductsMaxOrderBy>;
  min?: Maybe<INutritionMealProductsMinOrderBy>;
  stddev?: Maybe<INutritionMealProductsStddevOrderBy>;
  stddev_pop?: Maybe<INutritionMealProductsStddevPopOrderBy>;
  stddev_samp?: Maybe<INutritionMealProductsStddevSampOrderBy>;
  sum?: Maybe<INutritionMealProductsSumOrderBy>;
  var_pop?: Maybe<INutritionMealProductsVarPopOrderBy>;
  var_samp?: Maybe<INutritionMealProductsVarSampOrderBy>;
  variance?: Maybe<INutritionMealProductsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "nutrition_meal_products" */
export interface INutritionMealProductsArrRelInsertInput {
  data: Array<INutritionMealProductsInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<INutritionMealProductsOnConflict>;
}

/** aggregate avg on columns */
export interface INutritionMealProductsAvgFields {
   __typename?: 'nutrition_meal_products_avg_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "nutrition_meal_products" */
export interface INutritionMealProductsAvgOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "nutrition_meal_products". All fields are combined with a logical 'AND'. */
export interface INutritionMealProductsBoolExp {
  _and?: Maybe<Array<INutritionMealProductsBoolExp>>;
  _not?: Maybe<INutritionMealProductsBoolExp>;
  _or?: Maybe<Array<INutritionMealProductsBoolExp>>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  nutrition_meal?: Maybe<INutritionMealsBoolExp>;
  nutrition_meal_id?: Maybe<IIntComparisonExp>;
  order?: Maybe<IIntComparisonExp>;
  product?: Maybe<IProductsRgBoolExp>;
  product_id?: Maybe<IIntComparisonExp>;
  quantity?: Maybe<IIntComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "nutrition_meal_products" */
export enum INutritionMealProductsConstraint {
  /** unique or primary key constraint */
  NUTRITION_MEAL_PRODUCTS_PKEY = 'nutrition_meal_products_pkey'
}

/** input type for incrementing numeric columns in table "nutrition_meal_products" */
export interface INutritionMealProductsIncInput {
  id?: Maybe<Scalars['Int']>;
  nutrition_meal_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "nutrition_meal_products" */
export interface INutritionMealProductsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  nutrition_meal?: Maybe<INutritionMealsObjRelInsertInput>;
  nutrition_meal_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  product?: Maybe<IProductsRgObjRelInsertInput>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface INutritionMealProductsMaxFields {
   __typename?: 'nutrition_meal_products_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  nutrition_meal_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "nutrition_meal_products" */
export interface INutritionMealProductsMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface INutritionMealProductsMinFields {
   __typename?: 'nutrition_meal_products_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  nutrition_meal_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "nutrition_meal_products" */
export interface INutritionMealProductsMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "nutrition_meal_products" */
export interface INutritionMealProductsMutationResponse {
   __typename?: 'nutrition_meal_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<INutritionMealProducts>;
}

/** on conflict condition type for table "nutrition_meal_products" */
export interface INutritionMealProductsOnConflict {
  constraint: INutritionMealProductsConstraint;
  update_columns: Array<INutritionMealProductsUpdateColumn>;
  where?: Maybe<INutritionMealProductsBoolExp>;
}

/** Ordering options when selecting data from "nutrition_meal_products". */
export interface INutritionMealProductsOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  nutrition_meal?: Maybe<INutritionMealsOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  product?: Maybe<IProductsRgOrderBy>;
  product_id?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: nutrition_meal_products */
export interface INutritionMealProductsPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "nutrition_meal_products" */
export enum INutritionMealProductsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NUTRITION_MEAL_ID = 'nutrition_meal_id',
  /** column name */
  ORDER = 'order',
  /** column name */
  PRODUCT_ID = 'product_id',
  /** column name */
  QUANTITY = 'quantity',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "nutrition_meal_products" */
export interface INutritionMealProductsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  nutrition_meal_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface INutritionMealProductsStddevFields {
   __typename?: 'nutrition_meal_products_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "nutrition_meal_products" */
export interface INutritionMealProductsStddevOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface INutritionMealProductsStddevPopFields {
   __typename?: 'nutrition_meal_products_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "nutrition_meal_products" */
export interface INutritionMealProductsStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface INutritionMealProductsStddevSampFields {
   __typename?: 'nutrition_meal_products_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "nutrition_meal_products" */
export interface INutritionMealProductsStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface INutritionMealProductsSumFields {
   __typename?: 'nutrition_meal_products_sum_fields';
  id?: Maybe<Scalars['Int']>;
  nutrition_meal_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "nutrition_meal_products" */
export interface INutritionMealProductsSumOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
}

/** update columns of table "nutrition_meal_products" */
export enum INutritionMealProductsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NUTRITION_MEAL_ID = 'nutrition_meal_id',
  /** column name */
  ORDER = 'order',
  /** column name */
  PRODUCT_ID = 'product_id',
  /** column name */
  QUANTITY = 'quantity',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface INutritionMealProductsVarPopFields {
   __typename?: 'nutrition_meal_products_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "nutrition_meal_products" */
export interface INutritionMealProductsVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface INutritionMealProductsVarSampFields {
   __typename?: 'nutrition_meal_products_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "nutrition_meal_products" */
export interface INutritionMealProductsVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface INutritionMealProductsVarianceFields {
   __typename?: 'nutrition_meal_products_variance_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_meal_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "nutrition_meal_products" */
export interface INutritionMealProductsVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_meal_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  product_id?: Maybe<IOrderBy>;
  quantity?: Maybe<IOrderBy>;
}

/** columns and relationships of "nutrition_meals" */
export interface INutritionMeals {
   __typename?: 'nutrition_meals';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  nutrition_meal_products: Array<INutritionMealProducts>;
  /** An aggregate relationship */
  nutrition_meal_products_aggregate: INutritionMealProductsAggregate;
  /** An object relationship */
  nutrition_plan_meals: INutritionPlanMeals;
  nutrition_plan_meals_id: Scalars['Int'];
  order: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
}


/** columns and relationships of "nutrition_meals" */
export interface INutritionMealsNutritionMealProductsArgs {
  distinct_on?: Maybe<Array<INutritionMealProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionMealProductsOrderBy>>;
  where?: Maybe<INutritionMealProductsBoolExp>;
}


/** columns and relationships of "nutrition_meals" */
export interface INutritionMealsNutritionMealProductsAggregateArgs {
  distinct_on?: Maybe<Array<INutritionMealProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionMealProductsOrderBy>>;
  where?: Maybe<INutritionMealProductsBoolExp>;
}

/** aggregated selection of "nutrition_meals" */
export interface INutritionMealsAggregate {
   __typename?: 'nutrition_meals_aggregate';
  aggregate?: Maybe<INutritionMealsAggregateFields>;
  nodes: Array<INutritionMeals>;
}

/** aggregate fields of "nutrition_meals" */
export interface INutritionMealsAggregateFields {
   __typename?: 'nutrition_meals_aggregate_fields';
  avg?: Maybe<INutritionMealsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<INutritionMealsMaxFields>;
  min?: Maybe<INutritionMealsMinFields>;
  stddev?: Maybe<INutritionMealsStddevFields>;
  stddev_pop?: Maybe<INutritionMealsStddevPopFields>;
  stddev_samp?: Maybe<INutritionMealsStddevSampFields>;
  sum?: Maybe<INutritionMealsSumFields>;
  var_pop?: Maybe<INutritionMealsVarPopFields>;
  var_samp?: Maybe<INutritionMealsVarSampFields>;
  variance?: Maybe<INutritionMealsVarianceFields>;
}


/** aggregate fields of "nutrition_meals" */
export interface INutritionMealsAggregateFieldsCountArgs {
  columns?: Maybe<Array<INutritionMealsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "nutrition_meals" */
export interface INutritionMealsAggregateOrderBy {
  avg?: Maybe<INutritionMealsAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<INutritionMealsMaxOrderBy>;
  min?: Maybe<INutritionMealsMinOrderBy>;
  stddev?: Maybe<INutritionMealsStddevOrderBy>;
  stddev_pop?: Maybe<INutritionMealsStddevPopOrderBy>;
  stddev_samp?: Maybe<INutritionMealsStddevSampOrderBy>;
  sum?: Maybe<INutritionMealsSumOrderBy>;
  var_pop?: Maybe<INutritionMealsVarPopOrderBy>;
  var_samp?: Maybe<INutritionMealsVarSampOrderBy>;
  variance?: Maybe<INutritionMealsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "nutrition_meals" */
export interface INutritionMealsArrRelInsertInput {
  data: Array<INutritionMealsInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<INutritionMealsOnConflict>;
}

/** aggregate avg on columns */
export interface INutritionMealsAvgFields {
   __typename?: 'nutrition_meals_avg_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "nutrition_meals" */
export interface INutritionMealsAvgOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "nutrition_meals". All fields are combined with a logical 'AND'. */
export interface INutritionMealsBoolExp {
  _and?: Maybe<Array<INutritionMealsBoolExp>>;
  _not?: Maybe<INutritionMealsBoolExp>;
  _or?: Maybe<Array<INutritionMealsBoolExp>>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  nutrition_meal_products?: Maybe<INutritionMealProductsBoolExp>;
  nutrition_plan_meals?: Maybe<INutritionPlanMealsBoolExp>;
  nutrition_plan_meals_id?: Maybe<IIntComparisonExp>;
  order?: Maybe<IIntComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "nutrition_meals" */
export enum INutritionMealsConstraint {
  /** unique or primary key constraint */
  NUTRITION_MEALS_PKEY = 'nutrition_meals_pkey'
}

/** input type for incrementing numeric columns in table "nutrition_meals" */
export interface INutritionMealsIncInput {
  id?: Maybe<Scalars['Int']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "nutrition_meals" */
export interface INutritionMealsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nutrition_meal_products?: Maybe<INutritionMealProductsArrRelInsertInput>;
  nutrition_plan_meals?: Maybe<INutritionPlanMealsObjRelInsertInput>;
  nutrition_plan_meals_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface INutritionMealsMaxFields {
   __typename?: 'nutrition_meals_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "nutrition_meals" */
export interface INutritionMealsMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface INutritionMealsMinFields {
   __typename?: 'nutrition_meals_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "nutrition_meals" */
export interface INutritionMealsMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "nutrition_meals" */
export interface INutritionMealsMutationResponse {
   __typename?: 'nutrition_meals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<INutritionMeals>;
}

/** input type for inserting object relation for remote table "nutrition_meals" */
export interface INutritionMealsObjRelInsertInput {
  data: INutritionMealsInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<INutritionMealsOnConflict>;
}

/** on conflict condition type for table "nutrition_meals" */
export interface INutritionMealsOnConflict {
  constraint: INutritionMealsConstraint;
  update_columns: Array<INutritionMealsUpdateColumn>;
  where?: Maybe<INutritionMealsBoolExp>;
}

/** Ordering options when selecting data from "nutrition_meals". */
export interface INutritionMealsOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  nutrition_meal_products_aggregate?: Maybe<INutritionMealProductsAggregateOrderBy>;
  nutrition_plan_meals?: Maybe<INutritionPlanMealsOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: nutrition_meals */
export interface INutritionMealsPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "nutrition_meals" */
export enum INutritionMealsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  NUTRITION_PLAN_MEALS_ID = 'nutrition_plan_meals_id',
  /** column name */
  ORDER = 'order',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "nutrition_meals" */
export interface INutritionMealsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface INutritionMealsStddevFields {
   __typename?: 'nutrition_meals_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "nutrition_meals" */
export interface INutritionMealsStddevOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface INutritionMealsStddevPopFields {
   __typename?: 'nutrition_meals_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "nutrition_meals" */
export interface INutritionMealsStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface INutritionMealsStddevSampFields {
   __typename?: 'nutrition_meals_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "nutrition_meals" */
export interface INutritionMealsStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface INutritionMealsSumFields {
   __typename?: 'nutrition_meals_sum_fields';
  id?: Maybe<Scalars['Int']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "nutrition_meals" */
export interface INutritionMealsSumOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** update columns of table "nutrition_meals" */
export enum INutritionMealsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  NUTRITION_PLAN_MEALS_ID = 'nutrition_plan_meals_id',
  /** column name */
  ORDER = 'order',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface INutritionMealsVarPopFields {
   __typename?: 'nutrition_meals_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "nutrition_meals" */
export interface INutritionMealsVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface INutritionMealsVarSampFields {
   __typename?: 'nutrition_meals_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "nutrition_meals" */
export interface INutritionMealsVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface INutritionMealsVarianceFields {
   __typename?: 'nutrition_meals_variance_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "nutrition_meals" */
export interface INutritionMealsVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_meals_id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
}

/** columns and relationships of "nutrition_plan_meals" */
export interface INutritionPlanMeals {
   __typename?: 'nutrition_plan_meals';
  created_at: Scalars['date'];
  id: Scalars['Int'];
  is_original: Scalars['Boolean'];
  /** An array relationship */
  meals_view: Array<IMealsView>;
  /** An aggregate relationship */
  meals_view_aggregate: IMealsViewAggregate;
  /** An array relationship */
  nutrition_meals: Array<INutritionMeals>;
  /** An aggregate relationship */
  nutrition_meals_aggregate: INutritionMealsAggregate;
  /** An object relationship */
  nutrition_plan: INutritionPlans;
  nutrition_plan_id: Scalars['Int'];
  /** An object relationship */
  nutrition_plan_meals_view?: Maybe<INutritionPlanMealsView>;
  updated_at: Scalars['timestamptz'];
}


/** columns and relationships of "nutrition_plan_meals" */
export interface INutritionPlanMealsMealsViewArgs {
  distinct_on?: Maybe<Array<IMealsViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMealsViewOrderBy>>;
  where?: Maybe<IMealsViewBoolExp>;
}


/** columns and relationships of "nutrition_plan_meals" */
export interface INutritionPlanMealsMealsViewAggregateArgs {
  distinct_on?: Maybe<Array<IMealsViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMealsViewOrderBy>>;
  where?: Maybe<IMealsViewBoolExp>;
}


/** columns and relationships of "nutrition_plan_meals" */
export interface INutritionPlanMealsNutritionMealsArgs {
  distinct_on?: Maybe<Array<INutritionMealsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionMealsOrderBy>>;
  where?: Maybe<INutritionMealsBoolExp>;
}


/** columns and relationships of "nutrition_plan_meals" */
export interface INutritionPlanMealsNutritionMealsAggregateArgs {
  distinct_on?: Maybe<Array<INutritionMealsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionMealsOrderBy>>;
  where?: Maybe<INutritionMealsBoolExp>;
}

/** aggregated selection of "nutrition_plan_meals" */
export interface INutritionPlanMealsAggregate {
   __typename?: 'nutrition_plan_meals_aggregate';
  aggregate?: Maybe<INutritionPlanMealsAggregateFields>;
  nodes: Array<INutritionPlanMeals>;
}

/** aggregate fields of "nutrition_plan_meals" */
export interface INutritionPlanMealsAggregateFields {
   __typename?: 'nutrition_plan_meals_aggregate_fields';
  avg?: Maybe<INutritionPlanMealsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<INutritionPlanMealsMaxFields>;
  min?: Maybe<INutritionPlanMealsMinFields>;
  stddev?: Maybe<INutritionPlanMealsStddevFields>;
  stddev_pop?: Maybe<INutritionPlanMealsStddevPopFields>;
  stddev_samp?: Maybe<INutritionPlanMealsStddevSampFields>;
  sum?: Maybe<INutritionPlanMealsSumFields>;
  var_pop?: Maybe<INutritionPlanMealsVarPopFields>;
  var_samp?: Maybe<INutritionPlanMealsVarSampFields>;
  variance?: Maybe<INutritionPlanMealsVarianceFields>;
}


/** aggregate fields of "nutrition_plan_meals" */
export interface INutritionPlanMealsAggregateFieldsCountArgs {
  columns?: Maybe<Array<INutritionPlanMealsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "nutrition_plan_meals" */
export interface INutritionPlanMealsAggregateOrderBy {
  avg?: Maybe<INutritionPlanMealsAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<INutritionPlanMealsMaxOrderBy>;
  min?: Maybe<INutritionPlanMealsMinOrderBy>;
  stddev?: Maybe<INutritionPlanMealsStddevOrderBy>;
  stddev_pop?: Maybe<INutritionPlanMealsStddevPopOrderBy>;
  stddev_samp?: Maybe<INutritionPlanMealsStddevSampOrderBy>;
  sum?: Maybe<INutritionPlanMealsSumOrderBy>;
  var_pop?: Maybe<INutritionPlanMealsVarPopOrderBy>;
  var_samp?: Maybe<INutritionPlanMealsVarSampOrderBy>;
  variance?: Maybe<INutritionPlanMealsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "nutrition_plan_meals" */
export interface INutritionPlanMealsArrRelInsertInput {
  data: Array<INutritionPlanMealsInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<INutritionPlanMealsOnConflict>;
}

/** aggregate avg on columns */
export interface INutritionPlanMealsAvgFields {
   __typename?: 'nutrition_plan_meals_avg_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "nutrition_plan_meals" */
export interface INutritionPlanMealsAvgOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "nutrition_plan_meals". All fields are combined with a logical 'AND'. */
export interface INutritionPlanMealsBoolExp {
  _and?: Maybe<Array<INutritionPlanMealsBoolExp>>;
  _not?: Maybe<INutritionPlanMealsBoolExp>;
  _or?: Maybe<Array<INutritionPlanMealsBoolExp>>;
  created_at?: Maybe<IDateComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  is_original?: Maybe<IBooleanComparisonExp>;
  meals_view?: Maybe<IMealsViewBoolExp>;
  nutrition_meals?: Maybe<INutritionMealsBoolExp>;
  nutrition_plan?: Maybe<INutritionPlansBoolExp>;
  nutrition_plan_id?: Maybe<IIntComparisonExp>;
  nutrition_plan_meals_view?: Maybe<INutritionPlanMealsViewBoolExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "nutrition_plan_meals" */
export enum INutritionPlanMealsConstraint {
  /** unique or primary key constraint */
  NUTRITION_PLAN_MEALS_NUTRITION_PLAN_ID_IS_ORIGINAL_CREATED_AT_K = 'nutrition_plan_meals_nutrition_plan_id_is_original_created_at_k',
  /** unique or primary key constraint */
  NUTRITION_PLANS_NUTRITION_MEALS_PKEY = 'nutrition_plans_nutrition_meals_pkey'
}

/** input type for incrementing numeric columns in table "nutrition_plan_meals" */
export interface INutritionPlanMealsIncInput {
  id?: Maybe<Scalars['Int']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "nutrition_plan_meals" */
export interface INutritionPlanMealsInsertInput {
  created_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  is_original?: Maybe<Scalars['Boolean']>;
  meals_view?: Maybe<IMealsViewArrRelInsertInput>;
  nutrition_meals?: Maybe<INutritionMealsArrRelInsertInput>;
  nutrition_plan?: Maybe<INutritionPlansObjRelInsertInput>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meals_view?: Maybe<INutritionPlanMealsViewObjRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface INutritionPlanMealsMaxFields {
   __typename?: 'nutrition_plan_meals_max_fields';
  created_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "nutrition_plan_meals" */
export interface INutritionPlanMealsMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface INutritionPlanMealsMinFields {
   __typename?: 'nutrition_plan_meals_min_fields';
  created_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "nutrition_plan_meals" */
export interface INutritionPlanMealsMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "nutrition_plan_meals" */
export interface INutritionPlanMealsMutationResponse {
   __typename?: 'nutrition_plan_meals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<INutritionPlanMeals>;
}

/** input type for inserting object relation for remote table "nutrition_plan_meals" */
export interface INutritionPlanMealsObjRelInsertInput {
  data: INutritionPlanMealsInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<INutritionPlanMealsOnConflict>;
}

/** on conflict condition type for table "nutrition_plan_meals" */
export interface INutritionPlanMealsOnConflict {
  constraint: INutritionPlanMealsConstraint;
  update_columns: Array<INutritionPlanMealsUpdateColumn>;
  where?: Maybe<INutritionPlanMealsBoolExp>;
}

/** Ordering options when selecting data from "nutrition_plan_meals". */
export interface INutritionPlanMealsOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  is_original?: Maybe<IOrderBy>;
  meals_view_aggregate?: Maybe<IMealsViewAggregateOrderBy>;
  nutrition_meals_aggregate?: Maybe<INutritionMealsAggregateOrderBy>;
  nutrition_plan?: Maybe<INutritionPlansOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meals_view?: Maybe<INutritionPlanMealsViewOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: nutrition_plan_meals */
export interface INutritionPlanMealsPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "nutrition_plan_meals" */
export enum INutritionPlanMealsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  IS_ORIGINAL = 'is_original',
  /** column name */
  NUTRITION_PLAN_ID = 'nutrition_plan_id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "nutrition_plan_meals" */
export interface INutritionPlanMealsSetInput {
  created_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  is_original?: Maybe<Scalars['Boolean']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface INutritionPlanMealsStddevFields {
   __typename?: 'nutrition_plan_meals_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "nutrition_plan_meals" */
export interface INutritionPlanMealsStddevOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface INutritionPlanMealsStddevPopFields {
   __typename?: 'nutrition_plan_meals_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "nutrition_plan_meals" */
export interface INutritionPlanMealsStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface INutritionPlanMealsStddevSampFields {
   __typename?: 'nutrition_plan_meals_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "nutrition_plan_meals" */
export interface INutritionPlanMealsStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface INutritionPlanMealsSumFields {
   __typename?: 'nutrition_plan_meals_sum_fields';
  id?: Maybe<Scalars['Int']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "nutrition_plan_meals" */
export interface INutritionPlanMealsSumOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
}

/** update columns of table "nutrition_plan_meals" */
export enum INutritionPlanMealsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  IS_ORIGINAL = 'is_original',
  /** column name */
  NUTRITION_PLAN_ID = 'nutrition_plan_id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface INutritionPlanMealsVarPopFields {
   __typename?: 'nutrition_plan_meals_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "nutrition_plan_meals" */
export interface INutritionPlanMealsVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface INutritionPlanMealsVarSampFields {
   __typename?: 'nutrition_plan_meals_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "nutrition_plan_meals" */
export interface INutritionPlanMealsVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface INutritionPlanMealsVarianceFields {
   __typename?: 'nutrition_plan_meals_variance_fields';
  id?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "nutrition_plan_meals" */
export interface INutritionPlanMealsVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "nutrition_plan_meals_view" */
export interface INutritionPlanMealsView {
   __typename?: 'nutrition_plan_meals_view';
  calorie_sum?: Maybe<Scalars['float8']>;
  calories_maintenance_per_day?: Maybe<Scalars['Int']>;
  calories_per_day?: Maybe<Scalars['Int']>;
  calories_weekly_reduction?: Maybe<Scalars['Int']>;
  carbo?: Maybe<Scalars['float8']>;
  carbo_sum?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  fat?: Maybe<Scalars['float8']>;
  fat_sum?: Maybe<Scalars['float8']>;
  is_original?: Maybe<Scalars['Boolean']>;
  max_carbo?: Maybe<Scalars['float8']>;
  max_fat?: Maybe<Scalars['float8']>;
  max_protein?: Maybe<Scalars['float8']>;
  min_carbo?: Maybe<Scalars['float8']>;
  min_fat?: Maybe<Scalars['float8']>;
  min_protein?: Maybe<Scalars['float8']>;
  npm_created_at?: Maybe<Scalars['date']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meal_id?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  protein_sum?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  total_fats?: Maybe<Scalars['float8']>;
  total_protein?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregated selection of "nutrition_plan_meals_view" */
export interface INutritionPlanMealsViewAggregate {
   __typename?: 'nutrition_plan_meals_view_aggregate';
  aggregate?: Maybe<INutritionPlanMealsViewAggregateFields>;
  nodes: Array<INutritionPlanMealsView>;
}

/** aggregate fields of "nutrition_plan_meals_view" */
export interface INutritionPlanMealsViewAggregateFields {
   __typename?: 'nutrition_plan_meals_view_aggregate_fields';
  avg?: Maybe<INutritionPlanMealsViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<INutritionPlanMealsViewMaxFields>;
  min?: Maybe<INutritionPlanMealsViewMinFields>;
  stddev?: Maybe<INutritionPlanMealsViewStddevFields>;
  stddev_pop?: Maybe<INutritionPlanMealsViewStddevPopFields>;
  stddev_samp?: Maybe<INutritionPlanMealsViewStddevSampFields>;
  sum?: Maybe<INutritionPlanMealsViewSumFields>;
  var_pop?: Maybe<INutritionPlanMealsViewVarPopFields>;
  var_samp?: Maybe<INutritionPlanMealsViewVarSampFields>;
  variance?: Maybe<INutritionPlanMealsViewVarianceFields>;
}


/** aggregate fields of "nutrition_plan_meals_view" */
export interface INutritionPlanMealsViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<INutritionPlanMealsViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface INutritionPlanMealsViewAvgFields {
   __typename?: 'nutrition_plan_meals_view_avg_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meal_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "nutrition_plan_meals_view". All fields are combined with a logical 'AND'. */
export interface INutritionPlanMealsViewBoolExp {
  _and?: Maybe<Array<INutritionPlanMealsViewBoolExp>>;
  _not?: Maybe<INutritionPlanMealsViewBoolExp>;
  _or?: Maybe<Array<INutritionPlanMealsViewBoolExp>>;
  calorie_sum?: Maybe<IFloat8ComparisonExp>;
  calories_maintenance_per_day?: Maybe<IIntComparisonExp>;
  calories_per_day?: Maybe<IIntComparisonExp>;
  calories_weekly_reduction?: Maybe<IIntComparisonExp>;
  carbo?: Maybe<IFloat8ComparisonExp>;
  carbo_sum?: Maybe<IFloat8ComparisonExp>;
  end_date?: Maybe<IDateComparisonExp>;
  fat?: Maybe<IFloat8ComparisonExp>;
  fat_sum?: Maybe<IFloat8ComparisonExp>;
  is_original?: Maybe<IBooleanComparisonExp>;
  max_carbo?: Maybe<IFloat8ComparisonExp>;
  max_fat?: Maybe<IFloat8ComparisonExp>;
  max_protein?: Maybe<IFloat8ComparisonExp>;
  min_carbo?: Maybe<IFloat8ComparisonExp>;
  min_fat?: Maybe<IFloat8ComparisonExp>;
  min_protein?: Maybe<IFloat8ComparisonExp>;
  npm_created_at?: Maybe<IDateComparisonExp>;
  nutrition_plan_id?: Maybe<IIntComparisonExp>;
  nutrition_plan_meal_id?: Maybe<IIntComparisonExp>;
  protein?: Maybe<IFloat8ComparisonExp>;
  protein_sum?: Maybe<IFloat8ComparisonExp>;
  start_date?: Maybe<IDateComparisonExp>;
  total_fats?: Maybe<IFloat8ComparisonExp>;
  total_protein?: Maybe<IFloat8ComparisonExp>;
  user_id?: Maybe<IBigintComparisonExp>;
}

/** input type for inserting data into table "nutrition_plan_meals_view" */
export interface INutritionPlanMealsViewInsertInput {
  calorie_sum?: Maybe<Scalars['float8']>;
  calories_maintenance_per_day?: Maybe<Scalars['Int']>;
  calories_per_day?: Maybe<Scalars['Int']>;
  calories_weekly_reduction?: Maybe<Scalars['Int']>;
  carbo?: Maybe<Scalars['float8']>;
  carbo_sum?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  fat?: Maybe<Scalars['float8']>;
  fat_sum?: Maybe<Scalars['float8']>;
  is_original?: Maybe<Scalars['Boolean']>;
  max_carbo?: Maybe<Scalars['float8']>;
  max_fat?: Maybe<Scalars['float8']>;
  max_protein?: Maybe<Scalars['float8']>;
  min_carbo?: Maybe<Scalars['float8']>;
  min_fat?: Maybe<Scalars['float8']>;
  min_protein?: Maybe<Scalars['float8']>;
  npm_created_at?: Maybe<Scalars['date']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meal_id?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  protein_sum?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  total_fats?: Maybe<Scalars['float8']>;
  total_protein?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate max on columns */
export interface INutritionPlanMealsViewMaxFields {
   __typename?: 'nutrition_plan_meals_view_max_fields';
  calorie_sum?: Maybe<Scalars['float8']>;
  calories_maintenance_per_day?: Maybe<Scalars['Int']>;
  calories_per_day?: Maybe<Scalars['Int']>;
  calories_weekly_reduction?: Maybe<Scalars['Int']>;
  carbo?: Maybe<Scalars['float8']>;
  carbo_sum?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  fat?: Maybe<Scalars['float8']>;
  fat_sum?: Maybe<Scalars['float8']>;
  max_carbo?: Maybe<Scalars['float8']>;
  max_fat?: Maybe<Scalars['float8']>;
  max_protein?: Maybe<Scalars['float8']>;
  min_carbo?: Maybe<Scalars['float8']>;
  min_fat?: Maybe<Scalars['float8']>;
  min_protein?: Maybe<Scalars['float8']>;
  npm_created_at?: Maybe<Scalars['date']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meal_id?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  protein_sum?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  total_fats?: Maybe<Scalars['float8']>;
  total_protein?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate min on columns */
export interface INutritionPlanMealsViewMinFields {
   __typename?: 'nutrition_plan_meals_view_min_fields';
  calorie_sum?: Maybe<Scalars['float8']>;
  calories_maintenance_per_day?: Maybe<Scalars['Int']>;
  calories_per_day?: Maybe<Scalars['Int']>;
  calories_weekly_reduction?: Maybe<Scalars['Int']>;
  carbo?: Maybe<Scalars['float8']>;
  carbo_sum?: Maybe<Scalars['float8']>;
  end_date?: Maybe<Scalars['date']>;
  fat?: Maybe<Scalars['float8']>;
  fat_sum?: Maybe<Scalars['float8']>;
  max_carbo?: Maybe<Scalars['float8']>;
  max_fat?: Maybe<Scalars['float8']>;
  max_protein?: Maybe<Scalars['float8']>;
  min_carbo?: Maybe<Scalars['float8']>;
  min_fat?: Maybe<Scalars['float8']>;
  min_protein?: Maybe<Scalars['float8']>;
  npm_created_at?: Maybe<Scalars['date']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meal_id?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  protein_sum?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  total_fats?: Maybe<Scalars['float8']>;
  total_protein?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting object relation for remote table "nutrition_plan_meals_view" */
export interface INutritionPlanMealsViewObjRelInsertInput {
  data: INutritionPlanMealsViewInsertInput;
}

/** Ordering options when selecting data from "nutrition_plan_meals_view". */
export interface INutritionPlanMealsViewOrderBy {
  calorie_sum?: Maybe<IOrderBy>;
  calories_maintenance_per_day?: Maybe<IOrderBy>;
  calories_per_day?: Maybe<IOrderBy>;
  calories_weekly_reduction?: Maybe<IOrderBy>;
  carbo?: Maybe<IOrderBy>;
  carbo_sum?: Maybe<IOrderBy>;
  end_date?: Maybe<IOrderBy>;
  fat?: Maybe<IOrderBy>;
  fat_sum?: Maybe<IOrderBy>;
  is_original?: Maybe<IOrderBy>;
  max_carbo?: Maybe<IOrderBy>;
  max_fat?: Maybe<IOrderBy>;
  max_protein?: Maybe<IOrderBy>;
  min_carbo?: Maybe<IOrderBy>;
  min_fat?: Maybe<IOrderBy>;
  min_protein?: Maybe<IOrderBy>;
  npm_created_at?: Maybe<IOrderBy>;
  nutrition_plan_id?: Maybe<IOrderBy>;
  nutrition_plan_meal_id?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  protein_sum?: Maybe<IOrderBy>;
  start_date?: Maybe<IOrderBy>;
  total_fats?: Maybe<IOrderBy>;
  total_protein?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** select columns of table "nutrition_plan_meals_view" */
export enum INutritionPlanMealsViewSelectColumn {
  /** column name */
  CALORIE_SUM = 'calorie_sum',
  /** column name */
  CALORIES_MAINTENANCE_PER_DAY = 'calories_maintenance_per_day',
  /** column name */
  CALORIES_PER_DAY = 'calories_per_day',
  /** column name */
  CALORIES_WEEKLY_REDUCTION = 'calories_weekly_reduction',
  /** column name */
  CARBO = 'carbo',
  /** column name */
  CARBO_SUM = 'carbo_sum',
  /** column name */
  END_DATE = 'end_date',
  /** column name */
  FAT = 'fat',
  /** column name */
  FAT_SUM = 'fat_sum',
  /** column name */
  IS_ORIGINAL = 'is_original',
  /** column name */
  MAX_CARBO = 'max_carbo',
  /** column name */
  MAX_FAT = 'max_fat',
  /** column name */
  MAX_PROTEIN = 'max_protein',
  /** column name */
  MIN_CARBO = 'min_carbo',
  /** column name */
  MIN_FAT = 'min_fat',
  /** column name */
  MIN_PROTEIN = 'min_protein',
  /** column name */
  NPM_CREATED_AT = 'npm_created_at',
  /** column name */
  NUTRITION_PLAN_ID = 'nutrition_plan_id',
  /** column name */
  NUTRITION_PLAN_MEAL_ID = 'nutrition_plan_meal_id',
  /** column name */
  PROTEIN = 'protein',
  /** column name */
  PROTEIN_SUM = 'protein_sum',
  /** column name */
  START_DATE = 'start_date',
  /** column name */
  TOTAL_FATS = 'total_fats',
  /** column name */
  TOTAL_PROTEIN = 'total_protein',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface INutritionPlanMealsViewStddevFields {
   __typename?: 'nutrition_plan_meals_view_stddev_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meal_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface INutritionPlanMealsViewStddevPopFields {
   __typename?: 'nutrition_plan_meals_view_stddev_pop_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meal_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface INutritionPlanMealsViewStddevSampFields {
   __typename?: 'nutrition_plan_meals_view_stddev_samp_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meal_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface INutritionPlanMealsViewSumFields {
   __typename?: 'nutrition_plan_meals_view_sum_fields';
  calorie_sum?: Maybe<Scalars['float8']>;
  calories_maintenance_per_day?: Maybe<Scalars['Int']>;
  calories_per_day?: Maybe<Scalars['Int']>;
  calories_weekly_reduction?: Maybe<Scalars['Int']>;
  carbo?: Maybe<Scalars['float8']>;
  carbo_sum?: Maybe<Scalars['float8']>;
  fat?: Maybe<Scalars['float8']>;
  fat_sum?: Maybe<Scalars['float8']>;
  max_carbo?: Maybe<Scalars['float8']>;
  max_fat?: Maybe<Scalars['float8']>;
  max_protein?: Maybe<Scalars['float8']>;
  min_carbo?: Maybe<Scalars['float8']>;
  min_fat?: Maybe<Scalars['float8']>;
  min_protein?: Maybe<Scalars['float8']>;
  nutrition_plan_id?: Maybe<Scalars['Int']>;
  nutrition_plan_meal_id?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  protein_sum?: Maybe<Scalars['float8']>;
  total_fats?: Maybe<Scalars['float8']>;
  total_protein?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface INutritionPlanMealsViewVarPopFields {
   __typename?: 'nutrition_plan_meals_view_var_pop_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meal_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface INutritionPlanMealsViewVarSampFields {
   __typename?: 'nutrition_plan_meals_view_var_samp_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meal_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface INutritionPlanMealsViewVarianceFields {
   __typename?: 'nutrition_plan_meals_view_variance_fields';
  calorie_sum?: Maybe<Scalars['Float']>;
  calories_maintenance_per_day?: Maybe<Scalars['Float']>;
  calories_per_day?: Maybe<Scalars['Float']>;
  calories_weekly_reduction?: Maybe<Scalars['Float']>;
  carbo?: Maybe<Scalars['Float']>;
  carbo_sum?: Maybe<Scalars['Float']>;
  fat?: Maybe<Scalars['Float']>;
  fat_sum?: Maybe<Scalars['Float']>;
  max_carbo?: Maybe<Scalars['Float']>;
  max_fat?: Maybe<Scalars['Float']>;
  max_protein?: Maybe<Scalars['Float']>;
  min_carbo?: Maybe<Scalars['Float']>;
  min_fat?: Maybe<Scalars['Float']>;
  min_protein?: Maybe<Scalars['Float']>;
  nutrition_plan_id?: Maybe<Scalars['Float']>;
  nutrition_plan_meal_id?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  protein_sum?: Maybe<Scalars['Float']>;
  total_fats?: Maybe<Scalars['Float']>;
  total_protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "nutrition_plans" */
export interface INutritionPlans {
   __typename?: 'nutrition_plans';
  calories_per_day: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  end_date: Scalars['date'];
  fats: Scalars['float8'];
  id: Scalars['Int'];
  /** An array relationship */
  nutrition_plan_meals: Array<INutritionPlanMeals>;
  /** An aggregate relationship */
  nutrition_plan_meals_aggregate: INutritionPlanMealsAggregate;
  order: Scalars['Int'];
  protein: Scalars['float8'];
  push_notification_sent: Scalars['Boolean'];
  start_date: Scalars['date'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id: Scalars['Int'];
}


/** columns and relationships of "nutrition_plans" */
export interface INutritionPlansNutritionPlanMealsArgs {
  distinct_on?: Maybe<Array<INutritionPlanMealsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlanMealsOrderBy>>;
  where?: Maybe<INutritionPlanMealsBoolExp>;
}


/** columns and relationships of "nutrition_plans" */
export interface INutritionPlansNutritionPlanMealsAggregateArgs {
  distinct_on?: Maybe<Array<INutritionPlanMealsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlanMealsOrderBy>>;
  where?: Maybe<INutritionPlanMealsBoolExp>;
}

/** aggregated selection of "nutrition_plans" */
export interface INutritionPlansAggregate {
   __typename?: 'nutrition_plans_aggregate';
  aggregate?: Maybe<INutritionPlansAggregateFields>;
  nodes: Array<INutritionPlans>;
}

/** aggregate fields of "nutrition_plans" */
export interface INutritionPlansAggregateFields {
   __typename?: 'nutrition_plans_aggregate_fields';
  avg?: Maybe<INutritionPlansAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<INutritionPlansMaxFields>;
  min?: Maybe<INutritionPlansMinFields>;
  stddev?: Maybe<INutritionPlansStddevFields>;
  stddev_pop?: Maybe<INutritionPlansStddevPopFields>;
  stddev_samp?: Maybe<INutritionPlansStddevSampFields>;
  sum?: Maybe<INutritionPlansSumFields>;
  var_pop?: Maybe<INutritionPlansVarPopFields>;
  var_samp?: Maybe<INutritionPlansVarSampFields>;
  variance?: Maybe<INutritionPlansVarianceFields>;
}


/** aggregate fields of "nutrition_plans" */
export interface INutritionPlansAggregateFieldsCountArgs {
  columns?: Maybe<Array<INutritionPlansSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "nutrition_plans" */
export interface INutritionPlansAggregateOrderBy {
  avg?: Maybe<INutritionPlansAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<INutritionPlansMaxOrderBy>;
  min?: Maybe<INutritionPlansMinOrderBy>;
  stddev?: Maybe<INutritionPlansStddevOrderBy>;
  stddev_pop?: Maybe<INutritionPlansStddevPopOrderBy>;
  stddev_samp?: Maybe<INutritionPlansStddevSampOrderBy>;
  sum?: Maybe<INutritionPlansSumOrderBy>;
  var_pop?: Maybe<INutritionPlansVarPopOrderBy>;
  var_samp?: Maybe<INutritionPlansVarSampOrderBy>;
  variance?: Maybe<INutritionPlansVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "nutrition_plans" */
export interface INutritionPlansArrRelInsertInput {
  data: Array<INutritionPlansInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<INutritionPlansOnConflict>;
}

/** aggregate avg on columns */
export interface INutritionPlansAvgFields {
   __typename?: 'nutrition_plans_avg_fields';
  calories_per_day?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "nutrition_plans" */
export interface INutritionPlansAvgOrderBy {
  calories_per_day?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "nutrition_plans". All fields are combined with a logical 'AND'. */
export interface INutritionPlansBoolExp {
  _and?: Maybe<Array<INutritionPlansBoolExp>>;
  _not?: Maybe<INutritionPlansBoolExp>;
  _or?: Maybe<Array<INutritionPlansBoolExp>>;
  calories_per_day?: Maybe<IIntComparisonExp>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  end_date?: Maybe<IDateComparisonExp>;
  fats?: Maybe<IFloat8ComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  nutrition_plan_meals?: Maybe<INutritionPlanMealsBoolExp>;
  order?: Maybe<IIntComparisonExp>;
  protein?: Maybe<IFloat8ComparisonExp>;
  push_notification_sent?: Maybe<IBooleanComparisonExp>;
  start_date?: Maybe<IDateComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
  user?: Maybe<IUsersBoolExp>;
  user_id?: Maybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "nutrition_plans" */
export enum INutritionPlansConstraint {
  /** unique or primary key constraint */
  NUTRITION_PLANS_PKEY = 'nutrition_plans_pkey'
}

/** input type for incrementing numeric columns in table "nutrition_plans" */
export interface INutritionPlansIncInput {
  calories_per_day?: Maybe<Scalars['Int']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "nutrition_plans" */
export interface INutritionPlansInsertInput {
  calories_per_day?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['date']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  nutrition_plan_meals?: Maybe<INutritionPlanMealsArrRelInsertInput>;
  order?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  push_notification_sent?: Maybe<Scalars['Boolean']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<IUsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface INutritionPlansMaxFields {
   __typename?: 'nutrition_plans_max_fields';
  calories_per_day?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['date']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "nutrition_plans" */
export interface INutritionPlansMaxOrderBy {
  calories_per_day?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  end_date?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  start_date?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface INutritionPlansMinFields {
   __typename?: 'nutrition_plans_min_fields';
  calories_per_day?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['date']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "nutrition_plans" */
export interface INutritionPlansMinOrderBy {
  calories_per_day?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  end_date?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  start_date?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "nutrition_plans" */
export interface INutritionPlansMutationResponse {
   __typename?: 'nutrition_plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<INutritionPlans>;
}

/** input type for inserting object relation for remote table "nutrition_plans" */
export interface INutritionPlansObjRelInsertInput {
  data: INutritionPlansInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<INutritionPlansOnConflict>;
}

/** on conflict condition type for table "nutrition_plans" */
export interface INutritionPlansOnConflict {
  constraint: INutritionPlansConstraint;
  update_columns: Array<INutritionPlansUpdateColumn>;
  where?: Maybe<INutritionPlansBoolExp>;
}

/** Ordering options when selecting data from "nutrition_plans". */
export interface INutritionPlansOrderBy {
  calories_per_day?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  end_date?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  nutrition_plan_meals_aggregate?: Maybe<INutritionPlanMealsAggregateOrderBy>;
  order?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  push_notification_sent?: Maybe<IOrderBy>;
  start_date?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user?: Maybe<IUsersOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** primary key columns input for table: nutrition_plans */
export interface INutritionPlansPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "nutrition_plans" */
export enum INutritionPlansSelectColumn {
  /** column name */
  CALORIES_PER_DAY = 'calories_per_day',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  END_DATE = 'end_date',
  /** column name */
  FATS = 'fats',
  /** column name */
  ID = 'id',
  /** column name */
  ORDER = 'order',
  /** column name */
  PROTEIN = 'protein',
  /** column name */
  PUSH_NOTIFICATION_SENT = 'push_notification_sent',
  /** column name */
  START_DATE = 'start_date',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "nutrition_plans" */
export interface INutritionPlansSetInput {
  calories_per_day?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['date']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  push_notification_sent?: Maybe<Scalars['Boolean']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface INutritionPlansStddevFields {
   __typename?: 'nutrition_plans_stddev_fields';
  calories_per_day?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "nutrition_plans" */
export interface INutritionPlansStddevOrderBy {
  calories_per_day?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface INutritionPlansStddevPopFields {
   __typename?: 'nutrition_plans_stddev_pop_fields';
  calories_per_day?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "nutrition_plans" */
export interface INutritionPlansStddevPopOrderBy {
  calories_per_day?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface INutritionPlansStddevSampFields {
   __typename?: 'nutrition_plans_stddev_samp_fields';
  calories_per_day?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "nutrition_plans" */
export interface INutritionPlansStddevSampOrderBy {
  calories_per_day?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface INutritionPlansSumFields {
   __typename?: 'nutrition_plans_sum_fields';
  calories_per_day?: Maybe<Scalars['Int']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  protein?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "nutrition_plans" */
export interface INutritionPlansSumOrderBy {
  calories_per_day?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** update columns of table "nutrition_plans" */
export enum INutritionPlansUpdateColumn {
  /** column name */
  CALORIES_PER_DAY = 'calories_per_day',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  END_DATE = 'end_date',
  /** column name */
  FATS = 'fats',
  /** column name */
  ID = 'id',
  /** column name */
  ORDER = 'order',
  /** column name */
  PROTEIN = 'protein',
  /** column name */
  PUSH_NOTIFICATION_SENT = 'push_notification_sent',
  /** column name */
  START_DATE = 'start_date',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate var_pop on columns */
export interface INutritionPlansVarPopFields {
   __typename?: 'nutrition_plans_var_pop_fields';
  calories_per_day?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "nutrition_plans" */
export interface INutritionPlansVarPopOrderBy {
  calories_per_day?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface INutritionPlansVarSampFields {
   __typename?: 'nutrition_plans_var_samp_fields';
  calories_per_day?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "nutrition_plans" */
export interface INutritionPlansVarSampOrderBy {
  calories_per_day?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface INutritionPlansVarianceFields {
   __typename?: 'nutrition_plans_variance_fields';
  calories_per_day?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "nutrition_plans" */
export interface INutritionPlansVarianceOrderBy {
  calories_per_day?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  order?: Maybe<IOrderBy>;
  protein?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** column ordering options */
export enum IOrderBy {
  /** in ascending order, nulls last */
  ASC = 'asc',
  /** in ascending order, nulls first */
  ASC_NULLS_FIRST = 'asc_nulls_first',
  /** in ascending order, nulls last */
  ASC_NULLS_LAST = 'asc_nulls_last',
  /** in descending order, nulls first */
  DESC = 'desc',
  /** in descending order, nulls first */
  DESC_NULLS_FIRST = 'desc_nulls_first',
  /** in descending order, nulls last */
  DESC_NULLS_LAST = 'desc_nulls_last'
}

/** columns and relationships of "product_categories" */
export interface IProductCategories {
   __typename?: 'product_categories';
  id: Scalars['Int'];
  name: Scalars['String'];
}

/** aggregated selection of "product_categories" */
export interface IProductCategoriesAggregate {
   __typename?: 'product_categories_aggregate';
  aggregate?: Maybe<IProductCategoriesAggregateFields>;
  nodes: Array<IProductCategories>;
}

/** aggregate fields of "product_categories" */
export interface IProductCategoriesAggregateFields {
   __typename?: 'product_categories_aggregate_fields';
  avg?: Maybe<IProductCategoriesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProductCategoriesMaxFields>;
  min?: Maybe<IProductCategoriesMinFields>;
  stddev?: Maybe<IProductCategoriesStddevFields>;
  stddev_pop?: Maybe<IProductCategoriesStddevPopFields>;
  stddev_samp?: Maybe<IProductCategoriesStddevSampFields>;
  sum?: Maybe<IProductCategoriesSumFields>;
  var_pop?: Maybe<IProductCategoriesVarPopFields>;
  var_samp?: Maybe<IProductCategoriesVarSampFields>;
  variance?: Maybe<IProductCategoriesVarianceFields>;
}


/** aggregate fields of "product_categories" */
export interface IProductCategoriesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProductCategoriesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IProductCategoriesAvgFields {
   __typename?: 'product_categories_avg_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "product_categories". All fields are combined with a logical 'AND'. */
export interface IProductCategoriesBoolExp {
  _and?: Maybe<Array<IProductCategoriesBoolExp>>;
  _not?: Maybe<IProductCategoriesBoolExp>;
  _or?: Maybe<Array<IProductCategoriesBoolExp>>;
  id?: Maybe<IIntComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "product_categories" */
export enum IProductCategoriesConstraint {
  /** unique or primary key constraint */
  PRODUCT_CATEGORIES_PKEY = 'product_categories_pkey'
}

/** input type for incrementing numeric columns in table "product_categories" */
export interface IProductCategoriesIncInput {
  id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "product_categories" */
export interface IProductCategoriesInsertInput {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IProductCategoriesMaxFields {
   __typename?: 'product_categories_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IProductCategoriesMinFields {
   __typename?: 'product_categories_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "product_categories" */
export interface IProductCategoriesMutationResponse {
   __typename?: 'product_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IProductCategories>;
}

/** input type for inserting object relation for remote table "product_categories" */
export interface IProductCategoriesObjRelInsertInput {
  data: IProductCategoriesInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IProductCategoriesOnConflict>;
}

/** on conflict condition type for table "product_categories" */
export interface IProductCategoriesOnConflict {
  constraint: IProductCategoriesConstraint;
  update_columns: Array<IProductCategoriesUpdateColumn>;
  where?: Maybe<IProductCategoriesBoolExp>;
}

/** Ordering options when selecting data from "product_categories". */
export interface IProductCategoriesOrderBy {
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
}

/** primary key columns input for table: product_categories */
export interface IProductCategoriesPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "product_categories" */
export enum IProductCategoriesSelectColumn {
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name'
}

/** input type for updating data in table "product_categories" */
export interface IProductCategoriesSetInput {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IProductCategoriesStddevFields {
   __typename?: 'product_categories_stddev_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IProductCategoriesStddevPopFields {
   __typename?: 'product_categories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IProductCategoriesStddevSampFields {
   __typename?: 'product_categories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IProductCategoriesSumFields {
   __typename?: 'product_categories_sum_fields';
  id?: Maybe<Scalars['Int']>;
}

/** update columns of table "product_categories" */
export enum IProductCategoriesUpdateColumn {
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name'
}

/** aggregate var_pop on columns */
export interface IProductCategoriesVarPopFields {
   __typename?: 'product_categories_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IProductCategoriesVarSampFields {
   __typename?: 'product_categories_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IProductCategoriesVarianceFields {
   __typename?: 'product_categories_variance_fields';
  id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "products_rg" */
export interface IProductsRg {
   __typename?: 'products_rg';
  brand?: Maybe<Scalars['String']>;
  calories: Scalars['float8'];
  carbohydrates: Scalars['float8'];
  created_at?: Maybe<Scalars['timestamptz']>;
  fats: Scalars['float8'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  product_category?: Maybe<IProductCategories>;
  product_category_id: Scalars['Int'];
  /** An object relationship */
  product_review?: Maybe<IProductsRgReview>;
  product_review_id?: Maybe<Scalars['Int']>;
  proteins: Scalars['float8'];
  slug: Scalars['String'];
  unit: Scalars['String'];
  units: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregated selection of "products_rg" */
export interface IProductsRgAggregate {
   __typename?: 'products_rg_aggregate';
  aggregate?: Maybe<IProductsRgAggregateFields>;
  nodes: Array<IProductsRg>;
}

/** aggregate fields of "products_rg" */
export interface IProductsRgAggregateFields {
   __typename?: 'products_rg_aggregate_fields';
  avg?: Maybe<IProductsRgAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProductsRgMaxFields>;
  min?: Maybe<IProductsRgMinFields>;
  stddev?: Maybe<IProductsRgStddevFields>;
  stddev_pop?: Maybe<IProductsRgStddevPopFields>;
  stddev_samp?: Maybe<IProductsRgStddevSampFields>;
  sum?: Maybe<IProductsRgSumFields>;
  var_pop?: Maybe<IProductsRgVarPopFields>;
  var_samp?: Maybe<IProductsRgVarSampFields>;
  variance?: Maybe<IProductsRgVarianceFields>;
}


/** aggregate fields of "products_rg" */
export interface IProductsRgAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProductsRgSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IProductsRgAvgFields {
   __typename?: 'products_rg_avg_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_category_id?: Maybe<Scalars['Float']>;
  product_review_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "products_rg". All fields are combined with a logical 'AND'. */
export interface IProductsRgBoolExp {
  _and?: Maybe<Array<IProductsRgBoolExp>>;
  _not?: Maybe<IProductsRgBoolExp>;
  _or?: Maybe<Array<IProductsRgBoolExp>>;
  brand?: Maybe<IStringComparisonExp>;
  calories?: Maybe<IFloat8ComparisonExp>;
  carbohydrates?: Maybe<IFloat8ComparisonExp>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  fats?: Maybe<IFloat8ComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  product_category?: Maybe<IProductCategoriesBoolExp>;
  product_category_id?: Maybe<IIntComparisonExp>;
  product_review?: Maybe<IProductsRgReviewBoolExp>;
  product_review_id?: Maybe<IIntComparisonExp>;
  proteins?: Maybe<IFloat8ComparisonExp>;
  slug?: Maybe<IStringComparisonExp>;
  unit?: Maybe<IStringComparisonExp>;
  units?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "products_rg" */
export enum IProductsRgConstraint {
  /** unique or primary key constraint */
  PRODUCTS_PKEY = 'products_pkey'
}

/** input type for incrementing numeric columns in table "products_rg" */
export interface IProductsRgIncInput {
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  product_category_id?: Maybe<Scalars['Int']>;
  product_review_id?: Maybe<Scalars['Int']>;
  proteins?: Maybe<Scalars['float8']>;
}

/** input type for inserting data into table "products_rg" */
export interface IProductsRgInsertInput {
  brand?: Maybe<Scalars['String']>;
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  product_category?: Maybe<IProductCategoriesObjRelInsertInput>;
  product_category_id?: Maybe<Scalars['Int']>;
  product_review?: Maybe<IProductsRgReviewObjRelInsertInput>;
  product_review_id?: Maybe<Scalars['Int']>;
  proteins?: Maybe<Scalars['float8']>;
  slug?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IProductsRgMaxFields {
   __typename?: 'products_rg_max_fields';
  brand?: Maybe<Scalars['String']>;
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  product_category_id?: Maybe<Scalars['Int']>;
  product_review_id?: Maybe<Scalars['Int']>;
  proteins?: Maybe<Scalars['float8']>;
  slug?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface IProductsRgMinFields {
   __typename?: 'products_rg_min_fields';
  brand?: Maybe<Scalars['String']>;
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  product_category_id?: Maybe<Scalars['Int']>;
  product_review_id?: Maybe<Scalars['Int']>;
  proteins?: Maybe<Scalars['float8']>;
  slug?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "products_rg" */
export interface IProductsRgMutationResponse {
   __typename?: 'products_rg_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IProductsRg>;
}

/** input type for inserting object relation for remote table "products_rg" */
export interface IProductsRgObjRelInsertInput {
  data: IProductsRgInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IProductsRgOnConflict>;
}

/** on conflict condition type for table "products_rg" */
export interface IProductsRgOnConflict {
  constraint: IProductsRgConstraint;
  update_columns: Array<IProductsRgUpdateColumn>;
  where?: Maybe<IProductsRgBoolExp>;
}

/** Ordering options when selecting data from "products_rg". */
export interface IProductsRgOrderBy {
  brand?: Maybe<IOrderBy>;
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  product_category?: Maybe<IProductCategoriesOrderBy>;
  product_category_id?: Maybe<IOrderBy>;
  product_review?: Maybe<IProductsRgReviewOrderBy>;
  product_review_id?: Maybe<IOrderBy>;
  proteins?: Maybe<IOrderBy>;
  slug?: Maybe<IOrderBy>;
  unit?: Maybe<IOrderBy>;
  units?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: products_rg */
export interface IProductsRgPkColumnsInput {
  id: Scalars['Int'];
}

/** columns and relationships of "products_rg_review" */
export interface IProductsRgReview {
   __typename?: 'products_rg_review';
  approved: Scalars['Boolean'];
  barcode: Scalars['String'];
  calories: Scalars['float8'];
  carbohydrates: Scalars['float8'];
  created_at: Scalars['timestamptz'];
  fats: Scalars['float8'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  product_rg?: Maybe<IProductsRg>;
  proteins: Scalars['float8'];
  unit: Scalars['String'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['Int'];
}

/** aggregated selection of "products_rg_review" */
export interface IProductsRgReviewAggregate {
   __typename?: 'products_rg_review_aggregate';
  aggregate?: Maybe<IProductsRgReviewAggregateFields>;
  nodes: Array<IProductsRgReview>;
}

/** aggregate fields of "products_rg_review" */
export interface IProductsRgReviewAggregateFields {
   __typename?: 'products_rg_review_aggregate_fields';
  avg?: Maybe<IProductsRgReviewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProductsRgReviewMaxFields>;
  min?: Maybe<IProductsRgReviewMinFields>;
  stddev?: Maybe<IProductsRgReviewStddevFields>;
  stddev_pop?: Maybe<IProductsRgReviewStddevPopFields>;
  stddev_samp?: Maybe<IProductsRgReviewStddevSampFields>;
  sum?: Maybe<IProductsRgReviewSumFields>;
  var_pop?: Maybe<IProductsRgReviewVarPopFields>;
  var_samp?: Maybe<IProductsRgReviewVarSampFields>;
  variance?: Maybe<IProductsRgReviewVarianceFields>;
}


/** aggregate fields of "products_rg_review" */
export interface IProductsRgReviewAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProductsRgReviewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IProductsRgReviewAvgFields {
   __typename?: 'products_rg_review_avg_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "products_rg_review". All fields are combined with a logical 'AND'. */
export interface IProductsRgReviewBoolExp {
  _and?: Maybe<Array<IProductsRgReviewBoolExp>>;
  _not?: Maybe<IProductsRgReviewBoolExp>;
  _or?: Maybe<Array<IProductsRgReviewBoolExp>>;
  approved?: Maybe<IBooleanComparisonExp>;
  barcode?: Maybe<IStringComparisonExp>;
  calories?: Maybe<IFloat8ComparisonExp>;
  carbohydrates?: Maybe<IFloat8ComparisonExp>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  fats?: Maybe<IFloat8ComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  product_rg?: Maybe<IProductsRgBoolExp>;
  proteins?: Maybe<IFloat8ComparisonExp>;
  unit?: Maybe<IStringComparisonExp>;
  user?: Maybe<IUsersBoolExp>;
  user_id?: Maybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "products_rg_review" */
export enum IProductsRgReviewConstraint {
  /** unique or primary key constraint */
  PRODUCTS_RG_REVIEW_PKEY = 'products_rg_review_pkey'
}

/** input type for incrementing numeric columns in table "products_rg_review" */
export interface IProductsRgReviewIncInput {
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  proteins?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "products_rg_review" */
export interface IProductsRgReviewInsertInput {
  approved?: Maybe<Scalars['Boolean']>;
  barcode?: Maybe<Scalars['String']>;
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  product_rg?: Maybe<IProductsRgObjRelInsertInput>;
  proteins?: Maybe<Scalars['float8']>;
  unit?: Maybe<Scalars['String']>;
  user?: Maybe<IUsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface IProductsRgReviewMaxFields {
   __typename?: 'products_rg_review_max_fields';
  barcode?: Maybe<Scalars['String']>;
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proteins?: Maybe<Scalars['float8']>;
  unit?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate min on columns */
export interface IProductsRgReviewMinFields {
   __typename?: 'products_rg_review_min_fields';
  barcode?: Maybe<Scalars['String']>;
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proteins?: Maybe<Scalars['float8']>;
  unit?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** response of any mutation on the table "products_rg_review" */
export interface IProductsRgReviewMutationResponse {
   __typename?: 'products_rg_review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IProductsRgReview>;
}

/** input type for inserting object relation for remote table "products_rg_review" */
export interface IProductsRgReviewObjRelInsertInput {
  data: IProductsRgReviewInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IProductsRgReviewOnConflict>;
}

/** on conflict condition type for table "products_rg_review" */
export interface IProductsRgReviewOnConflict {
  constraint: IProductsRgReviewConstraint;
  update_columns: Array<IProductsRgReviewUpdateColumn>;
  where?: Maybe<IProductsRgReviewBoolExp>;
}

/** Ordering options when selecting data from "products_rg_review". */
export interface IProductsRgReviewOrderBy {
  approved?: Maybe<IOrderBy>;
  barcode?: Maybe<IOrderBy>;
  calories?: Maybe<IOrderBy>;
  carbohydrates?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  fats?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  product_rg?: Maybe<IProductsRgOrderBy>;
  proteins?: Maybe<IOrderBy>;
  unit?: Maybe<IOrderBy>;
  user?: Maybe<IUsersOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** primary key columns input for table: products_rg_review */
export interface IProductsRgReviewPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "products_rg_review" */
export enum IProductsRgReviewSelectColumn {
  /** column name */
  APPROVED = 'approved',
  /** column name */
  BARCODE = 'barcode',
  /** column name */
  CALORIES = 'calories',
  /** column name */
  CARBOHYDRATES = 'carbohydrates',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FATS = 'fats',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PROTEINS = 'proteins',
  /** column name */
  UNIT = 'unit',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "products_rg_review" */
export interface IProductsRgReviewSetInput {
  approved?: Maybe<Scalars['Boolean']>;
  barcode?: Maybe<Scalars['String']>;
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proteins?: Maybe<Scalars['float8']>;
  unit?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface IProductsRgReviewStddevFields {
   __typename?: 'products_rg_review_stddev_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IProductsRgReviewStddevPopFields {
   __typename?: 'products_rg_review_stddev_pop_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IProductsRgReviewStddevSampFields {
   __typename?: 'products_rg_review_stddev_samp_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IProductsRgReviewSumFields {
   __typename?: 'products_rg_review_sum_fields';
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  proteins?: Maybe<Scalars['float8']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** update columns of table "products_rg_review" */
export enum IProductsRgReviewUpdateColumn {
  /** column name */
  APPROVED = 'approved',
  /** column name */
  BARCODE = 'barcode',
  /** column name */
  CALORIES = 'calories',
  /** column name */
  CARBOHYDRATES = 'carbohydrates',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FATS = 'fats',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PROTEINS = 'proteins',
  /** column name */
  UNIT = 'unit',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate var_pop on columns */
export interface IProductsRgReviewVarPopFields {
   __typename?: 'products_rg_review_var_pop_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IProductsRgReviewVarSampFields {
   __typename?: 'products_rg_review_var_samp_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IProductsRgReviewVarianceFields {
   __typename?: 'products_rg_review_variance_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** select columns of table "products_rg" */
export enum IProductsRgSelectColumn {
  /** column name */
  BRAND = 'brand',
  /** column name */
  CALORIES = 'calories',
  /** column name */
  CARBOHYDRATES = 'carbohydrates',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FATS = 'fats',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PRODUCT_CATEGORY_ID = 'product_category_id',
  /** column name */
  PRODUCT_REVIEW_ID = 'product_review_id',
  /** column name */
  PROTEINS = 'proteins',
  /** column name */
  SLUG = 'slug',
  /** column name */
  UNIT = 'unit',
  /** column name */
  UNITS = 'units',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "products_rg" */
export interface IProductsRgSetInput {
  brand?: Maybe<Scalars['String']>;
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  product_category_id?: Maybe<Scalars['Int']>;
  product_review_id?: Maybe<Scalars['Int']>;
  proteins?: Maybe<Scalars['float8']>;
  slug?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IProductsRgStddevFields {
   __typename?: 'products_rg_stddev_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_category_id?: Maybe<Scalars['Float']>;
  product_review_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IProductsRgStddevPopFields {
   __typename?: 'products_rg_stddev_pop_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_category_id?: Maybe<Scalars['Float']>;
  product_review_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IProductsRgStddevSampFields {
   __typename?: 'products_rg_stddev_samp_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_category_id?: Maybe<Scalars['Float']>;
  product_review_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IProductsRgSumFields {
   __typename?: 'products_rg_sum_fields';
  calories?: Maybe<Scalars['float8']>;
  carbohydrates?: Maybe<Scalars['float8']>;
  fats?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  product_category_id?: Maybe<Scalars['Int']>;
  product_review_id?: Maybe<Scalars['Int']>;
  proteins?: Maybe<Scalars['float8']>;
}

/** update columns of table "products_rg" */
export enum IProductsRgUpdateColumn {
  /** column name */
  BRAND = 'brand',
  /** column name */
  CALORIES = 'calories',
  /** column name */
  CARBOHYDRATES = 'carbohydrates',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  FATS = 'fats',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  PRODUCT_CATEGORY_ID = 'product_category_id',
  /** column name */
  PRODUCT_REVIEW_ID = 'product_review_id',
  /** column name */
  PROTEINS = 'proteins',
  /** column name */
  SLUG = 'slug',
  /** column name */
  UNIT = 'unit',
  /** column name */
  UNITS = 'units',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IProductsRgVarPopFields {
   __typename?: 'products_rg_var_pop_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_category_id?: Maybe<Scalars['Float']>;
  product_review_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IProductsRgVarSampFields {
   __typename?: 'products_rg_var_samp_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_category_id?: Maybe<Scalars['Float']>;
  product_review_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IProductsRgVarianceFields {
   __typename?: 'products_rg_variance_fields';
  calories?: Maybe<Scalars['Float']>;
  carbohydrates?: Maybe<Scalars['Float']>;
  fats?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_category_id?: Maybe<Scalars['Float']>;
  product_review_id?: Maybe<Scalars['Float']>;
  proteins?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "program_event_roles" */
export interface IProgramEventRoles {
   __typename?: 'program_event_roles';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  /** An object relationship */
  program_event: IProgramEvents;
  program_event_id: Scalars['bigint'];
  /** An object relationship */
  role: IRoles;
  role_id: Scalars['bigint'];
  updated_at: Scalars['timestamp'];
}

/** aggregated selection of "program_event_roles" */
export interface IProgramEventRolesAggregate {
   __typename?: 'program_event_roles_aggregate';
  aggregate?: Maybe<IProgramEventRolesAggregateFields>;
  nodes: Array<IProgramEventRoles>;
}

/** aggregate fields of "program_event_roles" */
export interface IProgramEventRolesAggregateFields {
   __typename?: 'program_event_roles_aggregate_fields';
  avg?: Maybe<IProgramEventRolesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProgramEventRolesMaxFields>;
  min?: Maybe<IProgramEventRolesMinFields>;
  stddev?: Maybe<IProgramEventRolesStddevFields>;
  stddev_pop?: Maybe<IProgramEventRolesStddevPopFields>;
  stddev_samp?: Maybe<IProgramEventRolesStddevSampFields>;
  sum?: Maybe<IProgramEventRolesSumFields>;
  var_pop?: Maybe<IProgramEventRolesVarPopFields>;
  var_samp?: Maybe<IProgramEventRolesVarSampFields>;
  variance?: Maybe<IProgramEventRolesVarianceFields>;
}


/** aggregate fields of "program_event_roles" */
export interface IProgramEventRolesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProgramEventRolesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "program_event_roles" */
export interface IProgramEventRolesAggregateOrderBy {
  avg?: Maybe<IProgramEventRolesAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IProgramEventRolesMaxOrderBy>;
  min?: Maybe<IProgramEventRolesMinOrderBy>;
  stddev?: Maybe<IProgramEventRolesStddevOrderBy>;
  stddev_pop?: Maybe<IProgramEventRolesStddevPopOrderBy>;
  stddev_samp?: Maybe<IProgramEventRolesStddevSampOrderBy>;
  sum?: Maybe<IProgramEventRolesSumOrderBy>;
  var_pop?: Maybe<IProgramEventRolesVarPopOrderBy>;
  var_samp?: Maybe<IProgramEventRolesVarSampOrderBy>;
  variance?: Maybe<IProgramEventRolesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "program_event_roles" */
export interface IProgramEventRolesArrRelInsertInput {
  data: Array<IProgramEventRolesInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IProgramEventRolesOnConflict>;
}

/** aggregate avg on columns */
export interface IProgramEventRolesAvgFields {
   __typename?: 'program_event_roles_avg_fields';
  id?: Maybe<Scalars['Float']>;
  program_event_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "program_event_roles" */
export interface IProgramEventRolesAvgOrderBy {
  id?: Maybe<IOrderBy>;
  program_event_id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "program_event_roles". All fields are combined with a logical 'AND'. */
export interface IProgramEventRolesBoolExp {
  _and?: Maybe<Array<IProgramEventRolesBoolExp>>;
  _not?: Maybe<IProgramEventRolesBoolExp>;
  _or?: Maybe<Array<IProgramEventRolesBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  program_event?: Maybe<IProgramEventsBoolExp>;
  program_event_id?: Maybe<IBigintComparisonExp>;
  role?: Maybe<IRolesBoolExp>;
  role_id?: Maybe<IBigintComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "program_event_roles" */
export enum IProgramEventRolesConstraint {
  /** unique or primary key constraint */
  PROGRAM_EVENT_ROLES_PKEY = 'program_event_roles_pkey'
}

/** input type for incrementing numeric columns in table "program_event_roles" */
export interface IProgramEventRolesIncInput {
  id?: Maybe<Scalars['bigint']>;
  program_event_id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "program_event_roles" */
export interface IProgramEventRolesInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  program_event?: Maybe<IProgramEventsObjRelInsertInput>;
  program_event_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<IRolesObjRelInsertInput>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IProgramEventRolesMaxFields {
   __typename?: 'program_event_roles_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  program_event_id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by max() on columns of table "program_event_roles" */
export interface IProgramEventRolesMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_event_id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IProgramEventRolesMinFields {
   __typename?: 'program_event_roles_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  program_event_id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by min() on columns of table "program_event_roles" */
export interface IProgramEventRolesMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_event_id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "program_event_roles" */
export interface IProgramEventRolesMutationResponse {
   __typename?: 'program_event_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IProgramEventRoles>;
}

/** on conflict condition type for table "program_event_roles" */
export interface IProgramEventRolesOnConflict {
  constraint: IProgramEventRolesConstraint;
  update_columns: Array<IProgramEventRolesUpdateColumn>;
  where?: Maybe<IProgramEventRolesBoolExp>;
}

/** Ordering options when selecting data from "program_event_roles". */
export interface IProgramEventRolesOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_event?: Maybe<IProgramEventsOrderBy>;
  program_event_id?: Maybe<IOrderBy>;
  role?: Maybe<IRolesOrderBy>;
  role_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: program_event_roles */
export interface IProgramEventRolesPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "program_event_roles" */
export enum IProgramEventRolesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  PROGRAM_EVENT_ID = 'program_event_id',
  /** column name */
  ROLE_ID = 'role_id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "program_event_roles" */
export interface IProgramEventRolesSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  program_event_id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IProgramEventRolesStddevFields {
   __typename?: 'program_event_roles_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  program_event_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "program_event_roles" */
export interface IProgramEventRolesStddevOrderBy {
  id?: Maybe<IOrderBy>;
  program_event_id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IProgramEventRolesStddevPopFields {
   __typename?: 'program_event_roles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  program_event_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "program_event_roles" */
export interface IProgramEventRolesStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  program_event_id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IProgramEventRolesStddevSampFields {
   __typename?: 'program_event_roles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  program_event_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "program_event_roles" */
export interface IProgramEventRolesStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  program_event_id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IProgramEventRolesSumFields {
   __typename?: 'program_event_roles_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  program_event_id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "program_event_roles" */
export interface IProgramEventRolesSumOrderBy {
  id?: Maybe<IOrderBy>;
  program_event_id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
}

/** update columns of table "program_event_roles" */
export enum IProgramEventRolesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  PROGRAM_EVENT_ID = 'program_event_id',
  /** column name */
  ROLE_ID = 'role_id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IProgramEventRolesVarPopFields {
   __typename?: 'program_event_roles_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  program_event_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "program_event_roles" */
export interface IProgramEventRolesVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  program_event_id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IProgramEventRolesVarSampFields {
   __typename?: 'program_event_roles_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  program_event_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "program_event_roles" */
export interface IProgramEventRolesVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  program_event_id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IProgramEventRolesVarianceFields {
   __typename?: 'program_event_roles_variance_fields';
  id?: Maybe<Scalars['Float']>;
  program_event_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "program_event_roles" */
export interface IProgramEventRolesVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  program_event_id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "program_events" */
export interface IProgramEvents {
   __typename?: 'program_events';
  created_at: Scalars['timestamp'];
  day_of_week?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['time']>;
  id: Scalars['bigint'];
  moment_count?: Maybe<Scalars['Int']>;
  moment_position?: Maybe<Scalars['String']>;
  moment_program_time?: Maybe<Scalars['String']>;
  moment_unit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  program_event_roles: Array<IProgramEventRoles>;
  /** An aggregate relationship */
  program_event_roles_aggregate: IProgramEventRolesAggregate;
  program_id?: Maybe<Scalars['bigint']>;
  recurring_event?: Maybe<Scalars['Boolean']>;
  start_time?: Maybe<Scalars['time']>;
  updated_at: Scalars['timestamp'];
}


/** columns and relationships of "program_events" */
export interface IProgramEventsProgramEventRolesArgs {
  distinct_on?: Maybe<Array<IProgramEventRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramEventRolesOrderBy>>;
  where?: Maybe<IProgramEventRolesBoolExp>;
}


/** columns and relationships of "program_events" */
export interface IProgramEventsProgramEventRolesAggregateArgs {
  distinct_on?: Maybe<Array<IProgramEventRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramEventRolesOrderBy>>;
  where?: Maybe<IProgramEventRolesBoolExp>;
}

/** aggregated selection of "program_events" */
export interface IProgramEventsAggregate {
   __typename?: 'program_events_aggregate';
  aggregate?: Maybe<IProgramEventsAggregateFields>;
  nodes: Array<IProgramEvents>;
}

/** aggregate fields of "program_events" */
export interface IProgramEventsAggregateFields {
   __typename?: 'program_events_aggregate_fields';
  avg?: Maybe<IProgramEventsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProgramEventsMaxFields>;
  min?: Maybe<IProgramEventsMinFields>;
  stddev?: Maybe<IProgramEventsStddevFields>;
  stddev_pop?: Maybe<IProgramEventsStddevPopFields>;
  stddev_samp?: Maybe<IProgramEventsStddevSampFields>;
  sum?: Maybe<IProgramEventsSumFields>;
  var_pop?: Maybe<IProgramEventsVarPopFields>;
  var_samp?: Maybe<IProgramEventsVarSampFields>;
  variance?: Maybe<IProgramEventsVarianceFields>;
}


/** aggregate fields of "program_events" */
export interface IProgramEventsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProgramEventsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IProgramEventsAvgFields {
   __typename?: 'program_events_avg_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "program_events". All fields are combined with a logical 'AND'. */
export interface IProgramEventsBoolExp {
  _and?: Maybe<Array<IProgramEventsBoolExp>>;
  _not?: Maybe<IProgramEventsBoolExp>;
  _or?: Maybe<Array<IProgramEventsBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  day_of_week?: Maybe<IStringComparisonExp>;
  end_time?: Maybe<ITimeComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  moment_count?: Maybe<IIntComparisonExp>;
  moment_position?: Maybe<IStringComparisonExp>;
  moment_program_time?: Maybe<IStringComparisonExp>;
  moment_unit?: Maybe<IStringComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  program_event_roles?: Maybe<IProgramEventRolesBoolExp>;
  program_id?: Maybe<IBigintComparisonExp>;
  recurring_event?: Maybe<IBooleanComparisonExp>;
  start_time?: Maybe<ITimeComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "program_events" */
export enum IProgramEventsConstraint {
  /** unique or primary key constraint */
  PROGRAM_EVENTS_PKEY = 'program_events_pkey'
}

/** input type for incrementing numeric columns in table "program_events" */
export interface IProgramEventsIncInput {
  id?: Maybe<Scalars['bigint']>;
  moment_count?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "program_events" */
export interface IProgramEventsInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  day_of_week?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['time']>;
  id?: Maybe<Scalars['bigint']>;
  moment_count?: Maybe<Scalars['Int']>;
  moment_position?: Maybe<Scalars['String']>;
  moment_program_time?: Maybe<Scalars['String']>;
  moment_unit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  program_event_roles?: Maybe<IProgramEventRolesArrRelInsertInput>;
  program_id?: Maybe<Scalars['bigint']>;
  recurring_event?: Maybe<Scalars['Boolean']>;
  start_time?: Maybe<Scalars['time']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IProgramEventsMaxFields {
   __typename?: 'program_events_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  day_of_week?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  moment_count?: Maybe<Scalars['Int']>;
  moment_position?: Maybe<Scalars['String']>;
  moment_program_time?: Maybe<Scalars['String']>;
  moment_unit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate min on columns */
export interface IProgramEventsMinFields {
   __typename?: 'program_events_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  day_of_week?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  moment_count?: Maybe<Scalars['Int']>;
  moment_position?: Maybe<Scalars['String']>;
  moment_program_time?: Maybe<Scalars['String']>;
  moment_unit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** response of any mutation on the table "program_events" */
export interface IProgramEventsMutationResponse {
   __typename?: 'program_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IProgramEvents>;
}

/** input type for inserting object relation for remote table "program_events" */
export interface IProgramEventsObjRelInsertInput {
  data: IProgramEventsInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IProgramEventsOnConflict>;
}

/** on conflict condition type for table "program_events" */
export interface IProgramEventsOnConflict {
  constraint: IProgramEventsConstraint;
  update_columns: Array<IProgramEventsUpdateColumn>;
  where?: Maybe<IProgramEventsBoolExp>;
}

/** Ordering options when selecting data from "program_events". */
export interface IProgramEventsOrderBy {
  created_at?: Maybe<IOrderBy>;
  day_of_week?: Maybe<IOrderBy>;
  end_time?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  moment_count?: Maybe<IOrderBy>;
  moment_position?: Maybe<IOrderBy>;
  moment_program_time?: Maybe<IOrderBy>;
  moment_unit?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  program_event_roles_aggregate?: Maybe<IProgramEventRolesAggregateOrderBy>;
  program_id?: Maybe<IOrderBy>;
  recurring_event?: Maybe<IOrderBy>;
  start_time?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: program_events */
export interface IProgramEventsPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "program_events" */
export enum IProgramEventsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DAY_OF_WEEK = 'day_of_week',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  ID = 'id',
  /** column name */
  MOMENT_COUNT = 'moment_count',
  /** column name */
  MOMENT_POSITION = 'moment_position',
  /** column name */
  MOMENT_PROGRAM_TIME = 'moment_program_time',
  /** column name */
  MOMENT_UNIT = 'moment_unit',
  /** column name */
  NAME = 'name',
  /** column name */
  PROGRAM_ID = 'program_id',
  /** column name */
  RECURRING_EVENT = 'recurring_event',
  /** column name */
  START_TIME = 'start_time',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "program_events" */
export interface IProgramEventsSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  day_of_week?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['time']>;
  id?: Maybe<Scalars['bigint']>;
  moment_count?: Maybe<Scalars['Int']>;
  moment_position?: Maybe<Scalars['String']>;
  moment_program_time?: Maybe<Scalars['String']>;
  moment_unit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  recurring_event?: Maybe<Scalars['Boolean']>;
  start_time?: Maybe<Scalars['time']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IProgramEventsStddevFields {
   __typename?: 'program_events_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IProgramEventsStddevPopFields {
   __typename?: 'program_events_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IProgramEventsStddevSampFields {
   __typename?: 'program_events_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IProgramEventsSumFields {
   __typename?: 'program_events_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  moment_count?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['bigint']>;
}

/** update columns of table "program_events" */
export enum IProgramEventsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DAY_OF_WEEK = 'day_of_week',
  /** column name */
  END_TIME = 'end_time',
  /** column name */
  ID = 'id',
  /** column name */
  MOMENT_COUNT = 'moment_count',
  /** column name */
  MOMENT_POSITION = 'moment_position',
  /** column name */
  MOMENT_PROGRAM_TIME = 'moment_program_time',
  /** column name */
  MOMENT_UNIT = 'moment_unit',
  /** column name */
  NAME = 'name',
  /** column name */
  PROGRAM_ID = 'program_id',
  /** column name */
  RECURRING_EVENT = 'recurring_event',
  /** column name */
  START_TIME = 'start_time',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IProgramEventsVarPopFields {
   __typename?: 'program_events_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IProgramEventsVarSampFields {
   __typename?: 'program_events_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IProgramEventsVarianceFields {
   __typename?: 'program_events_variance_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "program_goals" */
export interface IProgramGoals {
   __typename?: 'program_goals';
  created_at: Scalars['timestamptz'];
  goal_amount: Scalars['Int'];
  goal_description: Scalars['String'];
  goal_unit: Scalars['String'];
  id: Scalars['uuid'];
  period_amount: Scalars['Int'];
  period_unit: Scalars['String'];
  /** An object relationship */
  program_plan_participator?: Maybe<IProgramPlanParticipators>;
  program_plan_participator_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "program_goals" */
export interface IProgramGoalsAggregate {
   __typename?: 'program_goals_aggregate';
  aggregate?: Maybe<IProgramGoalsAggregateFields>;
  nodes: Array<IProgramGoals>;
}

/** aggregate fields of "program_goals" */
export interface IProgramGoalsAggregateFields {
   __typename?: 'program_goals_aggregate_fields';
  avg?: Maybe<IProgramGoalsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProgramGoalsMaxFields>;
  min?: Maybe<IProgramGoalsMinFields>;
  stddev?: Maybe<IProgramGoalsStddevFields>;
  stddev_pop?: Maybe<IProgramGoalsStddevPopFields>;
  stddev_samp?: Maybe<IProgramGoalsStddevSampFields>;
  sum?: Maybe<IProgramGoalsSumFields>;
  var_pop?: Maybe<IProgramGoalsVarPopFields>;
  var_samp?: Maybe<IProgramGoalsVarSampFields>;
  variance?: Maybe<IProgramGoalsVarianceFields>;
}


/** aggregate fields of "program_goals" */
export interface IProgramGoalsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProgramGoalsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "program_goals" */
export interface IProgramGoalsAggregateOrderBy {
  avg?: Maybe<IProgramGoalsAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IProgramGoalsMaxOrderBy>;
  min?: Maybe<IProgramGoalsMinOrderBy>;
  stddev?: Maybe<IProgramGoalsStddevOrderBy>;
  stddev_pop?: Maybe<IProgramGoalsStddevPopOrderBy>;
  stddev_samp?: Maybe<IProgramGoalsStddevSampOrderBy>;
  sum?: Maybe<IProgramGoalsSumOrderBy>;
  var_pop?: Maybe<IProgramGoalsVarPopOrderBy>;
  var_samp?: Maybe<IProgramGoalsVarSampOrderBy>;
  variance?: Maybe<IProgramGoalsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "program_goals" */
export interface IProgramGoalsArrRelInsertInput {
  data: Array<IProgramGoalsInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IProgramGoalsOnConflict>;
}

/** aggregate avg on columns */
export interface IProgramGoalsAvgFields {
   __typename?: 'program_goals_avg_fields';
  goal_amount?: Maybe<Scalars['Float']>;
  period_amount?: Maybe<Scalars['Float']>;
  program_plan_participator_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "program_goals" */
export interface IProgramGoalsAvgOrderBy {
  goal_amount?: Maybe<IOrderBy>;
  period_amount?: Maybe<IOrderBy>;
  program_plan_participator_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "program_goals". All fields are combined with a logical 'AND'. */
export interface IProgramGoalsBoolExp {
  _and?: Maybe<Array<IProgramGoalsBoolExp>>;
  _not?: Maybe<IProgramGoalsBoolExp>;
  _or?: Maybe<Array<IProgramGoalsBoolExp>>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  goal_amount?: Maybe<IIntComparisonExp>;
  goal_description?: Maybe<IStringComparisonExp>;
  goal_unit?: Maybe<IStringComparisonExp>;
  id?: Maybe<IUuidComparisonExp>;
  period_amount?: Maybe<IIntComparisonExp>;
  period_unit?: Maybe<IStringComparisonExp>;
  program_plan_participator?: Maybe<IProgramPlanParticipatorsBoolExp>;
  program_plan_participator_id?: Maybe<IIntComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "program_goals" */
export enum IProgramGoalsConstraint {
  /** unique or primary key constraint */
  PROGRAM_GOALS_PKEY = 'program_goals_pkey'
}

/** input type for incrementing numeric columns in table "program_goals" */
export interface IProgramGoalsIncInput {
  goal_amount?: Maybe<Scalars['Int']>;
  period_amount?: Maybe<Scalars['Int']>;
  program_plan_participator_id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "program_goals" */
export interface IProgramGoalsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  goal_amount?: Maybe<Scalars['Int']>;
  goal_description?: Maybe<Scalars['String']>;
  goal_unit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  period_amount?: Maybe<Scalars['Int']>;
  period_unit?: Maybe<Scalars['String']>;
  program_plan_participator?: Maybe<IProgramPlanParticipatorsObjRelInsertInput>;
  program_plan_participator_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface IProgramGoalsMaxFields {
   __typename?: 'program_goals_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  goal_amount?: Maybe<Scalars['Int']>;
  goal_description?: Maybe<Scalars['String']>;
  goal_unit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  period_amount?: Maybe<Scalars['Int']>;
  period_unit?: Maybe<Scalars['String']>;
  program_plan_participator_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "program_goals" */
export interface IProgramGoalsMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  goal_amount?: Maybe<IOrderBy>;
  goal_description?: Maybe<IOrderBy>;
  goal_unit?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  period_amount?: Maybe<IOrderBy>;
  period_unit?: Maybe<IOrderBy>;
  program_plan_participator_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IProgramGoalsMinFields {
   __typename?: 'program_goals_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  goal_amount?: Maybe<Scalars['Int']>;
  goal_description?: Maybe<Scalars['String']>;
  goal_unit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  period_amount?: Maybe<Scalars['Int']>;
  period_unit?: Maybe<Scalars['String']>;
  program_plan_participator_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "program_goals" */
export interface IProgramGoalsMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  goal_amount?: Maybe<IOrderBy>;
  goal_description?: Maybe<IOrderBy>;
  goal_unit?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  period_amount?: Maybe<IOrderBy>;
  period_unit?: Maybe<IOrderBy>;
  program_plan_participator_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "program_goals" */
export interface IProgramGoalsMutationResponse {
   __typename?: 'program_goals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IProgramGoals>;
}

/** on conflict condition type for table "program_goals" */
export interface IProgramGoalsOnConflict {
  constraint: IProgramGoalsConstraint;
  update_columns: Array<IProgramGoalsUpdateColumn>;
  where?: Maybe<IProgramGoalsBoolExp>;
}

/** Ordering options when selecting data from "program_goals". */
export interface IProgramGoalsOrderBy {
  created_at?: Maybe<IOrderBy>;
  goal_amount?: Maybe<IOrderBy>;
  goal_description?: Maybe<IOrderBy>;
  goal_unit?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  period_amount?: Maybe<IOrderBy>;
  period_unit?: Maybe<IOrderBy>;
  program_plan_participator?: Maybe<IProgramPlanParticipatorsOrderBy>;
  program_plan_participator_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: program_goals */
export interface IProgramGoalsPkColumnsInput {
  id: Scalars['uuid'];
}

/** select columns of table "program_goals" */
export enum IProgramGoalsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  GOAL_AMOUNT = 'goal_amount',
  /** column name */
  GOAL_DESCRIPTION = 'goal_description',
  /** column name */
  GOAL_UNIT = 'goal_unit',
  /** column name */
  ID = 'id',
  /** column name */
  PERIOD_AMOUNT = 'period_amount',
  /** column name */
  PERIOD_UNIT = 'period_unit',
  /** column name */
  PROGRAM_PLAN_PARTICIPATOR_ID = 'program_plan_participator_id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "program_goals" */
export interface IProgramGoalsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  goal_amount?: Maybe<Scalars['Int']>;
  goal_description?: Maybe<Scalars['String']>;
  goal_unit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  period_amount?: Maybe<Scalars['Int']>;
  period_unit?: Maybe<Scalars['String']>;
  program_plan_participator_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface IProgramGoalsStddevFields {
   __typename?: 'program_goals_stddev_fields';
  goal_amount?: Maybe<Scalars['Float']>;
  period_amount?: Maybe<Scalars['Float']>;
  program_plan_participator_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "program_goals" */
export interface IProgramGoalsStddevOrderBy {
  goal_amount?: Maybe<IOrderBy>;
  period_amount?: Maybe<IOrderBy>;
  program_plan_participator_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IProgramGoalsStddevPopFields {
   __typename?: 'program_goals_stddev_pop_fields';
  goal_amount?: Maybe<Scalars['Float']>;
  period_amount?: Maybe<Scalars['Float']>;
  program_plan_participator_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "program_goals" */
export interface IProgramGoalsStddevPopOrderBy {
  goal_amount?: Maybe<IOrderBy>;
  period_amount?: Maybe<IOrderBy>;
  program_plan_participator_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IProgramGoalsStddevSampFields {
   __typename?: 'program_goals_stddev_samp_fields';
  goal_amount?: Maybe<Scalars['Float']>;
  period_amount?: Maybe<Scalars['Float']>;
  program_plan_participator_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "program_goals" */
export interface IProgramGoalsStddevSampOrderBy {
  goal_amount?: Maybe<IOrderBy>;
  period_amount?: Maybe<IOrderBy>;
  program_plan_participator_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IProgramGoalsSumFields {
   __typename?: 'program_goals_sum_fields';
  goal_amount?: Maybe<Scalars['Int']>;
  period_amount?: Maybe<Scalars['Int']>;
  program_plan_participator_id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "program_goals" */
export interface IProgramGoalsSumOrderBy {
  goal_amount?: Maybe<IOrderBy>;
  period_amount?: Maybe<IOrderBy>;
  program_plan_participator_id?: Maybe<IOrderBy>;
}

/** update columns of table "program_goals" */
export enum IProgramGoalsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  GOAL_AMOUNT = 'goal_amount',
  /** column name */
  GOAL_DESCRIPTION = 'goal_description',
  /** column name */
  GOAL_UNIT = 'goal_unit',
  /** column name */
  ID = 'id',
  /** column name */
  PERIOD_AMOUNT = 'period_amount',
  /** column name */
  PERIOD_UNIT = 'period_unit',
  /** column name */
  PROGRAM_PLAN_PARTICIPATOR_ID = 'program_plan_participator_id',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IProgramGoalsVarPopFields {
   __typename?: 'program_goals_var_pop_fields';
  goal_amount?: Maybe<Scalars['Float']>;
  period_amount?: Maybe<Scalars['Float']>;
  program_plan_participator_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "program_goals" */
export interface IProgramGoalsVarPopOrderBy {
  goal_amount?: Maybe<IOrderBy>;
  period_amount?: Maybe<IOrderBy>;
  program_plan_participator_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IProgramGoalsVarSampFields {
   __typename?: 'program_goals_var_samp_fields';
  goal_amount?: Maybe<Scalars['Float']>;
  period_amount?: Maybe<Scalars['Float']>;
  program_plan_participator_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "program_goals" */
export interface IProgramGoalsVarSampOrderBy {
  goal_amount?: Maybe<IOrderBy>;
  period_amount?: Maybe<IOrderBy>;
  program_plan_participator_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IProgramGoalsVarianceFields {
   __typename?: 'program_goals_variance_fields';
  goal_amount?: Maybe<Scalars['Float']>;
  period_amount?: Maybe<Scalars['Float']>;
  program_plan_participator_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "program_goals" */
export interface IProgramGoalsVarianceOrderBy {
  goal_amount?: Maybe<IOrderBy>;
  period_amount?: Maybe<IOrderBy>;
  program_plan_participator_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "program_messages" */
export interface IProgramMessages {
   __typename?: 'program_messages';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  message_type?: Maybe<Scalars['String']>;
  /** An array relationship */
  messages: Array<IMessages>;
  /** An aggregate relationship */
  messages_aggregate: IMessagesAggregate;
  moment_count?: Maybe<Scalars['Int']>;
  moment_position?: Maybe<Scalars['String']>;
  moment_program_time?: Maybe<Scalars['String']>;
  moment_unit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  program: IPrograms;
  program_id: Scalars['bigint'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
}


/** columns and relationships of "program_messages" */
export interface IProgramMessagesMessagesArgs {
  distinct_on?: Maybe<Array<IMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMessagesOrderBy>>;
  where?: Maybe<IMessagesBoolExp>;
}


/** columns and relationships of "program_messages" */
export interface IProgramMessagesMessagesAggregateArgs {
  distinct_on?: Maybe<Array<IMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMessagesOrderBy>>;
  where?: Maybe<IMessagesBoolExp>;
}

/** aggregated selection of "program_messages" */
export interface IProgramMessagesAggregate {
   __typename?: 'program_messages_aggregate';
  aggregate?: Maybe<IProgramMessagesAggregateFields>;
  nodes: Array<IProgramMessages>;
}

/** aggregate fields of "program_messages" */
export interface IProgramMessagesAggregateFields {
   __typename?: 'program_messages_aggregate_fields';
  avg?: Maybe<IProgramMessagesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProgramMessagesMaxFields>;
  min?: Maybe<IProgramMessagesMinFields>;
  stddev?: Maybe<IProgramMessagesStddevFields>;
  stddev_pop?: Maybe<IProgramMessagesStddevPopFields>;
  stddev_samp?: Maybe<IProgramMessagesStddevSampFields>;
  sum?: Maybe<IProgramMessagesSumFields>;
  var_pop?: Maybe<IProgramMessagesVarPopFields>;
  var_samp?: Maybe<IProgramMessagesVarSampFields>;
  variance?: Maybe<IProgramMessagesVarianceFields>;
}


/** aggregate fields of "program_messages" */
export interface IProgramMessagesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProgramMessagesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "program_messages" */
export interface IProgramMessagesAggregateOrderBy {
  avg?: Maybe<IProgramMessagesAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IProgramMessagesMaxOrderBy>;
  min?: Maybe<IProgramMessagesMinOrderBy>;
  stddev?: Maybe<IProgramMessagesStddevOrderBy>;
  stddev_pop?: Maybe<IProgramMessagesStddevPopOrderBy>;
  stddev_samp?: Maybe<IProgramMessagesStddevSampOrderBy>;
  sum?: Maybe<IProgramMessagesSumOrderBy>;
  var_pop?: Maybe<IProgramMessagesVarPopOrderBy>;
  var_samp?: Maybe<IProgramMessagesVarSampOrderBy>;
  variance?: Maybe<IProgramMessagesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "program_messages" */
export interface IProgramMessagesArrRelInsertInput {
  data: Array<IProgramMessagesInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IProgramMessagesOnConflict>;
}

/** aggregate avg on columns */
export interface IProgramMessagesAvgFields {
   __typename?: 'program_messages_avg_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "program_messages" */
export interface IProgramMessagesAvgOrderBy {
  id?: Maybe<IOrderBy>;
  moment_count?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "program_messages". All fields are combined with a logical 'AND'. */
export interface IProgramMessagesBoolExp {
  _and?: Maybe<Array<IProgramMessagesBoolExp>>;
  _not?: Maybe<IProgramMessagesBoolExp>;
  _or?: Maybe<Array<IProgramMessagesBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  message_type?: Maybe<IStringComparisonExp>;
  messages?: Maybe<IMessagesBoolExp>;
  moment_count?: Maybe<IIntComparisonExp>;
  moment_position?: Maybe<IStringComparisonExp>;
  moment_program_time?: Maybe<IStringComparisonExp>;
  moment_unit?: Maybe<IStringComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  program?: Maybe<IProgramsBoolExp>;
  program_id?: Maybe<IBigintComparisonExp>;
  title?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "program_messages" */
export enum IProgramMessagesConstraint {
  /** unique or primary key constraint */
  PROGRAM_MESSAGES_PKEY = 'program_messages_pkey'
}

/** input type for incrementing numeric columns in table "program_messages" */
export interface IProgramMessagesIncInput {
  id?: Maybe<Scalars['bigint']>;
  moment_count?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "program_messages" */
export interface IProgramMessagesInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  message_type?: Maybe<Scalars['String']>;
  messages?: Maybe<IMessagesArrRelInsertInput>;
  moment_count?: Maybe<Scalars['Int']>;
  moment_position?: Maybe<Scalars['String']>;
  moment_program_time?: Maybe<Scalars['String']>;
  moment_unit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  program?: Maybe<IProgramsObjRelInsertInput>;
  program_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IProgramMessagesMaxFields {
   __typename?: 'program_messages_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  message_type?: Maybe<Scalars['String']>;
  moment_count?: Maybe<Scalars['Int']>;
  moment_position?: Maybe<Scalars['String']>;
  moment_program_time?: Maybe<Scalars['String']>;
  moment_unit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by max() on columns of table "program_messages" */
export interface IProgramMessagesMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  message_type?: Maybe<IOrderBy>;
  moment_count?: Maybe<IOrderBy>;
  moment_position?: Maybe<IOrderBy>;
  moment_program_time?: Maybe<IOrderBy>;
  moment_unit?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
  title?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IProgramMessagesMinFields {
   __typename?: 'program_messages_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  message_type?: Maybe<Scalars['String']>;
  moment_count?: Maybe<Scalars['Int']>;
  moment_position?: Maybe<Scalars['String']>;
  moment_program_time?: Maybe<Scalars['String']>;
  moment_unit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by min() on columns of table "program_messages" */
export interface IProgramMessagesMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  message_type?: Maybe<IOrderBy>;
  moment_count?: Maybe<IOrderBy>;
  moment_position?: Maybe<IOrderBy>;
  moment_program_time?: Maybe<IOrderBy>;
  moment_unit?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
  title?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "program_messages" */
export interface IProgramMessagesMutationResponse {
   __typename?: 'program_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IProgramMessages>;
}

/** input type for inserting object relation for remote table "program_messages" */
export interface IProgramMessagesObjRelInsertInput {
  data: IProgramMessagesInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IProgramMessagesOnConflict>;
}

/** on conflict condition type for table "program_messages" */
export interface IProgramMessagesOnConflict {
  constraint: IProgramMessagesConstraint;
  update_columns: Array<IProgramMessagesUpdateColumn>;
  where?: Maybe<IProgramMessagesBoolExp>;
}

/** Ordering options when selecting data from "program_messages". */
export interface IProgramMessagesOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  message_type?: Maybe<IOrderBy>;
  messages_aggregate?: Maybe<IMessagesAggregateOrderBy>;
  moment_count?: Maybe<IOrderBy>;
  moment_position?: Maybe<IOrderBy>;
  moment_program_time?: Maybe<IOrderBy>;
  moment_unit?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  program?: Maybe<IProgramsOrderBy>;
  program_id?: Maybe<IOrderBy>;
  title?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: program_messages */
export interface IProgramMessagesPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "program_messages" */
export enum IProgramMessagesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  MESSAGE_TYPE = 'message_type',
  /** column name */
  MOMENT_COUNT = 'moment_count',
  /** column name */
  MOMENT_POSITION = 'moment_position',
  /** column name */
  MOMENT_PROGRAM_TIME = 'moment_program_time',
  /** column name */
  MOMENT_UNIT = 'moment_unit',
  /** column name */
  NAME = 'name',
  /** column name */
  PROGRAM_ID = 'program_id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "program_messages" */
export interface IProgramMessagesSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  message_type?: Maybe<Scalars['String']>;
  moment_count?: Maybe<Scalars['Int']>;
  moment_position?: Maybe<Scalars['String']>;
  moment_program_time?: Maybe<Scalars['String']>;
  moment_unit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IProgramMessagesStddevFields {
   __typename?: 'program_messages_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "program_messages" */
export interface IProgramMessagesStddevOrderBy {
  id?: Maybe<IOrderBy>;
  moment_count?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IProgramMessagesStddevPopFields {
   __typename?: 'program_messages_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "program_messages" */
export interface IProgramMessagesStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  moment_count?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IProgramMessagesStddevSampFields {
   __typename?: 'program_messages_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "program_messages" */
export interface IProgramMessagesStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  moment_count?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IProgramMessagesSumFields {
   __typename?: 'program_messages_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  moment_count?: Maybe<Scalars['Int']>;
  program_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "program_messages" */
export interface IProgramMessagesSumOrderBy {
  id?: Maybe<IOrderBy>;
  moment_count?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** update columns of table "program_messages" */
export enum IProgramMessagesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  MESSAGE_TYPE = 'message_type',
  /** column name */
  MOMENT_COUNT = 'moment_count',
  /** column name */
  MOMENT_POSITION = 'moment_position',
  /** column name */
  MOMENT_PROGRAM_TIME = 'moment_program_time',
  /** column name */
  MOMENT_UNIT = 'moment_unit',
  /** column name */
  NAME = 'name',
  /** column name */
  PROGRAM_ID = 'program_id',
  /** column name */
  TITLE = 'title',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IProgramMessagesVarPopFields {
   __typename?: 'program_messages_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "program_messages" */
export interface IProgramMessagesVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  moment_count?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IProgramMessagesVarSampFields {
   __typename?: 'program_messages_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "program_messages" */
export interface IProgramMessagesVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  moment_count?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IProgramMessagesVarianceFields {
   __typename?: 'program_messages_variance_fields';
  id?: Maybe<Scalars['Float']>;
  moment_count?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "program_messages" */
export interface IProgramMessagesVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  moment_count?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "program_plan_employees" */
export interface IProgramPlanEmployees {
   __typename?: 'program_plan_employees';
  created_at: Scalars['timestamp'];
  employee_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  program_plan: IProgramPlans;
  program_plan_id: Scalars['bigint'];
  state?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user: IUsers;
}

/** aggregated selection of "program_plan_employees" */
export interface IProgramPlanEmployeesAggregate {
   __typename?: 'program_plan_employees_aggregate';
  aggregate?: Maybe<IProgramPlanEmployeesAggregateFields>;
  nodes: Array<IProgramPlanEmployees>;
}

/** aggregate fields of "program_plan_employees" */
export interface IProgramPlanEmployeesAggregateFields {
   __typename?: 'program_plan_employees_aggregate_fields';
  avg?: Maybe<IProgramPlanEmployeesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProgramPlanEmployeesMaxFields>;
  min?: Maybe<IProgramPlanEmployeesMinFields>;
  stddev?: Maybe<IProgramPlanEmployeesStddevFields>;
  stddev_pop?: Maybe<IProgramPlanEmployeesStddevPopFields>;
  stddev_samp?: Maybe<IProgramPlanEmployeesStddevSampFields>;
  sum?: Maybe<IProgramPlanEmployeesSumFields>;
  var_pop?: Maybe<IProgramPlanEmployeesVarPopFields>;
  var_samp?: Maybe<IProgramPlanEmployeesVarSampFields>;
  variance?: Maybe<IProgramPlanEmployeesVarianceFields>;
}


/** aggregate fields of "program_plan_employees" */
export interface IProgramPlanEmployeesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProgramPlanEmployeesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "program_plan_employees" */
export interface IProgramPlanEmployeesAggregateOrderBy {
  avg?: Maybe<IProgramPlanEmployeesAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IProgramPlanEmployeesMaxOrderBy>;
  min?: Maybe<IProgramPlanEmployeesMinOrderBy>;
  stddev?: Maybe<IProgramPlanEmployeesStddevOrderBy>;
  stddev_pop?: Maybe<IProgramPlanEmployeesStddevPopOrderBy>;
  stddev_samp?: Maybe<IProgramPlanEmployeesStddevSampOrderBy>;
  sum?: Maybe<IProgramPlanEmployeesSumOrderBy>;
  var_pop?: Maybe<IProgramPlanEmployeesVarPopOrderBy>;
  var_samp?: Maybe<IProgramPlanEmployeesVarSampOrderBy>;
  variance?: Maybe<IProgramPlanEmployeesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "program_plan_employees" */
export interface IProgramPlanEmployeesArrRelInsertInput {
  data: Array<IProgramPlanEmployeesInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IProgramPlanEmployeesOnConflict>;
}

/** aggregate avg on columns */
export interface IProgramPlanEmployeesAvgFields {
   __typename?: 'program_plan_employees_avg_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "program_plan_employees" */
export interface IProgramPlanEmployeesAvgOrderBy {
  employee_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "program_plan_employees". All fields are combined with a logical 'AND'. */
export interface IProgramPlanEmployeesBoolExp {
  _and?: Maybe<Array<IProgramPlanEmployeesBoolExp>>;
  _not?: Maybe<IProgramPlanEmployeesBoolExp>;
  _or?: Maybe<Array<IProgramPlanEmployeesBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  employee_id?: Maybe<IBigintComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  program_plan?: Maybe<IProgramPlansBoolExp>;
  program_plan_id?: Maybe<IBigintComparisonExp>;
  state?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
  user?: Maybe<IUsersBoolExp>;
}

/** unique or primary key constraints on table "program_plan_employees" */
export enum IProgramPlanEmployeesConstraint {
  /** unique or primary key constraint */
  PROGRAM_PLAN_EMPLOYEES_PKEY = 'program_plan_employees_pkey'
}

/** input type for incrementing numeric columns in table "program_plan_employees" */
export interface IProgramPlanEmployeesIncInput {
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "program_plan_employees" */
export interface IProgramPlanEmployeesInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  program_plan?: Maybe<IProgramPlansObjRelInsertInput>;
  program_plan_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user?: Maybe<IUsersObjRelInsertInput>;
}

/** aggregate max on columns */
export interface IProgramPlanEmployeesMaxFields {
   __typename?: 'program_plan_employees_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by max() on columns of table "program_plan_employees" */
export interface IProgramPlanEmployeesMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  employee_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
  state?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IProgramPlanEmployeesMinFields {
   __typename?: 'program_plan_employees_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by min() on columns of table "program_plan_employees" */
export interface IProgramPlanEmployeesMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  employee_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
  state?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "program_plan_employees" */
export interface IProgramPlanEmployeesMutationResponse {
   __typename?: 'program_plan_employees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IProgramPlanEmployees>;
}

/** on conflict condition type for table "program_plan_employees" */
export interface IProgramPlanEmployeesOnConflict {
  constraint: IProgramPlanEmployeesConstraint;
  update_columns: Array<IProgramPlanEmployeesUpdateColumn>;
  where?: Maybe<IProgramPlanEmployeesBoolExp>;
}

/** Ordering options when selecting data from "program_plan_employees". */
export interface IProgramPlanEmployeesOrderBy {
  created_at?: Maybe<IOrderBy>;
  employee_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_plan?: Maybe<IProgramPlansOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
  state?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user?: Maybe<IUsersOrderBy>;
}

/** primary key columns input for table: program_plan_employees */
export interface IProgramPlanEmployeesPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "program_plan_employees" */
export enum IProgramPlanEmployeesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EMPLOYEE_ID = 'employee_id',
  /** column name */
  ID = 'id',
  /** column name */
  PROGRAM_PLAN_ID = 'program_plan_id',
  /** column name */
  STATE = 'state',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "program_plan_employees" */
export interface IProgramPlanEmployeesSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IProgramPlanEmployeesStddevFields {
   __typename?: 'program_plan_employees_stddev_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "program_plan_employees" */
export interface IProgramPlanEmployeesStddevOrderBy {
  employee_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IProgramPlanEmployeesStddevPopFields {
   __typename?: 'program_plan_employees_stddev_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "program_plan_employees" */
export interface IProgramPlanEmployeesStddevPopOrderBy {
  employee_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IProgramPlanEmployeesStddevSampFields {
   __typename?: 'program_plan_employees_stddev_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "program_plan_employees" */
export interface IProgramPlanEmployeesStddevSampOrderBy {
  employee_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IProgramPlanEmployeesSumFields {
   __typename?: 'program_plan_employees_sum_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "program_plan_employees" */
export interface IProgramPlanEmployeesSumOrderBy {
  employee_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** update columns of table "program_plan_employees" */
export enum IProgramPlanEmployeesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  EMPLOYEE_ID = 'employee_id',
  /** column name */
  ID = 'id',
  /** column name */
  PROGRAM_PLAN_ID = 'program_plan_id',
  /** column name */
  STATE = 'state',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IProgramPlanEmployeesVarPopFields {
   __typename?: 'program_plan_employees_var_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "program_plan_employees" */
export interface IProgramPlanEmployeesVarPopOrderBy {
  employee_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IProgramPlanEmployeesVarSampFields {
   __typename?: 'program_plan_employees_var_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "program_plan_employees" */
export interface IProgramPlanEmployeesVarSampOrderBy {
  employee_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IProgramPlanEmployeesVarianceFields {
   __typename?: 'program_plan_employees_variance_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "program_plan_employees" */
export interface IProgramPlanEmployeesVarianceOrderBy {
  employee_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "program_plan_participators" */
export interface IProgramPlanParticipators {
   __typename?: 'program_plan_participators';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  participator_id: Scalars['bigint'];
  /** An array relationship */
  program_goals: Array<IProgramGoals>;
  /** An aggregate relationship */
  program_goals_aggregate: IProgramGoalsAggregate;
  /** An object relationship */
  program_plan: IProgramPlans;
  program_plan_id: Scalars['bigint'];
  state?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user: IUsers;
}


/** columns and relationships of "program_plan_participators" */
export interface IProgramPlanParticipatorsProgramGoalsArgs {
  distinct_on?: Maybe<Array<IProgramGoalsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramGoalsOrderBy>>;
  where?: Maybe<IProgramGoalsBoolExp>;
}


/** columns and relationships of "program_plan_participators" */
export interface IProgramPlanParticipatorsProgramGoalsAggregateArgs {
  distinct_on?: Maybe<Array<IProgramGoalsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramGoalsOrderBy>>;
  where?: Maybe<IProgramGoalsBoolExp>;
}

/** aggregated selection of "program_plan_participators" */
export interface IProgramPlanParticipatorsAggregate {
   __typename?: 'program_plan_participators_aggregate';
  aggregate?: Maybe<IProgramPlanParticipatorsAggregateFields>;
  nodes: Array<IProgramPlanParticipators>;
}

/** aggregate fields of "program_plan_participators" */
export interface IProgramPlanParticipatorsAggregateFields {
   __typename?: 'program_plan_participators_aggregate_fields';
  avg?: Maybe<IProgramPlanParticipatorsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProgramPlanParticipatorsMaxFields>;
  min?: Maybe<IProgramPlanParticipatorsMinFields>;
  stddev?: Maybe<IProgramPlanParticipatorsStddevFields>;
  stddev_pop?: Maybe<IProgramPlanParticipatorsStddevPopFields>;
  stddev_samp?: Maybe<IProgramPlanParticipatorsStddevSampFields>;
  sum?: Maybe<IProgramPlanParticipatorsSumFields>;
  var_pop?: Maybe<IProgramPlanParticipatorsVarPopFields>;
  var_samp?: Maybe<IProgramPlanParticipatorsVarSampFields>;
  variance?: Maybe<IProgramPlanParticipatorsVarianceFields>;
}


/** aggregate fields of "program_plan_participators" */
export interface IProgramPlanParticipatorsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProgramPlanParticipatorsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "program_plan_participators" */
export interface IProgramPlanParticipatorsAggregateOrderBy {
  avg?: Maybe<IProgramPlanParticipatorsAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IProgramPlanParticipatorsMaxOrderBy>;
  min?: Maybe<IProgramPlanParticipatorsMinOrderBy>;
  stddev?: Maybe<IProgramPlanParticipatorsStddevOrderBy>;
  stddev_pop?: Maybe<IProgramPlanParticipatorsStddevPopOrderBy>;
  stddev_samp?: Maybe<IProgramPlanParticipatorsStddevSampOrderBy>;
  sum?: Maybe<IProgramPlanParticipatorsSumOrderBy>;
  var_pop?: Maybe<IProgramPlanParticipatorsVarPopOrderBy>;
  var_samp?: Maybe<IProgramPlanParticipatorsVarSampOrderBy>;
  variance?: Maybe<IProgramPlanParticipatorsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "program_plan_participators" */
export interface IProgramPlanParticipatorsArrRelInsertInput {
  data: Array<IProgramPlanParticipatorsInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IProgramPlanParticipatorsOnConflict>;
}

/** aggregate avg on columns */
export interface IProgramPlanParticipatorsAvgFields {
   __typename?: 'program_plan_participators_avg_fields';
  id?: Maybe<Scalars['Float']>;
  participator_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "program_plan_participators" */
export interface IProgramPlanParticipatorsAvgOrderBy {
  id?: Maybe<IOrderBy>;
  participator_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "program_plan_participators". All fields are combined with a logical 'AND'. */
export interface IProgramPlanParticipatorsBoolExp {
  _and?: Maybe<Array<IProgramPlanParticipatorsBoolExp>>;
  _not?: Maybe<IProgramPlanParticipatorsBoolExp>;
  _or?: Maybe<Array<IProgramPlanParticipatorsBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  participator_id?: Maybe<IBigintComparisonExp>;
  program_goals?: Maybe<IProgramGoalsBoolExp>;
  program_plan?: Maybe<IProgramPlansBoolExp>;
  program_plan_id?: Maybe<IBigintComparisonExp>;
  state?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
  user?: Maybe<IUsersBoolExp>;
}

/** unique or primary key constraints on table "program_plan_participators" */
export enum IProgramPlanParticipatorsConstraint {
  /** unique or primary key constraint */
  PROGRAM_PLAN_PARTICIPATORS_PKEY = 'program_plan_participators_pkey'
}

/** input type for incrementing numeric columns in table "program_plan_participators" */
export interface IProgramPlanParticipatorsIncInput {
  id?: Maybe<Scalars['bigint']>;
  participator_id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "program_plan_participators" */
export interface IProgramPlanParticipatorsInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  participator_id?: Maybe<Scalars['bigint']>;
  program_goals?: Maybe<IProgramGoalsArrRelInsertInput>;
  program_plan?: Maybe<IProgramPlansObjRelInsertInput>;
  program_plan_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user?: Maybe<IUsersObjRelInsertInput>;
}

/** aggregate max on columns */
export interface IProgramPlanParticipatorsMaxFields {
   __typename?: 'program_plan_participators_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  participator_id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by max() on columns of table "program_plan_participators" */
export interface IProgramPlanParticipatorsMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  participator_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
  state?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IProgramPlanParticipatorsMinFields {
   __typename?: 'program_plan_participators_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  participator_id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by min() on columns of table "program_plan_participators" */
export interface IProgramPlanParticipatorsMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  participator_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
  state?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "program_plan_participators" */
export interface IProgramPlanParticipatorsMutationResponse {
   __typename?: 'program_plan_participators_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IProgramPlanParticipators>;
}

/** input type for inserting object relation for remote table "program_plan_participators" */
export interface IProgramPlanParticipatorsObjRelInsertInput {
  data: IProgramPlanParticipatorsInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IProgramPlanParticipatorsOnConflict>;
}

/** on conflict condition type for table "program_plan_participators" */
export interface IProgramPlanParticipatorsOnConflict {
  constraint: IProgramPlanParticipatorsConstraint;
  update_columns: Array<IProgramPlanParticipatorsUpdateColumn>;
  where?: Maybe<IProgramPlanParticipatorsBoolExp>;
}

/** Ordering options when selecting data from "program_plan_participators". */
export interface IProgramPlanParticipatorsOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  participator_id?: Maybe<IOrderBy>;
  program_goals_aggregate?: Maybe<IProgramGoalsAggregateOrderBy>;
  program_plan?: Maybe<IProgramPlansOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
  state?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user?: Maybe<IUsersOrderBy>;
}

/** primary key columns input for table: program_plan_participators */
export interface IProgramPlanParticipatorsPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "program_plan_participators" */
export enum IProgramPlanParticipatorsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  PARTICIPATOR_ID = 'participator_id',
  /** column name */
  PROGRAM_PLAN_ID = 'program_plan_id',
  /** column name */
  STATE = 'state',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "program_plan_participators" */
export interface IProgramPlanParticipatorsSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  participator_id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IProgramPlanParticipatorsStddevFields {
   __typename?: 'program_plan_participators_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  participator_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "program_plan_participators" */
export interface IProgramPlanParticipatorsStddevOrderBy {
  id?: Maybe<IOrderBy>;
  participator_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IProgramPlanParticipatorsStddevPopFields {
   __typename?: 'program_plan_participators_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  participator_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "program_plan_participators" */
export interface IProgramPlanParticipatorsStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  participator_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IProgramPlanParticipatorsStddevSampFields {
   __typename?: 'program_plan_participators_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  participator_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "program_plan_participators" */
export interface IProgramPlanParticipatorsStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  participator_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IProgramPlanParticipatorsSumFields {
   __typename?: 'program_plan_participators_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  participator_id?: Maybe<Scalars['bigint']>;
  program_plan_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "program_plan_participators" */
export interface IProgramPlanParticipatorsSumOrderBy {
  id?: Maybe<IOrderBy>;
  participator_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** update columns of table "program_plan_participators" */
export enum IProgramPlanParticipatorsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  PARTICIPATOR_ID = 'participator_id',
  /** column name */
  PROGRAM_PLAN_ID = 'program_plan_id',
  /** column name */
  STATE = 'state',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IProgramPlanParticipatorsVarPopFields {
   __typename?: 'program_plan_participators_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  participator_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "program_plan_participators" */
export interface IProgramPlanParticipatorsVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  participator_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IProgramPlanParticipatorsVarSampFields {
   __typename?: 'program_plan_participators_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  participator_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "program_plan_participators" */
export interface IProgramPlanParticipatorsVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  participator_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IProgramPlanParticipatorsVarianceFields {
   __typename?: 'program_plan_participators_variance_fields';
  id?: Maybe<Scalars['Float']>;
  participator_id?: Maybe<Scalars['Float']>;
  program_plan_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "program_plan_participators" */
export interface IProgramPlanParticipatorsVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  participator_id?: Maybe<IOrderBy>;
  program_plan_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "program_plans" */
export interface IProgramPlans {
   __typename?: 'program_plans';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  /** An array relationship */
  messages: Array<IMessages>;
  /** An aggregate relationship */
  messages_aggregate: IMessagesAggregate;
  /** An object relationship */
  program: IPrograms;
  program_id: Scalars['bigint'];
  /** An array relationship */
  program_plan_employees: Array<IProgramPlanEmployees>;
  /** An aggregate relationship */
  program_plan_employees_aggregate: IProgramPlanEmployeesAggregate;
  /** An array relationship */
  program_plan_participators: Array<IProgramPlanParticipators>;
  /** An aggregate relationship */
  program_plan_participators_aggregate: IProgramPlanParticipatorsAggregate;
  starts_at?: Maybe<Scalars['timestamp']>;
  updated_at: Scalars['timestamp'];
}


/** columns and relationships of "program_plans" */
export interface IProgramPlansMessagesArgs {
  distinct_on?: Maybe<Array<IMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMessagesOrderBy>>;
  where?: Maybe<IMessagesBoolExp>;
}


/** columns and relationships of "program_plans" */
export interface IProgramPlansMessagesAggregateArgs {
  distinct_on?: Maybe<Array<IMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMessagesOrderBy>>;
  where?: Maybe<IMessagesBoolExp>;
}


/** columns and relationships of "program_plans" */
export interface IProgramPlansProgramPlanEmployeesArgs {
  distinct_on?: Maybe<Array<IProgramPlanEmployeesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanEmployeesOrderBy>>;
  where?: Maybe<IProgramPlanEmployeesBoolExp>;
}


/** columns and relationships of "program_plans" */
export interface IProgramPlansProgramPlanEmployeesAggregateArgs {
  distinct_on?: Maybe<Array<IProgramPlanEmployeesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanEmployeesOrderBy>>;
  where?: Maybe<IProgramPlanEmployeesBoolExp>;
}


/** columns and relationships of "program_plans" */
export interface IProgramPlansProgramPlanParticipatorsArgs {
  distinct_on?: Maybe<Array<IProgramPlanParticipatorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanParticipatorsOrderBy>>;
  where?: Maybe<IProgramPlanParticipatorsBoolExp>;
}


/** columns and relationships of "program_plans" */
export interface IProgramPlansProgramPlanParticipatorsAggregateArgs {
  distinct_on?: Maybe<Array<IProgramPlanParticipatorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanParticipatorsOrderBy>>;
  where?: Maybe<IProgramPlanParticipatorsBoolExp>;
}

/** aggregated selection of "program_plans" */
export interface IProgramPlansAggregate {
   __typename?: 'program_plans_aggregate';
  aggregate?: Maybe<IProgramPlansAggregateFields>;
  nodes: Array<IProgramPlans>;
}

/** aggregate fields of "program_plans" */
export interface IProgramPlansAggregateFields {
   __typename?: 'program_plans_aggregate_fields';
  avg?: Maybe<IProgramPlansAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProgramPlansMaxFields>;
  min?: Maybe<IProgramPlansMinFields>;
  stddev?: Maybe<IProgramPlansStddevFields>;
  stddev_pop?: Maybe<IProgramPlansStddevPopFields>;
  stddev_samp?: Maybe<IProgramPlansStddevSampFields>;
  sum?: Maybe<IProgramPlansSumFields>;
  var_pop?: Maybe<IProgramPlansVarPopFields>;
  var_samp?: Maybe<IProgramPlansVarSampFields>;
  variance?: Maybe<IProgramPlansVarianceFields>;
}


/** aggregate fields of "program_plans" */
export interface IProgramPlansAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProgramPlansSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "program_plans" */
export interface IProgramPlansAggregateOrderBy {
  avg?: Maybe<IProgramPlansAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IProgramPlansMaxOrderBy>;
  min?: Maybe<IProgramPlansMinOrderBy>;
  stddev?: Maybe<IProgramPlansStddevOrderBy>;
  stddev_pop?: Maybe<IProgramPlansStddevPopOrderBy>;
  stddev_samp?: Maybe<IProgramPlansStddevSampOrderBy>;
  sum?: Maybe<IProgramPlansSumOrderBy>;
  var_pop?: Maybe<IProgramPlansVarPopOrderBy>;
  var_samp?: Maybe<IProgramPlansVarSampOrderBy>;
  variance?: Maybe<IProgramPlansVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "program_plans" */
export interface IProgramPlansArrRelInsertInput {
  data: Array<IProgramPlansInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IProgramPlansOnConflict>;
}

/** aggregate avg on columns */
export interface IProgramPlansAvgFields {
   __typename?: 'program_plans_avg_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "program_plans" */
export interface IProgramPlansAvgOrderBy {
  id?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "program_plans". All fields are combined with a logical 'AND'. */
export interface IProgramPlansBoolExp {
  _and?: Maybe<Array<IProgramPlansBoolExp>>;
  _not?: Maybe<IProgramPlansBoolExp>;
  _or?: Maybe<Array<IProgramPlansBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  messages?: Maybe<IMessagesBoolExp>;
  program?: Maybe<IProgramsBoolExp>;
  program_id?: Maybe<IBigintComparisonExp>;
  program_plan_employees?: Maybe<IProgramPlanEmployeesBoolExp>;
  program_plan_participators?: Maybe<IProgramPlanParticipatorsBoolExp>;
  starts_at?: Maybe<ITimestampComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "program_plans" */
export enum IProgramPlansConstraint {
  /** unique or primary key constraint */
  PROGRAM_PLANS_PKEY = 'program_plans_pkey'
}

/** input type for incrementing numeric columns in table "program_plans" */
export interface IProgramPlansIncInput {
  id?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "program_plans" */
export interface IProgramPlansInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  messages?: Maybe<IMessagesArrRelInsertInput>;
  program?: Maybe<IProgramsObjRelInsertInput>;
  program_id?: Maybe<Scalars['bigint']>;
  program_plan_employees?: Maybe<IProgramPlanEmployeesArrRelInsertInput>;
  program_plan_participators?: Maybe<IProgramPlanParticipatorsArrRelInsertInput>;
  starts_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IProgramPlansMaxFields {
   __typename?: 'program_plans_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['bigint']>;
  starts_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by max() on columns of table "program_plans" */
export interface IProgramPlansMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
  starts_at?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IProgramPlansMinFields {
   __typename?: 'program_plans_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['bigint']>;
  starts_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** order by min() on columns of table "program_plans" */
export interface IProgramPlansMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
  starts_at?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "program_plans" */
export interface IProgramPlansMutationResponse {
   __typename?: 'program_plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IProgramPlans>;
}

/** input type for inserting object relation for remote table "program_plans" */
export interface IProgramPlansObjRelInsertInput {
  data: IProgramPlansInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IProgramPlansOnConflict>;
}

/** on conflict condition type for table "program_plans" */
export interface IProgramPlansOnConflict {
  constraint: IProgramPlansConstraint;
  update_columns: Array<IProgramPlansUpdateColumn>;
  where?: Maybe<IProgramPlansBoolExp>;
}

/** Ordering options when selecting data from "program_plans". */
export interface IProgramPlansOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  messages_aggregate?: Maybe<IMessagesAggregateOrderBy>;
  program?: Maybe<IProgramsOrderBy>;
  program_id?: Maybe<IOrderBy>;
  program_plan_employees_aggregate?: Maybe<IProgramPlanEmployeesAggregateOrderBy>;
  program_plan_participators_aggregate?: Maybe<IProgramPlanParticipatorsAggregateOrderBy>;
  starts_at?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: program_plans */
export interface IProgramPlansPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "program_plans" */
export enum IProgramPlansSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  PROGRAM_ID = 'program_id',
  /** column name */
  STARTS_AT = 'starts_at',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "program_plans" */
export interface IProgramPlansSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['bigint']>;
  starts_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IProgramPlansStddevFields {
   __typename?: 'program_plans_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "program_plans" */
export interface IProgramPlansStddevOrderBy {
  id?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IProgramPlansStddevPopFields {
   __typename?: 'program_plans_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "program_plans" */
export interface IProgramPlansStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IProgramPlansStddevSampFields {
   __typename?: 'program_plans_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "program_plans" */
export interface IProgramPlansStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IProgramPlansSumFields {
   __typename?: 'program_plans_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "program_plans" */
export interface IProgramPlansSumOrderBy {
  id?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** update columns of table "program_plans" */
export enum IProgramPlansUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  PROGRAM_ID = 'program_id',
  /** column name */
  STARTS_AT = 'starts_at',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IProgramPlansVarPopFields {
   __typename?: 'program_plans_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "program_plans" */
export interface IProgramPlansVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IProgramPlansVarSampFields {
   __typename?: 'program_plans_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "program_plans" */
export interface IProgramPlansVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IProgramPlansVarianceFields {
   __typename?: 'program_plans_variance_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "program_plans" */
export interface IProgramPlansVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  program_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "programs" */
export interface IPrograms {
   __typename?: 'programs';
  created_at: Scalars['timestamp'];
  duration_amount?: Maybe<Scalars['Int']>;
  duration_unit?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  program_messages: Array<IProgramMessages>;
  /** An aggregate relationship */
  program_messages_aggregate: IProgramMessagesAggregate;
  /** An array relationship */
  program_plans: Array<IProgramPlans>;
  /** An aggregate relationship */
  program_plans_aggregate: IProgramPlansAggregate;
  state?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
}


/** columns and relationships of "programs" */
export interface IProgramsProgramMessagesArgs {
  distinct_on?: Maybe<Array<IProgramMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramMessagesOrderBy>>;
  where?: Maybe<IProgramMessagesBoolExp>;
}


/** columns and relationships of "programs" */
export interface IProgramsProgramMessagesAggregateArgs {
  distinct_on?: Maybe<Array<IProgramMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramMessagesOrderBy>>;
  where?: Maybe<IProgramMessagesBoolExp>;
}


/** columns and relationships of "programs" */
export interface IProgramsProgramPlansArgs {
  distinct_on?: Maybe<Array<IProgramPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlansOrderBy>>;
  where?: Maybe<IProgramPlansBoolExp>;
}


/** columns and relationships of "programs" */
export interface IProgramsProgramPlansAggregateArgs {
  distinct_on?: Maybe<Array<IProgramPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlansOrderBy>>;
  where?: Maybe<IProgramPlansBoolExp>;
}

/** aggregated selection of "programs" */
export interface IProgramsAggregate {
   __typename?: 'programs_aggregate';
  aggregate?: Maybe<IProgramsAggregateFields>;
  nodes: Array<IPrograms>;
}

/** aggregate fields of "programs" */
export interface IProgramsAggregateFields {
   __typename?: 'programs_aggregate_fields';
  avg?: Maybe<IProgramsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProgramsMaxFields>;
  min?: Maybe<IProgramsMinFields>;
  stddev?: Maybe<IProgramsStddevFields>;
  stddev_pop?: Maybe<IProgramsStddevPopFields>;
  stddev_samp?: Maybe<IProgramsStddevSampFields>;
  sum?: Maybe<IProgramsSumFields>;
  var_pop?: Maybe<IProgramsVarPopFields>;
  var_samp?: Maybe<IProgramsVarSampFields>;
  variance?: Maybe<IProgramsVarianceFields>;
}


/** aggregate fields of "programs" */
export interface IProgramsAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProgramsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IProgramsAvgFields {
   __typename?: 'programs_avg_fields';
  duration_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "programs". All fields are combined with a logical 'AND'. */
export interface IProgramsBoolExp {
  _and?: Maybe<Array<IProgramsBoolExp>>;
  _not?: Maybe<IProgramsBoolExp>;
  _or?: Maybe<Array<IProgramsBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  duration_amount?: Maybe<IIntComparisonExp>;
  duration_unit?: Maybe<IStringComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  program_messages?: Maybe<IProgramMessagesBoolExp>;
  program_plans?: Maybe<IProgramPlansBoolExp>;
  state?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "programs" */
export enum IProgramsConstraint {
  /** unique or primary key constraint */
  PROGRAMS_PKEY = 'programs_pkey'
}

/** input type for incrementing numeric columns in table "programs" */
export interface IProgramsIncInput {
  duration_amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "programs" */
export interface IProgramsInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  duration_amount?: Maybe<Scalars['Int']>;
  duration_unit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  program_messages?: Maybe<IProgramMessagesArrRelInsertInput>;
  program_plans?: Maybe<IProgramPlansArrRelInsertInput>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IProgramsMaxFields {
   __typename?: 'programs_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  duration_amount?: Maybe<Scalars['Int']>;
  duration_unit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate min on columns */
export interface IProgramsMinFields {
   __typename?: 'programs_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  duration_amount?: Maybe<Scalars['Int']>;
  duration_unit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** response of any mutation on the table "programs" */
export interface IProgramsMutationResponse {
   __typename?: 'programs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IPrograms>;
}

/** input type for inserting object relation for remote table "programs" */
export interface IProgramsObjRelInsertInput {
  data: IProgramsInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IProgramsOnConflict>;
}

/** on conflict condition type for table "programs" */
export interface IProgramsOnConflict {
  constraint: IProgramsConstraint;
  update_columns: Array<IProgramsUpdateColumn>;
  where?: Maybe<IProgramsBoolExp>;
}

/** Ordering options when selecting data from "programs". */
export interface IProgramsOrderBy {
  created_at?: Maybe<IOrderBy>;
  duration_amount?: Maybe<IOrderBy>;
  duration_unit?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  program_messages_aggregate?: Maybe<IProgramMessagesAggregateOrderBy>;
  program_plans_aggregate?: Maybe<IProgramPlansAggregateOrderBy>;
  state?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: programs */
export interface IProgramsPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "programs" */
export enum IProgramsSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DURATION_AMOUNT = 'duration_amount',
  /** column name */
  DURATION_UNIT = 'duration_unit',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  STATE = 'state',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "programs" */
export interface IProgramsSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  duration_amount?: Maybe<Scalars['Int']>;
  duration_unit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IProgramsStddevFields {
   __typename?: 'programs_stddev_fields';
  duration_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IProgramsStddevPopFields {
   __typename?: 'programs_stddev_pop_fields';
  duration_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IProgramsStddevSampFields {
   __typename?: 'programs_stddev_samp_fields';
  duration_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IProgramsSumFields {
   __typename?: 'programs_sum_fields';
  duration_amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
}

/** update columns of table "programs" */
export enum IProgramsUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DURATION_AMOUNT = 'duration_amount',
  /** column name */
  DURATION_UNIT = 'duration_unit',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  STATE = 'state',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IProgramsVarPopFields {
   __typename?: 'programs_var_pop_fields';
  duration_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IProgramsVarSampFields {
   __typename?: 'programs_var_samp_fields';
  duration_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IProgramsVarianceFields {
   __typename?: 'programs_variance_fields';
  duration_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "progress_days" */
export interface IProgressDays {
   __typename?: 'progress_days';
  back_image?: Maybe<Scalars['String']>;
  body_weight: Scalars['numeric'];
  chest: Scalars['numeric'];
  created_at: Scalars['timestamp'];
  date?: Maybe<Scalars['timestamp']>;
  front_image?: Maybe<Scalars['String']>;
  hips: Scalars['numeric'];
  id: Scalars['bigint'];
  message?: Maybe<Scalars['String']>;
  /** An object relationship */
  progress_week: IProgressWeeks;
  progress_week_id: Scalars['bigint'];
  seen_at?: Maybe<Scalars['timestamp']>;
  side_image?: Maybe<Scalars['String']>;
  thigh: Scalars['numeric'];
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['bigint'];
  waist: Scalars['numeric'];
}

/** aggregated selection of "progress_days" */
export interface IProgressDaysAggregate {
   __typename?: 'progress_days_aggregate';
  aggregate?: Maybe<IProgressDaysAggregateFields>;
  nodes: Array<IProgressDays>;
}

/** aggregate fields of "progress_days" */
export interface IProgressDaysAggregateFields {
   __typename?: 'progress_days_aggregate_fields';
  avg?: Maybe<IProgressDaysAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProgressDaysMaxFields>;
  min?: Maybe<IProgressDaysMinFields>;
  stddev?: Maybe<IProgressDaysStddevFields>;
  stddev_pop?: Maybe<IProgressDaysStddevPopFields>;
  stddev_samp?: Maybe<IProgressDaysStddevSampFields>;
  sum?: Maybe<IProgressDaysSumFields>;
  var_pop?: Maybe<IProgressDaysVarPopFields>;
  var_samp?: Maybe<IProgressDaysVarSampFields>;
  variance?: Maybe<IProgressDaysVarianceFields>;
}


/** aggregate fields of "progress_days" */
export interface IProgressDaysAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProgressDaysSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "progress_days" */
export interface IProgressDaysAggregateOrderBy {
  avg?: Maybe<IProgressDaysAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IProgressDaysMaxOrderBy>;
  min?: Maybe<IProgressDaysMinOrderBy>;
  stddev?: Maybe<IProgressDaysStddevOrderBy>;
  stddev_pop?: Maybe<IProgressDaysStddevPopOrderBy>;
  stddev_samp?: Maybe<IProgressDaysStddevSampOrderBy>;
  sum?: Maybe<IProgressDaysSumOrderBy>;
  var_pop?: Maybe<IProgressDaysVarPopOrderBy>;
  var_samp?: Maybe<IProgressDaysVarSampOrderBy>;
  variance?: Maybe<IProgressDaysVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "progress_days" */
export interface IProgressDaysArrRelInsertInput {
  data: Array<IProgressDaysInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IProgressDaysOnConflict>;
}

/** aggregate avg on columns */
export interface IProgressDaysAvgFields {
   __typename?: 'progress_days_avg_fields';
  body_weight?: Maybe<Scalars['Float']>;
  chest?: Maybe<Scalars['Float']>;
  hips?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  progress_week_id?: Maybe<Scalars['Float']>;
  thigh?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  waist?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "progress_days" */
export interface IProgressDaysAvgOrderBy {
  body_weight?: Maybe<IOrderBy>;
  chest?: Maybe<IOrderBy>;
  hips?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  progress_week_id?: Maybe<IOrderBy>;
  thigh?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  waist?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "progress_days". All fields are combined with a logical 'AND'. */
export interface IProgressDaysBoolExp {
  _and?: Maybe<Array<IProgressDaysBoolExp>>;
  _not?: Maybe<IProgressDaysBoolExp>;
  _or?: Maybe<Array<IProgressDaysBoolExp>>;
  back_image?: Maybe<IStringComparisonExp>;
  body_weight?: Maybe<INumericComparisonExp>;
  chest?: Maybe<INumericComparisonExp>;
  created_at?: Maybe<ITimestampComparisonExp>;
  date?: Maybe<ITimestampComparisonExp>;
  front_image?: Maybe<IStringComparisonExp>;
  hips?: Maybe<INumericComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  message?: Maybe<IStringComparisonExp>;
  progress_week?: Maybe<IProgressWeeksBoolExp>;
  progress_week_id?: Maybe<IBigintComparisonExp>;
  seen_at?: Maybe<ITimestampComparisonExp>;
  side_image?: Maybe<IStringComparisonExp>;
  thigh?: Maybe<INumericComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
  user?: Maybe<IUsersBoolExp>;
  user_id?: Maybe<IBigintComparisonExp>;
  waist?: Maybe<INumericComparisonExp>;
}

/** unique or primary key constraints on table "progress_days" */
export enum IProgressDaysConstraint {
  /** unique or primary key constraint */
  PROGRESS_DAYS_PKEY = 'progress_days_pkey'
}

/** input type for incrementing numeric columns in table "progress_days" */
export interface IProgressDaysIncInput {
  body_weight?: Maybe<Scalars['numeric']>;
  chest?: Maybe<Scalars['numeric']>;
  hips?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  progress_week_id?: Maybe<Scalars['bigint']>;
  thigh?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
  waist?: Maybe<Scalars['numeric']>;
}

/** input type for inserting data into table "progress_days" */
export interface IProgressDaysInsertInput {
  back_image?: Maybe<Scalars['String']>;
  body_weight?: Maybe<Scalars['numeric']>;
  chest?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date?: Maybe<Scalars['timestamp']>;
  front_image?: Maybe<Scalars['String']>;
  hips?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  progress_week?: Maybe<IProgressWeeksObjRelInsertInput>;
  progress_week_id?: Maybe<Scalars['bigint']>;
  seen_at?: Maybe<Scalars['timestamp']>;
  side_image?: Maybe<Scalars['String']>;
  thigh?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user?: Maybe<IUsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
  waist?: Maybe<Scalars['numeric']>;
}

/** aggregate max on columns */
export interface IProgressDaysMaxFields {
   __typename?: 'progress_days_max_fields';
  back_image?: Maybe<Scalars['String']>;
  body_weight?: Maybe<Scalars['numeric']>;
  chest?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date?: Maybe<Scalars['timestamp']>;
  front_image?: Maybe<Scalars['String']>;
  hips?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  progress_week_id?: Maybe<Scalars['bigint']>;
  seen_at?: Maybe<Scalars['timestamp']>;
  side_image?: Maybe<Scalars['String']>;
  thigh?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
  waist?: Maybe<Scalars['numeric']>;
}

/** order by max() on columns of table "progress_days" */
export interface IProgressDaysMaxOrderBy {
  back_image?: Maybe<IOrderBy>;
  body_weight?: Maybe<IOrderBy>;
  chest?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  date?: Maybe<IOrderBy>;
  front_image?: Maybe<IOrderBy>;
  hips?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  message?: Maybe<IOrderBy>;
  progress_week_id?: Maybe<IOrderBy>;
  seen_at?: Maybe<IOrderBy>;
  side_image?: Maybe<IOrderBy>;
  thigh?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  waist?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IProgressDaysMinFields {
   __typename?: 'progress_days_min_fields';
  back_image?: Maybe<Scalars['String']>;
  body_weight?: Maybe<Scalars['numeric']>;
  chest?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date?: Maybe<Scalars['timestamp']>;
  front_image?: Maybe<Scalars['String']>;
  hips?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  progress_week_id?: Maybe<Scalars['bigint']>;
  seen_at?: Maybe<Scalars['timestamp']>;
  side_image?: Maybe<Scalars['String']>;
  thigh?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
  waist?: Maybe<Scalars['numeric']>;
}

/** order by min() on columns of table "progress_days" */
export interface IProgressDaysMinOrderBy {
  back_image?: Maybe<IOrderBy>;
  body_weight?: Maybe<IOrderBy>;
  chest?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  date?: Maybe<IOrderBy>;
  front_image?: Maybe<IOrderBy>;
  hips?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  message?: Maybe<IOrderBy>;
  progress_week_id?: Maybe<IOrderBy>;
  seen_at?: Maybe<IOrderBy>;
  side_image?: Maybe<IOrderBy>;
  thigh?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  waist?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "progress_days" */
export interface IProgressDaysMutationResponse {
   __typename?: 'progress_days_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IProgressDays>;
}

/** on conflict condition type for table "progress_days" */
export interface IProgressDaysOnConflict {
  constraint: IProgressDaysConstraint;
  update_columns: Array<IProgressDaysUpdateColumn>;
  where?: Maybe<IProgressDaysBoolExp>;
}

/** Ordering options when selecting data from "progress_days". */
export interface IProgressDaysOrderBy {
  back_image?: Maybe<IOrderBy>;
  body_weight?: Maybe<IOrderBy>;
  chest?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  date?: Maybe<IOrderBy>;
  front_image?: Maybe<IOrderBy>;
  hips?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  message?: Maybe<IOrderBy>;
  progress_week?: Maybe<IProgressWeeksOrderBy>;
  progress_week_id?: Maybe<IOrderBy>;
  seen_at?: Maybe<IOrderBy>;
  side_image?: Maybe<IOrderBy>;
  thigh?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user?: Maybe<IUsersOrderBy>;
  user_id?: Maybe<IOrderBy>;
  waist?: Maybe<IOrderBy>;
}

/** primary key columns input for table: progress_days */
export interface IProgressDaysPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "progress_days" */
export enum IProgressDaysSelectColumn {
  /** column name */
  BACK_IMAGE = 'back_image',
  /** column name */
  BODY_WEIGHT = 'body_weight',
  /** column name */
  CHEST = 'chest',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DATE = 'date',
  /** column name */
  FRONT_IMAGE = 'front_image',
  /** column name */
  HIPS = 'hips',
  /** column name */
  ID = 'id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  PROGRESS_WEEK_ID = 'progress_week_id',
  /** column name */
  SEEN_AT = 'seen_at',
  /** column name */
  SIDE_IMAGE = 'side_image',
  /** column name */
  THIGH = 'thigh',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WAIST = 'waist'
}

/** input type for updating data in table "progress_days" */
export interface IProgressDaysSetInput {
  back_image?: Maybe<Scalars['String']>;
  body_weight?: Maybe<Scalars['numeric']>;
  chest?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date?: Maybe<Scalars['timestamp']>;
  front_image?: Maybe<Scalars['String']>;
  hips?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  progress_week_id?: Maybe<Scalars['bigint']>;
  seen_at?: Maybe<Scalars['timestamp']>;
  side_image?: Maybe<Scalars['String']>;
  thigh?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
  waist?: Maybe<Scalars['numeric']>;
}

/** aggregate stddev on columns */
export interface IProgressDaysStddevFields {
   __typename?: 'progress_days_stddev_fields';
  body_weight?: Maybe<Scalars['Float']>;
  chest?: Maybe<Scalars['Float']>;
  hips?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  progress_week_id?: Maybe<Scalars['Float']>;
  thigh?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  waist?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "progress_days" */
export interface IProgressDaysStddevOrderBy {
  body_weight?: Maybe<IOrderBy>;
  chest?: Maybe<IOrderBy>;
  hips?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  progress_week_id?: Maybe<IOrderBy>;
  thigh?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  waist?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IProgressDaysStddevPopFields {
   __typename?: 'progress_days_stddev_pop_fields';
  body_weight?: Maybe<Scalars['Float']>;
  chest?: Maybe<Scalars['Float']>;
  hips?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  progress_week_id?: Maybe<Scalars['Float']>;
  thigh?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  waist?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "progress_days" */
export interface IProgressDaysStddevPopOrderBy {
  body_weight?: Maybe<IOrderBy>;
  chest?: Maybe<IOrderBy>;
  hips?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  progress_week_id?: Maybe<IOrderBy>;
  thigh?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  waist?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IProgressDaysStddevSampFields {
   __typename?: 'progress_days_stddev_samp_fields';
  body_weight?: Maybe<Scalars['Float']>;
  chest?: Maybe<Scalars['Float']>;
  hips?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  progress_week_id?: Maybe<Scalars['Float']>;
  thigh?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  waist?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "progress_days" */
export interface IProgressDaysStddevSampOrderBy {
  body_weight?: Maybe<IOrderBy>;
  chest?: Maybe<IOrderBy>;
  hips?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  progress_week_id?: Maybe<IOrderBy>;
  thigh?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  waist?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IProgressDaysSumFields {
   __typename?: 'progress_days_sum_fields';
  body_weight?: Maybe<Scalars['numeric']>;
  chest?: Maybe<Scalars['numeric']>;
  hips?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  progress_week_id?: Maybe<Scalars['bigint']>;
  thigh?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
  waist?: Maybe<Scalars['numeric']>;
}

/** order by sum() on columns of table "progress_days" */
export interface IProgressDaysSumOrderBy {
  body_weight?: Maybe<IOrderBy>;
  chest?: Maybe<IOrderBy>;
  hips?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  progress_week_id?: Maybe<IOrderBy>;
  thigh?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  waist?: Maybe<IOrderBy>;
}

/** update columns of table "progress_days" */
export enum IProgressDaysUpdateColumn {
  /** column name */
  BACK_IMAGE = 'back_image',
  /** column name */
  BODY_WEIGHT = 'body_weight',
  /** column name */
  CHEST = 'chest',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DATE = 'date',
  /** column name */
  FRONT_IMAGE = 'front_image',
  /** column name */
  HIPS = 'hips',
  /** column name */
  ID = 'id',
  /** column name */
  MESSAGE = 'message',
  /** column name */
  PROGRESS_WEEK_ID = 'progress_week_id',
  /** column name */
  SEEN_AT = 'seen_at',
  /** column name */
  SIDE_IMAGE = 'side_image',
  /** column name */
  THIGH = 'thigh',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WAIST = 'waist'
}

/** aggregate var_pop on columns */
export interface IProgressDaysVarPopFields {
   __typename?: 'progress_days_var_pop_fields';
  body_weight?: Maybe<Scalars['Float']>;
  chest?: Maybe<Scalars['Float']>;
  hips?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  progress_week_id?: Maybe<Scalars['Float']>;
  thigh?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  waist?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "progress_days" */
export interface IProgressDaysVarPopOrderBy {
  body_weight?: Maybe<IOrderBy>;
  chest?: Maybe<IOrderBy>;
  hips?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  progress_week_id?: Maybe<IOrderBy>;
  thigh?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  waist?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IProgressDaysVarSampFields {
   __typename?: 'progress_days_var_samp_fields';
  body_weight?: Maybe<Scalars['Float']>;
  chest?: Maybe<Scalars['Float']>;
  hips?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  progress_week_id?: Maybe<Scalars['Float']>;
  thigh?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  waist?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "progress_days" */
export interface IProgressDaysVarSampOrderBy {
  body_weight?: Maybe<IOrderBy>;
  chest?: Maybe<IOrderBy>;
  hips?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  progress_week_id?: Maybe<IOrderBy>;
  thigh?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  waist?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IProgressDaysVarianceFields {
   __typename?: 'progress_days_variance_fields';
  body_weight?: Maybe<Scalars['Float']>;
  chest?: Maybe<Scalars['Float']>;
  hips?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  progress_week_id?: Maybe<Scalars['Float']>;
  thigh?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  waist?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "progress_days" */
export interface IProgressDaysVarianceOrderBy {
  body_weight?: Maybe<IOrderBy>;
  chest?: Maybe<IOrderBy>;
  hips?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  progress_week_id?: Maybe<IOrderBy>;
  thigh?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  waist?: Maybe<IOrderBy>;
}

/** columns and relationships of "progress_weeks" */
export interface IProgressWeeks {
   __typename?: 'progress_weeks';
  created_at?: Maybe<Scalars['timestamptz']>;
  ends_on?: Maybe<Scalars['date']>;
  goal_weight?: Maybe<Scalars['numeric']>;
  id: Scalars['bigint'];
  /** fetch data from the table: "progress_days" */
  progress_days: Array<IProgressDays>;
  /** An aggregate relationship */
  progress_days_aggregate: IProgressDaysAggregate;
  start_weight?: Maybe<Scalars['numeric']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['bigint'];
  week_number?: Maybe<Scalars['Int']>;
}


/** columns and relationships of "progress_weeks" */
export interface IProgressWeeksProgressDaysArgs {
  distinct_on?: Maybe<Array<IProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressDaysOrderBy>>;
  where?: Maybe<IProgressDaysBoolExp>;
}


/** columns and relationships of "progress_weeks" */
export interface IProgressWeeksProgressDaysAggregateArgs {
  distinct_on?: Maybe<Array<IProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressDaysOrderBy>>;
  where?: Maybe<IProgressDaysBoolExp>;
}

/** aggregated selection of "progress_weeks" */
export interface IProgressWeeksAggregate {
   __typename?: 'progress_weeks_aggregate';
  aggregate?: Maybe<IProgressWeeksAggregateFields>;
  nodes: Array<IProgressWeeks>;
}

/** aggregate fields of "progress_weeks" */
export interface IProgressWeeksAggregateFields {
   __typename?: 'progress_weeks_aggregate_fields';
  avg?: Maybe<IProgressWeeksAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IProgressWeeksMaxFields>;
  min?: Maybe<IProgressWeeksMinFields>;
  stddev?: Maybe<IProgressWeeksStddevFields>;
  stddev_pop?: Maybe<IProgressWeeksStddevPopFields>;
  stddev_samp?: Maybe<IProgressWeeksStddevSampFields>;
  sum?: Maybe<IProgressWeeksSumFields>;
  var_pop?: Maybe<IProgressWeeksVarPopFields>;
  var_samp?: Maybe<IProgressWeeksVarSampFields>;
  variance?: Maybe<IProgressWeeksVarianceFields>;
}


/** aggregate fields of "progress_weeks" */
export interface IProgressWeeksAggregateFieldsCountArgs {
  columns?: Maybe<Array<IProgressWeeksSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "progress_weeks" */
export interface IProgressWeeksAggregateOrderBy {
  avg?: Maybe<IProgressWeeksAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IProgressWeeksMaxOrderBy>;
  min?: Maybe<IProgressWeeksMinOrderBy>;
  stddev?: Maybe<IProgressWeeksStddevOrderBy>;
  stddev_pop?: Maybe<IProgressWeeksStddevPopOrderBy>;
  stddev_samp?: Maybe<IProgressWeeksStddevSampOrderBy>;
  sum?: Maybe<IProgressWeeksSumOrderBy>;
  var_pop?: Maybe<IProgressWeeksVarPopOrderBy>;
  var_samp?: Maybe<IProgressWeeksVarSampOrderBy>;
  variance?: Maybe<IProgressWeeksVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "progress_weeks" */
export interface IProgressWeeksArrRelInsertInput {
  data: Array<IProgressWeeksInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IProgressWeeksOnConflict>;
}

/** aggregate avg on columns */
export interface IProgressWeeksAvgFields {
   __typename?: 'progress_weeks_avg_fields';
  goal_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  week_number?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "progress_weeks" */
export interface IProgressWeeksAvgOrderBy {
  goal_weight?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  start_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  week_number?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "progress_weeks". All fields are combined with a logical 'AND'. */
export interface IProgressWeeksBoolExp {
  _and?: Maybe<Array<IProgressWeeksBoolExp>>;
  _not?: Maybe<IProgressWeeksBoolExp>;
  _or?: Maybe<Array<IProgressWeeksBoolExp>>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  ends_on?: Maybe<IDateComparisonExp>;
  goal_weight?: Maybe<INumericComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  progress_days?: Maybe<IProgressDaysBoolExp>;
  start_weight?: Maybe<INumericComparisonExp>;
  starts_on?: Maybe<IDateComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
  user?: Maybe<IUsersBoolExp>;
  user_id?: Maybe<IBigintComparisonExp>;
  week_number?: Maybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "progress_weeks" */
export enum IProgressWeeksConstraint {
  /** unique or primary key constraint */
  PROGRESS_WEEKS_PKEY = 'progress_weeks_pkey',
  /** unique or primary key constraint */
  PROGRESS_WEEKS_USER_ID_STARTS_ON_ENDS_ON_KEY = 'progress_weeks_user_id_starts_on_ends_on_key'
}

/** input type for incrementing numeric columns in table "progress_weeks" */
export interface IProgressWeeksIncInput {
  goal_weight?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  start_weight?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
  week_number?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "progress_weeks" */
export interface IProgressWeeksInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  ends_on?: Maybe<Scalars['date']>;
  goal_weight?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  progress_days?: Maybe<IProgressDaysArrRelInsertInput>;
  start_weight?: Maybe<Scalars['numeric']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<IUsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
  week_number?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface IProgressWeeksMaxFields {
   __typename?: 'progress_weeks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ends_on?: Maybe<Scalars['date']>;
  goal_weight?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  start_weight?: Maybe<Scalars['numeric']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  week_number?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "progress_weeks" */
export interface IProgressWeeksMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  ends_on?: Maybe<IOrderBy>;
  goal_weight?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  start_weight?: Maybe<IOrderBy>;
  starts_on?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  week_number?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IProgressWeeksMinFields {
   __typename?: 'progress_weeks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ends_on?: Maybe<Scalars['date']>;
  goal_weight?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  start_weight?: Maybe<Scalars['numeric']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  week_number?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "progress_weeks" */
export interface IProgressWeeksMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  ends_on?: Maybe<IOrderBy>;
  goal_weight?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  start_weight?: Maybe<IOrderBy>;
  starts_on?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  week_number?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "progress_weeks" */
export interface IProgressWeeksMutationResponse {
   __typename?: 'progress_weeks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IProgressWeeks>;
}

/** input type for inserting object relation for remote table "progress_weeks" */
export interface IProgressWeeksObjRelInsertInput {
  data: IProgressWeeksInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IProgressWeeksOnConflict>;
}

/** on conflict condition type for table "progress_weeks" */
export interface IProgressWeeksOnConflict {
  constraint: IProgressWeeksConstraint;
  update_columns: Array<IProgressWeeksUpdateColumn>;
  where?: Maybe<IProgressWeeksBoolExp>;
}

/** Ordering options when selecting data from "progress_weeks". */
export interface IProgressWeeksOrderBy {
  created_at?: Maybe<IOrderBy>;
  ends_on?: Maybe<IOrderBy>;
  goal_weight?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  progress_days_aggregate?: Maybe<IProgressDaysAggregateOrderBy>;
  start_weight?: Maybe<IOrderBy>;
  starts_on?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user?: Maybe<IUsersOrderBy>;
  user_id?: Maybe<IOrderBy>;
  week_number?: Maybe<IOrderBy>;
}

/** primary key columns input for table: progress_weeks */
export interface IProgressWeeksPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "progress_weeks" */
export enum IProgressWeeksSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  GOAL_WEIGHT = 'goal_weight',
  /** column name */
  ID = 'id',
  /** column name */
  START_WEIGHT = 'start_weight',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WEEK_NUMBER = 'week_number'
}

/** input type for updating data in table "progress_weeks" */
export interface IProgressWeeksSetInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  ends_on?: Maybe<Scalars['date']>;
  goal_weight?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  start_weight?: Maybe<Scalars['numeric']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  week_number?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface IProgressWeeksStddevFields {
   __typename?: 'progress_weeks_stddev_fields';
  goal_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  week_number?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "progress_weeks" */
export interface IProgressWeeksStddevOrderBy {
  goal_weight?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  start_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  week_number?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IProgressWeeksStddevPopFields {
   __typename?: 'progress_weeks_stddev_pop_fields';
  goal_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  week_number?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "progress_weeks" */
export interface IProgressWeeksStddevPopOrderBy {
  goal_weight?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  start_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  week_number?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IProgressWeeksStddevSampFields {
   __typename?: 'progress_weeks_stddev_samp_fields';
  goal_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  week_number?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "progress_weeks" */
export interface IProgressWeeksStddevSampOrderBy {
  goal_weight?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  start_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  week_number?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IProgressWeeksSumFields {
   __typename?: 'progress_weeks_sum_fields';
  goal_weight?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  start_weight?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
  week_number?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "progress_weeks" */
export interface IProgressWeeksSumOrderBy {
  goal_weight?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  start_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  week_number?: Maybe<IOrderBy>;
}

/** update columns of table "progress_weeks" */
export enum IProgressWeeksUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  GOAL_WEIGHT = 'goal_weight',
  /** column name */
  ID = 'id',
  /** column name */
  START_WEIGHT = 'start_weight',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WEEK_NUMBER = 'week_number'
}

/** aggregate var_pop on columns */
export interface IProgressWeeksVarPopFields {
   __typename?: 'progress_weeks_var_pop_fields';
  goal_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  week_number?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "progress_weeks" */
export interface IProgressWeeksVarPopOrderBy {
  goal_weight?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  start_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  week_number?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IProgressWeeksVarSampFields {
   __typename?: 'progress_weeks_var_samp_fields';
  goal_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  week_number?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "progress_weeks" */
export interface IProgressWeeksVarSampOrderBy {
  goal_weight?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  start_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  week_number?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IProgressWeeksVarianceFields {
   __typename?: 'progress_weeks_variance_fields';
  goal_weight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
  week_number?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "progress_weeks" */
export interface IProgressWeeksVarianceOrderBy {
  goal_weight?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  start_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  week_number?: Maybe<IOrderBy>;
}

export interface IQueryRoot {
   __typename?: 'query_root';
  /** An array relationship */
  blog_articles: Array<IBlogArticles>;
  /** An aggregate relationship */
  blog_articles_aggregate: IBlogArticlesAggregate;
  /** fetch data from the table: "blog_articles" using primary key columns */
  blog_articles_by_pk?: Maybe<IBlogArticles>;
  /** fetch data from the table: "blog_categories" */
  blog_categories: Array<IBlogCategories>;
  /** fetch aggregated fields from the table: "blog_categories" */
  blog_categories_aggregate: IBlogCategoriesAggregate;
  /** fetch data from the table: "blog_categories" using primary key columns */
  blog_categories_by_pk?: Maybe<IBlogCategories>;
  /** fetch data from the table: "cookbook_categories" */
  cookbook_categories: Array<ICookbookCategories>;
  /** fetch aggregated fields from the table: "cookbook_categories" */
  cookbook_categories_aggregate: ICookbookCategoriesAggregate;
  /** fetch data from the table: "cookbook_categories" using primary key columns */
  cookbook_categories_by_pk?: Maybe<ICookbookCategories>;
  /** An array relationship */
  cookbook_recipes: Array<ICookbookRecipes>;
  /** An aggregate relationship */
  cookbook_recipes_aggregate: ICookbookRecipesAggregate;
  /** fetch data from the table: "cookbook_recipes" using primary key columns */
  cookbook_recipes_by_pk?: Maybe<ICookbookRecipes>;
  /** fetch data from the table: "diary_answers" */
  diary_answers: Array<IDiaryAnswers>;
  /** fetch aggregated fields from the table: "diary_answers" */
  diary_answers_aggregate: IDiaryAnswersAggregate;
  /** fetch data from the table: "diary_answers" using primary key columns */
  diary_answers_by_pk?: Maybe<IDiaryAnswers>;
  /** fetch data from the table: "diary_question_categories" */
  diary_question_categories: Array<IDiaryQuestionCategories>;
  /** fetch aggregated fields from the table: "diary_question_categories" */
  diary_question_categories_aggregate: IDiaryQuestionCategoriesAggregate;
  /** fetch data from the table: "diary_question_categories" using primary key columns */
  diary_question_categories_by_pk?: Maybe<IDiaryQuestionCategories>;
  /** fetch data from the table: "diary_question_types" */
  diary_question_types: Array<IDiaryQuestionTypes>;
  /** fetch aggregated fields from the table: "diary_question_types" */
  diary_question_types_aggregate: IDiaryQuestionTypesAggregate;
  /** fetch data from the table: "diary_question_types" using primary key columns */
  diary_question_types_by_pk?: Maybe<IDiaryQuestionTypes>;
  /** fetch data from the table: "diary_questions" */
  diary_questions: Array<IDiaryQuestions>;
  /** fetch aggregated fields from the table: "diary_questions" */
  diary_questions_aggregate: IDiaryQuestionsAggregate;
  /** fetch data from the table: "diary_questions" using primary key columns */
  diary_questions_by_pk?: Maybe<IDiaryQuestions>;
  /** fetch data from the table: "diet_summaries" */
  diet_summaries: Array<IDietSummaries>;
  /** fetch aggregated fields from the table: "diet_summaries" */
  diet_summaries_aggregate: IDietSummariesAggregate;
  /** fetch data from the table: "diet_summaries" using primary key columns */
  diet_summaries_by_pk?: Maybe<IDietSummaries>;
  /** fetch data from the table: "errors" */
  errors: Array<IErrors>;
  /** fetch aggregated fields from the table: "errors" */
  errors_aggregate: IErrorsAggregate;
  /** fetch data from the table: "errors" using primary key columns */
  errors_by_pk?: Maybe<IErrors>;
  /** fetch data from the table: "events" */
  events: Array<IEvents>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: IEventsAggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<IEvents>;
  /** An array relationship */
  exercise_day_progressions: Array<IExerciseDayProgressions>;
  /** An aggregate relationship */
  exercise_day_progressions_aggregate: IExerciseDayProgressionsAggregate;
  /** fetch data from the table: "exercise_day_progressions" using primary key columns */
  exercise_day_progressions_by_pk?: Maybe<IExerciseDayProgressions>;
  /** An array relationship */
  exercise_days: Array<IExerciseDays>;
  /** An aggregate relationship */
  exercise_days_aggregate: IExerciseDaysAggregate;
  /** fetch data from the table: "exercise_days" using primary key columns */
  exercise_days_by_pk?: Maybe<IExerciseDays>;
  /** fetch data from the table: "exercise_device_categories" */
  exercise_device_categories: Array<IExerciseDeviceCategories>;
  /** fetch aggregated fields from the table: "exercise_device_categories" */
  exercise_device_categories_aggregate: IExerciseDeviceCategoriesAggregate;
  /** fetch data from the table: "exercise_device_categories" using primary key columns */
  exercise_device_categories_by_pk?: Maybe<IExerciseDeviceCategories>;
  /** An array relationship */
  exercise_groups: Array<IExerciseGroups>;
  /** An aggregate relationship */
  exercise_groups_aggregate: IExerciseGroupsAggregate;
  /** fetch data from the table: "exercise_groups" using primary key columns */
  exercise_groups_by_pk?: Maybe<IExerciseGroups>;
  /** fetch data from the table: "exercise_muscle_categories" */
  exercise_muscle_categories: Array<IExerciseMuscleCategories>;
  /** fetch aggregated fields from the table: "exercise_muscle_categories" */
  exercise_muscle_categories_aggregate: IExerciseMuscleCategoriesAggregate;
  /** fetch data from the table: "exercise_muscle_categories" using primary key columns */
  exercise_muscle_categories_by_pk?: Maybe<IExerciseMuscleCategories>;
  /** fetch data from the table: "exercise_muscle_categories_enum" */
  exercise_muscle_categories_enum: Array<IExerciseMuscleCategoriesEnum>;
  /** fetch aggregated fields from the table: "exercise_muscle_categories_enum" */
  exercise_muscle_categories_enum_aggregate: IExerciseMuscleCategoriesEnumAggregate;
  /** fetch data from the table: "exercise_muscle_categories_enum" using primary key columns */
  exercise_muscle_categories_enum_by_pk?: Maybe<IExerciseMuscleCategoriesEnum>;
  /** An array relationship */
  exercise_progressions: Array<IExerciseProgressions>;
  /** An aggregate relationship */
  exercise_progressions_aggregate: IExerciseProgressionsAggregate;
  /** fetch data from the table: "exercise_progressions" using primary key columns */
  exercise_progressions_by_pk?: Maybe<IExerciseProgressions>;
  /** An array relationship */
  exercise_variants: Array<IExerciseVariants>;
  /** An aggregate relationship */
  exercise_variants_aggregate: IExerciseVariantsAggregate;
  /** fetch data from the table: "exercise_variants" using primary key columns */
  exercise_variants_by_pk?: Maybe<IExerciseVariants>;
  /** An array relationship */
  exercises: Array<IExercises>;
  /** An aggregate relationship */
  exercises_aggregate: IExercisesAggregate;
  /** fetch data from the table: "exercises" using primary key columns */
  exercises_by_pk?: Maybe<IExercises>;
  /** fetch data from the table: "feature_flags" */
  feature_flags: Array<IFeatureFlags>;
  /** fetch aggregated fields from the table: "feature_flags" */
  feature_flags_aggregate: IFeatureFlagsAggregate;
  /** fetch data from the table: "feature_flags" using primary key columns */
  feature_flags_by_pk?: Maybe<IFeatureFlags>;
  /** fetch data from the table: "inbox" */
  inbox: Array<IInbox>;
  /** fetch aggregated fields from the table: "inbox" */
  inbox_aggregate: IInboxAggregate;
  /** fetch data from the table: "inbox" using primary key columns */
  inbox_by_pk?: Maybe<IInbox>;
  /** An array relationship */
  inbox_messages: Array<IInboxMessages>;
  /** An aggregate relationship */
  inbox_messages_aggregate: IInboxMessagesAggregate;
  /** fetch data from the table: "inbox_messages" using primary key columns */
  inbox_messages_by_pk?: Maybe<IInboxMessages>;
  /** fetch data from the table: "inbox_user_view" */
  inbox_user_view: Array<IInboxUserView>;
  /** fetch aggregated fields from the table: "inbox_user_view" */
  inbox_user_view_aggregate: IInboxUserViewAggregate;
  /** fetch data from the table: "knowledge_base" */
  knowledge_base: Array<IKnowledgeBase>;
  /** fetch aggregated fields from the table: "knowledge_base" */
  knowledge_base_aggregate: IKnowledgeBaseAggregate;
  /** fetch data from the table: "knowledge_base" using primary key columns */
  knowledge_base_by_pk?: Maybe<IKnowledgeBase>;
  /** An array relationship */
  knowledge_base_video_progresses: Array<IKnowledgeBaseVideoProgresses>;
  /** An aggregate relationship */
  knowledge_base_video_progresses_aggregate: IKnowledgeBaseVideoProgressesAggregate;
  /** fetch data from the table: "knowledge_base_video_progresses" using primary key columns */
  knowledge_base_video_progresses_by_pk?: Maybe<IKnowledgeBaseVideoProgresses>;
  /** fetch data from the table: "knowledge_base_videos" */
  knowledge_base_videos: Array<IKnowledgeBaseVideos>;
  /** fetch aggregated fields from the table: "knowledge_base_videos" */
  knowledge_base_videos_aggregate: IKnowledgeBaseVideosAggregate;
  /** fetch data from the table: "knowledge_base_videos" using primary key columns */
  knowledge_base_videos_by_pk?: Maybe<IKnowledgeBaseVideos>;
  /** fetch data from the table: "meal_plans_view" */
  meal_plans_view: Array<IMealPlansView>;
  /** fetch aggregated fields from the table: "meal_plans_view" */
  meal_plans_view_aggregate: IMealPlansViewAggregate;
  /** An array relationship */
  meals_view: Array<IMealsView>;
  /** An aggregate relationship */
  meals_view_aggregate: IMealsViewAggregate;
  /** An array relationship */
  messages: Array<IMessages>;
  /** An aggregate relationship */
  messages_aggregate: IMessagesAggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<IMessages>;
  /** An array relationship */
  nutrition_meal_products: Array<INutritionMealProducts>;
  /** An aggregate relationship */
  nutrition_meal_products_aggregate: INutritionMealProductsAggregate;
  /** fetch data from the table: "nutrition_meal_products" using primary key columns */
  nutrition_meal_products_by_pk?: Maybe<INutritionMealProducts>;
  /** An array relationship */
  nutrition_meals: Array<INutritionMeals>;
  /** An aggregate relationship */
  nutrition_meals_aggregate: INutritionMealsAggregate;
  /** fetch data from the table: "nutrition_meals" using primary key columns */
  nutrition_meals_by_pk?: Maybe<INutritionMeals>;
  /** An array relationship */
  nutrition_plan_meals: Array<INutritionPlanMeals>;
  /** An aggregate relationship */
  nutrition_plan_meals_aggregate: INutritionPlanMealsAggregate;
  /** fetch data from the table: "nutrition_plan_meals" using primary key columns */
  nutrition_plan_meals_by_pk?: Maybe<INutritionPlanMeals>;
  /** fetch data from the table: "nutrition_plan_meals_view" */
  nutrition_plan_meals_view: Array<INutritionPlanMealsView>;
  /** fetch aggregated fields from the table: "nutrition_plan_meals_view" */
  nutrition_plan_meals_view_aggregate: INutritionPlanMealsViewAggregate;
  /** An array relationship */
  nutrition_plans: Array<INutritionPlans>;
  /** An aggregate relationship */
  nutrition_plans_aggregate: INutritionPlansAggregate;
  /** fetch data from the table: "nutrition_plans" using primary key columns */
  nutrition_plans_by_pk?: Maybe<INutritionPlans>;
  /** fetch data from the table: "product_categories" */
  product_categories: Array<IProductCategories>;
  /** fetch aggregated fields from the table: "product_categories" */
  product_categories_aggregate: IProductCategoriesAggregate;
  /** fetch data from the table: "product_categories" using primary key columns */
  product_categories_by_pk?: Maybe<IProductCategories>;
  /** fetch data from the table: "products_rg" */
  products_rg: Array<IProductsRg>;
  /** fetch aggregated fields from the table: "products_rg" */
  products_rg_aggregate: IProductsRgAggregate;
  /** fetch data from the table: "products_rg" using primary key columns */
  products_rg_by_pk?: Maybe<IProductsRg>;
  /** fetch data from the table: "products_rg_review" */
  products_rg_review: Array<IProductsRgReview>;
  /** fetch aggregated fields from the table: "products_rg_review" */
  products_rg_review_aggregate: IProductsRgReviewAggregate;
  /** fetch data from the table: "products_rg_review" using primary key columns */
  products_rg_review_by_pk?: Maybe<IProductsRgReview>;
  /** An array relationship */
  program_event_roles: Array<IProgramEventRoles>;
  /** An aggregate relationship */
  program_event_roles_aggregate: IProgramEventRolesAggregate;
  /** fetch data from the table: "program_event_roles" using primary key columns */
  program_event_roles_by_pk?: Maybe<IProgramEventRoles>;
  /** fetch data from the table: "program_events" */
  program_events: Array<IProgramEvents>;
  /** fetch aggregated fields from the table: "program_events" */
  program_events_aggregate: IProgramEventsAggregate;
  /** fetch data from the table: "program_events" using primary key columns */
  program_events_by_pk?: Maybe<IProgramEvents>;
  /** An array relationship */
  program_goals: Array<IProgramGoals>;
  /** An aggregate relationship */
  program_goals_aggregate: IProgramGoalsAggregate;
  /** fetch data from the table: "program_goals" using primary key columns */
  program_goals_by_pk?: Maybe<IProgramGoals>;
  /** An array relationship */
  program_messages: Array<IProgramMessages>;
  /** An aggregate relationship */
  program_messages_aggregate: IProgramMessagesAggregate;
  /** fetch data from the table: "program_messages" using primary key columns */
  program_messages_by_pk?: Maybe<IProgramMessages>;
  /** An array relationship */
  program_plan_employees: Array<IProgramPlanEmployees>;
  /** An aggregate relationship */
  program_plan_employees_aggregate: IProgramPlanEmployeesAggregate;
  /** fetch data from the table: "program_plan_employees" using primary key columns */
  program_plan_employees_by_pk?: Maybe<IProgramPlanEmployees>;
  /** An array relationship */
  program_plan_participators: Array<IProgramPlanParticipators>;
  /** An aggregate relationship */
  program_plan_participators_aggregate: IProgramPlanParticipatorsAggregate;
  /** fetch data from the table: "program_plan_participators" using primary key columns */
  program_plan_participators_by_pk?: Maybe<IProgramPlanParticipators>;
  /** An array relationship */
  program_plans: Array<IProgramPlans>;
  /** An aggregate relationship */
  program_plans_aggregate: IProgramPlansAggregate;
  /** fetch data from the table: "program_plans" using primary key columns */
  program_plans_by_pk?: Maybe<IProgramPlans>;
  /** fetch data from the table: "programs" */
  programs: Array<IPrograms>;
  /** fetch aggregated fields from the table: "programs" */
  programs_aggregate: IProgramsAggregate;
  /** fetch data from the table: "programs" using primary key columns */
  programs_by_pk?: Maybe<IPrograms>;
  /** fetch data from the table: "progress_days" */
  progress_days: Array<IProgressDays>;
  /** An aggregate relationship */
  progress_days_aggregate: IProgressDaysAggregate;
  /** fetch data from the table: "progress_days" using primary key columns */
  progress_days_by_pk?: Maybe<IProgressDays>;
  /** An array relationship */
  progress_weeks: Array<IProgressWeeks>;
  /** An aggregate relationship */
  progress_weeks_aggregate: IProgressWeeksAggregate;
  /** fetch data from the table: "progress_weeks" using primary key columns */
  progress_weeks_by_pk?: Maybe<IProgressWeeks>;
  /** fetch data from the table: "roles" */
  roles: Array<IRoles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: IRolesAggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<IRoles>;
  /** An array relationship */
  roles_users: Array<IRolesUsers>;
  /** An aggregate relationship */
  roles_users_aggregate: IRolesUsersAggregate;
  /** fetch data from the table: "roles_users" using primary key columns */
  roles_users_by_pk?: Maybe<IRolesUsers>;
  /** fetch data from the table: "user_coach_view" */
  user_coach_view: Array<IUserCoachView>;
  /** fetch aggregated fields from the table: "user_coach_view" */
  user_coach_view_aggregate: IUserCoachViewAggregate;
  /** An array relationship */
  user_profiles: Array<IUserProfiles>;
  /** An aggregate relationship */
  user_profiles_aggregate: IUserProfilesAggregate;
  /** fetch data from the table: "user_profiles" using primary key columns */
  user_profiles_by_pk?: Maybe<IUserProfiles>;
  /** fetch data from the table: "users" */
  users: Array<IUsers>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: IUsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<IUsers>;
  /** An array relationship */
  water_progress_days: Array<IWaterProgressDays>;
  /** An aggregate relationship */
  water_progress_days_aggregate: IWaterProgressDaysAggregate;
  /** fetch data from the table: "water_progress_days" using primary key columns */
  water_progress_days_by_pk?: Maybe<IWaterProgressDays>;
  /** An array relationship */
  workout_plans: Array<IWorkoutPlans>;
  /** An aggregate relationship */
  workout_plans_aggregate: IWorkoutPlansAggregate;
  /** fetch data from the table: "workout_plans" using primary key columns */
  workout_plans_by_pk?: Maybe<IWorkoutPlans>;
  /** fetch data from the table: "workout_plans_test_view" */
  workout_plans_test_view: Array<IWorkoutPlansTestView>;
  /** fetch aggregated fields from the table: "workout_plans_test_view" */
  workout_plans_test_view_aggregate: IWorkoutPlansTestViewAggregate;
  /** fetch data from the table: "workout_templates" */
  workout_templates: Array<IWorkoutTemplates>;
  /** fetch aggregated fields from the table: "workout_templates" */
  workout_templates_aggregate: IWorkoutTemplatesAggregate;
  /** fetch data from the table: "workout_templates" using primary key columns */
  workout_templates_by_pk?: Maybe<IWorkoutTemplates>;
}


export interface IQueryRootBlogArticlesArgs {
  distinct_on?: Maybe<Array<IBlogArticlesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IBlogArticlesOrderBy>>;
  where?: Maybe<IBlogArticlesBoolExp>;
}


export interface IQueryRootBlogArticlesAggregateArgs {
  distinct_on?: Maybe<Array<IBlogArticlesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IBlogArticlesOrderBy>>;
  where?: Maybe<IBlogArticlesBoolExp>;
}


export interface IQueryRootBlogArticlesByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootBlogCategoriesArgs {
  distinct_on?: Maybe<Array<IBlogCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IBlogCategoriesOrderBy>>;
  where?: Maybe<IBlogCategoriesBoolExp>;
}


export interface IQueryRootBlogCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<IBlogCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IBlogCategoriesOrderBy>>;
  where?: Maybe<IBlogCategoriesBoolExp>;
}


export interface IQueryRootBlogCategoriesByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootCookbookCategoriesArgs {
  distinct_on?: Maybe<Array<ICookbookCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICookbookCategoriesOrderBy>>;
  where?: Maybe<ICookbookCategoriesBoolExp>;
}


export interface IQueryRootCookbookCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<ICookbookCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICookbookCategoriesOrderBy>>;
  where?: Maybe<ICookbookCategoriesBoolExp>;
}


export interface IQueryRootCookbookCategoriesByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootCookbookRecipesArgs {
  distinct_on?: Maybe<Array<ICookbookRecipesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICookbookRecipesOrderBy>>;
  where?: Maybe<ICookbookRecipesBoolExp>;
}


export interface IQueryRootCookbookRecipesAggregateArgs {
  distinct_on?: Maybe<Array<ICookbookRecipesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICookbookRecipesOrderBy>>;
  where?: Maybe<ICookbookRecipesBoolExp>;
}


export interface IQueryRootCookbookRecipesByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootDiaryAnswersArgs {
  distinct_on?: Maybe<Array<IDiaryAnswersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryAnswersOrderBy>>;
  where?: Maybe<IDiaryAnswersBoolExp>;
}


export interface IQueryRootDiaryAnswersAggregateArgs {
  distinct_on?: Maybe<Array<IDiaryAnswersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryAnswersOrderBy>>;
  where?: Maybe<IDiaryAnswersBoolExp>;
}


export interface IQueryRootDiaryAnswersByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootDiaryQuestionCategoriesArgs {
  distinct_on?: Maybe<Array<IDiaryQuestionCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryQuestionCategoriesOrderBy>>;
  where?: Maybe<IDiaryQuestionCategoriesBoolExp>;
}


export interface IQueryRootDiaryQuestionCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<IDiaryQuestionCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryQuestionCategoriesOrderBy>>;
  where?: Maybe<IDiaryQuestionCategoriesBoolExp>;
}


export interface IQueryRootDiaryQuestionCategoriesByPkArgs {
  type: Scalars['String'];
}


export interface IQueryRootDiaryQuestionTypesArgs {
  distinct_on?: Maybe<Array<IDiaryQuestionTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryQuestionTypesOrderBy>>;
  where?: Maybe<IDiaryQuestionTypesBoolExp>;
}


export interface IQueryRootDiaryQuestionTypesAggregateArgs {
  distinct_on?: Maybe<Array<IDiaryQuestionTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryQuestionTypesOrderBy>>;
  where?: Maybe<IDiaryQuestionTypesBoolExp>;
}


export interface IQueryRootDiaryQuestionTypesByPkArgs {
  type: Scalars['String'];
}


export interface IQueryRootDiaryQuestionsArgs {
  distinct_on?: Maybe<Array<IDiaryQuestionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryQuestionsOrderBy>>;
  where?: Maybe<IDiaryQuestionsBoolExp>;
}


export interface IQueryRootDiaryQuestionsAggregateArgs {
  distinct_on?: Maybe<Array<IDiaryQuestionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryQuestionsOrderBy>>;
  where?: Maybe<IDiaryQuestionsBoolExp>;
}


export interface IQueryRootDiaryQuestionsByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootDietSummariesArgs {
  distinct_on?: Maybe<Array<IDietSummariesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDietSummariesOrderBy>>;
  where?: Maybe<IDietSummariesBoolExp>;
}


export interface IQueryRootDietSummariesAggregateArgs {
  distinct_on?: Maybe<Array<IDietSummariesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDietSummariesOrderBy>>;
  where?: Maybe<IDietSummariesBoolExp>;
}


export interface IQueryRootDietSummariesByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootErrorsArgs {
  distinct_on?: Maybe<Array<IErrorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IErrorsOrderBy>>;
  where?: Maybe<IErrorsBoolExp>;
}


export interface IQueryRootErrorsAggregateArgs {
  distinct_on?: Maybe<Array<IErrorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IErrorsOrderBy>>;
  where?: Maybe<IErrorsBoolExp>;
}


export interface IQueryRootErrorsByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootEventsArgs {
  distinct_on?: Maybe<Array<IEventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventsOrderBy>>;
  where?: Maybe<IEventsBoolExp>;
}


export interface IQueryRootEventsAggregateArgs {
  distinct_on?: Maybe<Array<IEventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventsOrderBy>>;
  where?: Maybe<IEventsBoolExp>;
}


export interface IQueryRootEventsByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootExerciseDayProgressionsArgs {
  distinct_on?: Maybe<Array<IExerciseDayProgressionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDayProgressionsOrderBy>>;
  where?: Maybe<IExerciseDayProgressionsBoolExp>;
}


export interface IQueryRootExerciseDayProgressionsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseDayProgressionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDayProgressionsOrderBy>>;
  where?: Maybe<IExerciseDayProgressionsBoolExp>;
}


export interface IQueryRootExerciseDayProgressionsByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootExerciseDaysArgs {
  distinct_on?: Maybe<Array<IExerciseDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDaysOrderBy>>;
  where?: Maybe<IExerciseDaysBoolExp>;
}


export interface IQueryRootExerciseDaysAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDaysOrderBy>>;
  where?: Maybe<IExerciseDaysBoolExp>;
}


export interface IQueryRootExerciseDaysByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootExerciseDeviceCategoriesArgs {
  distinct_on?: Maybe<Array<IExerciseDeviceCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDeviceCategoriesOrderBy>>;
  where?: Maybe<IExerciseDeviceCategoriesBoolExp>;
}


export interface IQueryRootExerciseDeviceCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseDeviceCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDeviceCategoriesOrderBy>>;
  where?: Maybe<IExerciseDeviceCategoriesBoolExp>;
}


export interface IQueryRootExerciseDeviceCategoriesByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootExerciseGroupsArgs {
  distinct_on?: Maybe<Array<IExerciseGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseGroupsOrderBy>>;
  where?: Maybe<IExerciseGroupsBoolExp>;
}


export interface IQueryRootExerciseGroupsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseGroupsOrderBy>>;
  where?: Maybe<IExerciseGroupsBoolExp>;
}


export interface IQueryRootExerciseGroupsByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootExerciseMuscleCategoriesArgs {
  distinct_on?: Maybe<Array<IExerciseMuscleCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseMuscleCategoriesOrderBy>>;
  where?: Maybe<IExerciseMuscleCategoriesBoolExp>;
}


export interface IQueryRootExerciseMuscleCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseMuscleCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseMuscleCategoriesOrderBy>>;
  where?: Maybe<IExerciseMuscleCategoriesBoolExp>;
}


export interface IQueryRootExerciseMuscleCategoriesByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootExerciseMuscleCategoriesEnumArgs {
  distinct_on?: Maybe<Array<IExerciseMuscleCategoriesEnumSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseMuscleCategoriesEnumOrderBy>>;
  where?: Maybe<IExerciseMuscleCategoriesEnumBoolExp>;
}


export interface IQueryRootExerciseMuscleCategoriesEnumAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseMuscleCategoriesEnumSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseMuscleCategoriesEnumOrderBy>>;
  where?: Maybe<IExerciseMuscleCategoriesEnumBoolExp>;
}


export interface IQueryRootExerciseMuscleCategoriesEnumByPkArgs {
  name: Scalars['String'];
}


export interface IQueryRootExerciseProgressionsArgs {
  distinct_on?: Maybe<Array<IExerciseProgressionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseProgressionsOrderBy>>;
  where?: Maybe<IExerciseProgressionsBoolExp>;
}


export interface IQueryRootExerciseProgressionsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseProgressionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseProgressionsOrderBy>>;
  where?: Maybe<IExerciseProgressionsBoolExp>;
}


export interface IQueryRootExerciseProgressionsByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootExerciseVariantsArgs {
  distinct_on?: Maybe<Array<IExerciseVariantsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseVariantsOrderBy>>;
  where?: Maybe<IExerciseVariantsBoolExp>;
}


export interface IQueryRootExerciseVariantsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseVariantsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseVariantsOrderBy>>;
  where?: Maybe<IExerciseVariantsBoolExp>;
}


export interface IQueryRootExerciseVariantsByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootExercisesArgs {
  distinct_on?: Maybe<Array<IExercisesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExercisesOrderBy>>;
  where?: Maybe<IExercisesBoolExp>;
}


export interface IQueryRootExercisesAggregateArgs {
  distinct_on?: Maybe<Array<IExercisesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExercisesOrderBy>>;
  where?: Maybe<IExercisesBoolExp>;
}


export interface IQueryRootExercisesByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootFeatureFlagsArgs {
  distinct_on?: Maybe<Array<IFeatureFlagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFeatureFlagsOrderBy>>;
  where?: Maybe<IFeatureFlagsBoolExp>;
}


export interface IQueryRootFeatureFlagsAggregateArgs {
  distinct_on?: Maybe<Array<IFeatureFlagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFeatureFlagsOrderBy>>;
  where?: Maybe<IFeatureFlagsBoolExp>;
}


export interface IQueryRootFeatureFlagsByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootInboxArgs {
  distinct_on?: Maybe<Array<IInboxSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxOrderBy>>;
  where?: Maybe<IInboxBoolExp>;
}


export interface IQueryRootInboxAggregateArgs {
  distinct_on?: Maybe<Array<IInboxSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxOrderBy>>;
  where?: Maybe<IInboxBoolExp>;
}


export interface IQueryRootInboxByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootInboxMessagesArgs {
  distinct_on?: Maybe<Array<IInboxMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxMessagesOrderBy>>;
  where?: Maybe<IInboxMessagesBoolExp>;
}


export interface IQueryRootInboxMessagesAggregateArgs {
  distinct_on?: Maybe<Array<IInboxMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxMessagesOrderBy>>;
  where?: Maybe<IInboxMessagesBoolExp>;
}


export interface IQueryRootInboxMessagesByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootInboxUserViewArgs {
  distinct_on?: Maybe<Array<IInboxUserViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxUserViewOrderBy>>;
  where?: Maybe<IInboxUserViewBoolExp>;
}


export interface IQueryRootInboxUserViewAggregateArgs {
  distinct_on?: Maybe<Array<IInboxUserViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxUserViewOrderBy>>;
  where?: Maybe<IInboxUserViewBoolExp>;
}


export interface IQueryRootKnowledgeBaseArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseOrderBy>>;
  where?: Maybe<IKnowledgeBaseBoolExp>;
}


export interface IQueryRootKnowledgeBaseAggregateArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseOrderBy>>;
  where?: Maybe<IKnowledgeBaseBoolExp>;
}


export interface IQueryRootKnowledgeBaseByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootKnowledgeBaseVideoProgressesArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseVideoProgressesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseVideoProgressesOrderBy>>;
  where?: Maybe<IKnowledgeBaseVideoProgressesBoolExp>;
}


export interface IQueryRootKnowledgeBaseVideoProgressesAggregateArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseVideoProgressesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseVideoProgressesOrderBy>>;
  where?: Maybe<IKnowledgeBaseVideoProgressesBoolExp>;
}


export interface IQueryRootKnowledgeBaseVideoProgressesByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootKnowledgeBaseVideosArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseVideosSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseVideosOrderBy>>;
  where?: Maybe<IKnowledgeBaseVideosBoolExp>;
}


export interface IQueryRootKnowledgeBaseVideosAggregateArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseVideosSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseVideosOrderBy>>;
  where?: Maybe<IKnowledgeBaseVideosBoolExp>;
}


export interface IQueryRootKnowledgeBaseVideosByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootMealPlansViewArgs {
  distinct_on?: Maybe<Array<IMealPlansViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMealPlansViewOrderBy>>;
  where?: Maybe<IMealPlansViewBoolExp>;
}


export interface IQueryRootMealPlansViewAggregateArgs {
  distinct_on?: Maybe<Array<IMealPlansViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMealPlansViewOrderBy>>;
  where?: Maybe<IMealPlansViewBoolExp>;
}


export interface IQueryRootMealsViewArgs {
  distinct_on?: Maybe<Array<IMealsViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMealsViewOrderBy>>;
  where?: Maybe<IMealsViewBoolExp>;
}


export interface IQueryRootMealsViewAggregateArgs {
  distinct_on?: Maybe<Array<IMealsViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMealsViewOrderBy>>;
  where?: Maybe<IMealsViewBoolExp>;
}


export interface IQueryRootMessagesArgs {
  distinct_on?: Maybe<Array<IMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMessagesOrderBy>>;
  where?: Maybe<IMessagesBoolExp>;
}


export interface IQueryRootMessagesAggregateArgs {
  distinct_on?: Maybe<Array<IMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMessagesOrderBy>>;
  where?: Maybe<IMessagesBoolExp>;
}


export interface IQueryRootMessagesByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootNutritionMealProductsArgs {
  distinct_on?: Maybe<Array<INutritionMealProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionMealProductsOrderBy>>;
  where?: Maybe<INutritionMealProductsBoolExp>;
}


export interface IQueryRootNutritionMealProductsAggregateArgs {
  distinct_on?: Maybe<Array<INutritionMealProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionMealProductsOrderBy>>;
  where?: Maybe<INutritionMealProductsBoolExp>;
}


export interface IQueryRootNutritionMealProductsByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootNutritionMealsArgs {
  distinct_on?: Maybe<Array<INutritionMealsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionMealsOrderBy>>;
  where?: Maybe<INutritionMealsBoolExp>;
}


export interface IQueryRootNutritionMealsAggregateArgs {
  distinct_on?: Maybe<Array<INutritionMealsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionMealsOrderBy>>;
  where?: Maybe<INutritionMealsBoolExp>;
}


export interface IQueryRootNutritionMealsByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootNutritionPlanMealsArgs {
  distinct_on?: Maybe<Array<INutritionPlanMealsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlanMealsOrderBy>>;
  where?: Maybe<INutritionPlanMealsBoolExp>;
}


export interface IQueryRootNutritionPlanMealsAggregateArgs {
  distinct_on?: Maybe<Array<INutritionPlanMealsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlanMealsOrderBy>>;
  where?: Maybe<INutritionPlanMealsBoolExp>;
}


export interface IQueryRootNutritionPlanMealsByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootNutritionPlanMealsViewArgs {
  distinct_on?: Maybe<Array<INutritionPlanMealsViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlanMealsViewOrderBy>>;
  where?: Maybe<INutritionPlanMealsViewBoolExp>;
}


export interface IQueryRootNutritionPlanMealsViewAggregateArgs {
  distinct_on?: Maybe<Array<INutritionPlanMealsViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlanMealsViewOrderBy>>;
  where?: Maybe<INutritionPlanMealsViewBoolExp>;
}


export interface IQueryRootNutritionPlansArgs {
  distinct_on?: Maybe<Array<INutritionPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlansOrderBy>>;
  where?: Maybe<INutritionPlansBoolExp>;
}


export interface IQueryRootNutritionPlansAggregateArgs {
  distinct_on?: Maybe<Array<INutritionPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlansOrderBy>>;
  where?: Maybe<INutritionPlansBoolExp>;
}


export interface IQueryRootNutritionPlansByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootProductCategoriesArgs {
  distinct_on?: Maybe<Array<IProductCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProductCategoriesOrderBy>>;
  where?: Maybe<IProductCategoriesBoolExp>;
}


export interface IQueryRootProductCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<IProductCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProductCategoriesOrderBy>>;
  where?: Maybe<IProductCategoriesBoolExp>;
}


export interface IQueryRootProductCategoriesByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootProductsRgArgs {
  distinct_on?: Maybe<Array<IProductsRgSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProductsRgOrderBy>>;
  where?: Maybe<IProductsRgBoolExp>;
}


export interface IQueryRootProductsRgAggregateArgs {
  distinct_on?: Maybe<Array<IProductsRgSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProductsRgOrderBy>>;
  where?: Maybe<IProductsRgBoolExp>;
}


export interface IQueryRootProductsRgByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootProductsRgReviewArgs {
  distinct_on?: Maybe<Array<IProductsRgReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProductsRgReviewOrderBy>>;
  where?: Maybe<IProductsRgReviewBoolExp>;
}


export interface IQueryRootProductsRgReviewAggregateArgs {
  distinct_on?: Maybe<Array<IProductsRgReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProductsRgReviewOrderBy>>;
  where?: Maybe<IProductsRgReviewBoolExp>;
}


export interface IQueryRootProductsRgReviewByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootProgramEventRolesArgs {
  distinct_on?: Maybe<Array<IProgramEventRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramEventRolesOrderBy>>;
  where?: Maybe<IProgramEventRolesBoolExp>;
}


export interface IQueryRootProgramEventRolesAggregateArgs {
  distinct_on?: Maybe<Array<IProgramEventRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramEventRolesOrderBy>>;
  where?: Maybe<IProgramEventRolesBoolExp>;
}


export interface IQueryRootProgramEventRolesByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootProgramEventsArgs {
  distinct_on?: Maybe<Array<IProgramEventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramEventsOrderBy>>;
  where?: Maybe<IProgramEventsBoolExp>;
}


export interface IQueryRootProgramEventsAggregateArgs {
  distinct_on?: Maybe<Array<IProgramEventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramEventsOrderBy>>;
  where?: Maybe<IProgramEventsBoolExp>;
}


export interface IQueryRootProgramEventsByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootProgramGoalsArgs {
  distinct_on?: Maybe<Array<IProgramGoalsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramGoalsOrderBy>>;
  where?: Maybe<IProgramGoalsBoolExp>;
}


export interface IQueryRootProgramGoalsAggregateArgs {
  distinct_on?: Maybe<Array<IProgramGoalsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramGoalsOrderBy>>;
  where?: Maybe<IProgramGoalsBoolExp>;
}


export interface IQueryRootProgramGoalsByPkArgs {
  id: Scalars['uuid'];
}


export interface IQueryRootProgramMessagesArgs {
  distinct_on?: Maybe<Array<IProgramMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramMessagesOrderBy>>;
  where?: Maybe<IProgramMessagesBoolExp>;
}


export interface IQueryRootProgramMessagesAggregateArgs {
  distinct_on?: Maybe<Array<IProgramMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramMessagesOrderBy>>;
  where?: Maybe<IProgramMessagesBoolExp>;
}


export interface IQueryRootProgramMessagesByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootProgramPlanEmployeesArgs {
  distinct_on?: Maybe<Array<IProgramPlanEmployeesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanEmployeesOrderBy>>;
  where?: Maybe<IProgramPlanEmployeesBoolExp>;
}


export interface IQueryRootProgramPlanEmployeesAggregateArgs {
  distinct_on?: Maybe<Array<IProgramPlanEmployeesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanEmployeesOrderBy>>;
  where?: Maybe<IProgramPlanEmployeesBoolExp>;
}


export interface IQueryRootProgramPlanEmployeesByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootProgramPlanParticipatorsArgs {
  distinct_on?: Maybe<Array<IProgramPlanParticipatorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanParticipatorsOrderBy>>;
  where?: Maybe<IProgramPlanParticipatorsBoolExp>;
}


export interface IQueryRootProgramPlanParticipatorsAggregateArgs {
  distinct_on?: Maybe<Array<IProgramPlanParticipatorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanParticipatorsOrderBy>>;
  where?: Maybe<IProgramPlanParticipatorsBoolExp>;
}


export interface IQueryRootProgramPlanParticipatorsByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootProgramPlansArgs {
  distinct_on?: Maybe<Array<IProgramPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlansOrderBy>>;
  where?: Maybe<IProgramPlansBoolExp>;
}


export interface IQueryRootProgramPlansAggregateArgs {
  distinct_on?: Maybe<Array<IProgramPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlansOrderBy>>;
  where?: Maybe<IProgramPlansBoolExp>;
}


export interface IQueryRootProgramPlansByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootProgramsArgs {
  distinct_on?: Maybe<Array<IProgramsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramsOrderBy>>;
  where?: Maybe<IProgramsBoolExp>;
}


export interface IQueryRootProgramsAggregateArgs {
  distinct_on?: Maybe<Array<IProgramsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramsOrderBy>>;
  where?: Maybe<IProgramsBoolExp>;
}


export interface IQueryRootProgramsByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootProgressDaysArgs {
  distinct_on?: Maybe<Array<IProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressDaysOrderBy>>;
  where?: Maybe<IProgressDaysBoolExp>;
}


export interface IQueryRootProgressDaysAggregateArgs {
  distinct_on?: Maybe<Array<IProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressDaysOrderBy>>;
  where?: Maybe<IProgressDaysBoolExp>;
}


export interface IQueryRootProgressDaysByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootProgressWeeksArgs {
  distinct_on?: Maybe<Array<IProgressWeeksSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressWeeksOrderBy>>;
  where?: Maybe<IProgressWeeksBoolExp>;
}


export interface IQueryRootProgressWeeksAggregateArgs {
  distinct_on?: Maybe<Array<IProgressWeeksSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressWeeksOrderBy>>;
  where?: Maybe<IProgressWeeksBoolExp>;
}


export interface IQueryRootProgressWeeksByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootRolesArgs {
  distinct_on?: Maybe<Array<IRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRolesOrderBy>>;
  where?: Maybe<IRolesBoolExp>;
}


export interface IQueryRootRolesAggregateArgs {
  distinct_on?: Maybe<Array<IRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRolesOrderBy>>;
  where?: Maybe<IRolesBoolExp>;
}


export interface IQueryRootRolesByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootRolesUsersArgs {
  distinct_on?: Maybe<Array<IRolesUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRolesUsersOrderBy>>;
  where?: Maybe<IRolesUsersBoolExp>;
}


export interface IQueryRootRolesUsersAggregateArgs {
  distinct_on?: Maybe<Array<IRolesUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRolesUsersOrderBy>>;
  where?: Maybe<IRolesUsersBoolExp>;
}


export interface IQueryRootRolesUsersByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootUserCoachViewArgs {
  distinct_on?: Maybe<Array<IUserCoachViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUserCoachViewOrderBy>>;
  where?: Maybe<IUserCoachViewBoolExp>;
}


export interface IQueryRootUserCoachViewAggregateArgs {
  distinct_on?: Maybe<Array<IUserCoachViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUserCoachViewOrderBy>>;
  where?: Maybe<IUserCoachViewBoolExp>;
}


export interface IQueryRootUserProfilesArgs {
  distinct_on?: Maybe<Array<IUserProfilesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUserProfilesOrderBy>>;
  where?: Maybe<IUserProfilesBoolExp>;
}


export interface IQueryRootUserProfilesAggregateArgs {
  distinct_on?: Maybe<Array<IUserProfilesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUserProfilesOrderBy>>;
  where?: Maybe<IUserProfilesBoolExp>;
}


export interface IQueryRootUserProfilesByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootUsersArgs {
  distinct_on?: Maybe<Array<IUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUsersOrderBy>>;
  where?: Maybe<IUsersBoolExp>;
}


export interface IQueryRootUsersAggregateArgs {
  distinct_on?: Maybe<Array<IUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUsersOrderBy>>;
  where?: Maybe<IUsersBoolExp>;
}


export interface IQueryRootUsersByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootWaterProgressDaysArgs {
  distinct_on?: Maybe<Array<IWaterProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWaterProgressDaysOrderBy>>;
  where?: Maybe<IWaterProgressDaysBoolExp>;
}


export interface IQueryRootWaterProgressDaysAggregateArgs {
  distinct_on?: Maybe<Array<IWaterProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWaterProgressDaysOrderBy>>;
  where?: Maybe<IWaterProgressDaysBoolExp>;
}


export interface IQueryRootWaterProgressDaysByPkArgs {
  id: Scalars['Int'];
}


export interface IQueryRootWorkoutPlansArgs {
  distinct_on?: Maybe<Array<IWorkoutPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutPlansOrderBy>>;
  where?: Maybe<IWorkoutPlansBoolExp>;
}


export interface IQueryRootWorkoutPlansAggregateArgs {
  distinct_on?: Maybe<Array<IWorkoutPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutPlansOrderBy>>;
  where?: Maybe<IWorkoutPlansBoolExp>;
}


export interface IQueryRootWorkoutPlansByPkArgs {
  id: Scalars['bigint'];
}


export interface IQueryRootWorkoutPlansTestViewArgs {
  distinct_on?: Maybe<Array<IWorkoutPlansTestViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutPlansTestViewOrderBy>>;
  where?: Maybe<IWorkoutPlansTestViewBoolExp>;
}


export interface IQueryRootWorkoutPlansTestViewAggregateArgs {
  distinct_on?: Maybe<Array<IWorkoutPlansTestViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutPlansTestViewOrderBy>>;
  where?: Maybe<IWorkoutPlansTestViewBoolExp>;
}


export interface IQueryRootWorkoutTemplatesArgs {
  distinct_on?: Maybe<Array<IWorkoutTemplatesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutTemplatesOrderBy>>;
  where?: Maybe<IWorkoutTemplatesBoolExp>;
}


export interface IQueryRootWorkoutTemplatesAggregateArgs {
  distinct_on?: Maybe<Array<IWorkoutTemplatesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutTemplatesOrderBy>>;
  where?: Maybe<IWorkoutTemplatesBoolExp>;
}


export interface IQueryRootWorkoutTemplatesByPkArgs {
  id: Scalars['bigint'];
}

/** columns and relationships of "roles" */
export interface IRoles {
   __typename?: 'roles';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  program_event_roles: Array<IProgramEventRoles>;
  /** An aggregate relationship */
  program_event_roles_aggregate: IProgramEventRolesAggregate;
  /** An array relationship */
  roles_users: Array<IRolesUsers>;
  /** An aggregate relationship */
  roles_users_aggregate: IRolesUsersAggregate;
  updated_at: Scalars['timestamp'];
}


/** columns and relationships of "roles" */
export interface IRolesProgramEventRolesArgs {
  distinct_on?: Maybe<Array<IProgramEventRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramEventRolesOrderBy>>;
  where?: Maybe<IProgramEventRolesBoolExp>;
}


/** columns and relationships of "roles" */
export interface IRolesProgramEventRolesAggregateArgs {
  distinct_on?: Maybe<Array<IProgramEventRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramEventRolesOrderBy>>;
  where?: Maybe<IProgramEventRolesBoolExp>;
}


/** columns and relationships of "roles" */
export interface IRolesRolesUsersArgs {
  distinct_on?: Maybe<Array<IRolesUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRolesUsersOrderBy>>;
  where?: Maybe<IRolesUsersBoolExp>;
}


/** columns and relationships of "roles" */
export interface IRolesRolesUsersAggregateArgs {
  distinct_on?: Maybe<Array<IRolesUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRolesUsersOrderBy>>;
  where?: Maybe<IRolesUsersBoolExp>;
}

/** aggregated selection of "roles" */
export interface IRolesAggregate {
   __typename?: 'roles_aggregate';
  aggregate?: Maybe<IRolesAggregateFields>;
  nodes: Array<IRoles>;
}

/** aggregate fields of "roles" */
export interface IRolesAggregateFields {
   __typename?: 'roles_aggregate_fields';
  avg?: Maybe<IRolesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IRolesMaxFields>;
  min?: Maybe<IRolesMinFields>;
  stddev?: Maybe<IRolesStddevFields>;
  stddev_pop?: Maybe<IRolesStddevPopFields>;
  stddev_samp?: Maybe<IRolesStddevSampFields>;
  sum?: Maybe<IRolesSumFields>;
  var_pop?: Maybe<IRolesVarPopFields>;
  var_samp?: Maybe<IRolesVarSampFields>;
  variance?: Maybe<IRolesVarianceFields>;
}


/** aggregate fields of "roles" */
export interface IRolesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IRolesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IRolesAvgFields {
   __typename?: 'roles_avg_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export interface IRolesBoolExp {
  _and?: Maybe<Array<IRolesBoolExp>>;
  _not?: Maybe<IRolesBoolExp>;
  _or?: Maybe<Array<IRolesBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  program_event_roles?: Maybe<IProgramEventRolesBoolExp>;
  roles_users?: Maybe<IRolesUsersBoolExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "roles" */
export enum IRolesConstraint {
  /** unique or primary key constraint */
  ROLES_PKEY = 'roles_pkey'
}

/** input type for incrementing numeric columns in table "roles" */
export interface IRolesIncInput {
  id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "roles" */
export interface IRolesInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  program_event_roles?: Maybe<IProgramEventRolesArrRelInsertInput>;
  roles_users?: Maybe<IRolesUsersArrRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IRolesMaxFields {
   __typename?: 'roles_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate min on columns */
export interface IRolesMinFields {
   __typename?: 'roles_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** response of any mutation on the table "roles" */
export interface IRolesMutationResponse {
   __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IRoles>;
}

/** input type for inserting object relation for remote table "roles" */
export interface IRolesObjRelInsertInput {
  data: IRolesInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IRolesOnConflict>;
}

/** on conflict condition type for table "roles" */
export interface IRolesOnConflict {
  constraint: IRolesConstraint;
  update_columns: Array<IRolesUpdateColumn>;
  where?: Maybe<IRolesBoolExp>;
}

/** Ordering options when selecting data from "roles". */
export interface IRolesOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  program_event_roles_aggregate?: Maybe<IProgramEventRolesAggregateOrderBy>;
  roles_users_aggregate?: Maybe<IRolesUsersAggregateOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: roles */
export interface IRolesPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "roles" */
export enum IRolesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "roles" */
export interface IRolesSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IRolesStddevFields {
   __typename?: 'roles_stddev_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IRolesStddevPopFields {
   __typename?: 'roles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IRolesStddevSampFields {
   __typename?: 'roles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IRolesSumFields {
   __typename?: 'roles_sum_fields';
  id?: Maybe<Scalars['bigint']>;
}

/** update columns of table "roles" */
export enum IRolesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** columns and relationships of "roles_users" */
export interface IRolesUsers {
   __typename?: 'roles_users';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  /** An object relationship */
  role?: Maybe<IRoles>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user?: Maybe<IUsers>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregated selection of "roles_users" */
export interface IRolesUsersAggregate {
   __typename?: 'roles_users_aggregate';
  aggregate?: Maybe<IRolesUsersAggregateFields>;
  nodes: Array<IRolesUsers>;
}

/** aggregate fields of "roles_users" */
export interface IRolesUsersAggregateFields {
   __typename?: 'roles_users_aggregate_fields';
  avg?: Maybe<IRolesUsersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IRolesUsersMaxFields>;
  min?: Maybe<IRolesUsersMinFields>;
  stddev?: Maybe<IRolesUsersStddevFields>;
  stddev_pop?: Maybe<IRolesUsersStddevPopFields>;
  stddev_samp?: Maybe<IRolesUsersStddevSampFields>;
  sum?: Maybe<IRolesUsersSumFields>;
  var_pop?: Maybe<IRolesUsersVarPopFields>;
  var_samp?: Maybe<IRolesUsersVarSampFields>;
  variance?: Maybe<IRolesUsersVarianceFields>;
}


/** aggregate fields of "roles_users" */
export interface IRolesUsersAggregateFieldsCountArgs {
  columns?: Maybe<Array<IRolesUsersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "roles_users" */
export interface IRolesUsersAggregateOrderBy {
  avg?: Maybe<IRolesUsersAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IRolesUsersMaxOrderBy>;
  min?: Maybe<IRolesUsersMinOrderBy>;
  stddev?: Maybe<IRolesUsersStddevOrderBy>;
  stddev_pop?: Maybe<IRolesUsersStddevPopOrderBy>;
  stddev_samp?: Maybe<IRolesUsersStddevSampOrderBy>;
  sum?: Maybe<IRolesUsersSumOrderBy>;
  var_pop?: Maybe<IRolesUsersVarPopOrderBy>;
  var_samp?: Maybe<IRolesUsersVarSampOrderBy>;
  variance?: Maybe<IRolesUsersVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "roles_users" */
export interface IRolesUsersArrRelInsertInput {
  data: Array<IRolesUsersInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IRolesUsersOnConflict>;
}

/** aggregate avg on columns */
export interface IRolesUsersAvgFields {
   __typename?: 'roles_users_avg_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "roles_users" */
export interface IRolesUsersAvgOrderBy {
  id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "roles_users". All fields are combined with a logical 'AND'. */
export interface IRolesUsersBoolExp {
  _and?: Maybe<Array<IRolesUsersBoolExp>>;
  _not?: Maybe<IRolesUsersBoolExp>;
  _or?: Maybe<Array<IRolesUsersBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  role?: Maybe<IRolesBoolExp>;
  role_id?: Maybe<IBigintComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
  user?: Maybe<IUsersBoolExp>;
  user_id?: Maybe<IBigintComparisonExp>;
}

/** unique or primary key constraints on table "roles_users" */
export enum IRolesUsersConstraint {
  /** unique or primary key constraint */
  ROLES_USERS_PKEY = 'roles_users_pkey'
}

/** input type for incrementing numeric columns in table "roles_users" */
export interface IRolesUsersIncInput {
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "roles_users" */
export interface IRolesUsersInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  role?: Maybe<IRolesObjRelInsertInput>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user?: Maybe<IUsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate max on columns */
export interface IRolesUsersMaxFields {
   __typename?: 'roles_users_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** order by max() on columns of table "roles_users" */
export interface IRolesUsersMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IRolesUsersMinFields {
   __typename?: 'roles_users_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** order by min() on columns of table "roles_users" */
export interface IRolesUsersMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "roles_users" */
export interface IRolesUsersMutationResponse {
   __typename?: 'roles_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IRolesUsers>;
}

/** on conflict condition type for table "roles_users" */
export interface IRolesUsersOnConflict {
  constraint: IRolesUsersConstraint;
  update_columns: Array<IRolesUsersUpdateColumn>;
  where?: Maybe<IRolesUsersBoolExp>;
}

/** Ordering options when selecting data from "roles_users". */
export interface IRolesUsersOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  role?: Maybe<IRolesOrderBy>;
  role_id?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user?: Maybe<IUsersOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** primary key columns input for table: roles_users */
export interface IRolesUsersPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "roles_users" */
export enum IRolesUsersSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  ROLE_ID = 'role_id',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "roles_users" */
export interface IRolesUsersSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate stddev on columns */
export interface IRolesUsersStddevFields {
   __typename?: 'roles_users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "roles_users" */
export interface IRolesUsersStddevOrderBy {
  id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IRolesUsersStddevPopFields {
   __typename?: 'roles_users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "roles_users" */
export interface IRolesUsersStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IRolesUsersStddevSampFields {
   __typename?: 'roles_users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "roles_users" */
export interface IRolesUsersStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IRolesUsersSumFields {
   __typename?: 'roles_users_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "roles_users" */
export interface IRolesUsersSumOrderBy {
  id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** update columns of table "roles_users" */
export enum IRolesUsersUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  ROLE_ID = 'role_id',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate var_pop on columns */
export interface IRolesUsersVarPopFields {
   __typename?: 'roles_users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "roles_users" */
export interface IRolesUsersVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IRolesUsersVarSampFields {
   __typename?: 'roles_users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "roles_users" */
export interface IRolesUsersVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IRolesUsersVarianceFields {
   __typename?: 'roles_users_variance_fields';
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "roles_users" */
export interface IRolesUsersVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  role_id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate var_pop on columns */
export interface IRolesVarPopFields {
   __typename?: 'roles_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IRolesVarSampFields {
   __typename?: 'roles_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IRolesVarianceFields {
   __typename?: 'roles_variance_fields';
  id?: Maybe<Scalars['Float']>;
}

export interface ISubscriptionRoot {
   __typename?: 'subscription_root';
  /** An array relationship */
  blog_articles: Array<IBlogArticles>;
  /** An aggregate relationship */
  blog_articles_aggregate: IBlogArticlesAggregate;
  /** fetch data from the table: "blog_articles" using primary key columns */
  blog_articles_by_pk?: Maybe<IBlogArticles>;
  /** fetch data from the table: "blog_categories" */
  blog_categories: Array<IBlogCategories>;
  /** fetch aggregated fields from the table: "blog_categories" */
  blog_categories_aggregate: IBlogCategoriesAggregate;
  /** fetch data from the table: "blog_categories" using primary key columns */
  blog_categories_by_pk?: Maybe<IBlogCategories>;
  /** fetch data from the table: "cookbook_categories" */
  cookbook_categories: Array<ICookbookCategories>;
  /** fetch aggregated fields from the table: "cookbook_categories" */
  cookbook_categories_aggregate: ICookbookCategoriesAggregate;
  /** fetch data from the table: "cookbook_categories" using primary key columns */
  cookbook_categories_by_pk?: Maybe<ICookbookCategories>;
  /** An array relationship */
  cookbook_recipes: Array<ICookbookRecipes>;
  /** An aggregate relationship */
  cookbook_recipes_aggregate: ICookbookRecipesAggregate;
  /** fetch data from the table: "cookbook_recipes" using primary key columns */
  cookbook_recipes_by_pk?: Maybe<ICookbookRecipes>;
  /** fetch data from the table: "diary_answers" */
  diary_answers: Array<IDiaryAnswers>;
  /** fetch aggregated fields from the table: "diary_answers" */
  diary_answers_aggregate: IDiaryAnswersAggregate;
  /** fetch data from the table: "diary_answers" using primary key columns */
  diary_answers_by_pk?: Maybe<IDiaryAnswers>;
  /** fetch data from the table: "diary_question_categories" */
  diary_question_categories: Array<IDiaryQuestionCategories>;
  /** fetch aggregated fields from the table: "diary_question_categories" */
  diary_question_categories_aggregate: IDiaryQuestionCategoriesAggregate;
  /** fetch data from the table: "diary_question_categories" using primary key columns */
  diary_question_categories_by_pk?: Maybe<IDiaryQuestionCategories>;
  /** fetch data from the table: "diary_question_types" */
  diary_question_types: Array<IDiaryQuestionTypes>;
  /** fetch aggregated fields from the table: "diary_question_types" */
  diary_question_types_aggregate: IDiaryQuestionTypesAggregate;
  /** fetch data from the table: "diary_question_types" using primary key columns */
  diary_question_types_by_pk?: Maybe<IDiaryQuestionTypes>;
  /** fetch data from the table: "diary_questions" */
  diary_questions: Array<IDiaryQuestions>;
  /** fetch aggregated fields from the table: "diary_questions" */
  diary_questions_aggregate: IDiaryQuestionsAggregate;
  /** fetch data from the table: "diary_questions" using primary key columns */
  diary_questions_by_pk?: Maybe<IDiaryQuestions>;
  /** fetch data from the table: "diet_summaries" */
  diet_summaries: Array<IDietSummaries>;
  /** fetch aggregated fields from the table: "diet_summaries" */
  diet_summaries_aggregate: IDietSummariesAggregate;
  /** fetch data from the table: "diet_summaries" using primary key columns */
  diet_summaries_by_pk?: Maybe<IDietSummaries>;
  /** fetch data from the table: "errors" */
  errors: Array<IErrors>;
  /** fetch aggregated fields from the table: "errors" */
  errors_aggregate: IErrorsAggregate;
  /** fetch data from the table: "errors" using primary key columns */
  errors_by_pk?: Maybe<IErrors>;
  /** fetch data from the table: "events" */
  events: Array<IEvents>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: IEventsAggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<IEvents>;
  /** An array relationship */
  exercise_day_progressions: Array<IExerciseDayProgressions>;
  /** An aggregate relationship */
  exercise_day_progressions_aggregate: IExerciseDayProgressionsAggregate;
  /** fetch data from the table: "exercise_day_progressions" using primary key columns */
  exercise_day_progressions_by_pk?: Maybe<IExerciseDayProgressions>;
  /** An array relationship */
  exercise_days: Array<IExerciseDays>;
  /** An aggregate relationship */
  exercise_days_aggregate: IExerciseDaysAggregate;
  /** fetch data from the table: "exercise_days" using primary key columns */
  exercise_days_by_pk?: Maybe<IExerciseDays>;
  /** fetch data from the table: "exercise_device_categories" */
  exercise_device_categories: Array<IExerciseDeviceCategories>;
  /** fetch aggregated fields from the table: "exercise_device_categories" */
  exercise_device_categories_aggregate: IExerciseDeviceCategoriesAggregate;
  /** fetch data from the table: "exercise_device_categories" using primary key columns */
  exercise_device_categories_by_pk?: Maybe<IExerciseDeviceCategories>;
  /** An array relationship */
  exercise_groups: Array<IExerciseGroups>;
  /** An aggregate relationship */
  exercise_groups_aggregate: IExerciseGroupsAggregate;
  /** fetch data from the table: "exercise_groups" using primary key columns */
  exercise_groups_by_pk?: Maybe<IExerciseGroups>;
  /** fetch data from the table: "exercise_muscle_categories" */
  exercise_muscle_categories: Array<IExerciseMuscleCategories>;
  /** fetch aggregated fields from the table: "exercise_muscle_categories" */
  exercise_muscle_categories_aggregate: IExerciseMuscleCategoriesAggregate;
  /** fetch data from the table: "exercise_muscle_categories" using primary key columns */
  exercise_muscle_categories_by_pk?: Maybe<IExerciseMuscleCategories>;
  /** fetch data from the table: "exercise_muscle_categories_enum" */
  exercise_muscle_categories_enum: Array<IExerciseMuscleCategoriesEnum>;
  /** fetch aggregated fields from the table: "exercise_muscle_categories_enum" */
  exercise_muscle_categories_enum_aggregate: IExerciseMuscleCategoriesEnumAggregate;
  /** fetch data from the table: "exercise_muscle_categories_enum" using primary key columns */
  exercise_muscle_categories_enum_by_pk?: Maybe<IExerciseMuscleCategoriesEnum>;
  /** An array relationship */
  exercise_progressions: Array<IExerciseProgressions>;
  /** An aggregate relationship */
  exercise_progressions_aggregate: IExerciseProgressionsAggregate;
  /** fetch data from the table: "exercise_progressions" using primary key columns */
  exercise_progressions_by_pk?: Maybe<IExerciseProgressions>;
  /** An array relationship */
  exercise_variants: Array<IExerciseVariants>;
  /** An aggregate relationship */
  exercise_variants_aggregate: IExerciseVariantsAggregate;
  /** fetch data from the table: "exercise_variants" using primary key columns */
  exercise_variants_by_pk?: Maybe<IExerciseVariants>;
  /** An array relationship */
  exercises: Array<IExercises>;
  /** An aggregate relationship */
  exercises_aggregate: IExercisesAggregate;
  /** fetch data from the table: "exercises" using primary key columns */
  exercises_by_pk?: Maybe<IExercises>;
  /** fetch data from the table: "feature_flags" */
  feature_flags: Array<IFeatureFlags>;
  /** fetch aggregated fields from the table: "feature_flags" */
  feature_flags_aggregate: IFeatureFlagsAggregate;
  /** fetch data from the table: "feature_flags" using primary key columns */
  feature_flags_by_pk?: Maybe<IFeatureFlags>;
  /** fetch data from the table: "inbox" */
  inbox: Array<IInbox>;
  /** fetch aggregated fields from the table: "inbox" */
  inbox_aggregate: IInboxAggregate;
  /** fetch data from the table: "inbox" using primary key columns */
  inbox_by_pk?: Maybe<IInbox>;
  /** An array relationship */
  inbox_messages: Array<IInboxMessages>;
  /** An aggregate relationship */
  inbox_messages_aggregate: IInboxMessagesAggregate;
  /** fetch data from the table: "inbox_messages" using primary key columns */
  inbox_messages_by_pk?: Maybe<IInboxMessages>;
  /** fetch data from the table: "inbox_user_view" */
  inbox_user_view: Array<IInboxUserView>;
  /** fetch aggregated fields from the table: "inbox_user_view" */
  inbox_user_view_aggregate: IInboxUserViewAggregate;
  /** fetch data from the table: "knowledge_base" */
  knowledge_base: Array<IKnowledgeBase>;
  /** fetch aggregated fields from the table: "knowledge_base" */
  knowledge_base_aggregate: IKnowledgeBaseAggregate;
  /** fetch data from the table: "knowledge_base" using primary key columns */
  knowledge_base_by_pk?: Maybe<IKnowledgeBase>;
  /** An array relationship */
  knowledge_base_video_progresses: Array<IKnowledgeBaseVideoProgresses>;
  /** An aggregate relationship */
  knowledge_base_video_progresses_aggregate: IKnowledgeBaseVideoProgressesAggregate;
  /** fetch data from the table: "knowledge_base_video_progresses" using primary key columns */
  knowledge_base_video_progresses_by_pk?: Maybe<IKnowledgeBaseVideoProgresses>;
  /** fetch data from the table: "knowledge_base_videos" */
  knowledge_base_videos: Array<IKnowledgeBaseVideos>;
  /** fetch aggregated fields from the table: "knowledge_base_videos" */
  knowledge_base_videos_aggregate: IKnowledgeBaseVideosAggregate;
  /** fetch data from the table: "knowledge_base_videos" using primary key columns */
  knowledge_base_videos_by_pk?: Maybe<IKnowledgeBaseVideos>;
  /** fetch data from the table: "meal_plans_view" */
  meal_plans_view: Array<IMealPlansView>;
  /** fetch aggregated fields from the table: "meal_plans_view" */
  meal_plans_view_aggregate: IMealPlansViewAggregate;
  /** An array relationship */
  meals_view: Array<IMealsView>;
  /** An aggregate relationship */
  meals_view_aggregate: IMealsViewAggregate;
  /** An array relationship */
  messages: Array<IMessages>;
  /** An aggregate relationship */
  messages_aggregate: IMessagesAggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<IMessages>;
  /** An array relationship */
  nutrition_meal_products: Array<INutritionMealProducts>;
  /** An aggregate relationship */
  nutrition_meal_products_aggregate: INutritionMealProductsAggregate;
  /** fetch data from the table: "nutrition_meal_products" using primary key columns */
  nutrition_meal_products_by_pk?: Maybe<INutritionMealProducts>;
  /** An array relationship */
  nutrition_meals: Array<INutritionMeals>;
  /** An aggregate relationship */
  nutrition_meals_aggregate: INutritionMealsAggregate;
  /** fetch data from the table: "nutrition_meals" using primary key columns */
  nutrition_meals_by_pk?: Maybe<INutritionMeals>;
  /** An array relationship */
  nutrition_plan_meals: Array<INutritionPlanMeals>;
  /** An aggregate relationship */
  nutrition_plan_meals_aggregate: INutritionPlanMealsAggregate;
  /** fetch data from the table: "nutrition_plan_meals" using primary key columns */
  nutrition_plan_meals_by_pk?: Maybe<INutritionPlanMeals>;
  /** fetch data from the table: "nutrition_plan_meals_view" */
  nutrition_plan_meals_view: Array<INutritionPlanMealsView>;
  /** fetch aggregated fields from the table: "nutrition_plan_meals_view" */
  nutrition_plan_meals_view_aggregate: INutritionPlanMealsViewAggregate;
  /** An array relationship */
  nutrition_plans: Array<INutritionPlans>;
  /** An aggregate relationship */
  nutrition_plans_aggregate: INutritionPlansAggregate;
  /** fetch data from the table: "nutrition_plans" using primary key columns */
  nutrition_plans_by_pk?: Maybe<INutritionPlans>;
  /** fetch data from the table: "product_categories" */
  product_categories: Array<IProductCategories>;
  /** fetch aggregated fields from the table: "product_categories" */
  product_categories_aggregate: IProductCategoriesAggregate;
  /** fetch data from the table: "product_categories" using primary key columns */
  product_categories_by_pk?: Maybe<IProductCategories>;
  /** fetch data from the table: "products_rg" */
  products_rg: Array<IProductsRg>;
  /** fetch aggregated fields from the table: "products_rg" */
  products_rg_aggregate: IProductsRgAggregate;
  /** fetch data from the table: "products_rg" using primary key columns */
  products_rg_by_pk?: Maybe<IProductsRg>;
  /** fetch data from the table: "products_rg_review" */
  products_rg_review: Array<IProductsRgReview>;
  /** fetch aggregated fields from the table: "products_rg_review" */
  products_rg_review_aggregate: IProductsRgReviewAggregate;
  /** fetch data from the table: "products_rg_review" using primary key columns */
  products_rg_review_by_pk?: Maybe<IProductsRgReview>;
  /** An array relationship */
  program_event_roles: Array<IProgramEventRoles>;
  /** An aggregate relationship */
  program_event_roles_aggregate: IProgramEventRolesAggregate;
  /** fetch data from the table: "program_event_roles" using primary key columns */
  program_event_roles_by_pk?: Maybe<IProgramEventRoles>;
  /** fetch data from the table: "program_events" */
  program_events: Array<IProgramEvents>;
  /** fetch aggregated fields from the table: "program_events" */
  program_events_aggregate: IProgramEventsAggregate;
  /** fetch data from the table: "program_events" using primary key columns */
  program_events_by_pk?: Maybe<IProgramEvents>;
  /** An array relationship */
  program_goals: Array<IProgramGoals>;
  /** An aggregate relationship */
  program_goals_aggregate: IProgramGoalsAggregate;
  /** fetch data from the table: "program_goals" using primary key columns */
  program_goals_by_pk?: Maybe<IProgramGoals>;
  /** An array relationship */
  program_messages: Array<IProgramMessages>;
  /** An aggregate relationship */
  program_messages_aggregate: IProgramMessagesAggregate;
  /** fetch data from the table: "program_messages" using primary key columns */
  program_messages_by_pk?: Maybe<IProgramMessages>;
  /** An array relationship */
  program_plan_employees: Array<IProgramPlanEmployees>;
  /** An aggregate relationship */
  program_plan_employees_aggregate: IProgramPlanEmployeesAggregate;
  /** fetch data from the table: "program_plan_employees" using primary key columns */
  program_plan_employees_by_pk?: Maybe<IProgramPlanEmployees>;
  /** An array relationship */
  program_plan_participators: Array<IProgramPlanParticipators>;
  /** An aggregate relationship */
  program_plan_participators_aggregate: IProgramPlanParticipatorsAggregate;
  /** fetch data from the table: "program_plan_participators" using primary key columns */
  program_plan_participators_by_pk?: Maybe<IProgramPlanParticipators>;
  /** An array relationship */
  program_plans: Array<IProgramPlans>;
  /** An aggregate relationship */
  program_plans_aggregate: IProgramPlansAggregate;
  /** fetch data from the table: "program_plans" using primary key columns */
  program_plans_by_pk?: Maybe<IProgramPlans>;
  /** fetch data from the table: "programs" */
  programs: Array<IPrograms>;
  /** fetch aggregated fields from the table: "programs" */
  programs_aggregate: IProgramsAggregate;
  /** fetch data from the table: "programs" using primary key columns */
  programs_by_pk?: Maybe<IPrograms>;
  /** fetch data from the table: "progress_days" */
  progress_days: Array<IProgressDays>;
  /** An aggregate relationship */
  progress_days_aggregate: IProgressDaysAggregate;
  /** fetch data from the table: "progress_days" using primary key columns */
  progress_days_by_pk?: Maybe<IProgressDays>;
  /** An array relationship */
  progress_weeks: Array<IProgressWeeks>;
  /** An aggregate relationship */
  progress_weeks_aggregate: IProgressWeeksAggregate;
  /** fetch data from the table: "progress_weeks" using primary key columns */
  progress_weeks_by_pk?: Maybe<IProgressWeeks>;
  /** fetch data from the table: "roles" */
  roles: Array<IRoles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: IRolesAggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<IRoles>;
  /** An array relationship */
  roles_users: Array<IRolesUsers>;
  /** An aggregate relationship */
  roles_users_aggregate: IRolesUsersAggregate;
  /** fetch data from the table: "roles_users" using primary key columns */
  roles_users_by_pk?: Maybe<IRolesUsers>;
  /** fetch data from the table: "user_coach_view" */
  user_coach_view: Array<IUserCoachView>;
  /** fetch aggregated fields from the table: "user_coach_view" */
  user_coach_view_aggregate: IUserCoachViewAggregate;
  /** An array relationship */
  user_profiles: Array<IUserProfiles>;
  /** An aggregate relationship */
  user_profiles_aggregate: IUserProfilesAggregate;
  /** fetch data from the table: "user_profiles" using primary key columns */
  user_profiles_by_pk?: Maybe<IUserProfiles>;
  /** fetch data from the table: "users" */
  users: Array<IUsers>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: IUsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<IUsers>;
  /** An array relationship */
  water_progress_days: Array<IWaterProgressDays>;
  /** An aggregate relationship */
  water_progress_days_aggregate: IWaterProgressDaysAggregate;
  /** fetch data from the table: "water_progress_days" using primary key columns */
  water_progress_days_by_pk?: Maybe<IWaterProgressDays>;
  /** An array relationship */
  workout_plans: Array<IWorkoutPlans>;
  /** An aggregate relationship */
  workout_plans_aggregate: IWorkoutPlansAggregate;
  /** fetch data from the table: "workout_plans" using primary key columns */
  workout_plans_by_pk?: Maybe<IWorkoutPlans>;
  /** fetch data from the table: "workout_plans_test_view" */
  workout_plans_test_view: Array<IWorkoutPlansTestView>;
  /** fetch aggregated fields from the table: "workout_plans_test_view" */
  workout_plans_test_view_aggregate: IWorkoutPlansTestViewAggregate;
  /** fetch data from the table: "workout_templates" */
  workout_templates: Array<IWorkoutTemplates>;
  /** fetch aggregated fields from the table: "workout_templates" */
  workout_templates_aggregate: IWorkoutTemplatesAggregate;
  /** fetch data from the table: "workout_templates" using primary key columns */
  workout_templates_by_pk?: Maybe<IWorkoutTemplates>;
}


export interface ISubscriptionRootBlogArticlesArgs {
  distinct_on?: Maybe<Array<IBlogArticlesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IBlogArticlesOrderBy>>;
  where?: Maybe<IBlogArticlesBoolExp>;
}


export interface ISubscriptionRootBlogArticlesAggregateArgs {
  distinct_on?: Maybe<Array<IBlogArticlesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IBlogArticlesOrderBy>>;
  where?: Maybe<IBlogArticlesBoolExp>;
}


export interface ISubscriptionRootBlogArticlesByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootBlogCategoriesArgs {
  distinct_on?: Maybe<Array<IBlogCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IBlogCategoriesOrderBy>>;
  where?: Maybe<IBlogCategoriesBoolExp>;
}


export interface ISubscriptionRootBlogCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<IBlogCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IBlogCategoriesOrderBy>>;
  where?: Maybe<IBlogCategoriesBoolExp>;
}


export interface ISubscriptionRootBlogCategoriesByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootCookbookCategoriesArgs {
  distinct_on?: Maybe<Array<ICookbookCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICookbookCategoriesOrderBy>>;
  where?: Maybe<ICookbookCategoriesBoolExp>;
}


export interface ISubscriptionRootCookbookCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<ICookbookCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICookbookCategoriesOrderBy>>;
  where?: Maybe<ICookbookCategoriesBoolExp>;
}


export interface ISubscriptionRootCookbookCategoriesByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootCookbookRecipesArgs {
  distinct_on?: Maybe<Array<ICookbookRecipesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICookbookRecipesOrderBy>>;
  where?: Maybe<ICookbookRecipesBoolExp>;
}


export interface ISubscriptionRootCookbookRecipesAggregateArgs {
  distinct_on?: Maybe<Array<ICookbookRecipesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ICookbookRecipesOrderBy>>;
  where?: Maybe<ICookbookRecipesBoolExp>;
}


export interface ISubscriptionRootCookbookRecipesByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootDiaryAnswersArgs {
  distinct_on?: Maybe<Array<IDiaryAnswersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryAnswersOrderBy>>;
  where?: Maybe<IDiaryAnswersBoolExp>;
}


export interface ISubscriptionRootDiaryAnswersAggregateArgs {
  distinct_on?: Maybe<Array<IDiaryAnswersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryAnswersOrderBy>>;
  where?: Maybe<IDiaryAnswersBoolExp>;
}


export interface ISubscriptionRootDiaryAnswersByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootDiaryQuestionCategoriesArgs {
  distinct_on?: Maybe<Array<IDiaryQuestionCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryQuestionCategoriesOrderBy>>;
  where?: Maybe<IDiaryQuestionCategoriesBoolExp>;
}


export interface ISubscriptionRootDiaryQuestionCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<IDiaryQuestionCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryQuestionCategoriesOrderBy>>;
  where?: Maybe<IDiaryQuestionCategoriesBoolExp>;
}


export interface ISubscriptionRootDiaryQuestionCategoriesByPkArgs {
  type: Scalars['String'];
}


export interface ISubscriptionRootDiaryQuestionTypesArgs {
  distinct_on?: Maybe<Array<IDiaryQuestionTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryQuestionTypesOrderBy>>;
  where?: Maybe<IDiaryQuestionTypesBoolExp>;
}


export interface ISubscriptionRootDiaryQuestionTypesAggregateArgs {
  distinct_on?: Maybe<Array<IDiaryQuestionTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryQuestionTypesOrderBy>>;
  where?: Maybe<IDiaryQuestionTypesBoolExp>;
}


export interface ISubscriptionRootDiaryQuestionTypesByPkArgs {
  type: Scalars['String'];
}


export interface ISubscriptionRootDiaryQuestionsArgs {
  distinct_on?: Maybe<Array<IDiaryQuestionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryQuestionsOrderBy>>;
  where?: Maybe<IDiaryQuestionsBoolExp>;
}


export interface ISubscriptionRootDiaryQuestionsAggregateArgs {
  distinct_on?: Maybe<Array<IDiaryQuestionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDiaryQuestionsOrderBy>>;
  where?: Maybe<IDiaryQuestionsBoolExp>;
}


export interface ISubscriptionRootDiaryQuestionsByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootDietSummariesArgs {
  distinct_on?: Maybe<Array<IDietSummariesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDietSummariesOrderBy>>;
  where?: Maybe<IDietSummariesBoolExp>;
}


export interface ISubscriptionRootDietSummariesAggregateArgs {
  distinct_on?: Maybe<Array<IDietSummariesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IDietSummariesOrderBy>>;
  where?: Maybe<IDietSummariesBoolExp>;
}


export interface ISubscriptionRootDietSummariesByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootErrorsArgs {
  distinct_on?: Maybe<Array<IErrorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IErrorsOrderBy>>;
  where?: Maybe<IErrorsBoolExp>;
}


export interface ISubscriptionRootErrorsAggregateArgs {
  distinct_on?: Maybe<Array<IErrorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IErrorsOrderBy>>;
  where?: Maybe<IErrorsBoolExp>;
}


export interface ISubscriptionRootErrorsByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootEventsArgs {
  distinct_on?: Maybe<Array<IEventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventsOrderBy>>;
  where?: Maybe<IEventsBoolExp>;
}


export interface ISubscriptionRootEventsAggregateArgs {
  distinct_on?: Maybe<Array<IEventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IEventsOrderBy>>;
  where?: Maybe<IEventsBoolExp>;
}


export interface ISubscriptionRootEventsByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootExerciseDayProgressionsArgs {
  distinct_on?: Maybe<Array<IExerciseDayProgressionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDayProgressionsOrderBy>>;
  where?: Maybe<IExerciseDayProgressionsBoolExp>;
}


export interface ISubscriptionRootExerciseDayProgressionsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseDayProgressionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDayProgressionsOrderBy>>;
  where?: Maybe<IExerciseDayProgressionsBoolExp>;
}


export interface ISubscriptionRootExerciseDayProgressionsByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootExerciseDaysArgs {
  distinct_on?: Maybe<Array<IExerciseDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDaysOrderBy>>;
  where?: Maybe<IExerciseDaysBoolExp>;
}


export interface ISubscriptionRootExerciseDaysAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDaysOrderBy>>;
  where?: Maybe<IExerciseDaysBoolExp>;
}


export interface ISubscriptionRootExerciseDaysByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootExerciseDeviceCategoriesArgs {
  distinct_on?: Maybe<Array<IExerciseDeviceCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDeviceCategoriesOrderBy>>;
  where?: Maybe<IExerciseDeviceCategoriesBoolExp>;
}


export interface ISubscriptionRootExerciseDeviceCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseDeviceCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDeviceCategoriesOrderBy>>;
  where?: Maybe<IExerciseDeviceCategoriesBoolExp>;
}


export interface ISubscriptionRootExerciseDeviceCategoriesByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootExerciseGroupsArgs {
  distinct_on?: Maybe<Array<IExerciseGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseGroupsOrderBy>>;
  where?: Maybe<IExerciseGroupsBoolExp>;
}


export interface ISubscriptionRootExerciseGroupsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseGroupsOrderBy>>;
  where?: Maybe<IExerciseGroupsBoolExp>;
}


export interface ISubscriptionRootExerciseGroupsByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootExerciseMuscleCategoriesArgs {
  distinct_on?: Maybe<Array<IExerciseMuscleCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseMuscleCategoriesOrderBy>>;
  where?: Maybe<IExerciseMuscleCategoriesBoolExp>;
}


export interface ISubscriptionRootExerciseMuscleCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseMuscleCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseMuscleCategoriesOrderBy>>;
  where?: Maybe<IExerciseMuscleCategoriesBoolExp>;
}


export interface ISubscriptionRootExerciseMuscleCategoriesByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootExerciseMuscleCategoriesEnumArgs {
  distinct_on?: Maybe<Array<IExerciseMuscleCategoriesEnumSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseMuscleCategoriesEnumOrderBy>>;
  where?: Maybe<IExerciseMuscleCategoriesEnumBoolExp>;
}


export interface ISubscriptionRootExerciseMuscleCategoriesEnumAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseMuscleCategoriesEnumSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseMuscleCategoriesEnumOrderBy>>;
  where?: Maybe<IExerciseMuscleCategoriesEnumBoolExp>;
}


export interface ISubscriptionRootExerciseMuscleCategoriesEnumByPkArgs {
  name: Scalars['String'];
}


export interface ISubscriptionRootExerciseProgressionsArgs {
  distinct_on?: Maybe<Array<IExerciseProgressionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseProgressionsOrderBy>>;
  where?: Maybe<IExerciseProgressionsBoolExp>;
}


export interface ISubscriptionRootExerciseProgressionsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseProgressionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseProgressionsOrderBy>>;
  where?: Maybe<IExerciseProgressionsBoolExp>;
}


export interface ISubscriptionRootExerciseProgressionsByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootExerciseVariantsArgs {
  distinct_on?: Maybe<Array<IExerciseVariantsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseVariantsOrderBy>>;
  where?: Maybe<IExerciseVariantsBoolExp>;
}


export interface ISubscriptionRootExerciseVariantsAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseVariantsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseVariantsOrderBy>>;
  where?: Maybe<IExerciseVariantsBoolExp>;
}


export interface ISubscriptionRootExerciseVariantsByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootExercisesArgs {
  distinct_on?: Maybe<Array<IExercisesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExercisesOrderBy>>;
  where?: Maybe<IExercisesBoolExp>;
}


export interface ISubscriptionRootExercisesAggregateArgs {
  distinct_on?: Maybe<Array<IExercisesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExercisesOrderBy>>;
  where?: Maybe<IExercisesBoolExp>;
}


export interface ISubscriptionRootExercisesByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootFeatureFlagsArgs {
  distinct_on?: Maybe<Array<IFeatureFlagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFeatureFlagsOrderBy>>;
  where?: Maybe<IFeatureFlagsBoolExp>;
}


export interface ISubscriptionRootFeatureFlagsAggregateArgs {
  distinct_on?: Maybe<Array<IFeatureFlagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IFeatureFlagsOrderBy>>;
  where?: Maybe<IFeatureFlagsBoolExp>;
}


export interface ISubscriptionRootFeatureFlagsByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootInboxArgs {
  distinct_on?: Maybe<Array<IInboxSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxOrderBy>>;
  where?: Maybe<IInboxBoolExp>;
}


export interface ISubscriptionRootInboxAggregateArgs {
  distinct_on?: Maybe<Array<IInboxSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxOrderBy>>;
  where?: Maybe<IInboxBoolExp>;
}


export interface ISubscriptionRootInboxByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootInboxMessagesArgs {
  distinct_on?: Maybe<Array<IInboxMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxMessagesOrderBy>>;
  where?: Maybe<IInboxMessagesBoolExp>;
}


export interface ISubscriptionRootInboxMessagesAggregateArgs {
  distinct_on?: Maybe<Array<IInboxMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxMessagesOrderBy>>;
  where?: Maybe<IInboxMessagesBoolExp>;
}


export interface ISubscriptionRootInboxMessagesByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootInboxUserViewArgs {
  distinct_on?: Maybe<Array<IInboxUserViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxUserViewOrderBy>>;
  where?: Maybe<IInboxUserViewBoolExp>;
}


export interface ISubscriptionRootInboxUserViewAggregateArgs {
  distinct_on?: Maybe<Array<IInboxUserViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IInboxUserViewOrderBy>>;
  where?: Maybe<IInboxUserViewBoolExp>;
}


export interface ISubscriptionRootKnowledgeBaseArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseOrderBy>>;
  where?: Maybe<IKnowledgeBaseBoolExp>;
}


export interface ISubscriptionRootKnowledgeBaseAggregateArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseOrderBy>>;
  where?: Maybe<IKnowledgeBaseBoolExp>;
}


export interface ISubscriptionRootKnowledgeBaseByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootKnowledgeBaseVideoProgressesArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseVideoProgressesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseVideoProgressesOrderBy>>;
  where?: Maybe<IKnowledgeBaseVideoProgressesBoolExp>;
}


export interface ISubscriptionRootKnowledgeBaseVideoProgressesAggregateArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseVideoProgressesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseVideoProgressesOrderBy>>;
  where?: Maybe<IKnowledgeBaseVideoProgressesBoolExp>;
}


export interface ISubscriptionRootKnowledgeBaseVideoProgressesByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootKnowledgeBaseVideosArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseVideosSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseVideosOrderBy>>;
  where?: Maybe<IKnowledgeBaseVideosBoolExp>;
}


export interface ISubscriptionRootKnowledgeBaseVideosAggregateArgs {
  distinct_on?: Maybe<Array<IKnowledgeBaseVideosSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IKnowledgeBaseVideosOrderBy>>;
  where?: Maybe<IKnowledgeBaseVideosBoolExp>;
}


export interface ISubscriptionRootKnowledgeBaseVideosByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootMealPlansViewArgs {
  distinct_on?: Maybe<Array<IMealPlansViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMealPlansViewOrderBy>>;
  where?: Maybe<IMealPlansViewBoolExp>;
}


export interface ISubscriptionRootMealPlansViewAggregateArgs {
  distinct_on?: Maybe<Array<IMealPlansViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMealPlansViewOrderBy>>;
  where?: Maybe<IMealPlansViewBoolExp>;
}


export interface ISubscriptionRootMealsViewArgs {
  distinct_on?: Maybe<Array<IMealsViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMealsViewOrderBy>>;
  where?: Maybe<IMealsViewBoolExp>;
}


export interface ISubscriptionRootMealsViewAggregateArgs {
  distinct_on?: Maybe<Array<IMealsViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMealsViewOrderBy>>;
  where?: Maybe<IMealsViewBoolExp>;
}


export interface ISubscriptionRootMessagesArgs {
  distinct_on?: Maybe<Array<IMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMessagesOrderBy>>;
  where?: Maybe<IMessagesBoolExp>;
}


export interface ISubscriptionRootMessagesAggregateArgs {
  distinct_on?: Maybe<Array<IMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IMessagesOrderBy>>;
  where?: Maybe<IMessagesBoolExp>;
}


export interface ISubscriptionRootMessagesByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootNutritionMealProductsArgs {
  distinct_on?: Maybe<Array<INutritionMealProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionMealProductsOrderBy>>;
  where?: Maybe<INutritionMealProductsBoolExp>;
}


export interface ISubscriptionRootNutritionMealProductsAggregateArgs {
  distinct_on?: Maybe<Array<INutritionMealProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionMealProductsOrderBy>>;
  where?: Maybe<INutritionMealProductsBoolExp>;
}


export interface ISubscriptionRootNutritionMealProductsByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootNutritionMealsArgs {
  distinct_on?: Maybe<Array<INutritionMealsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionMealsOrderBy>>;
  where?: Maybe<INutritionMealsBoolExp>;
}


export interface ISubscriptionRootNutritionMealsAggregateArgs {
  distinct_on?: Maybe<Array<INutritionMealsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionMealsOrderBy>>;
  where?: Maybe<INutritionMealsBoolExp>;
}


export interface ISubscriptionRootNutritionMealsByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootNutritionPlanMealsArgs {
  distinct_on?: Maybe<Array<INutritionPlanMealsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlanMealsOrderBy>>;
  where?: Maybe<INutritionPlanMealsBoolExp>;
}


export interface ISubscriptionRootNutritionPlanMealsAggregateArgs {
  distinct_on?: Maybe<Array<INutritionPlanMealsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlanMealsOrderBy>>;
  where?: Maybe<INutritionPlanMealsBoolExp>;
}


export interface ISubscriptionRootNutritionPlanMealsByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootNutritionPlanMealsViewArgs {
  distinct_on?: Maybe<Array<INutritionPlanMealsViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlanMealsViewOrderBy>>;
  where?: Maybe<INutritionPlanMealsViewBoolExp>;
}


export interface ISubscriptionRootNutritionPlanMealsViewAggregateArgs {
  distinct_on?: Maybe<Array<INutritionPlanMealsViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlanMealsViewOrderBy>>;
  where?: Maybe<INutritionPlanMealsViewBoolExp>;
}


export interface ISubscriptionRootNutritionPlansArgs {
  distinct_on?: Maybe<Array<INutritionPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlansOrderBy>>;
  where?: Maybe<INutritionPlansBoolExp>;
}


export interface ISubscriptionRootNutritionPlansAggregateArgs {
  distinct_on?: Maybe<Array<INutritionPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlansOrderBy>>;
  where?: Maybe<INutritionPlansBoolExp>;
}


export interface ISubscriptionRootNutritionPlansByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootProductCategoriesArgs {
  distinct_on?: Maybe<Array<IProductCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProductCategoriesOrderBy>>;
  where?: Maybe<IProductCategoriesBoolExp>;
}


export interface ISubscriptionRootProductCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<IProductCategoriesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProductCategoriesOrderBy>>;
  where?: Maybe<IProductCategoriesBoolExp>;
}


export interface ISubscriptionRootProductCategoriesByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootProductsRgArgs {
  distinct_on?: Maybe<Array<IProductsRgSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProductsRgOrderBy>>;
  where?: Maybe<IProductsRgBoolExp>;
}


export interface ISubscriptionRootProductsRgAggregateArgs {
  distinct_on?: Maybe<Array<IProductsRgSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProductsRgOrderBy>>;
  where?: Maybe<IProductsRgBoolExp>;
}


export interface ISubscriptionRootProductsRgByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootProductsRgReviewArgs {
  distinct_on?: Maybe<Array<IProductsRgReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProductsRgReviewOrderBy>>;
  where?: Maybe<IProductsRgReviewBoolExp>;
}


export interface ISubscriptionRootProductsRgReviewAggregateArgs {
  distinct_on?: Maybe<Array<IProductsRgReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProductsRgReviewOrderBy>>;
  where?: Maybe<IProductsRgReviewBoolExp>;
}


export interface ISubscriptionRootProductsRgReviewByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootProgramEventRolesArgs {
  distinct_on?: Maybe<Array<IProgramEventRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramEventRolesOrderBy>>;
  where?: Maybe<IProgramEventRolesBoolExp>;
}


export interface ISubscriptionRootProgramEventRolesAggregateArgs {
  distinct_on?: Maybe<Array<IProgramEventRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramEventRolesOrderBy>>;
  where?: Maybe<IProgramEventRolesBoolExp>;
}


export interface ISubscriptionRootProgramEventRolesByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootProgramEventsArgs {
  distinct_on?: Maybe<Array<IProgramEventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramEventsOrderBy>>;
  where?: Maybe<IProgramEventsBoolExp>;
}


export interface ISubscriptionRootProgramEventsAggregateArgs {
  distinct_on?: Maybe<Array<IProgramEventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramEventsOrderBy>>;
  where?: Maybe<IProgramEventsBoolExp>;
}


export interface ISubscriptionRootProgramEventsByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootProgramGoalsArgs {
  distinct_on?: Maybe<Array<IProgramGoalsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramGoalsOrderBy>>;
  where?: Maybe<IProgramGoalsBoolExp>;
}


export interface ISubscriptionRootProgramGoalsAggregateArgs {
  distinct_on?: Maybe<Array<IProgramGoalsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramGoalsOrderBy>>;
  where?: Maybe<IProgramGoalsBoolExp>;
}


export interface ISubscriptionRootProgramGoalsByPkArgs {
  id: Scalars['uuid'];
}


export interface ISubscriptionRootProgramMessagesArgs {
  distinct_on?: Maybe<Array<IProgramMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramMessagesOrderBy>>;
  where?: Maybe<IProgramMessagesBoolExp>;
}


export interface ISubscriptionRootProgramMessagesAggregateArgs {
  distinct_on?: Maybe<Array<IProgramMessagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramMessagesOrderBy>>;
  where?: Maybe<IProgramMessagesBoolExp>;
}


export interface ISubscriptionRootProgramMessagesByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootProgramPlanEmployeesArgs {
  distinct_on?: Maybe<Array<IProgramPlanEmployeesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanEmployeesOrderBy>>;
  where?: Maybe<IProgramPlanEmployeesBoolExp>;
}


export interface ISubscriptionRootProgramPlanEmployeesAggregateArgs {
  distinct_on?: Maybe<Array<IProgramPlanEmployeesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanEmployeesOrderBy>>;
  where?: Maybe<IProgramPlanEmployeesBoolExp>;
}


export interface ISubscriptionRootProgramPlanEmployeesByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootProgramPlanParticipatorsArgs {
  distinct_on?: Maybe<Array<IProgramPlanParticipatorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanParticipatorsOrderBy>>;
  where?: Maybe<IProgramPlanParticipatorsBoolExp>;
}


export interface ISubscriptionRootProgramPlanParticipatorsAggregateArgs {
  distinct_on?: Maybe<Array<IProgramPlanParticipatorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanParticipatorsOrderBy>>;
  where?: Maybe<IProgramPlanParticipatorsBoolExp>;
}


export interface ISubscriptionRootProgramPlanParticipatorsByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootProgramPlansArgs {
  distinct_on?: Maybe<Array<IProgramPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlansOrderBy>>;
  where?: Maybe<IProgramPlansBoolExp>;
}


export interface ISubscriptionRootProgramPlansAggregateArgs {
  distinct_on?: Maybe<Array<IProgramPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlansOrderBy>>;
  where?: Maybe<IProgramPlansBoolExp>;
}


export interface ISubscriptionRootProgramPlansByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootProgramsArgs {
  distinct_on?: Maybe<Array<IProgramsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramsOrderBy>>;
  where?: Maybe<IProgramsBoolExp>;
}


export interface ISubscriptionRootProgramsAggregateArgs {
  distinct_on?: Maybe<Array<IProgramsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramsOrderBy>>;
  where?: Maybe<IProgramsBoolExp>;
}


export interface ISubscriptionRootProgramsByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootProgressDaysArgs {
  distinct_on?: Maybe<Array<IProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressDaysOrderBy>>;
  where?: Maybe<IProgressDaysBoolExp>;
}


export interface ISubscriptionRootProgressDaysAggregateArgs {
  distinct_on?: Maybe<Array<IProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressDaysOrderBy>>;
  where?: Maybe<IProgressDaysBoolExp>;
}


export interface ISubscriptionRootProgressDaysByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootProgressWeeksArgs {
  distinct_on?: Maybe<Array<IProgressWeeksSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressWeeksOrderBy>>;
  where?: Maybe<IProgressWeeksBoolExp>;
}


export interface ISubscriptionRootProgressWeeksAggregateArgs {
  distinct_on?: Maybe<Array<IProgressWeeksSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressWeeksOrderBy>>;
  where?: Maybe<IProgressWeeksBoolExp>;
}


export interface ISubscriptionRootProgressWeeksByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootRolesArgs {
  distinct_on?: Maybe<Array<IRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRolesOrderBy>>;
  where?: Maybe<IRolesBoolExp>;
}


export interface ISubscriptionRootRolesAggregateArgs {
  distinct_on?: Maybe<Array<IRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRolesOrderBy>>;
  where?: Maybe<IRolesBoolExp>;
}


export interface ISubscriptionRootRolesByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootRolesUsersArgs {
  distinct_on?: Maybe<Array<IRolesUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRolesUsersOrderBy>>;
  where?: Maybe<IRolesUsersBoolExp>;
}


export interface ISubscriptionRootRolesUsersAggregateArgs {
  distinct_on?: Maybe<Array<IRolesUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRolesUsersOrderBy>>;
  where?: Maybe<IRolesUsersBoolExp>;
}


export interface ISubscriptionRootRolesUsersByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootUserCoachViewArgs {
  distinct_on?: Maybe<Array<IUserCoachViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUserCoachViewOrderBy>>;
  where?: Maybe<IUserCoachViewBoolExp>;
}


export interface ISubscriptionRootUserCoachViewAggregateArgs {
  distinct_on?: Maybe<Array<IUserCoachViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUserCoachViewOrderBy>>;
  where?: Maybe<IUserCoachViewBoolExp>;
}


export interface ISubscriptionRootUserProfilesArgs {
  distinct_on?: Maybe<Array<IUserProfilesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUserProfilesOrderBy>>;
  where?: Maybe<IUserProfilesBoolExp>;
}


export interface ISubscriptionRootUserProfilesAggregateArgs {
  distinct_on?: Maybe<Array<IUserProfilesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUserProfilesOrderBy>>;
  where?: Maybe<IUserProfilesBoolExp>;
}


export interface ISubscriptionRootUserProfilesByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootUsersArgs {
  distinct_on?: Maybe<Array<IUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUsersOrderBy>>;
  where?: Maybe<IUsersBoolExp>;
}


export interface ISubscriptionRootUsersAggregateArgs {
  distinct_on?: Maybe<Array<IUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUsersOrderBy>>;
  where?: Maybe<IUsersBoolExp>;
}


export interface ISubscriptionRootUsersByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootWaterProgressDaysArgs {
  distinct_on?: Maybe<Array<IWaterProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWaterProgressDaysOrderBy>>;
  where?: Maybe<IWaterProgressDaysBoolExp>;
}


export interface ISubscriptionRootWaterProgressDaysAggregateArgs {
  distinct_on?: Maybe<Array<IWaterProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWaterProgressDaysOrderBy>>;
  where?: Maybe<IWaterProgressDaysBoolExp>;
}


export interface ISubscriptionRootWaterProgressDaysByPkArgs {
  id: Scalars['Int'];
}


export interface ISubscriptionRootWorkoutPlansArgs {
  distinct_on?: Maybe<Array<IWorkoutPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutPlansOrderBy>>;
  where?: Maybe<IWorkoutPlansBoolExp>;
}


export interface ISubscriptionRootWorkoutPlansAggregateArgs {
  distinct_on?: Maybe<Array<IWorkoutPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutPlansOrderBy>>;
  where?: Maybe<IWorkoutPlansBoolExp>;
}


export interface ISubscriptionRootWorkoutPlansByPkArgs {
  id: Scalars['bigint'];
}


export interface ISubscriptionRootWorkoutPlansTestViewArgs {
  distinct_on?: Maybe<Array<IWorkoutPlansTestViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutPlansTestViewOrderBy>>;
  where?: Maybe<IWorkoutPlansTestViewBoolExp>;
}


export interface ISubscriptionRootWorkoutPlansTestViewAggregateArgs {
  distinct_on?: Maybe<Array<IWorkoutPlansTestViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutPlansTestViewOrderBy>>;
  where?: Maybe<IWorkoutPlansTestViewBoolExp>;
}


export interface ISubscriptionRootWorkoutTemplatesArgs {
  distinct_on?: Maybe<Array<IWorkoutTemplatesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutTemplatesOrderBy>>;
  where?: Maybe<IWorkoutTemplatesBoolExp>;
}


export interface ISubscriptionRootWorkoutTemplatesAggregateArgs {
  distinct_on?: Maybe<Array<IWorkoutTemplatesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutTemplatesOrderBy>>;
  where?: Maybe<IWorkoutTemplatesBoolExp>;
}


export interface ISubscriptionRootWorkoutTemplatesByPkArgs {
  id: Scalars['bigint'];
}


/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export interface ITimeComparisonExp {
  _eq?: Maybe<Scalars['time']>;
  _gt?: Maybe<Scalars['time']>;
  _gte?: Maybe<Scalars['time']>;
  _in?: Maybe<Array<Scalars['time']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['time']>;
  _lte?: Maybe<Scalars['time']>;
  _neq?: Maybe<Scalars['time']>;
  _nin?: Maybe<Array<Scalars['time']>>;
}


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export interface ITimestampComparisonExp {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
}


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface ITimestamptzComparisonExp {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
}

/**
 * This view exposes the name of the coach for a certain user. It's currently being used to start a new chat (insert inbox record) with his/her coach.
 * 
 * 
 * columns and relationships of "user_coach_view"
 */
export interface IUserCoachView {
   __typename?: 'user_coach_view';
  client_first_name?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['bigint']>;
  client_last_name?: Maybe<Scalars['String']>;
  coach_id?: Maybe<Scalars['bigint']>;
  coach_name?: Maybe<Scalars['String']>;
}

/** aggregated selection of "user_coach_view" */
export interface IUserCoachViewAggregate {
   __typename?: 'user_coach_view_aggregate';
  aggregate?: Maybe<IUserCoachViewAggregateFields>;
  nodes: Array<IUserCoachView>;
}

/** aggregate fields of "user_coach_view" */
export interface IUserCoachViewAggregateFields {
   __typename?: 'user_coach_view_aggregate_fields';
  avg?: Maybe<IUserCoachViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IUserCoachViewMaxFields>;
  min?: Maybe<IUserCoachViewMinFields>;
  stddev?: Maybe<IUserCoachViewStddevFields>;
  stddev_pop?: Maybe<IUserCoachViewStddevPopFields>;
  stddev_samp?: Maybe<IUserCoachViewStddevSampFields>;
  sum?: Maybe<IUserCoachViewSumFields>;
  var_pop?: Maybe<IUserCoachViewVarPopFields>;
  var_samp?: Maybe<IUserCoachViewVarSampFields>;
  variance?: Maybe<IUserCoachViewVarianceFields>;
}


/** aggregate fields of "user_coach_view" */
export interface IUserCoachViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<IUserCoachViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IUserCoachViewAvgFields {
   __typename?: 'user_coach_view_avg_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "user_coach_view". All fields are combined with a logical 'AND'. */
export interface IUserCoachViewBoolExp {
  _and?: Maybe<Array<IUserCoachViewBoolExp>>;
  _not?: Maybe<IUserCoachViewBoolExp>;
  _or?: Maybe<Array<IUserCoachViewBoolExp>>;
  client_first_name?: Maybe<IStringComparisonExp>;
  client_id?: Maybe<IBigintComparisonExp>;
  client_last_name?: Maybe<IStringComparisonExp>;
  coach_id?: Maybe<IBigintComparisonExp>;
  coach_name?: Maybe<IStringComparisonExp>;
}

/** aggregate max on columns */
export interface IUserCoachViewMaxFields {
   __typename?: 'user_coach_view_max_fields';
  client_first_name?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['bigint']>;
  client_last_name?: Maybe<Scalars['String']>;
  coach_id?: Maybe<Scalars['bigint']>;
  coach_name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IUserCoachViewMinFields {
   __typename?: 'user_coach_view_min_fields';
  client_first_name?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['bigint']>;
  client_last_name?: Maybe<Scalars['String']>;
  coach_id?: Maybe<Scalars['bigint']>;
  coach_name?: Maybe<Scalars['String']>;
}

/** Ordering options when selecting data from "user_coach_view". */
export interface IUserCoachViewOrderBy {
  client_first_name?: Maybe<IOrderBy>;
  client_id?: Maybe<IOrderBy>;
  client_last_name?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  coach_name?: Maybe<IOrderBy>;
}

/** select columns of table "user_coach_view" */
export enum IUserCoachViewSelectColumn {
  /** column name */
  CLIENT_FIRST_NAME = 'client_first_name',
  /** column name */
  CLIENT_ID = 'client_id',
  /** column name */
  CLIENT_LAST_NAME = 'client_last_name',
  /** column name */
  COACH_ID = 'coach_id',
  /** column name */
  COACH_NAME = 'coach_name'
}

/** aggregate stddev on columns */
export interface IUserCoachViewStddevFields {
   __typename?: 'user_coach_view_stddev_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IUserCoachViewStddevPopFields {
   __typename?: 'user_coach_view_stddev_pop_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IUserCoachViewStddevSampFields {
   __typename?: 'user_coach_view_stddev_samp_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IUserCoachViewSumFields {
   __typename?: 'user_coach_view_sum_fields';
  client_id?: Maybe<Scalars['bigint']>;
  coach_id?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface IUserCoachViewVarPopFields {
   __typename?: 'user_coach_view_var_pop_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IUserCoachViewVarSampFields {
   __typename?: 'user_coach_view_var_samp_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IUserCoachViewVarianceFields {
   __typename?: 'user_coach_view_variance_fields';
  client_id?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "user_profiles" */
export interface IUserProfiles {
   __typename?: 'user_profiles';
  activity_level?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamp'];
  /** An object relationship */
  current_coach?: Maybe<IUsers>;
  date_of_birth?: Maybe<Scalars['date']>;
  /** An object relationship */
  diet_summary?: Maybe<IDietSummaries>;
  find_us_on?: Maybe<Scalars['String']>;
  has_injuries?: Maybe<Scalars['Boolean']>;
  how_to_help?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  initial_height?: Maybe<Scalars['numeric']>;
  initial_weight?: Maybe<Scalars['numeric']>;
  is_allergic?: Maybe<Scalars['Boolean']>;
  is_vegan?: Maybe<Scalars['Boolean']>;
  is_vegetarian?: Maybe<Scalars['Boolean']>;
  meals_dislike?: Maybe<Scalars['_varchar']>;
  meals_like?: Maybe<Scalars['_varchar']>;
  member_number?: Maybe<Scalars['String']>;
  personal_goal?: Maybe<Scalars['String']>;
  program_info?: Maybe<Scalars['String']>;
  result_after_1month?: Maybe<Scalars['String']>;
  result_after_3month?: Maybe<Scalars['String']>;
  result_after_6month?: Maybe<Scalars['String']>;
  rythm?: Maybe<Scalars['String']>;
  training_expectations?: Maybe<Scalars['String']>;
  training_frequency?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['bigint'];
  weekdays?: Maybe<Scalars['String']>;
  weekend?: Maybe<Scalars['String']>;
}

/** aggregated selection of "user_profiles" */
export interface IUserProfilesAggregate {
   __typename?: 'user_profiles_aggregate';
  aggregate?: Maybe<IUserProfilesAggregateFields>;
  nodes: Array<IUserProfiles>;
}

/** aggregate fields of "user_profiles" */
export interface IUserProfilesAggregateFields {
   __typename?: 'user_profiles_aggregate_fields';
  avg?: Maybe<IUserProfilesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IUserProfilesMaxFields>;
  min?: Maybe<IUserProfilesMinFields>;
  stddev?: Maybe<IUserProfilesStddevFields>;
  stddev_pop?: Maybe<IUserProfilesStddevPopFields>;
  stddev_samp?: Maybe<IUserProfilesStddevSampFields>;
  sum?: Maybe<IUserProfilesSumFields>;
  var_pop?: Maybe<IUserProfilesVarPopFields>;
  var_samp?: Maybe<IUserProfilesVarSampFields>;
  variance?: Maybe<IUserProfilesVarianceFields>;
}


/** aggregate fields of "user_profiles" */
export interface IUserProfilesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IUserProfilesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "user_profiles" */
export interface IUserProfilesAggregateOrderBy {
  avg?: Maybe<IUserProfilesAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IUserProfilesMaxOrderBy>;
  min?: Maybe<IUserProfilesMinOrderBy>;
  stddev?: Maybe<IUserProfilesStddevOrderBy>;
  stddev_pop?: Maybe<IUserProfilesStddevPopOrderBy>;
  stddev_samp?: Maybe<IUserProfilesStddevSampOrderBy>;
  sum?: Maybe<IUserProfilesSumOrderBy>;
  var_pop?: Maybe<IUserProfilesVarPopOrderBy>;
  var_samp?: Maybe<IUserProfilesVarSampOrderBy>;
  variance?: Maybe<IUserProfilesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "user_profiles" */
export interface IUserProfilesArrRelInsertInput {
  data: Array<IUserProfilesInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IUserProfilesOnConflict>;
}

/** aggregate avg on columns */
export interface IUserProfilesAvgFields {
   __typename?: 'user_profiles_avg_fields';
  activity_level?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_height?: Maybe<Scalars['Float']>;
  initial_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "user_profiles" */
export interface IUserProfilesAvgOrderBy {
  activity_level?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  initial_height?: Maybe<IOrderBy>;
  initial_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "user_profiles". All fields are combined with a logical 'AND'. */
export interface IUserProfilesBoolExp {
  _and?: Maybe<Array<IUserProfilesBoolExp>>;
  _not?: Maybe<IUserProfilesBoolExp>;
  _or?: Maybe<Array<IUserProfilesBoolExp>>;
  activity_level?: Maybe<IIntComparisonExp>;
  coach_id?: Maybe<IBigintComparisonExp>;
  created_at?: Maybe<ITimestampComparisonExp>;
  current_coach?: Maybe<IUsersBoolExp>;
  date_of_birth?: Maybe<IDateComparisonExp>;
  diet_summary?: Maybe<IDietSummariesBoolExp>;
  find_us_on?: Maybe<IStringComparisonExp>;
  has_injuries?: Maybe<IBooleanComparisonExp>;
  how_to_help?: Maybe<IStringComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  initial_height?: Maybe<INumericComparisonExp>;
  initial_weight?: Maybe<INumericComparisonExp>;
  is_allergic?: Maybe<IBooleanComparisonExp>;
  is_vegan?: Maybe<IBooleanComparisonExp>;
  is_vegetarian?: Maybe<IBooleanComparisonExp>;
  meals_dislike?: Maybe<IVarcharComparisonExp>;
  meals_like?: Maybe<IVarcharComparisonExp>;
  member_number?: Maybe<IStringComparisonExp>;
  personal_goal?: Maybe<IStringComparisonExp>;
  program_info?: Maybe<IStringComparisonExp>;
  result_after_1month?: Maybe<IStringComparisonExp>;
  result_after_3month?: Maybe<IStringComparisonExp>;
  result_after_6month?: Maybe<IStringComparisonExp>;
  rythm?: Maybe<IStringComparisonExp>;
  training_expectations?: Maybe<IStringComparisonExp>;
  training_frequency?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
  user?: Maybe<IUsersBoolExp>;
  user_id?: Maybe<IBigintComparisonExp>;
  weekdays?: Maybe<IStringComparisonExp>;
  weekend?: Maybe<IStringComparisonExp>;
}

/** unique or primary key constraints on table "user_profiles" */
export enum IUserProfilesConstraint {
  /** unique or primary key constraint */
  USER_PROFILES_PKEY = 'user_profiles_pkey'
}

/** input type for incrementing numeric columns in table "user_profiles" */
export interface IUserProfilesIncInput {
  activity_level?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  initial_height?: Maybe<Scalars['numeric']>;
  initial_weight?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "user_profiles" */
export interface IUserProfilesInsertInput {
  activity_level?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current_coach?: Maybe<IUsersObjRelInsertInput>;
  date_of_birth?: Maybe<Scalars['date']>;
  diet_summary?: Maybe<IDietSummariesObjRelInsertInput>;
  find_us_on?: Maybe<Scalars['String']>;
  has_injuries?: Maybe<Scalars['Boolean']>;
  how_to_help?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  initial_height?: Maybe<Scalars['numeric']>;
  initial_weight?: Maybe<Scalars['numeric']>;
  is_allergic?: Maybe<Scalars['Boolean']>;
  is_vegan?: Maybe<Scalars['Boolean']>;
  is_vegetarian?: Maybe<Scalars['Boolean']>;
  meals_dislike?: Maybe<Scalars['_varchar']>;
  meals_like?: Maybe<Scalars['_varchar']>;
  member_number?: Maybe<Scalars['String']>;
  personal_goal?: Maybe<Scalars['String']>;
  program_info?: Maybe<Scalars['String']>;
  result_after_1month?: Maybe<Scalars['String']>;
  result_after_3month?: Maybe<Scalars['String']>;
  result_after_6month?: Maybe<Scalars['String']>;
  rythm?: Maybe<Scalars['String']>;
  training_expectations?: Maybe<Scalars['String']>;
  training_frequency?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user?: Maybe<IUsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
  weekdays?: Maybe<Scalars['String']>;
  weekend?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface IUserProfilesMaxFields {
   __typename?: 'user_profiles_max_fields';
  activity_level?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_of_birth?: Maybe<Scalars['date']>;
  find_us_on?: Maybe<Scalars['String']>;
  how_to_help?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  initial_height?: Maybe<Scalars['numeric']>;
  initial_weight?: Maybe<Scalars['numeric']>;
  member_number?: Maybe<Scalars['String']>;
  personal_goal?: Maybe<Scalars['String']>;
  program_info?: Maybe<Scalars['String']>;
  result_after_1month?: Maybe<Scalars['String']>;
  result_after_3month?: Maybe<Scalars['String']>;
  result_after_6month?: Maybe<Scalars['String']>;
  rythm?: Maybe<Scalars['String']>;
  training_expectations?: Maybe<Scalars['String']>;
  training_frequency?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
  weekdays?: Maybe<Scalars['String']>;
  weekend?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "user_profiles" */
export interface IUserProfilesMaxOrderBy {
  activity_level?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  date_of_birth?: Maybe<IOrderBy>;
  find_us_on?: Maybe<IOrderBy>;
  how_to_help?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  initial_height?: Maybe<IOrderBy>;
  initial_weight?: Maybe<IOrderBy>;
  member_number?: Maybe<IOrderBy>;
  personal_goal?: Maybe<IOrderBy>;
  program_info?: Maybe<IOrderBy>;
  result_after_1month?: Maybe<IOrderBy>;
  result_after_3month?: Maybe<IOrderBy>;
  result_after_6month?: Maybe<IOrderBy>;
  rythm?: Maybe<IOrderBy>;
  training_expectations?: Maybe<IOrderBy>;
  training_frequency?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  weekdays?: Maybe<IOrderBy>;
  weekend?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IUserProfilesMinFields {
   __typename?: 'user_profiles_min_fields';
  activity_level?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_of_birth?: Maybe<Scalars['date']>;
  find_us_on?: Maybe<Scalars['String']>;
  how_to_help?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  initial_height?: Maybe<Scalars['numeric']>;
  initial_weight?: Maybe<Scalars['numeric']>;
  member_number?: Maybe<Scalars['String']>;
  personal_goal?: Maybe<Scalars['String']>;
  program_info?: Maybe<Scalars['String']>;
  result_after_1month?: Maybe<Scalars['String']>;
  result_after_3month?: Maybe<Scalars['String']>;
  result_after_6month?: Maybe<Scalars['String']>;
  rythm?: Maybe<Scalars['String']>;
  training_expectations?: Maybe<Scalars['String']>;
  training_frequency?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
  weekdays?: Maybe<Scalars['String']>;
  weekend?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "user_profiles" */
export interface IUserProfilesMinOrderBy {
  activity_level?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  date_of_birth?: Maybe<IOrderBy>;
  find_us_on?: Maybe<IOrderBy>;
  how_to_help?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  initial_height?: Maybe<IOrderBy>;
  initial_weight?: Maybe<IOrderBy>;
  member_number?: Maybe<IOrderBy>;
  personal_goal?: Maybe<IOrderBy>;
  program_info?: Maybe<IOrderBy>;
  result_after_1month?: Maybe<IOrderBy>;
  result_after_3month?: Maybe<IOrderBy>;
  result_after_6month?: Maybe<IOrderBy>;
  rythm?: Maybe<IOrderBy>;
  training_expectations?: Maybe<IOrderBy>;
  training_frequency?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
  weekdays?: Maybe<IOrderBy>;
  weekend?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "user_profiles" */
export interface IUserProfilesMutationResponse {
   __typename?: 'user_profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUserProfiles>;
}

/** input type for inserting object relation for remote table "user_profiles" */
export interface IUserProfilesObjRelInsertInput {
  data: IUserProfilesInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IUserProfilesOnConflict>;
}

/** on conflict condition type for table "user_profiles" */
export interface IUserProfilesOnConflict {
  constraint: IUserProfilesConstraint;
  update_columns: Array<IUserProfilesUpdateColumn>;
  where?: Maybe<IUserProfilesBoolExp>;
}

/** Ordering options when selecting data from "user_profiles". */
export interface IUserProfilesOrderBy {
  activity_level?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  current_coach?: Maybe<IUsersOrderBy>;
  date_of_birth?: Maybe<IOrderBy>;
  diet_summary?: Maybe<IDietSummariesOrderBy>;
  find_us_on?: Maybe<IOrderBy>;
  has_injuries?: Maybe<IOrderBy>;
  how_to_help?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  initial_height?: Maybe<IOrderBy>;
  initial_weight?: Maybe<IOrderBy>;
  is_allergic?: Maybe<IOrderBy>;
  is_vegan?: Maybe<IOrderBy>;
  is_vegetarian?: Maybe<IOrderBy>;
  meals_dislike?: Maybe<IOrderBy>;
  meals_like?: Maybe<IOrderBy>;
  member_number?: Maybe<IOrderBy>;
  personal_goal?: Maybe<IOrderBy>;
  program_info?: Maybe<IOrderBy>;
  result_after_1month?: Maybe<IOrderBy>;
  result_after_3month?: Maybe<IOrderBy>;
  result_after_6month?: Maybe<IOrderBy>;
  rythm?: Maybe<IOrderBy>;
  training_expectations?: Maybe<IOrderBy>;
  training_frequency?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user?: Maybe<IUsersOrderBy>;
  user_id?: Maybe<IOrderBy>;
  weekdays?: Maybe<IOrderBy>;
  weekend?: Maybe<IOrderBy>;
}

/** primary key columns input for table: user_profiles */
export interface IUserProfilesPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "user_profiles" */
export enum IUserProfilesSelectColumn {
  /** column name */
  ACTIVITY_LEVEL = 'activity_level',
  /** column name */
  COACH_ID = 'coach_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DATE_OF_BIRTH = 'date_of_birth',
  /** column name */
  FIND_US_ON = 'find_us_on',
  /** column name */
  HAS_INJURIES = 'has_injuries',
  /** column name */
  HOW_TO_HELP = 'how_to_help',
  /** column name */
  ID = 'id',
  /** column name */
  INITIAL_HEIGHT = 'initial_height',
  /** column name */
  INITIAL_WEIGHT = 'initial_weight',
  /** column name */
  IS_ALLERGIC = 'is_allergic',
  /** column name */
  IS_VEGAN = 'is_vegan',
  /** column name */
  IS_VEGETARIAN = 'is_vegetarian',
  /** column name */
  MEALS_DISLIKE = 'meals_dislike',
  /** column name */
  MEALS_LIKE = 'meals_like',
  /** column name */
  MEMBER_NUMBER = 'member_number',
  /** column name */
  PERSONAL_GOAL = 'personal_goal',
  /** column name */
  PROGRAM_INFO = 'program_info',
  /** column name */
  RESULT_AFTER_1MONTH = 'result_after_1month',
  /** column name */
  RESULT_AFTER_3MONTH = 'result_after_3month',
  /** column name */
  RESULT_AFTER_6MONTH = 'result_after_6month',
  /** column name */
  RYTHM = 'rythm',
  /** column name */
  TRAINING_EXPECTATIONS = 'training_expectations',
  /** column name */
  TRAINING_FREQUENCY = 'training_frequency',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WEEKDAYS = 'weekdays',
  /** column name */
  WEEKEND = 'weekend'
}

/** input type for updating data in table "user_profiles" */
export interface IUserProfilesSetInput {
  activity_level?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  date_of_birth?: Maybe<Scalars['date']>;
  find_us_on?: Maybe<Scalars['String']>;
  has_injuries?: Maybe<Scalars['Boolean']>;
  how_to_help?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  initial_height?: Maybe<Scalars['numeric']>;
  initial_weight?: Maybe<Scalars['numeric']>;
  is_allergic?: Maybe<Scalars['Boolean']>;
  is_vegan?: Maybe<Scalars['Boolean']>;
  is_vegetarian?: Maybe<Scalars['Boolean']>;
  meals_dislike?: Maybe<Scalars['_varchar']>;
  meals_like?: Maybe<Scalars['_varchar']>;
  member_number?: Maybe<Scalars['String']>;
  personal_goal?: Maybe<Scalars['String']>;
  program_info?: Maybe<Scalars['String']>;
  result_after_1month?: Maybe<Scalars['String']>;
  result_after_3month?: Maybe<Scalars['String']>;
  result_after_6month?: Maybe<Scalars['String']>;
  rythm?: Maybe<Scalars['String']>;
  training_expectations?: Maybe<Scalars['String']>;
  training_frequency?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
  weekdays?: Maybe<Scalars['String']>;
  weekend?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IUserProfilesStddevFields {
   __typename?: 'user_profiles_stddev_fields';
  activity_level?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_height?: Maybe<Scalars['Float']>;
  initial_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "user_profiles" */
export interface IUserProfilesStddevOrderBy {
  activity_level?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  initial_height?: Maybe<IOrderBy>;
  initial_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IUserProfilesStddevPopFields {
   __typename?: 'user_profiles_stddev_pop_fields';
  activity_level?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_height?: Maybe<Scalars['Float']>;
  initial_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "user_profiles" */
export interface IUserProfilesStddevPopOrderBy {
  activity_level?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  initial_height?: Maybe<IOrderBy>;
  initial_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IUserProfilesStddevSampFields {
   __typename?: 'user_profiles_stddev_samp_fields';
  activity_level?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_height?: Maybe<Scalars['Float']>;
  initial_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "user_profiles" */
export interface IUserProfilesStddevSampOrderBy {
  activity_level?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  initial_height?: Maybe<IOrderBy>;
  initial_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IUserProfilesSumFields {
   __typename?: 'user_profiles_sum_fields';
  activity_level?: Maybe<Scalars['Int']>;
  coach_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  initial_height?: Maybe<Scalars['numeric']>;
  initial_weight?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "user_profiles" */
export interface IUserProfilesSumOrderBy {
  activity_level?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  initial_height?: Maybe<IOrderBy>;
  initial_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** update columns of table "user_profiles" */
export enum IUserProfilesUpdateColumn {
  /** column name */
  ACTIVITY_LEVEL = 'activity_level',
  /** column name */
  COACH_ID = 'coach_id',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DATE_OF_BIRTH = 'date_of_birth',
  /** column name */
  FIND_US_ON = 'find_us_on',
  /** column name */
  HAS_INJURIES = 'has_injuries',
  /** column name */
  HOW_TO_HELP = 'how_to_help',
  /** column name */
  ID = 'id',
  /** column name */
  INITIAL_HEIGHT = 'initial_height',
  /** column name */
  INITIAL_WEIGHT = 'initial_weight',
  /** column name */
  IS_ALLERGIC = 'is_allergic',
  /** column name */
  IS_VEGAN = 'is_vegan',
  /** column name */
  IS_VEGETARIAN = 'is_vegetarian',
  /** column name */
  MEALS_DISLIKE = 'meals_dislike',
  /** column name */
  MEALS_LIKE = 'meals_like',
  /** column name */
  MEMBER_NUMBER = 'member_number',
  /** column name */
  PERSONAL_GOAL = 'personal_goal',
  /** column name */
  PROGRAM_INFO = 'program_info',
  /** column name */
  RESULT_AFTER_1MONTH = 'result_after_1month',
  /** column name */
  RESULT_AFTER_3MONTH = 'result_after_3month',
  /** column name */
  RESULT_AFTER_6MONTH = 'result_after_6month',
  /** column name */
  RYTHM = 'rythm',
  /** column name */
  TRAINING_EXPECTATIONS = 'training_expectations',
  /** column name */
  TRAINING_FREQUENCY = 'training_frequency',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id',
  /** column name */
  WEEKDAYS = 'weekdays',
  /** column name */
  WEEKEND = 'weekend'
}

/** aggregate var_pop on columns */
export interface IUserProfilesVarPopFields {
   __typename?: 'user_profiles_var_pop_fields';
  activity_level?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_height?: Maybe<Scalars['Float']>;
  initial_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "user_profiles" */
export interface IUserProfilesVarPopOrderBy {
  activity_level?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  initial_height?: Maybe<IOrderBy>;
  initial_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IUserProfilesVarSampFields {
   __typename?: 'user_profiles_var_samp_fields';
  activity_level?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_height?: Maybe<Scalars['Float']>;
  initial_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "user_profiles" */
export interface IUserProfilesVarSampOrderBy {
  activity_level?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  initial_height?: Maybe<IOrderBy>;
  initial_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IUserProfilesVarianceFields {
   __typename?: 'user_profiles_variance_fields';
  activity_level?: Maybe<Scalars['Float']>;
  coach_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_height?: Maybe<Scalars['Float']>;
  initial_weight?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "user_profiles" */
export interface IUserProfilesVarianceOrderBy {
  activity_level?: Maybe<IOrderBy>;
  coach_id?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  initial_height?: Maybe<IOrderBy>;
  initial_weight?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "users" */
export interface IUsers {
   __typename?: 'users';
  avatar_url?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  cloud_messaging_token?: Maybe<Scalars['String']>;
  /** An array relationship */
  coaching_members: Array<IUserProfiles>;
  /** An aggregate relationship */
  coaching_members_aggregate: IUserProfilesAggregate;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  created_at: Scalars['timestamp'];
  current_sign_in_at?: Maybe<Scalars['timestamp']>;
  current_sign_in_ip?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['date']>;
  email: Scalars['String'];
  encrypted_password: Scalars['String'];
  failed_attempts: Scalars['Int'];
  firmhouse_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  is_blocked?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_sign_in_at?: Maybe<Scalars['timestamp']>;
  last_sign_in_ip?: Maybe<Scalars['String']>;
  locked_at?: Maybe<Scalars['timestamp']>;
  middle_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  nutrition_plans: Array<INutritionPlans>;
  /** An aggregate relationship */
  nutrition_plans_aggregate: INutritionPlansAggregate;
  /** An array relationship */
  program_plan_employees: Array<IProgramPlanEmployees>;
  /** An aggregate relationship */
  program_plan_employees_aggregate: IProgramPlanEmployeesAggregate;
  /** An array relationship */
  program_plan_participators: Array<IProgramPlanParticipators>;
  /** An aggregate relationship */
  program_plan_participators_aggregate: IProgramPlanParticipatorsAggregate;
  /** fetch data from the table: "progress_days" */
  progress_days: Array<IProgressDays>;
  /** An aggregate relationship */
  progress_days_aggregate: IProgressDaysAggregate;
  /** An array relationship */
  progress_weeks: Array<IProgressWeeks>;
  /** An aggregate relationship */
  progress_weeks_aggregate: IProgressWeeksAggregate;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  /** An array relationship */
  roles: Array<IRolesUsers>;
  /** An aggregate relationship */
  roles_aggregate: IRolesUsersAggregate;
  sign_in_count: Scalars['Int'];
  unconfirmed_email?: Maybe<Scalars['String']>;
  unlock_token?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user_profile?: Maybe<IUserProfiles>;
  /** An array relationship */
  user_profiles: Array<IUserProfiles>;
  /** An aggregate relationship */
  user_profiles_aggregate: IUserProfilesAggregate;
  username?: Maybe<Scalars['String']>;
  /** An array relationship */
  water_progress_days: Array<IWaterProgressDays>;
  /** An aggregate relationship */
  water_progress_days_aggregate: IWaterProgressDaysAggregate;
  /** An array relationship */
  workout_plans: Array<IWorkoutPlans>;
  /** An aggregate relationship */
  workout_plans_aggregate: IWorkoutPlansAggregate;
}


/** columns and relationships of "users" */
export interface IUsersCoachingMembersArgs {
  distinct_on?: Maybe<Array<IUserProfilesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUserProfilesOrderBy>>;
  where?: Maybe<IUserProfilesBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersCoachingMembersAggregateArgs {
  distinct_on?: Maybe<Array<IUserProfilesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUserProfilesOrderBy>>;
  where?: Maybe<IUserProfilesBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersNutritionPlansArgs {
  distinct_on?: Maybe<Array<INutritionPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlansOrderBy>>;
  where?: Maybe<INutritionPlansBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersNutritionPlansAggregateArgs {
  distinct_on?: Maybe<Array<INutritionPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<INutritionPlansOrderBy>>;
  where?: Maybe<INutritionPlansBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersProgramPlanEmployeesArgs {
  distinct_on?: Maybe<Array<IProgramPlanEmployeesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanEmployeesOrderBy>>;
  where?: Maybe<IProgramPlanEmployeesBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersProgramPlanEmployeesAggregateArgs {
  distinct_on?: Maybe<Array<IProgramPlanEmployeesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanEmployeesOrderBy>>;
  where?: Maybe<IProgramPlanEmployeesBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersProgramPlanParticipatorsArgs {
  distinct_on?: Maybe<Array<IProgramPlanParticipatorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanParticipatorsOrderBy>>;
  where?: Maybe<IProgramPlanParticipatorsBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersProgramPlanParticipatorsAggregateArgs {
  distinct_on?: Maybe<Array<IProgramPlanParticipatorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgramPlanParticipatorsOrderBy>>;
  where?: Maybe<IProgramPlanParticipatorsBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersProgressDaysArgs {
  distinct_on?: Maybe<Array<IProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressDaysOrderBy>>;
  where?: Maybe<IProgressDaysBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersProgressDaysAggregateArgs {
  distinct_on?: Maybe<Array<IProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressDaysOrderBy>>;
  where?: Maybe<IProgressDaysBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersProgressWeeksArgs {
  distinct_on?: Maybe<Array<IProgressWeeksSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressWeeksOrderBy>>;
  where?: Maybe<IProgressWeeksBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersProgressWeeksAggregateArgs {
  distinct_on?: Maybe<Array<IProgressWeeksSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IProgressWeeksOrderBy>>;
  where?: Maybe<IProgressWeeksBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersRolesArgs {
  distinct_on?: Maybe<Array<IRolesUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRolesUsersOrderBy>>;
  where?: Maybe<IRolesUsersBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersRolesAggregateArgs {
  distinct_on?: Maybe<Array<IRolesUsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IRolesUsersOrderBy>>;
  where?: Maybe<IRolesUsersBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersUserProfilesArgs {
  distinct_on?: Maybe<Array<IUserProfilesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUserProfilesOrderBy>>;
  where?: Maybe<IUserProfilesBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersUserProfilesAggregateArgs {
  distinct_on?: Maybe<Array<IUserProfilesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IUserProfilesOrderBy>>;
  where?: Maybe<IUserProfilesBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersWaterProgressDaysArgs {
  distinct_on?: Maybe<Array<IWaterProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWaterProgressDaysOrderBy>>;
  where?: Maybe<IWaterProgressDaysBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersWaterProgressDaysAggregateArgs {
  distinct_on?: Maybe<Array<IWaterProgressDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWaterProgressDaysOrderBy>>;
  where?: Maybe<IWaterProgressDaysBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersWorkoutPlansArgs {
  distinct_on?: Maybe<Array<IWorkoutPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutPlansOrderBy>>;
  where?: Maybe<IWorkoutPlansBoolExp>;
}


/** columns and relationships of "users" */
export interface IUsersWorkoutPlansAggregateArgs {
  distinct_on?: Maybe<Array<IWorkoutPlansSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IWorkoutPlansOrderBy>>;
  where?: Maybe<IWorkoutPlansBoolExp>;
}

/** aggregated selection of "users" */
export interface IUsersAggregate {
   __typename?: 'users_aggregate';
  aggregate?: Maybe<IUsersAggregateFields>;
  nodes: Array<IUsers>;
}

/** aggregate fields of "users" */
export interface IUsersAggregateFields {
   __typename?: 'users_aggregate_fields';
  avg?: Maybe<IUsersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IUsersMaxFields>;
  min?: Maybe<IUsersMinFields>;
  stddev?: Maybe<IUsersStddevFields>;
  stddev_pop?: Maybe<IUsersStddevPopFields>;
  stddev_samp?: Maybe<IUsersStddevSampFields>;
  sum?: Maybe<IUsersSumFields>;
  var_pop?: Maybe<IUsersVarPopFields>;
  var_samp?: Maybe<IUsersVarSampFields>;
  variance?: Maybe<IUsersVarianceFields>;
}


/** aggregate fields of "users" */
export interface IUsersAggregateFieldsCountArgs {
  columns?: Maybe<Array<IUsersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IUsersAvgFields {
   __typename?: 'users_avg_fields';
  failed_attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sign_in_count?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export interface IUsersBoolExp {
  _and?: Maybe<Array<IUsersBoolExp>>;
  _not?: Maybe<IUsersBoolExp>;
  _or?: Maybe<Array<IUsersBoolExp>>;
  avatar_url?: Maybe<IStringComparisonExp>;
  biography?: Maybe<IStringComparisonExp>;
  cloud_messaging_token?: Maybe<IStringComparisonExp>;
  coaching_members?: Maybe<IUserProfilesBoolExp>;
  confirmation_sent_at?: Maybe<ITimestampComparisonExp>;
  confirmation_token?: Maybe<IStringComparisonExp>;
  confirmed_at?: Maybe<ITimestampComparisonExp>;
  created_at?: Maybe<ITimestampComparisonExp>;
  current_sign_in_at?: Maybe<ITimestampComparisonExp>;
  current_sign_in_ip?: Maybe<IStringComparisonExp>;
  date_of_birth?: Maybe<IDateComparisonExp>;
  email?: Maybe<IStringComparisonExp>;
  encrypted_password?: Maybe<IStringComparisonExp>;
  failed_attempts?: Maybe<IIntComparisonExp>;
  firmhouse_token?: Maybe<IStringComparisonExp>;
  first_name?: Maybe<IStringComparisonExp>;
  gender?: Maybe<IStringComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  is_blocked?: Maybe<IBooleanComparisonExp>;
  language?: Maybe<IStringComparisonExp>;
  last_name?: Maybe<IStringComparisonExp>;
  last_sign_in_at?: Maybe<ITimestampComparisonExp>;
  last_sign_in_ip?: Maybe<IStringComparisonExp>;
  locked_at?: Maybe<ITimestampComparisonExp>;
  middle_name?: Maybe<IStringComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  nutrition_plans?: Maybe<INutritionPlansBoolExp>;
  program_plan_employees?: Maybe<IProgramPlanEmployeesBoolExp>;
  program_plan_participators?: Maybe<IProgramPlanParticipatorsBoolExp>;
  progress_days?: Maybe<IProgressDaysBoolExp>;
  progress_weeks?: Maybe<IProgressWeeksBoolExp>;
  remember_created_at?: Maybe<ITimestampComparisonExp>;
  reset_password_sent_at?: Maybe<ITimestampComparisonExp>;
  reset_password_token?: Maybe<IStringComparisonExp>;
  roles?: Maybe<IRolesUsersBoolExp>;
  sign_in_count?: Maybe<IIntComparisonExp>;
  unconfirmed_email?: Maybe<IStringComparisonExp>;
  unlock_token?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
  user_profile?: Maybe<IUserProfilesBoolExp>;
  user_profiles?: Maybe<IUserProfilesBoolExp>;
  username?: Maybe<IStringComparisonExp>;
  water_progress_days?: Maybe<IWaterProgressDaysBoolExp>;
  workout_plans?: Maybe<IWorkoutPlansBoolExp>;
}

/** unique or primary key constraints on table "users" */
export enum IUsersConstraint {
  /** unique or primary key constraint */
  INDEX_USERS_ON_RESET_PASSWORD_TOKEN = 'index_users_on_reset_password_token',
  /** unique or primary key constraint */
  USERS_CLOUD_MESSAGING_TOKEN_KEY = 'users_cloud_messaging_token_key',
  /** unique or primary key constraint */
  USERS_FIRMHOUSE_TOKEN_KEY = 'users_firmhouse_token_key',
  /** unique or primary key constraint */
  USERS_PKEY = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export interface IUsersIncInput {
  failed_attempts?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  sign_in_count?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "users" */
export interface IUsersInsertInput {
  avatar_url?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  cloud_messaging_token?: Maybe<Scalars['String']>;
  coaching_members?: Maybe<IUserProfilesArrRelInsertInput>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current_sign_in_at?: Maybe<Scalars['timestamp']>;
  current_sign_in_ip?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  failed_attempts?: Maybe<Scalars['Int']>;
  firmhouse_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_blocked?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_sign_in_at?: Maybe<Scalars['timestamp']>;
  last_sign_in_ip?: Maybe<Scalars['String']>;
  locked_at?: Maybe<Scalars['timestamp']>;
  middle_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nutrition_plans?: Maybe<INutritionPlansArrRelInsertInput>;
  program_plan_employees?: Maybe<IProgramPlanEmployeesArrRelInsertInput>;
  program_plan_participators?: Maybe<IProgramPlanParticipatorsArrRelInsertInput>;
  progress_days?: Maybe<IProgressDaysArrRelInsertInput>;
  progress_weeks?: Maybe<IProgressWeeksArrRelInsertInput>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  roles?: Maybe<IRolesUsersArrRelInsertInput>;
  sign_in_count?: Maybe<Scalars['Int']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  unlock_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_profile?: Maybe<IUserProfilesObjRelInsertInput>;
  user_profiles?: Maybe<IUserProfilesArrRelInsertInput>;
  username?: Maybe<Scalars['String']>;
  water_progress_days?: Maybe<IWaterProgressDaysArrRelInsertInput>;
  workout_plans?: Maybe<IWorkoutPlansArrRelInsertInput>;
}

/** aggregate max on columns */
export interface IUsersMaxFields {
   __typename?: 'users_max_fields';
  avatar_url?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  cloud_messaging_token?: Maybe<Scalars['String']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current_sign_in_at?: Maybe<Scalars['timestamp']>;
  current_sign_in_ip?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  failed_attempts?: Maybe<Scalars['Int']>;
  firmhouse_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_sign_in_at?: Maybe<Scalars['timestamp']>;
  last_sign_in_ip?: Maybe<Scalars['String']>;
  locked_at?: Maybe<Scalars['timestamp']>;
  middle_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  sign_in_count?: Maybe<Scalars['Int']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  unlock_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface IUsersMinFields {
   __typename?: 'users_min_fields';
  avatar_url?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  cloud_messaging_token?: Maybe<Scalars['String']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current_sign_in_at?: Maybe<Scalars['timestamp']>;
  current_sign_in_ip?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  failed_attempts?: Maybe<Scalars['Int']>;
  firmhouse_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_sign_in_at?: Maybe<Scalars['timestamp']>;
  last_sign_in_ip?: Maybe<Scalars['String']>;
  locked_at?: Maybe<Scalars['timestamp']>;
  middle_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  sign_in_count?: Maybe<Scalars['Int']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  unlock_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "users" */
export interface IUsersMutationResponse {
   __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IUsers>;
}

/** input type for inserting object relation for remote table "users" */
export interface IUsersObjRelInsertInput {
  data: IUsersInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IUsersOnConflict>;
}

/** on conflict condition type for table "users" */
export interface IUsersOnConflict {
  constraint: IUsersConstraint;
  update_columns: Array<IUsersUpdateColumn>;
  where?: Maybe<IUsersBoolExp>;
}

/** Ordering options when selecting data from "users". */
export interface IUsersOrderBy {
  avatar_url?: Maybe<IOrderBy>;
  biography?: Maybe<IOrderBy>;
  cloud_messaging_token?: Maybe<IOrderBy>;
  coaching_members_aggregate?: Maybe<IUserProfilesAggregateOrderBy>;
  confirmation_sent_at?: Maybe<IOrderBy>;
  confirmation_token?: Maybe<IOrderBy>;
  confirmed_at?: Maybe<IOrderBy>;
  created_at?: Maybe<IOrderBy>;
  current_sign_in_at?: Maybe<IOrderBy>;
  current_sign_in_ip?: Maybe<IOrderBy>;
  date_of_birth?: Maybe<IOrderBy>;
  email?: Maybe<IOrderBy>;
  encrypted_password?: Maybe<IOrderBy>;
  failed_attempts?: Maybe<IOrderBy>;
  firmhouse_token?: Maybe<IOrderBy>;
  first_name?: Maybe<IOrderBy>;
  gender?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  is_blocked?: Maybe<IOrderBy>;
  language?: Maybe<IOrderBy>;
  last_name?: Maybe<IOrderBy>;
  last_sign_in_at?: Maybe<IOrderBy>;
  last_sign_in_ip?: Maybe<IOrderBy>;
  locked_at?: Maybe<IOrderBy>;
  middle_name?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  nutrition_plans_aggregate?: Maybe<INutritionPlansAggregateOrderBy>;
  program_plan_employees_aggregate?: Maybe<IProgramPlanEmployeesAggregateOrderBy>;
  program_plan_participators_aggregate?: Maybe<IProgramPlanParticipatorsAggregateOrderBy>;
  progress_days_aggregate?: Maybe<IProgressDaysAggregateOrderBy>;
  progress_weeks_aggregate?: Maybe<IProgressWeeksAggregateOrderBy>;
  remember_created_at?: Maybe<IOrderBy>;
  reset_password_sent_at?: Maybe<IOrderBy>;
  reset_password_token?: Maybe<IOrderBy>;
  roles_aggregate?: Maybe<IRolesUsersAggregateOrderBy>;
  sign_in_count?: Maybe<IOrderBy>;
  unconfirmed_email?: Maybe<IOrderBy>;
  unlock_token?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_profile?: Maybe<IUserProfilesOrderBy>;
  user_profiles_aggregate?: Maybe<IUserProfilesAggregateOrderBy>;
  username?: Maybe<IOrderBy>;
  water_progress_days_aggregate?: Maybe<IWaterProgressDaysAggregateOrderBy>;
  workout_plans_aggregate?: Maybe<IWorkoutPlansAggregateOrderBy>;
}

/** primary key columns input for table: users */
export interface IUsersPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "users" */
export enum IUsersSelectColumn {
  /** column name */
  AVATAR_URL = 'avatar_url',
  /** column name */
  BIOGRAPHY = 'biography',
  /** column name */
  CLOUD_MESSAGING_TOKEN = 'cloud_messaging_token',
  /** column name */
  CONFIRMATION_SENT_AT = 'confirmation_sent_at',
  /** column name */
  CONFIRMATION_TOKEN = 'confirmation_token',
  /** column name */
  CONFIRMED_AT = 'confirmed_at',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  CURRENT_SIGN_IN_AT = 'current_sign_in_at',
  /** column name */
  CURRENT_SIGN_IN_IP = 'current_sign_in_ip',
  /** column name */
  DATE_OF_BIRTH = 'date_of_birth',
  /** column name */
  EMAIL = 'email',
  /** column name */
  ENCRYPTED_PASSWORD = 'encrypted_password',
  /** column name */
  FAILED_ATTEMPTS = 'failed_attempts',
  /** column name */
  FIRMHOUSE_TOKEN = 'firmhouse_token',
  /** column name */
  FIRST_NAME = 'first_name',
  /** column name */
  GENDER = 'gender',
  /** column name */
  ID = 'id',
  /** column name */
  IS_BLOCKED = 'is_blocked',
  /** column name */
  LANGUAGE = 'language',
  /** column name */
  LAST_NAME = 'last_name',
  /** column name */
  LAST_SIGN_IN_AT = 'last_sign_in_at',
  /** column name */
  LAST_SIGN_IN_IP = 'last_sign_in_ip',
  /** column name */
  LOCKED_AT = 'locked_at',
  /** column name */
  MIDDLE_NAME = 'middle_name',
  /** column name */
  NAME = 'name',
  /** column name */
  REMEMBER_CREATED_AT = 'remember_created_at',
  /** column name */
  RESET_PASSWORD_SENT_AT = 'reset_password_sent_at',
  /** column name */
  RESET_PASSWORD_TOKEN = 'reset_password_token',
  /** column name */
  SIGN_IN_COUNT = 'sign_in_count',
  /** column name */
  UNCONFIRMED_EMAIL = 'unconfirmed_email',
  /** column name */
  UNLOCK_TOKEN = 'unlock_token',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERNAME = 'username'
}

/** input type for updating data in table "users" */
export interface IUsersSetInput {
  avatar_url?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  cloud_messaging_token?: Maybe<Scalars['String']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current_sign_in_at?: Maybe<Scalars['timestamp']>;
  current_sign_in_ip?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  encrypted_password?: Maybe<Scalars['String']>;
  failed_attempts?: Maybe<Scalars['Int']>;
  firmhouse_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_blocked?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_sign_in_at?: Maybe<Scalars['timestamp']>;
  last_sign_in_ip?: Maybe<Scalars['String']>;
  locked_at?: Maybe<Scalars['timestamp']>;
  middle_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  remember_created_at?: Maybe<Scalars['timestamp']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']>;
  reset_password_token?: Maybe<Scalars['String']>;
  sign_in_count?: Maybe<Scalars['Int']>;
  unconfirmed_email?: Maybe<Scalars['String']>;
  unlock_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface IUsersStddevFields {
   __typename?: 'users_stddev_fields';
  failed_attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sign_in_count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IUsersStddevPopFields {
   __typename?: 'users_stddev_pop_fields';
  failed_attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sign_in_count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IUsersStddevSampFields {
   __typename?: 'users_stddev_samp_fields';
  failed_attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sign_in_count?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IUsersSumFields {
   __typename?: 'users_sum_fields';
  failed_attempts?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  sign_in_count?: Maybe<Scalars['Int']>;
}

/** update columns of table "users" */
export enum IUsersUpdateColumn {
  /** column name */
  AVATAR_URL = 'avatar_url',
  /** column name */
  BIOGRAPHY = 'biography',
  /** column name */
  CLOUD_MESSAGING_TOKEN = 'cloud_messaging_token',
  /** column name */
  CONFIRMATION_SENT_AT = 'confirmation_sent_at',
  /** column name */
  CONFIRMATION_TOKEN = 'confirmation_token',
  /** column name */
  CONFIRMED_AT = 'confirmed_at',
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  CURRENT_SIGN_IN_AT = 'current_sign_in_at',
  /** column name */
  CURRENT_SIGN_IN_IP = 'current_sign_in_ip',
  /** column name */
  DATE_OF_BIRTH = 'date_of_birth',
  /** column name */
  EMAIL = 'email',
  /** column name */
  ENCRYPTED_PASSWORD = 'encrypted_password',
  /** column name */
  FAILED_ATTEMPTS = 'failed_attempts',
  /** column name */
  FIRMHOUSE_TOKEN = 'firmhouse_token',
  /** column name */
  FIRST_NAME = 'first_name',
  /** column name */
  GENDER = 'gender',
  /** column name */
  ID = 'id',
  /** column name */
  IS_BLOCKED = 'is_blocked',
  /** column name */
  LANGUAGE = 'language',
  /** column name */
  LAST_NAME = 'last_name',
  /** column name */
  LAST_SIGN_IN_AT = 'last_sign_in_at',
  /** column name */
  LAST_SIGN_IN_IP = 'last_sign_in_ip',
  /** column name */
  LOCKED_AT = 'locked_at',
  /** column name */
  MIDDLE_NAME = 'middle_name',
  /** column name */
  NAME = 'name',
  /** column name */
  REMEMBER_CREATED_AT = 'remember_created_at',
  /** column name */
  RESET_PASSWORD_SENT_AT = 'reset_password_sent_at',
  /** column name */
  RESET_PASSWORD_TOKEN = 'reset_password_token',
  /** column name */
  SIGN_IN_COUNT = 'sign_in_count',
  /** column name */
  UNCONFIRMED_EMAIL = 'unconfirmed_email',
  /** column name */
  UNLOCK_TOKEN = 'unlock_token',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USERNAME = 'username'
}

/** aggregate var_pop on columns */
export interface IUsersVarPopFields {
   __typename?: 'users_var_pop_fields';
  failed_attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sign_in_count?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IUsersVarSampFields {
   __typename?: 'users_var_samp_fields';
  failed_attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sign_in_count?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IUsersVarianceFields {
   __typename?: 'users_variance_fields';
  failed_attempts?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sign_in_count?: Maybe<Scalars['Float']>;
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface IUuidComparisonExp {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
}

/** columns and relationships of "water_progress_days" */
export interface IWaterProgressDays {
   __typename?: 'water_progress_days';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  ml?: Maybe<Scalars['Int']>;
  starts_on: Scalars['date'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['Int'];
}

/** aggregated selection of "water_progress_days" */
export interface IWaterProgressDaysAggregate {
   __typename?: 'water_progress_days_aggregate';
  aggregate?: Maybe<IWaterProgressDaysAggregateFields>;
  nodes: Array<IWaterProgressDays>;
}

/** aggregate fields of "water_progress_days" */
export interface IWaterProgressDaysAggregateFields {
   __typename?: 'water_progress_days_aggregate_fields';
  avg?: Maybe<IWaterProgressDaysAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IWaterProgressDaysMaxFields>;
  min?: Maybe<IWaterProgressDaysMinFields>;
  stddev?: Maybe<IWaterProgressDaysStddevFields>;
  stddev_pop?: Maybe<IWaterProgressDaysStddevPopFields>;
  stddev_samp?: Maybe<IWaterProgressDaysStddevSampFields>;
  sum?: Maybe<IWaterProgressDaysSumFields>;
  var_pop?: Maybe<IWaterProgressDaysVarPopFields>;
  var_samp?: Maybe<IWaterProgressDaysVarSampFields>;
  variance?: Maybe<IWaterProgressDaysVarianceFields>;
}


/** aggregate fields of "water_progress_days" */
export interface IWaterProgressDaysAggregateFieldsCountArgs {
  columns?: Maybe<Array<IWaterProgressDaysSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "water_progress_days" */
export interface IWaterProgressDaysAggregateOrderBy {
  avg?: Maybe<IWaterProgressDaysAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IWaterProgressDaysMaxOrderBy>;
  min?: Maybe<IWaterProgressDaysMinOrderBy>;
  stddev?: Maybe<IWaterProgressDaysStddevOrderBy>;
  stddev_pop?: Maybe<IWaterProgressDaysStddevPopOrderBy>;
  stddev_samp?: Maybe<IWaterProgressDaysStddevSampOrderBy>;
  sum?: Maybe<IWaterProgressDaysSumOrderBy>;
  var_pop?: Maybe<IWaterProgressDaysVarPopOrderBy>;
  var_samp?: Maybe<IWaterProgressDaysVarSampOrderBy>;
  variance?: Maybe<IWaterProgressDaysVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "water_progress_days" */
export interface IWaterProgressDaysArrRelInsertInput {
  data: Array<IWaterProgressDaysInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IWaterProgressDaysOnConflict>;
}

/** aggregate avg on columns */
export interface IWaterProgressDaysAvgFields {
   __typename?: 'water_progress_days_avg_fields';
  id?: Maybe<Scalars['Float']>;
  ml?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "water_progress_days" */
export interface IWaterProgressDaysAvgOrderBy {
  id?: Maybe<IOrderBy>;
  ml?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "water_progress_days". All fields are combined with a logical 'AND'. */
export interface IWaterProgressDaysBoolExp {
  _and?: Maybe<Array<IWaterProgressDaysBoolExp>>;
  _not?: Maybe<IWaterProgressDaysBoolExp>;
  _or?: Maybe<Array<IWaterProgressDaysBoolExp>>;
  created_at?: Maybe<ITimestamptzComparisonExp>;
  id?: Maybe<IIntComparisonExp>;
  ml?: Maybe<IIntComparisonExp>;
  starts_on?: Maybe<IDateComparisonExp>;
  updated_at?: Maybe<ITimestamptzComparisonExp>;
  user?: Maybe<IUsersBoolExp>;
  user_id?: Maybe<IIntComparisonExp>;
}

/** unique or primary key constraints on table "water_progress_days" */
export enum IWaterProgressDaysConstraint {
  /** unique or primary key constraint */
  WATER_PROGRESS_DAYS_PKEY = 'water_progress_days_pkey',
  /** unique or primary key constraint */
  WATER_PROGRESS_DAYS_STARTS_ON_USER_ID_KEY = 'water_progress_days_starts_on_user_id_key'
}

/** input type for incrementing numeric columns in table "water_progress_days" */
export interface IWaterProgressDaysIncInput {
  id?: Maybe<Scalars['Int']>;
  ml?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "water_progress_days" */
export interface IWaterProgressDaysInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  ml?: Maybe<Scalars['Int']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<IUsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate max on columns */
export interface IWaterProgressDaysMaxFields {
   __typename?: 'water_progress_days_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  ml?: Maybe<Scalars['Int']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** order by max() on columns of table "water_progress_days" */
export interface IWaterProgressDaysMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  ml?: Maybe<IOrderBy>;
  starts_on?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IWaterProgressDaysMinFields {
   __typename?: 'water_progress_days_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  ml?: Maybe<Scalars['Int']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** order by min() on columns of table "water_progress_days" */
export interface IWaterProgressDaysMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  ml?: Maybe<IOrderBy>;
  starts_on?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "water_progress_days" */
export interface IWaterProgressDaysMutationResponse {
   __typename?: 'water_progress_days_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IWaterProgressDays>;
}

/** on conflict condition type for table "water_progress_days" */
export interface IWaterProgressDaysOnConflict {
  constraint: IWaterProgressDaysConstraint;
  update_columns: Array<IWaterProgressDaysUpdateColumn>;
  where?: Maybe<IWaterProgressDaysBoolExp>;
}

/** Ordering options when selecting data from "water_progress_days". */
export interface IWaterProgressDaysOrderBy {
  created_at?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  ml?: Maybe<IOrderBy>;
  starts_on?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user?: Maybe<IUsersOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** primary key columns input for table: water_progress_days */
export interface IWaterProgressDaysPkColumnsInput {
  id: Scalars['Int'];
}

/** select columns of table "water_progress_days" */
export enum IWaterProgressDaysSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  ML = 'ml',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "water_progress_days" */
export interface IWaterProgressDaysSetInput {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  ml?: Maybe<Scalars['Int']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** aggregate stddev on columns */
export interface IWaterProgressDaysStddevFields {
   __typename?: 'water_progress_days_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  ml?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "water_progress_days" */
export interface IWaterProgressDaysStddevOrderBy {
  id?: Maybe<IOrderBy>;
  ml?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IWaterProgressDaysStddevPopFields {
   __typename?: 'water_progress_days_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ml?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "water_progress_days" */
export interface IWaterProgressDaysStddevPopOrderBy {
  id?: Maybe<IOrderBy>;
  ml?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IWaterProgressDaysStddevSampFields {
   __typename?: 'water_progress_days_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ml?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "water_progress_days" */
export interface IWaterProgressDaysStddevSampOrderBy {
  id?: Maybe<IOrderBy>;
  ml?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IWaterProgressDaysSumFields {
   __typename?: 'water_progress_days_sum_fields';
  id?: Maybe<Scalars['Int']>;
  ml?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
}

/** order by sum() on columns of table "water_progress_days" */
export interface IWaterProgressDaysSumOrderBy {
  id?: Maybe<IOrderBy>;
  ml?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** update columns of table "water_progress_days" */
export enum IWaterProgressDaysUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  ID = 'id',
  /** column name */
  ML = 'ml',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate var_pop on columns */
export interface IWaterProgressDaysVarPopFields {
   __typename?: 'water_progress_days_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  ml?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "water_progress_days" */
export interface IWaterProgressDaysVarPopOrderBy {
  id?: Maybe<IOrderBy>;
  ml?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IWaterProgressDaysVarSampFields {
   __typename?: 'water_progress_days_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  ml?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "water_progress_days" */
export interface IWaterProgressDaysVarSampOrderBy {
  id?: Maybe<IOrderBy>;
  ml?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IWaterProgressDaysVarianceFields {
   __typename?: 'water_progress_days_variance_fields';
  id?: Maybe<Scalars['Float']>;
  ml?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "water_progress_days" */
export interface IWaterProgressDaysVarianceOrderBy {
  id?: Maybe<IOrderBy>;
  ml?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "workout_plans" */
export interface IWorkoutPlans {
   __typename?: 'workout_plans';
  created_at: Scalars['timestamp'];
  day_count?: Maybe<Scalars['Int']>;
  ends_on?: Maybe<Scalars['date']>;
  /** An array relationship */
  exercise_days: Array<IExerciseDays>;
  /** An aggregate relationship */
  exercise_days_aggregate: IExerciseDaysAggregate;
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user: IUsers;
  user_id: Scalars['bigint'];
}


/** columns and relationships of "workout_plans" */
export interface IWorkoutPlansExerciseDaysArgs {
  distinct_on?: Maybe<Array<IExerciseDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDaysOrderBy>>;
  where?: Maybe<IExerciseDaysBoolExp>;
}


/** columns and relationships of "workout_plans" */
export interface IWorkoutPlansExerciseDaysAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDaysOrderBy>>;
  where?: Maybe<IExerciseDaysBoolExp>;
}

/** aggregated selection of "workout_plans" */
export interface IWorkoutPlansAggregate {
   __typename?: 'workout_plans_aggregate';
  aggregate?: Maybe<IWorkoutPlansAggregateFields>;
  nodes: Array<IWorkoutPlans>;
}

/** aggregate fields of "workout_plans" */
export interface IWorkoutPlansAggregateFields {
   __typename?: 'workout_plans_aggregate_fields';
  avg?: Maybe<IWorkoutPlansAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IWorkoutPlansMaxFields>;
  min?: Maybe<IWorkoutPlansMinFields>;
  stddev?: Maybe<IWorkoutPlansStddevFields>;
  stddev_pop?: Maybe<IWorkoutPlansStddevPopFields>;
  stddev_samp?: Maybe<IWorkoutPlansStddevSampFields>;
  sum?: Maybe<IWorkoutPlansSumFields>;
  var_pop?: Maybe<IWorkoutPlansVarPopFields>;
  var_samp?: Maybe<IWorkoutPlansVarSampFields>;
  variance?: Maybe<IWorkoutPlansVarianceFields>;
}


/** aggregate fields of "workout_plans" */
export interface IWorkoutPlansAggregateFieldsCountArgs {
  columns?: Maybe<Array<IWorkoutPlansSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "workout_plans" */
export interface IWorkoutPlansAggregateOrderBy {
  avg?: Maybe<IWorkoutPlansAvgOrderBy>;
  count?: Maybe<IOrderBy>;
  max?: Maybe<IWorkoutPlansMaxOrderBy>;
  min?: Maybe<IWorkoutPlansMinOrderBy>;
  stddev?: Maybe<IWorkoutPlansStddevOrderBy>;
  stddev_pop?: Maybe<IWorkoutPlansStddevPopOrderBy>;
  stddev_samp?: Maybe<IWorkoutPlansStddevSampOrderBy>;
  sum?: Maybe<IWorkoutPlansSumOrderBy>;
  var_pop?: Maybe<IWorkoutPlansVarPopOrderBy>;
  var_samp?: Maybe<IWorkoutPlansVarSampOrderBy>;
  variance?: Maybe<IWorkoutPlansVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "workout_plans" */
export interface IWorkoutPlansArrRelInsertInput {
  data: Array<IWorkoutPlansInsertInput>;
  /** on conflict condition */
  on_conflict?: Maybe<IWorkoutPlansOnConflict>;
}

/** aggregate avg on columns */
export interface IWorkoutPlansAvgFields {
   __typename?: 'workout_plans_avg_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "workout_plans" */
export interface IWorkoutPlansAvgOrderBy {
  day_count?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "workout_plans". All fields are combined with a logical 'AND'. */
export interface IWorkoutPlansBoolExp {
  _and?: Maybe<Array<IWorkoutPlansBoolExp>>;
  _not?: Maybe<IWorkoutPlansBoolExp>;
  _or?: Maybe<Array<IWorkoutPlansBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  day_count?: Maybe<IIntComparisonExp>;
  ends_on?: Maybe<IDateComparisonExp>;
  exercise_days?: Maybe<IExerciseDaysBoolExp>;
  id?: Maybe<IBigintComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  starts_on?: Maybe<IDateComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
  user?: Maybe<IUsersBoolExp>;
  user_id?: Maybe<IBigintComparisonExp>;
}

/** unique or primary key constraints on table "workout_plans" */
export enum IWorkoutPlansConstraint {
  /** unique or primary key constraint */
  WORKOUT_PLANS_PKEY = 'workout_plans_pkey'
}

/** input type for incrementing numeric columns in table "workout_plans" */
export interface IWorkoutPlansIncInput {
  day_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "workout_plans" */
export interface IWorkoutPlansInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  day_count?: Maybe<Scalars['Int']>;
  ends_on?: Maybe<Scalars['date']>;
  exercise_days?: Maybe<IExerciseDaysArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user?: Maybe<IUsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate max on columns */
export interface IWorkoutPlansMaxFields {
   __typename?: 'workout_plans_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  day_count?: Maybe<Scalars['Int']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** order by max() on columns of table "workout_plans" */
export interface IWorkoutPlansMaxOrderBy {
  created_at?: Maybe<IOrderBy>;
  day_count?: Maybe<IOrderBy>;
  ends_on?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  starts_on?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IWorkoutPlansMinFields {
   __typename?: 'workout_plans_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  day_count?: Maybe<Scalars['Int']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** order by min() on columns of table "workout_plans" */
export interface IWorkoutPlansMinOrderBy {
  created_at?: Maybe<IOrderBy>;
  day_count?: Maybe<IOrderBy>;
  ends_on?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  starts_on?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** response of any mutation on the table "workout_plans" */
export interface IWorkoutPlansMutationResponse {
   __typename?: 'workout_plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkoutPlans>;
}

/** input type for inserting object relation for remote table "workout_plans" */
export interface IWorkoutPlansObjRelInsertInput {
  data: IWorkoutPlansInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IWorkoutPlansOnConflict>;
}

/** on conflict condition type for table "workout_plans" */
export interface IWorkoutPlansOnConflict {
  constraint: IWorkoutPlansConstraint;
  update_columns: Array<IWorkoutPlansUpdateColumn>;
  where?: Maybe<IWorkoutPlansBoolExp>;
}

/** Ordering options when selecting data from "workout_plans". */
export interface IWorkoutPlansOrderBy {
  created_at?: Maybe<IOrderBy>;
  day_count?: Maybe<IOrderBy>;
  ends_on?: Maybe<IOrderBy>;
  exercise_days_aggregate?: Maybe<IExerciseDaysAggregateOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  starts_on?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user?: Maybe<IUsersOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** primary key columns input for table: workout_plans */
export interface IWorkoutPlansPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "workout_plans" */
export enum IWorkoutPlansSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DAY_COUNT = 'day_count',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** input type for updating data in table "workout_plans" */
export interface IWorkoutPlansSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  day_count?: Maybe<Scalars['Int']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate stddev on columns */
export interface IWorkoutPlansStddevFields {
   __typename?: 'workout_plans_stddev_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "workout_plans" */
export interface IWorkoutPlansStddevOrderBy {
  day_count?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IWorkoutPlansStddevPopFields {
   __typename?: 'workout_plans_stddev_pop_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "workout_plans" */
export interface IWorkoutPlansStddevPopOrderBy {
  day_count?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IWorkoutPlansStddevSampFields {
   __typename?: 'workout_plans_stddev_samp_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "workout_plans" */
export interface IWorkoutPlansStddevSampOrderBy {
  day_count?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IWorkoutPlansSumFields {
   __typename?: 'workout_plans_sum_fields';
  day_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "workout_plans" */
export interface IWorkoutPlansSumOrderBy {
  day_count?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "workout_plans_test_view" */
export interface IWorkoutPlansTestView {
   __typename?: 'workout_plans_test_view';
  created_at?: Maybe<Scalars['timestamp']>;
  day_count?: Maybe<Scalars['Int']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregated selection of "workout_plans_test_view" */
export interface IWorkoutPlansTestViewAggregate {
   __typename?: 'workout_plans_test_view_aggregate';
  aggregate?: Maybe<IWorkoutPlansTestViewAggregateFields>;
  nodes: Array<IWorkoutPlansTestView>;
}

/** aggregate fields of "workout_plans_test_view" */
export interface IWorkoutPlansTestViewAggregateFields {
   __typename?: 'workout_plans_test_view_aggregate_fields';
  avg?: Maybe<IWorkoutPlansTestViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IWorkoutPlansTestViewMaxFields>;
  min?: Maybe<IWorkoutPlansTestViewMinFields>;
  stddev?: Maybe<IWorkoutPlansTestViewStddevFields>;
  stddev_pop?: Maybe<IWorkoutPlansTestViewStddevPopFields>;
  stddev_samp?: Maybe<IWorkoutPlansTestViewStddevSampFields>;
  sum?: Maybe<IWorkoutPlansTestViewSumFields>;
  var_pop?: Maybe<IWorkoutPlansTestViewVarPopFields>;
  var_samp?: Maybe<IWorkoutPlansTestViewVarSampFields>;
  variance?: Maybe<IWorkoutPlansTestViewVarianceFields>;
}


/** aggregate fields of "workout_plans_test_view" */
export interface IWorkoutPlansTestViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<IWorkoutPlansTestViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IWorkoutPlansTestViewAvgFields {
   __typename?: 'workout_plans_test_view_avg_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "workout_plans_test_view". All fields are combined with a logical 'AND'. */
export interface IWorkoutPlansTestViewBoolExp {
  _and?: Maybe<Array<IWorkoutPlansTestViewBoolExp>>;
  _not?: Maybe<IWorkoutPlansTestViewBoolExp>;
  _or?: Maybe<Array<IWorkoutPlansTestViewBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  day_count?: Maybe<IIntComparisonExp>;
  ends_on?: Maybe<IDateComparisonExp>;
  id?: Maybe<IBigintComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  starts_on?: Maybe<IDateComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
  user_id?: Maybe<IBigintComparisonExp>;
}

/** aggregate max on columns */
export interface IWorkoutPlansTestViewMaxFields {
   __typename?: 'workout_plans_test_view_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  day_count?: Maybe<Scalars['Int']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate min on columns */
export interface IWorkoutPlansTestViewMinFields {
   __typename?: 'workout_plans_test_view_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  day_count?: Maybe<Scalars['Int']>;
  ends_on?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  starts_on?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** Ordering options when selecting data from "workout_plans_test_view". */
export interface IWorkoutPlansTestViewOrderBy {
  created_at?: Maybe<IOrderBy>;
  day_count?: Maybe<IOrderBy>;
  ends_on?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  starts_on?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** select columns of table "workout_plans_test_view" */
export enum IWorkoutPlansTestViewSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DAY_COUNT = 'day_count',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate stddev on columns */
export interface IWorkoutPlansTestViewStddevFields {
   __typename?: 'workout_plans_test_view_stddev_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IWorkoutPlansTestViewStddevPopFields {
   __typename?: 'workout_plans_test_view_stddev_pop_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IWorkoutPlansTestViewStddevSampFields {
   __typename?: 'workout_plans_test_view_stddev_samp_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IWorkoutPlansTestViewSumFields {
   __typename?: 'workout_plans_test_view_sum_fields';
  day_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface IWorkoutPlansTestViewVarPopFields {
   __typename?: 'workout_plans_test_view_var_pop_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IWorkoutPlansTestViewVarSampFields {
   __typename?: 'workout_plans_test_view_var_samp_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IWorkoutPlansTestViewVarianceFields {
   __typename?: 'workout_plans_test_view_variance_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** update columns of table "workout_plans" */
export enum IWorkoutPlansUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DAY_COUNT = 'day_count',
  /** column name */
  ENDS_ON = 'ends_on',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  STARTS_ON = 'starts_on',
  /** column name */
  UPDATED_AT = 'updated_at',
  /** column name */
  USER_ID = 'user_id'
}

/** aggregate var_pop on columns */
export interface IWorkoutPlansVarPopFields {
   __typename?: 'workout_plans_var_pop_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "workout_plans" */
export interface IWorkoutPlansVarPopOrderBy {
  day_count?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IWorkoutPlansVarSampFields {
   __typename?: 'workout_plans_var_samp_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "workout_plans" */
export interface IWorkoutPlansVarSampOrderBy {
  day_count?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IWorkoutPlansVarianceFields {
   __typename?: 'workout_plans_variance_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "workout_plans" */
export interface IWorkoutPlansVarianceOrderBy {
  day_count?: Maybe<IOrderBy>;
  id?: Maybe<IOrderBy>;
  user_id?: Maybe<IOrderBy>;
}

/** columns and relationships of "workout_templates" */
export interface IWorkoutTemplates {
   __typename?: 'workout_templates';
  created_at: Scalars['timestamp'];
  day_count: Scalars['Int'];
  /** An array relationship */
  exercise_days: Array<IExerciseDays>;
  /** An aggregate relationship */
  exercise_days_aggregate: IExerciseDaysAggregate;
  id: Scalars['bigint'];
  name: Scalars['String'];
  updated_at: Scalars['timestamp'];
}


/** columns and relationships of "workout_templates" */
export interface IWorkoutTemplatesExerciseDaysArgs {
  distinct_on?: Maybe<Array<IExerciseDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDaysOrderBy>>;
  where?: Maybe<IExerciseDaysBoolExp>;
}


/** columns and relationships of "workout_templates" */
export interface IWorkoutTemplatesExerciseDaysAggregateArgs {
  distinct_on?: Maybe<Array<IExerciseDaysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IExerciseDaysOrderBy>>;
  where?: Maybe<IExerciseDaysBoolExp>;
}

/** aggregated selection of "workout_templates" */
export interface IWorkoutTemplatesAggregate {
   __typename?: 'workout_templates_aggregate';
  aggregate?: Maybe<IWorkoutTemplatesAggregateFields>;
  nodes: Array<IWorkoutTemplates>;
}

/** aggregate fields of "workout_templates" */
export interface IWorkoutTemplatesAggregateFields {
   __typename?: 'workout_templates_aggregate_fields';
  avg?: Maybe<IWorkoutTemplatesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IWorkoutTemplatesMaxFields>;
  min?: Maybe<IWorkoutTemplatesMinFields>;
  stddev?: Maybe<IWorkoutTemplatesStddevFields>;
  stddev_pop?: Maybe<IWorkoutTemplatesStddevPopFields>;
  stddev_samp?: Maybe<IWorkoutTemplatesStddevSampFields>;
  sum?: Maybe<IWorkoutTemplatesSumFields>;
  var_pop?: Maybe<IWorkoutTemplatesVarPopFields>;
  var_samp?: Maybe<IWorkoutTemplatesVarSampFields>;
  variance?: Maybe<IWorkoutTemplatesVarianceFields>;
}


/** aggregate fields of "workout_templates" */
export interface IWorkoutTemplatesAggregateFieldsCountArgs {
  columns?: Maybe<Array<IWorkoutTemplatesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** aggregate avg on columns */
export interface IWorkoutTemplatesAvgFields {
   __typename?: 'workout_templates_avg_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "workout_templates". All fields are combined with a logical 'AND'. */
export interface IWorkoutTemplatesBoolExp {
  _and?: Maybe<Array<IWorkoutTemplatesBoolExp>>;
  _not?: Maybe<IWorkoutTemplatesBoolExp>;
  _or?: Maybe<Array<IWorkoutTemplatesBoolExp>>;
  created_at?: Maybe<ITimestampComparisonExp>;
  day_count?: Maybe<IIntComparisonExp>;
  exercise_days?: Maybe<IExerciseDaysBoolExp>;
  id?: Maybe<IBigintComparisonExp>;
  name?: Maybe<IStringComparisonExp>;
  updated_at?: Maybe<ITimestampComparisonExp>;
}

/** unique or primary key constraints on table "workout_templates" */
export enum IWorkoutTemplatesConstraint {
  /** unique or primary key constraint */
  WORKOUT_TEMPLATES_PKEY = 'workout_templates_pkey'
}

/** input type for incrementing numeric columns in table "workout_templates" */
export interface IWorkoutTemplatesIncInput {
  day_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "workout_templates" */
export interface IWorkoutTemplatesInsertInput {
  created_at?: Maybe<Scalars['timestamp']>;
  day_count?: Maybe<Scalars['Int']>;
  exercise_days?: Maybe<IExerciseDaysArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate max on columns */
export interface IWorkoutTemplatesMaxFields {
   __typename?: 'workout_templates_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  day_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate min on columns */
export interface IWorkoutTemplatesMinFields {
   __typename?: 'workout_templates_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  day_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** response of any mutation on the table "workout_templates" */
export interface IWorkoutTemplatesMutationResponse {
   __typename?: 'workout_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IWorkoutTemplates>;
}

/** input type for inserting object relation for remote table "workout_templates" */
export interface IWorkoutTemplatesObjRelInsertInput {
  data: IWorkoutTemplatesInsertInput;
  /** on conflict condition */
  on_conflict?: Maybe<IWorkoutTemplatesOnConflict>;
}

/** on conflict condition type for table "workout_templates" */
export interface IWorkoutTemplatesOnConflict {
  constraint: IWorkoutTemplatesConstraint;
  update_columns: Array<IWorkoutTemplatesUpdateColumn>;
  where?: Maybe<IWorkoutTemplatesBoolExp>;
}

/** Ordering options when selecting data from "workout_templates". */
export interface IWorkoutTemplatesOrderBy {
  created_at?: Maybe<IOrderBy>;
  day_count?: Maybe<IOrderBy>;
  exercise_days_aggregate?: Maybe<IExerciseDaysAggregateOrderBy>;
  id?: Maybe<IOrderBy>;
  name?: Maybe<IOrderBy>;
  updated_at?: Maybe<IOrderBy>;
}

/** primary key columns input for table: workout_templates */
export interface IWorkoutTemplatesPkColumnsInput {
  id: Scalars['bigint'];
}

/** select columns of table "workout_templates" */
export enum IWorkoutTemplatesSelectColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DAY_COUNT = 'day_count',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** input type for updating data in table "workout_templates" */
export interface IWorkoutTemplatesSetInput {
  created_at?: Maybe<Scalars['timestamp']>;
  day_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate stddev on columns */
export interface IWorkoutTemplatesStddevFields {
   __typename?: 'workout_templates_stddev_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface IWorkoutTemplatesStddevPopFields {
   __typename?: 'workout_templates_stddev_pop_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface IWorkoutTemplatesStddevSampFields {
   __typename?: 'workout_templates_stddev_samp_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate sum on columns */
export interface IWorkoutTemplatesSumFields {
   __typename?: 'workout_templates_sum_fields';
  day_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
}

/** update columns of table "workout_templates" */
export enum IWorkoutTemplatesUpdateColumn {
  /** column name */
  CREATED_AT = 'created_at',
  /** column name */
  DAY_COUNT = 'day_count',
  /** column name */
  ID = 'id',
  /** column name */
  NAME = 'name',
  /** column name */
  UPDATED_AT = 'updated_at'
}

/** aggregate var_pop on columns */
export interface IWorkoutTemplatesVarPopFields {
   __typename?: 'workout_templates_var_pop_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface IWorkoutTemplatesVarSampFields {
   __typename?: 'workout_templates_var_samp_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface IWorkoutTemplatesVarianceFields {
   __typename?: 'workout_templates_variance_fields';
  day_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

export type IDeleteCookbookCategoryByPkMutationVariables = {
  id: Scalars['Int'];
};


export type IDeleteCookbookCategoryByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_cookbook_categories_by_pk?: Maybe<(
    { __typename?: 'cookbook_categories' }
    & Pick<ICookbookCategories, 'id'>
  )> }
);

export type IDeleteCookbookRecipeByPkMutationVariables = {
  id: Scalars['Int'];
};


export type IDeleteCookbookRecipeByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_cookbook_recipes_by_pk?: Maybe<(
    { __typename?: 'cookbook_recipes' }
    & Pick<ICookbookRecipes, 'id'>
  )> }
);

export type IDeleteExerciseByPkMutationVariables = {
  id: Scalars['bigint'];
};


export type IDeleteExerciseByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_exercises_by_pk?: Maybe<(
    { __typename?: 'exercises' }
    & Pick<IExercises, 'id'>
  )> }
);

export type IDeleteExerciseDaysByPkMutationVariables = {
  ids: Array<Scalars['bigint']>;
};


export type IDeleteExerciseDaysByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_exercise_days?: Maybe<(
    { __typename?: 'exercise_days_mutation_response' }
    & Pick<IExerciseDaysMutationResponse, 'affected_rows'>
  )> }
);

export type IDeleteExerciseGroupByPkMutationVariables = {
  id: Scalars['bigint'];
};


export type IDeleteExerciseGroupByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_exercise_groups_by_pk?: Maybe<(
    { __typename?: 'exercise_groups' }
    & Pick<IExerciseGroups, 'id'>
  )> }
);

export type IDeleteExercisesByPkMutationVariables = {
  ids: Array<Scalars['bigint']>;
};


export type IDeleteExercisesByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_exercises?: Maybe<(
    { __typename?: 'exercises_mutation_response' }
    & Pick<IExercisesMutationResponse, 'affected_rows'>
  )> }
);

export type IDeleteNutritionMealByPkMutationVariables = {
  id: Scalars['Int'];
};


export type IDeleteNutritionMealByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_nutrition_meals_by_pk?: Maybe<(
    { __typename?: 'nutrition_meals' }
    & Pick<INutritionMeals, 'id'>
  )> }
);

export type IDeleteNutritionMealProductByPkMutationVariables = {
  id: Scalars['Int'];
};


export type IDeleteNutritionMealProductByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_nutrition_meal_products_by_pk?: Maybe<(
    { __typename?: 'nutrition_meal_products' }
    & Pick<INutritionMealProducts, 'id'>
  )> }
);

export type IDeleteNutritionPlanByPkMutationVariables = {
  id: Scalars['Int'];
};


export type IDeleteNutritionPlanByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_nutrition_plans_by_pk?: Maybe<(
    { __typename?: 'nutrition_plans' }
    & Pick<INutritionPlans, 'id'>
  )> }
);

export type IDeleteWorkoutPlanByPkMutationVariables = {
  id: Scalars['bigint'];
};


export type IDeleteWorkoutPlanByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_workout_plans_by_pk?: Maybe<(
    { __typename?: 'workout_plans' }
    & Pick<IWorkoutPlans, 'id'>
  )> }
);

export type IGetCookbookQueryVariables = {};


export type IGetCookbookQuery = (
  { __typename?: 'query_root' }
  & { cookbook_categories: Array<(
    { __typename?: 'cookbook_categories' }
    & Pick<ICookbookCategories, 'id' | 'name' | 'photo_url'>
    & { cookbook_recipes: Array<(
      { __typename?: 'cookbook_recipes' }
      & Pick<ICookbookRecipes, 'id' | 'title' | 'description' | 'duration' | 'photo_urls'>
      & { cookbook_category: (
        { __typename?: 'cookbook_categories' }
        & Pick<ICookbookCategories, 'name' | 'id'>
      ) }
    )>, cookbook_recipes_aggregate: (
      { __typename?: 'cookbook_recipes_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'cookbook_recipes_aggregate_fields' }
        & Pick<ICookbookRecipesAggregateFields, 'count'>
      )> }
    ) }
  )> }
);

export type IGetCookbookCategoryByIdQueryVariables = {
  categoryId: Scalars['Int'];
};


export type IGetCookbookCategoryByIdQuery = (
  { __typename?: 'query_root' }
  & { cookbook_categories_by_pk?: Maybe<(
    { __typename?: 'cookbook_categories' }
    & Pick<ICookbookCategories, 'id' | 'name' | 'photo_url'>
    & { cookbook_recipes: Array<(
      { __typename?: 'cookbook_recipes' }
      & Pick<ICookbookRecipes, 'id' | 'title' | 'description' | 'duration' | 'kcal' | 'photo_urls'>
      & { cookbook_category: (
        { __typename?: 'cookbook_categories' }
        & Pick<ICookbookCategories, 'name' | 'id'>
      ) }
    )> }
  )> }
);

export type IGetDashboardUsersQueryVariables = {};


export type IGetDashboardUsersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<IUsers, 'id' | 'first_name' | 'last_name' | 'email' | 'created_at'>
  )> }
);

export type IGetExerciseVariantsQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  _like?: Maybe<Scalars['String']>;
};


export type IGetExerciseVariantsQuery = (
  { __typename?: 'query_root' }
  & { exercise_variants: Array<(
    { __typename?: 'exercise_variants' }
    & Pick<IExerciseVariants, 'id' | 'name' | 'media_content'>
  )> }
);

export type IGetExerciseDaysByWorkoutPlanIdQueryVariables = {
  workoutPlanId?: Maybe<Scalars['bigint']>;
};


export type IGetExerciseDaysByWorkoutPlanIdQuery = (
  { __typename?: 'query_root' }
  & { exercise_days: Array<(
    { __typename?: 'exercise_days' }
    & Pick<IExerciseDays, 'id' | 'sequence'>
    & { exercise_groups: Array<(
      { __typename?: 'exercise_groups' }
      & Pick<IExerciseGroups, 'id' | 'order' | 'group_type'>
      & { exercises: Array<(
        { __typename?: 'exercises' }
        & Pick<IExercises, 'id' | 'repetition' | 'duration' | 'rest' | 'unit' | 'sets'>
        & { exercise_variant: (
          { __typename?: 'exercise_variants' }
          & Pick<IExerciseVariants, 'id' | 'name' | 'exercise_muscle_category_id' | 'exercise_device_category_id'>
        ) }
      )> }
    )> }
  )> }
);

export type IGetMealPlanQueryVariables = {
  userId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  endDate?: Maybe<Scalars['date']>;
};


export type IGetMealPlanQuery = (
  { __typename?: 'query_root' }
  & { meals_view: Array<(
    { __typename?: 'meals_view' }
    & Pick<IMealsView, 'calories' | 'carbohydrates' | 'units' | 'meal_name' | 'quantity' | 'fats' | 'nutrition_meal_id' | 'proteins' | 'nutrition_meal_order' | 'product_name' | 'product_id' | 'nutrition_meal_product_id' | 'nutrition_meal_product_order'>
  )> }
);

export type IGetMealPlanByNutritionMealPlanIdQueryVariables = {
  id: Scalars['Int'];
};


export type IGetMealPlanByNutritionMealPlanIdQuery = (
  { __typename?: 'query_root' }
  & { nutrition_plan_meals_by_pk?: Maybe<(
    { __typename?: 'nutrition_plan_meals' }
    & Pick<INutritionPlanMeals, 'id'>
    & { nutrition_meals: Array<(
      { __typename?: 'nutrition_meals' }
      & Pick<INutritionMeals, 'id' | 'name'>
      & { nutrition_meal_products_aggregate: (
        { __typename?: 'nutrition_meal_products_aggregate' }
        & { nodes: Array<(
          { __typename?: 'nutrition_meal_products' }
          & Pick<INutritionMealProducts, 'id' | 'quantity'>
          & { product?: Maybe<(
            { __typename?: 'products_rg' }
            & Pick<IProductsRg, 'proteins' | 'units' | 'calories' | 'carbohydrates' | 'fats' | 'name'>
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type IGetNutritionPlanByQueryVariables = {
  userId?: Maybe<Scalars['Int']>;
};


export type IGetNutritionPlanByQuery = (
  { __typename?: 'query_root' }
  & { nutrition_plans: Array<(
    { __typename?: 'nutrition_plans' }
    & Pick<INutritionPlans, 'id' | 'start_date' | 'end_date' | 'calories_per_day' | 'protein' | 'fats' | 'push_notification_sent'>
    & { nutrition_plan_meals: Array<(
      { __typename?: 'nutrition_plan_meals' }
      & Pick<INutritionPlanMeals, 'id' | 'created_at'>
      & { nutrition_meals: Array<(
        { __typename?: 'nutrition_meals' }
        & Pick<INutritionMeals, 'name' | 'order'>
        & { nutrition_meal_products: Array<(
          { __typename?: 'nutrition_meal_products' }
          & Pick<INutritionMealProducts, 'quantity' | 'product_id' | 'order'>
        )> }
      )> }
    )> }
  )> }
);

export type IGetNutritionPlanByPkQueryVariables = {
  planId: Scalars['Int'];
};


export type IGetNutritionPlanByPkQuery = (
  { __typename?: 'query_root' }
  & { nutrition_plans_by_pk?: Maybe<(
    { __typename?: 'nutrition_plans' }
    & Pick<INutritionPlans, 'id' | 'start_date' | 'end_date' | 'calories_per_day' | 'protein' | 'fats'>
  )> }
);

export type IGetNutritionPlanMealsByPkQueryVariables = {
  id: Scalars['Int'];
};


export type IGetNutritionPlanMealsByPkQuery = (
  { __typename?: 'query_root' }
  & { nutrition_plan_meals_by_pk?: Maybe<(
    { __typename?: 'nutrition_plan_meals' }
    & Pick<INutritionPlanMeals, 'id' | 'is_original' | 'created_at'>
    & { nutrition_plan_meals_view?: Maybe<(
      { __typename?: 'nutrition_plan_meals_view' }
      & Pick<INutritionPlanMealsView, 'calories_maintenance_per_day' | 'calories_weekly_reduction' | 'calories_per_day' | 'carbo_sum' | 'fat_sum' | 'protein_sum' | 'calorie_sum' | 'carbo' | 'min_carbo' | 'max_carbo' | 'fat' | 'min_fat' | 'max_fat' | 'protein' | 'min_protein' | 'max_protein'>
    )>, nutrition_plan: (
      { __typename?: 'nutrition_plans' }
      & Pick<INutritionPlans, 'id' | 'fats' | 'calories_per_day' | 'end_date' | 'order' | 'protein' | 'start_date'>
    ) }
  )> }
);

export type IGetNutritionPlanMealsQueryVariables = {
  nutritionPlanId?: Maybe<Scalars['Int']>;
};


export type IGetNutritionPlanMealsQuery = (
  { __typename?: 'query_root' }
  & { nutrition_plan_meals: Array<(
    { __typename?: 'nutrition_plan_meals' }
    & Pick<INutritionPlanMeals, 'id' | 'created_at'>
    & { nutrition_meals_aggregate: (
      { __typename?: 'nutrition_meals_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'nutrition_meals_aggregate_fields' }
        & Pick<INutritionMealsAggregateFields, 'count'>
      )> }
    ), nutrition_meals: Array<(
      { __typename?: 'nutrition_meals' }
      & { nutrition_meal_products_aggregate: (
        { __typename?: 'nutrition_meal_products_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'nutrition_meal_products_aggregate_fields' }
          & Pick<INutritionMealProductsAggregateFields, 'count'>
        )> }
      ) }
    )> }
  )> }
);

export type IGetNutritionPlanMealsViewQueryVariables = {
  userId: Scalars['bigint'];
  nutritionPlanId: Scalars['Int'];
};


export type IGetNutritionPlanMealsViewQuery = (
  { __typename?: 'query_root' }
  & { nutrition_plan_meals_view: Array<(
    { __typename?: 'nutrition_plan_meals_view' }
    & Pick<INutritionPlanMealsView, 'calories_maintenance_per_day' | 'calories_weekly_reduction' | 'calories_per_day' | 'carbo_sum' | 'fat_sum' | 'protein_sum' | 'calorie_sum' | 'carbo' | 'min_carbo' | 'max_carbo' | 'fat' | 'min_fat' | 'max_fat' | 'protein' | 'min_protein' | 'max_protein'>
  )> }
);

export type IGetNutritionPlanNutritionMealQueryVariables = {
  planId: Scalars['Int'];
};


export type IGetNutritionPlanNutritionMealQuery = (
  { __typename?: 'query_root' }
  & { nutrition_plan_meals: Array<(
    { __typename?: 'nutrition_plan_meals' }
    & Pick<INutritionPlanMeals, 'id' | 'is_original' | 'created_at'>
  )> }
);

export type IGetAllProductsAndCategoriesQueryVariables = {};


export type IGetAllProductsAndCategoriesQuery = (
  { __typename?: 'query_root' }
  & { products_rg: Array<(
    { __typename?: 'products_rg' }
    & Pick<IProductsRg, 'product_category_id' | 'name' | 'fats' | 'carbohydrates' | 'calories' | 'proteins' | 'units' | 'id'>
    & { product_category?: Maybe<(
      { __typename?: 'product_categories' }
      & Pick<IProductCategories, 'name'>
    )> }
  )> }
);

export type IGetProductsListByQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  _like?: Maybe<Scalars['String']>;
};


export type IGetProductsListByQuery = (
  { __typename?: 'query_root' }
  & { products_rg: Array<(
    { __typename?: 'products_rg' }
    & Pick<IProductsRg, 'carbohydrates' | 'calories' | 'proteins' | 'unit' | 'name' | 'id' | 'fats' | 'units'>
  )> }
);

export type IGetProgramPlanParticipantsQueryVariables = {
  programPlanId?: Maybe<Scalars['bigint']>;
};


export type IGetProgramPlanParticipantsQuery = (
  { __typename?: 'query_root' }
  & { program_plan_participators: Array<(
    { __typename?: 'program_plan_participators' }
    & Pick<IProgramPlanParticipators, 'created_at' | 'program_plan_id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<IUsers, 'avatar_url' | 'created_at' | 'date_of_birth' | 'email' | 'first_name' | 'gender' | 'id' | 'is_blocked' | 'last_name' | 'locked_at' | 'middle_name' | 'name' | 'username'>
    ) }
  )> }
);

export type IGetProgramPlansBetweenDatesQueryVariables = {
  _gte?: Maybe<Scalars['timestamp']>;
  _lt?: Maybe<Scalars['timestamp']>;
};


export type IGetProgramPlansBetweenDatesQuery = (
  { __typename?: 'query_root' }
  & { program_plans: Array<(
    { __typename?: 'program_plans' }
    & Pick<IProgramPlans, 'id' | 'program_id' | 'starts_at' | 'updated_at'>
    & { program: (
      { __typename?: 'programs' }
      & Pick<IPrograms, 'duration_unit' | 'duration_amount' | 'name'>
    ) }
  )> }
);

export type IGetProgramPlanByIdQueryVariables = {
  programPlanId: Scalars['bigint'];
};


export type IGetProgramPlanByIdQuery = (
  { __typename?: 'query_root' }
  & { program_plans_by_pk?: Maybe<(
    { __typename?: 'program_plans' }
    & Pick<IProgramPlans, 'id' | 'program_id' | 'starts_at' | 'updated_at'>
    & { program: (
      { __typename?: 'programs' }
      & Pick<IPrograms, 'duration_unit' | 'duration_amount' | 'name'>
    ) }
  )> }
);

export type IGetProgramPlansDatesRangeQueryVariables = {};


export type IGetProgramPlansDatesRangeQuery = (
  { __typename?: 'query_root' }
  & { program_plans_aggregate: (
    { __typename?: 'program_plans_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'program_plans_aggregate_fields' }
      & { min?: Maybe<(
        { __typename?: 'program_plans_min_fields' }
        & Pick<IProgramPlansMinFields, 'starts_at'>
      )>, max?: Maybe<(
        { __typename?: 'program_plans_max_fields' }
        & Pick<IProgramPlansMaxFields, 'starts_at'>
      )> }
    )> }
  ) }
);

export type IGetUserByIdQueryVariables = {
  userId: Scalars['bigint'];
};


export type IGetUserByIdQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<IUsers, 'avatar_url' | 'id' | 'first_name' | 'last_name' | 'email' | 'gender' | 'biography' | 'language' | 'created_at'>
    & { user_profile?: Maybe<(
      { __typename?: 'user_profiles' }
      & Pick<IUserProfiles, 'id' | 'coach_id' | 'has_injuries' | 'date_of_birth' | 'is_vegan' | 'is_allergic' | 'is_vegetarian' | 'initial_height' | 'initial_weight' | 'meals_dislike' | 'meals_like' | 'rythm' | 'find_us_on' | 'how_to_help' | 'weekdays' | 'weekend' | 'training_frequency' | 'program_info' | 'training_expectations' | 'result_after_1month' | 'result_after_3month' | 'result_after_6month'>
      & { diet_summary?: Maybe<(
        { __typename?: 'diet_summaries' }
        & Pick<IDietSummaries, 'id' | 'activity_level' | 'fat_range' | 'kcal_deficiency' | 'personal_goal' | 'protein_range' | 'user_profile_id'>
      )>, current_coach?: Maybe<(
        { __typename?: 'users' }
        & Pick<IUsers, 'first_name'>
      )> }
    )>, progress_weeks: Array<(
      { __typename?: 'progress_weeks' }
      & Pick<IProgressWeeks, 'id' | 'starts_on' | 'ends_on' | 'goal_weight' | 'start_weight' | 'week_number'>
      & { progress_days: Array<(
        { __typename?: 'progress_days' }
        & Pick<IProgressDays, 'id' | 'date' | 'front_image' | 'side_image' | 'back_image' | 'body_weight' | 'thigh' | 'waist' | 'hips' | 'chest' | 'updated_at'>
      )> }
    )>, workout_plans: Array<(
      { __typename?: 'workout_plans' }
      & Pick<IWorkoutPlans, 'day_count' | 'ends_on' | 'id' | 'name' | 'starts_on'>
    )>, program_plan_participators: Array<(
      { __typename?: 'program_plan_participators' }
      & Pick<IProgramPlanParticipators, 'id'>
      & { program_plan: (
        { __typename?: 'program_plans' }
        & Pick<IProgramPlans, 'id' | 'starts_at'>
        & { program: (
          { __typename?: 'programs' }
          & Pick<IPrograms, 'id' | 'duration_amount'>
        ) }
      ) }
    )> }
  )>, user_profiles_aggregate: (
    { __typename?: 'user_profiles_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'user_profiles_aggregate_fields' }
      & Pick<IUserProfilesAggregateFields, 'count'>
    )> }
  ) }
);

export type IGetWorkoutPlansQueryVariables = {};


export type IGetWorkoutPlansQuery = (
  { __typename?: 'query_root' }
  & { workout_plans: Array<(
    { __typename?: 'workout_plans' }
    & Pick<IWorkoutPlans, 'id' | 'day_count' | 'ends_on' | 'name' | 'starts_on'>
    & { user: (
      { __typename?: 'users' }
      & Pick<IUsers, 'email'>
    ) }
  )> }
);

export type IGetWorkoutPlansByUserIdQueryVariables = {
  userId: Scalars['bigint'];
};


export type IGetWorkoutPlansByUserIdQuery = (
  { __typename?: 'query_root' }
  & { workout_plans: Array<(
    { __typename?: 'workout_plans' }
    & Pick<IWorkoutPlans, 'id' | 'name' | 'starts_on' | 'ends_on' | 'day_count' | 'user_id'>
  )> }
);

export type IGetWorkoutPlanByPkQueryVariables = {
  id: Scalars['bigint'];
};


export type IGetWorkoutPlanByPkQuery = (
  { __typename?: 'query_root' }
  & { workout_plans_by_pk?: Maybe<(
    { __typename?: 'workout_plans' }
    & Pick<IWorkoutPlans, 'id' | 'name' | 'starts_on' | 'ends_on' | 'day_count' | 'user_id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<IUsers, 'first_name' | 'last_name'>
    ) }
  )> }
);

export type IGetWorkoutTemplatesQueryVariables = {
  dayCount: Scalars['Int'];
};


export type IGetWorkoutTemplatesQuery = (
  { __typename?: 'query_root' }
  & { workout_templates: Array<(
    { __typename?: 'workout_templates' }
    & Pick<IWorkoutTemplates, 'name' | 'id' | 'day_count'>
    & { exercise_days: Array<(
      { __typename?: 'exercise_days' }
      & Pick<IExerciseDays, 'id' | 'sequence' | 'workout_template_id'>
      & { exercise_groups: Array<(
        { __typename?: 'exercise_groups' }
        & Pick<IExerciseGroups, 'id' | 'order' | 'group_type'>
        & { exercises: Array<(
          { __typename?: 'exercises' }
          & Pick<IExercises, 'id' | 'repetition' | 'rest' | 'unit' | 'sets'>
          & { exercise_variant: (
            { __typename?: 'exercise_variants' }
            & Pick<IExerciseVariants, 'id' | 'name' | 'exercise_muscle_category_id' | 'exercise_device_category_id'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type IInsertCookbookCategoryMutationVariables = {
  objects: Array<ICookbookCategoriesInsertInput>;
};


export type IInsertCookbookCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { insert_cookbook_categories?: Maybe<(
    { __typename?: 'cookbook_categories_mutation_response' }
    & Pick<ICookbookCategoriesMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'cookbook_categories' }
      & Pick<ICookbookCategories, 'id'>
    )> }
  )> }
);

export type IInsertCookbookRecipeMutationVariables = {
  objects: Array<ICookbookRecipesInsertInput>;
};


export type IInsertCookbookRecipeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_cookbook_recipes?: Maybe<(
    { __typename?: 'cookbook_recipes_mutation_response' }
    & Pick<ICookbookRecipesMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'cookbook_recipes' }
      & Pick<ICookbookRecipes, 'id' | 'title' | 'description' | 'photo_urls' | 'duration' | 'kcal'>
    )> }
  )> }
);

export type IInsertExerciseMutationVariables = {
  exercise_variant_id: Scalars['bigint'];
  exercise_group_id: Scalars['bigint'];
  repetition?: Maybe<Scalars['String']>;
  rest?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  sets?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};


export type IInsertExerciseMutation = (
  { __typename?: 'mutation_root' }
  & { insert_exercises_one?: Maybe<(
    { __typename?: 'exercises' }
    & Pick<IExercises, 'id' | 'created_at' | 'exercise_group_id' | 'exercise_variant_id' | 'repetition' | 'rest' | 'sequence' | 'sets' | 'updated_at' | 'weight'>
    & { exercise_variant: (
      { __typename?: 'exercise_variants' }
      & Pick<IExerciseVariants, 'created_at' | 'exercise_device_category_id' | 'exercise_muscle_category_id' | 'id' | 'name' | 'updated_at'>
    ) }
  )> }
);

export type IInsertExerciseDaysMutationVariables = {
  objects: Array<IExerciseDaysInsertInput>;
};


export type IInsertExerciseDaysMutation = (
  { __typename?: 'mutation_root' }
  & { insert_exercise_days?: Maybe<(
    { __typename?: 'exercise_days_mutation_response' }
    & Pick<IExerciseDaysMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'exercise_days' }
      & Pick<IExerciseDays, 'id'>
    )> }
  )> }
);

export type IInsertExerciseGroupMutationVariables = {
  groupType: Scalars['String'];
  exerciseDayId: Scalars['bigint'];
  order: Scalars['Int'];
};


export type IInsertExerciseGroupMutation = (
  { __typename?: 'mutation_root' }
  & { insert_exercise_groups_one?: Maybe<(
    { __typename?: 'exercise_groups' }
    & Pick<IExerciseGroups, 'id'>
  )> }
);

export type IInsertNewMealMutationVariables = {
  name?: Maybe<Scalars['String']>;
  nutrition_plan_meals_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};


export type IInsertNewMealMutation = (
  { __typename?: 'mutation_root' }
  & { insert_nutrition_meals_one?: Maybe<(
    { __typename?: 'nutrition_meals' }
    & Pick<INutritionMeals, 'id' | 'name'>
  )> }
);

export type IInsertNewProductMutationVariables = {
  nutrition_meal_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};


export type IInsertNewProductMutation = (
  { __typename?: 'mutation_root' }
  & { insert_nutrition_meal_products_one?: Maybe<(
    { __typename?: 'nutrition_meal_products' }
    & Pick<INutritionMealProducts, 'id'>
  )> }
);

export type IInsertNutritionPlanMutationVariables = {
  objects: Array<INutritionPlansInsertInput>;
};


export type IInsertNutritionPlanMutation = (
  { __typename?: 'mutation_root' }
  & { insert_nutrition_plans?: Maybe<(
    { __typename?: 'nutrition_plans_mutation_response' }
    & Pick<INutritionPlansMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'nutrition_plans' }
      & Pick<INutritionPlans, 'id'>
    )> }
  )> }
);

export type IInsertOrUpdateDietSummaryMutationVariables = {
  dateOfBirth: Scalars['date'];
  initialWeight: Scalars['numeric'];
  initialHeight: Scalars['numeric'];
  activityLevel: Scalars['numeric'];
  personalGoal: Scalars['Int'];
  proteinRange: Scalars['numeric'];
  fatRange?: Maybe<Scalars['numeric']>;
  kcalDeficiency?: Maybe<Scalars['Int']>;
  userProfileId: Scalars['bigint'];
};


export type IInsertOrUpdateDietSummaryMutation = (
  { __typename?: 'mutation_root' }
  & { update_user_profiles_by_pk?: Maybe<(
    { __typename?: 'user_profiles' }
    & Pick<IUserProfiles, 'id'>
  )>, insert_diet_summaries_one?: Maybe<(
    { __typename?: 'diet_summaries' }
    & Pick<IDietSummaries, 'id'>
  )> }
);

export type IInsertUserProfilesMutationVariables = {
  objects: Array<IUserProfilesInsertInput>;
};


export type IInsertUserProfilesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_profiles?: Maybe<(
    { __typename?: 'user_profiles_mutation_response' }
    & Pick<IUserProfilesMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'user_profiles' }
      & Pick<IUserProfiles, 'id'>
    )> }
  )> }
);

export type IInsertWorkoutPlanMutationVariables = {
  objects: Array<IWorkoutPlansInsertInput>;
};


export type IInsertWorkoutPlanMutation = (
  { __typename?: 'mutation_root' }
  & { insert_workout_plans?: Maybe<(
    { __typename?: 'workout_plans_mutation_response' }
    & Pick<IWorkoutPlansMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'workout_plans' }
      & Pick<IWorkoutPlans, 'id'>
    )> }
  )> }
);

export type IInsertWorkoutTemplatesMutationVariables = {
  objects: Array<IWorkoutTemplatesInsertInput>;
};


export type IInsertWorkoutTemplatesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_workout_templates?: Maybe<(
    { __typename?: 'workout_templates_mutation_response' }
    & Pick<IWorkoutTemplatesMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'workout_templates' }
      & Pick<IWorkoutTemplates, 'id'>
    )> }
  )> }
);

export type IUpdateCookbookCategoryByPkMutationVariables = {
  id: Scalars['Int'];
  changes: ICookbookCategoriesSetInput;
};


export type IUpdateCookbookCategoryByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_cookbook_categories_by_pk?: Maybe<(
    { __typename?: 'cookbook_categories' }
    & Pick<ICookbookCategories, 'id' | 'name' | 'photo_url'>
  )> }
);

export type IUpdateCookbookRecipeByPkMutationVariables = {
  id: Scalars['Int'];
  changes: ICookbookRecipesSetInput;
};


export type IUpdateCookbookRecipeByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_cookbook_recipes_by_pk?: Maybe<(
    { __typename?: 'cookbook_recipes' }
    & Pick<ICookbookRecipes, 'id' | 'title' | 'description' | 'photo_urls' | 'duration' | 'kcal'>
  )> }
);

export type IUpdateExerciseGroupByPkMutationVariables = {
  id: Scalars['bigint'];
  order: Scalars['Int'];
};


export type IUpdateExerciseGroupByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_exercise_groups_by_pk?: Maybe<(
    { __typename?: 'exercise_groups' }
    & Pick<IExerciseGroups, 'id' | 'order'>
  )> }
);

export type IUpdateNutritionMealByPkMutationVariables = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type IUpdateNutritionMealByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_nutrition_meals_by_pk?: Maybe<(
    { __typename?: 'nutrition_meals' }
    & Pick<INutritionMeals, 'id'>
  )> }
);

export type IUpdateNutritionMealProductByPkMutationVariables = {
  id: Scalars['Int'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
};


export type IUpdateNutritionMealProductByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_nutrition_meal_products_by_pk?: Maybe<(
    { __typename?: 'nutrition_meal_products' }
    & Pick<INutritionMealProducts, 'id'>
  )> }
);

export type IUpdateNutritionPlanByPkMutationVariables = {
  id: Scalars['Int'];
  input: INutritionPlansSetInput;
};


export type IUpdateNutritionPlanByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_nutrition_plans_by_pk?: Maybe<(
    { __typename?: 'nutrition_plans' }
    & Pick<INutritionPlans, 'id'>
  )> }
);

export type IUpdateOrderForMealProductMutationVariables = {
  nutritionMealProductId: Scalars['Int'];
  newOrder: Scalars['Int'];
};


export type IUpdateOrderForMealProductMutation = (
  { __typename?: 'mutation_root' }
  & { update_nutrition_meal_products_by_pk?: Maybe<(
    { __typename?: 'nutrition_meal_products' }
    & Pick<INutritionMealProducts, 'updated_at'>
  )> }
);

export type IUpdateUserByPkMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes: IUsersSetInput;
};


export type IUpdateUserByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & Pick<IUsersMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'users' }
      & Pick<IUsers, 'id'>
    )> }
  )> }
);

export type IUpdateUserProfileByPkMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes: IUserProfilesSetInput;
};


export type IUpdateUserProfileByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_user_profiles?: Maybe<(
    { __typename?: 'user_profiles_mutation_response' }
    & Pick<IUserProfilesMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'user_profiles' }
      & Pick<IUserProfiles, 'id'>
    )> }
  )> }
);

export type IUpdateWorkoutPlanByPkMutationVariables = {
  id: Scalars['bigint'];
  ends_on?: Maybe<Scalars['date']>;
  name?: Maybe<Scalars['String']>;
  starts_on?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['bigint']>;
};


export type IUpdateWorkoutPlanByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_workout_plans_by_pk?: Maybe<(
    { __typename?: 'workout_plans' }
    & Pick<IWorkoutPlans, 'id'>
  )> }
);

export type IUpsertExercisesMutationVariables = {
  objects?: Array<IExercisesInsertInput>;
};


export type IUpsertExercisesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_exercises?: Maybe<(
    { __typename?: 'exercises_mutation_response' }
    & Pick<IExercisesMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'exercises' }
      & Pick<IExercises, 'id' | 'exercise_group_id' | 'sequence'>
    )> }
  )> }
);

export type IDeleteBlogArticleByPkMutationVariables = {
  id: Scalars['Int'];
};


export type IDeleteBlogArticleByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_blog_articles_by_pk?: Maybe<(
    { __typename?: 'blog_articles' }
    & Pick<IBlogArticles, 'id'>
  )> }
);

export type IGetBlogCategoryByPkQueryVariables = {
  id: Scalars['Int'];
};


export type IGetBlogCategoryByPkQuery = (
  { __typename?: 'query_root' }
  & { blog_categories_by_pk?: Maybe<(
    { __typename?: 'blog_categories' }
    & Pick<IBlogCategories, 'id' | 'name'>
    & { blog_articles: Array<(
      { __typename?: 'blog_articles' }
      & Pick<IBlogArticles, 'id' | 'title' | 'text' | 'tag' | 'photo_url'>
    )> }
  )> }
);

export type IInsertBlogArticleMutationVariables = {
  objects: Array<IBlogArticlesInsertInput>;
};


export type IInsertBlogArticleMutation = (
  { __typename?: 'mutation_root' }
  & { insert_blog_articles?: Maybe<(
    { __typename?: 'blog_articles_mutation_response' }
    & Pick<IBlogArticlesMutationResponse, 'affected_rows'>
  )> }
);

export type IUpdateBlogArticleByPkMutationVariables = {
  id: Scalars['Int'];
  changes: IBlogArticlesSetInput;
};


export type IUpdateBlogArticleByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_blog_articles_by_pk?: Maybe<(
    { __typename?: 'blog_articles' }
    & Pick<IBlogArticles, 'id' | 'title' | 'text' | 'tag' | 'created_at' | 'updated_at'>
  )> }
);

export type IDeleteBlogCategoryByPkMutationVariables = {
  id: Scalars['Int'];
};


export type IDeleteBlogCategoryByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_blog_categories_by_pk?: Maybe<(
    { __typename?: 'blog_categories' }
    & Pick<IBlogCategories, 'id'>
  )> }
);

export type IGetBlogCategoriesQueryVariables = {};


export type IGetBlogCategoriesQuery = (
  { __typename?: 'query_root' }
  & { blog_categories_aggregate: (
    { __typename?: 'blog_categories_aggregate' }
    & { nodes: Array<(
      { __typename?: 'blog_categories' }
      & Pick<IBlogCategories, 'id' | 'name'>
      & { blog_articles_aggregate: (
        { __typename?: 'blog_articles_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'blog_articles_aggregate_fields' }
          & Pick<IBlogArticlesAggregateFields, 'count'>
        )> }
      ) }
    )> }
  ) }
);

export type IInsertBlogCategoryMutationVariables = {
  objects: Array<IBlogCategoriesInsertInput>;
};


export type IInsertBlogCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { insert_blog_categories?: Maybe<(
    { __typename?: 'blog_categories_mutation_response' }
    & Pick<IBlogCategoriesMutationResponse, 'affected_rows'>
  )> }
);

export type IUpdateBlogCategoryByPkMutationVariables = {
  id: Scalars['Int'];
  changes: IBlogCategoriesSetInput;
};


export type IUpdateBlogCategoryByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_blog_categories_by_pk?: Maybe<(
    { __typename?: 'blog_categories' }
    & Pick<IBlogCategories, 'id' | 'name'>
  )> }
);

export type IGetUsersLikeQueryVariables = {
  _like?: Maybe<Scalars['String']>;
};


export type IGetUsersLikeQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<IUsers, 'id' | 'avatar_url' | 'first_name' | 'last_name'>
  )> }
);

export type IGetNotificationsQueryVariables = {};


export type IGetNotificationsQuery = (
  { __typename?: 'query_root' }
  & { progress_days: Array<(
    { __typename?: 'progress_days' }
    & Pick<IProgressDays, 'id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<IUsers, 'id' | 'first_name' | 'last_name'>
    ) }
  )> }
);

export type ISetSeenAtMutationVariables = {
  id: Scalars['bigint'];
};


export type ISetSeenAtMutation = (
  { __typename?: 'mutation_root' }
  & { update_progress_days_by_pk?: Maybe<(
    { __typename?: 'progress_days' }
    & Pick<IProgressDays, 'id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<IUsers, 'id' | 'first_name' | 'last_name'>
    ) }
  )> }
);

export type IGetExercisesProgressQueryVariables = {
  id: Scalars['bigint'];
};


export type IGetExercisesProgressQuery = (
  { __typename?: 'query_root' }
  & { exercise_progressions: Array<(
    { __typename?: 'exercise_progressions' }
    & Pick<IExerciseProgressions, 'id' | 'sets' | 'created_at'>
    & { exercise: (
      { __typename?: 'exercises' }
      & Pick<IExercises, 'id'>
      & { exercise_variant: (
        { __typename?: 'exercise_variants' }
        & Pick<IExerciseVariants, 'id' | 'name'>
      ) }
    ) }
  )>, workout_plans_by_pk?: Maybe<(
    { __typename?: 'workout_plans' }
    & { user: (
      { __typename?: 'users' }
      & Pick<IUsers, 'first_name' | 'last_name' | 'id'>
    ) }
  )> }
);

export type IGetMessagesByInboxIdSubscriptionVariables = {
  id: Scalars['Int'];
};


export type IGetMessagesByInboxIdSubscription = (
  { __typename?: 'subscription_root' }
  & { inbox_messages: Array<(
    { __typename?: 'inbox_messages' }
    & Pick<IInboxMessages, 'id' | 'sender_id' | 'message' | 'updated_at' | 'read'>
    & { inbox: (
      { __typename?: 'inbox' }
      & Pick<IInbox, 'id' | 'coach_id' | 'client_id'>
    ) }
  )> }
);

export type IInsertMessageMutationVariables = {
  inboxId: Scalars['Int'];
  message: Scalars['String'];
  senderId: Scalars['Int'];
};


export type IInsertMessageMutation = (
  { __typename?: 'mutation_root' }
  & { insert_inbox_messages_one?: Maybe<(
    { __typename?: 'inbox_messages' }
    & Pick<IInboxMessages, 'id' | 'message' | 'sender_id'>
  )> }
);

export type IMarkAsReadMutationVariables = {
  ids?: Maybe<Array<Scalars['Int']>>;
};


export type IMarkAsReadMutation = (
  { __typename?: 'mutation_root' }
  & { update_inbox_messages?: Maybe<(
    { __typename?: 'inbox_messages_mutation_response' }
    & Pick<IInboxMessagesMutationResponse, 'affected_rows'>
  )> }
);

export type IGetCoachMembersQueryVariables = {
  ilike: Scalars['String'];
  coachId: Scalars['bigint'];
};


export type IGetCoachMembersQuery = (
  { __typename?: 'query_root' }
  & { user_coach_view: Array<(
    { __typename?: 'user_coach_view' }
    & Pick<IUserCoachView, 'client_last_name' | 'client_id' | 'client_first_name' | 'coach_id'>
  )> }
);

export type IInsertInboxMutationVariables = {
  coachId: Scalars['Int'];
  clientId: Scalars['Int'];
};


export type IInsertInboxMutation = (
  { __typename?: 'mutation_root' }
  & { insert_inbox_one?: Maybe<(
    { __typename?: 'inbox' }
    & Pick<IInbox, 'id'>
    & { client: (
      { __typename?: 'users' }
      & Pick<IUsers, 'id' | 'first_name' | 'last_name' | 'avatar_url'>
    ), coach: (
      { __typename?: 'users' }
      & Pick<IUsers, 'id' | 'first_name'>
    ), inbox_messages: Array<(
      { __typename?: 'inbox_messages' }
      & Pick<IInboxMessages, 'id' | 'message' | 'sender_id' | 'created_at' | 'updated_at'>
    )> }
  )> }
);

export type IGetInboxSubscriptionVariables = {};


export type IGetInboxSubscription = (
  { __typename?: 'subscription_root' }
  & { inbox: Array<(
    { __typename?: 'inbox' }
    & Pick<IInbox, 'id'>
    & { client: (
      { __typename?: 'users' }
      & Pick<IUsers, 'id' | 'first_name' | 'last_name' | 'avatar_url'>
      & { program_plan_participators: Array<(
        { __typename?: 'program_plan_participators' }
        & { program_plan: (
          { __typename?: 'program_plans' }
          & Pick<IProgramPlans, 'id' | 'starts_at'>
          & { program: (
            { __typename?: 'programs' }
            & Pick<IPrograms, 'duration_amount'>
          ) }
        ) }
      )> }
    ), coach: (
      { __typename?: 'users' }
      & Pick<IUsers, 'id' | 'first_name'>
    ), inbox_messages: Array<(
      { __typename?: 'inbox_messages' }
      & Pick<IInboxMessages, 'id' | 'message' | 'sender_id' | 'read' | 'created_at' | 'updated_at'>
    )> }
  )> }
);

export type IDeleteKnowledgeBaseByPkMutationVariables = {
  id: Scalars['Int'];
};


export type IDeleteKnowledgeBaseByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_knowledge_base_by_pk?: Maybe<(
    { __typename?: 'knowledge_base' }
    & Pick<IKnowledgeBase, 'id'>
  )> }
);

export type IGetKnowledgeBaseQueryVariables = {};


export type IGetKnowledgeBaseQuery = (
  { __typename?: 'query_root' }
  & { knowledge_base_aggregate: (
    { __typename?: 'knowledge_base_aggregate' }
    & { nodes: Array<(
      { __typename?: 'knowledge_base' }
      & Pick<IKnowledgeBase, 'id' | 'description' | 'sequence'>
      & { knowledge_base_videos_aggregate: (
        { __typename?: 'knowledge_base_videos_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'knowledge_base_videos_aggregate_fields' }
          & Pick<IKnowledgeBaseVideosAggregateFields, 'count'>
        )> }
      ) }
    )> }
  ) }
);

export type IInsertKnowledgeBaseMutationVariables = {
  objects: Array<IKnowledgeBaseInsertInput>;
};


export type IInsertKnowledgeBaseMutation = (
  { __typename?: 'mutation_root' }
  & { insert_knowledge_base?: Maybe<(
    { __typename?: 'knowledge_base_mutation_response' }
    & Pick<IKnowledgeBaseMutationResponse, 'affected_rows'>
  )> }
);

export type IUpdateKnowledgeBaseByPkMutationVariables = {
  id: Scalars['Int'];
  changes: IKnowledgeBaseSetInput;
};


export type IUpdateKnowledgeBaseByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_knowledge_base_by_pk?: Maybe<(
    { __typename?: 'knowledge_base' }
    & Pick<IKnowledgeBase, 'id'>
  )> }
);

export type IDeleteKnowledgeBaseVideosByPkMutationVariables = {
  id: Scalars['Int'];
};


export type IDeleteKnowledgeBaseVideosByPkMutation = (
  { __typename?: 'mutation_root' }
  & { delete_knowledge_base_videos_by_pk?: Maybe<(
    { __typename?: 'knowledge_base_videos' }
    & Pick<IKnowledgeBaseVideos, 'id'>
  )> }
);

export type IGetKnowledgeBaseByPkQueryVariables = {
  id: Scalars['Int'];
};


export type IGetKnowledgeBaseByPkQuery = (
  { __typename?: 'query_root' }
  & { knowledge_base_by_pk?: Maybe<(
    { __typename?: 'knowledge_base' }
    & Pick<IKnowledgeBase, 'id' | 'description' | 'sequence'>
    & { knowledge_base_videos: Array<(
      { __typename?: 'knowledge_base_videos' }
      & Pick<IKnowledgeBaseVideos, 'id' | 'title' | 'sequence' | 'video_url' | 'duration_in_minutes' | 'knowledge_base_id'>
    )> }
  )> }
);

export type IInsertKnowledgeBaseVideosMutationVariables = {
  objects: Array<IKnowledgeBaseVideosInsertInput>;
};


export type IInsertKnowledgeBaseVideosMutation = (
  { __typename?: 'mutation_root' }
  & { insert_knowledge_base_videos?: Maybe<(
    { __typename?: 'knowledge_base_videos_mutation_response' }
    & Pick<IKnowledgeBaseVideosMutationResponse, 'affected_rows'>
  )> }
);

export type IUpdateKnowledgeBaseVideosByPkMutationVariables = {
  id: Scalars['Int'];
  changes: IKnowledgeBaseVideosSetInput;
};


export type IUpdateKnowledgeBaseVideosByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_knowledge_base_videos_by_pk?: Maybe<(
    { __typename?: 'knowledge_base_videos' }
    & Pick<IKnowledgeBaseVideos, 'id'>
  )> }
);

export type IUpdateProductMutationVariables = {
  productId: Scalars['Int'];
  input?: Maybe<IProductsRgSetInput>;
};


export type IUpdateProductMutation = (
  { __typename?: 'mutation_root' }
  & { update_products_rg_by_pk?: Maybe<(
    { __typename?: 'products_rg' }
    & Pick<IProductsRg, 'id'>
  )> }
);

export type IApproveProductMutationVariables = {
  id: Scalars['Int'];
};


export type IApproveProductMutation = (
  { __typename?: 'mutation_root' }
  & { update_products_rg_review_by_pk?: Maybe<(
    { __typename?: 'products_rg_review' }
    & Pick<IProductsRgReview, 'id'>
  )> }
);

export type IGetProductCategoriesQueryVariables = {};


export type IGetProductCategoriesQuery = (
  { __typename?: 'query_root' }
  & { product_categories: Array<(
    { __typename?: 'product_categories' }
    & Pick<IProductCategories, 'id' | 'name'>
  )> }
);

export type IGetRevieableProductsQueryVariables = {};


export type IGetRevieableProductsQuery = (
  { __typename?: 'query_root' }
  & { products_rg_review: Array<(
    { __typename?: 'products_rg_review' }
    & Pick<IProductsRgReview, 'id' | 'name' | 'calories' | 'unit' | 'carbohydrates' | 'fats' | 'proteins' | 'barcode' | 'created_at'>
    & { product_rg?: Maybe<(
      { __typename?: 'products_rg' }
      & Pick<IProductsRg, 'id' | 'product_category_id'>
    )>, user: (
      { __typename?: 'users' }
      & Pick<IUsers, 'id' | 'first_name' | 'last_name'>
    ) }
  )> }
);

export type IInsertProductMutationVariables = {
  input: Array<IProductsRgInsertInput>;
};


export type IInsertProductMutation = (
  { __typename?: 'mutation_root' }
  & { insert_products_rg?: Maybe<(
    { __typename?: 'products_rg_mutation_response' }
    & Pick<IProductsRgMutationResponse, 'affected_rows'>
  )> }
);

export type IGetAllExerciseVariantsQueryVariables = {};


export type IGetAllExerciseVariantsQuery = (
  { __typename?: 'query_root' }
  & { exercise_variants: Array<(
    { __typename?: 'exercise_variants' }
    & Pick<IExerciseVariants, 'id' | 'name' | 'exercise_muscle_category_id' | 'exercise_device_category_id' | 'exercise_variant_category' | 'media_content'>
    & { exercise_muscle_category: (
      { __typename?: 'exercise_muscle_categories' }
      & Pick<IExerciseMuscleCategories, 'id' | 'name'>
      & { exercise_variants: Array<(
        { __typename?: 'exercise_variants' }
        & Pick<IExerciseVariants, 'id' | 'name' | 'exercise_muscle_category_id' | 'exercise_device_category_id' | 'exercise_variant_category'>
      )> }
    ) }
  )> }
);

export type IDeleteExerciseGroupMutationVariables = {
  id: Scalars['bigint'];
};


export type IDeleteExerciseGroupMutation = (
  { __typename?: 'mutation_root' }
  & { delete_exercise_groups_by_pk?: Maybe<(
    { __typename?: 'exercise_groups' }
    & Pick<IExerciseGroups, 'id'>
  )> }
);

export type IGetProductByIdQueryVariables = {
  id: Scalars['Int'];
};


export type IGetProductByIdQuery = (
  { __typename?: 'query_root' }
  & { products_rg_by_pk?: Maybe<(
    { __typename?: 'products_rg' }
    & Pick<IProductsRg, 'id' | 'name' | 'calories' | 'carbohydrates' | 'proteins' | 'fats' | 'units'>
  )> }
);

export type IGetDiaryAnswersQueryVariables = {
  userId: Scalars['bigint'];
};


export type IGetDiaryAnswersQuery = (
  { __typename?: 'query_root' }
  & { diary_answers: Array<(
    { __typename?: 'diary_answers' }
    & Pick<IDiaryAnswers, 'id' | 'answer' | 'motivation' | 'created_at'>
    & { diary_question: (
      { __typename?: 'diary_questions' }
      & Pick<IDiaryQuestions, 'id' | 'question' | 'answers' | 'category' | 'type' | 'range_to' | 'range_from' | 'requires_motivation' | 'created_at'>
    ) }
  )> }
);


export const DeleteCookbookCategoryByPkDocument = gql`
    mutation DeleteCookbookCategoryByPk($id: Int!) {
  delete_cookbook_categories_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteCookbookCategoryByPkMutation__
 *
 * To run a mutation, you first call `useDeleteCookbookCategoryByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCookbookCategoryByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCookbookCategoryByPkMutation, { data, loading, error }] = useDeleteCookbookCategoryByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCookbookCategoryByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteCookbookCategoryByPkMutation, IDeleteCookbookCategoryByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteCookbookCategoryByPkMutation, IDeleteCookbookCategoryByPkMutationVariables>(DeleteCookbookCategoryByPkDocument, baseOptions);
      }
export type DeleteCookbookCategoryByPkMutationHookResult = ReturnType<typeof useDeleteCookbookCategoryByPkMutation>;
export type DeleteCookbookCategoryByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteCookbookCategoryByPkMutation>;
export type DeleteCookbookCategoryByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteCookbookCategoryByPkMutation, IDeleteCookbookCategoryByPkMutationVariables>;
export const DeleteCookbookRecipeByPkDocument = gql`
    mutation DeleteCookbookRecipeByPk($id: Int!) {
  delete_cookbook_recipes_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteCookbookRecipeByPkMutation__
 *
 * To run a mutation, you first call `useDeleteCookbookRecipeByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCookbookRecipeByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCookbookRecipeByPkMutation, { data, loading, error }] = useDeleteCookbookRecipeByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCookbookRecipeByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteCookbookRecipeByPkMutation, IDeleteCookbookRecipeByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteCookbookRecipeByPkMutation, IDeleteCookbookRecipeByPkMutationVariables>(DeleteCookbookRecipeByPkDocument, baseOptions);
      }
export type DeleteCookbookRecipeByPkMutationHookResult = ReturnType<typeof useDeleteCookbookRecipeByPkMutation>;
export type DeleteCookbookRecipeByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteCookbookRecipeByPkMutation>;
export type DeleteCookbookRecipeByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteCookbookRecipeByPkMutation, IDeleteCookbookRecipeByPkMutationVariables>;
export const DeleteExerciseByPkDocument = gql`
    mutation DeleteExerciseByPk($id: bigint!) {
  delete_exercises_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteExerciseByPkMutation__
 *
 * To run a mutation, you first call `useDeleteExerciseByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExerciseByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExerciseByPkMutation, { data, loading, error }] = useDeleteExerciseByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExerciseByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteExerciseByPkMutation, IDeleteExerciseByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteExerciseByPkMutation, IDeleteExerciseByPkMutationVariables>(DeleteExerciseByPkDocument, baseOptions);
      }
export type DeleteExerciseByPkMutationHookResult = ReturnType<typeof useDeleteExerciseByPkMutation>;
export type DeleteExerciseByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteExerciseByPkMutation>;
export type DeleteExerciseByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteExerciseByPkMutation, IDeleteExerciseByPkMutationVariables>;
export const DeleteExerciseDaysByPkDocument = gql`
    mutation DeleteExerciseDaysByPk($ids: [bigint!]!) {
  delete_exercise_days(where: {id: {_in: $ids}}) {
    affected_rows
  }
}
    `;

/**
 * __useDeleteExerciseDaysByPkMutation__
 *
 * To run a mutation, you first call `useDeleteExerciseDaysByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExerciseDaysByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExerciseDaysByPkMutation, { data, loading, error }] = useDeleteExerciseDaysByPkMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteExerciseDaysByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteExerciseDaysByPkMutation, IDeleteExerciseDaysByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteExerciseDaysByPkMutation, IDeleteExerciseDaysByPkMutationVariables>(DeleteExerciseDaysByPkDocument, baseOptions);
      }
export type DeleteExerciseDaysByPkMutationHookResult = ReturnType<typeof useDeleteExerciseDaysByPkMutation>;
export type DeleteExerciseDaysByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteExerciseDaysByPkMutation>;
export type DeleteExerciseDaysByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteExerciseDaysByPkMutation, IDeleteExerciseDaysByPkMutationVariables>;
export const DeleteExerciseGroupByPkDocument = gql`
    mutation DeleteExerciseGroupByPk($id: bigint!) {
  delete_exercise_groups_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteExerciseGroupByPkMutation__
 *
 * To run a mutation, you first call `useDeleteExerciseGroupByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExerciseGroupByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExerciseGroupByPkMutation, { data, loading, error }] = useDeleteExerciseGroupByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExerciseGroupByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteExerciseGroupByPkMutation, IDeleteExerciseGroupByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteExerciseGroupByPkMutation, IDeleteExerciseGroupByPkMutationVariables>(DeleteExerciseGroupByPkDocument, baseOptions);
      }
export type DeleteExerciseGroupByPkMutationHookResult = ReturnType<typeof useDeleteExerciseGroupByPkMutation>;
export type DeleteExerciseGroupByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteExerciseGroupByPkMutation>;
export type DeleteExerciseGroupByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteExerciseGroupByPkMutation, IDeleteExerciseGroupByPkMutationVariables>;
export const DeleteExercisesByPkDocument = gql`
    mutation DeleteExercisesByPk($ids: [bigint!]!) {
  delete_exercises(where: {id: {_in: $ids}}) {
    affected_rows
  }
}
    `;

/**
 * __useDeleteExercisesByPkMutation__
 *
 * To run a mutation, you first call `useDeleteExercisesByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExercisesByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExercisesByPkMutation, { data, loading, error }] = useDeleteExercisesByPkMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteExercisesByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteExercisesByPkMutation, IDeleteExercisesByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteExercisesByPkMutation, IDeleteExercisesByPkMutationVariables>(DeleteExercisesByPkDocument, baseOptions);
      }
export type DeleteExercisesByPkMutationHookResult = ReturnType<typeof useDeleteExercisesByPkMutation>;
export type DeleteExercisesByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteExercisesByPkMutation>;
export type DeleteExercisesByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteExercisesByPkMutation, IDeleteExercisesByPkMutationVariables>;
export const DeleteNutritionMealByPkDocument = gql`
    mutation DeleteNutritionMealByPk($id: Int!) {
  delete_nutrition_meals_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteNutritionMealByPkMutation__
 *
 * To run a mutation, you first call `useDeleteNutritionMealByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNutritionMealByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNutritionMealByPkMutation, { data, loading, error }] = useDeleteNutritionMealByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNutritionMealByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteNutritionMealByPkMutation, IDeleteNutritionMealByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteNutritionMealByPkMutation, IDeleteNutritionMealByPkMutationVariables>(DeleteNutritionMealByPkDocument, baseOptions);
      }
export type DeleteNutritionMealByPkMutationHookResult = ReturnType<typeof useDeleteNutritionMealByPkMutation>;
export type DeleteNutritionMealByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteNutritionMealByPkMutation>;
export type DeleteNutritionMealByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteNutritionMealByPkMutation, IDeleteNutritionMealByPkMutationVariables>;
export const DeleteNutritionMealProductByPkDocument = gql`
    mutation DeleteNutritionMealProductByPk($id: Int!) {
  delete_nutrition_meal_products_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteNutritionMealProductByPkMutation__
 *
 * To run a mutation, you first call `useDeleteNutritionMealProductByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNutritionMealProductByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNutritionMealProductByPkMutation, { data, loading, error }] = useDeleteNutritionMealProductByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNutritionMealProductByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteNutritionMealProductByPkMutation, IDeleteNutritionMealProductByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteNutritionMealProductByPkMutation, IDeleteNutritionMealProductByPkMutationVariables>(DeleteNutritionMealProductByPkDocument, baseOptions);
      }
export type DeleteNutritionMealProductByPkMutationHookResult = ReturnType<typeof useDeleteNutritionMealProductByPkMutation>;
export type DeleteNutritionMealProductByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteNutritionMealProductByPkMutation>;
export type DeleteNutritionMealProductByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteNutritionMealProductByPkMutation, IDeleteNutritionMealProductByPkMutationVariables>;
export const DeleteNutritionPlanByPkDocument = gql`
    mutation DeleteNutritionPlanByPk($id: Int!) {
  delete_nutrition_plans_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteNutritionPlanByPkMutation__
 *
 * To run a mutation, you first call `useDeleteNutritionPlanByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNutritionPlanByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNutritionPlanByPkMutation, { data, loading, error }] = useDeleteNutritionPlanByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNutritionPlanByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteNutritionPlanByPkMutation, IDeleteNutritionPlanByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteNutritionPlanByPkMutation, IDeleteNutritionPlanByPkMutationVariables>(DeleteNutritionPlanByPkDocument, baseOptions);
      }
export type DeleteNutritionPlanByPkMutationHookResult = ReturnType<typeof useDeleteNutritionPlanByPkMutation>;
export type DeleteNutritionPlanByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteNutritionPlanByPkMutation>;
export type DeleteNutritionPlanByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteNutritionPlanByPkMutation, IDeleteNutritionPlanByPkMutationVariables>;
export const DeleteWorkoutPlanByPkDocument = gql`
    mutation DeleteWorkoutPlanByPk($id: bigint!) {
  delete_workout_plans_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteWorkoutPlanByPkMutation__
 *
 * To run a mutation, you first call `useDeleteWorkoutPlanByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkoutPlanByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkoutPlanByPkMutation, { data, loading, error }] = useDeleteWorkoutPlanByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkoutPlanByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteWorkoutPlanByPkMutation, IDeleteWorkoutPlanByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteWorkoutPlanByPkMutation, IDeleteWorkoutPlanByPkMutationVariables>(DeleteWorkoutPlanByPkDocument, baseOptions);
      }
export type DeleteWorkoutPlanByPkMutationHookResult = ReturnType<typeof useDeleteWorkoutPlanByPkMutation>;
export type DeleteWorkoutPlanByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteWorkoutPlanByPkMutation>;
export type DeleteWorkoutPlanByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteWorkoutPlanByPkMutation, IDeleteWorkoutPlanByPkMutationVariables>;
export const GetCookbookDocument = gql`
    query GetCookbook {
  cookbook_categories {
    id
    name
    photo_url
    cookbook_recipes {
      id
      title
      description
      duration
      photo_urls
      cookbook_category {
        name
        id
      }
    }
    cookbook_recipes_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetCookbookQuery__
 *
 * To run a query within a React component, call `useGetCookbookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCookbookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCookbookQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCookbookQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetCookbookQuery, IGetCookbookQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetCookbookQuery, IGetCookbookQueryVariables>(GetCookbookDocument, baseOptions);
      }
export function useGetCookbookLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetCookbookQuery, IGetCookbookQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetCookbookQuery, IGetCookbookQueryVariables>(GetCookbookDocument, baseOptions);
        }
export type GetCookbookQueryHookResult = ReturnType<typeof useGetCookbookQuery>;
export type GetCookbookLazyQueryHookResult = ReturnType<typeof useGetCookbookLazyQuery>;
export type GetCookbookQueryResult = ApolloReactCommon.QueryResult<IGetCookbookQuery, IGetCookbookQueryVariables>;
export function refetchGetCookbookQuery(variables?: IGetCookbookQueryVariables) {
      return { query: GetCookbookDocument, variables: variables }
    }
export const GetCookbookCategoryByIdDocument = gql`
    query GetCookbookCategoryById($categoryId: Int!) {
  cookbook_categories_by_pk(id: $categoryId) {
    id
    name
    photo_url
    cookbook_recipes {
      id
      title
      description
      duration
      kcal
      photo_urls
      cookbook_category {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetCookbookCategoryByIdQuery__
 *
 * To run a query within a React component, call `useGetCookbookCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCookbookCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCookbookCategoryByIdQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetCookbookCategoryByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetCookbookCategoryByIdQuery, IGetCookbookCategoryByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetCookbookCategoryByIdQuery, IGetCookbookCategoryByIdQueryVariables>(GetCookbookCategoryByIdDocument, baseOptions);
      }
export function useGetCookbookCategoryByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetCookbookCategoryByIdQuery, IGetCookbookCategoryByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetCookbookCategoryByIdQuery, IGetCookbookCategoryByIdQueryVariables>(GetCookbookCategoryByIdDocument, baseOptions);
        }
export type GetCookbookCategoryByIdQueryHookResult = ReturnType<typeof useGetCookbookCategoryByIdQuery>;
export type GetCookbookCategoryByIdLazyQueryHookResult = ReturnType<typeof useGetCookbookCategoryByIdLazyQuery>;
export type GetCookbookCategoryByIdQueryResult = ApolloReactCommon.QueryResult<IGetCookbookCategoryByIdQuery, IGetCookbookCategoryByIdQueryVariables>;
export function refetchGetCookbookCategoryByIdQuery(variables?: IGetCookbookCategoryByIdQueryVariables) {
      return { query: GetCookbookCategoryByIdDocument, variables: variables }
    }
export const GetDashboardUsersDocument = gql`
    query GetDashboardUsers {
  users(limit: 10) {
    id
    first_name
    last_name
    email
    created_at
  }
}
    `;

/**
 * __useGetDashboardUsersQuery__
 *
 * To run a query within a React component, call `useGetDashboardUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetDashboardUsersQuery, IGetDashboardUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetDashboardUsersQuery, IGetDashboardUsersQueryVariables>(GetDashboardUsersDocument, baseOptions);
      }
export function useGetDashboardUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetDashboardUsersQuery, IGetDashboardUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetDashboardUsersQuery, IGetDashboardUsersQueryVariables>(GetDashboardUsersDocument, baseOptions);
        }
export type GetDashboardUsersQueryHookResult = ReturnType<typeof useGetDashboardUsersQuery>;
export type GetDashboardUsersLazyQueryHookResult = ReturnType<typeof useGetDashboardUsersLazyQuery>;
export type GetDashboardUsersQueryResult = ApolloReactCommon.QueryResult<IGetDashboardUsersQuery, IGetDashboardUsersQueryVariables>;
export function refetchGetDashboardUsersQuery(variables?: IGetDashboardUsersQueryVariables) {
      return { query: GetDashboardUsersDocument, variables: variables }
    }
export const GetExerciseVariantsDocument = gql`
    query GetExerciseVariants($limit: Int = 10, $_like: String = "") {
  exercise_variants(limit: $limit, where: {name: {_like: $_like}}) {
    id
    name
    media_content
  }
}
    `;

/**
 * __useGetExerciseVariantsQuery__
 *
 * To run a query within a React component, call `useGetExerciseVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExerciseVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExerciseVariantsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      _like: // value for '_like'
 *   },
 * });
 */
export function useGetExerciseVariantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetExerciseVariantsQuery, IGetExerciseVariantsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetExerciseVariantsQuery, IGetExerciseVariantsQueryVariables>(GetExerciseVariantsDocument, baseOptions);
      }
export function useGetExerciseVariantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetExerciseVariantsQuery, IGetExerciseVariantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetExerciseVariantsQuery, IGetExerciseVariantsQueryVariables>(GetExerciseVariantsDocument, baseOptions);
        }
export type GetExerciseVariantsQueryHookResult = ReturnType<typeof useGetExerciseVariantsQuery>;
export type GetExerciseVariantsLazyQueryHookResult = ReturnType<typeof useGetExerciseVariantsLazyQuery>;
export type GetExerciseVariantsQueryResult = ApolloReactCommon.QueryResult<IGetExerciseVariantsQuery, IGetExerciseVariantsQueryVariables>;
export function refetchGetExerciseVariantsQuery(variables?: IGetExerciseVariantsQueryVariables) {
      return { query: GetExerciseVariantsDocument, variables: variables }
    }
export const GetExerciseDaysByWorkoutPlanIdDocument = gql`
    query GetExerciseDaysByWorkoutPlanId($workoutPlanId: bigint) {
  exercise_days(
    where: {workout_plan_id: {_eq: $workoutPlanId}}
    order_by: {sequence: asc}
  ) {
    id
    sequence
    exercise_groups(order_by: {order: asc}) {
      id
      order
      group_type
      exercises(order_by: {id: asc}) {
        id
        repetition
        duration
        rest
        unit
        sets
        exercise_variant {
          id
          name
          exercise_muscle_category_id
          exercise_device_category_id
        }
      }
    }
  }
}
    `;

/**
 * __useGetExerciseDaysByWorkoutPlanIdQuery__
 *
 * To run a query within a React component, call `useGetExerciseDaysByWorkoutPlanIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExerciseDaysByWorkoutPlanIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExerciseDaysByWorkoutPlanIdQuery({
 *   variables: {
 *      workoutPlanId: // value for 'workoutPlanId'
 *   },
 * });
 */
export function useGetExerciseDaysByWorkoutPlanIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetExerciseDaysByWorkoutPlanIdQuery, IGetExerciseDaysByWorkoutPlanIdQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetExerciseDaysByWorkoutPlanIdQuery, IGetExerciseDaysByWorkoutPlanIdQueryVariables>(GetExerciseDaysByWorkoutPlanIdDocument, baseOptions);
      }
export function useGetExerciseDaysByWorkoutPlanIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetExerciseDaysByWorkoutPlanIdQuery, IGetExerciseDaysByWorkoutPlanIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetExerciseDaysByWorkoutPlanIdQuery, IGetExerciseDaysByWorkoutPlanIdQueryVariables>(GetExerciseDaysByWorkoutPlanIdDocument, baseOptions);
        }
export type GetExerciseDaysByWorkoutPlanIdQueryHookResult = ReturnType<typeof useGetExerciseDaysByWorkoutPlanIdQuery>;
export type GetExerciseDaysByWorkoutPlanIdLazyQueryHookResult = ReturnType<typeof useGetExerciseDaysByWorkoutPlanIdLazyQuery>;
export type GetExerciseDaysByWorkoutPlanIdQueryResult = ApolloReactCommon.QueryResult<IGetExerciseDaysByWorkoutPlanIdQuery, IGetExerciseDaysByWorkoutPlanIdQueryVariables>;
export function refetchGetExerciseDaysByWorkoutPlanIdQuery(variables?: IGetExerciseDaysByWorkoutPlanIdQueryVariables) {
      return { query: GetExerciseDaysByWorkoutPlanIdDocument, variables: variables }
    }
export const GetMealPlanDocument = gql`
    query getMealPlan($userId: Int, $startDate: date, $endDate: date) {
  meals_view(
    where: {user_id: {_eq: $userId}, start_date: {_gte: $startDate, _lte: $endDate}, nutrition_plan_meals_is_original: {_eq: true}}
  ) {
    calories
    carbohydrates
    units
    meal_name
    quantity
    fats
    nutrition_meal_id
    proteins
    nutrition_meal_order
    product_name
    product_id
    nutrition_meal_product_id
    nutrition_meal_product_order
  }
}
    `;

/**
 * __useGetMealPlanQuery__
 *
 * To run a query within a React component, call `useGetMealPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMealPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMealPlanQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetMealPlanQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetMealPlanQuery, IGetMealPlanQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetMealPlanQuery, IGetMealPlanQueryVariables>(GetMealPlanDocument, baseOptions);
      }
export function useGetMealPlanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetMealPlanQuery, IGetMealPlanQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetMealPlanQuery, IGetMealPlanQueryVariables>(GetMealPlanDocument, baseOptions);
        }
export type GetMealPlanQueryHookResult = ReturnType<typeof useGetMealPlanQuery>;
export type GetMealPlanLazyQueryHookResult = ReturnType<typeof useGetMealPlanLazyQuery>;
export type GetMealPlanQueryResult = ApolloReactCommon.QueryResult<IGetMealPlanQuery, IGetMealPlanQueryVariables>;
export function refetchGetMealPlanQuery(variables?: IGetMealPlanQueryVariables) {
      return { query: GetMealPlanDocument, variables: variables }
    }
export const GetMealPlanByNutritionMealPlanIdDocument = gql`
    query getMealPlanByNutritionMealPlanId($id: Int!) {
  nutrition_plan_meals_by_pk(id: $id) {
    id
    nutrition_meals(order_by: {order: asc}) {
      id
      name
      nutrition_meal_products_aggregate(order_by: {order: asc}) {
        nodes {
          id
          product {
            proteins
            units
            calories
            carbohydrates
            fats
            name
          }
          quantity
        }
      }
    }
  }
}
    `;

/**
 * __useGetMealPlanByNutritionMealPlanIdQuery__
 *
 * To run a query within a React component, call `useGetMealPlanByNutritionMealPlanIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMealPlanByNutritionMealPlanIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMealPlanByNutritionMealPlanIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMealPlanByNutritionMealPlanIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetMealPlanByNutritionMealPlanIdQuery, IGetMealPlanByNutritionMealPlanIdQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetMealPlanByNutritionMealPlanIdQuery, IGetMealPlanByNutritionMealPlanIdQueryVariables>(GetMealPlanByNutritionMealPlanIdDocument, baseOptions);
      }
export function useGetMealPlanByNutritionMealPlanIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetMealPlanByNutritionMealPlanIdQuery, IGetMealPlanByNutritionMealPlanIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetMealPlanByNutritionMealPlanIdQuery, IGetMealPlanByNutritionMealPlanIdQueryVariables>(GetMealPlanByNutritionMealPlanIdDocument, baseOptions);
        }
export type GetMealPlanByNutritionMealPlanIdQueryHookResult = ReturnType<typeof useGetMealPlanByNutritionMealPlanIdQuery>;
export type GetMealPlanByNutritionMealPlanIdLazyQueryHookResult = ReturnType<typeof useGetMealPlanByNutritionMealPlanIdLazyQuery>;
export type GetMealPlanByNutritionMealPlanIdQueryResult = ApolloReactCommon.QueryResult<IGetMealPlanByNutritionMealPlanIdQuery, IGetMealPlanByNutritionMealPlanIdQueryVariables>;
export function refetchGetMealPlanByNutritionMealPlanIdQuery(variables?: IGetMealPlanByNutritionMealPlanIdQueryVariables) {
      return { query: GetMealPlanByNutritionMealPlanIdDocument, variables: variables }
    }
export const GetNutritionPlanByDocument = gql`
    query GetNutritionPlanBy($userId: Int) {
  nutrition_plans(where: {user_id: {_eq: $userId}}) {
    id
    start_date
    end_date
    calories_per_day
    protein
    fats
    push_notification_sent
    nutrition_plan_meals(where: {is_original: {_eq: true}}) {
      id
      created_at
      nutrition_meals {
        name
        order
        nutrition_meal_products {
          quantity
          product_id
          order
        }
      }
    }
  }
}
    `;

/**
 * __useGetNutritionPlanByQuery__
 *
 * To run a query within a React component, call `useGetNutritionPlanByQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNutritionPlanByQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNutritionPlanByQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetNutritionPlanByQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetNutritionPlanByQuery, IGetNutritionPlanByQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetNutritionPlanByQuery, IGetNutritionPlanByQueryVariables>(GetNutritionPlanByDocument, baseOptions);
      }
export function useGetNutritionPlanByLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetNutritionPlanByQuery, IGetNutritionPlanByQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetNutritionPlanByQuery, IGetNutritionPlanByQueryVariables>(GetNutritionPlanByDocument, baseOptions);
        }
export type GetNutritionPlanByQueryHookResult = ReturnType<typeof useGetNutritionPlanByQuery>;
export type GetNutritionPlanByLazyQueryHookResult = ReturnType<typeof useGetNutritionPlanByLazyQuery>;
export type GetNutritionPlanByQueryResult = ApolloReactCommon.QueryResult<IGetNutritionPlanByQuery, IGetNutritionPlanByQueryVariables>;
export function refetchGetNutritionPlanByQuery(variables?: IGetNutritionPlanByQueryVariables) {
      return { query: GetNutritionPlanByDocument, variables: variables }
    }
export const GetNutritionPlanByPkDocument = gql`
    query GetNutritionPlanByPk($planId: Int!) {
  nutrition_plans_by_pk(id: $planId) {
    id
    start_date
    end_date
    calories_per_day
    protein
    fats
  }
}
    `;

/**
 * __useGetNutritionPlanByPkQuery__
 *
 * To run a query within a React component, call `useGetNutritionPlanByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNutritionPlanByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNutritionPlanByPkQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useGetNutritionPlanByPkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetNutritionPlanByPkQuery, IGetNutritionPlanByPkQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetNutritionPlanByPkQuery, IGetNutritionPlanByPkQueryVariables>(GetNutritionPlanByPkDocument, baseOptions);
      }
export function useGetNutritionPlanByPkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetNutritionPlanByPkQuery, IGetNutritionPlanByPkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetNutritionPlanByPkQuery, IGetNutritionPlanByPkQueryVariables>(GetNutritionPlanByPkDocument, baseOptions);
        }
export type GetNutritionPlanByPkQueryHookResult = ReturnType<typeof useGetNutritionPlanByPkQuery>;
export type GetNutritionPlanByPkLazyQueryHookResult = ReturnType<typeof useGetNutritionPlanByPkLazyQuery>;
export type GetNutritionPlanByPkQueryResult = ApolloReactCommon.QueryResult<IGetNutritionPlanByPkQuery, IGetNutritionPlanByPkQueryVariables>;
export function refetchGetNutritionPlanByPkQuery(variables?: IGetNutritionPlanByPkQueryVariables) {
      return { query: GetNutritionPlanByPkDocument, variables: variables }
    }
export const GetNutritionPlanMealsByPkDocument = gql`
    query GetNutritionPlanMealsByPk($id: Int!) {
  nutrition_plan_meals_by_pk(id: $id) {
    id
    nutrition_plan_meals_view {
      calories_maintenance_per_day
      calories_weekly_reduction
      calories_per_day
      carbo_sum
      fat_sum
      protein_sum
      calorie_sum
      carbo
      min_carbo
      max_carbo
      fat
      min_fat
      max_fat
      protein
      min_protein
      max_protein
    }
    nutrition_plan {
      id
      fats
      calories_per_day
      end_date
      order
      protein
      start_date
    }
    is_original
    created_at
  }
}
    `;

/**
 * __useGetNutritionPlanMealsByPkQuery__
 *
 * To run a query within a React component, call `useGetNutritionPlanMealsByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNutritionPlanMealsByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNutritionPlanMealsByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNutritionPlanMealsByPkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetNutritionPlanMealsByPkQuery, IGetNutritionPlanMealsByPkQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetNutritionPlanMealsByPkQuery, IGetNutritionPlanMealsByPkQueryVariables>(GetNutritionPlanMealsByPkDocument, baseOptions);
      }
export function useGetNutritionPlanMealsByPkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetNutritionPlanMealsByPkQuery, IGetNutritionPlanMealsByPkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetNutritionPlanMealsByPkQuery, IGetNutritionPlanMealsByPkQueryVariables>(GetNutritionPlanMealsByPkDocument, baseOptions);
        }
export type GetNutritionPlanMealsByPkQueryHookResult = ReturnType<typeof useGetNutritionPlanMealsByPkQuery>;
export type GetNutritionPlanMealsByPkLazyQueryHookResult = ReturnType<typeof useGetNutritionPlanMealsByPkLazyQuery>;
export type GetNutritionPlanMealsByPkQueryResult = ApolloReactCommon.QueryResult<IGetNutritionPlanMealsByPkQuery, IGetNutritionPlanMealsByPkQueryVariables>;
export function refetchGetNutritionPlanMealsByPkQuery(variables?: IGetNutritionPlanMealsByPkQueryVariables) {
      return { query: GetNutritionPlanMealsByPkDocument, variables: variables }
    }
export const GetNutritionPlanMealsDocument = gql`
    query GetNutritionPlanMeals($nutritionPlanId: Int) {
  nutrition_plan_meals(
    where: {is_original: {_eq: false}, nutrition_plan_id: {_eq: $nutritionPlanId}}
    order_by: {created_at: asc}
  ) {
    id
    created_at
    nutrition_meals_aggregate {
      aggregate {
        count
      }
    }
    nutrition_meals {
      nutrition_meal_products_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useGetNutritionPlanMealsQuery__
 *
 * To run a query within a React component, call `useGetNutritionPlanMealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNutritionPlanMealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNutritionPlanMealsQuery({
 *   variables: {
 *      nutritionPlanId: // value for 'nutritionPlanId'
 *   },
 * });
 */
export function useGetNutritionPlanMealsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetNutritionPlanMealsQuery, IGetNutritionPlanMealsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetNutritionPlanMealsQuery, IGetNutritionPlanMealsQueryVariables>(GetNutritionPlanMealsDocument, baseOptions);
      }
export function useGetNutritionPlanMealsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetNutritionPlanMealsQuery, IGetNutritionPlanMealsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetNutritionPlanMealsQuery, IGetNutritionPlanMealsQueryVariables>(GetNutritionPlanMealsDocument, baseOptions);
        }
export type GetNutritionPlanMealsQueryHookResult = ReturnType<typeof useGetNutritionPlanMealsQuery>;
export type GetNutritionPlanMealsLazyQueryHookResult = ReturnType<typeof useGetNutritionPlanMealsLazyQuery>;
export type GetNutritionPlanMealsQueryResult = ApolloReactCommon.QueryResult<IGetNutritionPlanMealsQuery, IGetNutritionPlanMealsQueryVariables>;
export function refetchGetNutritionPlanMealsQuery(variables?: IGetNutritionPlanMealsQueryVariables) {
      return { query: GetNutritionPlanMealsDocument, variables: variables }
    }
export const GetNutritionPlanMealsViewDocument = gql`
    query GetNutritionPlanMealsView($userId: bigint!, $nutritionPlanId: Int!) {
  nutrition_plan_meals_view(
    where: {user_id: {_eq: $userId}, nutrition_plan_id: {_eq: $nutritionPlanId}, is_original: {_eq: true}}
  ) {
    calories_maintenance_per_day
    calories_weekly_reduction
    calories_per_day
    carbo_sum
    fat_sum
    protein_sum
    calorie_sum
    carbo
    min_carbo
    max_carbo
    fat
    min_fat
    max_fat
    protein
    min_protein
    max_protein
  }
}
    `;

/**
 * __useGetNutritionPlanMealsViewQuery__
 *
 * To run a query within a React component, call `useGetNutritionPlanMealsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNutritionPlanMealsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNutritionPlanMealsViewQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      nutritionPlanId: // value for 'nutritionPlanId'
 *   },
 * });
 */
export function useGetNutritionPlanMealsViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetNutritionPlanMealsViewQuery, IGetNutritionPlanMealsViewQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetNutritionPlanMealsViewQuery, IGetNutritionPlanMealsViewQueryVariables>(GetNutritionPlanMealsViewDocument, baseOptions);
      }
export function useGetNutritionPlanMealsViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetNutritionPlanMealsViewQuery, IGetNutritionPlanMealsViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetNutritionPlanMealsViewQuery, IGetNutritionPlanMealsViewQueryVariables>(GetNutritionPlanMealsViewDocument, baseOptions);
        }
export type GetNutritionPlanMealsViewQueryHookResult = ReturnType<typeof useGetNutritionPlanMealsViewQuery>;
export type GetNutritionPlanMealsViewLazyQueryHookResult = ReturnType<typeof useGetNutritionPlanMealsViewLazyQuery>;
export type GetNutritionPlanMealsViewQueryResult = ApolloReactCommon.QueryResult<IGetNutritionPlanMealsViewQuery, IGetNutritionPlanMealsViewQueryVariables>;
export function refetchGetNutritionPlanMealsViewQuery(variables?: IGetNutritionPlanMealsViewQueryVariables) {
      return { query: GetNutritionPlanMealsViewDocument, variables: variables }
    }
export const GetNutritionPlanNutritionMealDocument = gql`
    query GetNutritionPlanNutritionMeal($planId: Int!) {
  nutrition_plan_meals(where: {nutrition_plan_id: {_eq: $planId}}) {
    id
    is_original
    created_at
  }
}
    `;

/**
 * __useGetNutritionPlanNutritionMealQuery__
 *
 * To run a query within a React component, call `useGetNutritionPlanNutritionMealQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNutritionPlanNutritionMealQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNutritionPlanNutritionMealQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useGetNutritionPlanNutritionMealQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetNutritionPlanNutritionMealQuery, IGetNutritionPlanNutritionMealQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetNutritionPlanNutritionMealQuery, IGetNutritionPlanNutritionMealQueryVariables>(GetNutritionPlanNutritionMealDocument, baseOptions);
      }
export function useGetNutritionPlanNutritionMealLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetNutritionPlanNutritionMealQuery, IGetNutritionPlanNutritionMealQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetNutritionPlanNutritionMealQuery, IGetNutritionPlanNutritionMealQueryVariables>(GetNutritionPlanNutritionMealDocument, baseOptions);
        }
export type GetNutritionPlanNutritionMealQueryHookResult = ReturnType<typeof useGetNutritionPlanNutritionMealQuery>;
export type GetNutritionPlanNutritionMealLazyQueryHookResult = ReturnType<typeof useGetNutritionPlanNutritionMealLazyQuery>;
export type GetNutritionPlanNutritionMealQueryResult = ApolloReactCommon.QueryResult<IGetNutritionPlanNutritionMealQuery, IGetNutritionPlanNutritionMealQueryVariables>;
export function refetchGetNutritionPlanNutritionMealQuery(variables?: IGetNutritionPlanNutritionMealQueryVariables) {
      return { query: GetNutritionPlanNutritionMealDocument, variables: variables }
    }
export const GetAllProductsAndCategoriesDocument = gql`
    query GetAllProductsAndCategories {
  products_rg {
    product_category_id
    product_category {
      name
    }
    name
    fats
    carbohydrates
    calories
    proteins
    units
    id
  }
}
    `;

/**
 * __useGetAllProductsAndCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllProductsAndCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsAndCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsAndCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductsAndCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetAllProductsAndCategoriesQuery, IGetAllProductsAndCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetAllProductsAndCategoriesQuery, IGetAllProductsAndCategoriesQueryVariables>(GetAllProductsAndCategoriesDocument, baseOptions);
      }
export function useGetAllProductsAndCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetAllProductsAndCategoriesQuery, IGetAllProductsAndCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetAllProductsAndCategoriesQuery, IGetAllProductsAndCategoriesQueryVariables>(GetAllProductsAndCategoriesDocument, baseOptions);
        }
export type GetAllProductsAndCategoriesQueryHookResult = ReturnType<typeof useGetAllProductsAndCategoriesQuery>;
export type GetAllProductsAndCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllProductsAndCategoriesLazyQuery>;
export type GetAllProductsAndCategoriesQueryResult = ApolloReactCommon.QueryResult<IGetAllProductsAndCategoriesQuery, IGetAllProductsAndCategoriesQueryVariables>;
export function refetchGetAllProductsAndCategoriesQuery(variables?: IGetAllProductsAndCategoriesQueryVariables) {
      return { query: GetAllProductsAndCategoriesDocument, variables: variables }
    }
export const GetProductsListByDocument = gql`
    query GetProductsListBy($limit: Int = 20, $_like: String) {
  products_rg(limit: $limit, where: {name: {_ilike: $_like}}) {
    carbohydrates
    calories
    proteins
    unit
    name
    id
    fats
    units
  }
}
    `;

/**
 * __useGetProductsListByQuery__
 *
 * To run a query within a React component, call `useGetProductsListByQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsListByQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsListByQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      _like: // value for '_like'
 *   },
 * });
 */
export function useGetProductsListByQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetProductsListByQuery, IGetProductsListByQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetProductsListByQuery, IGetProductsListByQueryVariables>(GetProductsListByDocument, baseOptions);
      }
export function useGetProductsListByLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetProductsListByQuery, IGetProductsListByQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetProductsListByQuery, IGetProductsListByQueryVariables>(GetProductsListByDocument, baseOptions);
        }
export type GetProductsListByQueryHookResult = ReturnType<typeof useGetProductsListByQuery>;
export type GetProductsListByLazyQueryHookResult = ReturnType<typeof useGetProductsListByLazyQuery>;
export type GetProductsListByQueryResult = ApolloReactCommon.QueryResult<IGetProductsListByQuery, IGetProductsListByQueryVariables>;
export function refetchGetProductsListByQuery(variables?: IGetProductsListByQueryVariables) {
      return { query: GetProductsListByDocument, variables: variables }
    }
export const GetProgramPlanParticipantsDocument = gql`
    query GetProgramPlanParticipants($programPlanId: bigint) {
  program_plan_participators(where: {program_plan_id: {_eq: $programPlanId}}) {
    created_at
    user {
      avatar_url
      created_at
      date_of_birth
      email
      first_name
      gender
      id
      is_blocked
      last_name
      locked_at
      middle_name
      name
      username
    }
    program_plan_id
  }
}
    `;

/**
 * __useGetProgramPlanParticipantsQuery__
 *
 * To run a query within a React component, call `useGetProgramPlanParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramPlanParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramPlanParticipantsQuery({
 *   variables: {
 *      programPlanId: // value for 'programPlanId'
 *   },
 * });
 */
export function useGetProgramPlanParticipantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetProgramPlanParticipantsQuery, IGetProgramPlanParticipantsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetProgramPlanParticipantsQuery, IGetProgramPlanParticipantsQueryVariables>(GetProgramPlanParticipantsDocument, baseOptions);
      }
export function useGetProgramPlanParticipantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetProgramPlanParticipantsQuery, IGetProgramPlanParticipantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetProgramPlanParticipantsQuery, IGetProgramPlanParticipantsQueryVariables>(GetProgramPlanParticipantsDocument, baseOptions);
        }
export type GetProgramPlanParticipantsQueryHookResult = ReturnType<typeof useGetProgramPlanParticipantsQuery>;
export type GetProgramPlanParticipantsLazyQueryHookResult = ReturnType<typeof useGetProgramPlanParticipantsLazyQuery>;
export type GetProgramPlanParticipantsQueryResult = ApolloReactCommon.QueryResult<IGetProgramPlanParticipantsQuery, IGetProgramPlanParticipantsQueryVariables>;
export function refetchGetProgramPlanParticipantsQuery(variables?: IGetProgramPlanParticipantsQueryVariables) {
      return { query: GetProgramPlanParticipantsDocument, variables: variables }
    }
export const GetProgramPlansBetweenDatesDocument = gql`
    query GetProgramPlansBetweenDates($_gte: timestamp, $_lt: timestamp = "") {
  program_plans(where: {starts_at: {_gte: $_gte, _lt: $_lt}}) {
    id
    program_id
    starts_at
    updated_at
    program {
      duration_unit
      duration_amount
      name
    }
  }
}
    `;

/**
 * __useGetProgramPlansBetweenDatesQuery__
 *
 * To run a query within a React component, call `useGetProgramPlansBetweenDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramPlansBetweenDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramPlansBetweenDatesQuery({
 *   variables: {
 *      _gte: // value for '_gte'
 *      _lt: // value for '_lt'
 *   },
 * });
 */
export function useGetProgramPlansBetweenDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetProgramPlansBetweenDatesQuery, IGetProgramPlansBetweenDatesQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetProgramPlansBetweenDatesQuery, IGetProgramPlansBetweenDatesQueryVariables>(GetProgramPlansBetweenDatesDocument, baseOptions);
      }
export function useGetProgramPlansBetweenDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetProgramPlansBetweenDatesQuery, IGetProgramPlansBetweenDatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetProgramPlansBetweenDatesQuery, IGetProgramPlansBetweenDatesQueryVariables>(GetProgramPlansBetweenDatesDocument, baseOptions);
        }
export type GetProgramPlansBetweenDatesQueryHookResult = ReturnType<typeof useGetProgramPlansBetweenDatesQuery>;
export type GetProgramPlansBetweenDatesLazyQueryHookResult = ReturnType<typeof useGetProgramPlansBetweenDatesLazyQuery>;
export type GetProgramPlansBetweenDatesQueryResult = ApolloReactCommon.QueryResult<IGetProgramPlansBetweenDatesQuery, IGetProgramPlansBetweenDatesQueryVariables>;
export function refetchGetProgramPlansBetweenDatesQuery(variables?: IGetProgramPlansBetweenDatesQueryVariables) {
      return { query: GetProgramPlansBetweenDatesDocument, variables: variables }
    }
export const GetProgramPlanByIdDocument = gql`
    query GetProgramPlanById($programPlanId: bigint!) {
  program_plans_by_pk(id: $programPlanId) {
    id
    program_id
    starts_at
    updated_at
    program {
      duration_unit
      duration_amount
      name
    }
  }
}
    `;

/**
 * __useGetProgramPlanByIdQuery__
 *
 * To run a query within a React component, call `useGetProgramPlanByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramPlanByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramPlanByIdQuery({
 *   variables: {
 *      programPlanId: // value for 'programPlanId'
 *   },
 * });
 */
export function useGetProgramPlanByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetProgramPlanByIdQuery, IGetProgramPlanByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetProgramPlanByIdQuery, IGetProgramPlanByIdQueryVariables>(GetProgramPlanByIdDocument, baseOptions);
      }
export function useGetProgramPlanByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetProgramPlanByIdQuery, IGetProgramPlanByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetProgramPlanByIdQuery, IGetProgramPlanByIdQueryVariables>(GetProgramPlanByIdDocument, baseOptions);
        }
export type GetProgramPlanByIdQueryHookResult = ReturnType<typeof useGetProgramPlanByIdQuery>;
export type GetProgramPlanByIdLazyQueryHookResult = ReturnType<typeof useGetProgramPlanByIdLazyQuery>;
export type GetProgramPlanByIdQueryResult = ApolloReactCommon.QueryResult<IGetProgramPlanByIdQuery, IGetProgramPlanByIdQueryVariables>;
export function refetchGetProgramPlanByIdQuery(variables?: IGetProgramPlanByIdQueryVariables) {
      return { query: GetProgramPlanByIdDocument, variables: variables }
    }
export const GetProgramPlansDatesRangeDocument = gql`
    query GetProgramPlansDatesRange {
  program_plans_aggregate {
    aggregate {
      min {
        starts_at
      }
      max {
        starts_at
      }
    }
  }
}
    `;

/**
 * __useGetProgramPlansDatesRangeQuery__
 *
 * To run a query within a React component, call `useGetProgramPlansDatesRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramPlansDatesRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramPlansDatesRangeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProgramPlansDatesRangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetProgramPlansDatesRangeQuery, IGetProgramPlansDatesRangeQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetProgramPlansDatesRangeQuery, IGetProgramPlansDatesRangeQueryVariables>(GetProgramPlansDatesRangeDocument, baseOptions);
      }
export function useGetProgramPlansDatesRangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetProgramPlansDatesRangeQuery, IGetProgramPlansDatesRangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetProgramPlansDatesRangeQuery, IGetProgramPlansDatesRangeQueryVariables>(GetProgramPlansDatesRangeDocument, baseOptions);
        }
export type GetProgramPlansDatesRangeQueryHookResult = ReturnType<typeof useGetProgramPlansDatesRangeQuery>;
export type GetProgramPlansDatesRangeLazyQueryHookResult = ReturnType<typeof useGetProgramPlansDatesRangeLazyQuery>;
export type GetProgramPlansDatesRangeQueryResult = ApolloReactCommon.QueryResult<IGetProgramPlansDatesRangeQuery, IGetProgramPlansDatesRangeQueryVariables>;
export function refetchGetProgramPlansDatesRangeQuery(variables?: IGetProgramPlansDatesRangeQueryVariables) {
      return { query: GetProgramPlansDatesRangeDocument, variables: variables }
    }
export const GetUserByIdDocument = gql`
    query GetUserById($userId: bigint!) {
  users_by_pk(id: $userId) {
    avatar_url
    id
    first_name
    last_name
    email
    gender
    biography
    language
    created_at
    user_profile {
      id
      coach_id
      has_injuries
      date_of_birth
      is_vegan
      is_allergic
      is_vegetarian
      initial_height
      initial_weight
      meals_dislike
      meals_like
      rythm
      find_us_on
      how_to_help
      weekdays
      weekend
      training_frequency
      program_info
      training_expectations
      result_after_1month
      result_after_3month
      result_after_6month
      diet_summary {
        id
        activity_level
        fat_range
        kcal_deficiency
        personal_goal
        protein_range
        user_profile_id
      }
      current_coach {
        first_name
      }
    }
    progress_weeks(order_by: {starts_on: asc}) {
      id
      starts_on
      ends_on
      goal_weight
      start_weight
      week_number
      progress_days(order_by: {date: asc}) {
        id
        date
        front_image
        side_image
        back_image
        body_weight
        thigh
        waist
        hips
        chest
        updated_at
      }
    }
    workout_plans {
      day_count
      ends_on
      id
      name
      starts_on
    }
    program_plan_participators {
      id
      program_plan {
        id
        starts_at
        program {
          id
          duration_amount
        }
      }
    }
  }
  user_profiles_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetUserByIdQuery, IGetUserByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetUserByIdQuery, IGetUserByIdQueryVariables>(GetUserByIdDocument, baseOptions);
      }
export function useGetUserByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetUserByIdQuery, IGetUserByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetUserByIdQuery, IGetUserByIdQueryVariables>(GetUserByIdDocument, baseOptions);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = ApolloReactCommon.QueryResult<IGetUserByIdQuery, IGetUserByIdQueryVariables>;
export function refetchGetUserByIdQuery(variables?: IGetUserByIdQueryVariables) {
      return { query: GetUserByIdDocument, variables: variables }
    }
export const GetWorkoutPlansDocument = gql`
    query GetWorkoutPlans {
  workout_plans {
    id
    day_count
    ends_on
    name
    starts_on
    user {
      email
    }
  }
}
    `;

/**
 * __useGetWorkoutPlansQuery__
 *
 * To run a query within a React component, call `useGetWorkoutPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkoutPlansQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetWorkoutPlansQuery, IGetWorkoutPlansQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetWorkoutPlansQuery, IGetWorkoutPlansQueryVariables>(GetWorkoutPlansDocument, baseOptions);
      }
export function useGetWorkoutPlansLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetWorkoutPlansQuery, IGetWorkoutPlansQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetWorkoutPlansQuery, IGetWorkoutPlansQueryVariables>(GetWorkoutPlansDocument, baseOptions);
        }
export type GetWorkoutPlansQueryHookResult = ReturnType<typeof useGetWorkoutPlansQuery>;
export type GetWorkoutPlansLazyQueryHookResult = ReturnType<typeof useGetWorkoutPlansLazyQuery>;
export type GetWorkoutPlansQueryResult = ApolloReactCommon.QueryResult<IGetWorkoutPlansQuery, IGetWorkoutPlansQueryVariables>;
export function refetchGetWorkoutPlansQuery(variables?: IGetWorkoutPlansQueryVariables) {
      return { query: GetWorkoutPlansDocument, variables: variables }
    }
export const GetWorkoutPlansByUserIdDocument = gql`
    query GetWorkoutPlansByUserId($userId: bigint!) {
  workout_plans(where: {user_id: {_eq: $userId}}) {
    id
    name
    starts_on
    ends_on
    day_count
    user_id
  }
}
    `;

/**
 * __useGetWorkoutPlansByUserIdQuery__
 *
 * To run a query within a React component, call `useGetWorkoutPlansByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutPlansByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutPlansByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetWorkoutPlansByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetWorkoutPlansByUserIdQuery, IGetWorkoutPlansByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetWorkoutPlansByUserIdQuery, IGetWorkoutPlansByUserIdQueryVariables>(GetWorkoutPlansByUserIdDocument, baseOptions);
      }
export function useGetWorkoutPlansByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetWorkoutPlansByUserIdQuery, IGetWorkoutPlansByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetWorkoutPlansByUserIdQuery, IGetWorkoutPlansByUserIdQueryVariables>(GetWorkoutPlansByUserIdDocument, baseOptions);
        }
export type GetWorkoutPlansByUserIdQueryHookResult = ReturnType<typeof useGetWorkoutPlansByUserIdQuery>;
export type GetWorkoutPlansByUserIdLazyQueryHookResult = ReturnType<typeof useGetWorkoutPlansByUserIdLazyQuery>;
export type GetWorkoutPlansByUserIdQueryResult = ApolloReactCommon.QueryResult<IGetWorkoutPlansByUserIdQuery, IGetWorkoutPlansByUserIdQueryVariables>;
export function refetchGetWorkoutPlansByUserIdQuery(variables?: IGetWorkoutPlansByUserIdQueryVariables) {
      return { query: GetWorkoutPlansByUserIdDocument, variables: variables }
    }
export const GetWorkoutPlanByPkDocument = gql`
    query GetWorkoutPlanByPk($id: bigint!) {
  workout_plans_by_pk(id: $id) {
    id
    name
    starts_on
    ends_on
    day_count
    user_id
    user {
      first_name
      last_name
    }
  }
}
    `;

/**
 * __useGetWorkoutPlanByPkQuery__
 *
 * To run a query within a React component, call `useGetWorkoutPlanByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutPlanByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutPlanByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkoutPlanByPkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetWorkoutPlanByPkQuery, IGetWorkoutPlanByPkQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetWorkoutPlanByPkQuery, IGetWorkoutPlanByPkQueryVariables>(GetWorkoutPlanByPkDocument, baseOptions);
      }
export function useGetWorkoutPlanByPkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetWorkoutPlanByPkQuery, IGetWorkoutPlanByPkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetWorkoutPlanByPkQuery, IGetWorkoutPlanByPkQueryVariables>(GetWorkoutPlanByPkDocument, baseOptions);
        }
export type GetWorkoutPlanByPkQueryHookResult = ReturnType<typeof useGetWorkoutPlanByPkQuery>;
export type GetWorkoutPlanByPkLazyQueryHookResult = ReturnType<typeof useGetWorkoutPlanByPkLazyQuery>;
export type GetWorkoutPlanByPkQueryResult = ApolloReactCommon.QueryResult<IGetWorkoutPlanByPkQuery, IGetWorkoutPlanByPkQueryVariables>;
export function refetchGetWorkoutPlanByPkQuery(variables?: IGetWorkoutPlanByPkQueryVariables) {
      return { query: GetWorkoutPlanByPkDocument, variables: variables }
    }
export const GetWorkoutTemplatesDocument = gql`
    query GetWorkoutTemplates($dayCount: Int!) {
  workout_templates(where: {day_count: {_eq: $dayCount}}) {
    name
    id
    day_count
    exercise_days(order_by: {sequence: asc}) {
      id
      sequence
      workout_template_id
      exercise_groups(order_by: {order: asc}) {
        id
        order
        group_type
        exercises {
          id
          repetition
          rest
          unit
          sets
          exercise_variant {
            id
            name
            exercise_muscle_category_id
            exercise_device_category_id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetWorkoutTemplatesQuery__
 *
 * To run a query within a React component, call `useGetWorkoutTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutTemplatesQuery({
 *   variables: {
 *      dayCount: // value for 'dayCount'
 *   },
 * });
 */
export function useGetWorkoutTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetWorkoutTemplatesQuery, IGetWorkoutTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetWorkoutTemplatesQuery, IGetWorkoutTemplatesQueryVariables>(GetWorkoutTemplatesDocument, baseOptions);
      }
export function useGetWorkoutTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetWorkoutTemplatesQuery, IGetWorkoutTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetWorkoutTemplatesQuery, IGetWorkoutTemplatesQueryVariables>(GetWorkoutTemplatesDocument, baseOptions);
        }
export type GetWorkoutTemplatesQueryHookResult = ReturnType<typeof useGetWorkoutTemplatesQuery>;
export type GetWorkoutTemplatesLazyQueryHookResult = ReturnType<typeof useGetWorkoutTemplatesLazyQuery>;
export type GetWorkoutTemplatesQueryResult = ApolloReactCommon.QueryResult<IGetWorkoutTemplatesQuery, IGetWorkoutTemplatesQueryVariables>;
export function refetchGetWorkoutTemplatesQuery(variables?: IGetWorkoutTemplatesQueryVariables) {
      return { query: GetWorkoutTemplatesDocument, variables: variables }
    }
export const InsertCookbookCategoryDocument = gql`
    mutation InsertCookbookCategory($objects: [cookbook_categories_insert_input!]!) {
  insert_cookbook_categories(objects: $objects) {
    affected_rows
    returning {
      id
    }
  }
}
    `;

/**
 * __useInsertCookbookCategoryMutation__
 *
 * To run a mutation, you first call `useInsertCookbookCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCookbookCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCookbookCategoryMutation, { data, loading, error }] = useInsertCookbookCategoryMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertCookbookCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertCookbookCategoryMutation, IInsertCookbookCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertCookbookCategoryMutation, IInsertCookbookCategoryMutationVariables>(InsertCookbookCategoryDocument, baseOptions);
      }
export type InsertCookbookCategoryMutationHookResult = ReturnType<typeof useInsertCookbookCategoryMutation>;
export type InsertCookbookCategoryMutationResult = ApolloReactCommon.MutationResult<IInsertCookbookCategoryMutation>;
export type InsertCookbookCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertCookbookCategoryMutation, IInsertCookbookCategoryMutationVariables>;
export const InsertCookbookRecipeDocument = gql`
    mutation InsertCookbookRecipe($objects: [cookbook_recipes_insert_input!]!) {
  insert_cookbook_recipes(objects: $objects) {
    affected_rows
    returning {
      id
      title
      description
      photo_urls
      duration
      kcal
    }
  }
}
    `;

/**
 * __useInsertCookbookRecipeMutation__
 *
 * To run a mutation, you first call `useInsertCookbookRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCookbookRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCookbookRecipeMutation, { data, loading, error }] = useInsertCookbookRecipeMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertCookbookRecipeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertCookbookRecipeMutation, IInsertCookbookRecipeMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertCookbookRecipeMutation, IInsertCookbookRecipeMutationVariables>(InsertCookbookRecipeDocument, baseOptions);
      }
export type InsertCookbookRecipeMutationHookResult = ReturnType<typeof useInsertCookbookRecipeMutation>;
export type InsertCookbookRecipeMutationResult = ApolloReactCommon.MutationResult<IInsertCookbookRecipeMutation>;
export type InsertCookbookRecipeMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertCookbookRecipeMutation, IInsertCookbookRecipeMutationVariables>;
export const InsertExerciseDocument = gql`
    mutation InsertExercise($exercise_variant_id: bigint!, $exercise_group_id: bigint!, $repetition: String = "", $rest: Int = 10, $sequence: Int = 10, $sets: String = "", $weight: numeric = "", $created_at: timestamp = "", $updated_at: timestamp = "") {
  insert_exercises_one(
    object: {exercise_variant_id: $exercise_variant_id, exercise_group_id: $exercise_group_id, repetition: $repetition, rest: $rest, sequence: $sequence, sets: $sets, weight: $weight, created_at: $created_at, updated_at: $updated_at}
  ) {
    id
    created_at
    exercise_group_id
    exercise_variant_id
    repetition
    rest
    sequence
    sets
    updated_at
    weight
    exercise_variant {
      created_at
      exercise_device_category_id
      exercise_muscle_category_id
      id
      name
      updated_at
    }
  }
}
    `;

/**
 * __useInsertExerciseMutation__
 *
 * To run a mutation, you first call `useInsertExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertExerciseMutation, { data, loading, error }] = useInsertExerciseMutation({
 *   variables: {
 *      exercise_variant_id: // value for 'exercise_variant_id'
 *      exercise_group_id: // value for 'exercise_group_id'
 *      repetition: // value for 'repetition'
 *      rest: // value for 'rest'
 *      sequence: // value for 'sequence'
 *      sets: // value for 'sets'
 *      weight: // value for 'weight'
 *      created_at: // value for 'created_at'
 *      updated_at: // value for 'updated_at'
 *   },
 * });
 */
export function useInsertExerciseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertExerciseMutation, IInsertExerciseMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertExerciseMutation, IInsertExerciseMutationVariables>(InsertExerciseDocument, baseOptions);
      }
export type InsertExerciseMutationHookResult = ReturnType<typeof useInsertExerciseMutation>;
export type InsertExerciseMutationResult = ApolloReactCommon.MutationResult<IInsertExerciseMutation>;
export type InsertExerciseMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertExerciseMutation, IInsertExerciseMutationVariables>;
export const InsertExerciseDaysDocument = gql`
    mutation InsertExerciseDays($objects: [exercise_days_insert_input!]!) {
  insert_exercise_days(objects: $objects) {
    affected_rows
    returning {
      id
    }
  }
}
    `;

/**
 * __useInsertExerciseDaysMutation__
 *
 * To run a mutation, you first call `useInsertExerciseDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertExerciseDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertExerciseDaysMutation, { data, loading, error }] = useInsertExerciseDaysMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertExerciseDaysMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertExerciseDaysMutation, IInsertExerciseDaysMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertExerciseDaysMutation, IInsertExerciseDaysMutationVariables>(InsertExerciseDaysDocument, baseOptions);
      }
export type InsertExerciseDaysMutationHookResult = ReturnType<typeof useInsertExerciseDaysMutation>;
export type InsertExerciseDaysMutationResult = ApolloReactCommon.MutationResult<IInsertExerciseDaysMutation>;
export type InsertExerciseDaysMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertExerciseDaysMutation, IInsertExerciseDaysMutationVariables>;
export const InsertExerciseGroupDocument = gql`
    mutation InsertExerciseGroup($groupType: String!, $exerciseDayId: bigint!, $order: Int!) {
  insert_exercise_groups_one(
    object: {group_type: $groupType, exercise_day_id: $exerciseDayId, order: $order}
  ) {
    id
  }
}
    `;

/**
 * __useInsertExerciseGroupMutation__
 *
 * To run a mutation, you first call `useInsertExerciseGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertExerciseGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertExerciseGroupMutation, { data, loading, error }] = useInsertExerciseGroupMutation({
 *   variables: {
 *      groupType: // value for 'groupType'
 *      exerciseDayId: // value for 'exerciseDayId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInsertExerciseGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertExerciseGroupMutation, IInsertExerciseGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertExerciseGroupMutation, IInsertExerciseGroupMutationVariables>(InsertExerciseGroupDocument, baseOptions);
      }
export type InsertExerciseGroupMutationHookResult = ReturnType<typeof useInsertExerciseGroupMutation>;
export type InsertExerciseGroupMutationResult = ApolloReactCommon.MutationResult<IInsertExerciseGroupMutation>;
export type InsertExerciseGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertExerciseGroupMutation, IInsertExerciseGroupMutationVariables>;
export const InsertNewMealDocument = gql`
    mutation InsertNewMeal($name: String, $nutrition_plan_meals_id: Int, $order: Int) {
  insert_nutrition_meals_one(
    object: {nutrition_plan_meals_id: $nutrition_plan_meals_id, name: $name, order: $order}
  ) {
    id
    name
  }
}
    `;

/**
 * __useInsertNewMealMutation__
 *
 * To run a mutation, you first call `useInsertNewMealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNewMealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNewMealMutation, { data, loading, error }] = useInsertNewMealMutation({
 *   variables: {
 *      name: // value for 'name'
 *      nutrition_plan_meals_id: // value for 'nutrition_plan_meals_id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInsertNewMealMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertNewMealMutation, IInsertNewMealMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertNewMealMutation, IInsertNewMealMutationVariables>(InsertNewMealDocument, baseOptions);
      }
export type InsertNewMealMutationHookResult = ReturnType<typeof useInsertNewMealMutation>;
export type InsertNewMealMutationResult = ApolloReactCommon.MutationResult<IInsertNewMealMutation>;
export type InsertNewMealMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertNewMealMutation, IInsertNewMealMutationVariables>;
export const InsertNewProductDocument = gql`
    mutation InsertNewProduct($nutrition_meal_id: Int, $order: Int, $product_id: Int, $quantity: Int) {
  insert_nutrition_meal_products_one(
    object: {quantity: $quantity, product_id: $product_id, nutrition_meal_id: $nutrition_meal_id, order: $order}
  ) {
    id
  }
}
    `;

/**
 * __useInsertNewProductMutation__
 *
 * To run a mutation, you first call `useInsertNewProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNewProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNewProductMutation, { data, loading, error }] = useInsertNewProductMutation({
 *   variables: {
 *      nutrition_meal_id: // value for 'nutrition_meal_id'
 *      order: // value for 'order'
 *      product_id: // value for 'product_id'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useInsertNewProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertNewProductMutation, IInsertNewProductMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertNewProductMutation, IInsertNewProductMutationVariables>(InsertNewProductDocument, baseOptions);
      }
export type InsertNewProductMutationHookResult = ReturnType<typeof useInsertNewProductMutation>;
export type InsertNewProductMutationResult = ApolloReactCommon.MutationResult<IInsertNewProductMutation>;
export type InsertNewProductMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertNewProductMutation, IInsertNewProductMutationVariables>;
export const InsertNutritionPlanDocument = gql`
    mutation InsertNutritionPlan($objects: [nutrition_plans_insert_input!]!) {
  insert_nutrition_plans(objects: $objects) {
    affected_rows
    returning {
      id
    }
  }
}
    `;

/**
 * __useInsertNutritionPlanMutation__
 *
 * To run a mutation, you first call `useInsertNutritionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNutritionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNutritionPlanMutation, { data, loading, error }] = useInsertNutritionPlanMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertNutritionPlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertNutritionPlanMutation, IInsertNutritionPlanMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertNutritionPlanMutation, IInsertNutritionPlanMutationVariables>(InsertNutritionPlanDocument, baseOptions);
      }
export type InsertNutritionPlanMutationHookResult = ReturnType<typeof useInsertNutritionPlanMutation>;
export type InsertNutritionPlanMutationResult = ApolloReactCommon.MutationResult<IInsertNutritionPlanMutation>;
export type InsertNutritionPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertNutritionPlanMutation, IInsertNutritionPlanMutationVariables>;
export const InsertOrUpdateDietSummaryDocument = gql`
    mutation InsertOrUpdateDietSummary($dateOfBirth: date!, $initialWeight: numeric!, $initialHeight: numeric!, $activityLevel: numeric!, $personalGoal: Int!, $proteinRange: numeric!, $fatRange: numeric, $kcalDeficiency: Int, $userProfileId: bigint!) {
  update_user_profiles_by_pk(
    pk_columns: {id: $userProfileId}
    _set: {date_of_birth: $dateOfBirth, initial_height: $initialHeight, initial_weight: $initialWeight}
  ) {
    id
  }
  insert_diet_summaries_one(
    object: {activity_level: $activityLevel, personal_goal: $personalGoal, protein_range: $proteinRange, fat_range: $fatRange, kcal_deficiency: $kcalDeficiency, user_profile_id: $userProfileId}
    on_conflict: {update_columns: [activity_level, personal_goal, protein_range, fat_range, kcal_deficiency], constraint: diet_summary_user_profile_id_key}
  ) {
    id
  }
}
    `;

/**
 * __useInsertOrUpdateDietSummaryMutation__
 *
 * To run a mutation, you first call `useInsertOrUpdateDietSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOrUpdateDietSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOrUpdateDietSummaryMutation, { data, loading, error }] = useInsertOrUpdateDietSummaryMutation({
 *   variables: {
 *      dateOfBirth: // value for 'dateOfBirth'
 *      initialWeight: // value for 'initialWeight'
 *      initialHeight: // value for 'initialHeight'
 *      activityLevel: // value for 'activityLevel'
 *      personalGoal: // value for 'personalGoal'
 *      proteinRange: // value for 'proteinRange'
 *      fatRange: // value for 'fatRange'
 *      kcalDeficiency: // value for 'kcalDeficiency'
 *      userProfileId: // value for 'userProfileId'
 *   },
 * });
 */
export function useInsertOrUpdateDietSummaryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertOrUpdateDietSummaryMutation, IInsertOrUpdateDietSummaryMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertOrUpdateDietSummaryMutation, IInsertOrUpdateDietSummaryMutationVariables>(InsertOrUpdateDietSummaryDocument, baseOptions);
      }
export type InsertOrUpdateDietSummaryMutationHookResult = ReturnType<typeof useInsertOrUpdateDietSummaryMutation>;
export type InsertOrUpdateDietSummaryMutationResult = ApolloReactCommon.MutationResult<IInsertOrUpdateDietSummaryMutation>;
export type InsertOrUpdateDietSummaryMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertOrUpdateDietSummaryMutation, IInsertOrUpdateDietSummaryMutationVariables>;
export const InsertUserProfilesDocument = gql`
    mutation InsertUserProfiles($objects: [user_profiles_insert_input!]!) {
  insert_user_profiles(objects: $objects) {
    affected_rows
    returning {
      id
    }
  }
}
    `;

/**
 * __useInsertUserProfilesMutation__
 *
 * To run a mutation, you first call `useInsertUserProfilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserProfilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserProfilesMutation, { data, loading, error }] = useInsertUserProfilesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertUserProfilesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertUserProfilesMutation, IInsertUserProfilesMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertUserProfilesMutation, IInsertUserProfilesMutationVariables>(InsertUserProfilesDocument, baseOptions);
      }
export type InsertUserProfilesMutationHookResult = ReturnType<typeof useInsertUserProfilesMutation>;
export type InsertUserProfilesMutationResult = ApolloReactCommon.MutationResult<IInsertUserProfilesMutation>;
export type InsertUserProfilesMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertUserProfilesMutation, IInsertUserProfilesMutationVariables>;
export const InsertWorkoutPlanDocument = gql`
    mutation InsertWorkoutPlan($objects: [workout_plans_insert_input!]!) {
  insert_workout_plans(objects: $objects) {
    affected_rows
    returning {
      id
    }
  }
}
    `;

/**
 * __useInsertWorkoutPlanMutation__
 *
 * To run a mutation, you first call `useInsertWorkoutPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertWorkoutPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertWorkoutPlanMutation, { data, loading, error }] = useInsertWorkoutPlanMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertWorkoutPlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertWorkoutPlanMutation, IInsertWorkoutPlanMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertWorkoutPlanMutation, IInsertWorkoutPlanMutationVariables>(InsertWorkoutPlanDocument, baseOptions);
      }
export type InsertWorkoutPlanMutationHookResult = ReturnType<typeof useInsertWorkoutPlanMutation>;
export type InsertWorkoutPlanMutationResult = ApolloReactCommon.MutationResult<IInsertWorkoutPlanMutation>;
export type InsertWorkoutPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertWorkoutPlanMutation, IInsertWorkoutPlanMutationVariables>;
export const InsertWorkoutTemplatesDocument = gql`
    mutation InsertWorkoutTemplates($objects: [workout_templates_insert_input!]!) {
  insert_workout_templates(objects: $objects) {
    affected_rows
    returning {
      id
    }
  }
}
    `;

/**
 * __useInsertWorkoutTemplatesMutation__
 *
 * To run a mutation, you first call `useInsertWorkoutTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertWorkoutTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertWorkoutTemplatesMutation, { data, loading, error }] = useInsertWorkoutTemplatesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertWorkoutTemplatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertWorkoutTemplatesMutation, IInsertWorkoutTemplatesMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertWorkoutTemplatesMutation, IInsertWorkoutTemplatesMutationVariables>(InsertWorkoutTemplatesDocument, baseOptions);
      }
export type InsertWorkoutTemplatesMutationHookResult = ReturnType<typeof useInsertWorkoutTemplatesMutation>;
export type InsertWorkoutTemplatesMutationResult = ApolloReactCommon.MutationResult<IInsertWorkoutTemplatesMutation>;
export type InsertWorkoutTemplatesMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertWorkoutTemplatesMutation, IInsertWorkoutTemplatesMutationVariables>;
export const UpdateCookbookCategoryByPkDocument = gql`
    mutation UpdateCookbookCategoryByPk($id: Int!, $changes: cookbook_categories_set_input!) {
  update_cookbook_categories_by_pk(_set: $changes, pk_columns: {id: $id}) {
    id
    name
    photo_url
  }
}
    `;

/**
 * __useUpdateCookbookCategoryByPkMutation__
 *
 * To run a mutation, you first call `useUpdateCookbookCategoryByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCookbookCategoryByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCookbookCategoryByPkMutation, { data, loading, error }] = useUpdateCookbookCategoryByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCookbookCategoryByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateCookbookCategoryByPkMutation, IUpdateCookbookCategoryByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateCookbookCategoryByPkMutation, IUpdateCookbookCategoryByPkMutationVariables>(UpdateCookbookCategoryByPkDocument, baseOptions);
      }
export type UpdateCookbookCategoryByPkMutationHookResult = ReturnType<typeof useUpdateCookbookCategoryByPkMutation>;
export type UpdateCookbookCategoryByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateCookbookCategoryByPkMutation>;
export type UpdateCookbookCategoryByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateCookbookCategoryByPkMutation, IUpdateCookbookCategoryByPkMutationVariables>;
export const UpdateCookbookRecipeByPkDocument = gql`
    mutation UpdateCookbookRecipeByPk($id: Int!, $changes: cookbook_recipes_set_input!) {
  update_cookbook_recipes_by_pk(_set: $changes, pk_columns: {id: $id}) {
    id
    title
    description
    photo_urls
    duration
    kcal
  }
}
    `;

/**
 * __useUpdateCookbookRecipeByPkMutation__
 *
 * To run a mutation, you first call `useUpdateCookbookRecipeByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCookbookRecipeByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCookbookRecipeByPkMutation, { data, loading, error }] = useUpdateCookbookRecipeByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCookbookRecipeByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateCookbookRecipeByPkMutation, IUpdateCookbookRecipeByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateCookbookRecipeByPkMutation, IUpdateCookbookRecipeByPkMutationVariables>(UpdateCookbookRecipeByPkDocument, baseOptions);
      }
export type UpdateCookbookRecipeByPkMutationHookResult = ReturnType<typeof useUpdateCookbookRecipeByPkMutation>;
export type UpdateCookbookRecipeByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateCookbookRecipeByPkMutation>;
export type UpdateCookbookRecipeByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateCookbookRecipeByPkMutation, IUpdateCookbookRecipeByPkMutationVariables>;
export const UpdateExerciseGroupByPkDocument = gql`
    mutation UpdateExerciseGroupByPk($id: bigint!, $order: Int!) {
  update_exercise_groups_by_pk(pk_columns: {id: $id}, _set: {order: $order}) {
    id
    order
  }
}
    `;

/**
 * __useUpdateExerciseGroupByPkMutation__
 *
 * To run a mutation, you first call `useUpdateExerciseGroupByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExerciseGroupByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExerciseGroupByPkMutation, { data, loading, error }] = useUpdateExerciseGroupByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateExerciseGroupByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateExerciseGroupByPkMutation, IUpdateExerciseGroupByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateExerciseGroupByPkMutation, IUpdateExerciseGroupByPkMutationVariables>(UpdateExerciseGroupByPkDocument, baseOptions);
      }
export type UpdateExerciseGroupByPkMutationHookResult = ReturnType<typeof useUpdateExerciseGroupByPkMutation>;
export type UpdateExerciseGroupByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateExerciseGroupByPkMutation>;
export type UpdateExerciseGroupByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateExerciseGroupByPkMutation, IUpdateExerciseGroupByPkMutationVariables>;
export const UpdateNutritionMealByPkDocument = gql`
    mutation UpdateNutritionMealByPk($id: Int!, $name: String!) {
  update_nutrition_meals_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    id
  }
}
    `;

/**
 * __useUpdateNutritionMealByPkMutation__
 *
 * To run a mutation, you first call `useUpdateNutritionMealByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNutritionMealByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNutritionMealByPkMutation, { data, loading, error }] = useUpdateNutritionMealByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateNutritionMealByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateNutritionMealByPkMutation, IUpdateNutritionMealByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateNutritionMealByPkMutation, IUpdateNutritionMealByPkMutationVariables>(UpdateNutritionMealByPkDocument, baseOptions);
      }
export type UpdateNutritionMealByPkMutationHookResult = ReturnType<typeof useUpdateNutritionMealByPkMutation>;
export type UpdateNutritionMealByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateNutritionMealByPkMutation>;
export type UpdateNutritionMealByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateNutritionMealByPkMutation, IUpdateNutritionMealByPkMutationVariables>;
export const UpdateNutritionMealProductByPkDocument = gql`
    mutation UpdateNutritionMealProductByPk($id: Int!, $productId: Int!, $quantity: Int!) {
  update_nutrition_meal_products_by_pk(
    pk_columns: {id: $id}
    _set: {product_id: $productId, quantity: $quantity}
  ) {
    id
  }
}
    `;

/**
 * __useUpdateNutritionMealProductByPkMutation__
 *
 * To run a mutation, you first call `useUpdateNutritionMealProductByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNutritionMealProductByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNutritionMealProductByPkMutation, { data, loading, error }] = useUpdateNutritionMealProductByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productId: // value for 'productId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateNutritionMealProductByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateNutritionMealProductByPkMutation, IUpdateNutritionMealProductByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateNutritionMealProductByPkMutation, IUpdateNutritionMealProductByPkMutationVariables>(UpdateNutritionMealProductByPkDocument, baseOptions);
      }
export type UpdateNutritionMealProductByPkMutationHookResult = ReturnType<typeof useUpdateNutritionMealProductByPkMutation>;
export type UpdateNutritionMealProductByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateNutritionMealProductByPkMutation>;
export type UpdateNutritionMealProductByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateNutritionMealProductByPkMutation, IUpdateNutritionMealProductByPkMutationVariables>;
export const UpdateNutritionPlanByPkDocument = gql`
    mutation UpdateNutritionPlanByPk($id: Int!, $input: nutrition_plans_set_input!) {
  update_nutrition_plans_by_pk(pk_columns: {id: $id}, _set: $input) {
    id
  }
}
    `;

/**
 * __useUpdateNutritionPlanByPkMutation__
 *
 * To run a mutation, you first call `useUpdateNutritionPlanByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNutritionPlanByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNutritionPlanByPkMutation, { data, loading, error }] = useUpdateNutritionPlanByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNutritionPlanByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateNutritionPlanByPkMutation, IUpdateNutritionPlanByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateNutritionPlanByPkMutation, IUpdateNutritionPlanByPkMutationVariables>(UpdateNutritionPlanByPkDocument, baseOptions);
      }
export type UpdateNutritionPlanByPkMutationHookResult = ReturnType<typeof useUpdateNutritionPlanByPkMutation>;
export type UpdateNutritionPlanByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateNutritionPlanByPkMutation>;
export type UpdateNutritionPlanByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateNutritionPlanByPkMutation, IUpdateNutritionPlanByPkMutationVariables>;
export const UpdateOrderForMealProductDocument = gql`
    mutation UpdateOrderForMealProduct($nutritionMealProductId: Int!, $newOrder: Int!) {
  update_nutrition_meal_products_by_pk(
    pk_columns: {id: $nutritionMealProductId}
    _set: {order: $newOrder}
  ) {
    updated_at
  }
}
    `;

/**
 * __useUpdateOrderForMealProductMutation__
 *
 * To run a mutation, you first call `useUpdateOrderForMealProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderForMealProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderForMealProductMutation, { data, loading, error }] = useUpdateOrderForMealProductMutation({
 *   variables: {
 *      nutritionMealProductId: // value for 'nutritionMealProductId'
 *      newOrder: // value for 'newOrder'
 *   },
 * });
 */
export function useUpdateOrderForMealProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateOrderForMealProductMutation, IUpdateOrderForMealProductMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateOrderForMealProductMutation, IUpdateOrderForMealProductMutationVariables>(UpdateOrderForMealProductDocument, baseOptions);
      }
export type UpdateOrderForMealProductMutationHookResult = ReturnType<typeof useUpdateOrderForMealProductMutation>;
export type UpdateOrderForMealProductMutationResult = ApolloReactCommon.MutationResult<IUpdateOrderForMealProductMutation>;
export type UpdateOrderForMealProductMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateOrderForMealProductMutation, IUpdateOrderForMealProductMutationVariables>;
export const UpdateUserByPkDocument = gql`
    mutation UpdateUserByPk($id: bigint, $changes: users_set_input!) {
  update_users(_set: $changes, where: {id: {_eq: $id}}) {
    returning {
      id
    }
    affected_rows
  }
}
    `;

/**
 * __useUpdateUserByPkMutation__
 *
 * To run a mutation, you first call `useUpdateUserByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByPkMutation, { data, loading, error }] = useUpdateUserByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateUserByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateUserByPkMutation, IUpdateUserByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateUserByPkMutation, IUpdateUserByPkMutationVariables>(UpdateUserByPkDocument, baseOptions);
      }
export type UpdateUserByPkMutationHookResult = ReturnType<typeof useUpdateUserByPkMutation>;
export type UpdateUserByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateUserByPkMutation>;
export type UpdateUserByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateUserByPkMutation, IUpdateUserByPkMutationVariables>;
export const UpdateUserProfileByPkDocument = gql`
    mutation UpdateUserProfileByPk($id: bigint, $changes: user_profiles_set_input!) {
  update_user_profiles(_set: $changes, where: {id: {_eq: $id}}) {
    returning {
      id
    }
    affected_rows
  }
}
    `;

/**
 * __useUpdateUserProfileByPkMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileByPkMutation, { data, loading, error }] = useUpdateUserProfileByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateUserProfileByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateUserProfileByPkMutation, IUpdateUserProfileByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateUserProfileByPkMutation, IUpdateUserProfileByPkMutationVariables>(UpdateUserProfileByPkDocument, baseOptions);
      }
export type UpdateUserProfileByPkMutationHookResult = ReturnType<typeof useUpdateUserProfileByPkMutation>;
export type UpdateUserProfileByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateUserProfileByPkMutation>;
export type UpdateUserProfileByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateUserProfileByPkMutation, IUpdateUserProfileByPkMutationVariables>;
export const UpdateWorkoutPlanByPkDocument = gql`
    mutation UpdateWorkoutPlanByPk($id: bigint!, $ends_on: date = "", $name: String = "", $starts_on: date = "", $user_id: bigint) {
  update_workout_plans_by_pk(
    pk_columns: {id: $id}
    _set: {ends_on: $ends_on, name: $name, starts_on: $starts_on, user_id: $user_id}
  ) {
    id
  }
}
    `;

/**
 * __useUpdateWorkoutPlanByPkMutation__
 *
 * To run a mutation, you first call `useUpdateWorkoutPlanByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkoutPlanByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkoutPlanByPkMutation, { data, loading, error }] = useUpdateWorkoutPlanByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ends_on: // value for 'ends_on'
 *      name: // value for 'name'
 *      starts_on: // value for 'starts_on'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUpdateWorkoutPlanByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateWorkoutPlanByPkMutation, IUpdateWorkoutPlanByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateWorkoutPlanByPkMutation, IUpdateWorkoutPlanByPkMutationVariables>(UpdateWorkoutPlanByPkDocument, baseOptions);
      }
export type UpdateWorkoutPlanByPkMutationHookResult = ReturnType<typeof useUpdateWorkoutPlanByPkMutation>;
export type UpdateWorkoutPlanByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateWorkoutPlanByPkMutation>;
export type UpdateWorkoutPlanByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateWorkoutPlanByPkMutation, IUpdateWorkoutPlanByPkMutationVariables>;
export const UpsertExercisesDocument = gql`
    mutation UpsertExercises($objects: [exercises_insert_input!]! = {id: "", exercise_variant_id: "", exercise_group_id: "", rest: 0, repetition: "", sets: "", unit: ""}) {
  insert_exercises(
    objects: $objects
    on_conflict: {constraint: exercises_pkey, update_columns: [exercise_group_id, exercise_variant_id, rest, repetition, sets, unit]}
  ) {
    affected_rows
    returning {
      id
      exercise_group_id
      sequence
    }
  }
}
    `;

/**
 * __useUpsertExercisesMutation__
 *
 * To run a mutation, you first call `useUpsertExercisesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertExercisesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertExercisesMutation, { data, loading, error }] = useUpsertExercisesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertExercisesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpsertExercisesMutation, IUpsertExercisesMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpsertExercisesMutation, IUpsertExercisesMutationVariables>(UpsertExercisesDocument, baseOptions);
      }
export type UpsertExercisesMutationHookResult = ReturnType<typeof useUpsertExercisesMutation>;
export type UpsertExercisesMutationResult = ApolloReactCommon.MutationResult<IUpsertExercisesMutation>;
export type UpsertExercisesMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpsertExercisesMutation, IUpsertExercisesMutationVariables>;
export const DeleteBlogArticleByPkDocument = gql`
    mutation deleteBlogArticleByPk($id: Int!) {
  delete_blog_articles_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteBlogArticleByPkMutation__
 *
 * To run a mutation, you first call `useDeleteBlogArticleByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlogArticleByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlogArticleByPkMutation, { data, loading, error }] = useDeleteBlogArticleByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlogArticleByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteBlogArticleByPkMutation, IDeleteBlogArticleByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteBlogArticleByPkMutation, IDeleteBlogArticleByPkMutationVariables>(DeleteBlogArticleByPkDocument, baseOptions);
      }
export type DeleteBlogArticleByPkMutationHookResult = ReturnType<typeof useDeleteBlogArticleByPkMutation>;
export type DeleteBlogArticleByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteBlogArticleByPkMutation>;
export type DeleteBlogArticleByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteBlogArticleByPkMutation, IDeleteBlogArticleByPkMutationVariables>;
export const GetBlogCategoryByPkDocument = gql`
    query getBlogCategoryByPk($id: Int!) {
  blog_categories_by_pk(id: $id) {
    id
    name
    blog_articles(order_by: {id: asc}) {
      id
      title
      text
      tag
      photo_url
    }
  }
}
    `;

/**
 * __useGetBlogCategoryByPkQuery__
 *
 * To run a query within a React component, call `useGetBlogCategoryByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogCategoryByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogCategoryByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBlogCategoryByPkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetBlogCategoryByPkQuery, IGetBlogCategoryByPkQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetBlogCategoryByPkQuery, IGetBlogCategoryByPkQueryVariables>(GetBlogCategoryByPkDocument, baseOptions);
      }
export function useGetBlogCategoryByPkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetBlogCategoryByPkQuery, IGetBlogCategoryByPkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetBlogCategoryByPkQuery, IGetBlogCategoryByPkQueryVariables>(GetBlogCategoryByPkDocument, baseOptions);
        }
export type GetBlogCategoryByPkQueryHookResult = ReturnType<typeof useGetBlogCategoryByPkQuery>;
export type GetBlogCategoryByPkLazyQueryHookResult = ReturnType<typeof useGetBlogCategoryByPkLazyQuery>;
export type GetBlogCategoryByPkQueryResult = ApolloReactCommon.QueryResult<IGetBlogCategoryByPkQuery, IGetBlogCategoryByPkQueryVariables>;
export function refetchGetBlogCategoryByPkQuery(variables?: IGetBlogCategoryByPkQueryVariables) {
      return { query: GetBlogCategoryByPkDocument, variables: variables }
    }
export const InsertBlogArticleDocument = gql`
    mutation insertBlogArticle($objects: [blog_articles_insert_input!]!) {
  insert_blog_articles(objects: $objects) {
    affected_rows
  }
}
    `;

/**
 * __useInsertBlogArticleMutation__
 *
 * To run a mutation, you first call `useInsertBlogArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertBlogArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertBlogArticleMutation, { data, loading, error }] = useInsertBlogArticleMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertBlogArticleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertBlogArticleMutation, IInsertBlogArticleMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertBlogArticleMutation, IInsertBlogArticleMutationVariables>(InsertBlogArticleDocument, baseOptions);
      }
export type InsertBlogArticleMutationHookResult = ReturnType<typeof useInsertBlogArticleMutation>;
export type InsertBlogArticleMutationResult = ApolloReactCommon.MutationResult<IInsertBlogArticleMutation>;
export type InsertBlogArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertBlogArticleMutation, IInsertBlogArticleMutationVariables>;
export const UpdateBlogArticleByPkDocument = gql`
    mutation updateBlogArticleByPk($id: Int!, $changes: blog_articles_set_input!) {
  update_blog_articles_by_pk(_set: $changes, pk_columns: {id: $id}) {
    id
    title
    text
    tag
    created_at
    updated_at
  }
}
    `;

/**
 * __useUpdateBlogArticleByPkMutation__
 *
 * To run a mutation, you first call `useUpdateBlogArticleByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogArticleByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogArticleByPkMutation, { data, loading, error }] = useUpdateBlogArticleByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateBlogArticleByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateBlogArticleByPkMutation, IUpdateBlogArticleByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateBlogArticleByPkMutation, IUpdateBlogArticleByPkMutationVariables>(UpdateBlogArticleByPkDocument, baseOptions);
      }
export type UpdateBlogArticleByPkMutationHookResult = ReturnType<typeof useUpdateBlogArticleByPkMutation>;
export type UpdateBlogArticleByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateBlogArticleByPkMutation>;
export type UpdateBlogArticleByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateBlogArticleByPkMutation, IUpdateBlogArticleByPkMutationVariables>;
export const DeleteBlogCategoryByPkDocument = gql`
    mutation deleteBlogCategoryByPk($id: Int!) {
  delete_blog_categories_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteBlogCategoryByPkMutation__
 *
 * To run a mutation, you first call `useDeleteBlogCategoryByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlogCategoryByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlogCategoryByPkMutation, { data, loading, error }] = useDeleteBlogCategoryByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlogCategoryByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteBlogCategoryByPkMutation, IDeleteBlogCategoryByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteBlogCategoryByPkMutation, IDeleteBlogCategoryByPkMutationVariables>(DeleteBlogCategoryByPkDocument, baseOptions);
      }
export type DeleteBlogCategoryByPkMutationHookResult = ReturnType<typeof useDeleteBlogCategoryByPkMutation>;
export type DeleteBlogCategoryByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteBlogCategoryByPkMutation>;
export type DeleteBlogCategoryByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteBlogCategoryByPkMutation, IDeleteBlogCategoryByPkMutationVariables>;
export const GetBlogCategoriesDocument = gql`
    query getBlogCategories {
  blog_categories_aggregate {
    nodes {
      id
      name
      blog_articles_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useGetBlogCategoriesQuery__
 *
 * To run a query within a React component, call `useGetBlogCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBlogCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetBlogCategoriesQuery, IGetBlogCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetBlogCategoriesQuery, IGetBlogCategoriesQueryVariables>(GetBlogCategoriesDocument, baseOptions);
      }
export function useGetBlogCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetBlogCategoriesQuery, IGetBlogCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetBlogCategoriesQuery, IGetBlogCategoriesQueryVariables>(GetBlogCategoriesDocument, baseOptions);
        }
export type GetBlogCategoriesQueryHookResult = ReturnType<typeof useGetBlogCategoriesQuery>;
export type GetBlogCategoriesLazyQueryHookResult = ReturnType<typeof useGetBlogCategoriesLazyQuery>;
export type GetBlogCategoriesQueryResult = ApolloReactCommon.QueryResult<IGetBlogCategoriesQuery, IGetBlogCategoriesQueryVariables>;
export function refetchGetBlogCategoriesQuery(variables?: IGetBlogCategoriesQueryVariables) {
      return { query: GetBlogCategoriesDocument, variables: variables }
    }
export const InsertBlogCategoryDocument = gql`
    mutation insertBlogCategory($objects: [blog_categories_insert_input!]!) {
  insert_blog_categories(objects: $objects) {
    affected_rows
  }
}
    `;

/**
 * __useInsertBlogCategoryMutation__
 *
 * To run a mutation, you first call `useInsertBlogCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertBlogCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertBlogCategoryMutation, { data, loading, error }] = useInsertBlogCategoryMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertBlogCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertBlogCategoryMutation, IInsertBlogCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertBlogCategoryMutation, IInsertBlogCategoryMutationVariables>(InsertBlogCategoryDocument, baseOptions);
      }
export type InsertBlogCategoryMutationHookResult = ReturnType<typeof useInsertBlogCategoryMutation>;
export type InsertBlogCategoryMutationResult = ApolloReactCommon.MutationResult<IInsertBlogCategoryMutation>;
export type InsertBlogCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertBlogCategoryMutation, IInsertBlogCategoryMutationVariables>;
export const UpdateBlogCategoryByPkDocument = gql`
    mutation updateBlogCategoryByPk($id: Int!, $changes: blog_categories_set_input!) {
  update_blog_categories_by_pk(_set: $changes, pk_columns: {id: $id}) {
    id
    name
  }
}
    `;

/**
 * __useUpdateBlogCategoryByPkMutation__
 *
 * To run a mutation, you first call `useUpdateBlogCategoryByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogCategoryByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogCategoryByPkMutation, { data, loading, error }] = useUpdateBlogCategoryByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateBlogCategoryByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateBlogCategoryByPkMutation, IUpdateBlogCategoryByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateBlogCategoryByPkMutation, IUpdateBlogCategoryByPkMutationVariables>(UpdateBlogCategoryByPkDocument, baseOptions);
      }
export type UpdateBlogCategoryByPkMutationHookResult = ReturnType<typeof useUpdateBlogCategoryByPkMutation>;
export type UpdateBlogCategoryByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateBlogCategoryByPkMutation>;
export type UpdateBlogCategoryByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateBlogCategoryByPkMutation, IUpdateBlogCategoryByPkMutationVariables>;
export const GetUsersLikeDocument = gql`
    query GetUsersLike($_like: String) {
  users(
    where: {_or: [{name: {_ilike: $_like}}, {last_name: {_ilike: $_like}}, {first_name: {_ilike: $_like}}, {email: {_ilike: $_like}}, {middle_name: {_ilike: $_like}}]}
  ) {
    id
    avatar_url
    first_name
    last_name
  }
}
    `;

/**
 * __useGetUsersLikeQuery__
 *
 * To run a query within a React component, call `useGetUsersLikeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersLikeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersLikeQuery({
 *   variables: {
 *      _like: // value for '_like'
 *   },
 * });
 */
export function useGetUsersLikeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetUsersLikeQuery, IGetUsersLikeQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetUsersLikeQuery, IGetUsersLikeQueryVariables>(GetUsersLikeDocument, baseOptions);
      }
export function useGetUsersLikeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetUsersLikeQuery, IGetUsersLikeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetUsersLikeQuery, IGetUsersLikeQueryVariables>(GetUsersLikeDocument, baseOptions);
        }
export type GetUsersLikeQueryHookResult = ReturnType<typeof useGetUsersLikeQuery>;
export type GetUsersLikeLazyQueryHookResult = ReturnType<typeof useGetUsersLikeLazyQuery>;
export type GetUsersLikeQueryResult = ApolloReactCommon.QueryResult<IGetUsersLikeQuery, IGetUsersLikeQueryVariables>;
export function refetchGetUsersLikeQuery(variables?: IGetUsersLikeQueryVariables) {
      return { query: GetUsersLikeDocument, variables: variables }
    }
export const GetNotificationsDocument = gql`
    query getNotifications {
  progress_days(where: {seen_at: {_is_null: true}}) {
    id
    user {
      id
      first_name
      last_name
    }
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetNotificationsQuery, IGetNotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetNotificationsQuery, IGetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
      }
export function useGetNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetNotificationsQuery, IGetNotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetNotificationsQuery, IGetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = ApolloReactCommon.QueryResult<IGetNotificationsQuery, IGetNotificationsQueryVariables>;
export function refetchGetNotificationsQuery(variables?: IGetNotificationsQueryVariables) {
      return { query: GetNotificationsDocument, variables: variables }
    }
export const SetSeenAtDocument = gql`
    mutation setSeenAt($id: bigint!) {
  update_progress_days_by_pk(_set: {seen_at: "now()"}, pk_columns: {id: $id}) {
    id
    user {
      id
      first_name
      last_name
    }
  }
}
    `;

/**
 * __useSetSeenAtMutation__
 *
 * To run a mutation, you first call `useSetSeenAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSeenAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSeenAtMutation, { data, loading, error }] = useSetSeenAtMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetSeenAtMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISetSeenAtMutation, ISetSeenAtMutationVariables>) {
        return ApolloReactHooks.useMutation<ISetSeenAtMutation, ISetSeenAtMutationVariables>(SetSeenAtDocument, baseOptions);
      }
export type SetSeenAtMutationHookResult = ReturnType<typeof useSetSeenAtMutation>;
export type SetSeenAtMutationResult = ApolloReactCommon.MutationResult<ISetSeenAtMutation>;
export type SetSeenAtMutationOptions = ApolloReactCommon.BaseMutationOptions<ISetSeenAtMutation, ISetSeenAtMutationVariables>;
export const GetExercisesProgressDocument = gql`
    query getExercisesProgress($id: bigint!) {
  exercise_progressions(
    order_by: {created_at: desc}
    where: {exercise: {exercise_group: {exercise_day: {workout_plan_id: {_eq: $id}}}}}
  ) {
    id
    sets
    created_at
    exercise {
      id
      exercise_variant {
        id
        name
      }
    }
  }
  workout_plans_by_pk(id: $id) {
    user {
      first_name
      last_name
      id
    }
  }
}
    `;

/**
 * __useGetExercisesProgressQuery__
 *
 * To run a query within a React component, call `useGetExercisesProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExercisesProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExercisesProgressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExercisesProgressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetExercisesProgressQuery, IGetExercisesProgressQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetExercisesProgressQuery, IGetExercisesProgressQueryVariables>(GetExercisesProgressDocument, baseOptions);
      }
export function useGetExercisesProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetExercisesProgressQuery, IGetExercisesProgressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetExercisesProgressQuery, IGetExercisesProgressQueryVariables>(GetExercisesProgressDocument, baseOptions);
        }
export type GetExercisesProgressQueryHookResult = ReturnType<typeof useGetExercisesProgressQuery>;
export type GetExercisesProgressLazyQueryHookResult = ReturnType<typeof useGetExercisesProgressLazyQuery>;
export type GetExercisesProgressQueryResult = ApolloReactCommon.QueryResult<IGetExercisesProgressQuery, IGetExercisesProgressQueryVariables>;
export function refetchGetExercisesProgressQuery(variables?: IGetExercisesProgressQueryVariables) {
      return { query: GetExercisesProgressDocument, variables: variables }
    }
export const GetMessagesByInboxIdDocument = gql`
    subscription getMessagesByInboxId($id: Int!) {
  inbox_messages(where: {inbox_id: {_eq: $id}}, order_by: {created_at: asc}) {
    id
    sender_id
    message
    updated_at
    read
    inbox {
      id
      coach_id
      client_id
    }
  }
}
    `;

/**
 * __useGetMessagesByInboxIdSubscription__
 *
 * To run a query within a React component, call `useGetMessagesByInboxIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesByInboxIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesByInboxIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMessagesByInboxIdSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<IGetMessagesByInboxIdSubscription, IGetMessagesByInboxIdSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<IGetMessagesByInboxIdSubscription, IGetMessagesByInboxIdSubscriptionVariables>(GetMessagesByInboxIdDocument, baseOptions);
      }
export type GetMessagesByInboxIdSubscriptionHookResult = ReturnType<typeof useGetMessagesByInboxIdSubscription>;
export type GetMessagesByInboxIdSubscriptionResult = ApolloReactCommon.SubscriptionResult<IGetMessagesByInboxIdSubscription>;
export const InsertMessageDocument = gql`
    mutation insertMessage($inboxId: Int!, $message: String!, $senderId: Int!) {
  insert_inbox_messages_one(
    object: {inbox_id: $inboxId, message: $message, sender_id: $senderId}
  ) {
    id
    message
    sender_id
  }
}
    `;

/**
 * __useInsertMessageMutation__
 *
 * To run a mutation, you first call `useInsertMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMessageMutation, { data, loading, error }] = useInsertMessageMutation({
 *   variables: {
 *      inboxId: // value for 'inboxId'
 *      message: // value for 'message'
 *      senderId: // value for 'senderId'
 *   },
 * });
 */
export function useInsertMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertMessageMutation, IInsertMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertMessageMutation, IInsertMessageMutationVariables>(InsertMessageDocument, baseOptions);
      }
export type InsertMessageMutationHookResult = ReturnType<typeof useInsertMessageMutation>;
export type InsertMessageMutationResult = ApolloReactCommon.MutationResult<IInsertMessageMutation>;
export type InsertMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertMessageMutation, IInsertMessageMutationVariables>;
export const MarkAsReadDocument = gql`
    mutation markAsRead($ids: [Int!]) {
  update_inbox_messages(where: {id: {_in: $ids}}, _set: {read: true}) {
    affected_rows
  }
}
    `;

/**
 * __useMarkAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMutation, { data, loading, error }] = useMarkAsReadMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkAsReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IMarkAsReadMutation, IMarkAsReadMutationVariables>) {
        return ApolloReactHooks.useMutation<IMarkAsReadMutation, IMarkAsReadMutationVariables>(MarkAsReadDocument, baseOptions);
      }
export type MarkAsReadMutationHookResult = ReturnType<typeof useMarkAsReadMutation>;
export type MarkAsReadMutationResult = ApolloReactCommon.MutationResult<IMarkAsReadMutation>;
export type MarkAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<IMarkAsReadMutation, IMarkAsReadMutationVariables>;
export const GetCoachMembersDocument = gql`
    query getCoachMembers($ilike: String!, $coachId: bigint!) {
  user_coach_view(
    where: {_or: [{client_first_name: {_ilike: $ilike}}, {client_last_name: {_ilike: $ilike}}], _and: {coach_id: {_eq: $coachId}}}
  ) {
    client_last_name
    client_id
    client_first_name
    coach_id
  }
}
    `;

/**
 * __useGetCoachMembersQuery__
 *
 * To run a query within a React component, call `useGetCoachMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachMembersQuery({
 *   variables: {
 *      ilike: // value for 'ilike'
 *      coachId: // value for 'coachId'
 *   },
 * });
 */
export function useGetCoachMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetCoachMembersQuery, IGetCoachMembersQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetCoachMembersQuery, IGetCoachMembersQueryVariables>(GetCoachMembersDocument, baseOptions);
      }
export function useGetCoachMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetCoachMembersQuery, IGetCoachMembersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetCoachMembersQuery, IGetCoachMembersQueryVariables>(GetCoachMembersDocument, baseOptions);
        }
export type GetCoachMembersQueryHookResult = ReturnType<typeof useGetCoachMembersQuery>;
export type GetCoachMembersLazyQueryHookResult = ReturnType<typeof useGetCoachMembersLazyQuery>;
export type GetCoachMembersQueryResult = ApolloReactCommon.QueryResult<IGetCoachMembersQuery, IGetCoachMembersQueryVariables>;
export function refetchGetCoachMembersQuery(variables?: IGetCoachMembersQueryVariables) {
      return { query: GetCoachMembersDocument, variables: variables }
    }
export const InsertInboxDocument = gql`
    mutation insertInbox($coachId: Int!, $clientId: Int!) {
  insert_inbox_one(object: {coach_id: $coachId, client_id: $clientId}) {
    id
    client {
      id
      first_name
      last_name
      avatar_url
    }
    coach {
      id
      first_name
    }
    inbox_messages {
      id
      message
      sender_id
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useInsertInboxMutation__
 *
 * To run a mutation, you first call `useInsertInboxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertInboxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertInboxMutation, { data, loading, error }] = useInsertInboxMutation({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useInsertInboxMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertInboxMutation, IInsertInboxMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertInboxMutation, IInsertInboxMutationVariables>(InsertInboxDocument, baseOptions);
      }
export type InsertInboxMutationHookResult = ReturnType<typeof useInsertInboxMutation>;
export type InsertInboxMutationResult = ApolloReactCommon.MutationResult<IInsertInboxMutation>;
export type InsertInboxMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertInboxMutation, IInsertInboxMutationVariables>;
export const GetInboxDocument = gql`
    subscription getInbox {
  inbox(order_by: {inbox_messages_aggregate: {max: {id: desc}}}) {
    id
    client {
      id
      first_name
      last_name
      avatar_url
      program_plan_participators {
        program_plan {
          id
          starts_at
          program {
            duration_amount
          }
        }
      }
    }
    coach {
      id
      first_name
    }
    inbox_messages {
      id
      message
      sender_id
      read
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useGetInboxSubscription__
 *
 * To run a query within a React component, call `useGetInboxSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetInboxSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<IGetInboxSubscription, IGetInboxSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<IGetInboxSubscription, IGetInboxSubscriptionVariables>(GetInboxDocument, baseOptions);
      }
export type GetInboxSubscriptionHookResult = ReturnType<typeof useGetInboxSubscription>;
export type GetInboxSubscriptionResult = ApolloReactCommon.SubscriptionResult<IGetInboxSubscription>;
export const DeleteKnowledgeBaseByPkDocument = gql`
    mutation deleteKnowledgeBaseByPk($id: Int!) {
  delete_knowledge_base_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteKnowledgeBaseByPkMutation__
 *
 * To run a mutation, you first call `useDeleteKnowledgeBaseByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKnowledgeBaseByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKnowledgeBaseByPkMutation, { data, loading, error }] = useDeleteKnowledgeBaseByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKnowledgeBaseByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteKnowledgeBaseByPkMutation, IDeleteKnowledgeBaseByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteKnowledgeBaseByPkMutation, IDeleteKnowledgeBaseByPkMutationVariables>(DeleteKnowledgeBaseByPkDocument, baseOptions);
      }
export type DeleteKnowledgeBaseByPkMutationHookResult = ReturnType<typeof useDeleteKnowledgeBaseByPkMutation>;
export type DeleteKnowledgeBaseByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteKnowledgeBaseByPkMutation>;
export type DeleteKnowledgeBaseByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteKnowledgeBaseByPkMutation, IDeleteKnowledgeBaseByPkMutationVariables>;
export const GetKnowledgeBaseDocument = gql`
    query getKnowledgeBase {
  knowledge_base_aggregate(order_by: {sequence: asc}) {
    nodes {
      id
      description
      sequence
      knowledge_base_videos_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useGetKnowledgeBaseQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeBaseQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKnowledgeBaseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetKnowledgeBaseQuery, IGetKnowledgeBaseQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetKnowledgeBaseQuery, IGetKnowledgeBaseQueryVariables>(GetKnowledgeBaseDocument, baseOptions);
      }
export function useGetKnowledgeBaseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetKnowledgeBaseQuery, IGetKnowledgeBaseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetKnowledgeBaseQuery, IGetKnowledgeBaseQueryVariables>(GetKnowledgeBaseDocument, baseOptions);
        }
export type GetKnowledgeBaseQueryHookResult = ReturnType<typeof useGetKnowledgeBaseQuery>;
export type GetKnowledgeBaseLazyQueryHookResult = ReturnType<typeof useGetKnowledgeBaseLazyQuery>;
export type GetKnowledgeBaseQueryResult = ApolloReactCommon.QueryResult<IGetKnowledgeBaseQuery, IGetKnowledgeBaseQueryVariables>;
export function refetchGetKnowledgeBaseQuery(variables?: IGetKnowledgeBaseQueryVariables) {
      return { query: GetKnowledgeBaseDocument, variables: variables }
    }
export const InsertKnowledgeBaseDocument = gql`
    mutation insertKnowledgeBase($objects: [knowledge_base_insert_input!]!) {
  insert_knowledge_base(objects: $objects) {
    affected_rows
  }
}
    `;

/**
 * __useInsertKnowledgeBaseMutation__
 *
 * To run a mutation, you first call `useInsertKnowledgeBaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertKnowledgeBaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertKnowledgeBaseMutation, { data, loading, error }] = useInsertKnowledgeBaseMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertKnowledgeBaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertKnowledgeBaseMutation, IInsertKnowledgeBaseMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertKnowledgeBaseMutation, IInsertKnowledgeBaseMutationVariables>(InsertKnowledgeBaseDocument, baseOptions);
      }
export type InsertKnowledgeBaseMutationHookResult = ReturnType<typeof useInsertKnowledgeBaseMutation>;
export type InsertKnowledgeBaseMutationResult = ApolloReactCommon.MutationResult<IInsertKnowledgeBaseMutation>;
export type InsertKnowledgeBaseMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertKnowledgeBaseMutation, IInsertKnowledgeBaseMutationVariables>;
export const UpdateKnowledgeBaseByPkDocument = gql`
    mutation updateKnowledgeBaseByPk($id: Int!, $changes: knowledge_base_set_input!) {
  update_knowledge_base_by_pk(_set: $changes, pk_columns: {id: $id}) {
    id
  }
}
    `;

/**
 * __useUpdateKnowledgeBaseByPkMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeBaseByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeBaseByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeBaseByPkMutation, { data, loading, error }] = useUpdateKnowledgeBaseByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateKnowledgeBaseByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateKnowledgeBaseByPkMutation, IUpdateKnowledgeBaseByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateKnowledgeBaseByPkMutation, IUpdateKnowledgeBaseByPkMutationVariables>(UpdateKnowledgeBaseByPkDocument, baseOptions);
      }
export type UpdateKnowledgeBaseByPkMutationHookResult = ReturnType<typeof useUpdateKnowledgeBaseByPkMutation>;
export type UpdateKnowledgeBaseByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateKnowledgeBaseByPkMutation>;
export type UpdateKnowledgeBaseByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateKnowledgeBaseByPkMutation, IUpdateKnowledgeBaseByPkMutationVariables>;
export const DeleteKnowledgeBaseVideosByPkDocument = gql`
    mutation deleteKnowledgeBaseVideosByPk($id: Int!) {
  delete_knowledge_base_videos_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteKnowledgeBaseVideosByPkMutation__
 *
 * To run a mutation, you first call `useDeleteKnowledgeBaseVideosByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKnowledgeBaseVideosByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKnowledgeBaseVideosByPkMutation, { data, loading, error }] = useDeleteKnowledgeBaseVideosByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKnowledgeBaseVideosByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteKnowledgeBaseVideosByPkMutation, IDeleteKnowledgeBaseVideosByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteKnowledgeBaseVideosByPkMutation, IDeleteKnowledgeBaseVideosByPkMutationVariables>(DeleteKnowledgeBaseVideosByPkDocument, baseOptions);
      }
export type DeleteKnowledgeBaseVideosByPkMutationHookResult = ReturnType<typeof useDeleteKnowledgeBaseVideosByPkMutation>;
export type DeleteKnowledgeBaseVideosByPkMutationResult = ApolloReactCommon.MutationResult<IDeleteKnowledgeBaseVideosByPkMutation>;
export type DeleteKnowledgeBaseVideosByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteKnowledgeBaseVideosByPkMutation, IDeleteKnowledgeBaseVideosByPkMutationVariables>;
export const GetKnowledgeBaseByPkDocument = gql`
    query getKnowledgeBaseByPk($id: Int!) {
  knowledge_base_by_pk(id: $id) {
    id
    description
    sequence
    knowledge_base_videos(order_by: {sequence: asc}) {
      id
      title
      sequence
      video_url
      duration_in_minutes
      knowledge_base_id
    }
  }
}
    `;

/**
 * __useGetKnowledgeBaseByPkQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeBaseByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeBaseByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeBaseByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetKnowledgeBaseByPkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetKnowledgeBaseByPkQuery, IGetKnowledgeBaseByPkQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetKnowledgeBaseByPkQuery, IGetKnowledgeBaseByPkQueryVariables>(GetKnowledgeBaseByPkDocument, baseOptions);
      }
export function useGetKnowledgeBaseByPkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetKnowledgeBaseByPkQuery, IGetKnowledgeBaseByPkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetKnowledgeBaseByPkQuery, IGetKnowledgeBaseByPkQueryVariables>(GetKnowledgeBaseByPkDocument, baseOptions);
        }
export type GetKnowledgeBaseByPkQueryHookResult = ReturnType<typeof useGetKnowledgeBaseByPkQuery>;
export type GetKnowledgeBaseByPkLazyQueryHookResult = ReturnType<typeof useGetKnowledgeBaseByPkLazyQuery>;
export type GetKnowledgeBaseByPkQueryResult = ApolloReactCommon.QueryResult<IGetKnowledgeBaseByPkQuery, IGetKnowledgeBaseByPkQueryVariables>;
export function refetchGetKnowledgeBaseByPkQuery(variables?: IGetKnowledgeBaseByPkQueryVariables) {
      return { query: GetKnowledgeBaseByPkDocument, variables: variables }
    }
export const InsertKnowledgeBaseVideosDocument = gql`
    mutation insertKnowledgeBaseVideos($objects: [knowledge_base_videos_insert_input!]!) {
  insert_knowledge_base_videos(objects: $objects) {
    affected_rows
  }
}
    `;

/**
 * __useInsertKnowledgeBaseVideosMutation__
 *
 * To run a mutation, you first call `useInsertKnowledgeBaseVideosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertKnowledgeBaseVideosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertKnowledgeBaseVideosMutation, { data, loading, error }] = useInsertKnowledgeBaseVideosMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertKnowledgeBaseVideosMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertKnowledgeBaseVideosMutation, IInsertKnowledgeBaseVideosMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertKnowledgeBaseVideosMutation, IInsertKnowledgeBaseVideosMutationVariables>(InsertKnowledgeBaseVideosDocument, baseOptions);
      }
export type InsertKnowledgeBaseVideosMutationHookResult = ReturnType<typeof useInsertKnowledgeBaseVideosMutation>;
export type InsertKnowledgeBaseVideosMutationResult = ApolloReactCommon.MutationResult<IInsertKnowledgeBaseVideosMutation>;
export type InsertKnowledgeBaseVideosMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertKnowledgeBaseVideosMutation, IInsertKnowledgeBaseVideosMutationVariables>;
export const UpdateKnowledgeBaseVideosByPkDocument = gql`
    mutation updateKnowledgeBaseVideosByPk($id: Int!, $changes: knowledge_base_videos_set_input!) {
  update_knowledge_base_videos_by_pk(_set: $changes, pk_columns: {id: $id}) {
    id
  }
}
    `;

/**
 * __useUpdateKnowledgeBaseVideosByPkMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeBaseVideosByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeBaseVideosByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeBaseVideosByPkMutation, { data, loading, error }] = useUpdateKnowledgeBaseVideosByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateKnowledgeBaseVideosByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateKnowledgeBaseVideosByPkMutation, IUpdateKnowledgeBaseVideosByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateKnowledgeBaseVideosByPkMutation, IUpdateKnowledgeBaseVideosByPkMutationVariables>(UpdateKnowledgeBaseVideosByPkDocument, baseOptions);
      }
export type UpdateKnowledgeBaseVideosByPkMutationHookResult = ReturnType<typeof useUpdateKnowledgeBaseVideosByPkMutation>;
export type UpdateKnowledgeBaseVideosByPkMutationResult = ApolloReactCommon.MutationResult<IUpdateKnowledgeBaseVideosByPkMutation>;
export type UpdateKnowledgeBaseVideosByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateKnowledgeBaseVideosByPkMutation, IUpdateKnowledgeBaseVideosByPkMutationVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($productId: Int!, $input: products_rg_set_input) {
  update_products_rg_by_pk(pk_columns: {id: $productId}, _set: $input) {
    id
  }
}
    `;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateProductMutation, IUpdateProductMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateProductMutation, IUpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = ApolloReactCommon.MutationResult<IUpdateProductMutation>;
export type UpdateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateProductMutation, IUpdateProductMutationVariables>;
export const ApproveProductDocument = gql`
    mutation approveProduct($id: Int!) {
  update_products_rg_review_by_pk(pk_columns: {id: $id}, _set: {approved: true}) {
    id
  }
}
    `;

/**
 * __useApproveProductMutation__
 *
 * To run a mutation, you first call `useApproveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveProductMutation, { data, loading, error }] = useApproveProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IApproveProductMutation, IApproveProductMutationVariables>) {
        return ApolloReactHooks.useMutation<IApproveProductMutation, IApproveProductMutationVariables>(ApproveProductDocument, baseOptions);
      }
export type ApproveProductMutationHookResult = ReturnType<typeof useApproveProductMutation>;
export type ApproveProductMutationResult = ApolloReactCommon.MutationResult<IApproveProductMutation>;
export type ApproveProductMutationOptions = ApolloReactCommon.BaseMutationOptions<IApproveProductMutation, IApproveProductMutationVariables>;
export const GetProductCategoriesDocument = gql`
    query getProductCategories {
  product_categories {
    id
    name
  }
}
    `;

/**
 * __useGetProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetProductCategoriesQuery, IGetProductCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetProductCategoriesQuery, IGetProductCategoriesQueryVariables>(GetProductCategoriesDocument, baseOptions);
      }
export function useGetProductCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetProductCategoriesQuery, IGetProductCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetProductCategoriesQuery, IGetProductCategoriesQueryVariables>(GetProductCategoriesDocument, baseOptions);
        }
export type GetProductCategoriesQueryHookResult = ReturnType<typeof useGetProductCategoriesQuery>;
export type GetProductCategoriesLazyQueryHookResult = ReturnType<typeof useGetProductCategoriesLazyQuery>;
export type GetProductCategoriesQueryResult = ApolloReactCommon.QueryResult<IGetProductCategoriesQuery, IGetProductCategoriesQueryVariables>;
export function refetchGetProductCategoriesQuery(variables?: IGetProductCategoriesQueryVariables) {
      return { query: GetProductCategoriesDocument, variables: variables }
    }
export const GetRevieableProductsDocument = gql`
    query getRevieableProducts {
  products_rg_review(where: {approved: {_eq: false}}, order_by: {created_at: asc}) {
    id
    name
    calories
    unit
    carbohydrates
    fats
    proteins
    barcode
    created_at
    product_rg {
      id
      product_category_id
    }
    user {
      id
      first_name
      last_name
    }
  }
}
    `;

/**
 * __useGetRevieableProductsQuery__
 *
 * To run a query within a React component, call `useGetRevieableProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevieableProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevieableProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRevieableProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetRevieableProductsQuery, IGetRevieableProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetRevieableProductsQuery, IGetRevieableProductsQueryVariables>(GetRevieableProductsDocument, baseOptions);
      }
export function useGetRevieableProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetRevieableProductsQuery, IGetRevieableProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetRevieableProductsQuery, IGetRevieableProductsQueryVariables>(GetRevieableProductsDocument, baseOptions);
        }
export type GetRevieableProductsQueryHookResult = ReturnType<typeof useGetRevieableProductsQuery>;
export type GetRevieableProductsLazyQueryHookResult = ReturnType<typeof useGetRevieableProductsLazyQuery>;
export type GetRevieableProductsQueryResult = ApolloReactCommon.QueryResult<IGetRevieableProductsQuery, IGetRevieableProductsQueryVariables>;
export function refetchGetRevieableProductsQuery(variables?: IGetRevieableProductsQueryVariables) {
      return { query: GetRevieableProductsDocument, variables: variables }
    }
export const InsertProductDocument = gql`
    mutation insertProduct($input: [products_rg_insert_input!]!) {
  insert_products_rg(objects: $input) {
    affected_rows
  }
}
    `;

/**
 * __useInsertProductMutation__
 *
 * To run a mutation, you first call `useInsertProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProductMutation, { data, loading, error }] = useInsertProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IInsertProductMutation, IInsertProductMutationVariables>) {
        return ApolloReactHooks.useMutation<IInsertProductMutation, IInsertProductMutationVariables>(InsertProductDocument, baseOptions);
      }
export type InsertProductMutationHookResult = ReturnType<typeof useInsertProductMutation>;
export type InsertProductMutationResult = ApolloReactCommon.MutationResult<IInsertProductMutation>;
export type InsertProductMutationOptions = ApolloReactCommon.BaseMutationOptions<IInsertProductMutation, IInsertProductMutationVariables>;
export const GetAllExerciseVariantsDocument = gql`
    query GetAllExerciseVariants {
  exercise_variants {
    id
    name
    exercise_muscle_category_id
    exercise_device_category_id
    exercise_variant_category
    exercise_variant_category
    media_content
    exercise_muscle_category {
      id
      name
      exercise_variants {
        id
        name
        exercise_muscle_category_id
        exercise_device_category_id
        exercise_variant_category
        exercise_variant_category
      }
    }
  }
}
    `;

/**
 * __useGetAllExerciseVariantsQuery__
 *
 * To run a query within a React component, call `useGetAllExerciseVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllExerciseVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllExerciseVariantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllExerciseVariantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetAllExerciseVariantsQuery, IGetAllExerciseVariantsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetAllExerciseVariantsQuery, IGetAllExerciseVariantsQueryVariables>(GetAllExerciseVariantsDocument, baseOptions);
      }
export function useGetAllExerciseVariantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetAllExerciseVariantsQuery, IGetAllExerciseVariantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetAllExerciseVariantsQuery, IGetAllExerciseVariantsQueryVariables>(GetAllExerciseVariantsDocument, baseOptions);
        }
export type GetAllExerciseVariantsQueryHookResult = ReturnType<typeof useGetAllExerciseVariantsQuery>;
export type GetAllExerciseVariantsLazyQueryHookResult = ReturnType<typeof useGetAllExerciseVariantsLazyQuery>;
export type GetAllExerciseVariantsQueryResult = ApolloReactCommon.QueryResult<IGetAllExerciseVariantsQuery, IGetAllExerciseVariantsQueryVariables>;
export function refetchGetAllExerciseVariantsQuery(variables?: IGetAllExerciseVariantsQueryVariables) {
      return { query: GetAllExerciseVariantsDocument, variables: variables }
    }
export const DeleteExerciseGroupDocument = gql`
    mutation deleteExerciseGroup($id: bigint!) {
  delete_exercise_groups_by_pk(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteExerciseGroupMutation__
 *
 * To run a mutation, you first call `useDeleteExerciseGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExerciseGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExerciseGroupMutation, { data, loading, error }] = useDeleteExerciseGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExerciseGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteExerciseGroupMutation, IDeleteExerciseGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteExerciseGroupMutation, IDeleteExerciseGroupMutationVariables>(DeleteExerciseGroupDocument, baseOptions);
      }
export type DeleteExerciseGroupMutationHookResult = ReturnType<typeof useDeleteExerciseGroupMutation>;
export type DeleteExerciseGroupMutationResult = ApolloReactCommon.MutationResult<IDeleteExerciseGroupMutation>;
export type DeleteExerciseGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteExerciseGroupMutation, IDeleteExerciseGroupMutationVariables>;
export const GetProductByIdDocument = gql`
    query getProductById($id: Int!) {
  products_rg_by_pk(id: $id) {
    id
    name
    calories
    carbohydrates
    proteins
    fats
    units
  }
}
    `;

/**
 * __useGetProductByIdQuery__
 *
 * To run a query within a React component, call `useGetProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetProductByIdQuery, IGetProductByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetProductByIdQuery, IGetProductByIdQueryVariables>(GetProductByIdDocument, baseOptions);
      }
export function useGetProductByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetProductByIdQuery, IGetProductByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetProductByIdQuery, IGetProductByIdQueryVariables>(GetProductByIdDocument, baseOptions);
        }
export type GetProductByIdQueryHookResult = ReturnType<typeof useGetProductByIdQuery>;
export type GetProductByIdLazyQueryHookResult = ReturnType<typeof useGetProductByIdLazyQuery>;
export type GetProductByIdQueryResult = ApolloReactCommon.QueryResult<IGetProductByIdQuery, IGetProductByIdQueryVariables>;
export function refetchGetProductByIdQuery(variables?: IGetProductByIdQueryVariables) {
      return { query: GetProductByIdDocument, variables: variables }
    }
export const GetDiaryAnswersDocument = gql`
    query getDiaryAnswers($userId: bigint!) {
  diary_answers(
    where: {user_id: {_eq: $userId}}
    order_by: {diary_question: {id: asc}}
  ) {
    id
    answer
    motivation
    created_at
    diary_question {
      id
      question
      answers
      category
      type
      range_to
      range_from
      requires_motivation
      created_at
    }
  }
}
    `;

/**
 * __useGetDiaryAnswersQuery__
 *
 * To run a query within a React component, call `useGetDiaryAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiaryAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiaryAnswersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetDiaryAnswersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetDiaryAnswersQuery, IGetDiaryAnswersQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetDiaryAnswersQuery, IGetDiaryAnswersQueryVariables>(GetDiaryAnswersDocument, baseOptions);
      }
export function useGetDiaryAnswersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetDiaryAnswersQuery, IGetDiaryAnswersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetDiaryAnswersQuery, IGetDiaryAnswersQueryVariables>(GetDiaryAnswersDocument, baseOptions);
        }
export type GetDiaryAnswersQueryHookResult = ReturnType<typeof useGetDiaryAnswersQuery>;
export type GetDiaryAnswersLazyQueryHookResult = ReturnType<typeof useGetDiaryAnswersLazyQuery>;
export type GetDiaryAnswersQueryResult = ApolloReactCommon.QueryResult<IGetDiaryAnswersQuery, IGetDiaryAnswersQueryVariables>;
export function refetchGetDiaryAnswersQuery(variables?: IGetDiaryAnswersQueryVariables) {
      return { query: GetDiaryAnswersDocument, variables: variables }
    }