import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from 'apollo-link-context'
import { WebSocketLink } from 'apollo-link-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'

export const createClient = (jwtToken: string | undefined): ApolloClient<NormalizedCacheObject> => {
  const hasura = 'hasura.rg.qraft.eu/v1/graphql'

  const httpLink = new HttpLink({
    uri: `https://${hasura}`,
  })

  const authLink = setContext(async (_request, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: jwtToken ? `Bearer ${jwtToken}` : 'niets',
      },
    }
  }) as any

  const client = new SubscriptionClient(`wss://${hasura}`, {
    reconnect: true,
    connectionParams: {
      headers: {
        authorization: jwtToken ? `Bearer ${jwtToken}` : 'niets',
      },
    },
  })

  const wsLink = new WebSocketLink(client) as any

  const link = split(
    ({ query }) => {
      const e = getMainDefinition(query)
      return e.kind === 'OperationDefinition' && e.operation === 'subscription'
    },
    wsLink,
    authLink.concat(httpLink)
  )

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })
}
