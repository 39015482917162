/* eslint radix: ["error", "as-needed"] */
// MUI
import { Box, Grid, Tab, Tabs } from '@material-ui/core'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import AssessmentIcon from '@material-ui/icons/Assessment'
import Book from '@material-ui/icons/Book'
import PieChartIcon from '@material-ui/icons/PieChart'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import SportsIcon from '@material-ui/icons/Sports'
import { Loading } from 'components/Loading'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { useGetUserByIdQuery } from '../../generated/graphql'
import WithDrawer from '../../wrappers'
import { TabPanel } from './components/TabPanel'
import { DiaryTab } from './tabs/DiaryTab'
import { ProgressieTab } from './tabs/ProgressieTab'
import TrainingsschemaTab from './tabs/Trainingsschema'
import { VoedingssamenstellingTab } from './tabs/VoedingssamenstellingTab'
import { VoedingsschemaTab } from './tabs/VoedingsschemaTab/VoedingsschemaTab'
import VolledigProfielTab from './tabs/VolledigProfielTab'
import { useStyles } from './useStyles'

interface IUserDetailedPageContext {
  tab: number
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const USER_DETAILED_PAGE_CONTEXT = 'userDetailedPageContext'

export const UserDetailedPage: React.FunctionComponent = () => {
  const classes = useStyles()
  const { userId } = useParams<{ userId: string }>()

  const [tab, setTab] = React.useState<number>(0)

  const userQuery = useGetUserByIdQuery({
    variables: { userId: parseInt(userId) },
  })

  const { data, loading } = userQuery

  const setTabHandler = (newValue: number) => {
    const userDetailedPageContext = {
      tab: newValue,
    }
    window.localStorage.setItem(USER_DETAILED_PAGE_CONTEXT, JSON.stringify(userDetailedPageContext))
    setTab(newValue)
  }

  React.useEffect(() => {
    const userDetailedPageContext = window.localStorage.getItem(USER_DETAILED_PAGE_CONTEXT)
    if (userDetailedPageContext) {
      try {
        const parsed = JSON.parse(userDetailedPageContext) as IUserDetailedPageContext
        setTab(parsed.tab)
      } catch (error) {
        console.error(error)
      }
    } else setTabHandler(0)
  }, [])

  const userInfo = data?.users_by_pk

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (__: React.ChangeEvent<{}>, newValue: number) => {
    setTabHandler(newValue)
  }

  const breadcrumbData: IBreadcrumb[] = [
    { isLink: true, to: '/clients', name: 'Members' },
    { isLink: false, name: `${userInfo?.first_name} ${userInfo?.last_name}` },
  ]

  if (loading) return <Loading />

  const getBox = (): JSX.Element => {
    if (!userInfo) return <div>Geen gebruiker gevonden</div>

    return (
      <React.Fragment>
        <TabPanel {...{ value: tab, index: 0 }}>
          <ProgressieTab
            {...{
              userInfo,
              navigateToTab: (tabValue: number): void => {
                setTabHandler(tabValue)
              },
            }}
          />
        </TabPanel>

        <TabPanel {...{ value: tab, index: 1 }}>
          <VoedingssamenstellingTab updateTab={setTabHandler} />
        </TabPanel>

        <TabPanel {...{ value: tab, index: 2 }}>
          <VoedingsschemaTab />
        </TabPanel>

        <TabPanel {...{ value: tab, index: 3 }}>
          <TrainingsschemaTab {...{ userId }} />
        </TabPanel>

        <TabPanel {...{ value: tab, index: 4 }}>
          <VolledigProfielTab userInfo={userInfo} />
        </TabPanel>

        <TabPanel {...{ value: tab, index: 5 }}>
          <DiaryTab userInfo={userInfo} />
        </TabPanel>
      </React.Fragment>
    )
  }

  return (
    <WithDrawer breadcrumbData={breadcrumbData}>
      <Box
        {...{
          component: 'div',
          className: classes.container,
        }}
      >
        <Grid container>
          <Tabs
            {...{
              value: tab,
              onChange: handleChange,
              TabIndicatorProps: {
                style: { display: 'none' },
              },
              scrollButtons: 'on',
              variant: 'scrollable',
              style: {
                position: 'relative',
                left: -40,
              },
            }}
          >
            <Tab
              icon={<AssessmentIcon />}
              {...{
                className: classes.tabButton,
                label: 'Progressie',
                ...a11yProps(0),
              }}
            />
            <Tab
              icon={<PieChartIcon />}
              {...{
                className: classes.tabButton,
                label: 'Voedingssamenstelling',
                ...a11yProps(1),
              }}
            />
            <Tab
              icon={<RestaurantMenuIcon />}
              {...{
                className: classes.tabButton,
                label: 'Voedingsschema',
                ...a11yProps(2),
              }}
            />
            <Tab
              icon={<SportsIcon />}
              {...{
                className: classes.tabButton,
                label: 'Trainingsschema',
                ...a11yProps(3),
              }}
            />
            <Tab
              icon={<AccountBoxIcon />}
              {...{
                className: classes.tabButton,
                label: 'Volledig profiel',
                disabled: false,
                ...a11yProps(3),
              }}
            />

            <Tab
              icon={<Book />}
              {...{
                className: classes.tabButton,
                label: 'Dagboek',
                disabled: false,
                ...a11yProps(4),
              }}
            />
          </Tabs>
        </Grid>

        {getBox()}
      </Box>
    </WithDrawer>
  )
}
