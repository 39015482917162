import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import styled from 'styled-components'

export const PageWrapper = styled.div`
  position: relative;
  height: 100%;
  &::before {
    content: '';
    position: absolute;
    background-image: url(./images/create_nutritions.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 100%;
    width: 100%;
    filter: blur(20px);
    z-index: -1;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 40px;
`

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: 'red',
    },
    success: {
      color: 'green',
    },
    container: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    gridWrapper: {
      background: '#fff',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
    },
    logoWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    formWrapper: {
      padding: '20px',
    },
    imageReset: {
      verticalAlign: 'top',
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    pageTitle: {
      fontSize: '16px',
      margin: '0 0 20px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    textfield: {
      borderRadius: '4px',
      border: 0,
      marginTop: 0,
    },
    formControl: {
      width: '100%',
      margin: '0 0 15px',
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    right: {
      textAlign: 'right',
    },
  })
)
