import { Button } from '@material-ui/core'
import React from 'react'

import AddMealBlock from '../../components/AddNewMealBlock'
import { useStyles } from '../../useStyles'

interface IProps {
  isMealBlockDisplayed: boolean
  _onFinishAddNewMealBlock: () => void
  _onAddNewMealBlock: (_: string) => void
  setMealBlockStatus: () => void
}

export const AddNutritionMealBlock: React.FC<IProps> = ({
  isMealBlockDisplayed,
  _onFinishAddNewMealBlock,
  _onAddNewMealBlock,
  setMealBlockStatus,
}): JSX.Element => {
  const classes = useStyles()

  if (isMealBlockDisplayed)
    return <AddMealBlock _onFinishAddNewMealBlock={_onFinishAddNewMealBlock} _onAddNewMealBlock={_onAddNewMealBlock} />

  return (
    <div className={classes.buttonsContainer}>
      <Button
        color="primary"
        variant="contained"
        className={classes.containedBtn}
        onClick={setMealBlockStatus}
        disableElevation
      >
        + Voeg Maaltijd Toe
      </Button>
    </div>
  )
}
