import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px 30px',
    width: '100%',
    overflow: 'auto',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  fixedHeight: {
    minHeight: 240,
  },
}))
