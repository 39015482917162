import { TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import ContainerModal from '../../../components/ContainerModal'
import { TKnowledgeBases } from '../interfaces'
import { useStyles } from '../styles'
import { useKnowledgeBase } from '../useKnowledgeBase'

interface IProps {
  closeModal: () => void
  item?: TKnowledgeBases[0] | undefined
}

const Modal: React.FC<IProps> = ({ closeModal, item }) => {
  const classes = useStyles()
  const { insert, updateById, isLoading } = useKnowledgeBase(closeModal)

  const [description, setDescription] = useState<string>('')
  const [sequence, setSequence] = useState<number>(0)

  useEffect(() => {
    if (item) {
      setDescription(item.description)
      setSequence(item.sequence)
    }
  }, [item])

  const onSave = (): void => {
    if (item) updateById({ id: item.id, description, sequence })
    else insert({ description, sequence })
  }

  const disableConfirmButton = isLoading || description.trim().length === 0 || sequence === 0

  return (
    <ContainerModal
      title={item ? 'Wijzigen' : 'Toevoegen'}
      isOpen
      handleClose={closeModal}
      handleSubmit={onSave}
      disabled={disableConfirmButton}
    >
      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Omschrijving*: </Typography>
        <TextField
          multiline
          rows={3}
          variant="outlined"
          size="small"
          value={description}
          onChange={(event): void => {
            setDescription(event.target.value)
          }}
        />
      </div>

      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Week*: </Typography>
        <TextField
          multiline
          rows={1}
          type="number"
          variant="outlined"
          size="small"
          value={sequence}
          onChange={(event): void => setSequence(Number(event.target.value))}
        />
      </div>
    </ContainerModal>
  )
}

export default Modal
