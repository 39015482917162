import { Chip, TextField } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import * as React from 'react'

interface IProps {
  isEditable: boolean
  name: string
  onAdd: (key: any, newValue: any) => void
  onDelete: (index: number, name: any) => void
  list: string[]
}

export const SwitchableChip: React.FunctionComponent<IProps> = ({
  isEditable,
  name,
  onDelete = () => {},
  onAdd = () => {},
  list,
}) => {
  const [input, setInput] = React.useState<string>('')

  const onChange = (event: any) => {
    const newValue = event.target.value
    setInput(newValue)
  }

  const onAddHandler = () => {
    if (input.trim().length) {
      onAdd(name, input)
      setInput('')
    }
  }

  const onEnter = (event: any) => {
    if (event.key === 'Enter') onAddHandler()
  }

  if (!isEditable) {
    return (
      <div>
        {list.length === 0 && <span>Niet gespecificeerd</span>}
        {list.map((item, index) => (
          <Chip key={index} style={{ margin: 3 }} color="primary" label={item} variant="outlined" />
        ))}
      </div>
    )
  }
  return (
    <React.Fragment>
      <TextField
        fullWidth
        type="text"
        placeholder="gerecht"
        variant="outlined"
        margin="dense"
        name={name}
        onKeyDown={onEnter}
        onChange={onChange}
        value={input}
        InputProps={{
          endAdornment: <AddIcon color="primary" onClick={onAddHandler} style={{ cursor: 'pointer' }} />,
        }}
      />
      <div style={{ marginTop: 15 }}>
        {list.map((item, index) => (
          <Chip
            key={index}
            style={{ margin: 3 }}
            onDelete={() => onDelete(index, name)}
            color="primary"
            label={item}
            variant="outlined"
          />
        ))}
      </div>
    </React.Fragment>
  )
}

export default SwitchableChip
