/* eslint radix: ["error", "as-needed"] */

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import List from '@material-ui/icons/List'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import clsx from 'clsx'
import { useConfirmation } from 'context/ConfirmationServiceContext'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { NotCreated } from 'pages/UserDetailedPage/components/NotCreated'
import * as React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { StyledTableCell, StyledTableRow } from '../../../../components/Table'
import {
  useDeleteWorkoutPlanByPkMutation,
  useGetWorkoutPlansByUserIdQuery,
  useInsertWorkoutPlanMutation,
  useUpdateWorkoutPlanByPkMutation,
} from '../../../../generated/graphql'
import PlanToePopUp from '../../components/PlanToePopUp'
import { useStyles } from '../../useStyles'
import { ITableItem, ITrainingsschemaTabProps } from './interfaces'
import { useScopedStyles } from './useScopedStyles'

const SORTABLE_COLUMNS = {
  name: {
    value: 'name',
    label: 'Naam',
  },
  starts_on: {
    value: 'starts_on',
    label: 'Start datum',
  },
  ends_on: {
    value: 'ends_on',
    label: 'Eind datum',
  },
  day_count: {
    value: 'day_count',
    label: 'Trainingsdagen',
  },
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const TrainingsschemaTab: React.FunctionComponent<ITrainingsschemaTabProps> = ({ userId }): JSX.Element => {
  const classes = useStyles()
  const scopedClasses = useScopedStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const [showCreatePlan, setShowCreatePlan] = React.useState<boolean>(false)
  const [workoutPlans, setWorkoutPlans] = React.useState<any[]>([])

  const [insertWorkoutPlan] = useInsertWorkoutPlanMutation()
  const [updateWorkoutPlan] = useUpdateWorkoutPlanByPkMutation()
  const [deleteWorkoutPlan] = useDeleteWorkoutPlanByPkMutation()

  const wPlans = useGetWorkoutPlansByUserIdQuery({
    variables: { userId: parseInt(userId) },
  })

  React.useEffect(() => {
    if (wPlans.data?.workout_plans.length) {
      setWorkoutPlans(wPlans.data.workout_plans)
      setShowCreatePlan(false)
    } else setShowCreatePlan(true)
  }, [wPlans])

  const [nextDateRange, setNextDateRange] = React.useState<{
    startDate: Date
    endDate: Date
  }>({
    startDate: new Date(),
    endDate: new Date(),
  })

  const [sortBy, setSortBy] = React.useState(SORTABLE_COLUMNS.name)

  const [anchorEl, setAnchorEl] = React.useState(null as any)
  const isMenuOpened = Boolean(anchorEl)

  const [isModalOpened, setModalOpened] = React.useState(false)
  const [editingItem, setEditingItem] = React.useState<ITableItem | null>(null)

  const formatDate = (date: any): string => {
    return moment(date).format('DD-MM-yyyy').toString()
  }

  const sort = (array: any[]) => {
    let comparator: (a: any, b: any) => number

    switch (sortBy.value) {
      case SORTABLE_COLUMNS.starts_on.value:
      case SORTABLE_COLUMNS.ends_on.value: {
        comparator = (first: string = Date.now().toString(), second: string = Date.now().toString()) =>
          new Date(first).getTime() - new Date(second).getTime()
        break
      }
      case SORTABLE_COLUMNS.name.value: {
        comparator = (first = '', second = '') => first.toString().localeCompare(second.toString())
        break
      }
      default: {
        comparator = (first = 0, second = 0) => first - second
      }
    }
    // @ts-ignore
    return [...array].sort((first: ITableItem, second: ITableItem) => {
      // @ts-ignore
      return comparator(first[sortBy.value] || 0, second[sortBy.value] || 0)
    })
  }

  const sorted = sort(workoutPlans as any[])

  const defineDateRange = React.useCallback(() => {
    const stDate = moment(new Date()).toDate()
    const ndDate = moment(stDate).add('7', 'days').toDate()
    setNextDateRange({ startDate: stDate, endDate: ndDate })
  }, [])

  React.useEffect(() => {
    if (wPlans?.data?.workout_plans?.length) {
      const copyOfWorkoutPlans = [...wPlans.data.workout_plans]
      const sortedList: any = copyOfWorkoutPlans.sort(
        (first: any, second: any) => new Date(first?.starts_on).getTime() - new Date(second?.starts_on).getTime()
      )

      const lastElement = sortedList[sortedList.length - 1]
      const startDate = moment(lastElement.ends_on).add(1, 'week').startOf('week').toDate()
      const endDate = moment(lastElement.ends_on).add(1, 'week').endOf('week').toDate()
      setNextDateRange({ startDate, endDate })
    } else defineDateRange()
  }, [wPlans.data, defineDateRange])

  React.useEffect(() => {
    defineDateRange()
  }, [defineDateRange])

  const _onMenuClick = (event: React.MouseEvent<HTMLButtonElement>, item: ITableItem) => {
    setEditingItem(item)
    setAnchorEl(event.currentTarget)
  }

  const _onMenuClose = () => {
    setAnchorEl(null)
  }

  const _onMenuItemSelected = (callback = () => {}) => {
    _onMenuClose()
    callback()
  }

  const _onModalSubmit = (data: any) => {
    if (editingItem) {
      updateWorkoutPlan({
        variables: {
          user_id: userId.toString(),
          id: editingItem.id,
          ...data,
        },
      })
        .then(() => {
          wPlans.refetch()
          enqueueSnackbar('Updated successfully', { variant: 'success' })
        })
        .catch(console.log)
    } else {
      const exerciseDays: { sequence: number; workout_template_id: any }[] = []
      for (let i = 0; i < data.day_count; i++) {
        const row = {
          sequence: i + 1,
          workout_template_id: null,
        }
        exerciseDays.push(row)
      }

      const body = [
        {
          day_count: data.day_count.toString(),
          ends_on: data.ends_on,
          name: data.name,
          starts_on: data.starts_on,
          user_id: userId.toString(),
          exercise_days: {
            data: exerciseDays,
          },
        },
      ]

      insertWorkoutPlan({
        variables: {
          objects: body,
        },
      })
        .then(() => {
          wPlans.refetch()
          enqueueSnackbar('Saved successfully', { variant: 'success' })
        })
        .catch(console.log)
    }
  }

  const _onModalClose = () => {
    setModalOpened(false)
    setEditingItem(null)
  }

  const _onOpenModalForInsert = () => {
    setEditingItem(null)
    setModalOpened(true)
  }

  const confirm = useConfirmation()

  const _onDelete = (toRemove: any) => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'Do you really want to remove the plan',
      description: `Do you really want to remove ${toRemove.name}`,
    }).then(() => {
      deleteWorkoutPlan({
        variables: {
          id: toRemove.id,
        },
      })
        .then(() => {
          wPlans.refetch()
          enqueueSnackbar('Deleted successfully', { variant: 'success' })
        })
        .catch(console.log)
    })
  }

  const _prepareToDelete = () => {
    _onMenuClose()
    if (editingItem) {
      _onDelete(editingItem)
      setEditingItem(null)
    }
  }

  const onPressHandler = () => {
    _onOpenModalForInsert()
  }

  return (
    <React.Fragment>
      <Paper
        {...{
          className: fixedHeightPaper,
          style: {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          },
        }}
      >
        <Grid container className={scopedClasses.gridWrapper}>
          {showCreatePlan ? (
            <NotCreated
              {...{
                onPress: onPressHandler,
                primary: 'No training schema created',
                secondary: 'Voor het maken van een trainingsprogramma, klik op de button hieronder.',
                buttonText: 'Voeg plan toe',
                image: '../../images/training_schema.png',
              }}
            />
          ) : (
            <React.Fragment>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <FormControl variant="outlined" size="small">
                  <InputLabel htmlFor="outlined-age-native-simple">Sort by</InputLabel>
                  <Select
                    native
                    value={sortBy.value}
                    // @ts-ignore
                    onChange={(event) =>
                      // @ts-ignore
                      setSortBy(SORTABLE_COLUMNS[event.target.value])
                    }
                    label="Sort by"
                    inputProps={{
                      name: 'age',
                      id: 'outlined-age-native-simple',
                    }}
                  >
                    {Object.values(SORTABLE_COLUMNS).map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.containedBtn}
                  onClick={_onOpenModalForInsert}
                >
                  + Voeg Plan Toe
                </Button>
              </Grid>

              <Grid container className={scopedClasses.tableContainer}>
                <TableContainer component={Paper} {...{ elevation: 0 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>#</StyledTableCell>
                        <StyledTableCell>Naam</StyledTableCell>
                        <StyledTableCell>Start datum</StyledTableCell>
                        <StyledTableCell>Eind datum</StyledTableCell>
                        <StyledTableCell>Trainingsdagen</StyledTableCell>
                        <StyledTableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody
                      {...{
                        style: { boxShadow: '2px 2px 21px rgb(0 0 0 / 6%)' },
                      }}
                    >
                      {sorted?.map((row, index) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{`${index + 1}.`}</StyledTableCell>
                          <StyledTableCell>{row.name}</StyledTableCell>
                          <StyledTableCell>{formatDate(row.starts_on)}</StyledTableCell>
                          <StyledTableCell>{formatDate(row.ends_on)}</StyledTableCell>
                          <StyledTableCell>{`${row.day_count} dagen`}</StyledTableCell>
                          <StyledTableCell className={scopedClasses.actionsCell} align="right">
                            <div className={scopedClasses.moreIconWrapper}>
                              <IconButton
                                aria-label="more"
                                aria-controls="action-menu"
                                aria-haspopup="true"
                                onClick={(event) => _onMenuClick(event, row)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </div>

                            <Link style={{ textDecoration: 'none' }} to={`/user/${userId}/training-schema/${row.id}`}>
                              <Button variant="outlined" size="small" className={scopedClasses.tableButton}>
                                Trainingsschema
                                <ChevronRightIcon className={scopedClasses.iconHack} />
                                <ChevronRightIcon />
                              </Button>
                            </Link>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Paper>

      <Menu id="action-menu" anchorEl={anchorEl} keepMounted open={isMenuOpened} onClose={_onMenuClose}>
        <MenuItem onClick={() => _onMenuItemSelected(() => setModalOpened(true))}>
          <EditIcon className={scopedClasses.menuIcon} />
          Edit
        </MenuItem>

        <MenuItem onClick={(): void => history.push(`/user/${userId}/training-schema/${editingItem?.id}/progress`)}>
          <List className={scopedClasses.menuIcon} />
          Bekijk clones
        </MenuItem>

        <MenuItem onClick={() => _prepareToDelete()}>
          <DeleteIcon className={scopedClasses.menuIcon} />
          Remove
        </MenuItem>
      </Menu>

      {!!isModalOpened && (
        <PlanToePopUp
          nextDate={nextDateRange}
          open={isModalOpened}
          item={editingItem}
          handleClose={_onModalClose}
          handleSubmit={_onModalSubmit}
        />
      )}
    </React.Fragment>
  )
}

export default TrainingsschemaTab
