import { Avatar, Box, Card, CardActionArea, Typography } from '@material-ui/core'
// MUI
import { AvatarGroup } from '@material-ui/lab'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { IProps } from './interfaces'
import { useStyles } from './styles'

export const MemberCard: React.FC<IProps> = ({ user }) => {
  const classes = useStyles()

  const fullName = `${user.first_name} ${user.last_name}`

  return (
    <Link to={`/user/${user.id}`} style={{ textDecoration: 'none' }}>
      <Card {...{ className: classes.card }} elevation={0}>
        <CardActionArea
          classes={{
            root: classes.actionArea,
          }}
        >
          <Box
            {...{
              className: classes.cardMediaWrapper,
              component: 'div',
            }}
          >
            <Typography
              gutterBottom
              {...{
                variant: 'body1',
                component: 'h2',
                className: classes.taskTitle,
              }}
            >
              {fullName}
            </Typography>

            <AvatarGroup max={4}>
              <Avatar
                {...{
                  alt: 'Remy Sharp',
                  src: '/static/images/avatar/1.jpg',
                  className: classes.avatar,
                }}
              />
            </AvatarGroup>
          </Box>
        </CardActionArea>
      </Card>
    </Link>
  )
}
