import { Paper, Typography } from '@material-ui/core'
import React from 'react'

import { useStyles } from '../useStyles'

interface IProps {
  params: any
  foodComposition: any
  caloriesPerDay: any
}

export const FaseOverview: React.FC<IProps> = ({ caloriesPerDay, foodComposition, params }): JSX.Element => {
  const classes = useStyles()

  return (
    <Paper className={classes.card} elevation={3}>
      <Typography className={classes.cardHead} variant="h6" component="h6">
        {`Fase ${params?.state?.sequence} Overzicht`}
      </Typography>

      <div className={classes.cardLine}>
        <Typography component="p" variant="body1" className={classes.cardPropertyName}>
          Doel:
        </Typography>
        <Typography component="p" variant="body2">
          {foodComposition?.personalGoal ?? 'Onbekend'}
        </Typography>
      </div>

      <div className={classes.cardLine}>
        <Typography component="p" variant="body1" className={classes.cardPropertyName}>
          Calorie inname voor onderhoud:
        </Typography>
        <Typography component="p" variant="body2">
          {`${foodComposition?.caloricIntakeForMaintenance ?? '-'} kcal`}
        </Typography>
      </div>

      <div className={classes.cardLine}>
        <Typography component="p" variant="body1" className={classes.cardPropertyName}>
          Calorie inname voor het doel:
        </Typography>
        <Typography component="p" variant="body2">
          {`${caloriesPerDay} kcal`}
        </Typography>
      </div>

      {foodComposition?.kcalDeficiency && (
        <div className={classes.cardLine}>
          <Typography component="p" variant="body1" className={classes.cardPropertyName}>
            Wekelijkse calorie te kort:
          </Typography>
          <Typography component="p" variant="body2">
            {`${foodComposition?.kcalDeficiency} kcal`}
          </Typography>
        </div>
      )}
    </Paper>
  )
}
