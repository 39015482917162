import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    boxShadow: '2px 2px 21px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
  },
  welcomeContentWrapper: {
    maxWidth: '60%',
    color: '#fff',
    '& p': { fontSize: 14 },
  },
  tasksWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  activeMembersCount: {
    fontSize: 50,
    fontWeight: 900,
    display: 'flex',
    alignItems: 'center',
    color: '#3F51B5',
    '& svg': {
      width: 50,
      height: 50,
      fill: '#9DAEC0',
      marginRight: 25,
    },
  },
  activeMembersPhotosWrapper: {
    marginTop: 15,
    '& div': {
      width: 25,
      height: 25,
      fontSize: 12,
    },
  },
}))
