import { Grid, Paper, Typography } from '@material-ui/core'
import { useGetNutritionPlanMealsByPkQuery } from 'generated/graphql'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router'

import ProgressBar from '../../../components/ProgressBar'
import { useStyles } from '../useStyles'
import { defaultTotalValues, getPercent } from './helpers'
import { IProps, TNutritionPlanMealsView } from './interfaces'

// eslint-disable-next-line sonarjs/cognitive-complexity
export const CurrentIndicators: React.FC<IProps> = ({ optionNutritionMealPlanId }): JSX.Element => {
  const { nutritionPlanMealId } = useParams<{ nutritionPlanMealId: string; userId: string }>()

  const Id = optionNutritionMealPlanId ?? nutritionPlanMealId

  const [total, setTotalMealDetails] = React.useState<TNutritionPlanMealsView>(defaultTotalValues)
  const classes = useStyles()

  const { data, loading } = useGetNutritionPlanMealsByPkQuery({
    variables: {
      id: +Id,
    },
  })

  const plan = data?.nutrition_plan_meals_by_pk?.nutrition_plan
  const nutritionPlanView = data?.nutrition_plan_meals_by_pk?.nutrition_plan_meals_view

  React.useEffect(() => {
    const npmv = data?.nutrition_plan_meals_by_pk?.nutrition_plan_meals_view
    if (npmv) setTotalMealDetails(npmv)
  }, [data])

  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Skeleton className={classes.tableContainer} height={50} count={2} />
      </div>
    )
  }

  return (
    <Grid item sm={12} md={7}>
      <Paper className={classes.card} elevation={3}>
        <Typography className={classes.cardHead} variant="h6" component="h6">
          Huidige indicatoren
        </Typography>

        <div className={classes.cardLine}>
          <div>
            <Typography component="p" variant="body1" className={classes.indicatorName}>
              Calorieen:
            </Typography>
            <Typography component="p" variant="body2" className={classes.goalName}>
              {`Doel: ${plan?.calories_per_day ?? '-'} KCAL`}
            </Typography>
          </div>

          <div className={classes.progressBarContainer}>
            <ProgressBar value={getPercent(total?.calorie_sum ?? 0, total?.calories_per_day ?? 0)} />
          </div>

          <Typography component="p" variant="body2" className={classes.indicatorValue}>
            {`${total?.calorie_sum.toFixed(2)} kcal`}
          </Typography>
        </div>

        <div className={classes.cardLine}>
          <div>
            <Typography component="p" variant="body1" className={classes.indicatorName}>
              Koolhydraten:
            </Typography>
            <Typography component="p" variant="body2" className={classes.goalName}>
              {`Doel: ${nutritionPlanView?.min_carbo ?? ''} - ${nutritionPlanView?.max_carbo ?? ''}`}
            </Typography>
          </div>

          <div className={classes.progressBarContainer}>
            <ProgressBar value={getPercent(total?.carbo_sum ?? 0, total?.max_carbo ?? 0)} />
          </div>

          <Typography component="p" variant="body2" className={classes.indicatorValue}>
            {`${total?.carbo_sum.toFixed(2)} gram`}
          </Typography>
        </div>

        <div className={classes.cardLine}>
          <div>
            <Typography component="p" variant="body1" className={classes.indicatorName}>
              Eiwitten:
            </Typography>
            <Typography component="p" variant="body2" className={classes.goalName}>
              {`Doel: ${nutritionPlanView?.min_protein ?? ''} - ${nutritionPlanView?.max_protein ?? ''}`}
            </Typography>
          </div>
          <div className={classes.progressBarContainer}>
            <ProgressBar value={getPercent(total?.protein_sum ?? 0, total?.max_protein ?? 0)} />
          </div>

          <Typography component="p" variant="body2" className={classes.indicatorValue}>
            {`${total?.protein_sum.toFixed(2)} gram`}
          </Typography>
        </div>

        <div className={classes.cardLine}>
          <div>
            <Typography component="p" variant="body1" className={classes.indicatorName}>
              Vetten:
            </Typography>
            <Typography component="p" variant="body2" className={classes.goalName}>
              {`Doel: ${nutritionPlanView?.min_fat ?? ''} - ${nutritionPlanView?.max_fat ?? ''}`}
            </Typography>
          </div>

          <div className={classes.progressBarContainer}>
            <ProgressBar value={getPercent(total?.fat_sum ?? 0, total?.max_fat ?? 0)} />
          </div>

          <Typography component="p" variant="body2" className={classes.indicatorValue}>
            {`${total?.fat_sum.toFixed(2)} gram`}
          </Typography>
        </div>
      </Paper>
    </Grid>
  )
}
