import { Button, Grid, Typography } from '@material-ui/core'
import { GetUserByIdDocument } from 'generated/graphql'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { useUpdateUserProfileByPkMutation } from '../../../../../generated/graphql'
import { TUser } from '../../../interfaces'
import Switchable from './Switchable'

interface IProps {
  userInfo: TUser
  editMode: boolean
  onClose: () => void
}

interface IUpdateProps {
  find_us_on: string
  how_to_help: string
}

export const IntakeTab: React.FunctionComponent<IProps> = ({ userInfo, editMode, onClose }) => {
  const { userId } = useParams()
  const [fields, setFields] = useState<IUpdateProps>({
    how_to_help: '',
    find_us_on: '',
  })
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setFields({
      find_us_on: userInfo.user_profile?.find_us_on ?? '',
      how_to_help: userInfo.user_profile?.how_to_help ?? '',
    })
  }, [userInfo])

  const onChange = (event: any): void => {
    event.persist()
    setFields((prevState) => ({ ...prevState, [event.target.name]: event.target.value }))
  }

  const [updateUserProfileByPk] = useUpdateUserProfileByPkMutation()

  const onSave = (): void => {
    updateUserProfileByPk({
      variables: {
        id: userInfo?.user_profile?.id,
        changes: {
          ...fields,
        },
      },
      refetchQueries: [{ query: GetUserByIdDocument, variables: { userId } }],
      awaitRefetchQueries: true,
    })
      .then(() => {
        enqueueSnackbar('Saved successfully', { variant: 'success' })
        onClose()
      })
      .catch(console.error)
  }

  const groupedTextAreas = [
    { name: 'how_to_help', label: 'Hoe kan ik jou het beste helpen?' },
    { name: 'find_us_on', label: 'Hoe ben je bij ons terecht gekomen?' },
  ]

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        {groupedTextAreas.map((item, index) => (
          <Grid key={index} item xs={12} sm={12} md={6}>
            <Typography
              style={{
                fontWeight: 'bold',
              }}
            >
              {item.label}
            </Typography>
            <Switchable
              type="textarea"
              onChange={onChange}
              name={item.name}
              // @ts-ignore
              defaultValue={userInfo?.user_profile[item.name]}
              isEditable={editMode}
            />
          </Grid>
        ))}
      </Grid>

      {editMode && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Button variant="outlined" color="primary" onClick={onClose} fullWidth>
              Annuleren
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button variant="contained" disableElevation color="primary" onClick={onSave} fullWidth>
              Opslaan
            </Button>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default IntakeTab
