import { useConfirmation } from 'context/ConfirmationServiceContext'
import {
  GetKnowledgeBaseByPkDocument,
  useDeleteKnowledgeBaseVideosByPkMutation,
  useInsertKnowledgeBaseVideosMutation,
  useUpdateKnowledgeBaseVideosByPkMutation,
} from 'generated/graphql'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'

import { ICreate, IHook, IUpdate } from './interfaces'

export const useKnowledgeBaseVideos = (callback?: () => void): IHook => {
  const { categoryId } = useParams<{ categoryId: string }>()

  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirmation()

  const handleSuccess = (): void => {
    enqueueSnackbar('saved successfully', { variant: 'success' })
  }

  const handleError = (error: any): void => {
    enqueueSnackbar(error?.message || 'Something went wrong, please try again later...', {
      variant: 'error',
      autoHideDuration: null,
    })
    console.error(error)
  }

  const [insertCategory, { loading: isInserting }] = useInsertKnowledgeBaseVideosMutation()
  const [updateCategory, { loading: isUpdating }] = useUpdateKnowledgeBaseVideosByPkMutation()
  const [deleteCategory, { loading: isDeleting }] = useDeleteKnowledgeBaseVideosByPkMutation()

  const deleteById = (id: number): void => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'You are planning to delete',
      description: 'Are you sure you want to do this? All articles under this category will be deleted too.',
    }).then(() => {
      // eslint-disable-next-line promise/no-nesting
      deleteCategory({
        variables: {
          id,
        },
        refetchQueries: [{ query: GetKnowledgeBaseByPkDocument, variables: { id: +categoryId } }],
        awaitRefetchQueries: true,
      })
        .then(handleSuccess)
        .catch((error) => handleError(error))
    })
  }

  const updateById = (body: IUpdate): void => {
    const { id, knowledgeBaseId, videoUrl, duration, sequence, title } = body
    updateCategory({
      variables: {
        id,
        changes: {
          knowledge_base_id: knowledgeBaseId,
          video_url: videoUrl,
          duration_in_minutes: duration,
          sequence,
          title,
        },
      },
      refetchQueries: [{ query: GetKnowledgeBaseByPkDocument, variables: { id: knowledgeBaseId } }],
      awaitRefetchQueries: true,
    })
      .then(handleSuccess)
      .catch((error) => handleError(error))
      .finally(callback)
  }

  const insert = (body: ICreate): void => {
    const { knowledgeBaseId, videoUrl, duration, sequence, title } = body

    insertCategory({
      variables: {
        objects: [
          {
            knowledge_base_id: knowledgeBaseId,
            video_url: videoUrl,
            duration_in_minutes: duration,
            sequence,
            title,
          },
        ],
      },
      refetchQueries: [{ query: GetKnowledgeBaseByPkDocument, variables: { id: knowledgeBaseId } }],
      awaitRefetchQueries: true,
    })
      .then(handleSuccess)
      .catch((error) => handleError(error))
      .finally(callback)
  }

  const isLoading = isInserting || isUpdating || isDeleting

  return { deleteById, updateById, insert, isLoading }
}
