import { useConfirmation } from 'context/ConfirmationServiceContext'
import {
  GetCookbookDocument,
  useDeleteCookbookCategoryByPkMutation,
  useInsertCookbookCategoryMutation,
  useUpdateCookbookCategoryByPkMutation,
} from 'generated/graphql'
import { useSnackbar } from 'notistack'

interface ICreate {
  name: string
  photo_url: string
}

interface IUpdate {
  id: number
  name: string
  photo_url: string
}

interface IHook {
  isLoading: boolean
  insert: (body: ICreate) => void
  updateById: (body: IUpdate) => void
  deleteById: (id: number) => void
}

export default function useCookbookCategories(callback?: () => void): IHook {
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirmation()

  const handleSuccess = () => {
    enqueueSnackbar('saved successfully', { variant: 'success' })
  }

  const handleError = (error: any) => {
    enqueueSnackbar('Something went wrong, please try again later...', {
      variant: 'error',
    })
    console.error(error)
  }

  const [insertCategory, { loading: isInserting }] = useInsertCookbookCategoryMutation()
  const [updateCategory, { loading: isUpdating }] = useUpdateCookbookCategoryByPkMutation()
  const [deleteCookbookCategory, { loading: isDeleting }] = useDeleteCookbookCategoryByPkMutation()

  const deleteById = (id: number): void => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'You are planning to delete',
      description: 'Are you sure you want to do this? All recipes under this category will be deleted too.',
    }).then(() => {
      deleteCookbookCategory({
        variables: {
          id,
        },
        refetchQueries: [{ query: GetCookbookDocument }],
      })
        .then(handleSuccess)
        .catch((error) => handleError(error))
    })
  }

  const updateById = (body: IUpdate): void => {
    updateCategory({
      variables: {
        id: body.id,
        changes: {
          name: body.name,
          photo_url: body.photo_url,
        },
      },
      refetchQueries: [{ query: GetCookbookDocument }],
    })
      .then(handleSuccess)
      .catch((error) => handleError(error))
      .finally(callback)
  }

  const insert = (body: ICreate): void => {
    insertCategory({
      variables: {
        objects: [body],
      },
      refetchQueries: [{ query: GetCookbookDocument }],
    })
      .then(handleSuccess)
      .catch((error) => handleError(error))
      .finally(callback)
  }

  const isLoading = isInserting || isUpdating || isDeleting

  return { deleteById, updateById, insert, isLoading }
}
