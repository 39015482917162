import { Button, CardMedia, makeStyles, TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import RichTextEditor from 'react-rte'

import ContainerModal from '../../../components/ContainerModal'
import { ICookbookRecipes } from '../../../generated/graphql'
import useCookbookRecipes from '../useCookbookRecipes'

const toolbarConfig: any = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
}

interface IProps {
  closeModal: () => void
  item?: ICookbookRecipes | undefined
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  informationWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  spacingBottom: {
    marginBottom: 10,
  },
  mediaWrapper: {
    display: 'flex',
  },
  deleteBtn: {
    backgroundColor: 'rgb(209,30,53)',
    width: '100%',
  },
}))

const Modal: React.FC<IProps> = ({ closeModal, item }) => {
  const classes = useStyles()
  const { insert, updateById, deleteById, isLoading } = useCookbookRecipes(closeModal)
  const { categoryId } = useParams()

  const [title, setTitle] = useState<string>('')
  const [firstImageUrl, setFirstImageUrl] = useState<string>('')
  const [secondImageUrl, setSecondImageUrl] = useState<string>('')
  const [duration, setDuration] = useState<number>(0)
  const [kcal, setKcal] = useState<number>(0)

  const [editorData, setEditorData] = useState(RichTextEditor.createEmptyValue())

  useEffect(() => {
    if (item) {
      setTitle(item.title)
      setFirstImageUrl(item.photo_urls[0] ?? '')
      setSecondImageUrl(item.photo_urls[1] ?? '')
      setDuration(item.duration ? item.duration : 0)
      setKcal(item.kcal ? item.kcal : 0)
      setEditorData(RichTextEditor.createValueFromString(item.description, 'html'))
    }
  }, [item])

  const onChange = (value: any): void => {
    setEditorData(value)
  }

  const isEmpty = (content: string): boolean => {
    return content.trim().length === 0
  }

  const onSave = () => {
    let photos = `{ ${firstImageUrl} }`
    if (!isEmpty(secondImageUrl)) photos = `{ ${firstImageUrl}, ${secondImageUrl} }`

    const body = {
      title,
      description: editorData.toString('html'),
      duration: duration === 0 ? null : duration,
      kcal: kcal === 0 ? null : kcal,
      photo_urls: photos,
      cookbook_categories_id: categoryId,
    }

    if (item) updateById(item.id, body)
    else insert(body)
  }

  const disableConfirmButton = isEmpty(title) || isEmpty(firstImageUrl) || isLoading

  return (
    <ContainerModal
      title="Voeg recept toe"
      isOpen
      handleClose={closeModal}
      handleSubmit={onSave}
      disabled={disableConfirmButton}
    >
      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Titel*: </Typography>
        <TextField
          multiline
          rows={1}
          variant="outlined"
          size="small"
          value={title}
          onChange={(event) => {
            setTitle(event.target.value)
          }}
        />
      </div>
      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Duur (minuten): </Typography>
        <TextField
          multiline
          rows={1}
          variant="outlined"
          size="small"
          type="number"
          value={duration}
          onChange={(event) => {
            setDuration(Number(event.target.value))
          }}
        />
      </div>
      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Kcal: </Typography>
        <TextField
          multiline
          rows={1}
          variant="outlined"
          size="small"
          type="number"
          value={kcal}
          onChange={(event) => {
            setKcal(Number(event.target.value))
          }}
        />
      </div>
      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Foto url 1*: </Typography>
        <TextField
          multiline
          rows={2}
          variant="outlined"
          size="small"
          value={firstImageUrl}
          onChange={(event) => {
            setFirstImageUrl(event.target.value)
          }}
        />
      </div>
      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Foto url 2: </Typography>
        <TextField
          multiline
          rows={2}
          variant="outlined"
          size="small"
          value={secondImageUrl}
          onChange={(event) => {
            setSecondImageUrl(event.target.value)
          }}
        />
      </div>
      <div className={classes.informationWrapper} style={{ alignItems: 'center' }}>
        <Typography>Voorbeeld: </Typography>
        <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
          <CardMedia
            {...{
              component: 'img',
              image: firstImageUrl,
              style: { width: 100, height: 100, marginRight: 3 },
            }}
          />
          <CardMedia
            {...{
              component: 'img',
              image: secondImageUrl,
              style: { width: 100, height: 100 },
            }}
          />
        </div>
      </div>

      <RichTextEditor
        rootStyle={{
          marginBottom: 10,
        }}
        editorStyle={{
          overflow: 'auto',
          maxHeight: 200,
          minHeight: 100,
        }}
        value={editorData}
        onChange={onChange}
        toolbarConfig={toolbarConfig}
      />

      {item && (
        <Button
          variant="contained"
          color="primary"
          className={classes.deleteBtn}
          onClick={() => deleteById(item.id, categoryId)}
        >
          Verwijder
        </Button>
      )}
    </ContainerModal>
  )
}

export default Modal
