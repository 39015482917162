import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import React from 'react'

interface IChangeProps {
  children: React.ReactElement
  onPress: (values: IInputState) => void
}

export interface IInputState {
  password: string
  confirmPassword: string
  showPassword: boolean
}

const ChangePasswordDialog: React.FC<IChangeProps> = ({ children, onPress }): JSX.Element => {
  const [values, setValues] = React.useState<IInputState>({
    password: '',
    confirmPassword: '',
    showPassword: false,
  })
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
    setValues({
      password: '',
      confirmPassword: '',
      showPassword: false,
    })
  }

  const handleClickShowPassword = (): void => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event: React.MouseEvent): void => {
    event.preventDefault()
  }

  const sendRequest = (): void => {
    // add api call to update profile password
    onPress(values)
    handleClose()
  }

  const showError = Boolean(values.confirmPassword) && values.password !== values.confirmPassword
  const isEmpty = !values.password || !values.confirmPassword

  return (
    <div>
      {React.cloneElement(children, { onClick: handleClickOpen })}
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Wilt u uw wachtwoord wijzigen?</DialogTitle>
        <div style={{ flexDirection: 'column', paddingLeft: 25, paddingRight: 25 }}>
          <div style={{ width: '100%' }}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Wachtwoord</InputLabel>
              <Input
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={(event: React.BaseSyntheticEvent): void => {
                  setValues({
                    ...values,
                    password: event.target.value,
                  })
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div style={{ marginTop: 15 }}>
            <FormControl style={{ width: '100%' }} error={showError}>
              <InputLabel>Bevestig wachtwoord</InputLabel>
              <Input
                type={values.showPassword ? 'text' : 'password'}
                value={values.confirmPassword}
                onChange={(event: React.BaseSyntheticEvent): void => {
                  setValues({
                    ...values,
                    confirmPassword: event.target.value,
                  })
                }}
              />
            </FormControl>
          </div>
          <div />
        </div>
        <DialogActions style={{ marginTop: 15 }}>
          <Button onClick={handleClose} color="primary">
            Annuleren
          </Button>
          <Button onClick={sendRequest} color="primary" disabled={showError || isEmpty}>
            Bevestingen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ChangePasswordDialog
