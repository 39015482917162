import { TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import ContainerModal from '../../../components/ContainerModal'
import { TBlogCategories } from '../interfaces'
import { useStyles } from '../styles'
import useBlogCategories from '../useBlogCategories'

interface IProps {
  closeModal: () => void
  item?: TBlogCategories[0] | undefined
}

const Modal: React.FC<IProps> = ({ closeModal, item }) => {
  const classes = useStyles()
  const { insert, updateById, isLoading } = useBlogCategories(closeModal)

  const [name, setName] = useState<string>('')

  useEffect(() => {
    if (item) setName(item.name)
  }, [item])

  const onSave = (): void => {
    if (item) updateById({ id: item.id, name })
    else insert({ name })
  }

  const disableConfirmButton = isLoading || name.trim().length === 0

  return (
    <ContainerModal
      title={item ? 'Wijzigen' : 'Toevoegen'}
      isOpen
      handleClose={closeModal}
      handleSubmit={onSave}
      disabled={disableConfirmButton}
    >
      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Naam: </Typography>
        <TextField
          multiline
          rows={1}
          variant="outlined"
          size="small"
          value={name}
          onChange={(event): void => {
            setName(event.target.value)
          }}
        />
      </div>
    </ContainerModal>
  )
}

export default Modal
