import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'

import { useConfirmation } from '../../../context/ConfirmationServiceContext'
import { GetExerciseDaysByWorkoutPlanIdDocument, useDeleteExerciseGroupMutation } from '../../../generated/graphql'

interface IHook {
  onDeleteParent: (groupId: number) => void
}

export const useExercises = (): IHook => {
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirmation()
  const [deleteExerciseGroup] = useDeleteExerciseGroupMutation()
  const { workoutPlanId } = useParams<{ workoutPlanId: string }>()

  const onDeleteParent = (groupId: number): void => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'Confirm',
      description: 'Deleting full exercise group',
    }).then(() => {
      // eslint-disable-next-line promise/no-nesting
      deleteExerciseGroup({
        variables: { id: groupId },
        refetchQueries: [
          { query: GetExerciseDaysByWorkoutPlanIdDocument, variables: { workoutPlanId: +workoutPlanId } },
        ],
      })
        .then((response) => {
          if (response.data) enqueueSnackbar('Succesvol verwijdert', { variant: 'success' })
          else enqueueSnackbar('Er ging wat fout', { variant: 'error' })
        })
        .catch(console.error)
    })
  }

  return { onDeleteParent }
}
