/* eslint radix: ["error", "as-needed"] */

import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as React from 'react'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { InputSelect } from '../../components/InputSelect'
import { ProgramCard } from '../../components/ProgramCard'
import { SearchPanel } from '../../components/SearchPanel'
import { useGetProgramPlansBetweenDatesLazyQuery, useGetProgramPlansDatesRangeQuery } from '../../generated/graphql'
import WithDrawer from '../../wrappers'
import { MONTHS } from './constants'

interface IMembersContext {
  year: string
  month: string
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  searchBarContainer: {
    marginBottom: 40,
    paddingTop: 30,
  },
  searchBarWrapper: {
    justifyContent: 'flex-end',
    display: 'flex',
    placeSelf: 'flex-start',
  },
}))

const MEMBERS_PAGE_CONTEXT = 'membersPageContext'

export const MembersPage: React.FunctionComponent = () => {
  const classes = useStyles()
  const breadcrumbData: IBreadcrumb[] = [{ isLink: false, name: 'Members' }]

  const [years, setYears] = React.useState([
    {
      value: new Date().getFullYear().toString(),
      label: new Date().getFullYear().toString(),
    },
  ])

  const [searchString, setSearchString] = React.useState('')

  const [year, setYear] = React.useState<string>(String(new Date().getFullYear()))
  const [month, setMonth] = React.useState<string>(String(new Date().getMonth() + 1))

  const onYearOrMonthChangeHandler = React.useCallback(
    (key: 'year' | 'month', newValue: string) => {
      const membersPageContext = {
        year,
        month,
      } as IMembersContext
      membersPageContext[key] = newValue

      window.localStorage.setItem(MEMBERS_PAGE_CONTEXT, JSON.stringify(membersPageContext))
      if (key === 'year') setYear(newValue)
      if (key === 'month') setMonth(newValue)
    },
    [month, year]
  )

  // first added
  React.useEffect(() => {
    const membersPageContext = window.localStorage.getItem(MEMBERS_PAGE_CONTEXT)
    if (membersPageContext) {
      try {
        const parsed = JSON.parse(membersPageContext) as IMembersContext

        setYear(years.some((obj) => obj.value === parsed.year) ? parsed.year : '')
        setMonth(parsed.month)
      } catch (error) {
        console.error(error)
      }
    } else {
      onYearOrMonthChangeHandler('year', String(new Date().getFullYear()))
      onYearOrMonthChangeHandler('month', String(new Date().getMonth() + 1))
    }
  }, [onYearOrMonthChangeHandler, years])

  const { data: getYearsResults } = useGetProgramPlansDatesRangeQuery()

  const [getProgramPlanQuery, programPlanResult] = useGetProgramPlansBetweenDatesLazyQuery()

  const data = programPlanResult?.data

  // @ts-ignore
  const filtered = data?.program_plans?.filter((item) => item?.program?.name?.indexOf(searchString) !== -1)

  React.useEffect(() => {
    const startDate = getYearsResults?.program_plans_aggregate?.aggregate?.min?.starts_at
    const endDate = getYearsResults?.program_plans_aggregate?.aggregate?.max?.starts_at

    if (startDate && endDate) {
      const startYear = new Date(startDate).getFullYear()
      const endYear = new Date(endDate).getFullYear()
      const yearsList: { value: string; label: string }[] = []

      for (let i = startYear; i <= endYear; i++) {
        yearsList.push({
          value: i.toString(),
          label: i.toString(),
        })
      }

      setYears(yearsList)
    }
  }, [getYearsResults])

  React.useEffect(() => {
    if (year.trim().length && month.trim().length) {
      getProgramPlanQuery({
        variables: {
          _gte: new Date(parseInt(year), parseInt(month) - 1, 1),
          _lt: new Date(parseInt(year), parseInt(month), 1),
        },
      })
    }
  }, [getProgramPlanQuery, month, year])

  return (
    <WithDrawer breadcrumbData={breadcrumbData}>
      <Box
        {...{
          component: 'div',
          className: classes.container,
        }}
      >
        <Grid container spacing={6} className={classes.searchBarContainer}>
          <Grid style={{ display: 'flex' }} item sm={12} md={6}>
            <InputSelect
              value={year}
              onChange={(value): void => onYearOrMonthChangeHandler('year', value)}
              label="Year"
              options={years}
            />

            <InputSelect
              value={month}
              onChange={(value): void => onYearOrMonthChangeHandler('month', value)}
              label="Month"
              options={MONTHS}
            />
          </Grid>

          <Grid item sm={12} md={6} className={classes.searchBarWrapper}>
            <SearchPanel placeholder="Search plan" onSubmit={(value): void => setSearchString(value)} />
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          {filtered?.map((item, index) => (
            <ProgramCard
              key={index}
              {...{
                data: item,
              }}
            />
          ))}
        </Grid>
      </Box>
    </WithDrawer>
  )
}
