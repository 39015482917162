// PrivateRoute.tsx
import React, { useContext } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { AuthContext } from '../context/auth.context'

interface IProps extends RouteProps {
  requiredRoles: string[]
}

const PrivateRoute: React.FC<IProps> = ({ requiredRoles, children, ...rest }) => {
  const { role } = useContext(AuthContext)

  if (!role) return <Redirect to={{ pathname: '/' }} />

  const userHasRequiredRole = requiredRoles.includes(role)

  if (!userHasRequiredRole) return <Redirect {...{ to: '/' }} />

  return <Route {...rest}>{children}</Route>
}

export default PrivateRoute
