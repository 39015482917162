import { debounce, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import React, { useContext } from 'react'

import { SearchPanel } from '../../../components/SearchPanel'
import { AuthContext } from '../../../context/auth.context'
import { useGetCoachMembersLazyQuery, useInsertInboxMutation } from '../../../generated/graphql'
import { IProps } from './interfaces'

export const ClientsModal: React.FC<IProps> = ({ onClose }): JSX.Element => {
  const { userId } = useContext(AuthContext)
  const [getMembersQuery, { data, loading }] = useGetCoachMembersLazyQuery()

  const [insertInbox] = useInsertInboxMutation()

  const submit = (clientId: number): void => {
    if (!userId) return

    insertInbox({
      variables: {
        coachId: +userId,
        clientId,
      },
    })
      .then(() => {
        console.log(`Inbox created - client_id: ${clientId} - coach_id: ${userId}`)
        onClose()
      })
      .catch((error) => console.error(error))
  }

  const searchHandler = debounce((newValue: string): void => {
    if (!newValue.length || !userId) return
    getMembersQuery({
      variables: {
        coachId: userId,
        ilike: `%${newValue}%`,
      },
    })
  }, 750)

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">Lid zoeken</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Staat de lid niet in je inbox? Zoek dan hier op basis van naam en/of achternaam.
        </DialogContentText>

        <SearchPanel onSubmit={searchHandler} placeholder="Zoek lid" showButton={false} fullWidth />

        <div style={{ height: '300px', overflow: 'auto' }}>
          {loading && <div>Bezig met laden...</div>}
          {!loading &&
            data?.user_coach_view.map((member) => (
              <div
                key={member.client_id}
                style={{
                  marginTop: 10,
                  padding: 10,
                  border: '1px solid #e3e3e3',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3 style={{ margin: 0 }}>{`${member.client_first_name} ${member.client_last_name}`}</h3>
                <Button color="primary" variant="contained" onClick={(): void => submit(member.client_id)}>
                  Start gesprek
                </Button>
              </div>
            ))}
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
