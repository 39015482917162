import { Paper } from '@material-ui/core'
import clsx from 'clsx'
import React, { useState } from 'react'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { useGetRevieableProductsQuery } from '../../generated/graphql'
import WithDrawer from '../../wrappers'
import { useStyles } from '../CookbookPage/styles'
import { Product } from './Product'
import { ProductModal } from './ProductModal'
import { TReviewProduct } from './ProductModal/interfaces'

/*
  Page for reviewing products
 */

const Products = (): JSX.Element => {
  const breadcrumbData: IBreadcrumb[] = [{ isLink: false, name: 'RECIPES' }]
  const [selectedProduct, setSelectedProduct] = useState<TReviewProduct>()
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  const { data } = useGetRevieableProductsQuery()
  const products = data?.products_rg_review

  return (
    <WithDrawer breadcrumbData={breadcrumbData}>
      <Paper
        {...{
          elevation: 0,
          className: fixedHeightPaper,
          style: {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            boxShadow: '2px 2px 21px rgba(0, 0, 0, 0.06)',
          },
        }}
      >
        <div style={{ padding: 20 }}>
          {products?.map((item) => (
            <div key={item.id}>
              <Product product={item} onClick={(): void => setSelectedProduct(item)} />
            </div>
          ))}
        </div>

        {selectedProduct && (
          <ProductModal onClose={(): void => setSelectedProduct(undefined)} product={selectedProduct} />
        )}
      </Paper>
    </WithDrawer>
  )
}

export default Products
