import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { StyledTableCell } from 'components/Table'
import { ICookbookCategories } from 'generated/graphql'
import React from 'react'

import CookbookTableRow from './CookbookTableRow'

interface IProps {
  categories: ICookbookCategories[]
  onUpdate: (category: ICookbookCategories) => void
  onDelete: (id: number) => void
}

const CategoriesTable: React.FunctionComponent<IProps> = ({ categories, onUpdate, onDelete }) => {
  return (
    <TableContainer component={Paper} elevation={0} style={{ maxHeight: 550 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell>Naam</StyledTableCell>
            <StyledTableCell>Aantal recepten</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody {...{ style: { boxShadow: '2px 2px 21px rgb(0 0 0 / 6%)' } }}>
          {[...categories]
            .sort((first, second) => first.id - second.id)
            .map((row: ICookbookCategories, index: number) => (
              <CookbookTableRow key={index} category={row} onUpdate={onUpdate} onDelete={onDelete} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CategoriesTable
