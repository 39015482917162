import { EventTracker } from '@devexpress/dx-react-chart'
import { Animation } from '@devexpress/dx-react-chart'
import { ArgumentAxis, Chart, LineSeries, Tooltip, ValueAxis } from '@devexpress/dx-react-chart-material-ui'
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Grid,
  GridList,
  GridListTile,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import clsx from 'clsx'
import { addMonths, format } from 'date-fns'
import { nl } from 'date-fns/locale'
import moment from 'moment'
import * as React from 'react'
import { useState } from 'react'
import { useCloudinary } from 'utils/useCloudinary'

import { InputSelectTabs } from '../../../components/InputSelectTabs'
import { useAuth } from '../../../utils/useAuth'
import { useHttp } from '../../../utils/useHTTP'
import { TUser } from '../interfaces'
import { useStyles } from '../useStyles'

interface IProgressieTabProps {
  userInfo: TUser
  navigateToTab: (tab: number) => void
}

const createData = (name: string, value: any) => {
  return { name, value }
}

// TEST DATA
const formatTick = () => (tick: any) => tick

const TABS = [
  { label: 'Gewicht', value: 'body_weight' },
  { label: 'Borst', value: 'chest' },
  { label: 'Taille', value: 'waist' },
  { label: 'Heupen', value: 'hips' },
  { label: 'Dijen', value: 'thigh' },
]

const dateFormat = 'dd-MM-yyyy'
// eslint-disable-next-line sonarjs/cognitive-complexity
export const ProgressieTab: React.FunctionComponent<IProgressieTabProps> = ({ userInfo, navigateToTab }) => {
  const { token } = useAuth()
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)
  const weeks = userInfo?.progress_weeks || []
  const sortedProgramPlans =
    userInfo?.program_plan_participators
      .slice()
      .sort(
        (first, second) =>
          new Date(first.program_plan.starts_at).getTime() - new Date(second.program_plan.starts_at).getTime()
      ) || []

  const weeksOrdered = weeks.slice()
  const [avatarUrl, setAvatarUrl] = React.useState<string | null>(null)
  const [photos, setPhotos] = React.useState<string[]>([])
  const [selectedTab, setSelectedTab] = React.useState(TABS[0].value)
  const [weekNumber, setWeekNumber] = React.useState(weeksOrdered.length || 1)

  const { getImagesByPublicIds } = useCloudinary()

  // Change this to start_weight of voedingssamenstelling.
  const selectedWeek = weeksOrdered[weekNumber - 1]
  const progressDays = selectedWeek?.progress_days ?? []
  const lastProgressDay = progressDays[progressDays.length - 1]
  const lastMeasuredWeight = lastProgressDay?.body_weight

  React.useEffect(() => {
    setWeekNumber(weeksOrdered.length || 1)
  }, [weeksOrdered.length])

  const getImagesFromCloudinary = async (imageIds: string[]): Promise<any> => {
    return getImagesByPublicIds(imageIds)
      .then((res) =>
        res.json().then((data) => {
          const images = data?.resources.map((cloudinaryImg: any) => cloudinaryImg.secure_url)
          return [...images]
        })
      )
      .catch((err) => {
        console.error(err)
        return []
      })
  }

  React.useEffect(() => {
    setPhotos([])
    const imagePublicIds: any[] = []

    selectedWeek?.progress_days?.forEach((pd) => {
      imagePublicIds.push(pd.front_image, pd.side_image, pd.back_image)
    })

    const filteredList = imagePublicIds.filter((imgUrl) => imgUrl!)

    const asyncImagesFetcher = async () => {
      const result = await getImagesFromCloudinary(filteredList)
      setPhotos(result)
    }

    if (filteredList.length > 0) asyncImagesFetcher()
  }, [selectedWeek])

  const updateWeekNumber = (num: number): void => {
    if (num && num <= weeksOrdered.length) setWeekNumber(num)
  }

  // @ts-ignore
  const chartData = weeksOrdered.map((week, idx) => ({
    week_number: idx + 1,
    ...week.progress_days[week.progress_days.length - 1],
  }))

  const subscriptionDates = ((): { start: string; end: string } => {
    const firstProgramPlan = sortedProgramPlans[0]?.program_plan
    const lastProgramPlan = sortedProgramPlans[sortedProgramPlans.length - 1]?.program_plan
    if (!firstProgramPlan?.starts_at && !lastProgramPlan?.starts_at) return { start: '-', end: '-' }
    return {
      start: format(new Date(firstProgramPlan.starts_at), dateFormat, { locale: nl }),
      end: format(
        addMonths(new Date(lastProgramPlan.starts_at), lastProgramPlan.program.duration_amount || 0),
        dateFormat,
        { locale: nl }
      ),
    }
  })()

  const rows = [
    createData('Lid:', userInfo?.id),
    createData('Geslacht:', userInfo?.gender === 'F' ? 'Vrouw' : 'Man'),

    createData(
      'Leeftijd:',
      userInfo?.user_profile?.date_of_birth
        ? moment().diff(moment(userInfo?.user_profile?.date_of_birth), 'years', false)
        : '-'
    ),
    createData('Lengte:', `${userInfo?.user_profile?.initial_height || '-'} cm`),
    createData('Gewicht:', `${userInfo?.user_profile?.initial_weight || '-'} kg`),
    createData('Start:', `${subscriptionDates?.start}`),
    createData('Einde:', `${subscriptionDates?.end}`),
  ]

  React.useEffect(() => {
    const asyncImagesFetcher = async (avatar: string) => {
      const result = await getImagesFromCloudinary([avatar])
      const image = result.length > 0 ? result[0] : null
      setAvatarUrl(image)
    }
    if (userInfo.avatar_url) asyncImagesFetcher(userInfo.avatar_url)
  }, [userInfo])

  const { request, loading } = useHttp()

  const sendIntakeRequest = async (): Promise<void> => {
    const send = { user_id: userInfo.id }
    const data = await request('https://admin.rg.qraft.eu/users/intake_request/', 'POST', send, {
      authorization: `Bearer ${token}`,
    })
    if (data.success) alert(data.success)
    else alert('Something went wrong!')
  }

  const [showImage, setShowImage] = useState<string>()

  // @ts-ignore
  return (
    <Paper
      {...{
        className: fixedHeightPaper,
        elevation: 0,
        style: {
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          boxShadow: '2px 2px 21px rgba(0, 0, 0, 0.06)',
        },
      }}
    >
      <Grid container className={classes.gridWrapper}>
        <Grid item xs={12} md={12} lg={3} className={classes.profileDetails}>
          <Box
            {...{
              component: 'div',
              className: classes.profilePhoto,
            }}
          >
            <Avatar
              {...{
                alt: 'ALT',
                src: avatarUrl || './images/user_photo.png',
                className: classes.userPhoto,
              }}
            />
          </Box>

          <Typography
            {...{
              variant: 'h5',
              component: 'h5',
            }}
          >
            {`${userInfo?.first_name || ''} ${userInfo?.last_name || ''}`}
          </Typography>

          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <ButtonGroup>
            <Button
              color="primary"
              variant="contained"
              className={classes.containedBtn}
              onClick={(): void => navigateToTab(4)}
            >
              Volledig profiel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.containedBtn}
              onClick={sendIntakeRequest}
              disabled={loading}
            >
              Intake verzoek versturen
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={12} md={12} lg={9} style={{ padding: '0px 30px' }}>
          <Grid container spacing={4} style={{ marginBottom: 20 }}>
            <Grid xs={12} item md={12} lg={8} style={{ paddingLeft: 0, paddingRight: 0 }}>
              <InputSelectTabs value={selectedTab} onChange={(tab): void => setSelectedTab(tab)} options={TABS} />
            </Grid>

            <Grid item xs={12} md={12} lg={4}>
              <div className={classes.slider}>
                <KeyboardArrowLeftIcon
                  className={classes.sliderArrow}
                  onClick={(): void => updateWeekNumber(weekNumber - 1)}
                />
                <Typography>{`Week ${weekNumber}`}</Typography>
                <KeyboardArrowRightIcon
                  className={classes.sliderArrow}
                  onClick={(): void => updateWeekNumber(weekNumber + 1)}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={8} className={classes.chart}>
              <Chart data={chartData}>
                <ArgumentAxis tickFormat={formatTick} />
                <ValueAxis />
                <LineSeries color="#E11919" name="Gewicht" valueField={selectedTab} argumentField="week_number" />
                <EventTracker />
                <Tooltip />
                <Animation />
              </Chart>
            </Grid>

            {/* WEEK WRAPPER START */}
            <Grid item xs={12} md={12} lg={4}>
              {/* TODO - HERE WEEK SWITCHER */}
              <Typography
                {...{
                  variant: 'h6',
                  component: 'div',
                  className: classes.tableTitle,
                  style: {
                    marginTop: 0,
                  },
                }}
              >
                Lichaamsgewicht:
              </Typography>
              <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Start Gewicht:
                      </TableCell>
                      <TableCell align="right">
                        {/* Voedingsssamenstelling[0].weight */}
                        {`${userInfo.user_profile?.initial_weight || '-'} kg`}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        Huidig Gewicht:
                      </TableCell>
                      <TableCell align="right">{`${lastMeasuredWeight || '-'} kg`}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography
                {...{
                  variant: 'h6',
                  component: 'div',
                  className: classes.tableTitle,
                }}
              >
                Lichaams afmetingen:
              </Typography>
              <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Borst:
                      </TableCell>
                      <TableCell align="right">{`${lastProgressDay?.chest || '-'}`}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        Taille:
                      </TableCell>
                      <TableCell align="right">{`${lastProgressDay?.waist || '-'}`}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        Heupen:
                      </TableCell>
                      <TableCell align="right">{`${lastProgressDay?.hips || '-'} cm`}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        Dijen:
                      </TableCell>
                      <TableCell align="right">{`${lastProgressDay?.thigh || '-'} cm`}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        Gemeten op:
                      </TableCell>
                      <TableCell align="right">
                        {`${
                          lastProgressDay?.updated_at ? format(new Date(lastProgressDay.updated_at), 'dd-MM-yyyy') : '-'
                        }`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12} md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Typography
                {...{
                  style: { paddingTop: 10, paddingBottom: 20, fontWeight: 500 },
                  variant: 'h5',
                  component: 'h5',
                }}
              >
                {`Week ${weekNumber}`}
              </Typography>
              <GridList className={classes.gridList} cols={2.5}>
                {photos.map((item, index) => {
                  return (
                    <GridListTile
                      key={index}
                      classes={{
                        root: classes.weeklyUpdate,
                        tile: classes.tileImage,
                      }}
                      style={{ cursor: 'pointer' }}
                      onClick={(): void => setShowImage(item)}
                    >
                      <img alt="asd" src={item} width="100" height="100" />
                      {showImage && (
                        <Dialog fullWidth onClose={(): void => setShowImage(undefined)} open={!!showImage}>
                          <img alt="asd" src={showImage} />
                        </Dialog>
                      )}
                    </GridListTile>
                  )
                })}
              </GridList>
            </Grid>
            {/* WEEK WRAPPER END */}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
