import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { AuthContext } from 'context/auth.context'
import React, { useContext } from 'react'

import { createClient } from './ApolloProvider'

export interface IApolloClientContext {
  apolloClient?: ApolloClient<NormalizedCacheObject>
}

export const ApolloClientContext = React.createContext<IApolloClientContext>({
  apolloClient: undefined,
})

interface IProps {
  children: React.ReactNode
}

export const ApolloClientProvider: React.FC<IProps> = ({ children }): JSX.Element | null => {
  const { token } = useContext(AuthContext)

  if (!token) return null
  const apolloClient = createClient(token)

  return (
    <ApolloClientContext.Provider value={{ apolloClient }}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </ApolloClientContext.Provider>
  )
}
