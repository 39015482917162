import { Box, Button, Grid, makeStyles } from '@material-ui/core'
import { Loading } from 'components/Loading'
import { SearchPanel } from 'components/SearchPanel'
import { NotCreated } from 'pages/UserDetailedPage/components/NotCreated'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import WithDrawer from 'wrappers'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { ICookbookRecipes, useGetCookbookCategoryByIdQuery } from '../../generated/graphql'
import Modal from './components/Modal'
import RecipeCard from './components/RecipeCard'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },

  containedBtn: {
    marginLeft: 'auto',
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    marginRight: 10,
  },
}))

export default function CookbookRecipesPage() {
  const classes = useStyles()
  const { categoryId }: any = useParams()

  const [searchString, setSearchString] = useState<string>('')
  const [isModalOpened, setModalOpened] = useState<boolean>(false)
  const [targetRecipe, setTargetRecipe] = useState<ICookbookRecipes | undefined>(undefined)

  const { data, loading } = useGetCookbookCategoryByIdQuery({
    variables: {
      categoryId,
    },
  })

  const closeModal = () => {
    setModalOpened(false)
    setTargetRecipe(undefined)
  }

  const breadcrumbData: IBreadcrumb[] = [
    { isLink: true, name: 'RECIPES', to: '/cookbook' },
    { isLink: false, name: data?.cookbook_categories_by_pk?.name ?? '-' },
  ]

  const filtered = data?.cookbook_categories_by_pk?.cookbook_recipes.filter(
    (item) => item.title.indexOf(searchString) !== -1
  )

  const showModal = (recipe: ICookbookRecipes) => {
    setTargetRecipe(recipe)
    setModalOpened(true)
  }

  const hasRecipes: boolean = !!filtered && filtered.length > 0
  return (
    <WithDrawer breadcrumbData={breadcrumbData}>
      {loading && <Loading />}

      <Box
        {...{
          component: 'div',
          className: classes.container,
        }}
      >
        {hasRecipes ? (
          <React.Fragment>
            <div style={{ display: 'flex', marginBottom: 20 }}>
              <SearchPanel placeholder="Search plan" onSubmit={(value) => setSearchString(value)} />

              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.containedBtn}
                onClick={() => {
                  setModalOpened(true)
                }}
              >
                + Voeg recept toe
              </Button>
            </div>
            <Grid container>
              {filtered!.map((item) => (
                <RecipeCard key={item.id} recipe={item as ICookbookRecipes} onClick={showModal} />
              ))}
            </Grid>
          </React.Fragment>
        ) : (
          <NotCreated
            onPress={() => setModalOpened(true)}
            primary="Geen recepten gevonden"
            secondary="Op deze pagina vind je een overzicht van recepten om te beheren."
            buttonText="Recept aanmaken"
            image="../../images/create_nutritions.png"
          />
        )}

        <Grid container />
      </Box>

      {isModalOpened && <Modal item={targetRecipe} closeModal={closeModal} />}
    </WithDrawer>
  )
}
