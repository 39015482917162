import { InputAdornment, Menu, MenuItem, TextField } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import groupBy from 'lodash/groupBy'
import NestedMenuItem from 'material-ui-nested-menu-item'
import React, { useEffect, useState } from 'react'

import {
  useGetAllProductsAndCategoriesQuery,
  useGetProductByIdLazyQuery,
  useGetProductsListByLazyQuery,
} from '../../../generated/graphql'
import { IProps, TProduct } from './interfaces'
import { useStyles } from './styles'
import useMealSelector from './useMealSelector'

export const MealSelector: React.FC<IProps> = ({ productId, onChange }): JSX.Element => {
  const ownClasses = useStyles()

  const { filterRef, moveFocusToInput } = useMealSelector()

  // useState
  const [selectedProduct, setSelectedProduct] = useState<TProduct>()
  const [filterString, setFilterString] = React.useState<string>('')
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  // Query hooks
  const [getProductById, { data: productData }] = useGetProductByIdLazyQuery()
  const { data: productsData } = useGetAllProductsAndCategoriesQuery()
  const [getProductsQuery, productsResults] = useGetProductsListByLazyQuery()

  // Custom functions
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {}, [filterString])

  const searchHandler = (newValue: string): void => {
    setFilterString(newValue)
    if (newValue.length === 0) return
    getProductsQuery({
      variables: { _like: `%${newValue}%` },
    })
  }

  const onMenuItemClickHandler = (item: TProduct): void => {
    onChange(item)
    setSelectedProduct(item)
    setFilterString(item.name)
    setAnchorEl(null)
  }

  const stopPropagation = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break
      default:
        event.stopPropagation()
    }
  }

  // Custom variables
  const isOpen = Boolean(anchorEl)
  const isFiltering = filterString.trim().length > 0
  const products = productsData?.products_rg
  const groupedProducts = Object.entries(groupBy(products, 'product_category.name')) || []
  const filteredProducts = productsResults.data?.products_rg

  // useEffects
  useEffect(() => {
    if (productId) {
      getProductById({
        variables: {
          id: productId,
        },
      })
    }
  }, [getProductById, productId])

  useEffect(() => {
    const product = productData?.products_rg_by_pk
    if (product) setSelectedProduct(product)
  }, [productData?.products_rg_by_pk])

  return (
    <React.Fragment>
      <TextField
        {...{
          className: ownClasses.productTextField,
          size: 'small',
          value: selectedProduct?.name ?? 'Select',
          onClick: handleClick,
        }}
        InputProps={{
          disableUnderline: true,
          classes: {
            inputMarginDense: ownClasses.inputMarginDense,
          },
          className: ownClasses.productInputField,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon style={{ color: '#AEAEAE' }} />
            </InputAdornment>
          ),
        }}
      />
      <Menu
        style={{ height: 400 }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={(): void => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem autoFocus={false} button={false} onKeyDown={moveFocusToInput}>
          <TextField
            inputRef={filterRef}
            variant="outlined"
            size="small"
            label="Type to filter..."
            value={filterString}
            onKeyDown={stopPropagation}
            onChange={(event): void => searchHandler(event.target.value)}
          />
        </MenuItem>
        {!isFiltering
          ? groupedProducts.map((el, idx) => {
              const category = el[0]
              const food = el[1]

              return (
                <NestedMenuItem
                  key={idx}
                  label={category}
                  parentMenuOpen={isOpen}
                  style={{ maxHeight: 22, justifyContent: 'space-between' }}
                >
                  {food.map((item, index) => (
                    <MenuItem style={{ maxHeight: 22 }} key={index} onClick={(): void => onMenuItemClickHandler(item)}>
                      {item.name}
                    </MenuItem>
                  ))}
                </NestedMenuItem>
              )
            })
          : filteredProducts?.map((item: any, index: number) => (
              <MenuItem style={{ maxHeight: 22 }} key={index} onClick={(): void => onMenuItemClickHandler(item)}>
                {item.name}
              </MenuItem>
            ))}
      </Menu>
    </React.Fragment>
  )
}
