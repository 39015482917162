import { Box, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import * as React from 'react'

const useStyles = makeStyles(() => ({
  input: {
    fontSize: '14px',
    paddingLeft: '10px',
    paddingRight: '40px !important',
    '&.MuiSelect-select:focus': {
      backgroundColor: '#fff',
    },
  },
  selectPanel: {
    maxWidth: 365,
    paddingRight: '15px',
    display: 'flex',
    position: 'relative',
  },
  textField: {
    fontSize: '14px',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: '#fff',
    '& div::before': {
      border: 'none !important',
    },
    '& div::after': {
      border: 'none !important',
    },
    '&focus': {
      backgroundColor: '#fff !important',
    },
  },
  label: {
    padding: '0 15px',
    fontSize: 12,
    color: '#AEAEAE',
    position: 'absolute',
    top: '-21px',
    left: '-12px',
  },
}))

interface IOption {
  value: string
  label: string
}

interface IInputSelectProps {
  value: string
  label: string
  options: IOption[]
  onChange: (value: string) => void
  className?: any
}

export const InputSelect: React.FunctionComponent<IInputSelectProps> = ({
  value = '',
  label = '',
  options,
  onChange = (): void => {},
}) => {
  const classes = useStyles()
  return (
    <Box {...{ component: 'div', className: classes.selectPanel }}>
      <Typography
        {...{
          component: 'p',
          variant: 'body1',
          className: classes.label,
        }}
      >
        {label}
      </Typography>
      <TextField
        className={classes.textField}
        value={value}
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        onChange={(event: React.BaseSyntheticEvent): void => onChange(event.target.value)}
        select
      >
        {options.map((item: IOption) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  )
}
