import { Button, IconButton, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { StyledTableCell, StyledTableRow } from 'components/Table'
import React from 'react'
import { Link } from 'react-router-dom'

import { Loading } from '../../../components/Loading'
import { TKnowledgeBaseVideos } from '../interfaces'
import { useKnowledgeBaseVideos } from '../useKnowledgeBaseVideos'

const useScopedStyles = makeStyles(() => ({
  tableButton: {
    backgroundColor: '#fff',
    color: '#3F51B5',
    textTransform: 'none',
    padding: '4px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    border: '1px solid #3F51B5',
  },
  actionsCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  moreIconWrapper: {
    marginLeft: 15,
  },
  iconHack: {
    position: 'relative',
    left: 15,
  },
  menuIcon: {
    marginRight: 10,
  },
}))

interface IProps {
  category: TKnowledgeBaseVideos[0]
  onUpdate: (category: TKnowledgeBaseVideos[0]) => void
}

const CategoriesRow: React.FunctionComponent<IProps> = ({ category, onUpdate }) => {
  const { deleteById, isLoading } = useKnowledgeBaseVideos()

  const scopedClasses = useScopedStyles()
  const [anchorEl, setAnchorEl] = React.useState(null as any)
  const isMenuOpened = Boolean(anchorEl)

  const _onMenuClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const _onMenuClose = (): void => {
    setAnchorEl(null)
  }

  const _onMenuItemSelected = (callback = (): void => {}): void => {
    _onMenuClose()
    callback()
  }

  if (isLoading) {
    return (
      <div style={{ zIndex: 10 }}>
        <Loading />
      </div>
    )
  }

  return (
    <StyledTableRow>
      <StyledTableCell>{category.id}</StyledTableCell>
      <StyledTableCell>{category.title}</StyledTableCell>
      <StyledTableCell>{category.sequence}</StyledTableCell>
      <StyledTableCell>{category.duration_in_minutes}</StyledTableCell>
      <StyledTableCell className={scopedClasses.actionsCell} align="right">
        <div className={scopedClasses.moreIconWrapper}>
          <IconButton aria-label="more" aria-controls="action-menu" aria-haspopup="true" onClick={_onMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu id="action-menu" anchorEl={anchorEl} keepMounted open={isMenuOpened} onClose={_onMenuClose}>
            <MenuItem onClick={(): void => _onMenuItemSelected(() => onUpdate(category))}>
              <EditIcon className={scopedClasses.menuIcon} />
              Edit
            </MenuItem>

            <MenuItem onClick={(): void => _onMenuItemSelected(() => deleteById(category.id))}>
              <DeleteIcon className={scopedClasses.menuIcon} />
              Remove
            </MenuItem>
          </Menu>
        </div>

        <Link
          {...{
            style: { textDecoration: 'none' },
            to: {
              pathname: category.video_url,
            },
            target: '_blank',
          }}
        >
          <Button variant="outlined" size="small" className={scopedClasses.tableButton}>
            Bekijk
            <ChevronRightIcon className={scopedClasses.iconHack} />
            <ChevronRightIcon />
          </Button>
        </Link>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default CategoriesRow
