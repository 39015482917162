import { Backdrop, Box, CircularProgress, Container } from '@material-ui/core'
import React from 'react'

export const Loading: React.FC = (): JSX.Element | null => {
  return (
    <Backdrop open>
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexWrap="nowrap">
          <Box display="flex" p={2}>
            <img alt="Logo Ruggengraat" src="../images/login_logo.png" />
          </Box>
          <Box display="flex">
            <CircularProgress disableShrink color="primary" />
          </Box>
        </Box>
      </Container>
    </Backdrop>
  )
}
