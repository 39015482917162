import { Button, FormControl, Grid, InputLabel, Menu, MenuItem, Paper, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import List from '@material-ui/icons/List'
import clsx from 'clsx'
import { useConfirmation } from 'context/ConfirmationServiceContext'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useParams } from 'react-router'

import {
  IGetNutritionPlanByQuery,
  INutritionPlans,
  useDeleteNutritionPlanByPkMutation,
  useGetNutritionPlanByQuery,
  useInsertNutritionPlanMutation,
  useUpdateNutritionPlanByPkMutation,
} from '../../../../generated/graphql'
import FaseToePopUp from '../../components/FaseToePopUp'
import { useStyles } from '../../useStyles'
import { VoedingsschemaTable } from './components/Table'
import { SORTABLE_COLUMNS } from './helpers'
import { ITableItem } from './interfaces'
import { NutritionPlanMeals } from './NutritionPlanMeals'

const useScopedStyles = makeStyles(() => ({
  gridWrapper: {
    height: '100%',
    padding: '10px 63px',
  },
  tableContainer: {
    marginTop: 30,
  },
  menuIcon: {
    marginRight: 10,
  },
}))

type TNutritionPlan = NonNullable<IGetNutritionPlanByQuery['nutrition_plans'][0]>

// eslint-disable-next-line sonarjs/cognitive-complexity
export const VoedingsschemaTab = (): JSX.Element => {
  const classes = useStyles()
  const [showClonesOfId, setShowClonesOfId] = React.useState<number>()
  const { userId } = useParams<{ userId: string }>()
  const { enqueueSnackbar } = useSnackbar()
  const scopedClasses = useScopedStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  const [sortBy, setSortBy] = React.useState(SORTABLE_COLUMNS.start_date)
  const [plans, setNutritionPlans] = React.useState<INutritionPlans[]>([])

  const [editingItem, setEditingItem] = React.useState<TNutritionPlan | null>(null)

  const [anchorEl, setAnchorEl] = React.useState(null as any)
  const isMenuOpened = Boolean(anchorEl)

  const [isModalOpened, setModalOpened] = React.useState(false)

  const getNutritionPlanQuery = useGetNutritionPlanByQuery({
    variables: { userId: +userId },
  })

  const { data } = getNutritionPlanQuery

  const [deleteNutritionPlan] = useDeleteNutritionPlanByPkMutation()
  const [updateNutritionPlan] = useUpdateNutritionPlanByPkMutation()
  const [insertNutritionPlan] = useInsertNutritionPlanMutation()

  const sort = (array: [ITableItem]) => {
    const comparator =
      [SORTABLE_COLUMNS.start_date.value, SORTABLE_COLUMNS.end_date.value].indexOf(sortBy.value) !== -1
        ? (first: string, second: string) => new Date(first).getTime() - new Date(second).getTime()
        : (first: number, second: number) => first - second

    // @ts-ignore
    return array.slice().sort((first: ITableItem, second: ITableItem) => {
      // @ts-ignore
      return comparator(first[sortBy.value], second[sortBy.value])
    })
  }

  React.useEffect(() => {
    if (data?.nutrition_plans?.length) {
      // @ts-ignore
      setNutritionPlans(sort(data.nutrition_plans))
    }
  }, [data, sortBy])

  const _onMenuClick = (event: React.MouseEvent<HTMLButtonElement>, item: any) => {
    setEditingItem(item)
    setAnchorEl(event.currentTarget)
  }

  const _onMenuClose = () => {
    setAnchorEl(null)
  }

  const _onMenuItemSelected = (callback = () => {}) => {
    _onMenuClose()
    callback()
  }

  const confirm = useConfirmation()
  const _onDelete = (toRemove: any) => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'Do you really want to remove the plan',
      description: 'Are you sure you want to do this?',
    }).then(() => {
      deleteNutritionPlan({
        variables: {
          id: toRemove.id,
        },
      })
        .then(() => {
          enqueueSnackbar('Deleted successfully', { variant: 'success' })
          getNutritionPlanQuery.refetch()
        })
        .catch(console.log)
      // .finally(() => setToRemove(null))
    })
  }

  const _prepareToDelete = () => {
    _onMenuClose()
    if (editingItem) {
      _onDelete(editingItem)
      setEditingItem(null)
    }
  }

  const _onModalSubmit = (incomingData: any, cloneMeals: boolean): void => {
    if (editingItem) {
      updateNutritionPlan({
        variables: {
          id: editingItem.id,
          input: {
            ...incomingData,
          },
        },
      })
        .then(() => {
          enqueueSnackbar('Updated successfully', { variant: 'success' })
          getNutritionPlanQuery.refetch()
        })
        .catch(console.error)
    } else {
      let body = {
        user_id: userId,
        ...incomingData,
        nutrition_plan_meals: {
          data: [{ is_original: true }],
        },
      }
      const lastItem = plans[plans.length - 1]
      if (cloneMeals && lastItem) {
        body = {
          ...body,
          nutrition_plan_meals: {
            data: [
              {
                is_original: true,
                nutrition_meals: {
                  data: lastItem.nutrition_plan_meals[0]?.nutrition_meals.map((nm) => ({
                    name: nm.name,
                    order: nm.order,
                    nutrition_meal_products: {
                      data: nm.nutrition_meal_products.map((nmp) => ({
                        quantity: nmp.quantity,
                        product_id: nmp.product_id,
                        order: nmp.order,
                      })),
                    },
                  })),
                },
              },
            ],
          },
        }
      }

      insertNutritionPlan({
        variables: {
          objects: body,
        },
      })
        .then(() => {
          enqueueSnackbar('saved successfully', { variant: 'success' })
          getNutritionPlanQuery.refetch()
        })
        .catch(console.log)
    }
  }

  const _onModalClose = () => {
    setModalOpened(false)
    setEditingItem(null)
  }

  const [addFaseDates, setAddFasePopupDates] = React.useState<{
    startDate: Date
    endDate: Date
  }>({
    startDate: new Date(),
    endDate: new Date(),
  })
  React.useEffect(() => {
    const sortedList: any = plans.sort(
      (first, second) => new Date(first?.start_date).getTime() - new Date(second?.start_date).getTime()
    )
    const startDate = plans.length
      ? moment(sortedList[sortedList.length - 1]?.end_date)
          .add(30, 'hours')
          .toDate()
      : moment().toDate()
    const endDate = moment(startDate).add(6, 'days').toDate()
    setAddFasePopupDates({ startDate, endDate })
  }, [plans])

  return (
    <React.Fragment>
      <Paper
        {...{
          elevation: 0,
          className: fixedHeightPaper,
          style: {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            boxShadow: '2px 2px 21px rgba(0, 0, 0, 0.06)',
          },
        }}
      >
        <Grid container className={scopedClasses.gridWrapper}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="outlined-age-native-simple">Sort by</InputLabel>
              <Select
                native
                value={sortBy.value}
                // @ts-ignore
                onChange={(event) => setSortBy(SORTABLE_COLUMNS[event.target.value])}
                label="Sort by"
                inputProps={{
                  name: 'age',
                  id: 'outlined-age-native-simple',
                }}
              >
                {Object.values(SORTABLE_COLUMNS).map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={classes.containedBtn}
              onClick={() => setModalOpened(true)}
            >
              + Voeg Fase Toe
            </Button>
          </Grid>

          {!plans.length ? null : (
            <Grid container className={scopedClasses.tableContainer}>
              <VoedingsschemaTable plans={plans} onMenuClick={_onMenuClick} />
            </Grid>
          )}

          {showClonesOfId && (
            <div style={{ marginTop: 20 }}>
              <NutritionPlanMeals nutritionPlanId={showClonesOfId} />
            </div>
          )}
        </Grid>
      </Paper>

      <Menu id="action-menu" anchorEl={anchorEl} keepMounted open={isMenuOpened} onClose={_onMenuClose}>
        <MenuItem onClick={(): void => _onMenuItemSelected(() => setModalOpened(true))}>
          <EditIcon className={scopedClasses.menuIcon} />
          Wijzig
        </MenuItem>

        <MenuItem onClick={(): void => setShowClonesOfId(editingItem?.id)}>
          <List className={scopedClasses.menuIcon} />
          Bekijk clones
        </MenuItem>

        <MenuItem onClick={(): void => _prepareToDelete()}>
          <DeleteIcon className={scopedClasses.menuIcon} />
          Verwijder
        </MenuItem>
      </Menu>

      {!!isModalOpened && (
        <FaseToePopUp
          startDate={addFaseDates?.startDate}
          endDate={addFaseDates?.endDate}
          item={editingItem}
          open={isModalOpened}
          handleClose={_onModalClose}
          handleSubmit={_onModalSubmit}
          showCloneOption={plans.length > 0}
        />
      )}
    </React.Fragment>
  )
}
