import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { StyledTableCell } from 'components/Table'
import React from 'react'

import { TBlogCategories } from '../interfaces'
import CategoriesRow from './CategoriesRow'

interface IProps {
  categories: TBlogCategories
  onUpdate: (category: TBlogCategories[0]) => void
}

const CategoriesTable: React.FunctionComponent<IProps> = ({ categories, onUpdate }) => {
  return (
    <TableContainer component={Paper} elevation={0} style={{ maxHeight: 550 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell>Naam</StyledTableCell>
            <StyledTableCell>Aantal artikelen</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody {...{ style: { boxShadow: '2px 2px 21px rgb(0 0 0 / 6%)' } }}>
          {[...categories]
            .sort((first, second) => first.id - second.id)
            .map((row, index: number) => (
              <CategoriesRow key={index} category={row} onUpdate={onUpdate} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CategoriesTable
