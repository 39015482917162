// MUI
import { Avatar, Box, Button, Container, Grid, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { Loading } from 'components/Loading'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useCloudinary } from 'utils/useCloudinary'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import ChangePasswordDialog, { IInputState } from '../../components/ChangePasswordDialog'
import EditProfileForm from '../../components/EditProfileForm'
import { useGetUserByIdQuery, useUpdateUserByPkMutation } from '../../generated/graphql'
import { detectLanguage } from '../../utils/detectLanguage'
import { useAuth } from '../../utils/useAuth'
import WithDrawer from '../../wrappers'
import { useStyles } from './styles'

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ProfilePage: React.FunctionComponent = () => {
  const classes = useStyles()
  const [avatarUrl, setAvatarUrl] = React.useState<string>('./images/user_photo.png')
  const { getImagesByPublicIds } = useCloudinary()

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)
  const { userId, token } = useAuth()
  const [updateCoachProfileMutation] = useUpdateUserByPkMutation()
  const { enqueueSnackbar } = useSnackbar()

  const userQuery = useGetUserByIdQuery({
    variables: {
      userId,
    },
    skip: userId === null,
  })

  const { data, loading } = userQuery
  const currentUser = data?.users_by_pk

  const getImageFromCloudinary = React.useCallback(
    async (imageId: string) => {
      return getImagesByPublicIds([imageId])
        .then((res) =>
          res.json().then((response) => {
            return response.resources
          })
        )
        .catch((err) => {
          console.error(err)
          return []
        })
    },
    [getImagesByPublicIds]
  )

  React.useEffect(() => {
    if (currentUser?.avatar_url) {
      const handleAsync = async () => {
        const result = await getImageFromCloudinary(currentUser.avatar_url!)
        const newAvatarUrl = result[0]?.secure_url
        if (newAvatarUrl) setAvatarUrl(newAvatarUrl)
      }

      handleAsync()
    }
  }, [currentUser, getImageFromCloudinary])

  const lng = detectLanguage(currentUser?.language ?? 'nl')

  const changePassword = async (values: IInputState): Promise<void> => {
    // todo: move to .env or something similar
    const url = 'https://admin.rg.qraft.eu/users/update_password'

    const requestBody = {
      password: values.password,
      password_confirmation: values.confirmPassword,
    }

    await fetch(url, {
      body: JSON.stringify(requestBody),
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 204) return enqueueSnackbar('Gelukt', { variant: 'success' })
        throw response
      })
      .catch(() => enqueueSnackbar('Mislukt', { variant: 'error' }))
  }

  const updateCoachProfile = async (values: any): Promise<void> => {
    updateCoachProfileMutation({
      variables: {
        // @ts-ignore
        id: userId,
        changes: {
          ...values,
        },
      },
    })
      .then(() => {
        enqueueSnackbar('Succeed', {
          variant: 'success',
        })
        userQuery.refetch()
      })
      .catch(console.error)
  }
  const breadcrumbData: IBreadcrumb[] = [{ isLink: false, name: 'Profile' }]

  if (loading) return <Loading />

  return (
    <WithDrawer breadcrumbData={breadcrumbData}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={fixedHeightPaper} elevation={0}>
              <Grid container>
                <Grid item xs={7} className={classes.profileWrapper}>
                  <Box
                    {...{
                      component: 'div',
                      className: classes.profilePhoto,
                    }}
                  >
                    <Avatar
                      {...{
                        alt: 'ALT',
                        src: avatarUrl,
                        className: classes.userPhoto,
                      }}
                    />
                  </Box>

                  <Box
                    {...{
                      component: 'div',
                      className: classes.profileInfo,
                    }}
                  >
                    <Typography
                      {...{
                        variant: 'h2',
                        component: 'h2',
                        className: classes.userName,
                      }}
                    >
                      {currentUser ? `${currentUser.first_name} ${currentUser.last_name}` : 'Guest'}
                    </Typography>

                    <Typography
                      {...{
                        variant: 'h4',
                        component: 'h3',
                        className: classes.userPosition,
                      }}
                    >
                      Coach
                    </Typography>

                    <Box
                      {...{
                        component: 'div',
                        className: classes.buttonsWrapper,
                      }}
                    >
                      <ChangePasswordDialog onPress={changePassword}>
                        <Button variant="contained" className={classes.containedBtn}>
                          Verander wachtwoord
                        </Button>
                      </ChangePasswordDialog>
                      {currentUser && (
                        <EditProfileForm user={currentUser} avatar={avatarUrl} onPress={updateCoachProfile}>
                          <Button variant="outlined" className={classes.outlinedBtn}>
                            Wijzig profiel
                          </Button>
                        </EditProfileForm>
                      )}
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={5} className={classes.profileContacts}>
                  <Box
                    {...{
                      component: 'div',
                      className: classes.contactsWrapper,
                      style: {
                        paddingLeft: 36,
                      },
                    }}
                  >
                    <Box
                      {...{
                        component: 'div',
                        className: classes.infoRow,
                      }}
                    >
                      <Typography
                        {...{
                          variant: 'body1',
                          className: classes.infoRowTitle,
                        }}
                      >
                        Email:
                      </Typography>
                      <Typography
                        {...{
                          variant: 'body1',
                        }}
                      >
                        {currentUser?.email ?? '-'}
                      </Typography>
                    </Box>

                    <Box
                      {...{
                        component: 'div',
                        className: classes.infoRow,
                      }}
                    >
                      <Typography
                        {...{
                          variant: 'body1',
                          className: classes.infoRowTitle,
                        }}
                      >
                        Leeftijd:
                      </Typography>
                      <Typography
                        {...{
                          variant: 'body1',
                        }}
                      >
                        {currentUser?.user_profile?.date_of_birth || '-'}
                      </Typography>
                    </Box>

                    <Box
                      {...{
                        component: 'div',
                        className: classes.infoRow,
                      }}
                    >
                      <Typography
                        {...{
                          variant: 'body1',
                          className: classes.infoRowTitle,
                        }}
                      >
                        Taal:
                      </Typography>
                      <Typography
                        {...{
                          variant: 'body1',
                        }}
                      >
                        {lng?.label ?? '-'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              className={classes.paper}
              style={{
                justifyContent: 'start',
                padding: 36,
                minHeight: 240,
                maxHeight: 400,
              }}
              elevation={0}
            >
              <Typography
                {...{
                  variant: 'h5',
                  component: 'h3',
                  className: classes.h3,
                }}
              >
                Mijn korte biografie
              </Typography>

              <div style={{ overflow: 'auto' }}>
                <Typography
                  {...{
                    variant: 'body1',
                  }}
                >
                  {currentUser?.biography}
                </Typography>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} lg={3}>
            <Paper
              className={fixedHeightPaper}
              style={{ justifyContent: 'center', padding: '36px 23px' }}
              elevation={0}
            >
              <Typography
                {...{
                  variant: 'h5',
                  component: 'h3',
                  className: classes.h3,
                }}
              >
                profile statistics
              </Typography>

              <Box
                {...{
                  component: 'div',
                  className: classes.contactsWrapper,
                }}
              >
                <Box
                  {...{
                    component: 'div',
                    className: classes.infoRow,
                  }}
                >
                  <Typography
                    {...{
                      variant: 'body1',
                      className: classes.infoRowTitle,
                    }}
                  >
                    Members:
                  </Typography>
                  <Typography
                    {...{
                      variant: 'body1',
                    }}
                  >
                    {data?.user_profiles_aggregate?.aggregate?.count ?? '-'}
                  </Typography>
                </Box>

                <Box
                  {...{
                    component: 'div',
                    className: classes.infoRow,
                  }}
                >
                  <Typography
                    {...{
                      variant: 'body1',
                      className: classes.infoRowTitle,
                    }}
                  >
                    Active:
                  </Typography>
                  <Typography
                    {...{
                      variant: 'body1',
                    }}
                  >
                    -
                  </Typography>
                </Box>

                <Box
                  {...{
                    component: 'div',
                    className: classes.infoRow,
                  }}
                >
                  <Typography
                    {...{
                      variant: 'body1',
                      className: classes.infoRowTitle,
                    }}
                  >
                    Trainings:
                  </Typography>
                  <Typography
                    {...{
                      variant: 'body1',
                    }}
                  >
                    -
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </WithDrawer>
  )
}
