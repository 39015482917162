import { Breadcrumbs, Typography } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
// MUI libs
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { AuthContext } from '../../context/auth.context'
import { Notifications } from '../../pages/Dashboard/Notifications'
import { IAppBarProps } from './interfaces'
import { useStyles } from './styles'

export const AppBarComponent: React.FunctionComponent<IAppBarProps> = ({ isOpen, drawerHandle, breadcrumbData }) => {
  const history = useHistory()
  const classes = useStyles()
  const auth = React.useContext(AuthContext)

  const logoutHandler = (event: any) => {
    event.preventDefault()
    auth.logout()
    history.push('/')
  }

  const onClickHandler = (item: any): void => {
    history.push(item.to!)
  }

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, isOpen && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={drawerHandle}
          className={clsx(classes.menuButton, isOpen && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Breadcrumbs
          classes={{ root: classes.breadcrumbs, separator: classes.seperator }}
          maxItems={3}
          separator="›"
          aria-label="breadcrumb"
          className={classes.breadcrumbs}
        >
          {breadcrumbData.map((item, index) => {
            if (item.isLink) {
              return (
                <Button style={{ fontSize: 14 }} key={index} color="inherit" onClick={() => onClickHandler(item)}>
                  {item.name}
                </Button>
              )
            }
            return (
              <Typography
                key={index}
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                style={{ color: 'white', fontSize: 14 }}
              >
                {item.name}
              </Typography>
            )
          })}
        </Breadcrumbs>

        <Notifications />
        <Button
          {...{
            className: classes.logoutButton,
            onClick: logoutHandler,
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  )
}
