import { Box, Button, IconButton, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { round } from 'lodash'
import * as React from 'react'

import { MealSelector } from '../MealSelector'
import { TProduct } from '../MealSelector/interfaces'

interface IMealToBlock {
  _onSaveNewProduct: (product: any) => void
  updateIndicators: (value: any) => void
}

const justifyContent = 'space-between'
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent,
    marginTop: 40,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  iconButtons: {
    '&:hover': {
      backgroundColor: '$3F51B5',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent,
    width: '100%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  span: {
    textTransform: 'uppercase',
    color: '#AEAEAE',
    fontSize: 14,
  },
  spanVaue: {
    marginTop: 15,
    color: '#333333a',
    fontSize: 14,
  },
  mealBlockWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent,
  },
  mealSelect: {
    minWidth: 200,
    backgroundColor: 'white',
    marginTop: 10,
  },
  outlinedBtn: {
    backgroundColor: '#fff',
    color: '#3F51B5',
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    border: '1px solid #3F51B5',
    marginLeft: 10,
  },
  containedBtn: {
    backgroundColor: '#3F51B5',
    color: '#fff',
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    marginLeft: 10,
  },
  categoryBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent,
    alignSelf: 'flex-start',
    marginBottom: '15px',
    width: 200,
  },
  inputMarginDense: {
    padding: '8px 10px',
  },
  quantityWrapper: {
    marginTop: 10,
    maxWidth: 365,
    width: '200px',
    display: 'flex',
    maxHeight: 34,
  },
  quantityInput: {
    backgroundColor: '#fff',
    width: '130px',
    padding: '0 14px',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    '& div::before': {
      border: 'none !important',
    },
    '& div::after': {
      border: 'none !important',
    },
    '& input': {
      fontSize: 12,
      padding: '10px 0',
      textAlign: 'center',
    },
  },
  quantityAddButton: {
    backgroundColor: '#3f51b5',
    borderRadius: '0px 4px 4px 0px',
  },
  quantityRemoveButton: {
    backgroundColor: '#3f51b5',
    borderRadius: '4px 0px 0px 4px',
  },
}))

const AddNewMealToBlock: React.FC<IMealToBlock> = ({ _onSaveNewProduct, updateIndicators }) => {
  const [quantity, setQuantity] = React.useState<number>(1)
  const [selectedProduct, setSelectedProduct] = React.useState<TProduct>()
  const ownClasses = useStyles()

  React.useEffect(() => {
    if (selectedProduct) {
      updateIndicators({
        fats: Number(selectedProduct.fats) * quantity,
        proteins: Number(selectedProduct.proteins) * quantity,
        calories: Number(selectedProduct.calories) * quantity,
        carbohydrates: Number(selectedProduct.carbohydrates) * quantity,
      })
    }
  }, [selectedProduct, quantity])

  const removeQuantityHandler = (): void => {
    if (quantity !== 1) setQuantity((prevState) => prevState - 1)
  }

  const addQuantityHandler = (): void => {
    setQuantity((prevState) => prevState + 1)
  }

  const selectProductHandler = (product: TProduct): void => {
    setSelectedProduct(product)
  }

  // NOTE: add api call to save/update meal with adding new product
  return (
    <div className={ownClasses.container}>
      <div className={ownClasses.categoryBlock} />
      <div className={ownClasses.wrapper}>
        <div className={ownClasses.flexColumn}>
          <span className={ownClasses.span}>Naam:</span>
          <MealSelector onChange={selectProductHandler} />
        </div>
        <div className={ownClasses.flexColumn}>
          <span className={ownClasses.span}>Hoeveelheid:</span>
          <Box {...{ component: 'div', className: ownClasses.quantityWrapper }}>
            <IconButton onClick={removeQuantityHandler} className={ownClasses.quantityRemoveButton}>
              <RemoveIcon {...{ style: { color: '#fff' } }} />
            </IconButton>
            <TextField
              className={ownClasses.quantityInput}
              value={quantity}
              onChange={(event: React.BaseSyntheticEvent): void => {
                setQuantity(event.target.value)
              }}
            />
            <IconButton onClick={addQuantityHandler} className={ownClasses.quantityAddButton}>
              <AddIcon {...{ style: { color: '#fff' } }} />
            </IconButton>
          </Box>
        </div>
        <div className={ownClasses.flexColumn}>
          <span className={ownClasses.span}>Eenheid:</span>
          <span className={ownClasses.spanVaue}>{selectedProduct?.units ?? 'gram'}</span>
        </div>
        <div className={ownClasses.flexColumn}>
          <span className={ownClasses.span}>Calorieen:</span>
          <span className={ownClasses.spanVaue}>
            {selectedProduct ? round(selectedProduct.calories * quantity, 2) : '0'}
          </span>
        </div>
        <div className={ownClasses.flexColumn}>
          <span className={ownClasses.span}>Carbs:</span>
          <span className={ownClasses.spanVaue}>
            {selectedProduct ? round(selectedProduct?.carbohydrates * quantity, 2) : '0'}
          </span>
        </div>
        <div className={ownClasses.flexColumn}>
          <span className={ownClasses.span}>Vetten:</span>
          <span className={ownClasses.spanVaue}>
            {selectedProduct ? round(selectedProduct?.fats * quantity, 2) : '0'}
          </span>
        </div>
        <div className={ownClasses.flexColumn}>
          <span className={ownClasses.span}>Eiwitten:</span>
          <span className={ownClasses.spanVaue}>
            {selectedProduct ? round(selectedProduct?.proteins * quantity, 2) : '0'}
          </span>
        </div>
      </div>
      <div className={ownClasses.mealBlockWrapper} style={{ marginTop: 30 }}>
        <Button
          color="primary"
          variant="contained"
          className={ownClasses.containedBtn}
          disabled={selectedProduct === null || quantity < 1}
          onClick={(): void =>
            _onSaveNewProduct({
              quantity,
              product_id: selectedProduct?.id,
            })
          }
        >
          Opslaan
        </Button>
      </div>
    </div>
  )
}

export default AddNewMealToBlock
