import { Button, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { StyledTableCell, StyledTableRow } from 'components/Table'
import { useGetNutritionPlanMealsQuery } from 'generated/graphql'
import moment from 'moment'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

const useScopedStyles = makeStyles(() => ({
  tableButton: {
    backgroundColor: '#fff',
    color: '#3F51B5',
    textTransform: 'none',
    padding: '4px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    border: '1px solid #3F51B5',
  },
  actionsCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  moreIconWrapper: {
    marginLeft: 15,
  },
  iconHack: {
    position: 'relative',
    left: 15,
  },
}))

interface IProps {
  nutritionPlanId: number
}

export const NutritionPlanMeals: React.FunctionComponent<IProps> = ({ nutritionPlanId }): JSX.Element | null => {
  const scopedClasses = useScopedStyles()
  const { userId } = useParams<{ userId: string }>()
  const { data } = useGetNutritionPlanMealsQuery({
    variables: {
      nutritionPlanId,
    },
  })

  const formatDate = (date: string): string => {
    return moment(date).format('DD-MM-yyyy').toString()
  }

  if (!data) return null

  return (
    <TableContainer component={Paper} {...{ elevation: 0 }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell>Aantal maaltijden</StyledTableCell>
            <StyledTableCell>Aantal producten</StyledTableCell>
            <StyledTableCell>Aangemaakt op</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody {...{ style: { boxShadow: '2px 2px 21px rgb(0 0 0 / 6%)' } }}>
          {/* @ts-ignore */}
          {data.nutrition_plan_meals.map((row, index) => {
            const totalProducts = row.nutrition_meals.reduce((acc, obj) => {
              const dlt = obj.nutrition_meal_products_aggregate?.aggregate?.count
              if (dlt) return acc + dlt

              return acc
            }, 0)

            return (
              <StyledTableRow key={index}>
                <StyledTableCell>{`${index + 1}.`}</StyledTableCell>
                <StyledTableCell>{row.nutrition_meals_aggregate.aggregate?.count}</StyledTableCell>
                <StyledTableCell>{totalProducts}</StyledTableCell>
                <StyledTableCell>{formatDate(row.created_at)}</StyledTableCell>
                <StyledTableCell className={scopedClasses.actionsCell} align="right">
                  <Link
                    {...{
                      style: { textDecoration: 'none' },
                      to: {
                        pathname: `${userId}/meal-plan/clone/${row.id}`,
                      },
                    }}
                  >
                    <Button variant="outlined" size="small" className={scopedClasses.tableButton}>
                      Voedingsschema
                      <ChevronRightIcon className={scopedClasses.iconHack} />
                      <ChevronRightIcon />
                    </Button>
                  </Link>
                </StyledTableCell>
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
