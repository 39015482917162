import { useConfirmation } from 'context/ConfirmationServiceContext'
import {
  GetKnowledgeBaseDocument,
  useDeleteKnowledgeBaseByPkMutation,
  useInsertKnowledgeBaseMutation,
  useUpdateKnowledgeBaseByPkMutation,
} from 'generated/graphql'
import { useSnackbar } from 'notistack'

import { ICreate, IHook, IUpdate } from './interfaces'

export const useKnowledgeBase = (callback?: () => void): IHook => {
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirmation()

  const handleSuccess = (): void => {
    enqueueSnackbar('saved successfully', { variant: 'success' })
  }

  const handleError = (error: any): void => {
    enqueueSnackbar(error?.message || 'Something went wrong, please try again later...', {
      variant: 'error',
      autoHideDuration: null,
    })
    console.error(error)
  }

  const [insertCategory, { loading: isInserting }] = useInsertKnowledgeBaseMutation()
  const [updateCategory, { loading: isUpdating }] = useUpdateKnowledgeBaseByPkMutation()
  const [deleteCategory, { loading: isDeleting }] = useDeleteKnowledgeBaseByPkMutation()

  const deleteById = (id: number): void => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'You are planning to delete',
      description: 'Are you sure you want to do this? All articles under this category will be deleted too.',
    }).then(() => {
      // eslint-disable-next-line promise/no-nesting
      deleteCategory({
        variables: {
          id,
        },
        refetchQueries: [{ query: GetKnowledgeBaseDocument }],
        awaitRefetchQueries: true,
      })
        .then(handleSuccess)
        .catch((error) => handleError(error))
    })
  }

  const updateById = (body: IUpdate): void => {
    updateCategory({
      variables: {
        id: body.id,
        changes: {
          description: body.description,
          sequence: body.sequence,
        },
      },
      refetchQueries: [{ query: GetKnowledgeBaseDocument }],
      awaitRefetchQueries: true,
    })
      .then(handleSuccess)
      .catch((error) => handleError(error))
      .finally(callback)
  }

  const insert = (body: ICreate): void => {
    insertCategory({
      variables: {
        objects: [body],
      },
      refetchQueries: [{ query: GetKnowledgeBaseDocument }],
      awaitRefetchQueries: true,
    })
      .then(handleSuccess)
      .catch((error) => handleError(error))
      .finally(callback)
  }

  const isLoading = isInserting || isUpdating || isDeleting

  return { deleteById, updateById, insert, isLoading }
}
