import { useCallback } from 'react'

export const BASE_URL = 'https://admin.rg.qraft.eu'

const applicationJson = 'application/json'
export const useCloudinary = () => {
  const getImagesByPublicIds = useCallback(async (publicIds: string[]) => {
    return fetch(`${BASE_URL}/cloudinary/images`, {
      method: 'POST',
      body: JSON.stringify(publicIds),
      headers: {
        'Content-Type': applicationJson,
      },
    })
  }, [])

  const saveImage = useCallback(async (imageBase64: string, folderPath: string) => {
    return fetch(`${BASE_URL}/cloudinary/upload`, {
      method: 'POST',
      headers: {
        'Content-Type': applicationJson,
      },
      body: JSON.stringify({
        file: imageBase64,
        folderPath,
      }),
    })
  }, [])

  const deleteImagesByPublicIds = useCallback(async (publicIds: string[]) => {
    await fetch(`${BASE_URL}/cloudinary/images`, {
      method: 'DELETE',
      body: JSON.stringify(publicIds),
      headers: {
        'Content-Type': applicationJson,
      },
    })
  }, [])

  return {
    getImagesByPublicIds,
    deleteImagesByPublicIds,
    saveImage,
  }
}
