import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
// MUI
import { makeStyles } from '@material-ui/core/styles'
import { ChatBubble } from '@material-ui/icons'
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined'
import CropFreeIcon from '@material-ui/icons/CropFree'
import DashboardIcon from '@material-ui/icons/Dashboard'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
// Icons
import PeopleIcon from '@material-ui/icons/People'
import VideoCallIcon from '@material-ui/icons/VideoCall'
import * as React from 'react'
import { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { AuthContext } from '../../context/auth.context'

const useStyles = makeStyles(() => ({
  menuItem: {
    display: 'block',
    textDecoration: 'none',
    color: '#222',
  },
  active: {
    backgroundColor: '#3F51B5',
    color: '#fff',
    '& svg': {
      fill: '#fff',
    },
  },
  listItem: {
    paddingLeft: 25,
  },
}))

interface IMenuListProps {
  list?: any
}

export const MenuList: React.FunctionComponent<IMenuListProps> = () => {
  const { role } = useContext(AuthContext)
  const classes = useStyles()

  const navItems: { icon: any; title: string; to: string; role?: string }[] = [
    {
      icon: <DashboardIcon />,
      title: 'Dashboard',
      to: '/dashboard',
    },
    {
      icon: <PeopleIcon />,
      title: 'Members',
      to: '/clients',
    },
    {
      icon: <ChatBubble />,
      title: 'Chat',
      to: '/inbox',
    },
    {
      icon: <ContactMailOutlinedIcon />,
      title: 'My profile',
      to: '/profile',
    },
    {
      icon: <FastfoodIcon />,
      title: 'Recipes',
      to: '/cookbook',
      role: 'admin',
    },
    {
      icon: <CropFreeIcon />,
      title: 'Products',
      to: '/products',
      role: 'admin',
    },
    {
      icon: <LibraryBooksIcon />,
      title: 'Blog',
      to: '/blog',
      role: 'admin',
    },
    {
      icon: <VideoCallIcon />,
      title: 'Knowledge Base',
      to: '/knowledge_base',
      role: 'admin',
    },
  ]

  const filteredNavs = navItems.filter((item) => (item.role && item.role === role) || item.role === undefined)

  return (
    <List component="nav" aria-label="main mailbox folders">
      {filteredNavs.map((item, index) => (
        <NavLink
          key={index}
          {...{
            className: classes.menuItem,
            activeClassName: classes.active,
            to: item.to,
          }}
        >
          <ListItem className={classes.listItem} button>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        </NavLink>
      ))}
    </List>
  )
}
