import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      color: '#3F51B5',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface IDialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: IDialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(() => ({
  root: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 450,
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function ContainerModal({
  title,
  handleClose,
  handleSubmit,
  isOpen,
  children,
  disabled,
}: {
  title: string
  handleClose: any
  handleSubmit: () => void
  isOpen: boolean
  children: React.ReactNode
  disabled: boolean
}) {
  return (
    <Dialog style={{ maxHeight: '80%' }} onClose={handleClose} aria-labelledby={title} open={isOpen}>
      <DialogTitle id={title} onClose={handleClose}>
        {title}
      </DialogTitle>

      <DialogContent dividers>{children}</DialogContent>

      <DialogActions>
        <Button autoFocus variant="outlined" onClick={handleClose} color="primary">
          Annuleren
        </Button>

        <Button autoFocus variant="contained" onClick={handleSubmit} color="primary" disabled={disabled}>
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  )
}
