type LangT = {
  value: string
  label: string
}

// eslint-disable-next-line @typescript-eslint/no-shadow
type Indexer<LangT> = { [key: string]: LangT }

export const detectLanguage = (key: string): LangT => {
  const languages: Indexer<LangT> = {
    en: { value: 'en', label: 'English' },
    nl: { value: 'nl', label: 'Nederland' },
    ru: { value: 'ru', label: 'Русский' },
    ua: { value: 'ua', label: 'Українська' },
  }

  return languages[key]
}
