import { Button, TextField, Typography } from '@material-ui/core'
import * as React from 'react'

import { useStyles } from '../useStyles'

interface IMealBlock {
  _onFinishAddNewMealBlock: () => void
  _onAddNewMealBlock: (name: string) => void
}

const AddNewMealBlock: React.FC<IMealBlock> = ({ _onFinishAddNewMealBlock, _onAddNewMealBlock }) => {
  const classes = useStyles()

  const [mealName, setMealName] = React.useState<string>('')

  return (
    <div className={classes.mealBlockContainer}>
      <div className={classes.mealBlockWrapper}>
        <Typography variant="h6" component="h6" className={classes.indicatorName}>
          Maaltijd Naam
        </Typography>
        <TextField
          focused
          className={classes.mealBlockInput}
          placeholder="Nieuwe maaltijd"
          value={mealName}
          onChange={(event: React.BaseSyntheticEvent): void => setMealName(event.target.value)}
        />
      </div>
      <div className={classes.mealBlockWrapper}>
        <Button
          color="primary"
          variant="outlined"
          className={classes.outlinedBtn}
          onClick={(): void => {
            _onFinishAddNewMealBlock()
            setMealName('')
          }}
        >
          Annuleren
        </Button>

        <Button
          color="primary"
          variant="contained"
          className={classes.containedBtn}
          onClick={(): void => _onAddNewMealBlock(mealName)}
        >
          Opslaan
        </Button>
      </div>
    </div>
  )
}

export default AddNewMealBlock
