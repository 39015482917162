import { Box, Button, CardMedia, Typography } from '@material-ui/core'
import * as React from 'react'

import { useStyles } from '../useStyles'

interface INotCreatedProps {
  onPress(): any
  primary: string
  secondary: string
  buttonText: string
  image: string
}

export const NotCreated: React.FunctionComponent<INotCreatedProps> = ({
  onPress,
  primary,
  secondary,
  buttonText,
  image,
}) => {
  const classes = useStyles()

  return (
    <Box
      {...{
        className: classes.notCreatedWrapper,
        component: 'div',
      }}
    >
      <CardMedia
        {...{
          component: 'img',
          style: { width: 334, height: 200 },
          image,
          title: 'nutritions',
          alt: 'nutritions',
        }}
      />

      <Typography
        {...{
          variant: 'h3',
          component: 'h3',
          className: classes.notCreatedTitle,
        }}
      >
        {primary}
      </Typography>

      <Typography gutterBottom variant="body1" component="p" className={classes.notCreatedInfo}>
        {secondary}
      </Typography>

      <Button
        {...{
          variant: 'contained',
          color: 'primary',
          className: classes.containedBtn,
          onClick: onPress,
        }}
      >
        {buttonText}
      </Button>
    </Box>
  )
}
