import 'date-fns'

import DateFnsUtils from '@date-io/date-fns'
import { Box, Switch } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Slider from '@material-ui/core/Slider'
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import TodayIcon from '@material-ui/icons/Today'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import clsx from 'clsx'
import { useGetUserByIdQuery } from 'generated/graphql'
import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router'

import { styles, useStyles } from './styles'

const getInputFields = () => {
  return {
    inputs: [
      {
        type: 'date',
        name: 'startDate',
        label: 'Start datum:',
      },
      {
        type: 'date',
        name: 'endDate',
        label: 'Eind datum:',
      },
      {
        type: 'number',
        name: 'caloriesPerDay',
        label: 'Dagelijkse calorieën:',
        valueType: 'kcal',
      },
      {
        type: 'slider',
        name: 'protein',
        label: 'Eiwitten',
        valueType: ' gr',
        step: 0.1,
        min: 1.8,
        max: 2.8,
      },
      {
        type: 'slider',
        name: 'fatsPercentage',
        label: 'Vetten',
        valueType: '%',
        step: 1,
        min: 15,
        max: 40,
      },
      {
        type: 'switch',
        name: 'cloneMeals',
        label: 'Laatste maaltijdschema clonen',
      },
    ],
  }
}

export interface IDialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: IDialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(() => ({
  root: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 450,
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function CustomizedDialogs({
  startDate,
  endDate,
  handleClose,
  open = false,
  item,
  handleSubmit,
  showCloneOption,
}: {
  startDate: Date
  endDate: Date
  item: any
  handleClose: any
  open: boolean
  handleSubmit: (data: any, cloneMeals: boolean) => void
  showCloneOption: boolean
}) {
  const classes = useStyles()
  const [notificationSent, setNotificationSent] = useState<boolean>(item?.push_notification_sent || false)

  const [fase, setFase] = useState<{
    startDate: Date | null
    endDate: Date | null
    caloriesPerDay: string | null
    protein: number | null
    fatsPercentage: number | null
    cloneMeals: boolean
  }>({
    startDate: item ? new Date(item.start_date) : startDate,
    endDate: item ? new Date(item.end_date) : endDate,
    caloriesPerDay: item ? item.calories_per_day : 1,
    protein: item ? item.protein : 1.8,
    fatsPercentage: item ? item.fats : 15,
    cloneMeals: true,
  })

  const { userId } = useParams<{ userId: string }>()
  const getUserQuery = useGetUserByIdQuery({ variables: { userId } })
  const { data } = getUserQuery

  const calculateDailyCalories = useCallback(() => {
    const userProfile = data?.users_by_pk?.user_profile
    if (!userProfile) return

    const { initial_weight, diet_summary } = userProfile
    const { personal_goal, activity_level, kcal_deficiency } = diet_summary!
    const some_coeff = 2.20462

    const caloric_intake_for_maintenance = Math.round(initial_weight * some_coeff * 10 * activity_level)

    let caloric_intake_for_goal: any

    switch (personal_goal) {
      case 1:
        caloric_intake_for_goal = Math.round((caloric_intake_for_maintenance * 7 - kcal_deficiency!) / 7)
        break
      case 2:
        caloric_intake_for_goal = Math.round((caloric_intake_for_maintenance * 7 + kcal_deficiency!) / 7)
        break
      default:
        caloric_intake_for_goal = caloric_intake_for_maintenance
    }
    return caloric_intake_for_goal
  }, [data?.users_by_pk?.user_profile])

  React.useEffect(() => {
    const userProfile = data?.users_by_pk?.user_profile
    if (!item && userProfile?.diet_summary) {
      const caloriesPerDay = calculateDailyCalories()
      setFase((prevState) => ({
        ...prevState,
        caloriesPerDay: caloriesPerDay ?? 1,
        fatsPercentage: userProfile.diet_summary?.fat_range ?? 15,
        protein: userProfile.diet_summary?.protein_range ?? 1.8,
      }))
    }
  }, [item, data, calculateDailyCalories])

  React.useEffect(() => {
    if (item) {
      setFase({
        caloriesPerDay: item.calories_per_day,
        startDate: new Date(item.start_date),
        endDate: new Date(item.end_date),
        protein: item.protein,
        fatsPercentage: item.fats,
        cloneMeals: false,
      })
    }
  }, [item])

  const _onSubmit = (): void => {
    const { startDate: thisStartDate, endDate: thisEndDate, caloriesPerDay, protein, fatsPercentage, cloneMeals } = fase

    const invalidProtein = protein === null || protein < 1.8 || protein > 2.4
    const invalidFat = fatsPercentage === null || fatsPercentage < 15 || fatsPercentage > 40
    if (invalidProtein) return alert('Protein should be between 1.8 and 2.4')
    if (invalidFat) return alert('fatsPercentage should be between 15 and 30')

    const requestBody: any = {
      start_date: thisStartDate,
      end_date: thisEndDate,
      calories_per_day: caloriesPerDay,
      protein,
      fats: fatsPercentage,
    }

    if (item && !item.push_notification_sent && notificationSent) requestBody.push_notification_sent = notificationSent

    handleSubmit(requestBody, cloneMeals)
    handleClose()
  }

  const handleChange = (event: any, newValue?: any, name?: any): void => {
    let key: string
    let val: string | number | undefined

    if (name === undefined && event?.target?.name === undefined) {
      key = event.target.getAttribute('aria-labelledby')
      val = newValue
    } else if (name !== undefined) {
      key = name
      val = newValue
    } else {
      key = event.target.name
      val = event.target.value
    }

    setFase((prevState) => ({
      ...prevState,
      [key]: val,
    }))
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {item ? 'Bewerk Fase' : 'VOEG FASE TOE'}
      </DialogTitle>

      <DialogContent dividers>
        {getInputFields().inputs.map((input: any, index: number) => {
          switch (input.type) {
            case 'date':
              return (
                <Box
                  {...{
                    key: index,
                    className: classes.dateField,
                  }}
                >
                  <Typography>{input.label}</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      {...{
                        id: input.name,
                        keyboardIcon: <TodayIcon color="primary" />,
                        color: 'primary',
                        disableToolbar: true,
                        variant: 'inline',
                        format: 'MM/dd/yyyy',
                        margin: 'normal',
                        // @ts-ignore
                        value: fase[input.name],
                        onChange: (date, value) => handleChange(value, date, input.name),
                        style: { maxWidth: '195px' },
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              )
            case 'number':
              return (
                <Box
                  {...{
                    key: index,
                    className: clsx(classes.numberField, classes.flexWrapper),
                  }}
                >
                  <Typography>{input.label}</Typography>

                  <Box
                    {...{
                      className: classes.flexWrapper,
                    }}
                  >
                    <TextField
                      style={{ marginRight: 15, maxWidth: '150px' }}
                      className={classes.dialogContentItem}
                      variant="outlined"
                      name={input.name}
                      size="small"
                      // @ts-ignore
                      value={fase[input.name]}
                      onChange={handleChange}
                    />

                    <Typography>{input.valueType}</Typography>
                  </Box>
                </Box>
              )
            case 'switch':
              if (item || !showCloneOption) return null
              return (
                <Box
                  {...{
                    key: index,
                    className: clsx(classes.numberField, classes.flexWrapper),
                  }}
                >
                  <Typography>{input.label}</Typography>
                  <Box
                    {...{
                      className: classes.flexWrapper,
                    }}
                  >
                    <Switch
                      name={input.name}
                      checked={fase.cloneMeals}
                      onChange={(event, newValue) => handleChange(event, newValue, 'cloneMeals')}
                      color="primary"
                    />
                  </Box>
                </Box>
              )
            case 'slider':
              return (
                <Box
                  {...{
                    key: index,
                    style: {
                      display: 'flex',
                      alignItems: 'center',
                      margin: '10px 0',
                    },
                  }}
                >
                  <Typography
                    {...{
                      style: { whiteSpace: 'nowrap', width: '9ch' },
                    }}
                  >
                    {input.label}
                  </Typography>
                  <Slider
                    {...{
                      step: input.step,
                      min: input.min,
                      max: input.max,
                      // @ts-ignore
                      value: fase[input.name],
                      name: input.name.toLowerCase(),
                      onChange: (event, value) => handleChange(event, value, input.name),
                      style: { flex: '1' },
                    }}
                  />
                  <Typography
                    {...{
                      style: {
                        whiteSpace: 'nowrap',
                        width: '7ch',
                        textAlign: 'right',
                      },
                    }}
                  >
                    {/* @ts-ignore */}
                    {`${fase[input.name]}${input.valueType}`}
                  </Typography>
                </Box>
              )
            default:
              return null
          }
        })}
        {item && !item.push_notification_sent && (
          <React.Fragment>
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Typography
                {...{
                  style: {
                    whiteSpace: 'nowrap',
                    width: '7ch',
                    textAlign: 'right',
                  },
                }}
              >
                Verstuur notificatie*
              </Typography>

              <Switch
                name="notification"
                checked={notificationSent}
                onChange={(__, newValue): void => setNotificationSent(newValue)}
                color="primary"
              />
            </div>

            <Typography
              {...{
                style: {
                  whiteSpace: 'nowrap',
                  textAlign: 'right',
                },
              }}
            >
              * Verstuur een notificatie alleen als alle maaltijden zijn toegevoegd.
            </Typography>
          </React.Fragment>
        )}
      </DialogContent>

      <DialogActions>
        <Button autoFocus variant="outlined" onClick={handleClose} color="primary">
          Annuleren
        </Button>

        <Button autoFocus variant="contained" onClick={_onSubmit} color="primary">
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  )
}
