import { Button, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { StyledTableCell, StyledTableRow } from 'components/Table'
import { IGetNutritionPlanByQuery } from 'generated/graphql'
import moment from 'moment'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

const useScopedStyles = makeStyles(() => ({
  tableButton: {
    backgroundColor: '#fff',
    color: '#3F51B5',
    textTransform: 'none',
    padding: '4px 24px',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.03em',
    border: '1px solid #3F51B5',
  },
  actionsCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  moreIconWrapper: {
    marginLeft: 15,
  },
  iconHack: {
    position: 'relative',
    left: 15,
  },
}))

type TNutritionPlan = NonNullable<IGetNutritionPlanByQuery['nutrition_plans']>
interface IVoedingsschemaTableProps {
  plans: TNutritionPlan
  onMenuClick(event: any, row: any): any
}

export const VoedingsschemaTable: React.FunctionComponent<IVoedingsschemaTableProps> = ({ plans, onMenuClick }) => {
  const scopedClasses = useScopedStyles()
  const { userId } = useParams()

  const formatDate = (date: any): string => {
    return moment(date).format('DD-MM-yyyy').toString()
  }

  const getFase = (rowId: number): any => {
    const sortedList = plans.sort(
      (first, second) => new Date(first?.start_date).getTime() - new Date(second?.start_date).getTime()
    )
    return sortedList.map((item: any) => item.id).indexOf(rowId) + 1
  }

  return (
    <TableContainer component={Paper} {...{ elevation: 0 }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell>Start datum</StyledTableCell>
            <StyledTableCell>Eind datum</StyledTableCell>
            <StyledTableCell>Dagelijkse calor</StyledTableCell>
            <StyledTableCell>Eiwitten</StyledTableCell>
            <StyledTableCell>Vetten</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody {...{ style: { boxShadow: '2px 2px 21px rgb(0 0 0 / 6%)' } }}>
          {/* @ts-ignore */}
          {plans.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{`${index + 1}.`}</StyledTableCell>
              <StyledTableCell>{formatDate(row.start_date)}</StyledTableCell>
              <StyledTableCell>{formatDate(row.end_date)}</StyledTableCell>
              <StyledTableCell>{`${row.calories_per_day} kcal`}</StyledTableCell>
              <StyledTableCell>{`${row.protein} gr/kg`}</StyledTableCell>
              <StyledTableCell>{`${row.fats}%`}</StyledTableCell>
              <StyledTableCell className={scopedClasses.actionsCell} align="right">
                <div className={scopedClasses.moreIconWrapper}>
                  <IconButton
                    aria-label="more"
                    aria-controls="action-menu"
                    aria-haspopup="true"
                    onClick={(event) => onMenuClick(event, row)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </div>

                <Link
                  {...{
                    style: { textDecoration: 'none' },
                    to: {
                      pathname: `/user/${userId}/meal-plan/${row.id}`,
                      state: {
                        userId,
                        sequence: getFase(row.id),
                        planId: row.id,
                        startDate: row.start_date,
                        endDate: row.end_date,
                      },
                    },
                  }}
                >
                  <Button variant="outlined" size="small" className={scopedClasses.tableButton}>
                    Voedingsschema
                    <ChevronRightIcon className={scopedClasses.iconHack} />
                    <ChevronRightIcon />
                  </Button>
                </Link>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
