import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  inputMarginDense: {
    padding: '8px 10px',
  },
  productTextField: {
    border: 'none',
    marginTop: '10px',
    cursor: 'pointer',
  },
  productInputField: {
    backgroundColor: '#fff',
    borderRadius: 4,
    cursor: 'pointer',
  },
  '@global': {
    'li:focus': {
      outline: 'none',
    },
  },
}))
