import DateFnsUtils from '@date-io/date-fns'
import { Box, FormControlLabel, Radio, RadioGroup, Slider, TextField, Typography } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as React from 'react'

import { useStyles } from '../../useStyles'

interface IStepInterface {
  data: any
  userData: any
  handleChange: (e: any) => void
  handleDate: (data: any, value: any, name: any) => void
  dateInputValue: any
}

export const StepperWrapper: React.FunctionComponent<IStepInterface> = ({
  data,
  userData,
  handleChange,
  handleDate,
  dateInputValue,
}) => {
  const classes = useStyles()

  return (
    <Box
      {...{
        component: 'div',
        className: classes.stepWrapper,
      }}
    >
      <Box {...{ component: 'div', className: classes.stepLeft }}>
        <Typography {...{ className: classes.stepRightTitle, component: 'h3' }}>{data.inputsTitle}</Typography>
        {data.inputs.map((item: any, index: number) => {
          switch (item.type) {
            case 'number':
              return (
                <Box
                  {...{
                    key: index,
                    component: 'div',
                    style: {
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    },
                  }}
                >
                  <Typography
                    {...{
                      style: {
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '19px',
                        letterSpacing: '0.03em',
                        textAlign: 'left',
                      },
                    }}
                  >
                    {item.label}
                  </Typography>
                  <TextField
                    {...{
                      key: index,
                      type: item.type,
                      onChange: handleChange,
                      name: item.name,
                      value: userData[item.name],
                      size: 'small',
                      InputLabelProps: {
                        shrink: true,
                      },
                      variant: 'outlined',
                      color: 'primary',
                      className: classes.stepperTextField,
                      style: { width: '122px' },
                    }}
                  />
                </Box>
              )
            case 'date':
              return (
                <Box
                  {...{
                    key: index,
                    style: {
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                      flexFlow: 'row wrap',
                    },
                  }}
                >
                  <Typography>{item.label}</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      {...{
                        id: item.name,
                        color: 'primary',
                        variant: 'inline',
                        format: 'MM-dd-yyyy',
                        margin: 'normal',
                        autoOk: true,
                        disableFuture: true,

                        value: userData[item.name],
                        inputValue: dateInputValue,
                        onChange: (date, value) => handleDate(date, value, item.name),
                        style: { maxWidth: '195px' },
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              )
            case 'radio':
              return (
                <RadioGroup
                  {...{
                    key: index,
                    value: userData[item.name] !== null ? userData[item.name] : item.radio[0].value,
                    'aria-label': 'Acitiviteiten niveau opties',
                    name: item.name,
                  }}
                >
                  {item.radio.map((radiobtn: any, idx: number) => (
                    <FormControlLabel
                      key={idx}
                      {...{
                        value: radiobtn.value,
                        label: radiobtn.label,
                        onChange: handleChange,
                        control: <Radio />,
                      }}
                    />
                  ))}
                </RadioGroup>
              )
            case 'slider':
              return (
                <Box {...{ component: 'div', key: index }}>
                  <Typography
                    {...{
                      component: 'h3',
                      style: {
                        fontSize: '18px',
                        fontWeight: 500,
                        lineHeight: '28px',
                        letterSpacing: '0em',
                        marginBottom: '20px',
                      },
                    }}
                  >
                    Macronutritienten Range
                  </Typography>
                  {item.slider.map((slider: any, indx: number) => (
                    <Box key={indx} {...{ component: 'div' }}>
                      <Box
                        {...{
                          component: 'div',
                          className: classes.sliderHeader,
                        }}
                      >
                        <Typography
                          {...{
                            variant: 'h6',
                            className: classes.sliderHeaderText,
                          }}
                        >
                          {slider.element}
                        </Typography>
                        <Typography
                          {...{
                            variant: 'h6',
                            className: classes.sliderHeaderText,
                          }}
                        >
                          {`${userData[slider.name]}${slider.valueType}`}
                        </Typography>
                      </Box>

                      <Box
                        {...{
                          className: classes.sliderContent,
                        }}
                      >
                        <Typography
                          {...{
                            className: classes.sliderContentText,
                            style: { marginRight: '20px' },
                          }}
                        >
                          {`${slider.min}${slider.valueType}`}
                        </Typography>
                        <Slider
                          {...{
                            label: slider.name.toLowerCase(),
                            name: slider.name.toLowerCase(),
                            value: userData[slider.name.toLowerCase()],
                            min: slider.min,
                            max: slider.max,
                            step: slider.step,
                            onChange: handleChange,
                            valueLabelDisplay: 'auto',
                            'aria-labelledby': slider.name.toLowerCase(),
                          }}
                        />
                        <Typography
                          {...{
                            className: classes.sliderContentText,
                            style: { marginLeft: '20px' },
                          }}
                        >
                          {`${slider.max}${slider.valueType}`}
                        </Typography>
                      </Box>

                      <Typography
                        {...{
                          component: 'span',
                          variant: 'body2',
                          className: classes.sliderLabel,
                        }}
                      >
                        {slider.label}
                      </Typography>
                    </Box>
                  ))}
                  <Box {...{ style: { margin: '20px 0' } }}>
                    <Typography
                      {...{
                        variant: 'h6',
                        className: classes.sliderHeaderText,
                        style: {
                          marginBottom: 10,
                        },
                      }}
                    >
                      Koolhydraten
                    </Typography>

                    <Typography
                      {...{
                        component: 'span',
                        variant: 'body2',
                        className: classes.sliderLabel,
                        style: {
                          margin: '10px 0',
                        },
                      }}
                    >
                      Koolhydraten is de aanvullende voedingsstof binnen de Calorie inname
                    </Typography>
                  </Box>
                </Box>
              )
            default:
              return null
          }
        })}
      </Box>
      <Box {...{ component: 'div', className: classes.stepRight }}>
        <Typography {...{ className: classes.stepRightTitle, component: 'h3' }}>{data.title}</Typography>
        <Typography
          {...{
            component: 'span',
            className: classes.stepRightText,
            dangerouslySetInnerHTML: { __html: data.text },
          }}
        />
      </Box>
    </Box>
  )
}
