import {
  Button,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import EditIcon from '@material-ui/icons/Edit'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import clsx from 'clsx'
import { IExerciseGroups, IExerciseVariants } from 'generated/graphql'
import React, { useEffect, useState } from 'react'

import { ExerciseSelectorModal } from './ExerciseSelectorModal'
import { useExercises } from './useExercises'

export const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.primary.light,
  },
  tableHeaderCell: {
    color: '#fff',
  },
  tableCell: {
    borderBottom: 'none',
  },
  textField: {
    backgroundColor: '#fff',
    borderRadius: '5px',
  },
  inputField: {
    padding: 8,
  },
  errorSign: {
    border: '1px solid red',
  },
}))

interface IProps {
  group: IExerciseGroups
  onDelete: (id: any) => void
  onSave: (body: any, callback: () => void) => void
  previewMode: boolean
}

export const DropSetTableRow: React.FunctionComponent<IProps> = ({ group, onDelete, onSave, previewMode }) => {
  const classes = useStyles()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [errors, setErrors] = useState<any[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)

  const [data, setData] = useState<
    {
      id?: number
      exerciseVariant?: IExerciseVariants
      exerciseGroupId?: number
      repetition: string[]
    }[]
  >([])

  useEffect(() => {
    const { exercises } = group
    // @ts-ignore
    const newList: typeof data = [...exercises]
      .sort((first, second) => first.id - second.id)
      .map((exercise) => {
        return {
          id: exercise.id,
          exerciseVariant: exercise.exercise_variant,
          exerciseGroupId: group.id,
          repetition: exercise.repetition?.split(','),
        }
      })
    setData(newList)
  }, [group])

  const onExerciseVariantChangeHandler = (exerciseVariant: any) => {
    setData((prevState) =>
      prevState.map((ele) => {
        return { ...ele, exerciseVariant }
      })
    )
    setShowModal(false)
  }

  const onCloseEditModeHandler = () => {
    setEditMode(false)
    setErrors([])
    setData((prevState) => {
      return prevState.filter((item) => item.id !== undefined)
    })
  }

  const onChangeHandler = (event: any, repetitionIndex: number, rowIndex: number) => {
    const newValue = event.target.value

    if (newValue.trim().length !== 0) {
      const filteredList: any[] = [...errors].filter(
        (err) => err.rowIndex !== rowIndex && err.repetitionIndex !== repetitionIndex
      )
      setErrors(filteredList)
    } else {
      const newError = {
        rowIndex,
        repetitionIndex,
        message: 'Field required',
      }
      setErrors((prevState) => [...prevState, newError])
      return
    }

    setData((prevState) =>
      prevState.map((ele, idx) => {
        if (rowIndex === idx) {
          // eslint-disable-next-line no-param-reassign
          ele.repetition[repetitionIndex] = newValue
          return ele
        }
        return ele
      })
    )
  }

  const onSaveChangesHandler = () => {
    if (data.some((ele) => ele.exerciseVariant === undefined)) return window.alert('Selecteer een exerise')
    const objects = data.map((exercise) => {
      return {
        id: exercise.id,
        exercise_group_id: group.id,
        exercise_variant_id: exercise.exerciseVariant?.id,
        repetition: String(exercise.repetition),
      }
    })
    onSave(objects, () => setEditMode(false))
  }

  const addExerciseHandler = () => {
    const newExercise: typeof data[0] = {
      id: undefined,
      exerciseVariant: data[0]?.exerciseVariant,
      exerciseGroupId: group.id,
      repetition: ['1', '1', '1'],
    }
    setEditMode(true)
    setData((prevState) => [...prevState, newExercise])
  }

  const onDeleteHandler = (index: any) => {
    const id = data[index]?.id
    if (id) onDelete([id])
    else setData((prevState) => prevState.filter((__, idx) => idx !== index))
  }

  const onBulkDeleteHandler = () => {
    const allIds = group.exercises.map((exercise) => exercise.id)
    if (allIds.length > 0) onDelete(allIds)
  }

  const sorted = [...group.exercises].sort((first, second) => first.id - second.id)
  const { onDeleteParent } = useExercises()

  return (
    <TableContainer>
      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell className={classes.tableHeaderCell}>Dropset</TableCell>
            <TableCell className={classes.tableHeaderCell}>Sets</TableCell>
            <TableCell className={classes.tableHeaderCell}>Herhaling</TableCell>
            {editMode && <TableCell />}
            <TableCell style={{ float: 'right' }}>
              <IconButton style={{ padding: 5 }} onClick={(): void => onDeleteParent(group.id)}>
                <DeleteIcon style={{ color: 'red' }} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {editMode &&
            !previewMode &&
            data.map((exercise, index) => (
              <TableRow key={index}>
                {index === 0 && (
                  <TableCell rowSpan={data.length} className={classes.tableCell}>
                    <Button onClick={(): void => setShowModal(true)} color="primary" variant="outlined">
                      {exercise?.exerciseVariant?.name || 'Search'}
                    </Button>
                    <ExerciseSelectorModal
                      show={showModal}
                      onSelect={onExerciseVariantChangeHandler}
                      onClose={(): void => setShowModal(false)}
                    />
                  </TableCell>
                )}
                <TableCell className={classes.tableCell}>
                  <Typography>
                    Set
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <div style={{ display: 'flex' }}>
                    {exercise.repetition?.map((rep: any, idx: number) => (
                      <div key={idx} style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          className={clsx(
                            classes.textField,
                            errors.some((err) => err.repetitionIndex === idx && err.rowIndex === index)
                              ? classes.errorSign
                              : null
                          )}
                          defaultValue={rep}
                          type="number"
                          name="sets"
                          InputProps={{
                            classes: { input: classes.inputField },
                            disableUnderline: true,
                            inputProps: {
                              min: 1,
                            },
                          }}
                          onChange={(event: any) => onChangeHandler(event, idx, index)}
                        />
                        <span style={{ marginLeft: 5 }}>X</span>
                      </div>
                    ))}
                  </div>
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  <IconButton onClick={() => onDeleteHandler(index)}>
                    <DeleteIcon style={{ color: 'red' }} />
                  </IconButton>
                </TableCell>

                {index === 0 && (
                  <TableCell align="right" rowSpan={data.length} className={classes.tableCell}>
                    <IconButton onClick={onCloseEditModeHandler}>
                      <HighlightOffIcon color="error" />
                    </IconButton>
                    <IconButton disabled={errors.length > 0} onClick={onSaveChangesHandler}>
                      <CheckCircleIcon style={{ color: 'green' }} />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}

          {!editMode &&
            sorted.map((exercise, index) => (
              <TableRow key={exercise.id}>
                {index === 0 && (
                  <TableCell rowSpan={group.exercises.length} className={classes.tableCell}>
                    <Typography>{exercise.exercise_variant.name}</Typography>
                  </TableCell>
                )}
                <TableCell className={classes.tableCell}>
                  <Typography>
                    Set
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell style={{ display: 'flex' }} className={classes.tableCell}>
                  {exercise.repetition?.split(',')?.map((rep, idx) => (
                    <Typography key={idx} style={{ marginRight: 5 }}>
                      {`${rep}x`}
                    </Typography>
                  ))}
                </TableCell>

                {index === 0 && !previewMode && (
                  <TableCell rowSpan={group.exercises.length} align="right" className={classes.tableCell}>
                    <IconButton onClick={(): void => setEditMode(true)}>
                      <EditIcon color="primary" />
                    </IconButton>
                    <IconButton onClick={onBulkDeleteHandler}>
                      <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <IconButton
                      aria-label="more"
                      aria-controls="action-menu"
                      aria-haspopup="true"
                      className="sort-icon"
                    >
                      <DragIndicatorIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}

          {(editMode || (group.exercises.length === 0 && !previewMode)) && (
            <TableRow>
              <TableCell className={classes.tableCell} colSpan={2}>
                <Button color="primary" onClick={addExerciseHandler}>
                  Voeg set toe
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DropSetTableRow
