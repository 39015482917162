import { useCallback, useState } from 'react'

export const useHttp = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const request = useCallback(async (url, method = 'GET', body = null, headers = {}) => {
    setLoading(true)
    try {
      const requestOptions = {
        method,
        body: body ? JSON.stringify(body) : null,
        headers: {
          ...(body !== null && { 'Content-Type': 'application/json' }),
          ...headers,
        },
      }

      const response = await fetch(url, requestOptions)
      const data = await response.json()

      if (!response.ok) throw new Error(data.message || 'Что-то пошло не так')

      setLoading(false)

      return data
    } catch (e) {
      setLoading(false)
      setError(e.message)
      throw e
    }
  }, [])

  const clearError = useCallback(() => setError(null), [])

  return { loading, request, error, clearError }
}
