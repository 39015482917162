/* eslint radix: ["error", "as-needed"] */

import { Box, Button, ButtonGroup, Grid, Popover, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField/TextField'
import AddIcon from '@material-ui/icons/Add'
import { Autocomplete } from '@material-ui/lab'
import { useConfirmation } from 'context/ConfirmationServiceContext'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { ReactSortable } from 'react-sortablejs'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import {
  IExerciseDays,
  IExerciseGroups,
  IWorkoutTemplates,
  useDeleteExerciseDaysByPkMutation,
  useDeleteExercisesByPkMutation,
  useGetExerciseDaysByWorkoutPlanIdQuery,
  useGetWorkoutPlanByPkQuery,
  useGetWorkoutTemplatesLazyQuery,
  useInsertExerciseDaysMutation,
  useInsertExerciseGroupMutation,
  useInsertWorkoutTemplatesMutation,
  useUpdateExerciseGroupByPkMutation,
  useUpsertExercisesMutation,
} from '../../generated/graphql'
import WithDrawer from '../../wrappers'
import CircuitTableRow from './components/CircuitTableRow'
import DropSetTableRow from './components/DropSetTableRow'
import OefeningOpTijdTableRow from './components/OefeningOpTijdTableRow'
import OefeningTableRow from './components/OefeningTableRow'
import SuperSetTableRow from './components/SuperSetTableRow'
import { useStyles } from './useStyles'

const description = 'Are you sure you want to do this?'
// eslint-disable-next-line sonarjs/cognitive-complexity
export const TrainingSchemaItem: React.FunctionComponent = (): JSX.Element => {
  const classes = useStyles()
  const { workoutPlanId } = useParams<{ workoutPlanId: string }>()
  const confirm = useConfirmation()
  const { enqueueSnackbar } = useSnackbar()

  const [selectedTemplate, setSelectedTemplate] = React.useState<IWorkoutTemplates | undefined>(undefined)
  const [exerciseDays, setExerciseDays] = React.useState<IExerciseDays[]>([])

  const [getTemplatesQuery, templatesResult] = useGetWorkoutTemplatesLazyQuery()
  const [workoutTemplates, setWorkoutTemplates] = React.useState<IWorkoutTemplates[]>([])

  React.useEffect(() => {
    if (templatesResult.data) {
      const sorted = [...templatesResult.data.workout_templates].filter(
        (workoutTemplate) => workoutTemplate.exercise_days.length > 0
      ) as IWorkoutTemplates[]
      setWorkoutTemplates(sorted)
    }
  }, [templatesResult.data])

  const wPlan = useGetWorkoutPlanByPkQuery({
    variables: { id: parseInt(workoutPlanId) },
    onCompleted: (result) => {
      const dayCount = result.workout_plans_by_pk?.day_count
      if (dayCount) {
        getTemplatesQuery({
          variables: {
            dayCount,
          },
        })
      }
    },
  })

  const exerciseDaysQuery = useGetExerciseDaysByWorkoutPlanIdQuery({
    variables: {
      workoutPlanId,
    },
  })

  const [upsertExercises] = useUpsertExercisesMutation()
  const [deleteExercisesById] = useDeleteExercisesByPkMutation()
  const [insertExerciseGroup] = useInsertExerciseGroupMutation()
  const [deleteExerciseDays] = useDeleteExerciseDaysByPkMutation()
  const [insertWorkoutTemplates] = useInsertWorkoutTemplatesMutation()
  const [updateExerciseGroupOrder] = useUpdateExerciseGroupByPkMutation()
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<any>()
  const [newTemplateName, setNewTemplateName] = React.useState<string>('')
  const { data } = exerciseDaysQuery

  const sortListBySequence = (list: any[]): any[] => {
    return [...list].sort((first, second) => first.sequence - second.sequence)
  }

  React.useEffect(() => {
    const result = data?.exercise_days
    if (result) {
      const sortedList = sortListBySequence(result)
      setExerciseDays(sortedList)
    }
  }, [data])

  const deleteExercises = (ids: any[]): void => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'You are planning to delete',
      description,
    }).then(() => {
      // eslint-disable-next-line promise/no-nesting
      deleteExercisesById({
        variables: {
          ids,
        },
      })
        .then(() => {
          enqueueSnackbar('Deleted successfully', { variant: 'success' })
          exerciseDaysQuery.refetch()
        })
        .catch(console.error)
    })
  }

  const saveExercises = (body: any, callback: () => void): void => {
    upsertExercises({
      variables: {
        objects: body,
      },
    })
      .then(() => {
        enqueueSnackbar('Saved successfully', { variant: 'success' })
        exerciseDaysQuery.refetch()
        // eslint-disable-next-line promise/no-callback-in-promise
        callback()
      })
      .catch(console.error)
  }

  const onSelectExerciseType = (exerciseDay: IExerciseDays, groupType: string): void => {
    insertExerciseGroup({
      variables: {
        exerciseDayId: exerciseDay.id,
        groupType,
        order: exerciseDay.exercise_groups.length + 1,
      },
    })
      .then(() => {
        enqueueSnackbar('Saved successfully', { variant: 'success' })
        exerciseDaysQuery.refetch()
      })
      .catch(console.error)
  }

  const onSelectTemplate = (template: IWorkoutTemplates | null): void => {
    setSelectedTemplate(template ?? undefined)
  }

  const [insertExerciseDays] = useInsertExerciseDaysMutation()

  const onOverwriteHandler = (): void => {
    if (selectedTemplate) {
      confirm({
        variant: 'danger',
        catchOnCancel: false,
        title: 'You are planning to overwrite this plan',
        description,
      }).then(() => {
        const ids = exerciseDays.map((exDay) => exDay.id)
        // eslint-disable-next-line promise/no-nesting
        deleteExerciseDays({
          variables: {
            ids,
          },
        })
          .then(() => {
            const newExerciseDays = selectedTemplate.exercise_days.map((day) => ({
              workout_plan_id: workoutPlanId,
              sequence: day.sequence,
              exercise_groups: {
                data: day.exercise_groups.map((group) => ({
                  group_type: group.group_type,
                  order: group.order,
                  exercises: {
                    data: group.exercises.map((exercise) => {
                      return {
                        sets: exercise.sets,
                        rest: exercise.rest,
                        sequence: exercise.sequence,
                        repetition: exercise.repetition,
                        unit: exercise.unit,
                        exercise_variant_id: exercise.exercise_variant.id,
                      }
                    }),
                  },
                })),
              },
            }))
            // eslint-disable-next-line promise/no-nesting
            insertExerciseDays({
              variables: {
                objects: newExerciseDays,
              },
            })
              .then(() => {
                enqueueSnackbar('Successfully saved exersise days', {
                  variant: 'success',
                })
                setSelectedTemplate(undefined)
                exerciseDaysQuery.refetch()
              })
              .catch(console.error)
          })
          .catch(console.error)
      })
    } else {
      enqueueSnackbar('Selecteer een template', {
        variant: 'warning',
      })
    }
  }

  const onSaveAsTemplate = (): void => {
    if (newTemplateName.trim().length > 0) {
      confirm({
        variant: 'danger',
        catchOnCancel: false,
        title: 'You are planning to delete',
        description,
      }).then(() => {
        const template = [
          {
            name: newTemplateName,
            day_count: wPlan.data?.workout_plans_by_pk?.day_count,
            exercise_days: {
              data: exerciseDays.map((day) => ({
                sequence: day.sequence,
                exercise_groups: {
                  // eslint-disable-next-line sonarjs/no-identical-functions
                  data: day.exercise_groups.map((group) => ({
                    group_type: group.group_type,
                    order: group.order,
                    exercises: {
                      // eslint-disable-next-line sonarjs/no-identical-functions
                      data: group.exercises.map((exercise) => {
                        return {
                          sets: exercise.sets,
                          rest: exercise.rest,
                          sequence: exercise.sequence,
                          repetition: exercise.repetition,
                          unit: exercise.unit,
                          exercise_variant_id: exercise.exercise_variant.id,
                        }
                      }),
                    },
                  })),
                },
              })),
            },
          },
        ]

        // eslint-disable-next-line promise/no-nesting
        insertWorkoutTemplates({
          variables: {
            objects: template,
          },
          refetchQueries: ['GetWorkoutTemplates'],
        })
          .then(() => {
            enqueueSnackbar('Successfully saved template', {
              variant: 'success',
            })
            setNewTemplateName('')
            setMenuOpen(false)
          })
          .catch(console.error)
      })
    } else {
      enqueueSnackbar('Naam moet bestaat uit minimaal 1 karater', {
        variant: 'warning',
      })
    }
  }

  const onTemplateNameChange = (event: any): void => {
    const newValue = event.target.value
    setNewTemplateName(newValue)
  }

  const recordButtonPosition = (event: any): void => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }

  const closeMenu = (): void => {
    setMenuOpen(false)
  }

  const previewMode = selectedTemplate !== undefined

  const allDays = selectedTemplate ? selectedTemplate.exercise_days : exerciseDays

  const onDrag = (idx: number, array: IExerciseGroups[], day: IExerciseDays): void => {
    if (!day.workout_template_id) {
      const _isOrderChanged = (newArray: IExerciseGroups[], array2: IExerciseGroups[]): boolean => {
        return newArray.some((item, index) => item.id !== array2[index].id)
      }

      if (_isOrderChanged(array, exerciseDays[idx].exercise_groups)) {
        const promises = array.map(async (item, index) => {
          return updateExerciseGroupOrder({
            variables: {
              id: item.id,
              order: index,
            },
          })
            .then(() => console.warn('Order for', item, 'is changed'))
            .catch(console.error)
        })

        Promise.all(promises)
          .then(() =>
            enqueueSnackbar('Order changed successfully', {
              variant: 'success',
            })
          )
          .catch(console.error)
          .finally(() => {
            exerciseDaysQuery.refetch()
          })
      }
    }
  }

  const fullName = `${wPlan.data?.workout_plans_by_pk?.user.first_name} ${wPlan.data?.workout_plans_by_pk?.user.last_name}`
  const breadcrumbData: IBreadcrumb[] = [
    { isLink: true, to: '/clients', name: 'Members' },
    {
      isLink: true,
      to: `/user/${wPlan.data?.workout_plans_by_pk?.user_id}`,
      name: fullName,
    },
    { isLink: false, name: 'Training Schema' },
  ]

  return (
    <React.Fragment>
      <WithDrawer breadcrumbData={breadcrumbData}>
        <Box
          {...{
            component: 'div',
            className: classes.container,
          }}
        >
          <div style={{ display: 'flex' }}>
            <Autocomplete
              classes={{
                paper: classes.paper,
                option: classes.option,
                popperDisablePortal: classes.popperDisablePortal,
              }}
              clearOnEscape
              value={selectedTemplate ?? null}
              options={workoutTemplates}
              getOptionLabel={(option): string => option.name}
              // @ts-ignore
              onChange={(__, newValue: IWorkoutTemplates | null): void => onSelectTemplate(newValue)}
              style={{ width: 300 }}
              renderInput={(params): JSX.Element => (
                <TextField
                  {...params}
                  style={{ backgroundColor: '#Fff' }}
                  label="Workout Templates"
                  variant="outlined"
                  size="small"
                  color="primary"
                />
              )}
            />
            <Button
              disabled={!previewMode}
              disableElevation
              color="primary"
              variant="contained"
              size="small"
              style={{ marginRight: 'auto', marginLeft: '10px' }}
              onClick={onOverwriteHandler}
            >
              Overschrijven
            </Button>

            <Button
              disableElevation
              color="primary"
              size="small"
              variant="contained"
              disabled={previewMode}
              startIcon={<AddIcon />}
              onClick={recordButtonPosition}
            >
              Opslaan als template
            </Button>
            <Popover
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={closeMenu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  placeholder="Naam"
                  onChange={onTemplateNameChange}
                  defaultValue=""
                  InputProps={{
                    style: { borderRadius: 0 },
                  }}
                  style={{ borderRadius: 0 }}
                  variant="outlined"
                />
                <Button
                  style={{ borderRadius: 0 }}
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={onSaveAsTemplate}
                >
                  Opslaan
                </Button>
              </div>
            </Popover>
          </div>
          {allDays.map((day: IExerciseDays, index: number) => (
            <React.Fragment key={`${day.id}-parent`}>
              <div className={classes.dayWrapper}>
                <Typography style={{ marginRight: 'auto' }} variant="h6" component="h6">
                  {`Dag ${day.sequence}`}
                </Typography>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ReactSortable
                  handle=".sort-icon"
                  animation={200}
                  delay={2}
                  list={[...day.exercise_groups]}
                  setList={(array): void => onDrag(index, array, day)}
                >
                  {[...day.exercise_groups]
                    .sort((first, second) => first.order - second.order)
                    .map((group, idx) => {
                      const props = {
                        key: `${idx}-exercise`,
                        group,
                        onSave: saveExercises,
                        onDelete: deleteExercises,
                        previewMode,
                      }
                      switch (group.group_type) {
                        case 'exercise':
                          return <OefeningTableRow {...props} />
                        case 'exercise-time':
                          return <OefeningOpTijdTableRow {...props} />
                        case 'superset':
                          return <SuperSetTableRow {...props} />
                        case 'dropset':
                          return <DropSetTableRow {...props} />
                        case 'circuit':
                          return <CircuitTableRow {...props} />
                        default:
                          return <div key={`${idx}-exercise`}>Type niet gevonden</div>
                      }
                    })}
                </ReactSortable>
              </div>

              {!previewMode && (
                <Grid container style={{ display: 'flex', flexDirection: 'column' }} className={classes.tableContainer}>
                  <ButtonGroup style={{ display: 'flex', justifyContent: 'center' }} size="small">
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={(): void => onSelectExerciseType(day, 'exercise')}
                    >
                      Oefening
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={(): void => onSelectExerciseType(day, 'exercise-time')}
                    >
                      Oefening op tijd
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={(): void => onSelectExerciseType(day, 'superset')}
                    >
                      Superset
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={(): void => onSelectExerciseType(day, 'dropset')}
                    >
                      Dropset
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={(): void => onSelectExerciseType(day, 'circuit')}
                    >
                      Circuit
                    </Button>
                  </ButtonGroup>
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Box>
      </WithDrawer>
    </React.Fragment>
  )
}

export default TrainingSchemaItem
