import { Button, Grid, Paper } from '@material-ui/core'
import clsx from 'clsx'
import { Loading } from 'components/Loading'
import React, { useState } from 'react'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { ICookbookCategories, useGetCookbookQuery } from '../../generated/graphql'
import WithDrawer from '../../wrappers'
import CategoriesTable from './components/CookbookTable'
import Modal from './components/Modal'
import { useStyles } from './styles'
import useCookbookCategories from './useCookbookCategories'

const Cookbook: React.FunctionComponent = () => {
  const classes = useStyles()

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  const [targetCategory, setTargetCategory] = useState<ICookbookCategories | undefined>(undefined)
  const [isModalOpened, setModalOpened] = useState(false)

  const { data, loading } = useGetCookbookQuery()

  const closeModal = () => {
    setModalOpened(false)
    setTargetCategory(undefined)
  }

  const updateCategory = (category: ICookbookCategories) => {
    setTargetCategory(category)
    setModalOpened(true)
  }

  const { deleteById, isLoading } = useCookbookCategories()

  const deleteCategory = (id: number) => {
    deleteById(id)
  }

  const breadcrumbData: IBreadcrumb[] = [{ isLink: false, name: 'RECIPES' }]

  return (
    <WithDrawer breadcrumbData={breadcrumbData}>
      {loading || (isLoading && <Loading />)}
      <Paper
        {...{
          elevation: 0,
          className: fixedHeightPaper,
          style: {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            boxShadow: '2px 2px 21px rgba(0, 0, 0, 0.06)',
          },
        }}
      >
        <Grid container className={classes.gridWrapper}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.containedBtn}
            onClick={() => setModalOpened(true)}
          >
            + Voeg categorie toe
          </Button>

          {!data?.cookbook_categories.length ? null : (
            <Grid container className={classes.tableContainer}>
              <CategoriesTable
                categories={data?.cookbook_categories as ICookbookCategories[]}
                onUpdate={updateCategory}
                onDelete={deleteCategory}
              />
            </Grid>
          )}
        </Grid>
      </Paper>

      {isModalOpened && <Modal item={targetCategory} closeModal={closeModal} />}
    </WithDrawer>
  )
}

export default Cookbook
