import { Badge } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import NotificationsIcon from '@material-ui/icons/Notifications'
import React from 'react'
import { useHistory } from 'react-router'

import { GetNotificationsDocument, useGetNotificationsQuery, useSetSeenAtMutation } from '../../../generated/graphql'

// @ts-ignore
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    open
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

export const Notifications = (): JSX.Element | null => {
  const { data, loading } = useGetNotificationsQuery()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { push } = useHistory()
  const progressDays = data?.progress_days
  const count = progressDays?.length || 0
  const [setSeenAt] = useSetSeenAtMutation()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (loading) return null

  const onClick = (id: number): void => {
    setSeenAt({ variables: { id }, refetchQueries: [{ query: GetNotificationsDocument }], awaitRefetchQueries: true })
      .then((response) => {
        const user = response.data?.update_progress_days_by_pk?.user
        if (user) push(`/user/${user.id}`)
        else console.error('Something went wrong', response.errors)
      })
      .catch(console.error)
  }

  return (
    <div>
      <IconButton aria-label={`show ${count} new notifications`} color="inherit" onClick={handleClick}>
        <Badge badgeContent={count} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {/* @ts-ignore */}
      <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {progressDays?.map((day) => (
          <StyledMenuItem key={day.id} onClick={(): void => onClick(day.id)}>
            <ListItemText primary={`Nieuw progressie update van ${day.user.first_name} ${day.user.last_name}`} />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}
