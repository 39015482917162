import { Button, CardMedia, makeStyles, TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import RichTextEditor from 'react-rte'

import ContainerModal from '../../../components/ContainerModal'
import { ICreate, IUpdate, TBlogArticle } from '../interfaces'
import useBlogArticles from '../useBlogArticles'

const toolbarConfig: any = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
}

interface IProps {
  closeModal: () => void
  item?: TBlogArticle | undefined
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  informationWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  spacingBottom: {
    marginBottom: 10,
  },
  mediaWrapper: {
    display: 'flex',
  },
  deleteBtn: {
    backgroundColor: 'rgb(209,30,53)',
    width: '100%',
  },
}))

const Modal: React.FC<IProps> = ({ closeModal, item }) => {
  const classes = useStyles()
  const { insert, updateById, deleteById, isLoading } = useBlogArticles(closeModal)
  const { categoryId } = useParams()

  const [title, setTitle] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [tag, setTag] = useState<string>('')
  const [photoUrl, setPhotoUrl] = useState<string>('')

  const [editorData, setEditorData] = useState(RichTextEditor.createEmptyValue())

  useEffect(() => {
    if (item) {
      setTitle(item.title)
      setText(item.text)
      setTag(item.tag)
      setPhotoUrl(item.photo_url)
      setEditorData(RichTextEditor.createValueFromString(item.text, 'html'))
    }
  }, [item])

  const onChange = (value: any): void => {
    setEditorData(value)
  }

  const isEmpty = (content: string): boolean => {
    return content.trim().length === 0
  }

  const onSave = (): void => {
    // let photos = `{ ${photoUrl} }`
    // if (!isEmpty(secondImageUrl)) photos = `{ ${photoUrl}, ${secondImageUrl} }`

    const body: ICreate | IUpdate = {
      title,
      text: editorData.toString('html'),
      tag,
      photo_url: photoUrl,
      blog_category_id: categoryId,
    }

    if (item) updateById(item.id, body)
    else insert(body)
  }

  const disableConfirmButton = isEmpty(title) || isEmpty(photoUrl) || isLoading || isEmpty(tag)

  return (
    <ContainerModal
      title={item ? 'Wijzigen' : 'Toevoegen'}
      isOpen
      handleClose={closeModal}
      handleSubmit={onSave}
      disabled={disableConfirmButton}
    >
      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Titel*: </Typography>
        <TextField
          multiline
          rows={3}
          variant="outlined"
          size="small"
          value={title}
          onChange={(event): void => setTitle(event.target.value)}
        />
      </div>
      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Tag*: </Typography>
        <TextField variant="outlined" size="small" value={tag} onChange={(event): void => setTag(event.target.value)} />
      </div>

      <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
        <Typography>Foto url*: </Typography>
        <TextField
          multiline
          rows={2}
          variant="outlined"
          size="small"
          value={photoUrl}
          onChange={(event): void => setPhotoUrl(event.target.value)}
        />
      </div>

      <div className={classes.informationWrapper} style={{ alignItems: 'center' }}>
        <Typography>Voorbeeld: </Typography>
        <div className={clsx(classes.informationWrapper, classes.spacingBottom)}>
          <CardMedia
            {...{
              component: 'img',
              image: photoUrl,
              style: { width: 100, height: 100, marginRight: 3 },
            }}
          />
        </div>
      </div>

      <RichTextEditor
        rootStyle={{
          marginBottom: 10,
        }}
        editorStyle={{
          overflow: 'auto',
          maxHeight: 200,
          minHeight: 100,
        }}
        value={editorData}
        onChange={onChange}
        toolbarConfig={toolbarConfig}
      />

      {item && (
        <Button
          variant="contained"
          color="primary"
          className={classes.deleteBtn}
          onClick={(): void => deleteById(item.id, categoryId)}
        >
          Verwijder
        </Button>
      )}
    </ContainerModal>
  )
}

export default Modal
