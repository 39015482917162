import { createContext } from 'react'

function noop(): void {}

interface IAuthContextProps {
  token: string | null
  userId: string | null
  role: string | null
  login: (jwtToken: string, id: string, role: string) => void
  logout: () => void
  isAuthenticated: boolean
}

export const AuthContext = createContext<IAuthContextProps>({
  token: null,
  userId: null,
  role: null,
  login: noop,
  logout: noop,
  isAuthenticated: false,
})
