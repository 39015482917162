import { Button, TextField } from '@material-ui/core'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import React, { useEffect, useRef, useState } from 'react'

import {
  useGetMessagesByInboxIdSubscription,
  useInsertMessageMutation,
  useMarkAsReadMutation,
} from '../../../generated/graphql'
import { IProps } from './interfaces'
import { useStyles } from './styles'

export const ChatBox: React.FC<IProps> = ({ inbox }): JSX.Element => {
  const classes = useStyles()
  const [message, setMessage] = useState<string>('')
  const scroll = useRef<any>()

  const { data } = useGetMessagesByInboxIdSubscription({
    variables: {
      //  @ts-ignore
      id: inbox?.id,
    },
    skip: !inbox,
  })

  const messages = data?.inbox_messages

  const scrollToBottom = (): void => {
    const scrollHeight = scroll?.current?.scrollHeight
    const height = scroll?.current?.clientHeight
    const maxScrollTop = scrollHeight - height
    scroll.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
  }

  useEffect(() => {
    if (messages && messages.length > 0) scrollToBottom()
  }, [messages])

  const [insertMessage] = useInsertMessageMutation()
  const submit = (): void => {
    if (!message || !inbox) return

    insertMessage({
      variables: {
        message,
        inboxId: inbox.id,
        senderId: inbox.coach.id,
      },
    })
      .then(() => {
        setMessage('')
      })
      .catch((error) => console.error(error))
  }

  const [markAsRead] = useMarkAsReadMutation()

  useEffect(() => {
    if (data) {
      const unreadIds = data?.inbox_messages.reduce<number[]>((ids, obj) => {
        if (!obj.read && obj.sender_id !== obj.inbox.coach_id) return [...ids, obj.id]
        return ids
      }, [])

      if (unreadIds.length > 0) {
        markAsRead({
          variables: {
            ids: unreadIds,
          },
        })
          .then((response) => {
            if (response.data?.update_inbox_messages?.affected_rows === unreadIds.length)
              console.warn(`Marked ${unreadIds.length} messages as read`)
          })
          .catch((err) => console.error(err))
      }
    }
  }, [data, markAsRead])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
      <div ref={scroll} style={{ display: 'flex', flexDirection: 'column', maxHeight: 500, overflow: 'auto' }}>
        {data &&
          inbox &&
          data.inbox_messages.map((item) => {
            const isMyMessage = item.sender_id === inbox.coach.id
            if (isMyMessage) {
              return (
                <div
                  key={item.id}
                  id="right-bubble"
                  style={{
                    display: 'flex',
                    backgroundColor: '#3F51B5',
                    padding: 10,
                    maxWidth: '70%',
                    alignSelf: 'flex-end',
                    marginBottom: 10,
                    borderRadius: 10,
                    flexDirection: 'column',
                  }}
                >
                  <p style={{ margin: 0, color: '#fff' }}>{item.message}</p>

                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 'auto' }}>
                    <p style={{ margin: 0, fontSize: 11, color: '#fff', fontWeight: 300 }}>
                      {item.updated_at.substr(11, 5)}
                    </p>
                    {item.read && <DoneAllIcon style={{ color: '#fff', fontSize: 12, marginLeft: 'auto' }} />}
                  </div>
                </div>
              )
            }
            return (
              <div
                key={item.id}
                id="left-bubble"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#fff',
                  padding: 10,
                  maxWidth: '70%',
                  marginBottom: 10,
                  borderRadius: 10,
                }}
              >
                <p style={{ margin: 0, color: '#000000' }}>{item.message}</p>

                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                  <p style={{ margin: 0, fontSize: 11, fontWeight: 300 }}>{item.updated_at.substr(11, 5)}</p>
                </div>
              </div>
            )
          })}
      </div>

      {inbox && (
        <div style={{ display: 'flex', marginTop: 'auto' }}>
          <TextField
            className={classes.input}
            multiline
            style={{ width: '100%', backgroundColor: 'white', borderRadius: '5px' }}
            value={message}
            onChange={(event): void => setMessage(event.target.value)}
          />
          <Button id="send-button" color="primary" variant="contained" onClick={submit}>
            Verstuur
          </Button>
        </div>
      )}
    </div>
  )
}
