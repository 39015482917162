/* eslint radix: ["error", "as-needed"] */

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { addMonths, format } from 'date-fns'
import { nl } from 'date-fns/locale'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { InputSelectTabs } from '../../components/InputSelectTabs'
import { MemberCard } from '../../components/MemberCard'
import { SearchPanel } from '../../components/SearchPanel'
import { useGetProgramPlanByIdQuery, useGetProgramPlanParticipantsQuery } from '../../generated/graphql'
import WithDrawer from '../../wrappers'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  searchBarContainer: {
    marginBottom: 40,
    paddingTop: 30,
  },
  filtersWrapper: {
    justifyContent: 'flex-end',
    display: 'flex',
    placeSelf: 'flex-start',
  },
  heading: {
    paddingTop: '0px !important',
  },
}))

const filterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Not active', value: 'blocked' },
]

export const ProgramPage: React.FunctionComponent = () => {
  const classes = useStyles()
  const breadcrumbData: IBreadcrumb[] = [{ isLink: false, name: 'MEMBERS' }]
  const { programId } = useParams()

  const [filter, setFilter] = React.useState(filterOptions[0].value)
  const [searchString, setSearchString] = React.useState('')

  const { data } = useGetProgramPlanByIdQuery({
    variables: { programPlanId: parseInt(programId) },
  })

  const participantsResult = useGetProgramPlanParticipantsQuery({
    variables: { programPlanId: parseInt(programId) },
  })

  const datePlus = (date: string | undefined, plus?: number): string => {
    if (!date) return '-'
    const dateObject = plus ? addMonths(new Date(date), plus) : new Date(date)
    return format(dateObject, 'dd MMMM yyyy', { locale: nl })
  }

  const subscriptionDate = (() => {
    const startsAt = datePlus(data?.program_plans_by_pk?.starts_at)
    const endsAt = datePlus(
      data?.program_plans_by_pk?.starts_at,
      data?.program_plans_by_pk?.program?.duration_amount || 0
    )

    return { startsAt, endsAt }
  })()

  const participants = participantsResult?.data?.program_plan_participators

  const filtered =
    filter === filterOptions[0].value
      ? participants?.filter(
          (item) => `${item.user.first_name} ${item.user.last_name} ${item.user.email}`.indexOf(searchString) !== -1
        )
      : participants?.filter(
          (item) =>
            !!item.user.is_blocked === (filter !== filterOptions[1].value) &&
            `${item.user.first_name} ${item.user.last_name} ${item.user.email}`.indexOf(searchString) !== -1
        )

  return (
    <WithDrawer breadcrumbData={breadcrumbData}>
      <Box
        {...{
          component: 'div',
          className: classes.container,
        }}
      >
        <Grid container spacing={6} style={{ alignItems: 'center' }} className={classes.searchBarContainer}>
          <Grid item sm={12} md={6}>
            <SearchPanel placeholder="Search client" onSubmit={(value): void => setSearchString(value)} />
          </Grid>

          <Grid item sm={12} md={6} className={classes.filtersWrapper}>
            <InputSelectTabs
              value={filter}
              onChange={(newValue): void => setFilter(newValue)}
              options={filterOptions}
            />
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item sm={12} md={12} className={classes.heading}>
            <Typography variant="h6" component="h6">
              {data?.program_plans_by_pk?.program?.name ?? ''}
              {` ${subscriptionDate.startsAt} - 
              ${subscriptionDate.endsAt}`}
            </Typography>
          </Grid>

          <Grid item sm={12} md={12}>
            <Grid container>
              {filtered?.map((item, index) => {
                return (
                  <MemberCard
                    key={index}
                    user={item?.user}
                    startsAt={subscriptionDate.startsAt}
                    endsAt={subscriptionDate.endsAt}
                  />
                )
              })}

              {filtered?.length === 0 && (
                <Typography variant="h6" component="h6">
                  Er zijn geen clienten gevonden
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </WithDrawer>
  )
}
