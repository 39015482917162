/* eslint radix: ["error", "as-needed"] */

import { Button, Grid, Paper } from '@material-ui/core'
import clsx from 'clsx'
import { useGetUserByIdQuery } from 'generated/graphql'
import * as React from 'react'
import { useParams } from 'react-router'

import { NotCreated } from '../components/NotCreated'
import { HorizontalStepper } from '../components/Stepper'
import { VoedingssamenstellingView } from '../components/VoedingssamenstellingView'
import { useStyles } from '../useStyles'

interface IProps {
  updateTab: (newValue: number) => void
}

export const VoedingssamenstellingTab: React.FunctionComponent<IProps> = ({ updateTab }) => {
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  const [isEdit, setIsEdit] = React.useState(false)
  const { userId } = useParams<{ userId: string }>()

  const onPressHandler = (): void => {
    setIsEdit(true)
  }

  const userQuery = useGetUserByIdQuery({
    variables: { userId: parseInt(userId) },
  })

  const { data } = userQuery
  const userInfo = data?.users_by_pk

  const handleSuccess = (): void => {
    userQuery.refetch()
  }

  const hasNoNutritionalComposition = !userInfo?.user_profile?.diet_summary

  return (
    <Paper
      {...{
        className: fixedHeightPaper,
        style: {
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          boxShadow: '2px 2px 21px rgba(0, 0, 0, 0.06)',
        },
      }}
    >
      <Grid container className={classes.gridWrapper}>
        {!isEdit && hasNoNutritionalComposition && (
          <NotCreated
            {...{
              onPress: onPressHandler,
              primary: 'No nutritional composition created',
              secondary:
                'Op deze pagina vind je een kort overzicht van de benodigde calorie inname om op gewicht te blijven en de gewenste calorie inname voor het doel. Daarbij kan je de macronutrienten verhoudingen ook terug vinden in het overzicht. Dit is het beste advies voor de persoonlijke doel van de lid.',
              buttonText: 'Create composition',
              image: '../../images/create_nutritions.png',
            }}
          />
        )}
        {isEdit && userInfo && (
          <HorizontalStepper user={userInfo} onSuccessHandler={handleSuccess} onClose={(): void => setIsEdit(false)} />
        )}

        {!isEdit && !hasNoNutritionalComposition && (
          <div
            style={{
              padding: '0 107px 100px',
            }}
          >
            <VoedingssamenstellingView />
          </div>
        )}

        {!isEdit && !hasNoNutritionalComposition && (
          <Grid container style={{ padding: '0 107px' }}>
            <Button
              onClick={(): void => updateTab(2)}
              variant="contained"
              color="primary"
              className={classes.containedBtn}
            >
              Ga naar Voedingsschema
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.containedBtn}
              onClick={(): void => setIsEdit(true)}
            >
              Edit Voedingsschema
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}
