import { Avatar, Box, Card, CardContent, Typography } from '@material-ui/core'
// MUI
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { IGetProgramPlanParticipantsQuery } from '../../generated/graphql'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 240,
    minWidth: 240,
    marginRight: 20,
    marginBottom: 20,
    boxShadow: '2px 2px 21px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
    '&:hover': {
      border: '2px solid #3F51B5',
    },
  },
  cardMediaWrapper: {
    padding: `${theme.spacing(1)}px 0`,
    textAlign: 'center',
    border: '2px solid #fff',
  },
  userEmail: {
    fontSize: 12,
    color: '#AEAEAE',
  },
  userStatus: {
    maxWidth: 70,
    margin: '10px auto 15px',
    display: 'block',
    padding: '5px 16px',
    background: '#EFF1FF',
    borderRadius: 2,
    fontSize: 12,
    color: '#3F51B5',
    textTransform: 'uppercase',
  },
  cardMedia: {
    width: 70,
    height: 70,
    margin: '0 auto 20px',
  },
  cardContent: {
    padding: 0,
  },
  memberInfo: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    borderTop: '1px solid #E4E4E4',
  },
  memberInfoFont: {
    fontSize: 12,
    color: '#AEAEAE',
    '&.MuiTypography-body1': {
      color: '#222',
    },
  },
  bold: {
    fontWeight: 500,
  },
}))

type TParticipant = NonNullable<IGetProgramPlanParticipantsQuery['program_plan_participators'][0]['user']>

interface IProps {
  startsAt?: string | undefined
  endsAt?: string | undefined
  user: TParticipant
}

export const MemberCard: React.FC<IProps> = ({ user, startsAt, endsAt }): JSX.Element => {
  const classes = useStyles()

  return (
    <Link
      {...{
        to: `/user/${user?.id}`,
        style: { textDecoration: 'none' },
      }}
    >
      <Card {...{ className: classes.card }}>
        <Box
          {...{
            className: classes.cardMediaWrapper,
            component: 'div',
          }}
        >
          <Avatar
            {...{
              alt: 'ALT',
              src: user.avatar_url || './images/user_photo.png',
              className: classes.cardMedia,
            }}
          />

          <Typography
            gutterBottom
            {...{
              variant: 'body1',
              component: 'h2',
              className: classes.bold,
            }}
          >
            {`${user.first_name || ''} ${user.middle_name || ''} ${user.last_name || ''}`}
          </Typography>

          <Typography
            {...{
              variant: 'body2',
              component: 'span',
              className: classes.userEmail,
            }}
          >
            {user.email}
          </Typography>

          <Typography
            {...{
              variant: 'body2',
              component: 'span',
              className: clsx(classes.userStatus, classes.bold),
            }}
          >
            {user.is_blocked ? 'Blocked' : 'Active'}
          </Typography>
        </Box>

        <CardContent
          {...{
            className: classes.cardContent,
          }}
        >
          <Box
            {...{
              component: 'div',
              className: classes.memberInfo,
              style: { paddingBottom: 0 },
            }}
          >
            <Box
              {...{
                component: 'div',
              }}
            >
              <Typography
                {...{
                  variant: 'body2',
                  component: 'span',
                  className: classes.memberInfoFont,
                }}
              >
                Start date:
              </Typography>
              <Typography
                {...{
                  variant: 'body1',
                  component: 'p',
                  className: clsx(classes.memberInfoFont, classes.bold),
                }}
              >
                {startsAt || 'Onbekend'}
              </Typography>
            </Box>

            <Box
              {...{
                component: 'div',
              }}
            >
              <Typography
                {...{
                  variant: 'body2',
                  component: 'span',
                  className: classes.memberInfoFont,
                }}
              >
                End date:
              </Typography>
              <Typography
                {...{
                  variant: 'body1',
                  component: 'p',
                  className: clsx(classes.memberInfoFont, classes.bold),
                }}
              >
                {endsAt || 'Onbekend'}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Link>
  )
}
