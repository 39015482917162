import { useCallback, useEffect, useState } from 'react'

const storageName = 'userData'

export const useAuth = () => {
  const [token, setToken] = useState(null)
  const [ready, setReady] = useState(false)
  const [userId, setUserId] = useState(null)
  const [role, setRole] = useState<string | null>(null)

  const login = useCallback((jwtToken, id, userRole) => {
    setToken(jwtToken)
    setUserId(id)
    setRole(userRole)

    localStorage.setItem(
      storageName,
      JSON.stringify({
        userId: id,
        token: jwtToken,
        role: userRole,
      })
    )
  }, [])

  const logout = useCallback(() => {
    setToken(null)
    setUserId(null)
    localStorage.removeItem(storageName)
  }, [])

  useEffect(() => {
    const data = JSON.parse(String(localStorage.getItem(storageName)))

    if (data && data.token) {
      login(data.token, data.userId, data.role)
      setToken(data?.token)
    }
    setReady(true)
  }, [login])

  return { login, logout, token, userId, ready, role }
}
