import { Paper, Typography } from '@material-ui/core'
import React from 'react'

import ProgressBar from '../../../components/ProgressBar'
import { getPercent } from '../../MealPlanClone/CurrentIndicators/helpers'
import { useStyles } from '../useStyles'

interface IProps {
  caloriesPerDay: any
  total: any
  details: any
}

export const CurrentIndicators: React.FC<IProps> = ({ caloriesPerDay, details, total }): JSX.Element => {
  const classes = useStyles()

  return (
    <Paper className={classes.card} elevation={3}>
      <Typography className={classes.cardHead} variant="h6" component="h6">
        Huidige indicatoren
      </Typography>

      <div className={classes.cardLine}>
        <div>
          <Typography component="p" variant="body1" className={classes.indicatorName}>
            Calorieen:
          </Typography>
          <Typography component="p" variant="body2" className={classes.goalName}>
            {`Doel: ${caloriesPerDay} KCAL`}
          </Typography>
        </div>

        <div className={classes.progressBarContainer}>
          <ProgressBar value={getPercent(total.calorie_sum, total.calories_per_day)} />
        </div>

        <Typography component="p" variant="body2" className={classes.indicatorValue}>
          {`${total.calorie_sum.toFixed(2)} kcal`}
        </Typography>
      </div>

      <div className={classes.cardLine}>
        <div>
          <Typography component="p" variant="body1" className={classes.indicatorName}>
            Koolhydraten:
          </Typography>
          <Typography component="p" variant="body2" className={classes.goalName}>
            {`Doel: ${details?.nutrition_plan_meals_view[0]?.min_carbo ?? ''} - ${
              details?.nutrition_plan_meals_view[0]?.max_carbo ?? ''
            }`}
          </Typography>
        </div>

        <div className={classes.progressBarContainer}>
          <ProgressBar value={getPercent(total.carbo_sum, total.max_carbo)} />
        </div>

        <Typography component="p" variant="body2" className={classes.indicatorValue}>
          {`${total.carbo_sum.toFixed(2)} gram`}
        </Typography>
      </div>

      <div className={classes.cardLine}>
        <div>
          <Typography component="p" variant="body1" className={classes.indicatorName}>
            Eiwitten:
          </Typography>
          <Typography component="p" variant="body2" className={classes.goalName}>
            {`Doel: ${details?.nutrition_plan_meals_view[0]?.min_protein ?? ''} - ${
              details?.nutrition_plan_meals_view[0]?.max_protein ?? ''
            }`}
          </Typography>
        </div>
        <div className={classes.progressBarContainer}>
          <ProgressBar value={getPercent(total.protein_sum, total.max_protein)} />
        </div>

        <Typography component="p" variant="body2" className={classes.indicatorValue}>
          {`${total.protein_sum.toFixed(2)} gram`}
        </Typography>
      </div>

      <div className={classes.cardLine}>
        <div>
          <Typography component="p" variant="body1" className={classes.indicatorName}>
            Vetten:
          </Typography>
          <Typography component="p" variant="body2" className={classes.goalName}>
            {`Doel: ${details?.nutrition_plan_meals_view[0]?.min_fat ?? ''} - ${
              details?.nutrition_plan_meals_view[0]?.max_fat ?? ''
            }`}
          </Typography>
        </div>

        <div className={classes.progressBarContainer}>
          <ProgressBar value={getPercent(total.fat_sum, total.max_fat)} />
        </div>

        <Typography component="p" variant="body2" className={classes.indicatorValue}>
          {`${total.fat_sum.toFixed(2)} gram`}
        </Typography>
      </div>
    </Paper>
  )
}
