import { Button, Grid, Paper } from '@material-ui/core'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { IBreadcrumb } from '../../components/AppBar/interfaces'
import { Loading } from '../../components/Loading'
import { useGetKnowledgeBaseByPkQuery } from '../../generated/graphql'
import WithDrawer from '../../wrappers'
import CategoriesTable from './components/CategoriesTable'
import Modal from './components/Modal'
import { TKnowledgeBaseVideos } from './interfaces'
import { useStyles } from './styles'

const KnowledgeBaseVideosPage = (): JSX.Element => {
  const classes = useStyles()
  const { categoryId } = useParams<{ categoryId: string }>()

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  const [targetCategory, setTargetCategory] = useState<TKnowledgeBaseVideos[0] | undefined>(undefined)
  const [isModalOpened, setModalOpened] = useState(false)

  const { data, loading } = useGetKnowledgeBaseByPkQuery({
    variables: {
      id: +categoryId,
    },
  })

  const closeModal = (): void => {
    setModalOpened(false)
    setTargetCategory(undefined)
  }

  const updateCategory = (category: TKnowledgeBaseVideos[0]): void => {
    setTargetCategory(category)
    setModalOpened(true)
  }

  const breadcrumbData: IBreadcrumb[] = [{ isLink: false, name: `KNOWLEDGE BASE VIDEO'S` }]

  const renderContent = (): JSX.Element | null => {
    if (loading) {
      return (
        <div style={{ zIndex: 10 }}>
          <Loading />
        </div>
      )
    }

    const knowledgeBaseVideos = data?.knowledge_base_by_pk?.knowledge_base_videos || []

    if (knowledgeBaseVideos.length === 0) return null

    return (
      <Grid container className={classes.tableContainer}>
        <CategoriesTable categories={knowledgeBaseVideos} onUpdate={updateCategory} />
      </Grid>
    )
  }

  return (
    <WithDrawer breadcrumbData={breadcrumbData}>
      <Paper
        {...{
          elevation: 0,
          className: fixedHeightPaper,
          style: {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            boxShadow: '2px 2px 21px rgba(0, 0, 0, 0.06)',
          },
        }}
      >
        <Grid container className={classes.gridWrapper}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            className={classes.containedBtn}
            onClick={(): void => setModalOpened(true)}
          >
            + Voeg video toe
          </Button>

          {renderContent()}
        </Grid>
      </Paper>

      {isModalOpened && <Modal item={targetCategory} closeModal={closeModal} />}
    </WithDrawer>
  )
}

export default KnowledgeBaseVideosPage
