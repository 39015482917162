import DateFnsUtils from '@date-io/date-fns'
import { Box, Select, TextField, Typography } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import nlLocale from 'date-fns/locale/nl'
import moment from 'moment'
import * as React from 'react'

interface IProps {
  isEditable: boolean
  type: string
  name: string
  defaultValue: any
  onChange: (value: any, additionalKey?: any) => void
  options?: any[]
  disabled?: boolean
}

const nietGespecificeerd = 'Niet gespecificeerd'
export const Switchable: React.FunctionComponent<IProps> = ({
  isEditable,
  type,
  name,
  defaultValue,
  onChange = () => {},
  options,
  disabled = false,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): JSX.Element => {
  if (!isEditable) {
    if (type === 'date') {
      return (
        <Typography {...rest}>
          {defaultValue ? moment().diff(moment(defaultValue), 'years', false) : nietGespecificeerd}
        </Typography>
      )
    }
    if (type === 'select') {
      return (
        <Typography {...rest}>
          {options?.find((option) => option.value === defaultValue)?.label ??
            defaultValue?.length ??
            nietGespecificeerd}
        </Typography>
      )
    }
    if (type === 'number') return <Typography {...rest}>{defaultValue ?? nietGespecificeerd}</Typography>

    return <Typography {...rest}>{defaultValue?.trim().length ? defaultValue : nietGespecificeerd}</Typography>
  }
  switch (type) {
    case 'number':
      return (
        <TextField
          name={name}
          margin="dense"
          onChange={onChange}
          type="number"
          variant="outlined"
          defaultValue={defaultValue}
        />
      )
    case 'text':
      return (
        <TextField
          name={name}
          onChange={onChange}
          margin="dense"
          type="text"
          variant="outlined"
          size="small"
          defaultValue={defaultValue}
          disabled={disabled}
          {...rest}
        />
      )
    case 'select':
      return (
        <Select
          disabled={disabled}
          name={name}
          margin="dense"
          native
          variant="outlined"
          defaultValue={defaultValue}
          // @ts-ignore
          onChange={onChange}
        >
          {options?.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>
      )
    case 'textarea':
      return (
        <TextField
          variant="outlined"
          multiline
          margin="dense"
          rows={6}
          name={name}
          fullWidth
          defaultValue={defaultValue}
          onChange={onChange}
        />
      )

    case 'date':
      return (
        <MuiPickersUtilsProvider locale={nlLocale} utils={DateFnsUtils}>
          <KeyboardDatePicker
            name={name}
            variant="inline"
            format="dd-MM-yyyy"
            margin="dense"
            id="date-picker-inline"
            value={defaultValue ? moment(defaultValue).toDate() : moment().toDate()}
            inputValue={defaultValue ? moment(defaultValue).format('DD-MM-yyyy') : moment().format('DD-MM-yyyy')}
            onChange={(newDate): void => onChange(newDate, name)}
            inputVariant="outlined"
            size="small"
            disableFuture
          />
        </MuiPickersUtilsProvider>
      )
    default:
      return <Box>Wrong type received!</Box>
  }
}

export default Switchable
