import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 245,
    marginRight: 10,
    boxShadow: '2px 2px 21px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
    '&:hover': {
      border: '2px solid #3F51B5',
      backgroundColor: '#fff',
    },
  },
  cardMediaWrapper: {
    padding: `${theme.spacing(2)}px`,
    border: '2px solid #fff',
  },
  taskTitle: {
    fontSize: 14,
    color: '#222',
  },
  avatar: {
    marginTop: 10,
    height: 25,
    width: 25,
  },
  actionArea: {
    '&:hover $focusHighlight': {
      opacity: 0,
    },
  },
}))
