import { Button, Grid, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { GetUserByIdDocument, useUpdateUserProfileByPkMutation } from '../../../../../generated/graphql'
import { TUser } from '../../../interfaces'
import Switchable from './Switchable'
import SwitchableChip from './SwitchableChip'
import SwitchableRadio from './SwitchableRadio'

interface IProps {
  userInfo: TUser
  editMode: boolean
  onClose: () => void
}

interface IUpdateProps {
  rythm: string
  weekend: string
  training_frequency: string
  meals_like: string[]
  meals_dislike: string[]
  is_vegan: boolean
  is_vegetarian: boolean
  is_allergic: boolean
  has_injuries: boolean
}

export const VoedingsTab: React.FunctionComponent<IProps> = ({ userInfo, editMode, onClose }) => {
  const { userId } = useParams()
  const [fields, setFields] = useState<IUpdateProps>({
    rythm: userInfo.user_profile?.rythm ?? '',
    weekend: userInfo.user_profile?.weekend ?? '',
    training_frequency: userInfo.user_profile?.training_frequency ?? '',
    meals_like: userInfo.user_profile?.meals_like ?? [],
    meals_dislike: userInfo.user_profile?.meals_dislike ?? [],
    is_vegan: userInfo.user_profile?.is_vegan ?? false,
    is_vegetarian: userInfo.user_profile?.is_vegetarian ?? false,
    is_allergic: userInfo.user_profile?.is_allergic ?? false,
    has_injuries: userInfo.user_profile?.has_injuries ?? false,
  })

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setFields({
      rythm: userInfo.user_profile?.rythm ?? '',
      weekend: userInfo.user_profile?.weekend ?? '',
      training_frequency: userInfo.user_profile?.training_frequency ?? '',
      meals_like: userInfo.user_profile?.meals_like ?? [],
      meals_dislike: userInfo.user_profile?.meals_dislike ?? [],
      is_vegan: userInfo.user_profile?.is_vegan ?? false,
      is_vegetarian: userInfo.user_profile?.is_vegetarian ?? false,
      is_allergic: userInfo.user_profile?.is_allergic ?? false,
      has_injuries: userInfo.user_profile?.has_injuries ?? false,
    })
  }, [userInfo])

  const onChange = (event: any): void => {
    const key = event.target.name
    let newValue = event.target.value

    if (newValue && typeof newValue === 'string') {
      if (newValue === 'true') newValue = true
      if (newValue === 'false') newValue = false
    }
    setFields((prevState) => ({ ...prevState, [key]: newValue }))
  }

  const [updateUserProfileByPk] = useUpdateUserProfileByPkMutation()

  const onSave = () => {
    updateUserProfileByPk({
      variables: {
        id: userInfo?.user_profile?.id,
        changes: {
          ...fields,
          meals_like: `{ ${fields.meals_like} }`,
          meals_dislike: `{ ${fields.meals_dislike} }`,
        },
      },
      refetchQueries: [{ query: GetUserByIdDocument, variables: { userId } }],
      awaitRefetchQueries: true,
    })
      .then(() => {
        enqueueSnackbar('Saved successfully', { variant: 'success' })
        onClose()
      })
      .catch(console.error)
  }

  const handleDelete = (index: number, key: 'meals_like' | 'meals_dislike') => {
    setFields((prevState) => ({
      ...prevState,
      [key]: [...prevState[key]].filter((__, idx) => idx !== index),
    }))
  }

  const addLikeMeal = (key: 'meals_like' | 'meals_dislike', newValue: string) => {
    setFields((prevState) => ({
      ...prevState,
      [key]: [...prevState[key], newValue],
    }))
  }

  const groupOfRadios = [
    {
      label: 'Vegetarisch',
      name: 'is_vegetarian',
    },
    { label: 'Vegan', name: 'is_vegan' },
    { label: 'Allergisch', name: 'is_allergic' },
    { label: 'Geblesseerd', name: 'has_injuries' },
  ]

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <Typography
                style={{
                  fontWeight: 'bold',
                }}
              >
                Hoe ziet jouw huidige ritme eruit?
              </Typography>
              <Switchable
                type="textarea"
                onChange={onChange}
                name="rythm"
                defaultValue={userInfo.user_profile?.rythm}
                isEditable={editMode}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                style={{
                  fontWeight: 'bold',
                }}
              >
                Hoe ziet jouw weekend ritme eruit?
              </Typography>
              <Switchable
                type="textarea"
                onChange={onChange}
                name="weekend"
                defaultValue={userInfo.user_profile?.weekend}
                isEditable={editMode}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                style={{
                  fontWeight: 'bold',
                }}
              >
                Hoe vaak wil je sporten?
              </Typography>
              <Switchable
                type="textarea"
                onChange={onChange}
                name="training_frequency"
                defaultValue={userInfo.user_profile?.training_frequency}
                isEditable={editMode}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <Typography
                style={{
                  fontWeight: 'bold',
                }}
              >
                Welke voeding en gerechten vind je super lekker?
              </Typography>
              <SwitchableChip
                {...{
                  isEditable: editMode,
                  list: fields.meals_like,
                  onAdd: addLikeMeal,
                  name: 'meals_like',
                  onDelete: handleDelete,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                style={{
                  fontWeight: 'bold',
                }}
              >
                Welke voeding vind je niet lekker?
              </Typography>
              <SwitchableChip
                {...{
                  isEditable: editMode,
                  list: fields.meals_dislike,
                  onAdd: addLikeMeal,
                  name: 'meals_dislike',
                  onDelete: handleDelete,
                }}
              />
            </Grid>

            {groupOfRadios.map((item, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <SwitchableRadio
                  {...{
                    name: item.name,
                    label: item.label,
                    // @ts-ignore
                    defaultValue: fields[item.name],
                    isEditable: editMode,
                    onChange,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {editMode && (
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Button variant="outlined" color="primary" style={{ marginRight: 10 }} onClick={onClose} fullWidth>
              Annuleren
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button variant="contained" disableElevation color="primary" onClick={onSave} fullWidth>
              Opslaan
            </Button>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default VoedingsTab
