import {
  Button,
  IconButton,
  makeStyles,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import EditIcon from '@material-ui/icons/Edit'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import clsx from 'clsx'
import { IExerciseGroups, IExercises, IExerciseVariants } from 'generated/graphql'
import React, { useEffect, useState } from 'react'

import { ExerciseSelectorModal } from './ExerciseSelectorModal'
import { useExercises } from './useExercises'

export const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.primary.light,
  },
  tableHeaderCell: {
    color: '#fff',
  },
  tableCell: {
    borderBottom: 'none',
  },
  textField: {
    backgroundColor: '#fff',
    width: '50%',
    borderRadius: '5px',
  },
  inputField: {
    padding: 8,
  },
  errorSign: {
    border: '1px solid red',
  },
}))

interface IProps {
  group: IExerciseGroups
  onDelete: (id: any[]) => void
  onSave: (body: any, callback: () => void) => void
  previewMode: boolean
}

export const SuperSetTableRow: React.FunctionComponent<IProps> = ({ group, onDelete, onSave, previewMode }) => {
  const classes = useStyles()
  const [errors, setErrors] = useState<any[]>([])
  const [editMode, setEditMode] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [rowIndex, setRowIndex] = useState<number | undefined>(undefined)

  const [data, setData] = useState<
    {
      id?: number
      exerciseVariant?: IExerciseVariants
      exerciseGroupId: number
      sets: string | number
      repetition: string | number
      rest: number
      unit: string
    }[]
  >([])

  const [exercises, setExercises] = useState<IExercises[]>([])

  useEffect(() => {
    const newLst = [...group.exercises]
      .sort((first, second) => first.id - second.id)
      .map((exercise) => {
        return {
          id: exercise.id,
          exerciseVariant: exercise.exercise_variant,
          exerciseGroupId: group.id,
          sets: exercise.sets ?? 0,
          repetition: exercise.repetition ?? 0,
          rest: exercise.rest ?? 0,
          unit: exercise.unit ?? 'Sec',
        }
      })
    setData(newLst)
    setExercises([...group.exercises].sort((first, second) => first.id - second.id))
  }, [group])

  const onExerciseVariantChangeHandler = (exerciseVariant: any): void => {
    setData((prevState) =>
      prevState.map((ele, idx) => {
        if (rowIndex === idx) return { ...ele, exerciseVariant }

        return ele
      })
    )
    setShowModal(false)
    setRowIndex(undefined)
  }

  const onChangeHandler = (event: any, index: number) => {
    const key = event.target.name
    const newValue = event.target.value

    if (newValue.trim().length !== 0) {
      const filteredList: any[] = [...errors].filter((err) => err.name !== key)
      setErrors(filteredList)
    } else {
      const newError = { name: key, index }
      setErrors((prevState) => [...prevState, newError])
      return
    }

    setData((prevState) =>
      prevState.map((ele, idx) => {
        if (index === idx) {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          ele[key] = newValue
          return ele
        }
        return ele
      })
    )
  }

  const onSaveChangesHandler = () => {
    if (data.some((ele) => ele.exerciseVariant === undefined)) return window.alert('Selecteer een exerise')
    const objects = data.map((exercise) => {
      return {
        id: exercise.id,
        exercise_group_id: group.id,
        exercise_variant_id: exercise.exerciseVariant?.id,
        repetition: String(exercise.repetition),
        rest: exercise.rest,
        sets: String(exercise.sets),
        unit: exercise.unit,
      }
    })

    onSave(objects, () => setEditMode(false))
  }

  useEffect(() => {
    if (group.exercises.length === 0) setEditMode(true)
  }, [group.exercises])

  const addExerciseHandler = () => {
    const newExercise = {
      id: undefined,
      exerciseVariant: undefined,
      exerciseGroupId: group.id,
      sets: 1,
      repetition: 1,
      rest: 1,
      unit: 'Sec',
    }
    setEditMode(true)
    setData((prevState) => [...prevState, newExercise])
  }

  const onCloseEditModeHandler = () => {
    setEditMode(false)
    setErrors([])
    setData((prevState) => {
      return prevState.filter((item) => item.id !== undefined)
    })
  }

  useEffect(() => {
    if (exercises.length === 0 && editMode) setEditMode(true)
  }, [editMode])

  const onBulkDeleteHandler = () => {
    const allIds = group.exercises.map((exercise) => exercise.id)
    if (allIds.length > 0) onDelete(allIds)
  }

  const onDeleteHandler = (index: any) => {
    const id = data[index]?.id
    if (id) onDelete([id])
    else setData((prevState) => prevState.filter((__, idx) => idx !== index))
  }

  const { onDeleteParent } = useExercises()

  return (
    <TableContainer>
      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell className={classes.tableHeaderCell}>Superset</TableCell>
            <TableCell className={classes.tableHeaderCell}>Sets</TableCell>
            <TableCell className={classes.tableHeaderCell}>Herhaling</TableCell>
            <TableCell className={classes.tableHeaderCell}>Rust</TableCell>
            {editMode && <TableCell />}
            <TableCell style={{ float: 'right' }}>
              <IconButton style={{ padding: 5 }} onClick={(): void => onDeleteParent(group.id)}>
                <DeleteIcon style={{ color: 'red' }} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {editMode &&
            !previewMode &&
            data.map((exercise, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className={classes.tableCell}>
                    <Button
                      onClick={(): void => {
                        setShowModal(true)
                        setRowIndex(index)
                      }}
                      color="primary"
                      variant="outlined"
                    >
                      {exercise?.exerciseVariant?.name || 'Search'}
                    </Button>
                    {showModal && (
                      <ExerciseSelectorModal
                        show={showModal}
                        onSelect={(result): void => onExerciseVariantChangeHandler(result)}
                        onClose={(): void => setShowModal(false)}
                      />
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <TextField
                      className={clsx(
                        classes.textField,
                        errors.some((err) => err.name === 'sets' && err.index === index) ? classes.errorSign : null
                      )}
                      defaultValue={exercise.sets}
                      type="number"
                      name="sets"
                      InputProps={{
                        classes: { input: classes.inputField },
                        disableUnderline: true,
                        inputProps: {
                          min: 1,
                        },
                      }}
                      onChange={(event: any) => onChangeHandler(event, index)}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        className={clsx(
                          classes.textField,
                          // eslint-disable-next-line @getify/proper-arrows/return
                          errors.some((err) => err.name === 'repetition' && err.index === index)
                            ? classes.errorSign
                            : null
                        )}
                        defaultValue={exercise.repetition}
                        InputProps={{
                          classes: { input: classes.inputField },
                          disableUnderline: true,
                          inputProps: {
                            min: 1,
                          },
                        }}
                        type="number"
                        name="repetition"
                        onChange={(event: any) => onChangeHandler(event, index)}
                      />
                      <span style={{ marginLeft: 10 }}>X</span>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div style={{ display: 'flex' }}>
                      <TextField
                        className={clsx(
                          classes.textField,
                          errors.some((err) => err.name === 'rest' && err.index === index) ? classes.errorSign : null
                        )}
                        style={{ flex: 1, marginRight: '10px' }}
                        defaultValue={data[index].rest ?? 1}
                        InputProps={{
                          classes: { input: classes.inputField },
                          disableUnderline: true,
                          inputProps: {
                            min: 1,
                          },
                        }}
                        type="number"
                        onChange={(event: any) => onChangeHandler(event, index)}
                        name="rest"
                      />
                      <Select
                        disableUnderline
                        className={classes.textField}
                        style={{ flex: 1 }}
                        native
                        onChange={(event: any) => onChangeHandler(event, index)}
                        name="unit"
                        defaultValue={data[index].unit ?? 'Sec'}
                      >
                        <option value="Sec">Sec</option>
                        <option value="Min">Min</option>
                      </Select>
                    </div>
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    <IconButton onClick={() => onDeleteHandler(index)}>
                      <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                  </TableCell>
                  {index === 0 && !previewMode && (
                    <TableCell align="right" rowSpan={data.length} className={classes.tableCell}>
                      <IconButton onClick={onCloseEditModeHandler}>
                        <HighlightOffIcon color="error" />
                      </IconButton>
                      <IconButton disabled={errors.length > 0} onClick={onSaveChangesHandler}>
                        <CheckCircleIcon style={{ color: 'green' }} />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          {!editMode &&
            exercises.map((exercise, index) => (
              <TableRow key={exercise.id}>
                <TableCell className={classes.tableCell}>
                  <Typography>{exercise.exercise_variant.name}</Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography>{exercise.sets}</Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography>{`${exercise.repetition} X`}</Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography>{`${exercise.rest} ${exercise.unit}`}</Typography>
                </TableCell>
                {index === 0 && !previewMode && (
                  <TableCell rowSpan={data.length} align="right" className={classes.tableCell}>
                    <IconButton onClick={() => setEditMode(true)}>
                      <EditIcon color="primary" />
                    </IconButton>
                    <IconButton onClick={onBulkDeleteHandler}>
                      <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                    <IconButton
                      aria-label="more"
                      aria-controls="action-menu"
                      aria-haspopup="true"
                      className="sort-icon"
                    >
                      <DragIndicatorIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          {(editMode || exercises.length === 0) && (
            <TableRow>
              <TableCell className={classes.tableCell} colSpan={2}>
                <Button color="primary" onClick={addExerciseHandler}>
                  Voeg oefening toe
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SuperSetTableRow
