import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

import {
  GetRevieableProductsDocument,
  useApproveProductMutation,
  useGetProductCategoriesQuery,
  useInsertProductMutation,
  useUpdateProductMutation,
} from '../../../generated/graphql'
import { IProps, TProductCategory } from './interfaces'

export const ProductModal: React.FC<IProps> = ({ onClose, product }): JSX.Element => {
  const [name, setName] = useState<string>(product.name)
  const [units, setUnits] = useState<string>(product.unit)
  const [calories, setCalories] = useState<number>(product.calories)
  const [fats, setFats] = useState<number>(product.fats)
  const [proteins, setProteins] = useState<number | undefined>(product.proteins)
  const [carbohydrates, setCarbohydrates] = useState<number>(product.carbohydrates)
  const [selectedCategory, setSelectedCategory] = useState<TProductCategory>()
  const { data: productCategoriesData } = useGetProductCategoriesQuery()
  const categories = productCategoriesData?.product_categories || []
  const [insertProduct, { loading: mutating }] = useInsertProductMutation()
  const [updateProductMutation, { loading: updatingProduct }] = useUpdateProductMutation()
  const [approveProduct, { loading: approving }] = useApproveProductMutation()
  const { enqueueSnackbar } = useSnackbar()

  const approveReview = (): void => {
    approveProduct({
      variables: {
        id: product.id,
      },
      refetchQueries: [{ query: GetRevieableProductsDocument }],
      awaitRefetchQueries: true,
    })
      .then(() => {
        enqueueSnackbar('Product succesvol toegevoegd', { variant: 'success' })
        onClose()
      })
      .catch((error) => {
        enqueueSnackbar('Product is toegevoegd, maar approven is mislukt. Neem contact met de admin.', {
          variant: 'error',
        })
        console.error(error)
      })
  }

  const insertNewProduct = (): void => {
    if (!name || !units || !calories || !fats || !proteins || !carbohydrates || !selectedCategory) return

    insertProduct({
      variables: {
        input: [
          {
            product_review_id: product.id,
            product_category_id: selectedCategory.id,
            name,
            units,
            calories,
            carbohydrates,
            proteins,
            fats,
            slug: name.trim().replaceAll(' ', '-'),
            unit: 'piece',
          },
        ],
      },
    })
      .then((result) => {
        if (result.data?.insert_products_rg?.affected_rows) {
          approveReview()
          return
        }

        // eslint-disable-next-line sonarjs/no-duplicate-string
        enqueueSnackbar('Er ging wat mis. Probeer het later opnieuw.', { variant: 'error' })
      })
      .catch((err) => {
        enqueueSnackbar('Product toevoegen mislukt.', { variant: 'error' })
        console.error(err)
      })
  }

  const updateProduct = (productId: number): void => {
    if (!name || !units || !calories || !fats || !proteins || !carbohydrates || !selectedCategory) return

    updateProductMutation({
      variables: {
        productId,
        input: {
          product_category_id: selectedCategory.id,
          name,
          units,
          calories,
          carbohydrates,
          proteins,
          fats,
          slug: name.trim().replaceAll(' ', '-'),
          unit: 'piece',
        },
      },
    })
      .then((response) => {
        if (response.data?.update_products_rg_by_pk) approveReview()
        else enqueueSnackbar('Er ging wat mis. Probeer het later opnieuw.', { variant: 'error' })
      })
      .catch((error) => {
        console.error(error)
        enqueueSnackbar('Er ging wat mis. Probeer het later opnieuw.', { variant: 'error' })
      })
  }

  const isDisabled = !name || !units || !calories || !fats || !proteins || !carbohydrates || !selectedCategory

  const submit = (): void => {
    if (isDisabled) {
      alert('All fields are required!')
      return
    }

    if (product.product_rg?.id) {
      updateProduct(product.product_rg.id)
      return
    }

    insertNewProduct()
  }

  const isLoading = mutating || approving || updatingProduct

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title" style={{ paddingBottom: 0 }}>
        Product reviewen
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Controleer de gegevens en klik op bevestigen om het product vrij te geven.
        </DialogContentText>

        <div style={{ height: '300px', overflow: 'auto' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                style={{ width: '100%' }}
                name="Name"
                label="Name"
                margin="dense"
                onChange={(event): void => setName(event.target.value)}
                type="text"
                variant="outlined"
                defaultValue={name}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                style={{ width: '100%' }}
                name="units"
                label="units"
                margin="dense"
                onChange={(event): void => setUnits(event.target.value)}
                type="text"
                variant="outlined"
                defaultValue={units || ''}
                placeholder="Ex: Gram/Sneetje"
                required
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="categorie-select-label">Product category</InputLabel>
                <Select
                  labelId="categorie-select-label"
                  id="categorie-select"
                  value={selectedCategory}
                  variant="standard"
                  required
                  onChange={(event): void => {
                    const cat = event.target.value
                    /*
                      @ts-ignore */
                    setSelectedCategory(cat)
                  }}
                >
                  {categories?.map((item) => (
                    <MenuItem
                      key={item.id}
                      /*
                      @ts-ignore */
                      value={item}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={6}>
              <TextField
                style={{ width: '100%' }}
                name="Proteins"
                label="Proteins"
                margin="dense"
                onChange={(event): void => setProteins(Number(event.target.value))}
                type="number"
                variant="outlined"
                defaultValue={proteins}
                required
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                style={{ width: '100%' }}
                name="Fats"
                label="Fats"
                margin="dense"
                onChange={(event): void => setFats(Number(event.target.value))}
                type="number"
                variant="outlined"
                defaultValue={fats}
                required
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                style={{ width: '100%' }}
                name="Calories"
                label="Calories"
                margin="dense"
                onChange={(event): void => setCalories(Number(event.target.value))}
                type="number"
                variant="outlined"
                defaultValue={calories}
                required
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                style={{ width: '100%' }}
                name="Carbohydrates"
                label="Carbohydrates"
                margin="dense"
                onChange={(event): void => setCarbohydrates(Number(event.target.value))}
                type="number"
                variant="outlined"
                defaultValue={carbohydrates}
                required
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={mutating || approving}>
          Close
        </Button>

        <Button
          onClick={(): void => {
            window.open(`https://world.openfoodfacts.org/product/${product.barcode}/`)
          }}
          disabled={mutating || approving}
          color="primary"
          variant="outlined"
        >
          Toon product
        </Button>

        <Button onClick={submit} color="primary" variant="contained" disabled={isLoading || isDisabled}>
          Bevestigen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
