import { Button, Grid } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { format } from 'date-fns'
import React from 'react'

import { TReviewProduct } from '../ProductModal/interfaces'

interface IProps {
  product: TReviewProduct
  onClick: () => void
}

export const Product: React.FC<IProps> = ({ product, onClick }): JSX.Element => {
  const formattedDate = format(new Date(product.created_at), 'dd-MM-yyyy HH:mm')
  const fullName = `${product.user.first_name} ${product.user.last_name}`

  return (
    <div
      style={{
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        border: '1px solid #e3e3e3',
        marginBottom: 10,
        padding: 15,
      }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={3}>
          <p style={{ margin: 0, fontWeight: 600 }}>{product.name}</p>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: 0, fontWeight: 600 }}>{`${formattedDate}`}</p>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: 0, fontWeight: 600 }}>{`${fullName}`}</p>
        </Grid>

        <Grid item xs={3}>
          <Button
            fullWidth
            onClick={onClick}
            variant="outlined"
            disableElevation
            color="primary"
            style={{ marginRight: 15, textTransform: 'none' }}
            endIcon={<ChevronRightIcon />}
          >
            Toon
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
