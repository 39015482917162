import { Avatar } from '@material-ui/core'
import React, { useContext } from 'react'

import { AuthContext } from '../../../context/auth.context'
import { IProps } from './interfaces'

export const ClientCard: React.FC<IProps> = ({ inbox, isActive }): JSX.Element => {
  const { client } = inbox

  const message = ((): string => {
    if (inbox.inbox_messages.length === 0) return 'Er zijn nog geen berichten uitgewisseld.'
    // @ts-ignore
    return inbox.inbox_messages.sort((first, second) => new Date(second.created_at) - new Date(first.created_at))[0]
      .message
  })()

  const { userId } = useContext(AuthContext)
  const countUnread = inbox.inbox_messages.reduce((count, msg) => {
    if (!msg.read && msg.sender_id !== userId) return count + 1
    return count
  }, 0)

  return (
    <div
      id="client-card"
      style={{
        display: 'flex',
        cursor: 'pointer',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: isActive ? '#3F51B5' : '#fff',
        borderTop: '1px solid #f3f3f3',
      }}
    >
      <Avatar style={{ margin: 10 }} src={client.avatar_url || ''} />
      <div style={{ padding: 10, width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3 style={{ margin: 0, color: isActive ? '#fff' : 'black' }}>
            {`${client.first_name} ${client.last_name}`}
          </h3>
          {countUnread > 0 && <p style={{ margin: 0, color: isActive ? '#fff' : 'black' }}>{countUnread}</p>}
        </div>
        <p style={{ margin: 0, color: isActive ? '#fff' : 'black' }}>
          {`${message.substr(0, 30)}${message.length > 30 ? '...' : ''}`}
        </p>
      </div>
    </div>
  )
}
