import { IDiaryQuestionCategoriesEnum } from '../../../../generated/graphql'

export const getHumanizedName = (type: IDiaryQuestionCategoriesEnum): string => {
  switch (type) {
    case 'GENERAL':
      return 'Algemeen'
    case 'MOTIVATION':
      return 'Motivatie'
    case 'MOVEMENT':
      return 'Beweging'
    case 'RELAXATION_REST':
      return 'Ontspanning & Rust'
    case 'NUTRITION':
      return 'Voeding'
    default:
      return 'Onbekend'
  }
}
